(function() {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .controller('forgotPasswordController', forgotPasswordController);

    forgotPasswordController.$inject = [
        '$state',
        '$scope',
        '$rootScope',
        '$translate',
        'authService'
    ];

    function forgotPasswordController(
        $state,
        $scope,
        $rootScope,
        $translate,
        authService
    ) {
        $scope.requestPassword = requestPassword;

        function requestPassword(username) {
            if (username) {
                showBusyIndicator();

                authService.userExists(username).then((exists) => {
                    if (exists) {
                        authService.requestPasswordChange(username).then(() => {
                            alert($translate.instant('FORGOT_PASSWORD_REQUEST_SENT'));

                            $state.go('login');
                        }).catch(showError);
                    } else {
                        showError($translate.instant('FORGOT_PASSWORD_USERNAME_DOESNT_EXIST'));
                    }
                })
                .catch($translate('FORGOT_PASSWORD_MISSING_USERNAME'))
                .finally($rootScope.$broadcast('hideBusyIndicator', 'forgotPassword'));
            } else {
                showError($translate('FORGOT_PASSWORD_MISSING_USERNAME'));
            }
        }

        function showError(errorText=null) {
            errorText = errorText || $translate('FORGOT_PASSWORD_USERNAME_INVALID');
            alert(errorText);
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'forgotPassword',
                destination: '#login',
                style: {
                    position: 'fixed',
                    'background-color': 'rgba(0,0,0,0.25)'
                }
            });
        }
    }
})();
