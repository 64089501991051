(function (){
    'use strict';

    angular.module('NaviaqWebApp').controller('contractorAdministrationController', contractorAdministrationController);

    contractorAdministrationController.$inject = [
        '$q',
        '$scope',
        '$translate',
        'kendoUtility',
        'contractorService',
        'moduleService'
    ];

    function contractorAdministrationController(
        $q,
        $scope,
        $translate,
        kendoUtility,
        contractorService,
        moduleService
    ) {
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']
        
        $scope.selectedContractor = null; 
        $scope.selectedConglomerate = null; 
        $scope.saveSelected = saveSelected;
        $scope.setModuleOptions = setModuleOptions;
        $scope.clearOptions = clearOptions;

        $scope.selectedModules = null;
        $scope.contractors = [];
        $scope.conglomerates = [];

        $scope.isConglomerate = false;

        $scope.assignedAllowModules = [];
        $scope.unassignedAllowModules = [];
        $scope.assignedBanModules = [];
        $scope.unassignedBanModules = [];

        $scope.assignedAllowModuleOptions = {
            connectWith: 'allow-module-unassigned',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.assignedAllowModules)
                }
            }),
            dataTextField: 'name',
            dataValueField: 'moduleId',
            remove: e => {
                let removedItems = e.dataItems.map(di => di.toJSON());
                $scope.assignedAllowModules = $scope.assignedAllowModules.filter(
                    p => !removedItems.map(ri => ri.moduleId).includes(p.moduleId)
                );
            },
            add: e => {
                let addedItems = e.dataItems.map(di => di.toJSON());
                $scope.assignedAllowModules = $scope.assignedAllowModules.concat(addedItems);
            },
            selectable: 'multiple'
        };
        $scope.unassignedAllowModuleOptions = {
            connectWith: 'allow-module-assigned',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.unassignedAllowModules)
                }
            }),
            dataTextField: 'name',
            dataValueField: 'moduleId',
            selectable: 'multiple',
            toolbar: {
                tools: ['transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']
            }
        };

        $scope.assignedBanModuleOptions = {
            connectWith: 'ban-module-unassigned',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.assignedBanModules)
                }
            }),
            dataTextField: 'name',
            dataValueField: 'moduleId',
            remove: e => {
                let removedItems = e.dataItems.map(di => di.toJSON());
                $scope.assignedBanModules = $scope.assignedBanModules.filter(
                    p => !removedItems.map(ri => ri.moduleId).includes(p.moduleId)
                );
            },
            add: e => {
                let addedItems = e.dataItems.map(di => di.toJSON());
                $scope.assignedBanModules = $scope.assignedBanModules.concat(addedItems);
            },
            selectable: 'multiple'
        };
        $scope.unassignedBanModuleOptions = {
            connectWith: 'ban-module-assigned',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.unassignedBanModules)
                }
            }),
            dataTextField: 'name',
            dataValueField: 'moduleId',
            selectable: 'multiple',
            toolbar: {
                tools: ['transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']
            }
        };

        
        const contractorsAdministrationGridOptions = langId => ({
            dataSource:  new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.contractors)
                }
            }),
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'contractorId',
                    hidden: true
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_CONTRACTOR_NAME_LABEL', null, null, langId),
                    template: function (dataItem) {
                        return dataItem.name;
                    },
                }
            ],
            resizable: true,
            sortable: true,
            selectable: true,
            change: function () {
                var selectedItem = this.dataItem(this.select());
                $scope.selectedContractor = $scope.contractors.find(x => x.contractorId === selectedItem.contractorId);
                var moduleIds = $scope.selectedContractor.moduleIds;

                setModuleOptions(moduleIds);
            }
        });
        $scope.contractorsAdministrationGridOptions = contractorsAdministrationGridOptions($translate.use());

        const conglomeratesAdministrationGridOptions = langId => ({
            dataSource:  new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.conglomerates)
                }
            }),
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'conglomerateId',
                    hidden: true
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_CONTRACTOR_NAME_LABEL', null, null, langId),
                    template: function (dataItem) {
                        return dataItem.name;
                    },
                }
            ],
            resizable: true,
            sortable: true,
            selectable: true,
            change: function () {
                var selectedItem = this.dataItem(this.select());
                $scope.selectedConglomerate = $scope.conglomerates.find(x => x.conglomerateId === selectedItem.conglomerateId);
                var moduleIds = $scope.selectedConglomerate.moduleIds;
                
                setModuleOptions(moduleIds);
            }
        });
        $scope.conglomeratesAdministrationGridOptions = conglomeratesAdministrationGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            if ($scope.isConglomerate) {
                $scope.conglomeratesAdministrationGridOptions = conglomeratesAdministrationGridOptions(code);
                const grid = kendoUtility.createWidget('kendoGrid', $scope.conglomeratesAdministrationGridOptions);
                kendoUtility.changeLangOfWidget(code, [() => grid('#conglomerate-administration-grid')]);
                langId = code;
            }
            else {
                $scope.contractorsAdministrationGridOptions = contractorsAdministrationGridOptions(code);
                const grid = kendoUtility.createWidget('kendoGrid', $scope.contractorsAdministrationGridOptions);
                kendoUtility.changeLangOfWidget(code, [() => grid('#contractors-administration-grid')]);
                langId = code;
            }
            
        });


        initController();
        function initController(){

            let requests = {};
            requests.getModules = moduleService.getModules();
            requests.getContractorsWithModuleIds = contractorService.getContractorsWithModuleIds();
            requests.getConglomeratesWithModuleIds = contractorService.getConglomeratesWithModuleIds();

            $q.all(requests)
                .then(data => {
                    $scope.modules = data.getModules;
                    $scope.contractors = data.getContractorsWithModuleIds;
                    $scope.conglomerates = data.getConglomeratesWithModuleIds;

                    $scope.contractorsAdministrationGridOptions.dataSource.read();
                    $scope.conglomeratesAdministrationGridOptions.dataSource.read();
                })
                .catch(error => console.error(error));
        }

        function clearOptions(e){
            $scope.selectedContractor = null;
            $scope.selectedConglomerate = null;

            $scope.unassignedAllowModules = [];
            $scope.assignedAllowModules = [];
            $scope.unassignedAllowModuleOptions.dataSource.read();
            $scope.assignedAllowModuleOptions.dataSource.read();
            
            $scope.unassignedBanModules = [];
            $scope.assignedBanModules = [];
            $scope.unassignedBanModuleOptions.dataSource.read();
            $scope.assignedBanModuleOptions.dataSource.read();
        }

        function setModuleOptions(moduleIds){
            $scope.unassignedAllowModules = $scope.modules.filter(x => !x.isBan && !moduleIds.includes(x.moduleId));
            $scope.assignedAllowModules = $scope.modules.filter(x => !x.isBan && moduleIds.includes(x.moduleId));
            $scope.unassignedAllowModuleOptions.dataSource.read();
            $scope.assignedAllowModuleOptions.dataSource.read();
            
            $scope.unassignedBanModules = $scope.modules.filter(x => x.isBan && !moduleIds.includes(x.moduleId));
            $scope.assignedBanModules = $scope.modules.filter(x => x.isBan && moduleIds.includes(x.moduleId));
            $scope.unassignedBanModuleOptions.dataSource.read();
            $scope.assignedBanModuleOptions.dataSource.read();
        }

        function saveSelected(){

            if (!$scope.isConglomerate) {
                var contractor = $scope.selectedContractor;
                contractor.moduleIds = $scope.assignedAllowModules.map(x => x.moduleId).concat($scope.assignedBanModules.map(x => x.moduleId));
                
                contractorService.updateContractorModules(contractor).then(data => {
                    $scope.contractors.find(x => x.contractorId === data.contractorId).moduleIds = data.moduleIds;
                    $scope.contractorsAdministrationGridOptions.dataSource.read();
                });
            }
            else {
                var conglomerate = $scope.selectedConglomerate;
                conglomerate.moduleIds = $scope.assignedAllowModules.map(x => x.moduleId).concat($scope.assignedBanModules.map(x => x.moduleId));
                
                contractorService.updateConglomerateModules(conglomerate).then(data => {
                    $scope.conglomerates.find(x => x.conglomerateId === data.conglomerateId).moduleIds = data.moduleIds;
                    $scope.conglomeratesAdministrationGridOptions.dataSource.read();
                });
            }
        }

    }

})();