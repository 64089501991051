(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OperationTypeDetailsModalController', OperationTypeDetailsModalController);

    OperationTypeDetailsModalController.$inject = [
        '$scope',
        '$windowInstance',
        'authService',
        'operationService',
        'formTypes',
        'formType',
        'operationType',
        'operationTypeStatus'
    ];

    function OperationTypeDetailsModalController(
        $scope,
        $windowInstance,
        authService,
        operationService,
        formTypes,
        formType,
        operationType,
        operationTypeStatus
    ) {
        $scope.authData = authService.getAuthData();
        $scope.operationType = null;
        $scope.formType = formType;

        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;

        $scope.name = null;

        if (formType === formTypes.edit && operationType) {
            setTimeout(function () {
                $scope.$apply(function () {
                    initModal(operationType);
                });
            }, 800);
        }

        function initModal(operationType) {
            $scope.operationType = operationType;
            $scope.name = operationType.name;
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                if (formType === formTypes.add) {
                    $scope.operationType = {
                        contractorId: $scope.authData.contractorId,
                        name: $scope.name,
                        status: operationTypeStatus.Active
                    };
                    operationService.addOperationType($scope.operationType).then(function () {
                        //TODO: check if successful
                        $windowInstance.close(true);

                    });
                } else {
                    $scope.operationType.contractorId = $scope.authData.contractorId;
                    $scope.operationType.name = $scope.name;
                    operationService.updateOperationType($scope.operationType).then(function () {
                        $windowInstance.close(true);
                    });
                }
            }
        }

        function closeModal() {
            $windowInstance.close();
        }
    }
})();
