(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationSharingAdministrationController', LocationSharingAdministrationController);

    LocationSharingAdministrationController.$inject = [
        '$scope',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'locationService',
        'htmlElementService',
        'commonUtility'
    ];

    function LocationSharingAdministrationController(
        $scope,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        locationService,
        htmlElementService,
        commonUtility
    ) {
        $scope.ownLocations = [];
        $scope.hasSelectedRows = false;
        $scope.openBatchLocationSharingModal = openBatchLocationSharingModal;

        var locationSharingAdministrationGridDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.ownLocations),
                update: e => onCustomersGridUpdate(e)
            },
            schema: {
                model: {
                    id: 'locationNumber',
                    fields: {
                        locationNumber: { editable: false },
                        name: { editable: false },                     
                        sharedContractors: { editable: false },                     
                    }
                },
            }
        });

        const locationSharingAdministrationGridOptions = langId => ({
            dataSource: locationSharingAdministrationGridDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                { selectable: true, width: "50px" },
                {
                    field: 'locationNumber',
                    title: $translate.instant('ADMIN_LOCATION_SETTING_TAB_LOCATION_NUMBER', null, null, langId)                  
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_LOCATION_SETTING_TAB_LOCATION_NAME', null, null, langId)
                },
                {
                    field: 'sharedContractors',
                    title: $translate.instant('ADMIN_LOCATION_SETTING_TAB_CONTRACTOR_ASSIGNED_CONTRACTORS', null, null, langId)
                },
                {
                    command: [
                        {
                            name: 'editContractors',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest("tr");
                                var data = $("#location-sharing-administration-grid").data("kendoGrid").dataItem(row).toJSON();
                                openSharingModal([data.locationNumber.toString()]);
                            },
                            template: '<a class="k-grid-editContractors"><i class="fas fa-edit"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            editable: false,
            filterable: true,
            resizable: true,
            sortable: true,
            persistSelection: true,
            change: function(e) {
                $scope.locationNumbers = Object.entries(e.sender._selectedIds).map(( [k, v] ) => (k.toString()));
                if($scope.locationNumbers.length > 0){
                    $scope.$applyAsync(function () {
                        $scope.hasSelectedRows = true;
                    });
                } else {
                    $scope.$applyAsync(function () {
                        $scope.hasSelectedRows = false;
                    });
                }
            },
        });
        $scope.locationSharingAdministrationGridOptions = locationSharingAdministrationGridOptions($translate.use());
      
        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationSharingBusyIndicatorLoad',
                destination: '#location-sharing-administration'
            });

            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });

            locationService.GetContractorOwnAndSharedLocations($scope.authData.contractorId).then(function(locations){
                $scope.ownLocations = _.orderBy(locations, ['locationNumber'], ['asc']);              
                extendLocations();
                $scope.locationSharingAdministrationGridOptions.dataSource.read();              
            }).finally(function(){
                $rootScope.$broadcast('hideBusyIndicator', 'locationSharingBusyIndicatorLoad');
            });
        }

        function openBatchLocationSharingModal()
        {
            openSharingModal($scope.locationNumbers)
        }

        function openSharingModal(locationNumbers) {
            var locations = $scope.ownLocations.filter(l => locationNumbers.includes(l.locationNumber.toString()));
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_LOCATION_SETTING_TAB_CONTRACTOR_SHARING_TITLE'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/administration/locationSharing/location-sharing-edit-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LocationSharingEditModalController',
                resolve: {
                    locations: () => locations,
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    initController();
                }
            });
        }

        function extendLocations(){
            $scope.ownLocations.forEach(location => {
                var sharedContractors = location.contractorLocations.map(c => c.contractor.name);
                location.sharedContractors = sharedContractors.join(', ');
            });
        }
    }
})();
