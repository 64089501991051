angular.module('NaviaqWebApp').directive('imageModal', ['$q', function ($q) {
    'use strict';

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/shared/directives/image-modal.html',
        link: function (scope, element, attributes, controller, transclude) {

            //subscribe these events to wait while data is loading
            scope.$on('hideBusyIndicator', function (event, id) {
                setTimeout(init, 2000);
            });

            scope.$on('hideAllBusyIndicator', function (event) {
                setTimeout(init, 2000);
            });
        }
    };

    function init() {
        // Get the modal
        var modal = $('#imageModal');

        // Get the image and insert it inside the modal - use its "alt" text as a caption
        var img = $('img');
        var modalImg = $("#imageModal .image-modal-content");
        var captionText = $('#imageModal .image-modal-caption');
        img.bind('click', function (e) {
            if ($(e.target).parents('.image-editor').length) {
                modalImg.addClass('signature');
            } else {
                modalImg.removeClass('signature');
            }

            modal.attr('style', 'display:block');
            modalImg.attr('src', this.src);
            captionText.html(this.alt);
        });

        // Get the <span> element that closes the modal
        var close = $('#imageModal .image-modal-close');

        // When the user clicks on <span> (x), close the modal
        close.bind('click', function () {
            modal.attr('style', 'display:none');
        });
    }

}]);
