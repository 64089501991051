(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('restReportController', restReportController);

    restReportController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$q',
        '$translate',
        'authService',
        '$kWindow',
        'restService',
        'logService',
        'timeBankService',
        'commonUtility',
        'htmlElementService'
    ];

    function restReportController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $q,
        $translate,
        authService,
        $kWindow,
        restService,
        logService,
        timeBankService,
        commonUtility,
        htmlElementService
    ) {
        $scope.userId = $stateParams.userId;
        $scope.fromDate = $stateParams.fromDate;
        $scope.toDate = $stateParams.toDate;
        $scope.restReportOverview = null;
        $scope.timebankLogPairs = [];
        $scope.hasAccessToApprove = $rootScope.authData.isSuperuser || authService.hasPermission('TimebankSkipper');
        $('html').addClass('auto-overflow');

        $scope.changeFromDate = function(value){
            $scope.fromDate = value;
            if(moment($scope.fromDate) <= moment($scope.toDate)){
                initController();
            }
        }

        $scope.changeToDate = function(value){
            $scope.toDate = value;
            if(moment($scope.fromDate) <= moment($scope.toDate)){
                initController();
            }
        }

        $scope.logPairDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.timebankLogPairs)
                }
            }),
            change: function () {
                if($scope.selectedLogPairId){
                    loadRestLogs();
                }
            },
            optionLabel: $translate.instant('REST_REPORT_TIMEBANK_LOG_PAIR_DEFAULT_OPTION'),
            dataTextField: 'longName',
            dataValueField: 'timebankLogPairId'
        };

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#rest-report-container').addClass('print-view-container');
        }

        initController();
        function initController(){
            loadTimebankLogPairs();
        }

        function loadTimebankLogPairs(){
            htmlElementService.checkIfElementExists("main-view")
            .then(function (result) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'restReportIndicator',
                    destination: '#main-view',
                    overlay: true,
                });
            });

            var fromDate = moment($scope.fromDate).format('YYYY-MM-DDTHH:mm:ss');
            var toDate = moment($scope.toDate).format('YYYY-MM-DDTHH:mm:ss');

            var request = {
                getTimeBankOperationLogPairsForRestReport:
                    timeBankService.getTimeBankOperationLogPairsForRestReport(fromDate, toDate, $scope.userId)
            };

            $q.all(request).then(function (result) {
                var logPair = result.getTimeBankOperationLogPairsForRestReport;
                logPair.forEach(pair => {
                    pair.longName = `${pair.startActionName} - ${pair.stopActionName} ${moment(pair.startDateTime).format('DD.MM.YYYY HH:mm:ss')} - ${moment(pair.stopDateTime).format('DD.MM.YYYY HH:mm:ss')}`;
                    pair.timebankLogPairId = commonUtility.uuidv4()
                });
                $scope.timebankLogPairs = logPair;
                $scope.logPairDropdownOptions.dataSource.read();
            }).finally(function(){
                $rootScope.$broadcast('hideBusyIndicator', 'restReportIndicator');
            });
        }

        function loadRestLogs(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'restReportIndicator',
                destination: '#main-view',
                overlay: true,
            });

            var selectedLogPair = $scope.timebankLogPairs.find(x => x.timebankLogPairId == $scope.selectedLogPairId);
            var request = {
                getRestReportOverview: restService.getRestReportOverview({
                    contractorId: $rootScope.authData.contractorId,
                    userId: $scope.userId,
                    fromDate: moment(selectedLogPair.startDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                    toDate: moment(selectedLogPair.stopDateTime).format('YYYY-MM-DDTHH:mm:ss')
                }),
            };
            $q.all(request).then(function (result) {
                $scope.restReportOverview = result.getRestReportOverview;
                if($scope.restReportOverview.restReports.length){
                    $scope.restReportOverview.restReports = $scope.restReportOverview.restReports.map(x => ({
                        ...x,
                        startDate: new Date(x.startDateTime),
                    }));
                    $scope.restReportOverview.restReports = _.orderBy($scope.restReportOverview.restReports, ['startDate'], ['asc']);
                    $scope.restReportOverview.fromDate = selectedLogPair.startDateTime;
                    $scope.restReportOverview.toDate = selectedLogPair.stopDateTime;

                    $scope.restReportOverview.restReportsByLogs = [];
                    var length = moment($scope.restReportOverview.toDate).diff(moment($scope.restReportOverview.fromDate), 'days')
                    var restReportsByDays = [];

                    var startDateTimeOfLog = moment($scope.restReportOverview.fromDate);
                    var endDateTimeOfLog = moment($scope.restReportOverview.toDate).set("hour", 23).set("minute", 59);

                    var i = 0;
                    while  (i < length + 1) {
                        var shiftedCurentDateStart = moment(JSON.parse(JSON.stringify($scope.restReportOverview.fromDate))).add(i, 'days');
                        var currentDateStart = moment(moment(shiftedCurentDateStart).format('YYYY-MM-DDT00:00:00'));
                        var currentDateEnd = moment(JSON.parse(JSON.stringify(currentDateStart))).add(1, 'days');
                        var restReportsByDay = {};
                        restReportsByDay.date = moment(currentDateStart).format('DD.MM.YYYY');

                        var isFirstDayOfLog = false;
                        if(startDateTimeOfLog.format('DD.MM.YYYY') == currentDateStart.format('DD.MM.YYYY')){
                            isFirstDayOfLog = true;
                        }

                        var isLastDayOfLog = false;
                        if(endDateTimeOfLog.format('DD.MM.YYYY') == currentDateStart.format('DD.MM.YYYY')){
                            isLastDayOfLog = true;
                        }

                        var currentDaysRestReports = [];
                        var sumRestOfTheDay = 0;
                        var hasLargerPairThanSixHours = false;

                        $scope.restReportOverview.restReports.forEach(restReport => {
                            var restReportCopy = JSON.parse(JSON.stringify(restReport));
                            var startDate = moment(restReportCopy.startDateTime);
                            var stopDate = moment(restReportCopy.stopDateTime);

                            if( moment(moment(currentDateStart).format('YYYY-MM-DDT00:00:00')) >= moment(moment(selectedLogPair.startDateTime).format('YYYY-MM-DDT00:00:00'))

                                && ((!isFirstDayOfLog && startDate >= currentDateStart && startDate < currentDateEnd)
                                || (!isFirstDayOfLog && stopDate >= currentDateStart && stopDate <= currentDateEnd)
                                || (!isFirstDayOfLog && startDate < currentDateStart && stopDate > currentDateEnd)
                                || (isFirstDayOfLog && startDate >= startDateTimeOfLog && startDate < currentDateEnd)
                                || (isFirstDayOfLog && stopDate > startDateTimeOfLog && stopDate <= currentDateEnd)
                                || (isFirstDayOfLog && startDate < startDateTimeOfLog && stopDate > currentDateEnd))){

                                if(!isFirstDayOfLog && startDate < currentDateStart){
                                    restReportCopy.startDateTime = currentDateStart;
                                }

                                if(isFirstDayOfLog && startDate < moment(startDateTimeOfLog)){
                                    // check log length without splitting
                                    var logStartDate = startDate < currentDateStart ? currentDateStart : startDate;
                                    var logEndDate = stopDate > moment(JSON.parse(JSON.stringify(currentDateStart))).add(1, 'days')
                                    ? moment(JSON.parse(JSON.stringify(currentDateStart))).add(1, 'days') : stopDate;
                                    hasLargerPairThanSixHours = (moment(logEndDate).diff(moment(logStartDate), 'minutes') / 60) >= 6;
                                    restReportCopy.startDateTime = startDateTimeOfLog;
                                }

                                if(moment(moment(currentDateStart).format('YYYY-MM-DDT00:00:00')) == moment(moment(selectedLogPair.startDateTime).format('YYYY-MM-DDT00:00:00'))
                                    && startDate < currentDateStart){
                                    restReportCopy.startDateTime = currentDateStart;
                                }

                                if(moment(stopDate) > moment(currentDateEnd)){
                                    restReportCopy.stopDateTime = currentDateEnd;
                                }

                                if(moment(restReportCopy.stopDateTime) > moment(restReportCopy.startDateTime)){
                                    restReportCopy.sum = moment(restReportCopy.stopDateTime).diff(moment(restReportCopy.startDateTime), 'minutes') / 60;
                                    restReportCopy.statusText = logService.getTimeBankLogStatusText(restReportCopy.status);
                                    restReportCopy.needApprove = restReportCopy.status === 0 || restReportCopy.status === 2;
                                    // Format date to html
                                    restReportCopy.formatedStartDateTime = new Date(restReportCopy.startDateTime);
                                    restReportCopy.formatedStopDateTime = new Date(restReportCopy.stopDateTime);
                                    currentDaysRestReports.push(restReportCopy);
                                    sumRestOfTheDay = sumRestOfTheDay + restReportCopy.sum;
                                    hasLargerPairThanSixHours = hasLargerPairThanSixHours ? hasLargerPairThanSixHours : restReportCopy.sum >= 6
                                }
                            }
                        });

                        restReportsByDay.sumRestOfTheDay = sumRestOfTheDay;
                        restReportsByDay.hasLargerPairThanSixHours = hasLargerPairThanSixHours;
                        restReportsByDay.restReports = currentDaysRestReports;

                        if(moment(moment(currentDateStart).format('YYYY-MM-DDT00:00:00')) >= moment(moment(selectedLogPair.startDateTime).format('YYYY-MM-DDT00:00:00'))){
                            restReportsByDays.push(restReportsByDay);
                        }

                        var todayRestTime = $scope.restReportOverview.restTimePerDay.find(x => moment(x.day).isSame(currentDateStart));
                        if(todayRestTime){
                            restReportsByDay.lastSevenDaysRestTime = Math.round(todayRestTime.restTimeInLastSevenDays * 100) / 100;
                        }
                        i = i + 1;
                    }
                    $scope.restReportOverview.restReportsByLogs.push({restReportsByDays: restReportsByDays});
                }

                $scope.$applyAsync();
                $rootScope.$broadcast('hideBusyIndicator', 'restReportIndicator');
            });
        }

        $scope.$on('$stateChangeStart', function (event, next) {
            $('html').removeClass('auto-overflow');
        });

        $scope.printReport = function() {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#rest-report-container').addClass('print-view-container');
            document.title = 'Rest-report-' + $scope.restReportOverview.displayName;

            window.print();

            setTimeout(function () {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'restReportIndicator',
                    destination: '#main-view',
                    overlay: true,
                });

                $rootScope.showNavbar = true;
                $scope.printView = false;
                document.title = 'Naviaq Webinnsyn';
                $('#main-view').removeClass('print-view');
                $('#rest-report-container').removeClass('print-view-container');
                $rootScope.$broadcast('hideBusyIndicator', 'restReportIndicator');
            }, 1);
        }

        $scope.approveRestLogs = function(){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('REST_REPORT_APPROVE_DATES_MODAL_TITLE'),
                    resizable: true,
                    visible: false,
                    width: 400,
                    height: 200
                },
                templateUrl: 'app/report/restReport/approveRestLogsModal/approve-rest-logs-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'approveRestLogsModal',
                resolve: {
                    userId: () => {return $scope.userId},
                    fromDate: () => {return $scope.fromDate},
                    toDate: () => {return $scope.toDate}
                }
            });

            windowInstance.result.then(function (approveLogsFilter) {
                var request = {
                    approveRestLogs: restService.approveRestLogs(approveLogsFilter),
                };
                $q.all(request).then(function (result) {
                    initController();
                });
            });
        }
    }
})();
