(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentEventsModalController', EquipmentEventsModalController);

    EquipmentEventsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$kWindow',
        'systemUserName',
        'formTypes',
        'logService',
        'departmentId',
        'relatedItemId',
        '$translate',
        'caseDetailsModalService',
        'workOrderDetailsModalService',
        'excelExportService',
        'equipmentDescription',
        'kendoUtility'
    ];

    function EquipmentEventsModalController(
        $q, 
        $rootScope, 
        $scope, 
        $kWindow, 
        systemUserName, 
        formTypes, 
        logService,
        departmentId, 
        relatedItemId,
        $translate, 
        caseDetailsModalService, 
        workOrderDetailsModalService,
        excelExportService,
        equipmentDescription,
        kendoUtility
    ) {
        $scope.addEvent = addEvent;
        $scope.editEvent = editEvent;
        $scope.editChecklistItem = editChecklistItem;
        $scope.editCase = editCase;
        $scope.editWorkOrder = editWorkOrder;
        $scope.exportToExcel = exportToExcel;

        $scope.ttipOptions = kendoUtility.getTooltipOptions();

        $scope.dateTimeFormat = "{0:dd/MM/yyyy HH:mm}"
        $scope.dateTimeFormatExcelExport = "dd/mm/yyyy hh:mm"

        $scope.eventsGridOptions = {
            dataSource: {
                transport: {
                    read: (e) => {
                        logService.getOperationLogsWithItemsByItemFilterWebAPI({
                            contractorId: $scope.authData.contractorId,
                            departmentIds: [departmentId],
                            itemId: relatedItemId
                        })
                        .then((result) => { e.success(result) })
                        .catch((error) => {
                            console.error(`Error reading events grid data source: ${error}`);
                            e.error(error);
                        })
                    }
                },
                schema: {  
                    id: "equipmentEventLogs",  
                    model: { 
                        fields: {  
                            doneOn: { type: "date" },
                            value: { type: "number" },
                            editedBy: { 
                                type: "string",
                                parse: (editedBy) => editedBy === systemUserName ? systemUserName : editedBy,
                            },
                            editedOn: { type: "date" },
                            logActionType: { 
                                type: "string" ,
                                parse: (logActionType) => logService.getType(logActionType)

                            },
                            eventLogHasItemFiles: {
                                from: "hasItemFiles",
                                type: "string",
                                parse: (hasItemFiles) => hasItemFiles ? $translate.instant('G_YES') : $translate.instant('G_NO')
                            }
                        }  
                    }  
                },
                sort: { field: "doneOn", dir: "desc" }
            },
            sortable: true,
            scrollable: true,
            filterable: true,
            pageable: false,
            resizable: true,
            height: 200,
            columns: [
                {
                    title: '',
                    command: {
                        name: 'editEventLog',
                        text: '',
                        iconClass: 'fa fa-pencil-alt',
                        click: (event) => editEvent(kendoUtility.mapGridClickEventToDataItem($scope.eventsGrid, event))
                    },
                    exportable: false,
                    width: '6rem',
                    resizable: false
                },{
                    field: 'doneOn',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_DONE_ON'),
                    width: '12rem',
                    format: $scope.dateTimeFormat,
                    exportFormat: $scope.dateTimeFormatExcelExport
                },{
                    field: 'logActionName',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_LOGACTION'),
                    width: '16rem'
                },{
                    field: 'description',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_DESCRIPTION'),
                    width: '24rem'
                },{
                    field: 'value',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_VALUE'),
                    width: '8rem'
                },{
                    field: 'editedBy',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_EDITED_BY'),
                    width: '12rem'
                },{
                    field: 'editedOn',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_EDITED_ON'),
                    width: '12rem',
                    format: $scope.dateTimeFormat,
                    exportFormat: $scope.dateTimeFormatExcelExport
                },{
                    field: 'logActionType',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_LOGACTION_TYPE'),
                    width: '12rem'
                },{
                    field: 'eventLogHasItemFiles',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_FILES')
                }
            ]
        };

        $scope.checklistsGridOptions = {
            dataSource: {
                transport: {
                    read: (e) =>  {
                        logService.getChecklistItemsByEquipmentId(relatedItemId)
                        .then((result) => { e.success(result) })
                        .catch((error) => {
                            console.error(`Error reading checklistsGrid data source: ${error}`);
                            e.error(error);
                        })
                    }
                },
                schema: {  
                    id: "equipmentCheckListItemValues",  
                    model: { 
                        fields: {  
                            firstEditedOn: { type: "date" },  
                            numericValue: { type: "number" }, 
                            editedBy: { 
                                type: "string",
                                parse: (editedBy) => editedBy === systemUserName ? systemUserName : editedBy,
                            },
                            editedOn: { type: "date" },
                            logActionDescription: { from: "checkListItem.logAction.description"},
                            checkListItemDescription: { from: "checkListItem.description" }
                        }  
                    }
                },
                sort: { field: "firstEditedOn", dir: "desc" }
            },
            sortable: true,
            scrollable: true,
            filterable: true,
            pageable: false,
            height: 200,
            resizable: true,
            columns: [
                {
                    title: '',
                    command: {
                        name: 'editChecklistItem',
                        text: '',
                        iconClass: 'fa fa-pencil-alt',
                        click: (event) => editChecklistItem(kendoUtility.mapGridClickEventToDataItem($scope.checklistsGrid, event))
                    }, 
                    exportable: false,
                    width: '6rem',
                    resizable: false
                },{
                    field: 'checkListValueId',
                    hidden: true,
                    exportable: false
                },{
                    field: 'firstEditedOn',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_DONE_ON'),
                    width: '12rem',
                    format: $scope.dateTimeFormat,
                    exportFormat: $scope.dateTimeFormatExcelExport
                },{
                    field: 'logActionDescription',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_LOGACTION'),
                    width: '16rem'
                },{
                    field: 'checkListItemDescription',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_DESCRIPTION'),
                    width: '24rem'
                },{
                    field: 'numericValue',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_VALUE'),
                    width: '8rem'
                },{
                    field: 'editedBy',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_EDITED_BY'),
                    width: '12rem'
                },{
                    field: 'editedOn',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_EDITED_ON'),
                    format: $scope.dateTimeFormat,
                    exportFormat: $scope.dateTimeFormatExcelExport
                }
            ]
        };

        $scope.casesGridOptions = {
            dataSource: {
                transport: {
                    read: (e) => {
                        logService.getCasesByEquipmentId(relatedItemId)
                        .then((result) => { e.success(result) })
                        .catch((error) => {
                            console.error(`Error reading casesGrid data source: ${error}`);
                            e.error(error);
                        })
                    }
                },
                schema: {  
                    id: "equipmentCaseLogs",  
                    model: { 
                        fields: {  
                            doneOn: { type: "date" },  
                            caseId: { type: "number" }, 
                            createdOn: { type: "date" },
                            caseHasRelatedFiles: {
                                from: "caseRelatedFiles.length",
                                type: "string",
                                parse: (length) => length ? $translate.instant('G_YES') : $translate.instant('G_NO')
                            },
                            caseHasRelatedWorkOrders: {
                                from: "caseRelatedWorkOrders.length",
                                type: "string",
                                parse: (length) => length ? $translate.instant('G_YES') : $translate.instant('G_NO')
                            }
                        }  
                    }  
                },
                sort: { field: "doneOn", dir: "desc" }
            },
            sortable: true,
            scrollable: true,
            filterable: true,
            pageable: false,
            resizable: true,
            height: 200,
            columns: [
                {
                    title: '',
                    command: {
                        name: 'editCase',
                        text: '',
                        iconClass: 'fa fa-pencil-alt',
                        click: (event) => editCase(kendoUtility.mapGridClickEventToDataItem($scope.casesGrid, event))
                    },
                    exportable: false,
                    width: '6rem',
                    resizable: false
                },{
                    field: 'doneOn',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_DONE_ON'),
                    width: '12rem',
                    format: $scope.dateTimeFormat,
                    exportFormat: $scope.dateTimeFormatExcelExport
                },{
                    field: 'caseId',
                    title: $translate.instant('CASE_GRID_CASEID'),
                    width: '8rem'
                },{
                    field: 'title',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_DESCRIPTION'),
                    width: '16rem'
                },{
                    field: 'assignedTo',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_ASSIGNED_TO'),
                    width: '12rem'
                },{
                    field: 'caseStatus',
                    title: $translate.instant('CASE_GRID_STATUS'),
                    width: '12rem'
                },{
                    field: 'createdBy',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_CREATED_BY'),
                    width: '12rem'
                },{
                    field: 'createdOn',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_CREATED_ON'),
                    width: '12rem',
                    format: $scope.dateTimeFormat,
                    exportFormat: $scope.dateTimeFormatExcelExport
                },{
                    field: 'caseHasRelatedFiles',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_FILES'),
                    width: '12rem'
                },{
                    field: 'caseHasRelatedWorkOrders',
                    title: $translate.instant('CASE_GRID_AO')
                }
            ]
        };

        $scope.workOrdersGridOptions = { 
            dataSource: {
                transport: {
                    read: (e) => {
                        logService.getWorkOrdersByEquipmentId(relatedItemId)
                        .then((result) => { e.success(result) })
                        .catch((error) => {
                            console.error(`Error reading workOrdersGrid data source: ${error}`);
                            e.error(error);
                        })
                    }
                },
                schema: {  
                    id: "equipmentWorkOrderLogs",  
                    model: { 
                        fields: {  
                            doneOn: { type: 'date' },  
                            woNumber: { type: 'number' }, 
                            createdOn: { type: 'date' },
                            assignedTo: { 
                                type: 'string',
                                parse: (assignedTo) => assignedTo.includes('NAVIAQ SYSTEM') ? $translate.instant('G_NOTASSIGNED') : assignedTo
                            },
                            workOrderHasRelatedFiles: {
                                from: 'workOrderRelatedFiles.length',
                                type: 'string',
                                parse: (length) => length ? $translate.instant('G_YES') : $translate.instant('G_NO')
                            }
                        }  
                    }  
                },
                sort: { field: "doneOn", dir: "desc" }
            },
            sortable: true,
            scrollable: true,
            filterable: true,
            pageable: false,
            height: 200,
            resizable: true,
            columns: [
                {
                    title: '',
                    command: {
                        name: 'editWorkOrderLog',
                        text: '',
                        iconClass: 'fa fa-pencil-alt',
                        click: (event) => editWorkOrder(kendoUtility.mapGridClickEventToDataItem($scope.workOrdersGrid, event))
                    },
                    width: '6rem',
                    exportable: false,
                    resizable: false
                },{
                    field: 'doneOn',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_DONE_ON'),
                    width: '12rem',
                    format: $scope.dateTimeFormat,
                    exportFormat: $scope.dateTimeFormatExcelExport
                },{
                    field: 'woNumber',
                    title: $translate.instant('WORKORDER_GRID_WONUMBER'),
                    width: '8rem'
                },{
                    field: 'title',
                    title: $translate.instant('WORKORDER_GRID_TITLE'),
                    width: '16rem'
                },{
                    field: 'description',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_DESCRIPTION'),
                    width: '16rem'
                },{
                    field: 'assignedTo',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_ASSIGNED_TO'),
                    width: '12rem'
                },{
                    field: 'createdBy',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_CREATED_BY'),
                    width: '12rem'
                },{
                    field: 'createdOn',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_CREATED_ON'),
                    width: '12rem',
                    format: $scope.dateTimeFormat,
                    exportFormat: $scope.dateTimeFormatExcelExport
                },{
                    field: 'workOrderHasRelatedFiles',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_FILES'),
                    width: '8rem'
                },{
                    field: 'parentCaseId',
                    title: $translate.instant('EQUIPMENT_EVENTS_GRID_CASE'),
                    filterable: false,
                    sortable: false
                }
            ]
        };

        function addEvent() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Ny hendelse',
                    resizable: true,
                    height: 600,
                    width: 1200,
                    visible: false
                },
                templateUrl: 'app/log/operation-log-extdetails-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationLogExtDetailsModalController',
                resolve: {
                    departmentId: function () {
                        return departmentId;
                    },
                    formType: function () {
                        return formTypes.add;
                    },
                    relatedItemId: function () {
                        return relatedItemId;
                    },
                    operationLog: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                $scope.eventsGrid.dataSource.read();
            });
        }

        function editEvent(eventDataItem) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Endre hendelse',
                    resizable: true,
                    height: 600,
                    width: 1200,
                    visible: false
                },
                templateUrl: 'app/log/operation-log-extdetails-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationLogExtDetailsModalController',
                resolve: {
                    departmentId: () => {
                        return eventDataItem.departmentId;
                    },
                    formType: function () {
                        return formTypes.edit;
                    },
                    relatedItemId: function () {
                        return eventDataItem.relatedItemId;
                    },
                    operationLog: function () {
                        return eventDataItem;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                $scope.eventsGrid.dataSource.read();
            });
        }

        function editChecklistItem(dataItem) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'checklistsGrid',
                destination: '#checklists-grid'
            });

            var requests = {
                getOperationLogById: logService.getOperationLogById(dataItem.operationLogId),
            }

            $q.all(requests).then(function(result) {
                result.getOperationLogById.relatedItemId = result.getOperationLogById.itemId;
                editEvent(result.getOperationLogById)
            })
            .finally(() => {
                $rootScope.$broadcast('hideBusyIndicator', 'checklistsGrid');
            });
        }

        function editCase(dataItem) {
            caseDetailsModalService
            .createCase(dataItem)
            .then((caseData) => {
                return caseDetailsModalService.createCaseDetailsResolve(caseData)
            })
            .then((resolve) => {
                let modal = caseDetailsModalService.openCaseDetailsModal(resolve);
                modal.result.then(() => $scope.casesGrid.dataSource.read());
            })
            .finally(() => {
                $rootScope.$broadcast('hideBusyIndicator', 'caseDetailsControllerModal');
            })
        }

        function editWorkOrder(dataItem) {
            workOrderDetailsModalService.createWorkOrderDetailsResolve(dataItem)
            .then(workOrderLog => {
                workOrderDetailsModalService.openWorkOrderDetailsModal(workOrderLog)
                .result.then(() => {
                    $scope.workOrdersGrid.dataSource.read();
                })
            });
        }

        function exportToExcel() {
            var workbook = new kendo.ooxml.Workbook({
                sheets: [
                    excelExportService.exportKendoGridToKendoSheet($scope.eventsGrid, $translate.instant('EQUIPMENT_EVENTS_LOG_EVENTS')),
                    excelExportService.exportKendoGridToKendoSheet($scope.checklistsGrid, $translate.instant('EQUIPMENT_EVENTS_CHECKLIST_EVENTS')),
                    excelExportService.exportKendoGridToKendoSheet($scope.casesGrid, $translate.instant('EQUIPMENT_EVENTS_CASES_EVENTS')),
                    excelExportService.exportKendoGridToKendoSheet($scope.workOrdersGrid, $translate.instant('EQUIPMENT_EVENTS_WORKORDERS_EVENTS'))
                ]
            });

            kendo.saveAs({
                dataURI: workbook.toDataURL(),
                fileName:  `${equipmentDescription}_${$translate.instant('EQUIPMENT_EVENTS_TITLE')}_${moment().format('DD.MM.YYYY')}.xlsx`
            });
        }
    }
})();
