(function(){
    angular.module('NaviaqWebApp').directive('serviceCardOrderInfo', function () {
        'use strict';

        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/report/directives/service-card-order-info.html',
            link: function (scope, element, attrs) {
            }
        };
    });
})();
