/**
 * Utility used to extend kendo widget functionality
 */
(function() {
    'use strict';

    angular.module('NaviaqWebApp').factory('kendoUtility', kendoUtility);

    kendoUtility.$inject = ['$translate'];

    function kendoUtility($translate) {
        return {
            addFiles,
            rebindDropDownTree,
            adjustGridHeight,
            applyLanguage,
            calculateRemainingSpace,
            changeLangOfWidget,
            createWidget,
            calculateAndSetMaxWidth,
            getTooltipOptions,
            onFilterMenuInit,
            createFilterOnGrid,
            mapGridClickEventToDataItem
        };

        function addFiles(kendoWidget, files, fileNameProp) {
            if (!fileNameProp) {
                fileNameProp = 'fileName';
            }

            var kendoWidgetElement = kendoWidget.wrapper;
            var $existingFiles = $('<ul class="k-upload-files k-reset" />');

            for (var i = 0; i < files.length; ++i) {
                var $currentFile = $('<li class="k-file" data-uid="a2da5152-d350-46ad-a525-7f22dfe87f17" />');

                $currentFile.append('<span class="k-progress" style="width: 100%;"></span>');
                $currentFile.append(
                    '<span class="k-file-extension-wrapper"><span class="k-file-extension">png</span><span class="k-file-state"></span></span>'
                );
                $currentFile.append(
                    '<span class="k-file-name-size-wrapper"><span class="k-file-name" title="' +
                        files[i][fileNameProp] +
                        '">' +
                        files[i][fileNameProp] +
                        '</span><span class="k-file-size">52.81 KB</span></span>'
                );
                $currentFile.append(
                    '<strong class="k-upload-status"><button type="button" class="k-button k-button-bare k-upload-action"><span class="k-icon k-i-close k-i-delete" title="Fjern" aria-label="Fjern"></span></button></strong>'
                );

                $existingFiles.append($currentFile);
            }

            kendoWidgetElement.append($existingFiles);
        }

        function adjustGridHeight(kendoGridId, itemLength) {
            if (kendoGridId && itemLength) {
                var newHeight = itemLength * 40 + 80;
                if (newHeight < calculateRemainingSpace()) {
                    $(kendoGridId).height(newHeight);
                    $(kendoGridId + ' .k-grid-content').height(newHeight - 75);
                    $(kendoGridId + ' .k-grid-content tr').height(40);
                } else {
                    $(kendoGridId).height(calculateRemainingSpace());
                }
            } else {
                $(kendoGridId).height(calculateRemainingSpace());
            }
        }

        function applyLanguage(id, callback) {
            doApplyLanguage(id, function () {
                kendo.culture(id === 'en' ? "en-US" : 'no-NO');

                if (callback) {
                    callback();
                }
            });
        }

        function calculateRemainingSpace(adjustment) {
            adjustment = adjustment || 0;
            return window.innerHeight - (60 + 10 + 32 + 72 + 30) + adjustment; //navbar + padding + title + header + margin
        }

        function getPageSelectorTemplate(dataItem) {
            return `<span class="page-selector-item"><i class="${dataItem.icon}" aria-hidden="true"></i>${dataItem.text}</span>`;
        }

        function changeLangOfWidget(code, createFunctions) {
            kendo.culture(code);
            createFunctions.forEach(f => f());
        }

        function calculateAndSetMaxWidth(element) {
            if (element) {
                element[0].style.maxWidth = element[0].offsetWidth + 'px';
            }
        }

        function createWidget(type, options) {
            return function(selector) {
                const element = angular.element(selector);
                if (element.data(type)) {
                    element.data(type).destroy();
                    element.empty();
                }
                element[type](options);
            };
        }

        function rebindDropDownTree(type, options, id, isMultiselectDestroy) {
            var element = $('#' + id);
            if(element.length && isMultiselectDestroy){
                element.data(type).destroy();
                element.unwrap('.k-multiselect').show().empty();
                $(element).siblings('.k-multiselect-wrap').remove();
                $('#' + id).kendoDropDownTree(options);
            } else if(element.length) {
                element.data(type).destroy();
                element.unwrap('. k-dropdown').show().empty();
                $(element).siblings('.k-dropdown-wrap').remove();
                $('#' + id).kendoDropDownTree(options);
            }
        }

        function getTooltipOptions() {
            var tooltipOptions = {
                filter: 'td,span.with-tooltip', //selector to match elements that will get a tooltip
                content: function (e) {
                    var target = e.target; // element for which the tooltip is shown
                    return $(target).text(); //generate content for the tooltips
                },
                show: function (e) {
                    if (e.sender.content[0].textContent.length > 0) {
                        e.sender.popup.element.removeClass('k-tooltip-hidden');
                    }
                    e.sender.popup.element.addClass('k-tooltip-nowrap');
                },
                hide: function (e) {
                    e.sender.popup.element.addClass('k-tooltip-hidden');
                },
                showAfter: 1000
            };
            return tooltipOptions;
        }

        //Private functions
        function doApplyLanguage(id, callback) {
            const noCultureFile = 'dist/cultures/kendo/kendo.culture.no.js',
                  noMessageFile = 'dist/cultures/kendo/kendo.messages.nn-NO.js';

            let $kendoCultureScript = $('#kendo-culture-no');
            let $kendoMessageScript = $('#kendo-messages-no');

             if (id === 'en') {
                $kendoCultureScript = $('#kendo-culture-en');
                $kendoMessageScript = $('#kendo-messages-en');
            }

            if (id === 'no') {
                if ($kendoCultureScript != null) {
                    let script = createScriptElement('kendo-culture-no', noCultureFile, callback);
                    document.getElementsByTagName('body')[0].appendChild(script);
                }
                if ($kendoMessageScript != null) {
                    let script = createScriptElement('kendo-messages-no', noMessageFile, callback);
                    document.getElementsByTagName('body')[0].appendChild(script);
                }
            } else if (id === 'en')
            {
                if ($kendoCultureScript != null) {
                    let script = createScriptElement('kendo-culture-en', noCultureFile, callback);
                    document.getElementsByTagName('body')[0].appendChild(script);
                }
                if ($kendoMessageScript != null) {
                    let script = createScriptElement('kendo-messages-en', noMessageFile, callback);
                    document.getElementsByTagName('body')[0].appendChild(script);
                }
            }
            else {
                $kendoCultureScript.remove();
                $kendoMessageScript.remove();
                callback();
            }
        }

        function createScriptElement(id, src, callback) {
            let script = document.createElement('script');
            script.setAttribute('src', src);
            script.setAttribute('type', 'text/javascript');
            script.id = id;

            let loaded = false;
            let loadFunction = function () {
                if (loaded) {
                    return;
                }

                loaded = true;
                callback && callback();
            };

            script.onload = loadFunction;
            script.onreadystatechange = loadFunction;

            return script;
        }

        function onFilterMenuInit(e) {
            if(!this.dataSource.options.schema.model || !this.dataSource.options.schema.model.fields[e.field] || this.dataSource.options.schema.model.fields[e.field].type == 'string'){
                var firstValueDropDown = e.container.find('[data-bind="value: filters[0].operator"]').data('kendoDropDownList');
                firstValueDropDown.value("contains");
                firstValueDropDown.trigger("change");
            }

            if(this.dataSource.options.schema.model.fields && this.dataSource.options.schema.model.fields[e.field] && this.dataSource.options.schema.model.fields[e.field].type == 'date'){
                var firstValueDropDown = e.container.find('[data-bind="value: filters[0].operator"]').data('kendoDropDownList');
                firstValueDropDown.value("gte");
                firstValueDropDown.trigger("change");

                var ddl = e.container.find('select.k-filter-and').data('kendoDropDownList');
                ddl.select(0);
                ddl.trigger('change');

                var secondValueDropDown = e.container.find('[data-bind="value: filters[1].operator"]').data('kendoDropDownList');
                secondValueDropDown.value("lte");
                secondValueDropDown.trigger("change");
            }

            if(this.dataSource.options.schema.model.fields && this.dataSource.options.schema.model.fields[e.field] && this.dataSource.options.schema.model.fields[e.field].type == 'object'){
                // Set the Header Text
                var header = e.container.find('.k-filter-help-text');
                header[0].innerHTML = $translate.instant('G_SELECT_ITEMS');

                // hidden operators
                var firstValueDropDown =  e.container.find('[data-bind="value: filters[0].operator"]').data('kendoDropDownList');
                firstValueDropDown.wrapper.hide();

                var secondValueDropDown = e.container.find('[data-bind="value: filters[1].operator"]').data('kendoDropDownList');
                secondValueDropDown.wrapper.hide();

                e.container.find('.k-filter-and').parents('.k-widget').hide();
                e.container.find('.k-action-buttons').hide();

                // second value
                var secondDropdownValue = e.container.find("[title='Additional value']");
                if(secondDropdownValue){
                    secondDropdownValue.hide();
                }

                 // // set value
                 var filter = this.dataSource.filter() || { logic: "and", filters: [] };
                 var filteredFields = [];
                 getFildsFromFilters(filter, filteredFields);
                 var filterForFields = filteredFields.filter(f => f.field == e.field);

                 var dropdown = $(`#${e.field}-filter-menu-selector`).data("kendoMultiSelect");
                 if(dropdown){
                     if(filterForFields.length){
                         dropdown.value(filterForFields.map(f => f.value));
                     } else {
                         dropdown.value([]);
                     }
                 }
            }
        }

        function getFildsFromFilters(filters, fieldElList){
            for (const [key, value] of Object.entries(filters)) {
                if(Array.isArray(value)){
                    value.forEach(el => {
                        if(el.field){
                            fieldElList.push(el);
                        } else {
                            getFildsFromFilters(el, fieldElList);
                        }
                    });
                }
              }
        }

        function createFilterOnGrid(gridDataSource, fieldFilters, currentFieldName){
            var currentFilter = gridDataSource.filter();

            var activeFilters = [];
            if(currentFilter){
                iterateThroughNestedObj(currentFilter, fieldFilters, activeFilters);
             }
             activeFilters = activeFilters.filter(f => f.field != currentFieldName);
             activeFilters = activeFilters.concat(fieldFilters);

             var filterGroup = _.groupBy(activeFilters, 'field');

            var columnsFilter = [];
            Object.values(filterGroup).forEach(function (filtersOnField) {
                var filter = {
                    filters: filtersOnField,
                    logic: "or"
                };
                columnsFilter.push(filter);
            });
            var finalFilter = {
                filters: columnsFilter,
                logic: "and"
            };
            gridDataSource.filter(finalFilter);
        }

        function iterateThroughNestedObj(obj, array, outArray) {
            Object.keys(obj).forEach(function (key) {
                if(key == 'field'){
                    outArray.push(obj);
                }
                if (typeof obj[key] === 'object') {
                    return iterateThroughNestedObj(obj[key], array, outArray);
                }
            });
        }

        function mapGridClickEventToDataItem(grid, event) {
            event.preventDefault();

            return grid.dataItem($(event.currentTarget).closest("tr"));
            
        }
    }
})();
