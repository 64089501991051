(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('seagoingService', seagoingService);

    seagoingService.$inject = ['$q', '$http', 'serviceUrls'];

    function seagoingService($q, $http, serviceUrls) {
        const seagoingServiceWebApiUrl = `${serviceUrls.webApiBaseUrl}/seagoingService`;

        const service = {
            getSeagoingServiceByContractorId: getSeagoingServiceByContractorId,
        };

        return service;

        function getSeagoingServiceByContractorId(filter) {
            const deferred = $q.defer();

            var url = `${seagoingServiceWebApiUrl}/byFilter`;
            $http.post(url, filter).then(function(response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateJobDefinition(jobDefinition) {
            const deferred = $q.defer();

            $http.put(webApiUrl, jobDefinition).then(function(response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
