angular.module('NaviaqWebApp').directive('activityLineAdvancedTable', [function () {
    'use strict';

    return {
        restrict: 'A',
        replace: true,
        templateUrl: 'app/report/directives/activity-line-advanced-table.html',
        link: function (scope, element, attrs) {
            var $advancedTable = $('<table class="tblMain" />'),
                $thead = $('<thead />'),
                $tbody = $('<tbody />');

            if (scope && scope.$parent && scope.$parent.activityLine && scope.$parent.activityLine.Value) {
                var advTableObject = JSON.parse(scope.$parent.activityLine.Value);

                //Build header
                if (advTableObject.Columns) {
                    for (var i = 0; i < advTableObject.Columns.length; ++i) {
                        $thead.append('<th class="reportTableHeader">' + advTableObject.Columns[i].ColumnName + '</th>');
                    }
                }

                //Build rows
                if (advTableObject.Rows && advTableObject.Rows.length > 0) {
                    //Render rows
                    for (var j = 0; j < advTableObject.Rows.length; ++j) {
                        var $currentRow = $('<tr />')
                        for (var k = 1; k < advTableObject.Rows[j].length; ++k) {
                            $currentRow.append('<td>' + advTableObject.Rows[j][k] + '</td>');
                        }

                        $tbody.append($currentRow);
                    }
                } else {
                    if (advTableObject.Columns && advTableObject.Columns.length > 0) {
                        $tbody.append('<tr><td class="no-data" colspan="' + advTableObject.Columns.length + '">Ingen data registrert</td></tr>');
                    }
                }

                $advancedTable.append($thead);
                $advancedTable.append($tbody);
            }

            $('.table-container').append($advancedTable);
        }
    };
}]);
