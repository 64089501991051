(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('JobTitleDetailsModalController', JobTitleDetailsModalController);

    JobTitleDetailsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'jobTitleId',
        'formType',
        'formTypes',
        'jobTitleService',
        'userService',
        'htmlElementService',
        'styrkTitleService'
    ];

    function JobTitleDetailsModalController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        jobTitleId,
        formType,
        formTypes,
        jobTitleService,
        userService,
        htmlElementService,
        styrkTitleService
    ) {
        $scope.jobTitle = {};
        $scope.roles = [];
        $scope.styrkTitles = [];
        $scope.close = close;
        $scope.validateForm = validateForm;
        $scope.canAssignRoles = $rootScope.authData.isSuperuser || hasUserRole() ? true : false;
        $scope.jobTitleName = "";
        $scope.jobTitleSequenceNumber = "";

        $scope.jobTitleRolesOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.roles);
                    }
                }
            }),
            dataValueField: 'id',
            dataTextField: 'key'
        };

        $scope.styrkTitleDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.styrkTitles)
                }
            }),
            filter: 'contains',
            dataTextField: 'title',
            dataValueField: 'styrkTitleId'
        };

        function hasUserRole(){
            var canAssignRoles = false;
            $rootScope.authData.roles.forEach(role => {
                role.permissions.forEach(permission => {
                    if(permission.key == "UserAdd" || permission.key == "UserEdit"){
                        canAssignRoles = true;
                    }
                });
            });

            return canAssignRoles;
        }

        initController();
        function initController() {

            htmlElementService.checkIfElementExists("job-title-details-form")
            .then(function (result) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'JobTitleDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });
            });

            if(formType == formTypes.add){
                jobTitleService.getNextSequenceNumberByContractorId($rootScope.authData.contractorId).then(function(nextSequanceNumber){
                    $scope.jobTitleSequenceNumber = nextSequanceNumber;
                    $rootScope.$broadcast('hideBusyIndicator', 'JobTitleDetailsModalIndicator');
                });
            }

            var requests = {
                getRolesByContractorId: userService.getRolesByContractorId($rootScope.authData.contractorId),
                getStyrkTitles: styrkTitleService.getStyrkTitles()
            }

            if($scope.canAssignRoles){
                $q.all(requests).then(function(result){
                    $scope.roles = result.getRolesByContractorId;
                    $scope.styrkTitles = result.getStyrkTitles;
                    $scope.styrkTitleDropdownOptions.dataSource.read();

                    if(formType == formTypes.edit && jobTitleId){
                        $q.all([jobTitleService.getJobTitleById(jobTitleId)]).then(function (responses) {
                            $scope.jobTitle = responses[0];
                            initModal();
                        });
                    } else {
                        $scope.jobTitleRolesOptions.dataSource.read();
                    }
                    $rootScope.$broadcast('hideBusyIndicator', 'JobTitleDetailsModalIndicator');
                });
            } else {
                if(formType == formTypes.edit){
                    $scope.jobTitleRolesOptions.dataSource.read();
                    initModal();
                    $rootScope.$broadcast('hideBusyIndicator', 'JobTitleDetailsModalIndicator');
                }
            }
        }

        function initModal(){
            $scope.jobTitleName = $scope.jobTitle.name;
            $scope.jobTitleSequenceNumber = $scope.jobTitle.sequenceNumber;
            $scope.selectedStyrkTitle = $scope.jobTitle.styrkTitleId;

            $scope.jobTitleRolesOptions.dataSource.read();
            if( $scope.jobTitle && $scope.jobTitle.roles){
                var selectedRolesIds = _.map(_.sortBy($scope.jobTitle.roles, 'key'), 'id');
                $scope.selectedRoles = selectedRolesIds;
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'JobTitleDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (formType) {
                    case formTypes.add:
                    $scope.jobTitle.contractorId = $rootScope.authData.contractorId;
                    $scope.jobTitle.name = $scope.jobTitleName;
                    $scope.jobTitle.sequenceNumber = $scope.jobTitleSequenceNumber;
                    $scope.jobTitle.styrkTitleId = $scope.selectedStyrkTitle;
                    $scope.jobTitle.roles = $scope.selectedRoles ? getSelectedRoles() : null;

                    jobTitleService
                    .addJobTitle($scope.jobTitle)
                    .then(jobTitleId => close(jobTitleId))
                    .catch(() => close());
                        break;
                    case formTypes.edit:
                    $scope.jobTitle.name = $scope.jobTitleName;
                    $scope.jobTitle.sequenceNumber = $scope.jobTitleSequenceNumber;
                    $scope.jobTitle.styrkTitleId = $scope.selectedStyrkTitle;
                    $scope.jobTitle.roles = $scope.selectedRoles ? getSelectedRoles() : null;

                    jobTitleService
                    .updateJobTitle($scope.jobTitle)
                    .then(jobTitleId => close(jobTitleId))
                    .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function getSelectedRoles(){
            return $scope.roles.filter(j => $scope.selectedRoles.includes(j.id));
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'JobTitleDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
