(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LogActionPairsDetailsModalController', logActionPairsDetailsModalController);

    logActionPairsDetailsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'balanceTypes',
        'logActionService',
        'logActionPairsService',
        'formType',
        'formTypes',
        'logActionPairs'
    ];

    function logActionPairsDetailsModalController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        balanceTypes,
        logActionService,
        logActionPairsService,
        formType,
        formTypes,
        logActionPairs
    ) {

        $scope.logActions = [];
        $scope.externalCodes = [];
        $scope.closeModal = close;
        $scope.save = save;

        $scope.startActionOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.logActions);
                    }
                }
            }),
            dataValueField: 'logActionId',
            dataTextField: 'description',
            noDataTemplate: 'Ingen treff'
        };

        $scope.endActionOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.logActions);
                    }
                }
            }),
            dataValueField: 'logActionId',
            dataTextField: 'description',
            noDataTemplate: 'Ingen treff'
        };

        $scope.balanceTypeOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(balanceTypes);
                    }
                }
            }),
            dataValueField: 'value',
            dataTextField: 'name',
            noDataTemplate: 'Ingen treff'
        };

        $scope.externalCodeOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.externalCodes);
                    }
                }
            }),
            dataValueField: 'externalCodeId',
            dataTextField: 'value',
            noDataTemplate: 'Ingen treff'
        };


        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionPairsIndicator',
                destination: '#log-action-pairs-details-form',
                overlay: true
            });

            if(formType == formTypes.add){
                $scope.isVisibleInExport = true;
                $scope.isActive = true;
                $scope.isSeagoingService = true;
            }

            var logActionFilter = {
                contractorId: $scope.authData.contractorId,
                status: 0
            };

            if(formType == formTypes.edit && logActionPairs){
                logActionFilter.selectedLogActionIds = [];
                logActionFilter.selectedLogActionIds.push(logActionPairs.startLogActionId);
                logActionFilter.selectedLogActionIds.push(logActionPairs.endLogActionId);
            }

            $q.all([logActionService.getActiveLogActionsAsDropdownByFilter(logActionFilter),
                logActionPairsService.getExternalCodes($scope.authData.contractorId),
                logActionPairsService.getLogActionPairs($scope.authData.contractorId)]).then(function(results) {
                $scope.logActions = results[0];
                $scope.externalCodes = results[1];
                $scope.logActionPairs = results[2];
                $scope.startActionOptions.dataSource.read();
                $scope.endActionOptions.dataSource.read();
                $scope.balanceTypeOptions.dataSource.read();
                $scope.externalCodeOptions.dataSource.read();
                if(formType == formTypes.edit && logActionPairs){
                    initModal();
                }
            }).finally(() => {
                $rootScope.$broadcast('hideBusyIndicator', 'logActionPairsIndicator');
            });

        }

        function initModal(){
            $scope.selectedStartAction = logActionPairs.startLogAction;
            $scope.selectedEndAction = logActionPairs.endLogAction;
            $scope.selectedbalanceType = balanceTypes.find(b => b.value == logActionPairs.balanceType);
            $scope.selectedExternalCode = $scope.externalCodes.find(b => b.externalCodeId == logActionPairs.externalCodeId);
            $scope.isVisibleInExport = logActionPairs.isVisibleInExport;
            $scope.isWorkPeriod = logActionPairs.isWorkPeriod;
            $scope.isAbsence = logActionPairs.isAbsence;
            $scope.isRestTime = logActionPairs.isRestTime;
            $scope.multiplier = logActionPairs.multiplier;
            $scope.isActive = logActionPairs.isActive;
            $scope.isSeagoingService = logActionPairs.isVisibleInSeagoingService;
        }

        function save(){
            var logPairIsUnique = true;
            _.forEach($scope.logActionPairs, function (pair) {
               if($scope.selectedStartAction.logActionId == pair.startLogActionId
                && $scope.selectedEndAction.logActionId == pair.endLogActionId){
                    if(formType == formTypes.edit){
                        if(logActionPairs.logActionPairsId != pair.logActionPairsId){
                            logPairIsUnique = false;
                        }
                    } else {
                        logPairIsUnique = false;
                    }

               }
            });

            if(!logPairIsUnique){
                $scope.notUniqueErrorMsg = true;
                return;
            } else {
                $scope.notUniqueErrorMsg = false;
            }

            if(!$scope.validator.validate()){
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionPairsIndicator',
                destination: '#log-action-pairs-details-form',
                overlay: true
            });

           if(formType == formTypes.add){
                var logActionPairsObj = {
                    startLogActionId : $scope.selectedStartAction.logActionId,
                    endLogActionId : $scope.selectedEndAction.logActionId,
                    isVisibleInExport : $scope.isVisibleInExport,
                    isWorkPeriod : $scope.isWorkPeriod,
                    isAbsence : $scope.isAbsence,
                    isActive : $scope.isActive,
                    isRestTime : $scope.isRestTime,
                    balanceType : $scope.selectedbalanceType.value,
                    externalCodeId : $scope.selectedExternalCode ? $scope.selectedExternalCode.externalCodeId : null,
                    multiplier: $scope.multiplier,
                    isVisibleInSeagoingService :$scope.isSeagoingService
                }

                logActionPairsService.addLogActionPairs(logActionPairsObj).then(function(){
                    close(true);
                }).finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'logActionPairsIndicator');
                });

           } else if(formType == formTypes.edit) {
                logActionPairs.startLogActionId = $scope.selectedStartAction.logActionId;
                logActionPairs.endLogActionId = $scope.selectedEndAction.logActionId;
                logActionPairs.isVisibleInExport = $scope.isVisibleInExport;
                logActionPairs.isWorkPeriod = $scope.isWorkPeriod;
                logActionPairs.isAbsence = $scope.isAbsence;
                logActionPairs.isActive = $scope.isActive;
                logActionPairs.isRestTime = $scope.isRestTime;
                logActionPairs.balanceType = $scope.selectedbalanceType.value;
                logActionPairs.externalCodeId = $scope.selectedExternalCode ? $scope.selectedExternalCode.externalCodeId : null;
                logActionPairs.multiplier = $scope.multiplier;
                logActionPairs.isVisibleInSeagoingService = $scope.isSeagoingService;


                logActionPairsService.updateLogActionPairs(logActionPairs).then(function(){
                    close(true);
                }).finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'logActionPairsIndicator');
                });
           }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'landBaseDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
