(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('UserBalanceController', UserBalanceController);

    UserBalanceController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        'timeBankService',
        '$translate',
        'dateUtility',
        '$kWindow',
        'formTypes',
        'kendoUtility'
    ];

    function UserBalanceController($rootScope, $scope, $state, timeBankService, $translate, dateUtility, $kWindow, formTypes, kendoUtility) {
        $scope.userBalances = [];
        $scope.openUserBalanceDetailsModal = openUserBalanceDetailsModal;
        $scope.addUserBalance = addUserBalance;
        $scope.hasCreationPermisson = Boolean($rootScope.authData.isSuperuser || $scope.hasPermission('TimebankAdmin'));

        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];

        $scope.$on('langChanged', (e, code) => {
            $scope.userBalanceGridOptions = userBalanceGridOptions(code);
            var grid = kendoUtility.createWidget('kendoGrid', $scope.userBalanceGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#user-balance-grid')]);
            langId = code;
        });

        $scope.$on('timeBankFilterChanged', (event, filter, isUpdate, state) => {
            $scope.filter = filter;
            if(isUpdate && (state == 'userBalance' || (!state && $state.current.name == 'timeBank.userBalance')))
            {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'timebankUserBalanceBusyIndicator',
                    destination: '#timebank-user-balance',
                    overlay: true,
                });
                loadUserBalances(filter);
            }
        });

        var userBalanceGridOptions = (langId) => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.userBalances),
                },
            }),
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'userBalanceId',
                    hidden: true,
                },
                {
                    field: 'userDisplayName',
                    title: $translate.instant('TIMEBANK_USER_BALANCE_USER', null, null, langId),
                },
                {
                    field: 'balanceDate',
                    title: $translate.instant('TIMEBANK_USER_BALANCE_DAY', null, null, langId),
                    template: (dataItem) => {
                        return dateUtility.formatDate(dataItem.balanceDate);
                    },
                },
                {
                    field: 'currentBalance',
                    title: $translate.instant('TIMEBANK_USER_BALANCE_CURRENT_BALANCE', null, null, langId),
                    format: '{0:n2}',
                },
                {
                    field: 'balance',
                    title: $translate.instant('TIMEBANK_USER_BALANCE_BALANCE', null, null, langId),
                    format: '{0:n2}',
                },
                {
                    field: 'comment',
                    title: $translate.instant('TIMEBANK_USER_BALANCE_COMMENT', null, null, langId),
                },
                {
                    command: [
                        {
                            name: 'editUserBalance',
                            click: (e) => editUserBalance(e),
                            template: '<a class="k-grid-editUserBalance"><i class="fas fa-edit"></i></a>',
                        },
                    ],
                    width: 100,
                    hidden: false,
                },
            ],
            sortable: true,
            resizable: true,
            filterable: true,
        });
        $scope.userBalanceGridOptions = userBalanceGridOptions($translate.use());

        initController();
        function initController() {}

        function loadUserBalances(filter) {
            timeBankService
                .getUserBalances(filter)
                .then((result) => {
                    $scope.userBalances = result;
                    extendUserBalance();
                    $scope.userBalanceGridOptions.dataSource.read();
                })
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'timebankUserBalanceBusyIndicator');
                });
        }

        function extendUserBalance() {
            _.forEach($scope.userBalances, (userBalance) => {
                if (userBalance.user) {
                    userBalance.userDisplayName = userBalance.user.displayName;
                }
            });
        }

        function addUserBalance() {
            var windowInstance = openUserBalanceDetailsModal(formTypes.add);

            windowInstance.result.then(() => {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'timebankUserBalanceBusyIndicator',
                    destination: '#timebank-user-balance',
                    overlay: true,
                });

                loadUserBalances($scope.filter);
            });
        }

        function editUserBalance(event) {
            var windowInstance = openUserBalanceDetailsModal(formTypes.edit, getUserBalanceFromGrid(event));

            windowInstance.result.then((result) => {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'timebankUserBalanceBusyIndicator',
                    destination: '#timebank-user-balance',
                    overlay: true,
                });

                loadUserBalances($scope.filter);
            });
        }

        function getUserBalanceFromGrid(event) {
            var row = angular.element(event.currentTarget).closest('tr');
            return $('#user-balance-grid').data('kendoGrid').dataItem(row).toJSON();
        }

        function openUserBalanceDetailsModal(formType, userBalance) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('TIMEBANK_USER_BALANCE_MODAL_TITLE'),
                    resizable: true,
                    visible: false,
                },
                templateUrl: 'app/timebank/userBalance/user-balance-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'userBalanceDetailsController',
                resolve: {
                    currentFormType: () => formType,
                    userBalance: () => userBalance,
                    filter: () => $scope.filter,
                },
            });
        }
    }
})();
