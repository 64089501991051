(function () {
    angular.module('NaviaqWebApp').factory('objectPropertyNameHandlerService', objectPropertyNameHandlerService);

    objectPropertyNameHandlerService.$inject = [];

    function objectPropertyNameHandlerService() {
        return {
            setCamelCaseToPascalCase,
            setPascalCaseToCamelCase,
            renameProperty
        };

        function setCamelCaseToPascalCase(obj) {
            if (typeof obj !== 'object' || obj === null) {
                return obj;
            }

            if (Array.isArray(obj)) {
                return obj.map(setCamelCaseToPascalCase);
            }

            const pascalCase = (str) => str.replace(/(\w)(\w*)/g, (_, firstChar, restOfString) => firstChar.toUpperCase() + restOfString);

            const newObj = {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    let newKey = key;
                    if (/^[a-z][a-zA-Z]*$/.test(key)) {
                        newKey = pascalCase(key);
                    }
                    newObj[newKey] = setCamelCaseToPascalCase(obj[key]);
                }
            }

            return newObj;
        }

        function setPascalCaseToCamelCase(obj) {
            if (typeof obj !== 'object' || obj === null) {
                return obj;
            }

            if (Array.isArray(obj)) {
                return obj.map(setPascalCaseToCamelCase);
            }

            const camelCase = (str) => str.replace(/(\w)(\w*)/g, (_, firstChar, restOfString) => firstChar.toLowerCase() + restOfString);

            const newObj = {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    let newKey = key;
                    newKey = camelCase(key)
                    newObj[newKey] = setPascalCaseToCamelCase(obj[key]);
                }
            }

            return newObj;
        }

        function renameProperty(object, oldPropertyName, newPropertyName) {
            Object.defineProperty(
                object,
                newPropertyName,
                Object.getOwnPropertyDescriptor(object, oldPropertyName)
            );
            delete object[oldPropertyName];
        }
    }
})();
