(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('unitsBatchDetailsModalAdministrationController', unitsBatchDetailsModalAdministrationController);

    unitsBatchDetailsModalAdministrationController.$inject = [
        '$translate',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'currentFormType',
        'formTypes',
        'unitService',
        'locations',
        'unitId',
        'unitBatch',
        'mediaService',
        '$q'
    ];

    function unitsBatchDetailsModalAdministrationController(
        $translate,
        $rootScope,
        $scope,
        $windowInstance,
        currentFormType,
        formTypes,
        unitService,
        locations,
        unitId,
        unitBatch,
        mediaService,
        $q
    ) {
        $scope.unitBatch = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        $scope.locationOptions = {
            buttonClasses: 'k-button'
        };

        $scope.locationTranslations = {
            checkAll: $translate.instant('G_SELECT_ALL'),
            uncheckAll: $translate.instant('G_UNSELECT_ALL'),
            dynamicButtonTextSuffix: $translate.instant('G_SELECTED')
        };

        $scope.selectedLocations = [];

        $scope.locationSelectorSource = locations.map(function (location) {
            return {
                id: location.locationNumber,
                label: location.name
            };
        });

        $scope.certificate = null;

        $scope.fileUploadOptions = {
            multiple: false,
            localization: {
                select: $translate.instant('G_SELECT')
            }
        };
        initController();

        function initController() {
            if (currentFormType === formTypes.edit && unitBatch) {
                $scope.unitBatch = unitBatch;
                _.forEach($scope.unitBatch.locations, function (location) {
                    $scope.selectedLocations.push(location.locationNumber)
                });
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'unitDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        uploadFile().then(function(mediaId){
                            $scope.unitBatch.unitId = unitId
                            var selectedLocations = []
                            _.forEach($scope.selectedLocations, function (selectedLocation) {
                                var location = locations.find(obj => {
                                    return obj.locationNumber === selectedLocation.id
                                });
                                selectedLocations.push(location)
                            });

                            $scope.unitBatch.locations = selectedLocations

                            if(mediaId){
                                $scope.unitBatch.mediaId = mediaId
                            }

                            unitService.addUnitBatch($scope.unitBatch)
                                .then(unitBatch => close($scope.unitBatch))
                                .catch(() => close());
                        });
                        break;
                    case formTypes.edit:
                        uploadFile().then(function(mediaId){
                            var selectedLocations = []
                            _.forEach($scope.selectedLocations, function (selectedLocation) {
                                var location = locations.find(obj => {
                                    return obj.locationNumber === selectedLocation.id
                                });
                                selectedLocations.push(location)
                            });

                            $scope.unitBatch.locations = selectedLocations

                            if(mediaId){
                                $scope.unitBatch.mediaId = mediaId;
                            }

                            unitService.updateUnitBatch($scope.unitBatch)
                                .then(unitBatch => close($scope.unitBatch))
                                .catch(() => close());
                        });
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        $scope.uploadFile = function() {
            $('#file-upload').click();
        }

        function uploadFile(){
            const deferred = $q.defer();
            if($scope.fileArray && $scope.fileArray[0] )
            {
                var file = $scope.fileArray[0];
                mediaService.asyncEncodeImageFile(file)
                    .then(function (encodedImage) {
                        mediaService.uploadFileWebApi(file.name, encodedImage)
                            .then(function (newMediaId) {
                                deferred.resolve(newMediaId);
                            });
                    }, function (error){
                        deferred.reject(error);
                    });
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
