(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationInfoMarinogramController', LocationInfoMarinogramController);

    LocationInfoMarinogramController.$inject = ['$q', '$scope', '$rootScope', '$stateParams', 'locationService', 'mapUtility', '$translate'];

    function LocationInfoMarinogramController($q, $scope, $rootScope, $stateParams, locationService, mapUtility, $translate) {
        $scope.imageUrl = '';
        $scope.hasImage = false;

        initController();

        function initController() {
            if(!locationService.isDefaultLocation($stateParams.LocationNumber)){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'locationMarinogramIndicator',
                    destination: '#right-pane',
                    overlay: false
                });

                let requests = {};
                requests.getLocationBaseByLocationNumber = locationService.getLocationBaseByLocationNumber($stateParams.LocationNumber);
                $q.all(requests)
                    .then(data => {
                        var location = data.getLocationBaseByLocationNumber;
                        if (location) {
                            var point = mapUtility.convertToWGS84(location.x, location.y);
                            var translateKey = $translate.use();
                            var img = `https://jtimeseries.k8s.met.no/jtimeseries-webservices/marinogram?format=svg&latitude=${point.lat}&longitude=${point.lng}&waterTemperature=true&airTemperature=true&dewpointTemperature=true&pressure=true&waveHeight=true&waveDirection=true¤tDirection=true¤tSpeed=true&windDirection=true&windSpeed=true&timezone=Europe%2FOslo&language=${translateKey}`;

                            loadMarinogramImage(img);
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'locationMarinogramIndicator');
                    });
            }
        }

        function loadMarinogramImage(url) {
            var img = new Image();
            img.onerror = img.onabort = function () {
                $scope.hasImage = false;
                $rootScope.$broadcast('hideBusyIndicator', 'locationMarinogramIndicator');
            };
            img.onload = function () {
                $scope.hasImage = true;
                $scope.imageUrl = url;
                $rootScope.$broadcast('hideBusyIndicator', 'locationMarinogramIndicator');
            };
            img.src = url;
        }
    }
})();
