(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('finalReportLegalPageFroyDirective', finalReportLegalPageFroyDirective);

    finalReportLegalPageFroyDirective.$inject = [];
    function finalReportLegalPageFroyDirective() {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/report/finalReportComponents/final-report-legal-page-froy.html',
            scope: {
                data: '=data'
            },
            link: function (scope, element, attrs) {
            },
        };
    }
})();
