(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OperationLogController', OperationLogController);

    OperationLogController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$kWindow',
        '$translate',
        'systemUserName',
        'authService',
        'departmentService',
        'logService',
        'formTypes',
        'kendoUtility',
        'logActionStatus',
        'customerService',
        'OrderStatus',
        'logActionTypes',
        'logActionService'
    ];

    function OperationLogController(
        $rootScope,
        $scope,
        $q,
        $kWindow,
        $translate,
        systemUserName,
        authService,
        departmentService,
        logService,
        formTypes,
        kendoUtility,
        logActionStatus,
        customerService,
        OrderStatus,
        logActionTypes,
        logActionService
    ) {
        let authData = authService.getAuthData(),
            contractorId = authData.contractorId,
            uiFilterDateFromLoaded = $q.defer(),
            uiFilterDateToLoaded = $q.defer(),
            uiGridLoaded = $q.defer();

        kendo.culture("no-NO");

        $scope.logActions = [];
        $scope.vessels = [];
        $scope.logs = [];
        $scope.dateFrom = new Date();
        $scope.dateFrom.setDate($scope.dateFrom.getDate() - 7);
        $scope.dateTo = new Date();
        $scope.editDisabled = true;
        $scope.masseditDisabled = true;

        //Grid resize parameters
        var operationLogOverviewHeight;

        // set the default filter
        if (authData.activeDepartment && authData.activeDepartment.departmentId) {
            $scope.vesselFilter = [{ departmentId: authData.activeDepartment.departmentId }];
        }

        $scope.exportToExcel = exportToExcel;

        function exportToExcel() {
            $scope.operationLogOverviewGrid.saveAsExcel();
        }

        $scope.logActionFilterOptions = {
            dataTextField: 'description',
            dataValueField: 'logActionId',
            optionLabel: "Vis alle",
            autoBind: false,
            dataSource: {
                transport: {
                    read: function (e) {
                        e.success($scope.logActions.filter(l => l.status === logActionStatus.active));
                    }
                }
            }
        };

        $scope.operationLogOverviewGridOptions = {
            height: kendoUtility.calculateRemainingSpace(-40),
            dataSource: {
                transport: {
                    read: function (e) {
                        return e.success($scope.logs);
                    }
                }
            },
            dataBinding: function () {
                operationLogOverviewHeight = $('#operation-log-overview').height();
            },
            dataBound: function (e) {
                e.sender.element.find('.k-hierarchy-cell a').hide();

                var dataItems = e.sender.dataSource.data();
                for (var i = 0; i < dataItems.length; ++i) {
                    var itemRow = e.sender.element.find('[data-event-id="' + dataItems[i].operationLogId + '"]').closest('tr');

                    var invoicedStatuses = [OrderStatus.Monthlyinvoice, OrderStatus.InternalInvoice, OrderStatus.Invoiced]
                    var orderStatusInvoiced = dataItems[i].order && invoicedStatuses.includes(dataItems[i].order.orderStatus) ? true : false;
                    var orderStatusReadyForInvoicing = dataItems[i].order && dataItems[i].order.orderStatus == OrderStatus.ReadyForInvoicing ? true : false;
                    var editableAfterDueDate = dataItems[i].editableAfterDueDate !== null && dataItems[i].editableAfterDueDate === true;

                    if (orderStatusInvoiced) {
                        itemRow.addClass('orderIsInvoiced');
                    } else if (orderStatusReadyForInvoicing) {
                        itemRow.addClass('orderIsReadyForInvoicing');
                    } else if (dataItems[i].hasChecklist) {
                        itemRow.find('.k-hierarchy-cell a').show();
                        if (dataItems[i].isChecklistExpired) {
                            itemRow.addClass('checklistExpired');
                        } else if (dataItems[i].isChecklistCompleted) {
                            itemRow.addClass('checklistCompleted');
                        } else if (dataItems[i].isChecklistExpired && !dataItems[i].isChecklistCompleted && !editableAfterDueDate && dataItems[i].hasMissingChecklistItems) {
                            itemRow.addClass('checklistMissing');
                        } else if (dataItems[i].hasStartedChecklist) {
                            itemRow.addClass('checklistInProgress');
                        }
                    } else if (dataItems[i].isChecklistExpired && !dataItems[i].isChecklistCompleted && !editableAfterDueDate && dataItems[i].logAction && dataItems[i].logAction.logActionType != logActionTypes.operationLog
                        && dataItems[i].logAction.logActionType != logActionTypes.operationAndVessel && (orderStatusInvoiced || orderStatusReadyForInvoicing)) {
                        itemRow.addClass('expired');
                    }
                }

                //Resize k-grid-content accordingly
                if (this.content.height() > operationLogOverviewHeight - this.content.offset().top) {
                    var newContentHeight = operationLogOverviewHeight - this.content.offset().top,
                        rowHeight = this.content.find('tr').height();

                    var adjustedHeight = Math.floor(newContentHeight / rowHeight) * rowHeight;

                    this.content.height(adjustedHeight);
                }
            },
            autoBind: false,
            animation: false,
            resizable: true,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            selectable: "multiple",
            change: onOperationLogOverviewGridChange,
            filterable: {
                name: "FilterMenu",
                extra: true
            },
            excel: {
                fileName: 'Hendelser.xlsx',
                filterable: true
            },
            detailInit: function (e) {
                var detailRow = e.detailRow,
                    items = e.data.logAction.checkListItems,
                    grid = detailRow.find(".checklist-grid"),
                    data = grid.data();

                data.kendoGrid.dataSource.data(items);
            },
            detailTemplate: kendo.template($("#operationLog-checklist-template").html()),
            columns: [
                {
                    field: 'doneOn',
                    title: 'Dato/tid',
                    format: "{0:dd.MM.yyyy HH:mm:ss}",
                    type: 'date',
                    width: 125,
                    template: '<span data-event-id="#:operationLogId#">#: kendo.toString(doneOn, "dd.MM.yyyy HH:mm:ss")#<span>'
                }, {
                    field: 'dueDate',
                    title: 'Frist',
                    format: "{0:dd.MM.yyyy HH:mm:ss}",
                    type: 'date',
                    width: 125
                }, {
                    field: 'orderDisplayName',
                    title: 'Oppdrag',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.externalOrderId }}\'">{{dataItem.externalOrderId}}</span>'
                }, {
                    field: 'orderLocation',
                    title: 'Lokalitet',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.orderLocationName }}\'">{{dataItem.orderLocationName}}</span>'
                }, {
                    field: 'orderCustomerName',
                    title: 'Kunde',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.orderCustomerName }}\'">{{dataItem.orderCustomerName}}</span>'
                },  {
                    field: 'vesselName',
                    title: 'Avdeling',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.vesselName }}\'">{{dataItem.vesselName}}</span>'
                }, {
                    field: 'logActionName',
                    title: 'Handling',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.logActionName }}\'">{{dataItem.logActionName}}</span>'
                }, {
                    field: 'description',
                    title: 'Beskrivelse',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.description }}\'">{{dataItem.description}}</span>'
                }, {
                    field: 'comment',
                    title: 'Kommentar',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.comment }}\'">{{dataItem.comment}}</span>'
                }, {
                    field: 'itemDescription',
                    title: 'Utstyr',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.itemDescription }}\'">{{dataItem.itemDescription}}</span>'
                }, {
                    field: 'assignedUserDisplayName',
                    title: 'Person',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.assignedUserDisplayName }}\'">{{dataItem.assignedUserDisplayName}}</span>'
                }, {
                    field: 'statusName',
                    title: 'Status',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.statusName }}\'">{{dataItem.statusName}}</span>',
                    width: 80
                }, {
                    field: 'createdBy',
                    title: 'Opprettet av',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.createdBy }}\'">{{dataItem.createdBy}}</span>'
                }, {
                    field: 'editedBy',
                    title: 'Endret av',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.editedBy }}\'">{{dataItem.editedBy}}</span>'
                }, {
                    field: 'logAction.logActionType',
                    title: 'Logg',
                    template: '#: logAction.logActionType != null? logAction.logActionType.asLogActionType() : ""#',
                    filterable: {
                        multi: true,
                        messages: {
                            checkAll: "Vis alle",
                            filter: "Filtrer",
                            clear: "Fjern",
                            selectedItemsFormat: ""
                        },
                        itemTemplate: function (e) {
                            if (e.field === "all") {
                                return "<span><input type='checkbox' name='" + e.field + "' value='' /> <label><span>Vis alle</span></label></span> <br />";
                            }

                            return "<span><input type='checkbox' id='" + e.field + "_#= data.value #'  name='" + e.field + "' value='#= data.value #'/> <label for='" + e.field + "_#= data.value #'><span>#= data.name #</span></label></span> <br />";
                        },
                        dataSource: new kendo.data.DataSource({
                            transport: {
                                read: function (e) {
                                    e.success(logService.getTypes());
                                }
                            }
                        })
                    }
                },
                {
                    field: 'checklistProgress',
                    title: 'Sjekkliste',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.checklistProgress }}\'">{{dataItem.checklistProgress}}</span>',
                    width: 100
                }
            ]
        };

        $scope.checklistGridOption = {
            columns: [{
                width: 50,
                title: 'Utfør',
                template: '<input type="radio" disabled="disabled" #: checkListValue != null && checkListValue.boolValue != null && checkListValue.boolValue == true? checked="checked" : ""#  />'
            },
            {
                width: 60,
                title: 'Ikke OK',
                template: '<input type="radio" disabled="disabled" #: checkListValue != null && checkListValue.notOk != null && checkListValue.notOk == true? checked="checked" : ""#  />'
            },
            {
                width: 80,
                title: 'Ikke aktuelt',
                template: '<input type="radio" disabled="disabled" #: checkListValue != null && checkListValue.notApplicable != null && checkListValue.notApplicable == true? checked="checked" : ""#  />'
            }, {
                field: 'description',
                title: 'Sjekkpunkt',
                template: '<span kendo-tooltip k-content="\'#: description != null? description : ""#\'">#: description != null? description : ""#</span>'
            }, {
                field: 'checkListValue',
                title: 'Verdi',
                template: '#: checkListValue != null && checkListValue.numericValue != null ? checkListValue.numericValue : ""#'
            }, {
                field: 'measurementUnitType',
                title: 'Enhet',
                template: '<span kendo-tooltip k-content="\'#: measurementUnitType != null? measurementUnitType.measurementUnitDescription : ""#\'">#: measurementUnitType != null? measurementUnitType.measurementUnitDescription : ""#</span>'
            }, {
                field: 'checkListValue',
                title: 'Kommentar',
                template: '<span kendo-tooltip k-content="\'#: checkListValue != null && checkListValue.stringValue != null ? checkListValue.stringValue : ""#\'">#: checkListValue != null && checkListValue.stringValue != null ? checkListValue.stringValue : ""#</span>'
            }, {
                field: 'checkListValue',
                title: 'Sist endret',
                template: '#: checkListValue != null && checkListValue.editedOn != null ? kendo.toString(new Date(checkListValue.editedOn), "dd/MM/yyyy HH:mm:ss") : ""#'
            }, {
                field: 'checkListValue',
                title: 'Endret av',
                template: '<span kendo-tooltip k-content="\'#: checkListValue != null && checkListValue.editedBy != null ? checkListValue.editedBy : ""#\'">#: checkListValue != null && checkListValue.editedBy != null ? checkListValue.editedBy : ""#</span>'
            }
            ]
        };

        $scope.vesselFilterRequest = vesselFilterRequest;
        $scope.logActionFilterRequest = logActionFilterRequest;
        $scope.operationLogRequest = operationLogRequest;
        $scope.getFilterData = getFilterData;
        $scope.getOperationLogs = getOperationLogs;
        $scope.registerLog = registerLog;
        $scope.editLog = editLog;
        $scope.massEditLog = massEditLog;
        $scope.selectAllVessel = selectAllVessel;
        $scope.getOperationLogStatusText = getOperationLogStatusText;
        $scope.getOperationLogStatusValues = getOperationLogStatusValues;

        function vesselFilterRequest() {
            var deferred = $q.defer();

            departmentService.getDepartmentsWebapi(contractorId, false, true).then(function (departments) {
                if (departments && departments.length > 0) {
                    $scope.vessels = _.sortBy(departments, 'name');

                    // reload the default vessel and set selected
                    if (authData.activeDepartment && authData.activeDepartment.departmentId) {
                        $scope.vesselFilter = [_.find($scope.vessels, function (o) { return o.departmentId === authData.activeDepartment.departmentId; })];
                    }

                    deferred.resolve(_.sortBy(departments, 'name'));
                } else {
                    $scope.vessels = [];
                    deferred.resolve([]);
                }
            });

            return deferred.promise;
        }

        function logActionFilterRequest() {
            var deferred = $q.defer();

            logActionService.getActiveLogActionsAsDropdownByFilter({contractorId: contractorId, includeConglomerateLogActions: true}).then((actions) => {
                if (actions && actions.length > 0) {
                    deferred.resolve(actions);
                } else {
                    deferred.resolve([]);
                }

            });

            return deferred.promise;
        }

        function operationLogRequest() {
            var deferred = $q.defer();

            var filter = getFilterData();

            logService.getOperationLogsByFilterWebAPI(filter, true)
                .then((_logs) => {
                    if (_logs && _logs.length > 0) {
                        deferred.resolve(_logs);
                    } else {
                        deferred.resolve([]);
                    }
            });

            return deferred.promise;
        }

        function getFilterData() {
            var _departmentIds = _.map($scope.vesselFilter, function (o) { return o.departmentId; }),
            _fromDate = $scope.dateFrom,
            _toDate = $scope.dateTo,
            _logActionId = $scope.logActionFilterElement.value(),
            _userId = $scope.myTasks ? authData.userId : null;

            //Guard against uninitialized undefined value
            if (_logActionId.indexOf('undefined') !== -1) {
                _logActionId = '';
            }

            return {
                contractorId: contractorId,
                departmentIds: _departmentIds,
                fromDate: moment(_fromDate).format('YYYY-MM-DDT00:00:00'),
                toDate: moment(_toDate).format('YYYY-MM-DDT23:59:59'),
                logActionId: _logActionId,
                userId: _userId
            }
        }

        function getOperationLogs(e) {
            if ($scope.vesselFilter.length === 0) {
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'operationLogOverview',
                destination: '#operation-log-overview'
            });

            var currentDate = new Date();
            if (e === 'lastWeek') {
                var first = currentDate.getDate() - currentDate.getDay() - 6;
                var last = first + 6;

                $scope.operationLogDateTo.value(new Date(currentDate.setDate(last)));
                $scope.operationLogDateFrom.value(new Date(currentDate.setDate(first)));
            }
            if (e === 'thisWeek') {
                var firstDay = currentDate.getDate() - currentDate.getDay() + 1;
                var lastDay = firstDay + 6;


                $scope.operationLogDateFrom.value(new Date(currentDate.setDate(firstDay)));
                $scope.operationLogDateTo.value(new Date(currentDate.setDate(lastDay)));
            }

            var requests = {
                operationLogs: operationLogRequest(),
                getCustomersByContractorId: customerService.getCustomersByContractorId($rootScope.authData.contractorId)
            };

            $q.all(requests).then(function (result) {
                var logs = result.operationLogs;
                $scope.customers = result.getCustomersByContractorId;
                for (var i = 0; i < logs.length; i++) {
                    extendLog(logs[i]);
                }
                $scope.logs = logs;
                $scope.operationLogOverviewGrid.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'operationLogOverview');
            });
        }

        function registerLog() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('OPERATIONLOG_NEW'),
                    resizable: true,
                    width: 1200,
                    visible: false,
                    appendTo: "#operation-log-dialog"
                },
                templateUrl: 'app/log/operation-log-extdetails-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationLogExtDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.add;
                    },
                    operationLog: function () {
                        return null;
                    },
                    departmentId: function () {
                        return;
                    },
                    relatedItemId: function () {
                        return null;
                    },
                }
            });


            windowInstance.result.then(function (result) {
                getOperationLogs();
            });

        }

        function editLog() {
            if (!$scope.operationLogOverviewGrid) {
                return;
            }

            if ($scope.operationLogOverviewGrid.select().length !== 1) {
                return;
            }

            var log = $scope.operationLogOverviewGrid.dataItem($scope.operationLogOverviewGrid.select());


            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('OPERATIONLOG_EDIT'),
                    resizable: true,
                    width: 1200,
                    visible: false,
                    appendTo: "#operation-log-dialog"
                },
                templateUrl: 'app/log/operation-log-extdetails-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationLogExtDetailsModalController',
                resolve: {
                    departmentId: function () {
                        return log.departmentId;
                    },
                    formType: function () {
                        return $scope.editDisabled ? formTypes.readOnly : formTypes.edit;
                    },
                    relatedItemId: function () {
                        return log.itemId;
                    },
                    operationLog: function () {
                        return log;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result)
                    getOperationLogs();
            });
        }

        function massEditLog() {
            if (!$scope.operationLogOverviewGrid) {
                return;
            }

            if ($scope.operationLogOverviewGrid.select().length < 2) {
                return;
            }

            var selectedItems = [];
            _.each($scope.operationLogOverviewGrid.select(), (item) => {
                selectedItems.push($scope.operationLogOverviewGrid.dataItem(item));
            });

            var modal = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('OPERATIONLOG_EDIT'),
                    resizable: true,
                    width: 550,
                    visible: false,
                    appendTo: "#operation-log-dialog"
                },
                templateUrl: 'app/log/operation-log-massedit.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationLogMassEditModalController',
                resolve: {
                    orders: () => {
                        return [];
                    },
                    actions: () => {
                        return $scope.logActions;
                    },
                    vessels: () => {
                        return $scope.vessels;
                    },
                    logs: () => {
                        return selectedItems;
                    }
                }
            });

            modal.result.then(function(response) {
                if (response) {
                    getOperationLogs();
                }
            });
        }

        function selectAllVessel() {
            $scope.vesselFilter = $scope.vessels;

            var myElement = document.getElementById('select-vessel-filter'),
                angularElement = angular.element(myElement);

            angularElement.focus();
        }

        initController();

        function initController() {
            var vesselRequest = $scope.vesselFilterRequest(),
                logActionRequest = $scope.logActionFilterRequest();

            kendoUtility.calculateAndSetMaxWidth($('#operation-log-overview'));

            $scope.$on("kendoWidgetCreated", function (event, widget) {
                if (widget && widget.element && widget.element[0]) {
                    switch (widget.element[0].id) {
                        case "operationlog-date-from":
                            uiFilterDateFromLoaded.resolve();
                            break;
                        case "operationlog-date-to":
                            uiFilterDateToLoaded.resolve();
                            break;
                        case "operationLogOverviewGrid":
                            uiGridLoaded.resolve();
                            break;
                    }
                }
            });

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'operationLogOverview',
                destination: '#operation-log-overview'
            });

            $q.all([vesselRequest, logActionRequest]).then(function (result) {
                $scope.vessels = result[0];
                $scope.logActions = result[1];

                if ($scope.vessels && $scope.vessels.length > 0) {
                    $scope.vesselFilter = [$scope.vessels[0]];
                }

                $rootScope.$broadcast('hideBusyIndicator', 'operationLogOverview');
            });
        }

        function onOperationLogOverviewGridChange() {
            var selectedRows = this.select();
            if (selectedRows.length === 1) {
                $scope.$apply(function () {
                    $scope.editDisabled =
                        !(($(selectedRows[0]).data().$scope.dataItem.editableAfterDueDate == null
                            || $(selectedRows[0]).data().$scope.dataItem.editableAfterDueDate)
                            || moment($(selectedRows[0]).data().$scope.dataItem.dueDate) > moment())
                        || !($(selectedRows[0]).data().$scope.dataItem.order == null
                            || $(selectedRows[0]).data().$scope.dataItem.order.orderStatus < 2);
                    $scope.notEditable = !(($(selectedRows[0]).data().$scope.dataItem.editableAfterDueDate == null
                        || $(selectedRows[0]).data().$scope.dataItem.editableAfterDueDate)
                        || moment($(selectedRows[0]).data().$scope.dataItem.dueDate) > moment());
                    $scope.relatedOrderClosed = !($(selectedRows[0]).data().$scope.dataItem.order == null
                        || $(selectedRows[0]).data().$scope.dataItem.order.orderStatus < 2);
                    $scope.masseditDisabled = true;
                });
            } else if (selectedRows.length > 1) {
                $scope.$apply(function () {

                    $scope.editDisabled = true;
                    $scope.masseditDisabled = !isMassEditable();
                });
            } else {
                $scope.$apply(function () {
                    $scope.editDisabled = true;
                    $scope.masseditDisabled = true;
                });
            }

            function isMassEditable() {
                if (selectedRows.length < 2) {
                    return false;
                }

                var firstDepartmentId = $(selectedRows[0]).data().$scope.dataItem.departmentId,
                    different = _.find(selectedRows, (o) => {
                        if ($(o).data() && $(o).data().$scope && $(o).data().$scope.dataItem) {
                            return $(o).data().$scope.dataItem.departmentId !== firstDepartmentId;
                        }

                    });

                return !different;
            }
        }

        function extendLog(log) {
            var doneOn = moment(log.doneOn);
            doneOn = doneOn.hours(doneOn.hours());
            log.doneOn = doneOn.toDate();

            var opLogDate = doneOn.toDate();
            var dueDate = log.dueDate ? moment(log.dueDate) : moment(moment(opLogDate.setDate(opLogDate.getDate() + 28)).format('YYYY-MM-DDTHH:mm:ss'));
            dueDate = dueDate.hours(dueDate.hours());
            log.dueDate = dueDate.toDate();

            if (log.hasChecklist && log.logAction.checkListItems) {
                log.logAction.checkListItems = _.orderBy(log.logAction.checkListItems.filter(x => x.isActive), ['description'], ['asc']);

                _.forEach(log.logAction.checkListItems, (checkListItem) => {
                    checkListItem.checkListValue = _.find(checkListItem.checkListValues, (v) => v.operationLogId == log.operationLogId);
                });
            }

            log.assignedUserDisplayName = log.assignedUser ? log.assignedUser.length > 0 ? log.assignedUser[0].displayName : '' : '';

            log.vesselName = log.departmentId ? log.departmentId.asVesselName() : '';
            log.logActionName = log.logActionId ? log.logActionId.asLogAction() : '';

            //expired status doesn't exist, so we are setting this in the frontend.
            log.status = log.isChecklistExpired ? -1 : log.status;
            log.statusName = log.status != undefined ? getOperationLogStatusText(log.status) : '';

            log.itemDescription = log.item ? log.item.description : '';
            log.logActionTypeName = log.logAction ? log.logAction.logActionType.asLogActionType() : '';
            log.orderDisplayName = log.order != null ? log.order.externalOrderId + '-' +
                (log.order.location != null ? log.order.location.name : '') : '';

            if (log.order) {
                var customer = log.order.customerId ? $scope.customers.find(c => c.customerId == log.order.customerId) : null;
                log.orderCustomerName = customer ? customer.name : '';
                log.orderLocationName = log.order.location ? log.order.location.locationNumber + ' - ' + log.order.location.name : '';
            } else {
                log.orderCustomerName = '';
                log.orderLocationName = '';
            }

            if (log.createdBy === systemUserName) {
                log.createdBy = 'Systemgenerert';
            }

            if (log.editedBy === systemUserName) {
                log.editedBy = 'Systemgenerert';
            }
        }

        function getOperationLogStatusText(orderStatus) {
            return logService.getOperationLogStatusText(orderStatus);
        }

        function getOperationLogStatusValues() {
            return logService.getOperationLogStatusValues();
        }

        String.prototype.asVesselName = function () {
            var searchedId = this,
                vessel = _.find($scope.vessels, function (o) { return o.departmentId === searchedId; });

            if (vessel) {
                return vessel.name;
            }

            return this;
        };

        String.prototype.asLogAction = function () {
            var searchedId = this,
                logAction = _.find($scope.logActions, function (o) { return o.logActionId === searchedId; });

            if (logAction) {
                return logAction.description;
            }

            return this;
        };

        Number.prototype.asLogActionType = function () {
            return logService.getType(this);
        };
    }
})();
