(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CaseReportController', CaseReportController);

    CaseReportController.$inject = [
        '$q',
        '$state',
        '$scope',
        '$rootScope',
        '$stateParams',
        'workflowStatuses',
        'severityList',
        'dateUtility',
        'mediaService',
        'caseService'
    ];

    function CaseReportController(
        $q,
        $state,
        $scope,
        $rootScope,
        $stateParams,
        workflowStatuses,
        severityList,
        dateUtility,
        mediaService,
        caseService
    ) {
        $scope.goBack = goBack;
        $scope.printReport = printReport;

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
        }

        initController();
        $scope.case = {};
        $scope.media = [];
        $scope.workOrders = [];
        $scope.printView = false;
        $scope.reportImageUrl = '../img/reports/NAVIAQ/Images/Logo-width-1200.png';
        $scope.printDate = dateUtility.formatDate(new Date());

        function initController() {
            let requests = {
                getCaseReportContent: caseService.getCaseReportContent($stateParams.operationLogId)
            };
            $q.all(requests).then((data) => {
                var reportContent = data.getCaseReportContent;
                extendMedia(reportContent.deviationReleatedItems, reportContent.users);

                if (reportContent.deviationReleatedItems.length > 0) {
                    $scope.media = reportContent.deviationReleatedItems[0].files;
                    $scope.media.sort((a, b) => a.shouldPreview - b.shouldPreview);
                }
                extendCase(reportContent.deviation);
                $scope.case = reportContent.deviation;
                extendWorkOrder(reportContent.workOrderLogs);
                $scope.workOrders = reportContent.workOrderLogs;
            });

            window.onafterprint = function () {
                $rootScope.showNavbar = true;
                $scope.printView = false;
                $('#main-view').removeClass('print-view');
            };
        }

        function extendMedia(media, users) {
            media.forEach(obj => {
                if(obj.files.length){
                    obj.files.forEach(file => {
                        file.shouldPreview = ['png', 'jpg', 'jpeg', 'bmp', 'gif'].includes(file.fileName.split('.').pop().toLowerCase());
                        file.imageUrl = mediaService.createMediaUrl(file.mediaId);
                        file.formattedCreatedOn = dateUtility.formatDate(file.createdOn);
                        var createdByUser = users.find(u => u.userId === file.createdById);
                        file.createdBy = createdByUser ? createdByUser.displayName : '';
                    })
                }
            });
        }

        function extendWorkOrder(workOrder) {
            _.forEach(workOrder, function (obj) {
                obj.formattedCreatedOn = dateUtility.formatDate(obj.createdOn);
            });
        }

        function extendCase(caseObject) {
            var tagNames = '';
            _.forEach(caseObject.tag, function (tag) {
                if (tag.tag) {
                    tagNames = tagNames + tag.tag + ', ';
                }
            });

            tagNames = tagNames.substr(0, tagNames.length - 2);
            caseObject.tagNames = tagNames;

            //status
            var status = caseObject.workflowStatuses.sort((a,b) => new Date(b.createdOn) - new Date(a.createdOn))[0]

            var workflowStatus = workflowStatuses.find((obj) => {
                return obj.value === status.workflowStatusId;
            });
            caseObject.statusText = workflowStatus.text;

            //severity
            var severity = severityList.find((obj) => {
                return obj.value === caseObject.severity;
            });

            //assigned user name
            var assignedUser = caseObject.assignedUser.find((obj) => {
                return obj.status === 0 && obj.role === 'Assigned';
            });
            if (assignedUser && assignedUser.user && assignedUser.user.displayName) {
                assignedUser = assignedUser.user.displayName;
            }
            caseObject.assignedUserName = assignedUser;

            // associated user names
            var associatedUsers = caseObject.assignedUser.filter((obj) => {
                return obj.status === 0 && obj.role === 'Associated';
            });

            var associatedNames = '';
            _.forEach(associatedUsers, function (user) {
                if (user.user && user.user.displayName) {
                    associatedNames = associatedNames + user.user.displayName + ', ';
                }
            });

            associatedNames = associatedNames.substr(0, associatedNames.length - 2);
            caseObject.associatedNames = associatedNames;

            caseObject.severityText = severity.text;
            caseObject.formattedCreatedOn = dateUtility.formatDate(caseObject.createdOn);
            caseObject.formattedDoneOn = dateUtility.formatDate(caseObject.doneOn);
            caseObject.formattedDueDate = dateUtility.formatDate(caseObject.dueDate);
        }

        function goBack() {
            window.top.close();
        }

        function printReport() {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');

            window.print();
        }
    }
})();
