(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('workOrderConnectCaseModalService', workOrderConnectCaseModalService);

    workOrderConnectCaseModalService.$inject = [
        '$kWindow',
        '$translate'
    ];

    function workOrderConnectCaseModalService(
        $kWindow,
        $translate,
        ) {

        var service = {
            openWorkOrderConnectCaseModal: openWorkOrderConnectCaseModal
        };

        return service;

        function openWorkOrderConnectCaseModal(workOrderLog) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('WORKORDER_CONNECT_CASE_MODAL_TITLE'),
                    resizable: true,
                    visible: false,
                    minWidth: 1040,
                    width: 1040,
                    minHeight: 650,
                    height: 650
                },
                templateUrl: 'app/workOrders/work-order-connect-case-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'WorkOrderConnectCaseModalController',
                resolve: { workOrderLog: () => {return workOrderLog} },
            });
        }
    }
})();
