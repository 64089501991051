(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('TagDetailsModalController', TagDetailsModalController);

    TagDetailsModalController.$inject = ['$q', '$scope', '$rootScope', '$windowInstance', '$translate', 'caseService', 'workOrderService'];

    function TagDetailsModalController($q, $scope, $rootScope, $windowInstance, $translate, caseService, workOrderService) {

        $scope.close = close;
        $scope.saveTag = saveTag;
        $scope.tagTypes = [
            {
                text: $translate.instant('ADMIN_TAGTYPE_CASE'),
                value: 0,
            },
            {
                text: $translate.instant('ADMIN_TAGTYPE_WORKORDER'),
                value: 1,
            }
        ];

        $scope.TagTypeDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.tagTypes)
                },
            }),
            dataValueField: 'value',
            dataTextField: 'text',
            noDataTemplate: 'Ingen treff'
        };

        initController();
        function initController() {
            $scope.TagTypeDropDownOptions.dataSource.read();
        }

        function saveTag(){
            if(!$scope.validator.validate()){
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'tagSaveIndicator',
                destination: '#tag-form'
            });

            if($scope.selectedTagType == 0)
            {
                var caseTag = {
                    caseTagId : '00000000-0000-0000-0000-000000000000',
                    tag: $scope.tagText,
                    contractorId: $rootScope.authData.contractorId,
                    status: 0,
                    createdById: $rootScope.authData.userId,
                    createdOn: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
                };
                caseService.addCaseTag(caseTag).then(function(addedCaseTag){
                    close(addedCaseTag);
                });
            } else {
                var workOrderTag = {
                    workOrderTagId : '00000000-0000-0000-0000-000000000000',
                    tag: $scope.tagText,
                    contractorId: $rootScope.authData.contractorId,
                    status: 0,
                    createdById: $rootScope.authData.userId,
                    createdOn: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
                };
                workOrderService.addWorkOrderTag(workOrderTag).then(function(addedWorkOrderId){
                    close(addedWorkOrderId);
                });
            }
        }

        function close(param) {
            $windowInstance.close(param);
        }
    }
})();
