(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('timeBankService', timeBankService);

    timeBankService.$inject = ['$q', '$http', 'serviceUrls'];

    function timeBankService($q, $http, serviceUrls) {
        const timeBankApiUrl = serviceUrls.webApiBaseUrl + '/TimeBank';

        return {
            getTimeBankOperationLogPairs,
            getTimeBankOperationLogPairsForUsers,
            getTimeBankOperationLogPairsForRestReport,
            getRestLogPairs,
            getTimeBankSummary,
            getUserBalances,
            getUserBalanceForUserDay,
            createUserBalance,
            updateUserBalance,
            updateLogPairStatus,
            getPaycheckExport,
            getAbsenceExport,
            getDepartmentLogs,
            getTimebankBalaces,
            checkActiveLog,
            getCrewInformation
        };

        function getTimeBankOperationLogPairs(filter) {
            const deferred = $q.defer();

            $http
                .post(timeBankApiUrl, filter)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getTimeBankOperationLogPairsForUsers(filter, needOnlyRestLogs = false) {
            const deferred = $q.defer();
            var url = `${timeBankApiUrl}/webApp/TimeBankLogs?needOnlyRestLogs=${needOnlyRestLogs}`;
            $http
                .post(url, filter)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }



        function getTimeBankOperationLogPairsForRestReport(fromDate, toDate, userId) {
            const deferred = $q.defer();

            $http.post(`${timeBankApiUrl}/logPairsForRestReport`,{
                FromDate: fromDate,
                ToDate: toDate,
                UserIds: [userId]
            })
            .then((response) => deferred.resolve(response.data))
            .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getRestLogPairs(filter) {
            const deferred = $q.defer();

            var url = `${timeBankApiUrl}/restLogsByFilter`;
            $http
                .post(url, filter)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getTimeBankSummary(filter) {
            const deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/summary`, filter)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getUserBalances(filter) {
            var deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/usersBalances`, filter)

                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getUserBalanceForUserDay(filter) {
            var deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/usersBalances/userday`, filter)

                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentLogs(filter) {
            const deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/department`, filter)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getTimebankBalaces(filter) {
            const deferred = $q.defer();
            const url = `${timeBankApiUrl}/balances`;
            $http
                .post(url, filter)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function updateLogPairStatus(logPairsWithStatus) {
            var deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/operationlogstatusupdate`, logPairsWithStatus)

                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function createUserBalance(userBalance) {
            var deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/usersBalance/create`, userBalance)

                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function updateUserBalance(userBalance) {
            var deferred = $q.defer();

            $http
                .put(`${timeBankApiUrl}/usersBalance`, userBalance)

                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getPaycheckExport(filter) {
            const deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/exportPaycheck`, filter)
                .then((response) => deferred.resolve(response))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getCrewInformation(filter) {
            const deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/getCrewInformationForSkipper`, filter)
                .then((response) => deferred.resolve(response))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getAbsenceExport(filter) {
            const deferred = $q.defer();

            $http
                .post(`${timeBankApiUrl}/exportAbsence`, filter)
                .then((response) => deferred.resolve(response))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function checkActiveLog(userId, logActionId, departmentId) {
            const deferred = $q.defer(),
                timeBankUrl = timeBankApiUrl;

            var url = timeBankUrl + `/checkActiveLog?userId=${userId}&logActionId=${logActionId}&departmentId=${departmentId}`;

            $http.get(url)
                .then(result => deferred.resolve(result.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
