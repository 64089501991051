(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('SailingTimeController', SailingTimeController);

    SailingTimeController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$state',
        '$translate',
        'kendoUtility',
        'seagoingService',
        'dateUtility'
    ];

    function SailingTimeController(
        $rootScope,
        $scope,
        $q,
        $state,
        $translate,
        kendoUtility,
        seagoingService,
        dateUtility
    ) {
        $scope.sailingTimes = [];

        $scope.$on('timeBankFilterChanged', (event, filter, isUpdate, state) => {
            $scope.filter = filter;
            if(isUpdate && (state == 'sailingTime' || (!state && $state.current.name == 'timeBank.sailingTime'))){
                loadFilteredSailingTimes(filter);
            }
        });

        $scope.sailingTimeFilterDropDowns = {
            displayName: [],
        };

        var sailingTimeGridDataSource = new kendo.data.DataSource({
            transport: {
                read: (e) => e.success($scope.sailingTimes),
            },
            schema: {
                id: 'seagoingServiceId',
                model: {
                  fields: {
                    displayName: { type: "object" },
                    fromDate: { type: "date" },
                    toDate: { type: "date" },
                  }
                }
              },
        });

        var sailingTimeGridOptions = (langId) => ({
            dataSource: sailingTimeGridDataSource,
            filterMenuInit:kendoUtility.onFilterMenuInit,
            height: kendoUtility.calculateRemainingSpace(-40),
            excel: {
                fileName: `${$translate.instant('TIMEBANK_SAILING_TIME')}_${moment().format('DD.MM.YYYY')}.xlsx`,
                filterable: false
            },
            excelExport: function(e) {
                var sheet = e.workbook.sheets[0];

                const notificationTitle = $translate.instant('TIMEBANK_SAILING_TIME_INFORMATION_STRONG');
                const notificationMsg = $translate.instant('TIMEBANK_SAILING_TIME_INFORMATION');

                sheet.rows.unshift({ cells: [{value: `${notificationTitle} ${notificationMsg}`, colSpan: 8}]});
            },
            columns: [
                {
                    field: 'seagoingServiceId',
                    hidden: true,
                },
                {
                    field: 'displayName',
                    title: $translate.instant('TIMEBANK_SAILING_TIME_NAME', null, null, langId),
                    width: 200,
                    filterable:{ui: (element) => createDropDownFilter(element, 'displayName')},
                },
                {
                    field: 'employerOrgNo',
                    title: $translate.instant('TIMEBANK_SAILING_TIME_ORG_NR', null, null, langId),
                },
                {
                    field: 'departmentName',
                    title: $translate.instant('TIMEBANK_SAILING_TIME_DEPARTMENT', null, null, langId),
                },
                {
                    field: 'fromDate',
                    title: $translate.instant('TIMEBANK_SAILING_TIME_FROM_DATE', null, null, langId),
                    template: function (dataItem) {
                        return (dataItem.fromDate ? dateUtility.formatDate(dataItem.fromDate) : '');
                    },
                },
                {
                    field: 'toDate',
                    title: $translate.instant('TIMEBANK_SAILING_TIME_TO_DATE', null, null, langId),
                    template: function (dataItem) {
                        return (dataItem.toDate ? dateUtility.formatDate(dataItem.toDate) : '');
                    },
                },
                {
                    field: 'sum',
                    title: $translate.instant('TIMEBANK_SAILING_TIME_SUM', null, null, langId),
                },
                {
                    field: 'normalWorkHoursPerDay',
                    title: $translate.instant('TIMEBANK_SAILING_TIME_WORK_HOURS_PER_DAY_ON_DEPARTMENT', null, null, langId),
                },
                {
                    field: 'styrkTitle',
                    title: $translate.instant('TIMEBANK_SAILING_TIME_STYRKTITLE', null, null, langId),
                },

            ],
            sortable: true,
            resizable: true,
            filterable: true,
        });
        $scope.sailingTimeGridOptions = sailingTimeGridOptions($translate.use());

        function createDropDownFilter(element, field){
            element.removeAttr("data-bind");
            element.kendoMultiSelect({
                dataSource: new kendo.data.DataSource({ data: $scope.sailingTimeFilterDropDowns[field]}),
                dataTextField: "text",
                dataValueField: "value",
                change: function(e) {
                    var values = this.value();
                    var activeFilters = [];
                    $.each(values, function(i, v) {
                        activeFilters.push({field: field, operator: "equal", value: v });
                    });
                    kendoUtility.createFilterOnGrid(sailingTimeGridDataSource, activeFilters, field);
                }
            });
            if(!document.getElementById(`${field}-filter-menu-selector`)){
                $(element).context.id = `${field}-filter-menu-selector`;
            }
        }


        function loadFilteredSailingTimes(filter) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'timeBankSailingTimeBusyIndicator',
                destination: '#sailing-time-grid',
                overlay: true,
            });

            var request = {
                getSeagoingServiceByContractorId: seagoingService.getSeagoingServiceByContractorId(filter)
            };

            $q.all(request).then(function(result){
                $scope.sailingTimes = result.getSeagoingServiceByContractorId;

                $scope.sailingTimeFilterDropDowns.displayName = [];
                const displayNames = _.uniq($scope.sailingTimes.map(n => n.displayName));
                displayNames.forEach(displayName => displayName && $scope.sailingTimeFilterDropDowns.displayName.push({value: displayName, text: displayName}));

                $scope.sailingTimeGridOptions.dataSource.read();
                $("#sailing-time-grid").data("kendoGrid").setDataSource(sailingTimeGridDataSource);
            })
            .finally(function(){
                $rootScope.$broadcast('hideBusyIndicator', 'timeBankSailingTimeBusyIndicator');
            });
        }

        $scope.exportToExcel = function(){
            $scope.sailingTimeGrid.saveAsExcel();
        }
    }
})();
