(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('WorkOrderConnectCaseModalController', workOrderConnectCaseModalController);

    workOrderConnectCaseModalController.$inject = [
        '$rootScope',
        '$scope',
        '$translate',
        '$kWindow',
        '$windowInstance',
        'noUser',
        'caseService',
        'workOrderService',
        'htmlElementService',
        'dateUtility',
        'workflowStatuses',
        'workOrderLog'
    ];

    function workOrderConnectCaseModalController(
        $rootScope,
        $scope,
        $translate,
        $kWindow,
        $windowInstance,
        noUser,
        caseService,
        workOrderService,
        htmlElementService,
        dateUtility,
        workflowStatuses,
        workOrderLog
        ) {

        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
        $scope.deviations = [];
        $scope.connectCase = connectCase;

        const ConnectCaseGridColumns = langId => {
            let columns = [
                {
                    field: 'operationLogId',
                    hidden: true
                },
                {
                    title: $translate.instant('CASE_GRID_CASEID', null, null, langId),
                    field: 'caseId',
                    width: 30
                },
                {
                    title: $translate.instant('CASE_GRID_CASETITLE', null, null, langId),
                    field: 'title',
                    width: 50,
                },
                {
                    title: $translate.instant('G_VESSEL', null, null, langId),
                    field: 'departmentName',
                    width: 30,
                },
                {
                    title: $translate.instant('CASE_GRID_LOGACTION_DESCRIPTION', null, null, langId),
                    field: 'logActionDescription',
                    width: 30,
                },
                {
                    title: $translate.instant('CASE_GRID_DESCRIPTION', null, null, langId),
                    field: 'description',
                    width: 30,
                },
                {
                    title: $translate.instant('CASE_GRID_REGISTERED_BY', null, null, langId),
                    field: 'registeredUserName',
                    width: 30,
                },
                {
                    title: $translate.instant('CASE_GRID_ASSIGNED_TO', null, null, langId),
                    field: 'assignedUserName',
                    width: 30,
                },
                {
                    title: $translate.instant('CASE_GRID_CREATED_ON', null, null, langId),
                    field: 'createdOn',
                    width: 30,
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.createdOn);
                    }
                },
                {
                title: '&nbsp;',
                    width: 10,
                    command: {
                        text: ' ',
                        template: '<i class="fas fa-link fa-1-5x grid-icon" ng-click="connectCase($event)"></i>'
                    }
                }
            ]

            return columns;
        }

        $scope.ConnectCaseGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.deviations)
                },
                batch: false,
                schema: {
                    model: {
                        id: 'operationLogId',
                        fields: {
                            createdOn:  { type: 'date' },
                            caseId:     { type: 'number' }
                        }
                    },
                    parse: function (d) {
                        $.each(d, function (idx, elem) {
                            elem.createdOn = dateUtility.formatDateTime(elem.createdOn);
                        });
                        return d;
                    }
                }
            }),
            columns: ConnectCaseGridColumns(langId),
            editable: false,
            resizable: true,
            scrollable: true,
            filterable: true,
            sortable: true,
            height: "620px",
            dataBound: function () {
                var data = this.dataSource.view();
                for (var i = 0; i < data.length; i++) {
                    if (data[i].operationLogId === workOrderLog.parentId) {
                        var commandButton = this.tbody.find("tr[data-uid='" + data[i].uid + "']").find('.grid-icon');
                        commandButton.attr('isConnectedCase', '');
                        commandButton.removeClass('fa-link');
                        commandButton.addClass('fa-unlink');
                    }
                }
            }
        };

        $scope.caseFilter = {
            contractorId: $rootScope.authData.contractorId,
            workFlowStatusIds: workflowStatuses.filter(function (status) {
                return status.meaning !== 'Closed';
            }).map(function (status) {
                return status.value;
            }),
        }

        initController();

        function initController() {
            htmlElementService.checkIfElementExists('connect-case-modal').then( result => {
                if (result) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'connectCase_loadCases',
                        destination: '#connect-case-modal',
                        overlay: true
                    });
                }
            });

            caseService.getCasesByFilter($scope.caseFilter).then( resolved => {
                $scope.deviations = filterDeviations(mapAndSortDeviations(resolved));
                $scope.ConnectCaseGridOptions.dataSource.read();
            }, (reject) => {
                console.log('Error loading cases: ', reject);
            }).finally( () => {
                $rootScope.$broadcast('hideBusyIndicator', 'connectCase_loadCases');
            });

        }

        function mapAndSortDeviations(deviations) {
            return deviations.map(function (deviation) {
                var noUserCopy = angular.copy(noUser);
                noUserCopy.role = 'Assigned';

                if (!deviation.assignedUser || !deviation.assignedUser[0]) {
                    deviation.assignedUser = [noUserCopy];
                }
                if (deviation.assignedUser && deviation.assignedUser.length === 1 && deviation.assignedUser[0].role === 'Registered') {
                    deviation.assignedUser.push(noUserCopy);
                }
                deviation.isExpired = deviation.dueDate && new Date(deviation.dueDate) < new Date(new Date().setHours(0, 0, 0, 0));

                if (dateUtility.isDefaultDate(deviation.dueDate)) {
                    deviation.dueDate = '';
                }

                if (deviation.assignedUser && deviation.assignedUser.length > 0) {
                    var createdBy = deviation.assignedUser.filter(function (user) {
                        return user.role === 'Registered';
                    });
                    if (createdBy.length > 0) {
                        deviation.registeredUserName = createdBy[0].user.displayName;
                    } else {
                        deviation.registeredUserName = '';
                    }
                } else {
                    deviation.registeredUserName = '';
                }

                if (deviation.assignedUser && deviation.assignedUser.length > 0) {
                    var assignedTo = deviation.assignedUser.filter(function (user) {
                        return user.role === 'Assigned';
                    });
                    if (assignedTo.length > 0) {
                        deviation.assignedUserName = assignedTo[0].user ? assignedTo[0].user.displayName : noUser.displayName;
                    }
                    else{
                        deviation.assignedUserName = '';
                    }
                }

                return deviation;
            }).sort(function (a,b) {
                return new Date(b.createdOn) - new Date(a.createdOn);
            });
        }

        function filterDeviations(deviations) {
            return deviations.filter(deviation => {
                return deviation.status != 99;
            });
        }

        function connectCase(event) {

            var disconnectCase = event.currentTarget.hasAttribute('isConnectedCase');

            const confirmConnectWindowInstance =  $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: () => {
                        return disconnectCase ?
                                $translate.instant('WORKORDER_CONNECT_CASE_MODAL_CONFIRM_DISCONNECT_CASE', null, null, langId) :
                                $translate.instant('WORKORDER_CONNECT_CASE_MODAL_CONFIRM_CONNECT_CASE', null, null, langId);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });

            confirmConnectWindowInstance.result.then(function(response) {
                if (response) {
                    var parentCaseId = disconnectCase ? '' : $('#connect-case-grid').data('kendoGrid').dataItem(angular.element(event.currentTarget).closest('tr')).toJSON().operationLogId;
                    updateWorkOrder(parentCaseId);
                }
            });
        }

        function updateWorkOrder(parentCaseId) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'connectCase_updateWorkOrder',
                destination: '#connect-case-modal',
                overlay: true
            });

            var bUpdateSuccessful = false;

            workOrderService.updateWorkOrderLogParentId(workOrderLog.operationLogId, parentCaseId).then(resolve => {
                workOrderLog.parentId = parentCaseId;
                bUpdateSuccessful = true;
            }).catch(reject => {
                console.log('Error setting parent case of work order: ', reject);
            }).finally(() => {
                $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 400
                    },
                    templateUrl: 'app/shared/popups/information-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'InformationModalController',
                    resolve: {
                        content: () => {
                            return bUpdateSuccessful ?
                                    $translate.instant('WORKORDER_CONNECT_CASE_MODAL_INFO_SUCCESS', null, null, langId) :
                                    $translate.instant('WORKORDER_CONNECT_CASE_MODAL_INFO_FAIL', null, null, langId);
                            }
                    }
                });

                $rootScope.$broadcast('hideBusyIndicator', 'connectCase_updateWorkOrder');
                $windowInstance.close();
            });
        }

    }

})();
