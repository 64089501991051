(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('documentService', documentService);

    documentService.$inject = ['$q', '$http', 'serviceUrls'];

    function documentService($q, $http, serviceUrls) {
        const documentApiBaseUrl = `${serviceUrls.webApiBaseUrl}/document`;

        return {
            getAllByEquipmentId
        };

        function getAllByEquipmentId(equipmentId) {
            const deferred = $q.defer();

            $http
                .get(`${documentApiBaseUrl}/allByEquipmentId?equipmentId=${equipmentId}`)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
