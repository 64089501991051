(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('departmentService', departmentService);

    departmentService.$inject = ['$q', '$http', 'serviceUrls'];

    function departmentService($q, $http, serviceUrls) {
        const departmentBaseApiUrl = `${serviceUrls.webApiBaseUrl}/department`;
        const checkListBaseApiUrl = `${serviceUrls.webApiBaseUrl}/checkList`;
        const departmentShareBaseApiUrl = `${serviceUrls.webApiBaseUrl}/departmentSharing`;
        const departmentHistoryBaseApiUrl = `${serviceUrls.webApiBaseUrl}/departmentHistory`;

        return {
            getAllDepartmentsWebapi: getAllDepartmentsWebapi,
            getDepartmentsWebapi: getDepartmentsWebapi,
            getDepartmentTypesWebApi: getDepartmentTypesWebApi,
            getCheckListItemsWebApi: getCheckListItemsWebApi,
            getDepartmentInfosWebApi: getDepartmentInfosWebApi,
            addDepartmentWebApi: addDepartmentWebApi,
            updateDepartmentWebApi: updateDepartmentWebApi,
            getDepartmentHistoryElementsWebApi: getDepartmentHistoryElementsWebApi,
            addDepartmentHistoryElementWebApi: addDepartmentHistoryElementWebApi,
            getDepartmentWebApi: getDepartmentWebApi,
            getDepartmentByIdWebApi: getDepartmentByIdWebApi,
            getDepartmentByMMSIWebAPI: getDepartmentByMMSIWebAPI,
            getDepartmentSharingsWebApi: getDepartmentSharingsWebApi,
            addDepartmentSharingWebApi: addDepartmentSharingWebApi,
            updateDepartmentSharingWebApi: updateDepartmentSharingWebApi,
            deleteDepartmentSharingByIdWebApi: deleteDepartmentSharingByIdWebApi,
            getChildsDepartmentsWebApi: getChildsDepartmentsWebApi,
            updateParentChildDepartmentsWebApi: updateParentChildDepartmentsWebApi,
            getDepartmentTreeByDepartmentId: getDepartmentTreeByDepartmentId,
            getVesselLocations: getVesselLocations,
            updateStatus: updateStatus,
            getActiveEquipmentJobsByDepartmentId: getActiveEquipmentJobsByDepartmentId,
            getInactiveEquipmentJobsByDepartmentId: getInactiveEquipmentJobsByDepartmentId,
            updateEquipmentJobs: updateEquipmentJobs,
            getDepartmentsForDropdown: getDepartmentsForDropdown
        };

        function getVesselLocations(contractorId, includeInactiveDepartments = true) {
            const deferred = $q.defer();

            $http.get(`${departmentBaseApiUrl}/getExtendedVesselLocation?includeInactiveDepartments=${includeInactiveDepartments}`).then(
                (response) => {
                    const vessels = response.data,
                    filteredVessels = vessels;

                    if (contractorId) {
                        const vesselsByMmsi = _.groupBy(vessels, 'mmsi');

                        filteredVessels = [];
                        for (const mmsiProperty in vesselsByMmsi) {
                            if (vesselsByMmsi.hasOwnProperty(mmsiProperty)) {
                                const vesselWithContractor = _.find(vesselsByMmsi[mmsiProperty], { contractorId: contractorId });
                                if (vesselWithContractor) {
                                    filteredVessels.push(vesselWithContractor);
                                } else {
                                    filteredVessels = filteredVessels.concat(vesselsByMmsi[mmsiProperty]);
                                }
                            }
                        }
                    }

                    deferred.resolve(filteredVessels)
                },
                () => deferred.reject());

            return deferred.promise;
        }

        function getAllDepartmentsWebapi() {
            const deferred = $q.defer();
            const url = departmentBaseApiUrl;
            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentsWebapi(
                contractorId,
                includeInactiveDepartments = false,
                includeConglomerateDepartments = false,
                includeOrderDepartments = false,
                includePausedDepartments = false
        ) {
            const deferred = $q.defer();
            const url = `${departmentBaseApiUrl}/byContractorId?contractorId=${contractorId}&includeInactiveDepartments=${includeInactiveDepartments}&includeConglomerateDepartments=${includeConglomerateDepartments}&includeOrderDepartments=${includeOrderDepartments}&includePausedDepartments=${includePausedDepartments}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentTypesWebApi() {
            const deferred = $q.defer(),
                requestUrl = `${departmentBaseApiUrl}/departmentTypes`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getCheckListItemsWebApi(departmentId) {
            const deferred = $q.defer(),
                requestUrl = `${checkListBaseApiUrl}?departmentId=${departmentId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getDepartmentInfosWebApi() {
            const deferred = $q.defer(),
                requestUrl = `${departmentBaseApiUrl}/departmentInfo`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addDepartmentWebApi(department) {
            const deferred = $q.defer(),
                requestUrl = departmentBaseApiUrl;

            $http
                .post(requestUrl, department)
                .then(
                    function (response) {
                        deferred.resolve(response.data);
                    },
                    function () {
                        deferred.reject();
                    }
                );

            return deferred.promise;
        }

        function updateDepartmentWebApi(department) {
            department.departmentType = {}
            const deferred = $q.defer(),
                requestUrl = departmentBaseApiUrl;
            $http
                .put(requestUrl, department)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentHistoryElementsWebApi(departmentId) {
            const deferred = $q.defer();
            const requestUrl = departmentHistoryBaseApiUrl;
            $http({
                url: requestUrl,
                method: "GET",
                params: { departmentId: departmentId }
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addDepartmentHistoryElementWebApi(departmentHistory) {
            const deferred = $q.defer();
            const requestUrl = departmentHistoryBaseApiUrl;
            $http
                .post(requestUrl, departmentHistory)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentWebApi(departmentId) {
            const deferred = $q.defer();
            const requestUrl = `${departmentBaseApiUrl}/byDepartmentId`;
            $http({
                url: requestUrl,
                method: "GET",
                params: { departmentId: departmentId }
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getDepartmentByIdWebApi(departmentId) {
            const deferred = $q.defer();
            const requestUrl = `${departmentBaseApiUrl}/byId?departmentId=${departmentId}`;
            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentByMMSIWebAPI(contractorId, mmsi) {
            const deferred = $q.defer();
            const url = `${departmentBaseApiUrl}/byMMSI?contractorId=${contractorId}&mmsi=${mmsi}`;
            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentSharingsWebApi(contractorId) {
            const deferred = $q.defer();
            const requestUrl = departmentShareBaseApiUrl;
            $http({
                url: requestUrl,
                method: "GET",
                params: { contractorId: contractorId }
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addDepartmentSharingWebApi(departmentSharing) {
            const deferred = $q.defer();
            const requestUrl = departmentShareBaseApiUrl;
            $http
                .post(requestUrl, departmentSharing)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateDepartmentSharingWebApi(departmentSharing) {
            const deferred = $q.defer();
            const requestUrl = departmentShareBaseApiUrl;
            $http
                .put(requestUrl, departmentSharing)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteDepartmentSharingByIdWebApi(departmentSharingId) {
            const deferred = $q.defer();
            const url = `${departmentShareBaseApiUrl}?departmentSharingId=${departmentSharingId}`;

            $http
                .delete(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getChildsDepartmentsWebApi(parentDepartmentId, includeInactiveDepartments = false){
            const deferred = $q.defer();
            const url = `${departmentBaseApiUrl}/children?parentDepartmentId=${parentDepartmentId}&includeInactiveDepartments=${includeInactiveDepartments}`

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentsForDropdown(includeInactiveDepartments = false){
            const deferred = $q.defer();
            const url = `${departmentBaseApiUrl}/allForDropdown?includeInactive=${includeInactiveDepartments}`

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateParentChildDepartmentsWebApi(parentDepartmentId, newParentDepartmentId) {
            const deferred = $q.defer(),
                requestUrl = `${departmentBaseApiUrl}/updateChildren?parentDepartmentId=${parentDepartmentId}&newParentDepartmentId=${newParentDepartmentId}`;

            $http.put(requestUrl, null).then(response => deferred.resolve(response.data)).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentTreeByDepartmentId(departmentId, includeInactiveDepartments = false) {
            const deferred = $q.defer();
            const url = `${departmentBaseApiUrl}/departmentTreeByDepartmentId?departmentId=${departmentId}&includeInactiveDepartments=${includeInactiveDepartments}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateStatus(newStatusObj) {
            const deferred = $q.defer();
            const url = `${departmentBaseApiUrl}/updateStatus`;

            $http
                .put(url, newStatusObj)
                .then(response => {
                    if (response.data) {
                        deferred.resolve(response.data);
                    } else {
                        deferred.reject(response.data);
                    }
                })
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getActiveEquipmentJobsByDepartmentId(departmentId) {
            const deferred = $q.defer();

            $http
                .get(`${departmentBaseApiUrl}/activeEquipmentJobs?departmentId=${departmentId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getInactiveEquipmentJobsByDepartmentId(departmentId) {
            const deferred = $q.defer();

            $http
                .get(`${departmentBaseApiUrl}/inactiveEquipmentJobs?departmentId=${departmentId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateEquipmentJobs(equipmentJobIds, status) {
            const deferred = $q.defer();

            // Create a URLSearchParams object to handle the query parameters
            const params = new URLSearchParams();
            equipmentJobIds.forEach(id => params.append('equipmentJobIds', id));
            params.append('status', status);

            $http
                .put(`${departmentBaseApiUrl}/updateEquipmentJobs?${params.toString()}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
