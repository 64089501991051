(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('WorkContractReportController', WorkContractReportController);

    WorkContractReportController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$q', 'dateUtility', 'workContractService', 'workContractTypes', 'mediaService', 'signatureService'];

    function WorkContractReportController($rootScope, $scope, $state, $stateParams, $q, dateUtility, workContractService, workContractTypes, mediaService, signatureService) {

        $scope.printReport = printReport;
        $scope.goBack = goBack;
        $scope.workContractId = $stateParams.workContractId ? $stateParams.workContractId : null;
        $scope.contract = null;

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#fisketransport-as-contract-container').addClass('print-view-container');
        }


        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'workContractIndicator',
                destination: '#main-view',
                overlay: true,
            });

            var requests = {
                getSignaturesByWorkContractId: signatureService.getSignaturesByWorkContractId($scope.workContractId),
                getWorkContractById: workContractService.getWorkContractById($scope.workContractId)
            };

            $q.all(requests).then((result) => {
                $scope.contract = result.getWorkContractById;
                $scope.contract.contractDate = moment($scope.contract.contractDate).format('DD.MM.YYYY');

                var signatures = result.getSignaturesByWorkContractId;
                _.forEach(signatures, function (signature) {
                    mediaService
                    .getMediaByMediaId(signature.mediaId)
                    .then(base64 => {
                        if(signature.signatureType == 0){
                            $scope.signerImage = `data:image/jpeg;base64,${base64}`;
                        }

                        if(signature.signatureType == 1){
                            $scope.confirmerImage = `data:image/jpeg;base64,${base64}`;
                        }
                    });
                });

                $rootScope.$broadcast('hideBusyIndicator', 'workContractIndicator');

            }).catch(error => {
                $rootScope.$broadcast('hideBusyIndicator', 'workContractIndicator');
            });
        }

        function goBack() {
            window.top.close();
        }

        function printReport() {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#fisketransport-as-contract-container').addClass('print-view-container');
            document.title = "Arbeidsavtale - Employment agreement-" + kendo.toString(new Date(), 'HH:mm dd.MM.yyyy');
            window.print();

            setTimeout(function(){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'fiskeTransportAs',
                    destination: '#main-view',
                    overlay: true
                });

                $rootScope.showNavbar = true;
                $scope.printView = false;
                document.title = "Naviaq Webinnsyn";
                $('#main-view').removeClass('print-view');
                $('#fisketransport-as-contract-container').removeClass('print-view-container');
                $rootScope.$broadcast('hideBusyIndicator', 'fiskeTransportAs');
            }, 1);
        }
    }
})();
