(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('adminUserService', adminUserService);

    adminUserService.$inject = ['$q', '$http', 'serviceUrls'];

    function adminUserService($q, $http, serviceUrls) {
        const userWebApiUrl = `${serviceUrls.webApiBaseUrl}/adminUser`;

        return {
            setPasswordForUser,
            sendWelcomeMail
        };

        function setPasswordForUser(userPassword) {
            const deferred = $q.defer();

            $http
                .put(`${userWebApiUrl}/setPasswordForUser`, userPassword)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function sendWelcomeMail(welcomeMail) {
            const deferred = $q.defer();

            $http
                .post(`${userWebApiUrl}/sendWelcomeMail`, welcomeMail)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
