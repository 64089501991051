(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('mapObjectService', MapObjectService);

    MapObjectService.$inject = ['$q', '$http', 'serviceUrls'];

    function MapObjectService($q, $http, serviceUrls) {
        var mapObjectWebApiURL = `${serviceUrls.webApiBaseUrl}/mapObjectPoint`;

        return {
            getMapPoints: getMapPoints,
            setPointAsCornerPoint: setPointAsCornerPoint,
        };

        function getMapPoints(mapLayerIds) {
            const deferred = $q.defer();
            const requestUrl = mapObjectWebApiURL;
            $http({
                url: requestUrl,
                method: "GET",
                params: { mapLayerIds: mapLayerIds }

            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function setPointAsCornerPoint(pointId, cornerValue) {
            const deferred = $q.defer();
            const requestUrl = mapObjectWebApiURL;
            $http({
                url: requestUrl,
                method: "PUT",
                params: { pointId: pointId, cornerValue: cornerValue }
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }
    }
})();
