(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('entityHistoryService', entityHistoryService);

    entityHistoryService.$inject = ['$q', '$http', 'serviceUrls'];

    function entityHistoryService($q, $http, serviceUrls) {
        const mapObjectWebApiURL = `${serviceUrls.webApiBaseUrl}/entityHistory`;

        const get = (ids) => {
            const deferred = $q.defer();
            const requestUrl = mapObjectWebApiURL;

            $http({
                url: requestUrl,
                method: "GET",
                params: { ids: ids }
            }).then(
                (response) => {
                    deferred.resolve(response.data);
                },
                (error) => {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        return {
            get
        };
    }
})();
