(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationInstallationController', LocationInstallationController);

    LocationInstallationController.$inject = ['$scope', '$rootScope','$translate', '$stateParams', 'mapObjectType', 'locationService', 'kendoUtility', 'RovActionValue', 'locationLogService'];

    function LocationInstallationController($scope, $rootScope, $translate, $stateParams, mapObjectType, locationService, kendoUtility, RovActionValue, locationLogService) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.componentsGridData = [];
        $scope.rovGridData = [];
        $scope.mapObjects = [];
        $scope.selectedDataItem = null;
        $scope.tabStripOptions = {
            animation: false,
            scrollable: false
        };
        $scope.showLink = showLink;
        const componentsGridOptions = langId => ({
            dataSource: {
                transport: {
                    read: e => e.success($scope.componentsGridData)
                }
            },
            dataBound: function (e) {
                var dataItems = e.sender.dataSource.data();
                for (var i = 0; i < dataItems.length; ++i) {
                    if (dataItems[i].lastDayInSea && moment(dataItems[i].lastDayInSea,'DD.MM.YYYY') <= moment().add(14, 'days')) {
                        var itemRow = e.sender.element.find(':contains(' + dataItems[i].locationLogId + ')').closest('tr');
                        itemRow.addClass('marked-grid-row-red');
                    }
                }
            },
            sortable: true,
            resizable: true,
            filterable: {
                name: 'FilterMenu',
                extra: true
            },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [
                {
                    field: 'locationLogId',
                    hidden: true
                }, {
                    field: 'lineComponentNumber',
                    title: '#',
                    width: '90px'
                }, {
                    field: 'resource',
                    title: $translate.instant('MAIN_INSTALLATION_COMPONENTS_GRID_CATEGORY', null, null, langId)
                }, {
                    field: 'resourceCertificate',
                    title: $translate.instant('MAIN_INSTALLATION_COMPONENTS_GRID_BATCHANDCERTIFICATE', null, null, langId)
                }, {
                    field: 'resourceDimention',
                    title: $translate.instant('MAIN_INSTALLATION_COMPONENTS_GRID_PRODUCT', null, null, langId)
                }, {
                    field: 'amount',
                    title: $translate.instant('MAIN_INSTALLATION_COMPONENTS_GRID_QUANTITY', null, null, langId)
                }, {
                    field: 'depthInMeters',
                    title: $translate.instant('MAIN_INSTALLATION_COMPONENTS_GRID_DEEP', null, null, langId)
                }, {
                    field: 'nrOfDaysInSea',
                    title: $translate.instant('MAIN_INSTALLATION_COMPONENTS_ANTDAY', null, null, langId)
                }, {
                    field: 'lastDayInSea',
                    title: $translate.instant('MAIN_INSTALLATION_COMPONENTS_LASTDAYATSEA', null, null, langId),
                    width: 120
                }]
        });
        $scope.componentsGridOptions = componentsGridOptions($translate.use());
        $scope.$on('langChanged', (event, code) => {
            $scope.componentsGridOptions = componentsGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.componentsGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#componentsGrid')]);
        });

        const rovGridOptions = langId => ({
            dataSource: {
                transport: {
                    read: e => e.success($scope.rovGridData)
                }
            },
            sortable: true,
            resizable: true,
            filterable: {
                name: 'FilterMenu',
                extra: true
            },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [{
                field: 'timestamp',
                title: $translate.instant('MAIN_INSTALLATION_ROV_GRID_TIME', null, null, langId),
                width: '150px'
            }, {
                field: 'comment',
                    title: $translate.instant('MAIN_INSTALLATION_ROV_GRID_COMMENT', null, null, langId),
                width: '300px',
            },
            {
                field: 'secondaryComment',
                title: $translate.instant('MAIN_INSTALLATION_ROV_GRID_DEVIATIONLOCATION', null, null, langId),
                width: '300px',
            }, {
                field: 'media',
                filterable: false,
                sortable: false,
                title: $translate.instant('MAIN_INSTALLATION_ROV_GRID_VIDEOLINK', null, null, langId),
                width: '90px',
                template: '<button class="n-button"  ng-touch=showLink($event,dataItem)  ng-mouseup=showLink($event,dataItem)>' + $translate.instant('G_SHOW', null, null, langId) +'</button>'
            }]
        });

        $scope.rovGridOptions = rovGridOptions($translate.use());
        $scope.$on('langChanged', (event, code) => {
            $scope.rovGridOptions = rovGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.rovGridOptions);
            ChangeMapDropDown(code);
            kendoUtility.changeLangOfWidget(code, [
                () => grid('#rovGrid')
            ]);
        });

        initController();
        function initController() {
            $scope.mapObjects = [];

            $scope.Barges = [];
            $scope.Cages = [];
            $scope.Nets = [];
            $scope.Rings = [];
            $scope.MapLayers = [];

            locationService.getLocationByLocationNumber($stateParams.LocationNumber).then(function (location) {
                var cageMapObjects = [],
                    netMapObjects = [],
                    ringMapObjects = [],
                    lineMapObjects = [];

                $scope.Barges = location.Barges;
                _.forEach($scope.Barges, function (barge) {
                    var mapObject = {
                        group: $translate.instant('MAIN_INSTALLATION_GROUP_RAFTS'),
                        id: barge.BargeId,
                        bargeId: barge.BargeId,
                        text: 'Flåte',
                        objectType: mapObjectType.Barge
                    };

                    $scope.mapObjects.push(mapObject);
                });

                $scope.Cages = location.Cages;
                _.forEach($scope.Cages, function (cage) {
                    var mapObject = {
                        group: $translate.instant('MAIN_INSTALLATION_GROUP_CAGE'),
                        id: cage.CageId,
                        cageId: cage.CageId,
                        text: 'Bur#' + (cage.CageNumber ? cage.CageNumber : ''),
                        objectType: mapObjectType.Cage
                    };
                    cageMapObjects.push(mapObject);
                });

                $scope.Nets = location.Nets;
                _.forEach($scope.Nets, function (net) {
                    var mapObject = {
                        group: $translate.instant('MAIN_INSTALLATION_GROUP_NOTES'),
                        id: net.MapObjectReferenceId,
                        netId: net.NetId,
                        text: 'Not#' + (net.NetId ? net.NetId : ''),
                        objectType: mapObjectType.Net
                    };
                    netMapObjects.push(mapObject);
                });

                $scope.Rings = location.Rings;
                _.forEach($scope.Rings, function (ring) {
                    var mapObject = {
                        group: $translate.instant('MAIN_INSTALLATION_GROUP_FLOTATINCOLLARS'),
                        id: ring.MapObjectReferenceId,
                        ringId: ring.RingId,
                        text: 'Flytekrage#' + (ring.SerialNumber ? ring.SerialNumber : ''),
                        objectType: mapObjectType.Ring
                    };
                    ringMapObjects.push(mapObject);
                });

                //Lines
                $scope.Rings = location.MapLayers;
                _.forEach($scope.Rings, function (mapLayer) {
                    _.forEach(mapLayer.MapLayerObjects, function (mapLayerObject) {
                        if (mapLayerObject.ObjectType === 1) {
                            if (mapLayerObject.MapLayerObjectPoints && mapLayerObject.MapLayerObjectPoints.length === 2) {
                                var description = '-';

                                if (mapLayerObject.MapLayerObjectPoints[0].Description) {
                                    description = mapLayerObject.MapLayerObjectPoints[0].Description;
                                } else if (mapLayerObject.MapLayerObjectPoints[1].Description) {
                                    description = mapLayerObject.MapLayerObjectPoints[1].Description;
                                }

                                var mapObject = {
                                    group: $translate.instant('MAIN_INSTALLATION_GROUP_MOORING'),
                                    id: mapLayerObject.MapLayerObjectId,
                                    mapLayerObjectId: mapLayerObject.MapLayerObjectId,
                                    text: description,
                                    objectType: mapObjectType.MapLayerObject
                                };

                                lineMapObjects.push(mapObject);
                            }
                        }
                    });
                });

                $scope.mapObjects = _.concat(
                    $scope.mapObjects,
                    _.sortBy(cageMapObjects, 'text'),
                    _.sortBy(lineMapObjects, 'text'),
                    _.sortBy(netMapObjects, 'text'),
                    _.sortBy(ringMapObjects, 'text')
                );

                $scope.mapObjectsFilterOptions = {
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: e => e.success($scope.mapObjects)
                        },
                        group: { field: 'group' }
                    }),
                    dataTextField: 'text',
                    dataValueField: 'id',
                    change: mapObjectsFilterChanged
                };
            });

            $scope.$on('mapObjectSelected', function (event, selectedGraphic) {
                if (selectedGraphic && selectedGraphic.attributes) {
                    var dataItems = _.sortBy($scope.mapObjectsFilter.dataSource.data(), 'group'),
                        selectedId = null;

                    for (var i = 0; i < dataItems.length; ++i) {
                        if (dataItems[i].objectType === selectedGraphic.attributes.objectType) {
                            if (selectedGraphic.attributes.objectType === mapObjectType.Barge
                                && selectedGraphic.attributes.bargeId === dataItems[i].id) {

                                selectedId = dataItems[i].id;
                                break;
                            } else if (selectedGraphic.attributes.objectType === mapObjectType.Cage
                                && selectedGraphic.attributes.cageId === dataItems[i].id) {

                                selectedId = dataItems[i].id;
                                break;
                            } else if (selectedGraphic.attributes.objectType === mapObjectType.Net
                                && selectedGraphic.attributes.netId === dataItems[i].netId) {

                                selectedId = dataItems[i].id;
                                break;
                            } else if (selectedGraphic.attributes.objectType === mapObjectType.Ring
                                && selectedGraphic.attributes.ringId === dataItems[i].ringId) {

                                selectedId = dataItems[i].id;
                                break;
                            }
                        } else if (dataItems[i].objectType === mapObjectType.MapLayerObject
                            && selectedGraphic.attributes.objectType === 'Line'
                            && selectedGraphic.attributes.mapLayerObjectId === dataItems[i].mapLayerObjectId) {

                            selectedId = dataItems[i].id;
                            break;
                        }
                    }

                    if (selectedId) {
                        $scope.mapObjectsFilter.value(selectedId);
                        $scope.selectedDataItem = $scope.mapObjectsFilter.dataItem();

                        readComponents($scope.selectedDataItem);
                    }
                }
            });
        };

        function ChangeMapDropDown(langId) {
            $scope.mapObjects = [];
            var cageMapObjects = [],
                netMapObjects = [],
                ringMapObjects = [],
                lineMapObjects = [];

            _.forEach($scope.Barges, function (barge) {
                var mapObject = {
                    group: $translate.instant('MAIN_INSTALLATION_GROUP_RAFTS', null, null, langId),
                    id: barge.BargeId,
                    bargeId: barge.BargeId,
                    text: 'Flåte',
                    objectType: mapObjectType.Barge
                };

                $scope.mapObjects.push(mapObject);
            });

            _.forEach($scope.Cages, function (cage) {
                var mapObject = {
                    group: $translate.instant('MAIN_INSTALLATION_GROUP_CAGE', null, null, langId),
                    id: cage.CageId,
                    cageId: cage.CageId,
                    text: 'Bur#' + (cage.CageNumber ? cage.CageNumber : ''),
                    objectType: mapObjectType.Cage
                };
                cageMapObjects.push(mapObject);
            });

            _.forEach($scope.Nets, function (net) {
                var mapObject = {
                    group: $translate.instant('MAIN_INSTALLATION_GROUP_NOTES', null, null, langId),
                    id: net.MapObjectReferenceId,
                    netId: net.NetId,
                    text: 'Not#' + (net.NetId ? net.NetId : ''),
                    objectType: mapObjectType.Net
                };
                netMapObjects.push(mapObject);
            });

            _.forEach($scope.Rings, function (ring) {
                var mapObject = {
                    group: $translate.instant('MAIN_INSTALLATION_GROUP_FLOTATINCOLLARS', null, null, langId),
                    id: ring.MapObjectReferenceId,
                    ringId: ring.RingId,
                    text: 'Flytekrage#' + (ring.SerialNumber ? ring.SerialNumber : ''),
                    objectType: mapObjectType.Ring
                };
                ringMapObjects.push(mapObject);
            });

            //Lines
            _.forEach($scope.Rings, function (mapLayer) {
                _.forEach(mapLayer.MapLayerObjects, function (mapLayerObject) {
                    if (mapLayerObject.ObjectType === 1) {
                        if (mapLayerObject.MapLayerObjectPoints && mapLayerObject.MapLayerObjectPoints.length === 2) {
                            var description = '-';

                            if (mapLayerObject.MapLayerObjectPoints[0].Description) {
                                description = mapLayerObject.MapLayerObjectPoints[0].Description;
                            } else if (mapLayerObject.MapLayerObjectPoints[1].Description) {
                                description = mapLayerObject.MapLayerObjectPoints[1].Description;
                            }

                            var mapObject = {
                                group: $translate.instant('MAIN_INSTALLATION_GROUP_MOORING', null, null, langId),
                                id: mapLayerObject.MapLayerObjectId,
                                mapLayerObjectId: mapLayerObject.MapLayerObjectId,
                                text: description,
                                objectType: mapObjectType.MapLayerObject
                            };

                            lineMapObjects.push(mapObject);
                        }
                    }
                });
            });

            $scope.mapObjects = _.concat(
                $scope.mapObjects,
                _.sortBy(cageMapObjects, 'text'),
                _.sortBy(lineMapObjects, 'text'),
                _.sortBy(netMapObjects, 'text'),
                _.sortBy(ringMapObjects, 'text')
            );

            $scope.mapObjectsFilterOptions.dataSource.read();
        };

        function mapObjectsFilterChanged(e) {
            $scope.selectedDataItem = e.sender.dataItem();

            readComponents($scope.selectedDataItem);
        }

        function readComponents(selectedDataItem) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationLog',
                destination: '#right-pane',
                overlay: false
            });

            if (selectedDataItem) {
                locationLogService.getLocationLogByMapObject(selectedDataItem).then(function (locationLog) {
                    $scope.componentsGridData = [];
                    $scope.rovGridData = [];

                    _.each(locationLog, function (item) {
                        var newItem = {
                            locationLogId: item.locationLogId,
                            lineComponentNumber: item.lineComponentSequenceNumber ? item.lineComponentSequenceNumber : '',
                            resource: item.resourceDescription ? item.resourceDescription : '',
                            resourceCertificate: item.resourceCertificate,
                            resourceDimention: item.resourceDimention,
                            amount: item.amount,
                            depthInMeters: item.depthInMeters,
                            nrOfDaysInSea: '',
                            lastDayInSea: item.lastDayInSea ? moment(item.lastDayInSea).format('DD.MM.YYYY') : '',
                        };
                        var newROVItem = {
                            timestamp: item.timestamp ? moment(item.timestamp).format('DD.MM.YYYY HH:mm:ss') : '',
                            comment: item.comment,
                            secondaryComment: item.secundaryComment,
                            media: item.mediaUrl ? item.mediaUrl : '',
                        };

                        if (item.logActionId == RovActionValue) {
                            $scope.rovGridData.push(newROVItem);
                        }
                        else {
                            $scope.componentsGridData.push(newItem);
                        }
                    });


                    $scope.componentsGrid.dataSource.read();
                    $scope.rovGrid.dataSource.read();

                    //$scope.rovGrid.dataSource.data($scope.rovGridData);
                    //$scope.componentsGrid.dataSource.data($scope.componentsGridData);
                    //$scope.componentsGrid.refresh();
                    //$scope.rovGrid.refresh();

                    $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
                }, function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
                });
            }
            else {
                $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
            }
        }
        function showLink(event, dataItem) {
            window.open(dataItem.media, '_blank');
        }

    }
})();
