(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('caseConfirmDialog',caseConfirmDialog);

    caseConfirmDialog.$inject = ['$scope','$windowInstance','$translate','message','okBtnText','closeBtnText', 'workOrders', 'selectedDepartmentId'];

    function caseConfirmDialog($scope, $windowInstance, $translate, message, okBtnText, closeBtnText, workOrders, selectedDepartmentId) {
        $scope.init = init;
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.message = message;
        $scope.okBtnText = okBtnText ? okBtnText: $translate.instant('G_OK');
        $scope.closeBtnText = closeBtnText ? closeBtnText : $translate.instant('G_CANCEL');
        $scope.disabled = false;
        $scope.workOrders = workOrders;
        $scope.toggleAllCheckboxModel = false;
        $scope.selectedDepartmentId = selectedDepartmentId;
        $scope.toggleAllCheckbox = toggleAllCheckbox;
        $scope.toggleCheckbox = toggleCheckbox;
        let selectedWorkOrders = [];

        function init(){
            $scope.workOrders.forEach(wo => {
                wo.selected = wo.departmentId === $scope.selectedDepartmentId;
                wo.initSelected = wo.departmentId === $scope.selectedDepartmentId;
                wo.selcted && selectedWorkOrders.push(wo);
            });

            $scope.disabled = $scope.workOrders.every(wo => wo.initSelected);
        }

        function toggleAllCheckbox(){
            selectedWorkOrders = [];

            $scope.workOrders.forEach(wo => {
                if (!wo.initSelected) {
                    wo.selected= $scope.toggleAllCheckboxModel;
                    $scope.toggleAllCheckboxModel && !wo.initSelected ? selectedWorkOrders.push(wo) : selectedWorkOrders = [];
                }
            });
        }

        function toggleCheckbox(workOrder){
            if (!workOrder.selected && selectedWorkOrders.length) {
                const index = $scope.workOrders.findIndex(x => x.woNumber === workOrder.woNumber);
                selectedWorkOrders.splice(index, 1);
            }else {
                !workOrder.initSelected && selectedWorkOrders.push(workOrder);
            }
        }

        function ok() {
            $windowInstance.close({ result: true, selectedWorkOrders: selectedWorkOrders });
        }

        function cancel() {
            $windowInstance.close({ result: false });
        }
    }
})();
