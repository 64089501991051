angular.module('NaviaqWebApp').directive('vesselPopup', [function() {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/shared/popups/vessel-popup.html',
        link: function (scope, element, attributes) {
            scope.$on('showVesselPopup', function (event, model) {
                element.show();

                element.css('left', model.left);
                element.css('top', model.top);

                //Set attributes
                element.find('.name').text(model.shipname);
                element.find('.mmsi').text(model.mmsi);
                element.find('.course').text(model.course + '°');
                element.find('.speed').text(model.speed);
                element.find('.recieved').text(moment(model.timestamp).local().format('DD.MM.YYYY HH:mm:ss'));
            });

            scope.$on('hideVesselPopup', function (event, args) {
                element.hide();
            });
        }
    }
}]);
