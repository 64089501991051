(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ConfirmReasonModalController', confirmModalController);

    confirmModalController.$inject = ['$scope', '$windowInstance', 'content'];

    function confirmModalController($scope, $windowInstance, content) {
        $scope.content = content;

        $scope.ok = ok;
        $scope.cancel = cancel;

        function ok() {
            if ($scope.validator.validate()) {
                $windowInstance.close($scope.reason);
            }
        }

        function cancel() {
            $windowInstance.close(false);
        }
    }
})();
