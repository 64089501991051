(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationInfoBargeInformationController', LocationInfoBargeInformationController);

    LocationInfoBargeInformationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$stateParams',
        'locationService',
        '$translate',
        'kendoUtility',
        '$kWindow',
        'sanitizeService'];

    function LocationInfoBargeInformationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $stateParams,
        locationService,
        $translate,
        kendoUtility,
        $kWindow,
        sanitizeService) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#bargeInformationGrid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#bargeInformationGrid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        var bargeInformationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.bargeInformation),
                update: e => onbargeInformationUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        producer: { editable: true },
                        idNumber: { editable: true, type: 'number' },
                        productionYear: { editable: true, type: 'number' },
                        feedSiloCapacity: { editable: true, type: 'number' },
                        electricity: { editable: true },
                    }
                },
            }
        });

        const bargeInformationGridOptions = langId => ({
            dataSource: bargeInformationDataSource,
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'producer',
                    title: $translate.instant('MAIN_LOCATION_INFO_BARGE_PRODUCER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        if (dataItem.producer === null) {
                            return '-'
                        }
                        return dataItem.producer ? dataItem.producer : '-';
                    },
                },
                {
                    field: 'idNumber',
                    title: $translate.instant('MAIN_LOCATION_INFO_BARGE_ID_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.idNumber;
                    },
                },
                {
                    field: 'productionYear',
                    title: $translate.instant('MAIN_LOCATION_INFO_BARGE_PRODUCTION_YEAR', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.productionYear;
                    },
                },
                {
                    field: 'feedSiloCapacity',
                    title: $translate.instant('MAIN_LOCATION_INFO_BARGE_FEED_SILO_CAPACITY', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.feedSiloCapacity;
                    },
                },
                {
                    field: 'electricity',
                    title: $translate.instant('MAIN_LOCATION_INFO_BARGE_ELECTRICITY', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                            if (dataItem.electricity.id) {
                                dataItem.electricity = dataItem.electricity.id
                            }
                            var electricity = $scope.electricityOptions.find(function (obj) {
                                return obj.id === dataItem.electricity
                            });
                            if (electricity && electricity !== null && electricity.key) {
                                if (electricity.key === "230/400") {
                                    dataItem.electricity = "230400";
                                } else {
                                    dataItem.electricity = electricity.key;
                                }
                            } else {
                                return '-'
                            }
                        }
                        if (dataItem.electricity) {
                            return dataItem.electricity.toString() === "230400" ? "230/400" : dataItem.electricity
                        } else {
                            return '-'
                        }
                    },
                    editor: electricityDropDownEditor,
                },
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: false,
            resizable: false,
            sortable: true,
            groupable: false
        });
        $scope.bargeInformationGridOptions = bargeInformationGridOptions($translate.use());

        $scope.bargeInformation = [];
        $scope.electricityOptions = [];
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.toggleChangesButtons = toggleChangesButtons;

        $scope.$on('langChanged', (event, code) => {
            $scope.bargeInformationGridOptions = bargeInformationGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.bargeInformationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#bargeInformationGrid')]);
            langId = code;
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'bargeInformationGrid',
                destination: '#right-pane',
                overlay: false
            });
            toggleChangesButtons(true)
            let requests = {};
            requests.getBargeInformation = locationService.getBargeInformation($stateParams.LocationNumber);
            requests.getElectricityOptionsWebApi = locationService.getElectricityOptionsWebApi()
            $q.all(requests)
                .then(data => {
                    if (!data.getBargeInformation.length) {
                        $scope.bargeInformation.push({
                            producer: '',
                            idNumber: 0,
                            productionYear: 0,
                            feedSiloCapacity: 0,
                            electricity: null,
                            id: '00000000-0000-0000-0000-000000000000',
                            locationNumber: $stateParams.LocationNumber
                        });
                    } else {
                        $scope.bargeInformation = data.getBargeInformation;
                    }


                    $scope.electricityOptions = data.getElectricityOptionsWebApi
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.bargeInformationGrid.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'bargeInformationGrid');
                });
        }

        function electricityDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('G_SELECT_ELECTRICITY'),
                    dataTextField: 'key',
                    dataValueField: 'id',
                    dataSource: {
                        data: $scope.electricityOptions
                    },
                });
        }

        function saveChanges() {
            $scope.bargeInformationGrid.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.bargeInformationGrid.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-bargeinformation');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-bargeinformation');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onbargeInformationUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'bargeInformationGrid',
                destination: '#bargeInformationGrid'
            });
            let requests = {};
            if (e.data.id === '00000000-0000-0000-0000-000000000000') {
                requests.bargeInformation = locationService.addBargeInformationWebApi(e.data)
            } else {
                requests.bargeInformation = locationService.updateBargeInformationWebApi(e.data)
            }
            $q.all(requests)
                .then(data => {
                    if (data.bargeInformation) {
                        toggleChangesButtons(true);
                        e.success();
                        $scope.bargeInformation[0].id = data.bargeInformation
                        $scope.bargeInformationGrid.dataSource.read();
                        $rootScope.$broadcast('hideBusyIndicator', 'bargeInformationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'bargeInformationGrid');
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'bargeInformationGrid');
                });
        }
    }
})();
