(function () {
    "use strict";

    angular.module("NaviaqWebApp").controller("OperationLogMassEditModalController", OperationLogMassEditDialogController);

    OperationLogMassEditDialogController.$inject = ["$scope", "$rootScope", "$q", "$windowInstance", "actions", "vessels", "logs", 'logService', '$translate', 'orderService'];

    function OperationLogMassEditDialogController($scope, $rootScope, $q, $windowInstance, actions, vessels, logs, logService, $translate, orderService) {

        $scope.orders = [];
        $scope.actions = actions.filter(logAction =>
            logAction.contractorId == $rootScope.authData.contractorId
        );
        $scope.vessels = vessels;
        $scope.logs = logs;

        $scope.logActionFilterOptions = {
            dataTextField: 'description',
            dataValueField: 'logActionId',
            filter: "contains",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.actions);
                    }
                }
            }),
        }

        $scope.operationLogVesselOptions = {
            dataTextField: 'name',
            dataValueField: 'departmentId',
            filter: "contains",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.vessels);
                    }
                }
            }),
        }

        $scope.operationLogOrderOptions = {
            dataTextField: 'displayText',
            dataValueField: 'orderId',
            optionLabel: $translate.instant('OPERATIONLOG_BATCH_EDIT_SELECT_ORDER'),
            filter: "contains",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.orders);
                    }
                }
            }),
        }

        $scope.operationLogOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.logs),
                }
            }),
            //autoBind: false,
            animation: false,
            sortable: false,
            resizable: true,
            filterable: false,
            columns: [
            {
                field: 'doneOn',
                title: $translate.instant('OPERATIONLOG_BATCH_EDIT_DONEON'),
                format: "{0:dd.MM.yyyy HH:mm:ss}",
                type: 'date'
            }, {
                field: 'orderDisplayName',
                title: $translate.instant('OPERATIONLOG_BATCH_EDIT_ORDER')
            }, {
                field: 'departmentName',
                title: $translate.instant('OPERATIONLOG_BATCH_EDIT_DEPARTMENT')
            }, {
                field: 'logActionName',
                title: $translate.instant('OPERATIONLOG_BATCH_EDIT_LOGACTION')
            }]
        }

        initController();
        function initController(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'operationLogBatchEditModalInit',
                destination: '.k-widget.k-window',
                overlay: true,
            });

            $scope.logActionFilterOptions.dataSource.read();
            $scope.operationLogVesselOptions.dataSource.read();
            var logsDepartment = $scope.vessels.find(v => v.departmentId == logs[0].departmentId);
            if(logsDepartment){
                $scope.selectedDepartmentId = logsDepartment.departmentId;
            }

            $scope.logs.forEach(log => {
                var department = $scope.vessels.find(v => v.departmentId == log.departmentId);
                if(department){
                    log.departmentName = department.name;
                } else {
                    log.departmentName = '';
                }

                var logAction = $scope.actions.find(l => l.logActionId == log.logActionId);
                if(logAction){
                    log.logActionName = logAction.description;
                } else {
                    log.logActionName = '';
                }

            });
            $scope.operationLogOverviewGridOptions.dataSource.read();

            var requests = {
                getOrdersForBatchSave: orderService.getOrdersForBatchSave($rootScope.authData.contractorId)
            };

            $q.all(requests).then(function(results){
                $scope.orders = results.getOrdersForBatchSave;
                extendOrders();
                $scope.orders = _.sortBy($scope.orders, 'externalOrderId');
                $scope.operationLogOrderOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'operationLogBatchEditModalInit');
            });
        }

        function extendOrders(){
            $scope.orders.forEach(order => {
                if(order.customer && order.customer.name){
                    var customerName = order.customer.name;
                } else {
                    var customerName = null;
                }

                if(order.location && order.location.name){
                    var locationName = order.location.name;
                } else {
                    var locationName = null;
                }
                order.displayText = order.externalOrderId + (customerName ? ` - ${customerName}` : '') +  (locationName ? ` - ${locationName}` : '');
            });
        }

        $scope.closeModal = function(){
            $windowInstance.close();
        }

        $scope.save = function(){
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'operationLogBatchEditModalSave',
                    destination: '.k-widget.k-window',
                    overlay: true,
                });
                var logIds = $scope.logs.map(l => l.operationLogId);
                var orderId = $scope.selectedOrderId || null;
                var departmentId = $scope.selectedDepartmentId || null;
                var logActionId = $scope.selectedLogActionId || null;
                logService.updateOperationLogs(logIds, orderId, departmentId, logActionId).then(function(){
                    $windowInstance.close(true);
                }).finally(function(){
                    $rootScope.$broadcast('hideBusyIndicator', 'operationLogBatchEditModalSave');
                });
            }
        }
    }
})();
