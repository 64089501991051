(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('PrivacyAgreementController', PrivacyAgreementController);

    PrivacyAgreementController.$inject = ['$rootScope', '$scope', 'authService', 'userService'];

    function PrivacyAgreementController($rootScope, $scope, authService, userService) {

        var authData = authService.getAuthData();

        $scope.userHasConsent = getConsent;

        init();
        function init() {
            getConsent();
        }

        function getConsent() {
            if (authData.userId) {
                $rootScope.$broadcast('showMainBusyIndicator', 'privacyIndicator');
                userService.getConsentByUserId(authData.userId).then(function (result) {
                    $scope.consent = result;
                }).finally(function(){
                    $rootScope.$broadcast('hideBusyIndicator', 'privacyIndicator');
                });
            }
        }
    }
})();
