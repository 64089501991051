(function() {
    'use strict';

    angular.module('NaviaqWebApp').factory('roleService', roleService);

    roleService.$inject = ['$q', '$http', 'serviceUrls'];

    function roleService($q, $http, serviceUrls) {
        const webApiBaseUrl = `${serviceUrls.webApiBaseUrl}/user`;

        return {
            getRoles,
            getRolesByUserId: getRolesByUserId
        };

        function getRoles() {
            const deferred = $q.defer(),
                requestUrl = `${webApiBaseUrl}/roles`;

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getRolesByUserId(userId) {
            const deferred = $q.defer();

            $http.get(`${webApiBaseUrl}/${userId}/roles`).then(function(response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
