(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OrderWorkReportController', OrderWorkReportController);

    OrderWorkReportController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'reportService',
        'commonUtility',
        'anonymousReportService'];

    function OrderWorkReportController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        reportService,
        commonUtility,
        anonymousReportService) {

        $scope.contractorId = $stateParams.ContractorId;
        $scope.orderId = $stateParams.OrderId;
        $scope.reportFolder = $stateParams.ReportFolder;
        $scope.reportImageUrl = '';
        $scope.reportData = {};

        $scope.getOrderWorkReportData = getOrderWorkReportData;
        $scope.goBack = goBack;
        $scope.setReportLogo = setReportLogo;
        $scope.controlTypes = reportService.getControlTypeValues();

        $scope.printReport = printReport;
        $scope.rotateReport = rotateReport;
        $scope.toggleCheckList = toggleCheckList;
        $scope.toggleMainComponents = toggleMainComponents;

        if ($scope.contractorId && $scope.orderId) {
            $rootScope.$broadcast('showMainBusyIndicator','orderWorkReport');
        }

        //Calculate height for #report-details-container
        var bodyHeight = $('body').height();

        var newContainerHeight = bodyHeight - 60;
        $('#order-work-report-container').height(newContainerHeight);

        getOrderWorkReportData();

        function goBack() {
            $state.go('main.orderReport', {
                ContractorId: $scope.contractorId,
                OrderId: $scope.orderId
            });
        }

        function getOrderWorkReportData() {
            anonymousReportService.getOrderWorkReportContent($scope.contractorId, $scope.orderId).then(function (data) {
                setReportLogo();

                $scope.reportData = data;
                $scope.operationLogMedias = $scope.reportData.operationLogMedias;
                $scope.vessels = $scope.reportData.departments ? $scope.reportData.departments : [];
                $scope.reportData.order.orderDate = moment($scope.reportData.order.orderDate).format('DD.MM.YYYY');
                $scope.reportData.order.expireDate = $scope.reportData.order.expireDate ? moment($scope.reportData.order.expireDate).format('DD.MM.YYYY') : '';
                $scope.reportData.order.orderedDate = $scope.reportData.order.orderedDate ? moment($scope.reportData.order.orderedDate).format('DD.MM.YYYY') : '';

                if ($scope.reportData.order.orderLines.length > 0) {
                    $scope.hasOrderLines = true;
                }

                if ($scope.reportData.stretchData) {
                    $scope.reportData.stretchData.date = $scope.reportData.stretchData.date ? moment($scope.reportData.stretchData.date).format('DD.MM.YYYY') : '';
                    $scope.hasStretchActivity = true;
                }

                if ($scope.reportData.operationLogs && $scope.reportData.operationLogs.length > 0) {
                    $scope.hasOrderOperationLogs = true;
                    for (var o = 0; o < $scope.reportData.operationLogs.length; o++) {
                        reportService.extendLog($scope.reportData.operationLogs[o], $scope.operationLogMedias);
                    }
                }

                if ($scope.reportData.deviationLogs && $scope.reportData.deviationLogs.length > 0) {
                    $scope.hasOrderDeviationLogs = true;
                    for (var d = 0; d < $scope.reportData.deviationLogs.length; d++) {
                        reportService.extendLog($scope.reportData.deviationLogs[d], $scope.operationLogMedias);
                    }
                }

                reportService.extendMapObjectWithActivites($scope.reportData.mapObjectWithActivitiesList, $scope.controlTypes, $scope.operationLogMedias);

                $rootScope.$broadcast('hideBusyIndicator', 'orderWorkReport');
            });
        }

        function setReportLogo() {
            $scope.reportFolder = $scope.reportFolder ? $scope.reportFolder : 'NAVIAQ';
            $scope.reportImageUrl = '../img/reports/' + $scope.reportFolder + '/Images/Logo-width-1200.png';
            commonUtility.isImage($scope.reportImageUrl);
        }

        String.prototype.asVesselName = function () {
            var searchedId = this,
                vessel = _.find($scope.vessels, function (o) { return o.departmentId === searchedId; });

            if (vessel) {
                return vessel.name;
            }

            return this;
        };

        function toggleCheckList() {
            $('.paging-turn-text').toggle();
            $('.map-object-head').toggleClass('paging-off');
            $('#orderLines').toggleClass('paging-off');
        }

        function toggleMainComponents() {
            $('.toggle-main-components-button-text').toggle();
            $('.main-components-data').toggle();
        }

        function printReport() {
            $('#main-view').addClass('print-view');
            $('#order-work-report-container').addClass('print-view-container');
            document.title = "Arbeidsrapport -" + kendo.toString(new Date(), 'HH:mm dd.MM.yyyy');
            window.print();

            setTimeout(function(){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'OperationLogReportIndicator',
                    destination: '#main-view',
                    overlay: true
                });

                document.title = "Naviaq Webinnsyn";
                $('#main-view').removeClass('print-view');
                $('#order-work-report-container').removeClass('print-view-container');
                $rootScope.$broadcast('hideBusyIndicator', 'OperationLogReportIndicator');
            }, 1);
        }

        function rotateReport() {
            $('.report').toggleClass('wide-report');
        }
    }
})();
