(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('TourReportController', TourReportController);

    TourReportController.$inject = ['$q', '$scope', '$state', '$stateParams', '$rootScope', '$timeout', 'itemTreeService', 'orderService', 'logService'];

    function TourReportController($q, $scope, $state, $stateParams, $rootScope, $timeout,
        itemTreeService, orderService, logService) {

        var activityIdSigniture1 = 'Brønnbåt signatur 1';
        var activityIdSigniture2 = 'Brønnbåt signatur 2';

        $scope.contractorId = $stateParams.ContractorId;
        $scope.orderId = $stateParams.OrderId;
        $scope.fiskers = [];
        $scope.goBack = function () {
            $state.go('main.orderReport', {
                ContractorId: $scope.contractorId,
                OrderId: $scope.orderId
            });
        };

        var loading = {};
        var unloading = {};
        var transport = {};

        $timeout(250).then(activate);

        function findActivityLine(activityLines, name) {
            var result = _.find(activityLines, function (activity) {
                return activity && activity.name && activity.name.indexOf(name) !== -1;
            });

            if (result != undefined) {
                return result.value;
            }
            else {
                return '';
            }
        }

        function findListValue(listname, guid) {
            var items = _.find($scope.basisData, function (listItem) {
                return listItem.ContractorId === $scope.contractorId && listItem.PlaceHolderId.indexOf(listname) !== -1;
            });
            if (items != undefined && items.ListElements.length > 0) {
                var result = _.find(items.ListElements, function (item) {
                    return item.Id === guid;
                });
                if (result != undefined) {
                    return result.Value;
                }
                else {
                    return '';
                }
            }
            else {
                return '';
            }

        }

        function findActivityLineSigniture(activityLines, name, activityId) {
            var result = _.find(activityLines, function (activity) {
                return activity.Name.indexOf(name) !== -1 && activity.ActivityId == activityId;
            });
            if (result != undefined) {
                return result.Value;
            }
            else {
                return '';
            }
        }

        function yesNoConverter(text) {
            var result = '';
            if (text == undefined || text === '') {
                result = 'Nei';
            } else {
                result = 'Ja';
            }
            return result;
        }

        function dateSplit(date, number) {
            var result = '';

            if (date) {
                var dates = date.split('|');
                if (dates.length === 1 && number === 1) {
                    result = '';
                }
                else {
                    return dates[number];
                }
            }

            return result;
        }

        function sumFiskerFirstThree(type) {
            var result = 0;
            if ($scope.fiskers['0'] != undefined) {
                result += $scope.fiskers['0'][type];
            }
            if ($scope.fiskers['1'] != undefined) {
                result += $scope.fiskers['1'][type];
            }
            if ($scope.fiskers['2'] != undefined) {
                result += $scope.fiskers['2'][type];
            }
            return result;
        }

        function activate() {
            $('#tour-report-container').height($(window).height() - 60);

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'report',
                destination: '#tour-report-container'
            });

            var orderLoadPromise = orderService.getOrderWithActivityData($scope.orderId).then(function (order) {
                //loading.supplier.tourNr = order;
                loading.activityGroup = order.activityGroup;
                loading.vessel = order.departments[0].name;
                loading.vesselPhone = order.departments[0].departmentInfo.phone;
                loading.email = order.departments[0].departmentInfo.email;
                loading.location = order.locationName;
                loading.orderYear = moment(order.orderDate).format('YYYY');
                loading.orderWeek = moment(order.orderDate).format('WW');
                loading.tourNr = order.tourNumber;
                loading.supplierName = order.customer.name;
                loading.Kontaktperson = findActivityLine(order.activityLines, 'Kontaktperson');
                loading.Telefon = findActivityLine(order.activityLines, 'Telefon');
                loading.TurStart = dateSplit(findActivityLine(order.activityLines, 'Tur start/stopp'), 0);

                //1. Lasting
                loading.Fiskesort = findListValue('FISKESORT', findActivityLine(order.activityLines, 'Fiskesort'));

                var ankomsttid = findActivityLine(order.activityLines, 'Avtalt ankomsttid');
                loading.AvtaltAnkomsttid = ankomsttid ? moment(ankomsttid).format('DD.MM.YYYY hh:mm:ss') : '';
                loading.Avgangshavn = findActivityLine(order.activityLines, 'Avgangshavn');
                loading.LastingStartet = findActivityLine(order.activityLines, 'Lasting startet');
                loading.LastingAvsluttet = findActivityLine(order.activityLines, 'Lasting avsluttet');
                loading.Argang = findActivityLine(order.activityLines, 'Årgang');
                loading.AnmerkningTilLasting = findActivityLine(order.activityLines, 'Anmerkning til lasting');

                var sulting = findActivityLine(order.activityLines, 'Sulting (Siste dag med foring)');
                loading.Sulting = sulting ? moment(sulting).format('DD.MM.YYYY hh:mm:ss') : '';
                loading.UtfortVeterinarkontoll = yesNoConverter(findActivityLine(order.activityLines, 'Utført veterinærkontoll'));
                loading.OksygenmetningAnkLasteplass = findActivityLine(order.activityLines, 'Oksygenmetning ank.lasteplass');
                loading.RapportSendtSlakteri = yesNoConverter(findActivityLine(order.activityLines, 'Rapport sendt slakteri'));
                loading.LastingStart = dateSplit(findActivityLine(order.activityLines, 'Lasting start/stopp'), 0);
                loading.LastingStopp = dateSplit(findActivityLine(order.activityLines, 'Lasting start/stopp'), 1);
                loading.Sultetid = findListValue('NUMBERVALUES', findActivityLine(order.activityLines, 'Sultetid'));
                loading.LasteplassAnkomst = dateSplit(findActivityLine(order.activityLines, 'Lasteplass ankomst/avgang'), 0);
                loading.LasteplassAvgang = dateSplit(findActivityLine(order.activityLines, 'Lasteplass ankomst/avgang'), 1);
                loading.Restriksjoner = yesNoConverter(findActivityLine(order.activityLines, 'Restriksjoner'));
                loading.MetodeLasting = findListValue('LASTEMETODE', findActivityLine(order.activityLines, 'Lastemetode'));
                loading.Sjotemperatur = findActivityLine(order.activityLines, 'Sjøtemperatur');
                loading.EvSykdomFiskehelse = findActivityLine(order.activityLines, 'Evt. sykdom / fiskehelse');

                loading.tidsforbrukLasting = getTimeDiffString(loading.LasteplassAvgang, loading.LasteplassAnkomst);

                var bronnbat1 = findActivityLine(order.activityLines, activityIdSigniture1);
                loading.Bronnbat = bronnbat1;
                loading.Leverandor = findActivityLine(order.activityLines, 'Leverandør');

                //3. Lossing
                var slakteriValue = findActivityLine(order.activityLines, 'Slakteri');
                var lokalitetValue = findActivityLine(order.activityLines, 'Lokalitet');
                if (slakteriValue === 'True' && lokalitetValue === 'False') {
                    unloading.SlakteriLokalitetDisplay = 'Slakteri';

                } else if (slakteriValue === 'False' && lokalitetValue === 'True') {
                    unloading.SlakteriLokalitetDisplay = 'Lokalitet';
                } else {
                    unloading.SlakteriLokalitetDisplay = 'Slakteri/Lokalitet';
                }

                unloading.SlakteriLokalitetDisplayValue = findActivityLine(order.activityLines, 'Navn losseplass:');
                unloading.Bronnbat = findActivityLine(order.activityLines, activityIdSigniture2);
                unloading.Mottaker = findActivityLine(order.activityLines, 'Mottaker');
                unloading.Lastemetode = findActivityLine(order.activityLines, 'Lastemetode');
                unloading.Biomasse = findActivityLine(order.activityLines, 'Biomasse');
                unloading.LossingStart = dateSplit(findActivityLine(order.activityLines, 'Lossing start/ slutt'), 0);
                unloading.LossingSlutt = dateSplit(findActivityLine(order.activityLines, 'Lossing start/ slutt'), 1);
                unloading.FerdigLosset = findActivityLine(order.activityLines, 'Ferdig losset');
                unloading.LosseplassAnkomst = dateSplit(findActivityLine(order.activityLines, 'Losseplass ankomst/ avgang'), 0);
                unloading.LosseplassAvgang = dateSplit(findActivityLine(order.activityLines, 'Losseplass ankomst/ avgang'), 1);
                unloading.AftBronn = findActivityLine(order.activityLines, 'Aft brønn');
                unloading.CentBronn = findActivityLine(order.activityLines, 'Cent brønn');
                unloading.FwdBronn = findActivityLine(order.activityLines, 'Fwd brønn');
                unloading.MetodeLossing = findActivityLine(order.activityLines, 'Metode lossing');
                unloading.AvlestSjotemperaturVedLossing = findActivityLine(order.activityLines, 'Avlest sjøtemperatur ved lossing');
                unloading.SvimereDodfiskUnderveis = findActivityLine(order.activityLines, 'Svimere/ dødfisk underveis');
                unloading.AnmerkningTilLossing = findActivityLine(order.activityLines, 'Anmerkning til lossing');
                unloading.TurStop = dateSplit(findActivityLine(order.activityLines, 'Tur start/stopp'), 1);

                unloading.tidsforbrukLossing = getTimeDiffString(unloading.LossingSlutt, unloading.LossingStart);
                unloading.tidsforbrukpaturen = getTimeDiffString(unloading.TurStop, loading.TurStart);

                transport.Transport = findActivityLine(order.activityLines, 'Transport');
                transport.AnmerkningTilLastereise = findActivityLine(order.activityLines, 'Anmerkning til lastereise');
                transport.Sjotemperatur = findActivityLine(order.activityLines, 'Sjøtemperatur');
            });

            itemTreeService.getItemsByRelatedEntityIdWebApi($scope.contractorId, $scope.orderId).then(function (response) {
                _.each(response, function (record) {
                    $scope.fiskers.push(JSON.parse(record.itemDetails));
                });

                loading.SumAlleMerderNumber = sumFiskerFirstThree('Number');
                loading.SumAlleMerderWeight = sumFiskerFirstThree('Weight');

                if (loading.SumAlleMerderNumber === 0) {
                    loading.SumAlleMerderAvg = 0;
                }
                else {
                    loading.SumAlleMerderAvg = (loading.SumAlleMerderWeight / loading.SumAlleMerderNumber).toFixed(2);
                }
            });

            $scope.loading = loading;
            $scope.unloading = unloading;
            $scope.transport = transport;

            logService.getBasisData($scope.authData.contractorId).then(function (basisData) {
                if (basisData && basisData.length > 0) {
                    $scope.basisData = basisData;
                }

                $q.all([orderLoadPromise]).then(function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'report');
                });
            });
        }

        function getTimeDiffString(ds1, ds2) {
            var diffString = '',
                d1 = moment(ds1, 'dd.MM.yyyy hh:mm:ss'),
                d2 = moment(ds2, 'dd.MM.yyyy hh:mm:ss');

            if (ds1 !== '' && ds2 !== '') {
                var seconds = d1.diff(d2) / 1000;
                if (seconds !== NaN) {
                    var totalMinutes = parseInt(seconds / 60),
                        hours = parseInt(totalMinutes / 60),
                        minutes = totalMinutes - hours * 60;

                    diffString = hours + ' Timer ' + minutes + ' Minutter';
                }
            }

            return diffString;
        }
    }
})();
