(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OperationDetailsModalController', operationDetailsModalController);

    operationDetailsModalController.$inject = ['$timeout', '$scope', '$windowInstance', 'departmentService', 'locationService',
        'operationService', 'dateUtility', 'formTypes', 'currentFormType', 'operation'];

    function operationDetailsModalController($timeout, $scope, $windowInstance, departmentService, locationService,
        operationService, dateUtility, formTypes, currentFormType, operation) {

        $scope.description = '';

        $scope.internalVesselsOptions = {
            dataSource: {
                transport: {
                    read: readDepartments
                }
            },
            dataValueField: 'departmentId',
            dataTextField: 'name'
        };

        $scope.operationTypeOptions = {
            dataSource: {
                transport: {
                    read: readOperationTypes
                }
            },
            dataValueField: 'id',
            dataTextField: 'name',
            optionLabel: 'Velg operasjon'
        };

        $scope.locationOptions = {
            dataSource: {
                transport: {
                    read: readLocations
                }
            },
            dataValueField: 'locationNumber',
            dataTextField: 'locationName',
            optionLabel: 'Velg lokalitet'
        };

        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;

        kendo.culture('no-NO');

        $timeout(250).then(initModal);

        function initModal() {
            if (currentFormType === formTypes.edit) {
                $scope.description = operation.title;
                $scope.operationStart = operation.start;
                $scope.operationEnd = operation.end;
                $scope.selectedInternalVesselIds = operation.departments;
                $scope.selectedOperationTypeId = operation.OperationTypeId;
                $scope.selectedLocationNumber = operation.LocationNumber;
            }
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                var currentOperation = {
                    description: $scope.description,
                    operationTypeId: $scope.selectedOperationTypeId,
                    fromDate: dateUtility.toJson($scope.operationStart),
                    toDate: dateUtility.toJson($scope.operationEnd),
                    contractorId: $scope.authData.contractorId,
                    operationVessels: mapOpverationVessels($scope.selectedInternalVesselIds, operation),
                    locationNumber: $scope.selectedLocationNumber
                };

                if (currentFormType === formTypes.add) {
                    operationService.addOperation(currentOperation).then(function() {
                        closeModal(true);
                    }, function() {
                        closeModal();
                    });
                } else {
                    currentOperation.id = operation.id;

                    operationService.updateOperation(currentOperation).then(function() {
                        closeModal(true);
                    }, function() {
                        closeModal();
                    });
                }
            }
        }

        function readDepartments(e) {
            departmentService.getDepartmentsWebapi($scope.authData.contractorId, true).then(function (departments) {
                console.log(departments);
                e.success(departments);
            }, function() {
                e.success([]);
            });
        }

        function readOperationTypes(e) {
            operationService.getOperationTypes($scope.authData.contractorId).then(function (operationTypes) {
                e.success(operationTypes);
            }, function() {
                e.success([]);
            });
        }

        function readLocations(e) {
            locationService.getLocationsWebApi($scope.authData.contractorId).then(function(locations) {
                e.success(locations);
            }, function() {
                e.success([]);
            });
        }

        function mapOpverationVessels(vesselIds, operation) {
            var operationId = operation != null ? operation.id : '00000000-0000-0000-0000-000000000000';

            return _.map(vesselIds, function (vesselId) {
                return {
                    OperationId: operationId,
                    DepartmentId: vesselId,
                    Type: 0
                };
            });
        }

        function closeModal(result) {
            $windowInstance.close();
        }
    }
})();
