(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('arrivalReportService', arrivalReportService);

        arrivalReportService.$inject = [
        '$q',
        '$http',
        'serviceUrls',
    ];

    function arrivalReportService(
        $q,
        $http,
        serviceUrls,
    ) {
        return {
            getArrivalReportsByOrderId,
        };

        function getArrivalReportsByOrderId(orderId) {
            const deferred = $q.defer();

            var requestUrl = `${serviceUrls.webApiBaseUrl}/arrivalReport/list/byOrderId?orderId=${orderId}`;
            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
