(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationInfoController', LocationInfoController);

    LocationInfoController.$inject = ['$scope', '$state', '$translate', '$kWindow', '$stateParams', 'locationService'];

    function LocationInfoController($scope, $state, $translate, $kWindow, $stateParams, locationService) {
        $scope.tabStripOptions = {
            animation: false,
            scrollable: false
        };

        $scope.tabsVisibility = {
            details: true,
            marinogram: !locationService.isDefaultLocation($stateParams.LocationNumber),
            coordinates: true,
        };

        $scope.openInformationModal = openInformationModal;
         // Tooltip
         $scope.toolTipOptions = {
            position: "top",
            autoHide: false,
            content: function(e) {
                  return  $translate.instant('CREDIT_PROVIDED_BY') + ' BW, <span ng-click="openInformationModal()" >' + $translate.instant('CREDIT_CLICK_MORE_INFO') + '</span>';

            },
            show: function(e) {
              this.popup.element[0].style.width = "300px";
            }
          }


        initController();

        function initController() {
            $scope.$watch("locationinformationtab", function () {
                switch ($state.current.name) {
                    case "main.locationInfo.details":
                        $scope.locationinformationtab.select(0);
                        break;
                    case "main.locationInfo.marinogram":
                        $scope.locationinformationtab.select(1);
                        break;
                    case "main.locationInfo.coordinates":
                        $scope.locationinformationtab.select(2);
                        break;
                    default:
                        $scope.locationinformationtab.select(0);
                        break;
                }
            });
        }

        function openInformationModal(){
            $kWindow.open({
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/shared/popups/credit-information-dialog.html',
                controller: 'creditInformationDialogController',
                resolve: {},
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: $translate.instant('CREDIT_TITLE_INFO'),
                    height: 350,
                    width: 600
                }
            });
        }
    }
})();
