(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('DayCounterAdministrationController', dayCounterAdministrationController);

    dayCounterAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'dateUtility',
        'formTypes',
        'dayCounterService',
        'departmentService',
        'logActionService',
        'commonUtility',
        'htmlElementService',
        'sanitizeService'
    ];

    function dayCounterAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        dateUtility,
        formTypes,
        dayCounterService,
        departmentService,
        logActionService,
        commonUtility,
        htmlElementService,
        sanitizeService
    ) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];

        var checkDayCounterInactive = function (dayCounter) {
            return dayCounter.toDate && moment(dayCounter.fromDate) > moment(dayCounter.toDate)
        }

        window.onbeforeunload = function () {
            if ($('#day-counter-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#day-counter-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        var dayCounterAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.dayCounters),
                update: e => onDayCounterGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'dayCounterTypeId',
                    fields: {
                        description: { editable: true, validation: { required: true } },
                        logActionId: { editable: true },
                        intervalDays: { editable: true, type: 'number', validation: { required: true, min: 1 } },
                        fromDate: { editable: true, type: 'date', validation: { required: true } },
                        toDate: { editable: true, type: 'date' },
                        departmentId: { editable: true },
                        dueDays: { editable: true, type: 'number', validation: { min: 1 } },
                        editableAfterDueDate: { editable: false },
                        createdByName: { editable: false },
                        createdOn: { editable: false, type: 'date' },
                    }
                },
            }
        });

        const dayCounterAdministrationGridOptions = langId => ({
            dataSource: dayCounterAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'dayCounterTypeId',
                    hidden: true
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_DAY_COUNTER_DESCRIPTION', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.description;
                    },
                },
                {
                    field: 'logActionId',
                    title: $translate.instant('G_CHECKLIST', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);

                            if (dataItem.logActionId.logActionId) {
                                dataItem.logActionId = dataItem.logActionId.logActionId
                            }

                            var departmentObject = $scope.selectableData.selectableLogActions.find(obj => {
                                return obj.logActionId === dataItem.logActionId
                            });
                            dataItem.logActionName = departmentObject.description;
                            dataItem.logActionId = departmentObject.logActionId;

                            return departmentObject.description;
                        }
                        return dataItem.logActionName
                    },
                    editor: logActionsDropDownEditor,
                    groupHeaderTemplate: $translate.instant('G_CHECKLIST', null, null, langId) + ": #=items[0] && items[0].logActionName ? items[0].logActionName : '' #"
                },
                {
                    field: 'intervalDays',
                    title: $translate.instant('ADMIN_DAY_COUNTER_INTERVAL_DAYS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.intervalDays ? dataItem.intervalDays : '';
                    },
                },
                {
                    field: 'fromDate',
                    title: $translate.instant('G_DATEFROM', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return (dataItem.fromDate ? dateUtility.formatDate(dataItem.fromDate) : '');
                    },
                },
                {
                    field: 'toDate',
                    title: $translate.instant('G_DATETO', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return (dataItem.toDate ? dateUtility.formatDate(dataItem.toDate) : '');
                    },
                },
                {
                    field: 'departmentId',
                    title: $translate.instant('ADMIN_DAY_COUNTER_DEPARTMENT', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);

                            if (dataItem.departmentId.departmentId) {
                                dataItem.departmentId = dataItem.departmentId.departmentId
                            }
                            var departmentObject = $scope.selectableData.selectableDepartments.find(obj => {
                                return obj.departmentId === dataItem.departmentId
                            });
                            dataItem.departmentName = departmentObject.name;
                            dataItem.departmentId = departmentObject.departmentId;

                            return departmentObject.name;
                        }

                        return dataItem.departmentName
                    },
                    editor: departmentsDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_DAY_COUNTER_DEPARTMENT', null, null, langId) + ": #=items[0] && items[0].departmentName ? items[0].departmentName : '' #"
                },
                {
                    field: 'dueDays',
                    title: $translate.instant('G_DUEDAYS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.dueDays ? dataItem.dueDays : '';
                    },
                },
                {
                    field: 'editableAfterDueDate',
                    title: $translate.instant('ADMIN_DAY_COUNTER_EDITABLE_AFTER_DUE_DATE', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.editableAfterDueDate ? 'checked' : '';
                        const disabled = dataItem.editableAfterDueDate === null ? 'disabled' : '';
                        return `<div class="center-content"><input type="checkbox" class="editable-after-due-date-checkbox" ${checked} ${disabled} /></div>`;
                    },
                },
                {
                    field: 'createdByName',
                    title: $translate.instant('G_CREATED_BY', null, null, langId),
                },
                {
                    field: 'createdOn',
                    title: $translate.instant('G_CREATED_ON', null, null, langId),
                    template: dataItem => (dataItem.createdOn ? dateUtility.formatDate(dataItem.createdOn) : ''),
                    width: 100
                },
                {
                    command: [
                        {
                            name: 'editDayCounter',
                            click: e => editDayCounter(e),
                            template: '<a class="k-grid-editDayCounter"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'setDayCounterActiveStatus',
                            click: e => setDayCounterActiveStatus(e),
                            template: '<a class="k-grid-setDayCounterActiveStatus"><i class="fas fa-ban"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            dataBound: onDataBound,
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true
        });
        $scope.dayCounterAdministrationGridOptions = dayCounterAdministrationGridOptions($translate.use());
        $scope.dayCounters = [];
        $scope.selectableData = {};
        $scope.addNewDayCounter = addNewDayCounter;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editDayCounter = editDayCounter;
        $scope.filterTable = filterTable;

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.dayCounterAdministrationGridOptions = dayCounterAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.dayCounterAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#day-counter-administration-grid')]);
            langId = code;
        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'dayCounterAdministrationGrid',
                destination: '#day-counter-administration-grid'
            });
            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });
            addCheckboxChangeEventListener()
            toggleChangesButtons(true)
            let requests = {};

            var filter = {
                contractorId: $scope.authData.contractorId
            };

            requests.dayCounters = dayCounterService.getDayCounters($rootScope.authData.contractorId);
            requests.getDepartmentsWebapi = departmentService.getDepartmentsWebapi($rootScope.authData.contractorId, true);
            requests.getActiveLogActionsAsDropdownByFilter = logActionService.getActiveLogActionsAsDropdownByFilter(filter);
            $q.all(requests)
                .then(data => {
                    $scope.dayCounters = data.dayCounters;
                    $scope.selectableData.selectableDepartments = data.getDepartmentsWebapi
                    $scope.selectableData.selectableLogActions = data.getActiveLogActionsAsDropdownByFilter
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.dayCounterAdministrationGridOptions.dataSource.read();
                    disableInactiveLines();
                    $rootScope.$broadcast('hideBusyIndicator', 'dayCounterAdministrationGrid');
                });
        }

        function disableInactiveLines() {
            _.forEach($scope.dayCounters, function (dayCounter) {
                if (checkDayCounterInactive(dayCounter)) {
                    disableInactiveLine(dayCounter.dayCounterTypeId)
                }
            });
        }

        function disableInactiveLine(dayCounterId) {
            var $tr = $(`span:contains("${dayCounterId}")`).parent().parent();
            if (!$tr.hasClass('disabled-line')) {
                $tr.addClass('disabled-line')
                $tr.children('.k-command-cell').children('.k-grid-setDayCounterActiveStatus').children().first().removeClass('fa-ban').addClass('fa-check')
            }
        }

        function addCheckboxChangeEventListener() {
            $("#day-counter-administration").on("change", ".editable-after-due-date-checkbox", function (e) {
                var grid = $("#day-counter-administration-grid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");

                dataItem.editableAfterDueDate = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });
        }

        function logActionsDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('G_SELECT_CHECKLIST'),
                    dataTextField: 'description',
                    dataValueField: 'logActionId',
                    dataSource: {
                        data: $scope.selectableData.selectableLogActions
                    },
                });
        }

        function departmentsDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('G_SELECT_VESSEL'),
                    dataTextField: 'name',
                    dataValueField: 'departmentId',
                    dataSource: {
                        data: $scope.selectableData.selectableDepartments
                    }
                });
        }

        function addNewDayCounter() {
            const window = openDayCounterDetailsModal(formTypes.add);
            window.result.then(result => {
                if (result) {
                    var department = $scope.selectableData.selectableDepartments.find(obj => {
                        return obj.departmentId === result.departmentId
                    });

                    var logAction = $scope.selectableData.selectableLogActions.find(obj => {
                        return obj.logActionId === result.logActionId
                    });

                    result.departmentName = department.name
                    result.logActionName = logAction.description
                    $scope.dayCounters = $scope.dayCounters.concat([result]);
                    $scope.dayCounterAdministrationGridOptions.dataSource.read();
                    disableInactiveLines();
                }
            });
        }

        function editDayCounter(event) {
            const window = openDayCounterDetailsModal(formTypes.edit, getDayCounterFromGrid(event));
            window.result.then(result => {
                var department = $scope.selectableData.selectableDepartments.find(obj => {
                    return obj.departmentId === result.departmentId
                });

                var logAction = $scope.selectableData.selectableLogActions.find(obj => {
                    return obj.logActionId === result.logActionId
                });

                result.departmentName = department.name
                result.logActionName = logAction.description
                handleUpdate(result)
            });
        }

        function handleUpdate(dayCounter) {
            if (dayCounter) {
                $scope.dayCounters = $scope.dayCounters.map(u => (u.dayCounterTypeId === dayCounter.dayCounterTypeId ? dayCounter : u));
                $scope.dayCounterAdministrationGridOptions.dataSource.read();
                disableInactiveLines();
            }
        }

        function getDayCounterFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#day-counter-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function setDayCounterActiveStatus(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'dayCounterAdministrationGrid',
                destination: '#day-counter-administration-grid'
            });
            let requests = {};
            var dayCounter = getDayCounterFromGrid(e);
            var isDayCounterInactive = checkDayCounterInactive(dayCounter);
            var toDate = isDayCounterInactive ? moment().add(5, 'year').format() : moment().add(-5, 'year');
            dayCounter.toDate = toDate

            requests.setDayCounterActiveStatus = dayCounterService.updateDayCounter(dayCounter);
            $q.all(requests)
                .then(data => {
                    $scope.dayCounters = $scope.dayCounters.map(u => (u.dayCounterTypeId === data.setDayCounterActiveStatus.dayCounterTypeId ? data.setDayCounterActiveStatus : u));
                    $scope.dayCounterAdministrationGridOptions.dataSource.read();
                    disableInactiveLines();
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'dayCounterAdministrationGrid');
                });
        }

        function openDayCounterDetailsModal(formType, dayCounter) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: dayCounter ? dayCounter.description : $translate.instant('ADMIN_DAY_COUNTER_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/administration/dayCounter/day-counter-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DayCounterDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    dayCounter: () => dayCounter,
                    selectableData: () => $scope.selectableData,
                }
            });
        }

        function onDataBound(e) {
            const data = this.dataSource.view();
            for (let i = 0; i < data.length; i++) {
                if (data[i].inactive) {
                    const uid = data[i].uid;
                    const row = this.table.find("tr[data-uid='" + uid + "']");
                    let item = row.find('.fas.fa-ban');
                    item.removeClass('fa-ban').addClass('fa-check');
                }
            }
        }

        function filterTable() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'dayCounterAdministrationGrid',
                destination: '#day-counter-administration-grid'
            });
            var filterChangesButton = $('button.k-button.k-grid-filter-dayCounter');
            let requests = {};
            requests.dayCounters = dayCounterService.getDayCounters($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    $scope.dayCounters = data.dayCounters;
                    if (filterChangesButton.hasClass("filtered")) {
                        filterChangesButton.removeClass('filtered');
                        filterChangesButton.text($translate.instant('ADMIN_DAY_HIDE_EXPIRED'));
                        $scope.dayCounterAdministrationGridOptions.dataSource.read();
                        disableInactiveLines();
                    } else {
                        filterChangesButton.addClass('filtered');
                        filterChangesButton.text($translate.instant('ADMIN_DAY_SHOW_EXPIRED'));
                        $scope.dayCounters = $scope.dayCounters.filter(obj => {
                            return moment(obj.toDate).isAfter(moment(), 'days');
                        });
                        $scope.dayCounterAdministrationGridOptions.dataSource.read();
                        disableInactiveLines();
                    }
                })
                .catch(error => console.error(error));
            $rootScope.$broadcast('hideBusyIndicator', 'dayCounterAdministrationGrid');
        }

        function saveChanges() {
            $scope.dayCounterAdministrationGridOptions.dataSource.sync();
            disableInactiveLines();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.dayCounterAdministrationGridOptions.dataSource.read();
            disableInactiveLines();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-dayCounter');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-dayCounter');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onDayCounterGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'dayCounterAdministrationGrid',
                destination: '#day-counter-administration-grid'
            });
            dayCounterService.updateDayCounter(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'dayCounterAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'dayCounterAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'dayCounterAdministrationGrid');
                });

        }
    }
})();
