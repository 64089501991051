(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('personDetailsModalAdministrationController', personDetailsModalAdministrationController);

    personDetailsModalAdministrationController.$inject = [
        '$translate',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'person',
        'currentFormType',
        'formTypes',
        'personService',
        'locations'
    ];

    function personDetailsModalAdministrationController(
        $translate,
        $rootScope,
        $scope,
        $windowInstance,
        person,
        currentFormType,
        formTypes,
        personService,
        locations
    ) {
        $scope.person = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        $scope.locationOptions = {
            buttonClasses: 'k-button',
            enableSearch: true
        };

        $scope.locationTranslations = {
            checkAll: $translate.instant('G_SELECT_ALL'),
            uncheckAll: $translate.instant('G_UNSELECT_ALL'),
            dynamicButtonTextSuffix: $translate.instant('G_SELECTED')
        };

        $scope.selectedLocations = [];

        $scope.locationSelectorSource = locations.map(function (location) {
            return {
                id: location.locationNumber,
                label: location.name
            };
        });
        $scope.locationSelectorSource = _.orderBy($scope.locationSelectorSource, 'label')

        initController();

        function initController() {
            if (currentFormType === formTypes.edit && person) {
                $scope.person = person;

                _.forEach($scope.person.locations, function (location) {
                    $scope.selectedLocations.push({id: location.locationNumber});
                });
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'personDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        extendLocationPersons();
                        $scope.person.contractorId = $rootScope.authData.contractorId;
                        personService.addPerson($scope.person)
                            .then(person => close($scope.person))
                            .catch(() => close());
                        break;
                    case formTypes.edit:
                        extendLocationPersons();

                        personService.updatePerson($scope.person)
                            .then(person => close($scope.person))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function extendLocationPersons() {
            var locationPersons = []
            _.forEach($scope.selectedLocations, function (selectedLocation) {
                var locationPerson = {};
                locationPerson.locationNumber = selectedLocation.id;
                locationPerson.personId = $scope.person.personId;

                locationPersons.push(locationPerson)
            });

            $scope.person.locationPersons = locationPersons
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
