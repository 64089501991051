(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('emailTemplateDetailsModalController', emailTemplateDetailsModalController);

    emailTemplateDetailsModalController.$inject = ['$q', '$scope', '$rootScope', '$windowInstance', 'adminEmailTemplateService', 'type'];

    function emailTemplateDetailsModalController($q, $scope, $rootScope, $windowInstance, adminEmailTemplateService, type) {
        $scope.languages = [];
        $scope.selectedLanguage = "NO";
        $scope.templates = [];

        $scope.tabStripOptions = {
            animation: false,
            scrollable: false
        };

        $scope.save = save;
        $scope.cancel = cancel;
        $scope.setSelectedLanguage = setSelectedLanguage;

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', { id: 'emailTemplateDetailsModalController', destination: '#template-details-modal' });

            $q.all({
                getEmailTemplateByType: adminEmailTemplateService.getEmailTemplateByType(type),
                getLanguages: adminEmailTemplateService.getLanguages()
            }).then(result => {
                $scope.templates = result.getEmailTemplateByType;
                $scope.languages = result.getLanguages;

                if ($scope.templates.length === 0) {
                    $scope.languages.forEach(l => {
                        $scope.templates.push({
                            subject: "",
                            template: "",
                            iso: l.iso,
                            emailTemplateType: type
                        });
                    });
                }

                $scope.selectedLanguage = "NO";
            })
                .catch(error => console.error(error))
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'emailTemplateDetailsModalController'));
        }

        function save() {
            $rootScope.$broadcast('showBusyIndicator', { id: 'save', destination: '#template-details-modal' });

            $scope.templates.forEach(t => {
                t.template = `<!doctype html><html>${t.template}</html>`; //rich text editor removes these tags, need to readd them before save
            });

            adminEmailTemplateService.updateTemplates($scope.templates)
                .then(() => $windowInstance.close(true))
                .catch(error => console.error(error))
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'save'));
        }

        function cancel() {
            $windowInstance.close(false);
        }

        function setSelectedLanguage(iso) {
            $scope.selectedLanguage = iso;
        }
    }
})();
