(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ResourceModalController', resourceModalController);

    resourceModalController.$inject = ['$scope', '$rootScope', '$windowInstance', '$translate', 'resource', 'formType', 'formTypes', 'measurementUnitType', 'mediaService', 'adminResourceService'];

    function resourceModalController($scope, $rootScope, $windowInstance, $translate, resource, formType, formTypes, measurementUnitType, mediaService, adminResourceService) {
        $scope.resource = formType === 'add' ? { resourceDescription: "", measurementUnitTypeId: "" } : resource; 
        $scope.measurementUnitType = measurementUnitType;
        $scope.ok = ok;
        $scope.cancel = cancel;
        
        $scope.measurementTypeOptions = {
            dataSource: new kendo.data.DataSource({ data: $scope.measurementUnitType }),
            dataValueField: 'measurementUnitTypeId',
            dataTextField: 'measurementUnitDescription',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE')
        };

        initController();
        function initController() {
            if(formType !== formTypes.add && $scope.resource && $scope.resource.measurementType !== ""){
                $scope.selectedMeasurementType = $scope.measurementUnitType.find(type => type.measurementUnitTypeId === $scope.resource.measurementUnitTypeId);
                if($scope.resource.mediaId){
                    $scope.images = [{
                        mediaId: $scope.resource.mediaId,
                        fileName: $translate.instant('G_IMAGE'),
                        itemFileId: null,
                        url: mediaService.createMediaUrlMediaBank($scope.resource.mediaId)
                    }];
                }
            } else {
                $scope.selectedMeasurementType = null;
            }
        }

        function ok() {
            if(!$scope.validator.validate()) {
                return;
            }
            $rootScope.$broadcast('showBusyIndicator', { id: 'resourceModalSave', destination: '#resource-form' });
            $scope.resource.measurementUnitTypeId = $scope.selectedMeasurementType.measurementUnitTypeId;
            if (formType == formTypes.add) {
                if ($scope.images && $scope.images.length > 0) {
                    var file = $scope.images[0];
                    mediaService.uploadFileWebApi(file.fileName, file.base64Content).then(function (mediaId) {
                        $scope.resource.mediaId = mediaId;
                        adminResourceService.addResource($scope.resource)
                            .then(result => $windowInstance.close({ result: $scope.resource }))
                            .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'resourceModalSave'));
                    });
                } else {
                    $scope.resource.mediaId = null;
                    adminResourceService.addResource($scope.resource)
                        .then(result => $windowInstance.close({ result: $scope.resource }))
                        .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'resourceModalSave'));
                }
            } else {
                if ($scope.images && $scope.images.length > 0) {
                    var file = $scope.images[0];
                    mediaService.uploadFileWebApi(file.fileName, file.base64Content).then(function (mediaId) {
                        $scope.resource.mediaId = mediaId;
                        adminResourceService.updateResource($scope.resource)
                            .then(result => $windowInstance.close({ result: $scope.resource }))
                            .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'resourceModalSave'));

                    });
                } else {
                    $scope.resource.mediaId = null;
                    adminResourceService.updateResource($scope.resource)
                        .then(result => $windowInstance.close({ result: $scope.resource }))
                        .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'resourceModalSave'));

                }
            }
        }

        function cancel() {
            $windowInstance.close({result: false});
        }
    }
})();
