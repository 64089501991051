(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationInfoVSController', LocationInfoVSController);

    LocationInfoVSController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$stateParams',
        'locationService',
        '$translate',
        'kendoUtility',
        '$kWindow',
        'sanitizeService'];

    function LocationInfoVSController(
        $q,
        $scope,
        $state,
        $rootScope,
        $stateParams,
        locationService,
        $translate,
        kendoUtility,
        $kWindow,
        sanitizeService) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#locationInformationVSGrid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#locationInformationVSGrid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        var locationInformationVSDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.locationInformation),
                update: e => onLocationInformationGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        vsMaxValueInMeterPerSecond: { editable: true, type: 'number'  },
                        vS10Y: { editable: true , type: 'number' },
                        vS50Y: { editable: true, type: 'number'  },
                        vsDirectionGrd: { editable: true , type: 'number' },
                    }
                },
            }
        });

        const locationInformationVSGridOptions = langId => ({
            dataSource: locationInformationVSDataSource,
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'vsMaxValueInMeterPerSecond',
                    title: $translate.instant('MAIN_LOCATION_INFO_MAX_VALUE_IN_METER_PER_SECOND', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.vsMaxValueInMeterPerSecond;
                    },
                },
                {
                    field: 'vS10Y',
                    title: $translate.instant('MAIN_LOCATION_INFO_IN_10_YEARS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.vS10Y;
                    },
                },
                {
                    field: 'vS50Y',
                    title: $translate.instant('MAIN_LOCATION_INFO__IN_50_YEARS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.vS50Y;
                    },
                },
                {
                    field: 'vsDirectionGrd',
                    title: $translate.instant('MAIN_LOCATION_INFO_DIRECTION_GRD', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.vsDirectionGrd;
                    },
                },
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: false,
            resizable: true,
            sortable: true,
            groupable: false
        });
        $scope.locationInformationVSGridOptions = locationInformationVSGridOptions($translate.use());

        $scope.locationInformation = [];
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.toggleChangesButtons = toggleChangesButtons;

        $scope.$on('langChanged', (event, code) => {
            $scope.locationInformationVSGridOptions = locationInformationVSGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.locationInformationVSGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#locationInformationVSGrid')]);
            langId = code;
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationInformation',
                destination: '#right-pane',
                overlay: false
            });
            toggleChangesButtons(true)
            let requests = {};
            requests.getLocationInformationWebApi = locationService.getLocationInformationWebApi($stateParams.LocationNumber)
            $q.all(requests)
            .then(data => {
                 if(!data.getLocationInformationWebApi || data.getLocationInformationWebApi === null){
                    $scope.locationInformation.push({
                        vsMaxValueInMeterPerSecond: 0,
                        vS10Y: 0,
                        vS50Y: 0,
                        vsDirectionGrd: 0,
                        id: '00000000-0000-0000-0000-000000000000',
                        locationNumber: $stateParams.LocationNumber
                    });
                 }else{
                    $scope.locationInformation.push(data.getLocationInformationWebApi);
                 }
            })
            .catch(error => console.error(error))
            .finally(() => {
                $scope.locationInformationVSGrid.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'locationInformation');
            });
        }

        function saveChanges() {
            $scope.locationInformationVSGrid.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.locationInformationVSGrid.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-locationInformation-vs');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-locationInformation-vs');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onLocationInformationGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationInformationVSGrid',
                destination: '#locationInformationVSGrid'
            });

            let requests = {};
            if(e.data.id === '00000000-0000-0000-0000-000000000000'){
                e.data.hsWindWaves10Y= 0;
                e.data.hsWindWaves50Y= 0;
                e.data.hsOceanWaves10Y= 0;
                e.data.hsOceanWaves50Y= 0;
                requests.locationInformation = locationService.addLocationInformationWebApi(e.data)
            }else{
                requests.locationInformation =  locationService.updateLocationInformationWebApi(e.data)
            }
            $q.all(requests)
            .then(data => {
                if (data.locationInformation) {
                    toggleChangesButtons(true);
                    e.success();
                    $rootScope.$broadcast('hideBusyIndicator', 'locationInformationVSGrid');
                } else {
                    e.error();
                    $rootScope.$broadcast('hideBusyIndicator', 'locationInformationVSGrid');
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                $rootScope.$broadcast('hideBusyIndicator', 'locationInformationVSGrid');
            });
        }
    }
})();
