/**
 * Collection of service calls related to unmodified or rarely modified data.
 */
(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('baseDataService', baseDataService);

    baseDataService.$inject = ['$q', '$http', 'serviceUrls'];

    function baseDataService($q, $http, serviceUrls) {
        const checkListBaseApiUrl = `${serviceUrls.webApiBaseUrl}/checkList`;
        const resourcesWebApiUrl = `${serviceUrls.webApiBaseUrl}/resources`;

        return {
            getMeasurementUnitTypesWebapi: getMeasurementUnitTypesWebapi,
            getResourcesWebApi: getResourcesWebApi
        };

        function getMeasurementUnitTypesWebapi() {
            const deferred = $q.defer();
            const url = checkListBaseApiUrl + '/measurementUnitType';

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getResourcesWebApi() {
            const deferred = $q.defer();
            const requestUrl = `${resourcesWebApiUrl}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }
    }
})();
