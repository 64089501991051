(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('VesselHistoryController', vesselHistoryController);

    vesselHistoryController.$inject = [
        '$q',
        "$rootScope",
        '$scope',
        '$windowInstance',
        '$translate',
        'dateUtility',
        'authService',
        'departmentService',
        'department',
    ];

    function vesselHistoryController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        $translate,
        dateUtility,
        authService,
        departmentService,
        department
    ) {
        kendo.culture('no-NO');
        $scope.authData = authService.getAuthData();
        $scope.department = department;
        $scope.departmentHistory = null
        $scope.closeModal = closeModal;
        const defaultGroupFilter = [];

        var departmentHistoryDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success(_.sortBy($scope.departmentHistory, 'moveDate').reverse()),
            },
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        "location.name": { editable: true },
                        moveDate: { editable: false },
                        "user.userName": { editable: true },
                    }
                },
            }
        });

        const departmentHistoryGridOptions = langId => ({
            dataSource: departmentHistoryDataSource,
            columns: [
                {
                    field: 'location.name',
                    title: $translate.instant('G_FROM_TO', null, null, langId),
                    width: '150px',
                    template: function (dataItem) {
                        return dataItem.location.name ? dataItem.location.name : '';
                    },
                },
                {
                    field: 'moveDate',
                    title: $translate.instant('G_MOVE_DATE', null, null, langId),
                    width: '150px',
                    template: function (dataItem) {
                        return (dataItem.moveDate ? dateUtility.formatDateTime(dateUtility.toLocalDateTimeString(dataItem.moveDate)) : '');
                    },
                },
                {
                    field: 'user.userName',
                    title: $translate.instant('G_MOVE_BY', null, null, langId),
                    width: '150px',
                    template: function (dataItem) {
                        return dataItem.user.userName ? dataItem.user.userName : '';
                    },
                },
            ],
            animation: false,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            groupable: false,
            editable: false,
            resizable: true,
            filterable: {
                name: 'FilterMenu',
                extra: true
            }
        });
        $scope.departmentHistoryGridOptions = departmentHistoryGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.departmentHistoryGridOptions = vesselsGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.departmentHistoryGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#departmentHistoryGrid')]);
        });

        initModal();
        function initModal() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'vesselHistoryModalBusyIndicator',
                destination: '#departmentHistoryGrid',
                overlay: true
            });
            var requests = {}
            requests.departmentHistory = departmentService.getDepartmentHistoryElementsWebApi(department.departmentId);
            $q.all(requests)
                .then(data => {
                    $scope.departmentHistory = data.departmentHistory;
                    $scope.thereIsAnyLocationHistory =  $scope.departmentHistory.length > 0
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.departmentHistoryGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'vesselHistoryModalBusyIndicator');
                });
        }

        function closeModal() {
            $windowInstance.close();
        }
    }
})();
