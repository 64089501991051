(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CategoryCloneModalController', CategoryCloneModalController);

    CategoryCloneModalController.$inject = ['$scope', '$rootScope', '$q', '$windowInstance', '$kWindow', '$stateParams', 'itemTreeService', 'departmentService', 'authService', '$translate'];

    function CategoryCloneModalController($scope, $rootScope, $q, $windowInstance, $kWindow, $stateParams, itemTreeService, departmentService, authService, $translate) {

        var authData = authService.getAuthData();

        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;

        $scope.selectedDepartment = null;
        $scope.equipmentItemsTreeData = [];
        $scope.equipmentItemsSelectedIds = [];
        $scope.equipmentItemsDropdownTree = "equipmentItemsDropdownTree";
        $scope.copyAllEquipments = false;
        $scope.copyFromSfiCodes = false;

        $scope.departmentDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: loadDepartments
                }
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            optionLabel: $translate.instant('G_SELECT_VESSEL'),
            change: function () {
                if($scope.selectedDepartment && $scope.selectedDepartment.departmentId){
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'equipmentCopyModalLoadIndicator',
                        destination: '.k-window-content',
                    });

                    var requests = {
                        getItemCategoryWithItemsTree: itemTreeService.getItemCategoryWithItemsTree(authData.contractorId, $scope.selectedDepartment.departmentId)
                    };

                    $q.all(requests)
                    .then(result => {
                        $scope.equipmentItemsTreeData = result.getItemCategoryWithItemsTree;
                        $scope.equipmentItemsSelectedIds = [];
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'equipmentCopyModalLoadIndicator');
                    });
                }
            }
        }

        function loadDepartments(e) {
            var contractorId = authData.isAuth ? authData.contractorId : null;

            departmentService.getDepartmentsWebapi(contractorId).then(function (departments) {
                departments = _.filter(departments, function (d) { return d.contractorId === contractorId; });

                departments = _.orderBy(departments, 'name');

                if (departments && departments.length > 0) {
                    $scope.departments = departments;
                } else {
                    $scope.departments = [];
                }

                e.success(departments);
            });
        }

        function closeModal() {
            $windowInstance.close();
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.copyFromSfiCodes || ($scope.selectedDepartment && $scope.selectedDepartment.departmentId && $scope.selectedDepartment.departmentId !== "")) {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 550,
                        height: 140
                    },
                    templateUrl: 'app/shared/modals/confirm-dialog.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmDialogController',
                    resolve: {
                        content: () => {
                            return $translate.instant('EQUIPMENT_CATEGORY_CLONE_WARNING_BOX_MESSAGE');
                        },
                        okBtnText: () => null,
                        cancelBtnText: () => null
                    }
                });
                windowInstance.result.then((response) => {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'equipmentCopyIndicator',
                        destination: '.k-window-content',
                        overlay: true
                    });

                    if ($scope.copyFromSfiCodes) {
                        //clone category tree from SFI constants
                        itemTreeService.addCategoryTreeFromSfiConstants(authData.contractorId, $stateParams.DepartmentId).then(function () {
                            $rootScope.$broadcast('hideBusyIndicator', 'equipmentCopyIndicator');
                        }).finally(function () {
                            $windowInstance.close(true);
                            $rootScope.$broadcast('hideBusyIndicator', 'equipmentCopyIndicator');
                        });
                    } else {
                        //clone categories and equipment from selected department
                        if ($scope.copyAllEquipments) {
                            var needFileCopy = response ? true : false;
                            itemTreeService.cloneItemCategoryTree(authData.contractorId, $scope.selectedDepartment.departmentId, $stateParams.DepartmentId, needFileCopy).then(function () {
                                $rootScope.$broadcast('hideBusyIndicator', 'equipmentCopyIndicator');
                            }).finally(function () {
                                $windowInstance.close(true);
                                $rootScope.$broadcast('hideBusyIndicator', 'equipmentCopyIndicator');
                            });
                        } else {
                            var needFileCopy = response ? true : false;
                            var selectedItemIds = [];
                            collectAllItemsInTree($scope.equipmentItemsTreeData, selectedItemIds);

                            itemTreeService.cloneSelectedItemsWithCategory(authData.contractorId, $scope.selectedDepartment.departmentId, $stateParams.DepartmentId, needFileCopy, selectedItemIds).then(function () {
                                $rootScope.$broadcast('hideBusyIndicator', 'equipmentCopyIndicator');
                            }).finally(function () {
                                $windowInstance.close(true);
                                $rootScope.$broadcast('hideBusyIndicator', 'equipmentCopyIndicator');
                            });
                        }
                    }
                });
            }
        }

        function collectAllItemsInTree(list, collection){
            list.forEach(item => {
                if(item.value && !item.isCategory && $scope.equipmentItemsSelectedIds.includes(item.value)){
                    collection.push(item.value);
                }

                if(item.items && item.items.length > 0){
                    collectAllItemsInTree(item.items, collection);
                }
            });
        }
    }
})();
