(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ReactivateEquipmentJobModalController', ReactivateEquipmentJobModalController);

    ReactivateEquipmentJobModalController.$inject = ['$scope', '$windowInstance', '$translate', 'job', 'isActive'];

    function ReactivateEquipmentJobModalController($scope, $windowInstance, $translate, job, isActive) {
        $scope.isActive = isActive;
        $scope.showValidFrom = (job.itemJobType === 0 || job.itemJobType === 2) && !isActive;
        $scope.showCounterIntervalStart = (job.itemJobType === 1 || job.itemJobType === 2) && !isActive;
        $scope.currentTime = new Date();
        $scope.selectedValidTo = null;
        $scope.selectedValidFrom = null;
        $scope.selectedCounterInterval = null;
        $scope.confirm = confirm;
        $scope.close = close;

        $scope.validCounterOptions = {
            decimals: 0,
            min: 1,
            format: "n0"
        }

        $scope.validatorOptions = {
            rules: {
                validateFromDateRange: input => input.is("[name=item-validFrom]") && $scope.selectedValidTo && $scope.selectedValidFrom && input[0].required
                                            ? $scope.selectedValidTo > $scope.selectedValidFrom
                                            : true,

                validateToDateRange: input =>input.is("[name=item-validTo]") && $scope.selectedValidTo
                                            ? $scope.selectedValidFrom < $scope.selectedValidTo && $scope.selectedValidTo.toLocaleDateString('en-CA')  >= $scope.currentTime.toLocaleDateString('en-CA')
                                            : true,

                validateFromDateMin: input => input.is("[name=item-validFrom]") && input[0].required
                                            ? $scope.selectedValidFrom.toLocaleDateString('en-CA')  >= $scope.currentTime.toLocaleDateString('en-CA')
                                            : true,

                validateCounterIntervalValue: input => input.is("[name=counter-interval-start-value]") && input[0].required ? input.val() : true,
            },
            messages: {
                validateCounterIntervalValue: $translate.instant('EQUIPMENT_JOB_MODAL_COUNTER_STARTING_WARNING'),
                validateFromDateRange: $translate.instant('EQUIPMENT_JOB_MODAL_VALID_FROM_RANGE_WARNING'),
                validateToDateRange: $translate.instant('EQUIPMENT_JOB_MODAL_VALID_TO_RANGE_WARNING'),
                validateFromDateMin: $translate.instant('EQUIPMENT_JOB_MODAL_VALID_FROM_MIN_WARNING')
            }
        }

        function confirm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $windowInstance.close({ result: true, data: createResponseObject()})
            }
        }

        function close() {
            $windowInstance.close({ result: false, data: createResponseObject() })
        }

        function createResponseObject() {
            return {
                Id: job.equipmentJobDefinitionId,
                ValidTo: $scope.selectedValidTo ? moment($scope.selectedValidTo).format('YYYY-MM-DDTHH:mm:ss') : null,
                ValidFrom: $scope.selectedValidFrom ? moment($scope.selectedValidFrom).format('YYYY-MM-DDTHH:mm:ss') : null,
                CounterIntervalStartValue: $scope.selectedCounterInterval
            };
        }
    }
})();
