(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationDocumentsController', LocationDocumentsController);

    LocationDocumentsController.$inject = ['$scope', '$rootScope', '$translate', '$stateParams', '$kWindow', 'itemTreeService', 'kendoUtility', 'mediaService', 'formTypes'];

    function LocationDocumentsController($scope, $rootScope, $translate, $stateParams, $kWindow, itemTreeService, kendoUtility, mediaService, formTypes) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.documentsGridData = [];

        const documentsGridOptions = langId => ({
            dataSource: {
                data: $scope.documentsGridData,
                group: {
                    field: 'categoryName'
                },
                sort: [
                    { field: 'description', dir: 'asc' }
                ]
            },
            sortable: true,
            filterable: true,
            groupable: true,
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [
                { field: 'description', title: $translate.instant('MAIN_LOCATION_DOCUMENT_GRID_CAMPS', null, null, langId), width: 400, template: '<span ng-if="#:!hasDocumentUrl#">#: description#</span><a href="#:documentUrl#" target="_blank" ng-if="#:hasDocumentUrl#">#: description#</a>' },
                { field: 'validFrom', title: $translate.instant('MAIN_LOCATION_DOCUMENT_GRID_DATEFROM', null, null, langId), width: 100 },
                { field: 'validTo', title: $translate.instant('MAIN_LOCATION_DOCUMENT_GRID_DATETO', null, null, langId), width: 100 },
                { field: 'categoryName', title: $translate.instant('MAIN_LOCATION_DOCUMENT_GRID_TYPE', null, null, langId), width: 100 },
                { field: 'comment', title: $translate.instant('MAIN_LOCATION_DOCUMENT_GRID_COMMENT', null, null, langId) }
            ]
        });

        $scope.documentsGridOptions = documentsGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.documentsGridOptions = documentsGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.documentsGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#documentsGrid')]);
        });

        initController();
        function initController() {
            if ($scope.authData.isAuth) {
                $scope.contractorId = $scope.authData.contractorId;
            }

            readDocuments();
        }

        function readDocuments() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationLog',
                destination: '#right-pane',
                overlay: false
            });

            itemTreeService.getAllItemsByExternalId($stateParams.LocationNumber).then(function (documents) {
                $scope.documentsGridData = [];
                _.each(documents, function (item) {
                    extendItem(item);
                    $scope.documentsGridData.push(item);
                });

                $scope.documentsGrid.dataSource.data($scope.documentsGridData);
                $scope.documentsGrid.refresh();

                $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
            }, function () {
                $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
            });
        }

        function extendItem(item) {
            if (item.validityInterval) {
                if (!item.validityInterval.validTo || item.validityInterval.validTo === "1900-01-01T00:00:00") {
                    item.validTo = '';
                }
                else {
                    item.validTo = moment(item.validityInterval.validTo).format('DD.MM.YYYY');
                }
                if (!item.validityInterval.validFrom || item.validityInterval.validFrom === "1900-01-01T00:00:00") {
                    item.validFrom = '';
                }
                else {
                    item.validFrom = moment(item.validityInterval.validFrom).format('DD.MM.YYYY');
                }
            }
            else {
                item.validFrom = '';
                item.validTo = '';
            }
            item.categoryName = item.category && item.category.name ? item.category.name : '';

            //Add document link url to items
            if (item.files && item.files.length) {
                var files = _.orderBy(item.files, [function (file) {
                    return new Date(parseInt(file.createdOn.substr(6)));
                }], ['asc']);

                var documentUrl = mediaService.createMediaUrl(files[0].mediaId);

                item.hasDocumentUrl = true;
                item.documentUrl = documentUrl;
            } else if(item.description && (item.description.startsWith("http") || item.description.startsWith("www"))) {
                item.hasDocumentUrl = true;
                item.documentUrl = item.description;
            } else {
                item.documentUrl = '';
                item.hasDocumentUrl = false;
            }
        }

        $scope.addNewDocument = function(){
            const window =  $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('DOCUMENT_ADD_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/location/locationPanel/controllers/document-modal/document-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'documentModal',
                resolve: {
                    externalId: () => $stateParams.LocationNumber,
                    formType: () => formTypes.add,
                    document: () => null,
                    relatedEntityId: () => null,
                }
            });

            window.result.then(result => {
                readDocuments();
            });
        }
    }
})();
