angular.module('NaviaqWebApp').directive('activityLineTable', [function () {
    'use strict';

    return {
        restrict: 'A',
        replace: true,
        templateUrl: 'app/report/directives/activity-line-table.html',
        link: function (scope, element, attrs) { }
    };
}]);
