(function() {
    'use strict';

    angular.module('NaviaqWebApp').controller('VesselDashboardController', VesselDashboardController);

    VesselDashboardController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        'logService',
        'vesselDashboardService',
        'timeFilterService',
        'kendoUtility'
    ];

    function VesselDashboardController(
        $scope,
        $rootScope,
        $state,
        $stateParams,
        logService,
        vesselDashboardService,
        timeFilterService,
        kendoUtility
    ) {
        var minSchedulerHeight = 800,
            mainViewHeight = $('.inner-wrapper').height();
        var schedulerHeight = Math.max(minSchedulerHeight, mainViewHeight - 120);

        $scope.currentDepartmentId = $stateParams.DepartmentId;
        $scope.noSelectedVessel = false;
        $scope.defaultDateTo = new Date();
        $scope.defaultDateFrom = new Date();
        $scope.defaultDateFrom.setDate($scope.defaultDateFrom.getDate() - 7);
        $scope.filteredWorkOrders = [];
        $scope.fromDateFilter = {};
        $scope.toDateFilter = {};
        $scope.dashboardFilterClass = {
            Scheduled: false,
            Correction: false,
            Log: false,
            Other: false
        };
        const operationLogsSchedulerOptions = () => ({
            date: $scope.currentDate,
            startTime: $scope.startTime,
            height: schedulerHeight,
            views: [
                {
                    type: 'month',
                    selected: true,
                    editable: {
                        destroy: false,
                        move: false,
                        resize: false
                    }
                },
                // "timelineMonth",
                'agenda'
            ],
            timezone: 'Etc/GMT-1',
            dataSource: {
                transport: {
                    read: readScheduledOperationLogs
                },
                schema: {
                    model: {
                        id: 'id',
                        fields: {
                            id: { type: 'string', from: 'operationLogId' },
                            title: { from: 'description', defaultValue: 'No title', validation: { required: true } },
                            start: { type: 'date', from: 'doneOn' },
                            end: { type: 'date', from: 'end' },
                            description: { from: 'description' },
                            isAllDay: { type: 'boolean', from: 'isAllDay' }
                        }
                    }
                }
            },
            editable: false
        });
        $scope.operationLogsSchedulerOptions = operationLogsSchedulerOptions();

        $scope.selectDashboardTab = selectDashboardTab;
        $scope.editOperationLog = editOperationLog;
        $scope.getStatusNameFromJobType = getStatusNameFromJobType;
        $scope.getOpLog = getOpLog;
        $scope.getOpLogOfToday = getOpLogOfToday;
        $scope.getOpLogOfThisWeek = getOpLogOfThisWeek;
        $scope.getOpLogOfLastWeek = getOpLogOfLastWeek;
        $scope.mapOperationLogEnumToStatus = mapOperationLogEnumToStatus;
        $scope.editOperationLog = editOperationLog;
        $scope.formatDate = formatDate;

        $scope.$on('langChanged', (event, code) => {
            $scope.operationLogsSchedulerOptions = operationLogsSchedulerOptions();
            const widget = kendoUtility.createWidget('kendoScheduler', $scope.operationLogsSchedulerOptions);
            kendoUtility.changeLangOfWidget(code, [() => widget('#operationlog-scheduler')]);
        });

        initVesselDashboardController($scope.currentDepartmentId);

        function initVesselDashboardController(departmentId) {
            if (departmentId) {
                showDashboardIndicator();
                $scope.noSelectedVessel = false;
                getDashboardOrders();
                selectDashboardTab('Scheduled');
            } else {
                $scope.noSelectedVessel = true;
            }

            $rootScope.$on('navbarItemSelected', onNavbarItemSelected);
            $rootScope.$on('newLogAdded', onNewLogAdded);
        }

        function onNavbarItemSelected(event, item) {
            if (item && (item.type === 'Vessel' || item.type === 'Department')) {
                $scope.currentDepartmentId = item.departmentId;
                $state.transitionTo('vesselDashboard', { DepartmentId: item.departmentId }, { notify: false });

                initVesselDashboardController(item.departmentId);
            }
        }

        function onNewLogAdded(event) {
            getDashboardOrders($scope.fromDateFilter, $scope.toDateFilter);
        }

        function formatDate(jsonDate) {
            if (jsonDate) {
                return kendo.toString(new Date(parseInt(jsonDate.substr(6))), 'dd.MM.yyyy');
            }
        }

        function selectDashboardTab(filterOption) {
            if (typeof $scope.dashboardFilterClass[filterOption] === 'undefined') {
                return;
            }

            $scope.dashboardFilterClass[filterOption] = 'active';

            // unset others
            for (var cls in $scope.dashboardFilterClass) {
                if (cls !== filterOption) {
                    $scope.dashboardFilterClass[cls] = '';
                }
            }
        }

        function getStatusNameFromJobType(jobType) {
            return vesselDashboardService.getStatusNameFromJobType(jobType);
        }

        function editOperationLog(log) {
            return vesselDashboardService.editOperationLog(log);
        }

        function getDashboardOrders(fromDate, toDate) {
            $scope.fromDateFilter = fromDate || $scope.defaultDateFrom;
            $scope.toDateFilter = toDate || $scope.defaultDateTo;
            if ($scope.operationLogsScheduler) {
                $scope.operationLogsScheduler.dataSource.read();
                $scope.operationLogsScheduler.refresh();
            }
            var filterData = {
                departmentIds: [$scope.currentDepartmentId],
                fromDate: $scope.fromDateFilter,
                toDate: $scope.toDateFilter
            };

            logService.getLogActions($scope.authData.contractorId).then(function(logActions) {
                logService.getOperationLogsByFilterWebAPI(filterData).then(function(allOperationLogs) {
                    $scope.allOperationLogs = _.sortBy(allOperationLogs, function(log) {
                        return new Date(log.createdOn);
                    });

                    _.forEach($scope.allOperationLogs, function(log) {
                        log.icon = vesselDashboardService.getIcon(log);
                        log.dueMessage = vesselDashboardService.getDueDate(log);
                        log = vesselDashboardService.calculateOperationLogTasks(log, logActions);
                    });

                    $scope.openPeriodic = _.filter($scope.allOperationLogs, {
                        logAction: { jobType: vesselDashboardService.getJobTypeFromStatusName('Scheduled') }
                    }).length;
                    $scope.openCorrective = _.filter($scope.allOperationLogs, {
                        logAction: { jobType: vesselDashboardService.getJobTypeFromStatusName('Correction') }
                    }).length;
                    $scope.openAlarm = _.filter($scope.allOperationLogs, {
                        logAction: { jobType: vesselDashboardService.getJobTypeFromStatusName('Log') }
                    }).length;
                    $scope.openOther =
                        $scope.allOperationLogs.length -
                        ($scope.openPeriodic + $scope.openCorrective + $scope.openAlarm);

                    $scope.filteredWorkOrders = $scope.allOperationLogs;

                    $rootScope.$broadcast('hideBusyIndicator', 'vesselDashboardIndicator');
                });
            });
        }

        function readScheduledOperationLogs(e) {
            if ($scope.currentDepartmentId) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'scheduledOperationLogsIndicator',
                    destination: '#scheduled-operationLogs',
                    message: 'Henter data...',
                    overlay: true
                });

                logService
                    .getScheduledOperationLogsByFilter(
                        moment(new Date()).format('MM.DD.YYYY'),
                        moment(new Date())
                            .add(3, 'months')
                            .format('MM.DD.YYYY'),
                        $scope.currentDepartmentId
                    )
                    .then(
                        function(scheduledOperationLogs) {
                            $scope.scheduledOperationLogs = scheduledOperationLogs;
                            _.forEach($scope.scheduledOperationLogs, function(log) {
                                log.createdOn = moment(log.createdOn).toDate();
                                log.doneOn = moment(log.doneOn).toDate();
                                log.editedOn = moment(log.editedOn).toDate();
                                log.dueDate = moment(log.dueDate).toDate();
                                log.end = log.doneOn;
                                log.isAllDay = true;
                            });

                            e.success($scope.scheduledOperationLogs);
                            $rootScope.$broadcast('hideBusyIndicator', 'scheduledOperationLogsIndicator');
                        },
                        function() {
                            e.success([]);
                            $rootScope.$broadcast('hideBusyIndicator', 'scheduledOperationLogsIndicator');
                        }
                    );
            }
        }

        function getOpLog() {
            showDashboardIndicator();
            var from = $scope.operationLogDateFrom.value();
            var to = $scope.operationLogDateTo.value();
            getDashboardOrders(from, to);
        }

        function getOpLogOfToday() {
            showDashboardIndicator();
            var currentDate = timeFilterService.getDateOfToday();
            $scope.operationLogDateFrom.value(currentDate);
            $scope.operationLogDateTo.value(currentDate);
            getDashboardOrders(currentDate, currentDate);
        }

        function getOpLogOfThisWeek() {
            showDashboardIndicator();
            var from = timeFilterService.getStartDateOfThisWeek();
            var to = timeFilterService.getEndDateOfThisWeek();
            $scope.operationLogDateFrom.value(from);
            $scope.operationLogDateTo.value(to);
            getDashboardOrders(from, to);
        }

        function getOpLogOfLastWeek() {
            showDashboardIndicator();
            var from = timeFilterService.getStartDateOfLastWeek();
            var to = timeFilterService.getEndDateOfLastWeek();
            $scope.operationLogDateFrom.value(from);
            $scope.operationLogDateTo.value(to);
            getDashboardOrders(from, to);
        }

        function mapOperationLogEnumToStatus(statusId) {
            return vesselDashboardService.mapOperationLogEnumToStatus(statusId);
        }

        function showDashboardIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'vesselDashboardIndicator',
                destination: '#vessel-dashboard',
                message: 'Henter data...',
                overlay: true
            });
        }
    }
})();
