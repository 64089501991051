(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('equipmentJobDefinitionService', equipmentJobDefinitionService);

    equipmentJobDefinitionService.$inject = ['$q', '$http', 'serviceUrls'];

    function equipmentJobDefinitionService($q, $http, serviceUrls) {
        var baseServiceUrl = serviceUrls.webApiBaseUrl + '/EquipmentJob/';

        var service = {
            addEquipmentJobDefinition: addEquipmentJobDefinition,
            getEquipmentJobDefinitionsByItemId: getEquipmentJobDefinitionsByItemId,
            updateEquipmentJobDefinition: updateEquipmentJobDefinition,
            updateEquipmentJobDefinitionStatus: updateEquipmentJobDefinitionStatus,
            getEquipmentJobDefinitionGroupsByEquipmentItemId: getEquipmentJobDefinitionGroupsByEquipmentItemId,
            addEquipmentJobDefinitionGroup: addEquipmentJobDefinitionGroup,
            updateEquipmentJobDefinitionGroup: updateEquipmentJobDefinitionGroup,
            deleteEquipmentJobDefinitionGroup: deleteEquipmentJobDefinitionGroup
        };

        return service;

        function addEquipmentJobDefinition(jobDefinition) {
            var deferred = $q.defer();

            $http.post(baseServiceUrl + `AddEquipmentJobDefinition`, jobDefinition).then(function(response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getEquipmentJobDefinitionsByItemId(itemId) {
            var deferred = $q.defer();

            $http.get(baseServiceUrl + 'GetEquipmentJobDefinitionsByItemId?ItemId=' + itemId).then(function (response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateEquipmentJobDefinition(jobDefinition) {
            var deferred = $q.defer();

            $http.post(baseServiceUrl + 'UpdateEquipmentJobDefinition', jobDefinition).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateEquipmentJobDefinitionStatus(reactivatedJobDefinition) {
            var deferred = $q.defer();

            $http.put(baseServiceUrl, reactivatedJobDefinition).then(response => deferred.resolve(response), () => deferred.reject());

            return deferred.promise;
        }

        // GROUP

        function getEquipmentJobDefinitionGroupsByEquipmentItemId(equipmentItemId) {
            var deferred = $q.defer();

            $http.get(`${baseServiceUrl}group?equipmentItemId=${equipmentItemId}`).then(function (response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function addEquipmentJobDefinitionGroup(equipmentJobDefinitionGroup) {
            var deferred = $q.defer();

            $http.post(`${baseServiceUrl}group`, equipmentJobDefinitionGroup).then(function (response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateEquipmentJobDefinitionGroup(equipmentJobDefinitionGroup) {
            var deferred = $q.defer();

            $http.put(`${baseServiceUrl}group`, equipmentJobDefinitionGroup).then(function (response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function deleteEquipmentJobDefinitionGroup(equipmentJobDefinitionGroupid) {
            var deferred = $q.defer();

            $http.delete(`${baseServiceUrl}group?equipmentJobDefinitionGroupid=${equipmentJobDefinitionGroupid}`).then(function (response) {
                if(response && response.data){
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
