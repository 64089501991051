(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OrderProcessDetailsModalController', OrderProcessDetailsModalController);

    OrderProcessDetailsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'logActionService',
        'formType',
        'orderProcess',
        'orderService',
        'componentTypes',
        'processDocumentType',
        'contractorService',
        'froyConglomerateName',
        'mediaService',
        'itemTreeService',
        'orderRequestService',
        '$translate'
    ];

    function OrderProcessDetailsModalController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        logActionService,
        formType,
        orderProcess,
        orderService,
        componentTypes,
        processDocumentType,
        contractorService,
        froyConglomerateName,
        mediaService,
        itemTreeService,
        orderRequestService,
        $translate
    ) {
        $scope.close = close;
        $scope.saveProcess = saveProcess;
        $scope.addLogAction = addLogAction;
        $scope.listBoxSource = [];
        $scope.logActions = [];
        $scope.documentTypes = [];
        $scope.orderRequestTypes = [];
        $scope.orderProcess = {};
        $scope.createPerComponent = false;
        $scope.showFinalReport = false;
        $scope.showMooringReport = false;
        $scope.isFroyConglomerate = false;
        $scope.hasOrderConfirmationModule = $rootScope.hasModule('OrderConfirmation');
        $scope.isAdd = !orderProcess
        $scope.couldRemove = false;

        $scope.documentTypesDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.documentTypes);
                    }
                }
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            noDataTemplate: 'Ingen treff'
        };

        $scope.logActionDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.logActions);
                    }
                }
            }),
            dataValueField: 'logActionId',
            dataTextField: 'description',
            noDataTemplate: 'Ingen treff'
        };

        $scope.componentTypeDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(componentTypes);
                    }
                }
            }),
            dataValueField: 'value',
            dataTextField: 'text',
            noDataTemplate: 'Ingen treff'
        };

        $scope.orderRequestTypeDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.orderRequestTypes);
                    }
                }
            }),
            dataValueField: 'orderRequestTypeId',
            dataTextField: 'keyText',
            optionLabel: {
                keyText: "",
                orderRequestTypeId: null,
              },
        };

        $scope.logActionsListBoxOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.listBoxSource);
                    }
                }
            }),
            dataTextField: 'description',
            dataValueField: 'orderProcessItemId',
            toolbar: {
                position: 'right',
                tools: ['moveUp', 'moveDown', 'remove']
            },
            reorder: onReorder,
            remove: onRemove,
            change: e => {
                // var listBox = $("#connected-log-actions").data("kendoListBox");
                // listBox.select(listBox.items().first());
            },
            template: dataItem => {
                const checked = dataItem.perComponent ? 'checked' : '';
                return `<span class="col-md-6">${dataItem.description}</span>
                        <span class="col-md-3">${dataItem.componentTypeText}</span>
                        <span  class="col-md-3 text-center"><input type="checkbox" ${checked} disabled class="order-process-per-component" name="order-process-per-component"/></span>`;
            }
        };

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'orderProcessSaveIndicator',
                destination: '#order-process-form'
            });

            loadDocumentTypes()

            var filter = {
                contractorId: $scope.authData.contractorId,
                LogActionTypes: ['OperationLog'],
                status: 0
            };

            var requests = {
                getActiveLogActionsAsDropdownByFilter: logActionService.getActiveLogActionsAsDropdownByFilter(filter),
                getContractorsForConglomerate: contractorService.getContractorsForConglomerate(froyConglomerateName),
                getOrderRequestTypes: orderRequestService.getOrderRequestTypes()
            };

            if(orderProcess){
                requests.getItemsByRelatedEntityIdOrCreate = itemTreeService.getItemsByRelatedEntityIdOrCreate($scope.authData.contractorId, orderProcess.orderProcessId)

            }

            $q.all(requests). then(function(result) {
                $scope.logActions = result.getActiveLogActionsAsDropdownByFilter;
                $scope.orderRequestTypes = result.getOrderRequestTypes;
                extendOrderRequestTypes();
                addCheckboxChangeEventListener();
                $scope.orderRequestTypeDropDownOptions.dataSource.read();
                $scope.logActionDropDownOptions.dataSource.read();
                if(formType == 'edit'){
                    var selectedDocumentType = $scope.documentTypes ?  $scope.documentTypes.find(a => a.id == orderProcess.documentType) : null;
                    $scope.selectedDocumentType =selectedDocumentType? selectedDocumentType.id : null;
                    $scope.selectedOrderRequestTypeId = orderProcess.orderRequestTypeId || null;
                    $scope.orderProcess.name = orderProcess.name;
                    $scope.showFinalReport = orderProcess.showFinalReport;
                    $scope.showMooringReport = orderProcess.showMooringReport;
                    $scope.listBoxSource = loadOrderProcessToListBox();
                    $scope.listBoxSource = _.orderBy($scope.listBoxSource,'order');
                    $scope.logActionsListBoxOptions.dataSource.read();
                }
                if(result.getContractorsForConglomerate){
                    var contractorForConglomerate = result.getContractorsForConglomerate.map(c => c.contractorId);
                    $scope.isFroyConglomerate = contractorForConglomerate.includes($rootScope.authData.contractorId);
                }

                if(result.getItemsByRelatedEntityIdOrCreate && result.getItemsByRelatedEntityIdOrCreate.length){
                    $scope.$applyAsync(function () {
                        $scope.orderProcess.item = result.getItemsByRelatedEntityIdOrCreate[0];
                        if($scope.orderProcess.item && $scope.orderProcess.item.files[0] && $scope.orderProcess.item.files[0].fileName){
                            $scope.itemDocumentName = $scope.orderProcess.item.files[0].fileName;
                            $scope.isFileExist = true;
                            $scope.couldRemove = true;
                        }
                    });
                }

                $rootScope.$broadcast('hideBusyIndicator', 'landbaseAdministrationGrid');
            });
        }

        function extendOrderRequestTypes(){
            $scope.orderRequestTypes = $scope.orderRequestTypes.map(type => ({
                ...type,
                keyText:  $translate.instant(type.key)
            }));
        }

        function loadDocumentTypes(){
            $scope.documentTypes = [];
            Object.keys(processDocumentType).forEach((key) => {
                $scope.documentTypes.push({ id: key, name: processDocumentType[key] });
            });
        }

        function addCheckboxChangeEventListener() {
            $("#order-process-form").on("change", ".order-process-per-component", function (e) {
                var listbox = $("#connected-log-actions").data("kendoListBox");
                var dataItem = listbox.dataItem($(e.target).closest("li"));
                var listboxItem = $scope.listBoxSource.find(l => l.order == dataItem.order);
                if(listboxItem){
                    listboxItem.perComponent = !listboxItem.perComponent
                }
            });
        }

        function loadOrderProcessToListBox(){
            var orderProcessItems = orderProcess.orderProcessItems.map(original => {
                var logAction = $scope.logActions.find(l => l.logActionId == original.logActionId);
                if(logAction) {
                    var currentComponentType = componentTypes.find(c => c.value == original.componentType);
                    return {
                        ...original,
                        logAction: logAction,
                        description:  logAction.description,
                        componentTypeText: currentComponentType ? currentComponentType.text : ''
                    };
                } else {
                    return {
                        ...original
                    };
                }

            });
            return orderProcessItems.filter(x => x.logAction);
        }

        function onReorder(e) {
            let movedItem = e.sender
                .dataItems()
                .find(d => d.uid === e.items[0].id)
                .toJSON();

             var effectedItem = e.sender.dataItems().find(c => c.order === (movedItem.order + e.offset));
             var listBox =  $scope.listBoxSource.map(original => {
                const isMovedItem = original.order == movedItem.order;
                const isEffectedItem = original.order == effectedItem.order;
                if(isEffectedItem){
                    return {
                        ...original,
                        order:  effectedItem.order - e.offset
                    };
                }

                if (isMovedItem) {
                    return {
                        ...original,
                        order:  movedItem.order + e.offset
                    };
                }
                return original;
            });

            $scope.listBoxSource = _.orderBy(listBox,'order');
            $scope.logActionsListBoxOptions.dataSource.read();
        }

        function onRemove(e) {
            let movedItem = e.sender
                .dataItems()
                .find(d => d.uid === e.items[0].id)
                .toJSON();

            _.remove($scope.listBoxSource, function(processItem) {
            return processItem.order === movedItem.order;
            });

            var i = -1;
            $scope.listBoxSource = $scope.listBoxSource.map(original => {
                return {
                    ...original,
                    order:  i += 1
                };
            });

            $scope.logActionsListBoxOptions.dataSource.read();
        }


        function addLogAction()
        {
            orderProcessForm.orderProcessLogaction.$valid = false;
            orderProcessForm.orderProcessLogaction.required = true;
            orderProcessForm.orderProcessName.$valid = true;
            orderProcessForm.orderProcessName.required = false;

            if(!$scope.createPerComponent || $scope.createPerComponent == false){
                orderProcessForm.orderProcessComponentType.$valid = true;
                orderProcessForm.orderProcessComponentType.required = false;
            } else {
                orderProcessForm.orderProcessComponentType.$valid = false;
                orderProcessForm.orderProcessComponentType.required = true;
            }

            orderProcessForm.documentType.required = false;

            if(!$scope.validator.validate()){
                return;
            }

            if ($scope.selectedLogActionId) {
                var selectedLogAction = $scope.logActions.find(l => l.logActionId == $scope.selectedLogActionId);
                var currentComponentType = componentTypes.find(c => c.value == $scope.selectedComponentType);
                if(selectedLogAction) {
                    let newOrderProcessItem = {
                        logActionId: $scope.selectedLogActionId,
                        description: selectedLogAction.description,
                        order: $scope.listBoxSource.length,
                        perComponent: $scope.createPerComponent,
                        componentType: $scope.createPerComponent ? $scope.selectedComponentType : null,
                        componentTypeText: currentComponentType ? currentComponentType.text : '',
                        orderProcessId: orderProcess ? orderProcess.orderProcessId: null
                    };

                    $scope.createPerComponent = false;
                    $scope.selectedComponentType = null;
                    $scope.listBoxSource.push(newOrderProcessItem);
                    $scope.logActionsListBoxOptions.dataSource.read();
                }
            }
        }

        function saveProcess()
        {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'orderProcessSaveIndicator',
                destination: '#order-process-form'
            });

            orderProcessForm.orderProcessName.$valid = false;
            orderProcessForm.orderProcessName.required = true;
            orderProcessForm.orderProcessComponentType.$valid = true;
            orderProcessForm.orderProcessComponentType.required = false;
            orderProcessForm.orderProcessLogaction.$valid = true;
            orderProcessForm.orderProcessLogaction.required = false;

            if(!$scope.isFroyConglomerate){
                orderProcessForm.documentType.$valid = true;
                orderProcessForm.documentType.required = false;
            }

            if(!$scope.isFroyConglomerate){
                orderProcessForm.documentType.$valid = true;
                orderProcessForm.documentType.required = false;
            }

            if(!$scope.validator.validate()){
                return;
            }

            var orderProcessItems = _.map($scope.listBoxSource, function (processItem) {
                 return {
                     orderProcessId: processItem.orderProcessId || "00000000-0000-0000-0000-000000000000",
                     orderProcessItemId: processItem.orderProcessItemId || "00000000-0000-0000-0000-000000000000",
                     logActionId : processItem.logActionId,
                     order: processItem.order,
                     perComponent: processItem.perComponent,
                     componentType: processItem.componentType
                    }
                });

            $scope.orderProcess.orderProcessItems = orderProcessItems;
            $scope.orderProcess.contractorId = $scope.authData.contractorId;
            $scope.orderProcess.createdBy = $rootScope.authData.username;
            $scope.orderProcess.documentType = $scope.selectedDocumentType ? Number($scope.selectedDocumentType) : -1;
            $scope.orderProcess.showFinalReport = $scope.showFinalReport;
            $scope.orderProcess.showMooringReport = $scope.showMooringReport;
            $scope.orderProcess.orderRequestTypeId = $scope.selectedOrderRequestTypeId ? $scope.selectedOrderRequestTypeId : null;

           if(formType == 'add'){
                orderService.addOrderProcess($scope.orderProcess)
                .then(function(result){
                    close(result);
                    $rootScope.$broadcast('hideBusyIndicator', 'landbaseAdministrationGrid');
                });
           } else {
                $scope.orderProcess.orderProcessId = orderProcess.orderProcessId;
                orderService.updateOrderProcess($scope.orderProcess)
                .then(function(result){
                    if($scope.orderProcess.item){
                        itemTreeService.addItemFileWebApi({
                            createdById: $scope.authData.userId,
                            createdOn: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                            mediaId: $scope.uploadedFileId,
                            relatedItemId: $scope.orderProcess.item.id,
                            fileName: $scope.uploadedFileName
                        })
                    }
                    close(result);
                    $rootScope.$broadcast('hideBusyIndicator', 'landbaseAdministrationGrid');
                });
           }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'landBaseDetailsModalIndicator');
            $windowInstance.close(param);
        }

        $scope.finalReportClicked = function(){
            $scope.showMooringReport = false;
        }

        $scope.mooringReportClicked = function(){
            $scope.showFinalReport = false;
        }

        $scope.uploadFile = function() {
            $('#item-file-input').click();
        }

        $scope.itemDocumentOptions = {
            multiple: false
        };

        $scope.handleFiles = function() {
            if ($scope.fileArray[0]) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'fileUpload',
                    message: 'Uploading...',
                    destination: '#order-process-form'
                });

                var file = $scope.fileArray[0];
                $scope.$applyAsync(function () {
                    mediaService.asyncEncodeImageFile(file)
                    .then((base64FileContent) =>
                        mediaService.uploadFileWebApi(file.name, base64FileContent).then(function (uploadResult) {
                            $scope.isFileExist = true;
                            $scope.itemDocumentName = file.name;
                            $scope.uploadedFileId = uploadResult;
                            $scope.uploadedFileName = file.name;
                        })
                        .finally($rootScope.$broadcast('hideBusyIndicator', 'fileUpload'))
                    )
                });
            }
        }

        $scope.onFileRemoved = function() {
            $scope.$applyAsync(function () {
                if ($scope.couldRemove && $scope.orderProcess.item && $scope.orderProcess.item.files && $scope.orderProcess.item.files.length > 0) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'fileRemove',
                        destination: '#order-process-form'
                    });

                    $scope.couldRemove = false;

                    itemTreeService.deleteItemFileWebApi($scope.orderProcess.item.files[0].id)
                    .finally($rootScope.$broadcast('hideBusyIndicator', 'fileRemove'))
                }

                $scope.itemDocumentName = null;
                $scope.uploadedFileId = null;
                $scope.uploadedFileName = null;
                $scope.isFileExist = false;
            });
        }
    }
})();
