(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('orderRequestService', orderRequestService);

        orderRequestService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function orderRequestService(
        $q,
        $http,
        serviceUrls,
    ) {
        var orderRequestApiUrl = `${serviceUrls.webApiBaseUrl}/orderRequest`;
        return {
            getOrderRequestTypes: getOrderRequestTypes,
        };

        function getOrderRequestTypes(contractorId) {
            const deferred = $q.defer();
            var requestUrl = `${orderRequestApiUrl}/orderRequestTypes`;
            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
