(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('documentTypeService', documentTypeService);

    documentTypeService.$inject = ['$q', '$http', 'serviceUrls'];

    function documentTypeService($q, $http, serviceUrls) {
        const documentTypeApiUrl = `${serviceUrls.webApiBaseUrl}/document`;

        return {
            getDocumentTypes,
            addDocumentType,
            updateDocumentType
           
        };

        function getDocumentTypes(contractorId) {
            const deferred = $q.defer(),
                requestUrl = documentTypeApiUrl;
            $http({
                    url: requestUrl,
                    method: "GET",
                    params: { contractorId: contractorId }
                }).then(
                    function (response) {
                        deferred.resolve(response.data);
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );

            return deferred.promise;
        }      

        function addDocumentType(docType) {
            const deferred = $q.defer(),
            requestUrl = documentTypeApiUrl;
            $http
                .post(requestUrl, docType)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateDocumentType(docType) {
            const deferred = $q.defer(),
            requestUrl = documentTypeApiUrl;
            $http
                .put(requestUrl, docType)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
