(function() {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .controller('CheckListItemDetailsModalController', checkListItemDetailsModalController);

    checkListItemDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        '$translate',
        'authService',
        'logService',
        'formTypes',
        'formType',
        'checkListItem',
        'measurementUnitTypes',
        'logActionId',
        'itemTreeService',
        'departmentService',
        'htmlElementService'
    ];

    function checkListItemDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        $translate,
        authService,
        logService,
        formTypes,
        formType,
        checkListItem,
        measurementUnitTypes,
        logActionId,
        itemTreeService,
        departmentService,
        htmlElementService
    ) {
        $scope.authData = authService.getAuthData();
        $scope.logActionId = logActionId;
        $scope.checkListItem = null;
        $scope.formType = formType;

        // Item dropdown tree
        $scope.placeholder = '';
        $scope.treeData = null;
        $scope.selectedEquipmentIds = null;
        $scope.isMultiselect = false;
        $scope.measurementIsDisabled = false;
        $scope.departments = [];
        $scope.isActive = true;
        $scope.equipmentLoading = false;
        $scope.checklistEquipmentsDropdownTree = 'checklistEquipmentsDropdownTree';

        $scope.$on('dropdownTreeSelectedChanged', (event, selectedIds) => {
            if(selectedIds){
                var timerMesurement = measurementUnitTypes.find(m => m.measurementUnitDescription == 'timer');
                if(timerMesurement){
                    $scope.$apply(function () {
                        $scope.selectedMeasurementUnitType = timerMesurement;
                        $scope.measurementIsDisabled = true;
                    });
                }
            } else {
                $scope.$apply(function () {
                    $scope.measurementIsDisabled = false;
                });
            }
        });

        $scope.departmentOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.departments)
                }
            }),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            change: departmentChanged,
            optionLabel: $translate.instant('ADMIN_VESSEL_DEPARTMENTSHARING_DEPARTMENT_SELECT')
        };

        $scope.measurementUnitTypeOptions = {
            dataSource: new kendo.data.DataSource({
                data: measurementUnitTypes
            }),
            dataValueField: 'measurementUnitTypeId',
            dataTextField: 'measurementUnitDescription',
            optionLabel: $translate.instant('ADMIN_LOGACTION_CHECKLISTMODAL_SELECTMEASUREMENTTYPE')
        };
        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;

        initController();
        function initController(){
            var requests = {
                getDepartmentsWebapi: departmentService.getDepartmentsWebapi($rootScope.authData.contractorId)
            };

            if(formType === formTypes.edit && checkListItem && checkListItem.equipment){
                requests.getItemsTreeByRelatedEntityId = itemTreeService.getItemsTreeByRelatedEntityId($rootScope.authData.contractorId, checkListItem.equipment.relatedEntityId);
            }

            htmlElementService.checkIfElementExists('item-tree-dropdown').then(function(result){
                if(formType === formTypes.edit && checkListItem){
                    $scope.itemControl = angular.element(document.querySelector('.item-tree-dropdown')).find('.k-icon')
                    $scope.itemControl.addClass("k-i-loading");
                    $scope.equipmentLoading = true;
                }
            });

            $q.all(requests).then(function (results) {
                $scope.departments = results.getDepartmentsWebapi;
                $scope.departmentOptions.dataSource.read();
                if (formType === formTypes.edit && checkListItem) {
                    $scope.treeData = results.getItemsTreeByRelatedEntityId;
                    initModal(checkListItem);
                    removeSpinnerFromItemTreeDropdown();
                }
            });
        }

        function initModal(checkListItem) {
            $scope.checkListItem = checkListItem;
            $scope.description = checkListItem.description;
            $scope.additionalInformation = checkListItem.additionalInformation;
            measurementUnitTypes.find(function(unitType) {
                if (unitType.measurementUnitTypeId === checkListItem.measurementUnitTypeId) {
                    $scope.selectedMeasurementUnitType = unitType;
                }
                return unitType.measurementUnitTypeId === checkListItem.measurementUnitTypeId;
            });
            $scope.isActive = checkListItem.isActive;
            if(checkListItem.relatedEquipmentItemId && checkListItem.equipment){
                $scope.selectedDepartment = $scope.departments.find(d => d.departmentId == checkListItem.equipment.relatedEntityId);
                $scope.selectedEquipmentIds = checkListItem.relatedEquipmentItemId;
                var timerMesurement = measurementUnitTypes.find(m => m.measurementUnitDescription == 'timer');
                if(timerMesurement){
                    $scope.$applyAsync(function () {
                        $scope.selectedMeasurementUnitType = timerMesurement;
                        $scope.measurementIsDisabled = true;
                    });
                }
            }
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                if (formType === formTypes.add) {
                    $scope.checkListItem = {
                        description: $scope.description,
                        additionalInformation: $scope.additionalInformation,
                        measurementUnitTypeId:
                            $scope.selectedMeasurementUnitType &&
                            $scope.selectedMeasurementUnitType.measurementUnitTypeId != ''
                                ? $scope.selectedMeasurementUnitType.measurementUnitTypeId
                                : null,
                        isActive: $scope.isActive,
                        contractorId: $scope.authData.contractorId,
                        logActionId: $scope.logActionId,
                        relatedEquipmentItemId: $scope.selectedEquipmentIds ? $scope.selectedEquipmentIds : null,
                        createdBy: $scope.authData.username
                    };
                    logService.addCheckListItemWebApi($scope.checkListItem).then(function(response) {
                        //TODO: check if successful
                        $windowInstance.close(true);
                    });
                } else {
                    $scope.checkListItem.description = $scope.description;
                    $scope.checkListItem.additionalInformation = $scope.additionalInformation;
                    $scope.checkListItem.measurementUnitTypeId =
                        $scope.selectedMeasurementUnitType &&
                        $scope.selectedMeasurementUnitType.measurementUnitTypeId != ''
                            ? $scope.selectedMeasurementUnitType.measurementUnitTypeId
                            : null;
                    $scope.checkListItem.isActive = $scope.isActive;
                    $scope.checkListItem.relatedEquipmentItemId = $scope.selectedEquipmentIds ? $scope.selectedEquipmentIds : null;
                    logService.updateCheckListItemWebApi($scope.checkListItem).then(() => $windowInstance.close(true));
                }
            }
        }

        function departmentChanged(){
            if($scope.selectedDepartment){
                $scope.selectedEquipmentIds = null;

                htmlElementService.checkIfElementExists('item-tree-dropdown').then(function(result){
                  $scope.itemControl = angular.element(document.querySelector('.item-tree-dropdown')).find('.k-icon')
                  $scope.itemControl.addClass("k-i-loading");
                  $scope.equipmentLoading = true;
              });

                itemTreeService.getItemsTreeByRelatedEntityId($rootScope.authData.contractorId, $scope.selectedDepartment.departmentId).then(function(equipments){
                    $scope.treeData = equipments;
                    removeSpinnerFromItemTreeDropdown();
                });
            }
        }
        function removeSpinnerFromItemTreeDropdown(){
            if(!$scope.itemControl){
                htmlElementService.checkIfElementExists('item-tree-dropdown').then(function(result){
                    $scope.itemControl = angular.element(document.querySelector('.item-tree-dropdown')).find('.k-icon')
                    $scope.itemControl.removeClass("k-i-loading");
                    $scope.equipmentLoading = false;
                });
            } else {
                $scope.itemControl.removeClass("k-i-loading");
                $scope.equipmentLoading = false;
            }
        }

        function closeModal() {
            $windowInstance.close();
        }
    }
})();
