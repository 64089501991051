(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('signatureService', signatureService);

    signatureService.$inject = ['$q', '$http', 'serviceUrls'];

    function signatureService($q, $http, serviceUrls) {
        const signatureBaseApiUrl = serviceUrls.webApiBaseUrl + '/signature';

        return {
            getSignaturesByWorkContractId
        };

        function getSignaturesByWorkContractId(workContractId) {
            const deferred = $q.defer(),
                url = signatureBaseApiUrl + '/byWorkContractId?workContractId=' + workContractId;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
