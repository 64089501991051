(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('InformationModalController', informationModalController);

    informationModalController.$inject = ['$scope', '$windowInstance', 'content'];

    function informationModalController($scope, $windowInstance, content) {
        $scope.content = content;

        $scope.ok = ok;

        function ok() {
            $windowInstance.close();
        }
    }
})();
