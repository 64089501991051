(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('WorkOrderDetailsModalController', workOrderDetailsModalController);

    workOrderDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        '$translate',
        'statuses',
        'modalType',
        'workOrderLog',
        'logActionService',
        'formTypes',
        'workOrderService',
        'departments',
        'dateUtility',
        'users',
        'caseData',
        'logActionStatus',
        'locationService',
        'componentService',
        'itemTreeService',
        'htmlElementService',
        'canEdit',
        'noUser',
        'componentElementType',
        'mediaService',
        'DepartmentStatus',
        'contractorService'
    ];

    function workOrderDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        $translate,
        statuses,
        modalType,
        workOrderLog,
        logActionService,
        formTypes,
        workOrderService,
        departments,
        dateUtility,
        users,
        caseData,
        logActionStatus,
        locationService,
        componentService,
        itemTreeService,
        htmlElementService,
        canEdit,
        noUser,
        componentElementType,
        mediaService,
        DepartmentStatus,
        contractorService
    ) {

        $scope.okClickedOnce = false;
        $scope.showAutoClose = workOrderLog && workOrderLog.parentId;
        $scope.workOrderTags = [];
        $scope.isEdit = modalType === formTypes.edit;
        $scope.workOrder = workOrderLog ? workOrderLog : {};
        $scope.modalType = modalType;
        $scope.logActions = [];

        //File upload
        $scope.workOrderUploaderId = "workOrderUploader";
        $scope.documents = [];

        //Common Item dropdown tree
        $scope.isMultiselect = false;
        $scope.placeholder = '';
        $scope.equipCompListLoading = false;

        //AssignedTo Item dropdown tree
        $scope.assignedTreeData = [];
        $scope.assignSelectedIds = [];
        $scope.workorderAssignedToDropdownTree = 'workorderAssignedToDropdownTree';

        //EquipmentComponent Item dropdown tree
        $scope.componentTreeData = [];
        $scope.equipmentTreeData = [];
        $scope.equipCompSelectedIds = [];
        $scope.workorderEquipmentDropdownTree = 'workorderEquipmentDropdownTree';

        $scope.filteredUser = users.filter(u => !u.userName).concat(users.filter(u => u.userName && u.roles.some(r => r.permissions
            .some(p => p.key === 'WorkOrderView'))));

        $scope.filteredDepartments = departments.filter(d => d.status === DepartmentStatus.Active || (workOrderLog && workOrderLog.departmentId == d.departmentId));

        $scope.assignedTreeData = createUserDepTree($scope.filteredDepartments, $scope.filteredUser);

        $scope.departmentDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                data: $scope.filteredDepartments,
                sort: { field: "name", dir: "asc" }
            }),
            dataTextField: 'name',
            dataValueField: 'departmentId',
            change: () =>{
                $scope.$applyAsync(() => {
                    $scope.validator && $scope.validator.validateInput($("input[name=wo-department]"));
                    changeDepartment();
                    getLogActions();
                })
            }
        }

        $scope.tagsMultiselectOptions = {
            placeholder: $translate.instant('WORKORDER_MODAL_ADD_TAGS'),
            dataTextField: 'tag',
            dataValueField: 'workOrderTagId',
            dataSource: new kendo.data.DataSource({ transport: { read: e => e.success($scope.workOrderTags) } }),
            noDataTemplate: $("#workorder-tags-template").html()
        };

        $scope.logActionDropDownOptions = {
            dataSource: new kendo.data.DataSource({ transport: { read: e => e.success($scope.logActions) } }),
            dataTextField: 'description',
            dataValueField: 'logActionId',
            change: () => $scope.$applyAsync(() => $scope.validator && $scope.validator.validateInput($("input[name=wo-log-action]")))
        };

        $scope.statusDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                data: workOrderService.getAllowedStatuses($scope, statuses)
            }),
            dataTextField: 'text',
            dataValueField: 'value',
            select: (e) => e.dataItem.isDisabled && e.preventDefault(),
            template: kendo.template($("#wo-status-template").html())
        };

        $scope.selectedTags = [];
        $scope.selectedDepartmentId = null;
        $scope.workOrderLog = null;
        $scope.createdOnDisplay = null;
        $scope.isOperationLogAdded = false;
        $scope.canEdit = canEdit;

        $scope.ok = ok;
        $scope.close = close;
        $scope.setDateToNow = setDateToNow;

        $scope.$watchGroup(['workOrderLog.title', 'workOrderLog.doneOnObject'], revalidateAll);

        initController();

        function initController() {
            htmlElementService.checkIfElementExists("work-order-details-modal", 50)
            .then(() => {
                var requests = {
                    getWorkOrderTags: workOrderService.getWorkOrderTagsByContractorId($rootScope.authData.contractorId),
                    getLocationsThroughCustomersByContractorId: locationService.getLocationsThroughCustomersByContractorId($rootScope.authData.contractorId),
                    getDefaultLogActions: contractorService.getDefaultLogActions($rootScope.authData.contractorId)
                }
                if(workOrderLog && workOrderLog.operationLogId){
                    requests.getWorkOrderById = workOrderService.getWorkOrderById(workOrderLog.operationLogId);
                    requests.getItemsByRelatedEntityId = itemTreeService.getItemsByRelatedEntityIdWebApi($rootScope.authData.contractorId, workOrderLog.operationLogId);
                }
                $q.all(requests).then(result => {
                    $scope.defaultLogActions = result.getDefaultLogActions;
                    $scope.workOrderTags = result.getWorkOrderTags;
                    if(result.getWorkOrderById){
                        workOrderLog = result.getWorkOrderById;
                        $scope.workOrder = workOrderLog;
                        $scope.selectedDepartmentId = workOrderLog.departmentId;
                    }

                    $scope.tagsMultiselectOptions.dataSource.read();

                    if (workOrderLog) {
                        if(workOrderLog.assignedUser && workOrderLog.assignedUser[0]){
                            $scope.assignSelectedIds = [workOrderLog.assignedUser[0].userId];
                        } else if(workOrderLog.workOrderData && workOrderLog.workOrderData.assignedDepartmentId) {
                            $scope.assignSelectedIds = [workOrderLog.workOrderData.assignedDepartmentId]
                        } else {
                            $scope.assignSelectedIds = [noUser.userId];
                        }
                        $scope.isEdit && changeDepartment();

                        if(result.getItemsByRelatedEntityId && result.getItemsByRelatedEntityId.length > 0){
                           $scope.workOrderItems = result.getItemsByRelatedEntityId;
                        }
                    }

                    initModal();
                });
            });

        }
        function changeDepartment() {
            $scope.equipCompListLoading = true;
            $scope.componentTreeData = [];
            $scope.equipmentTreeData = [];
            $scope.equipCompSelectedIds  = [];

            let selectedDepartment = null;
            let locationNumber = null;
            var requests = {};

            if ($scope.selectedDepartmentId || ($scope.workOrder && $scope.workOrder.departmentId)) {
                selectedDepartment = $scope.selectedDepartmentId
                                        ? $scope.filteredDepartments.filter(d => d.departmentId === $scope.selectedDepartmentId)[0]
                                        : $scope.filteredDepartments.filter(d => d.departmentId === $scope.workOrder.departmentId)[0];
                locationNumber = selectedDepartment ? selectedDepartment.locationNumber : $scope.workOrder.locationNumber;
            }

            if ($scope.selectedDepartmentId) {
                requests.getItemsTreeByRelatedEntityId = itemTreeService.getItemsTreeByRelatedEntityId($scope.authData.contractorId, $scope.selectedDepartmentId);
            }

            if ($scope.workOrder && $scope.workOrder.departmentId) {
                requests.getItemsTreeByRelatedEntityId = itemTreeService.getItemsTreeByRelatedEntityId($scope.authData.contractorId, $scope.workOrder.departmentId);
            }

            const selectedIds = { cageIds: [], lineIds: [], netIds: [], ringIds: [] }
            if (workOrderLog && (workOrderLog.equipments || workOrderLog.rings || workOrderLog.nets || workOrderLog.lines)) {
                selectedIds.cageIds = workOrderLog.cages ? workOrderLog.cages.map(x => x.cageId) : [];
                selectedIds.ringIds = workOrderLog.rings ? workOrderLog.rings.map(x => x.ringId) : [];
                selectedIds.netIds = workOrderLog.nets ? workOrderLog.nets.map(x => x.id) : [];
                selectedIds.lineIds = workOrderLog.lines ? workOrderLog.lines.map(x => x.lineId) : [];
                selectedIds.equipmentId = workOrderLog.equipments && workOrderLog.equipments.length ? workOrderLog.equipments[0].equipmentId : null;
            }

            if(locationNumber) {
                requests.getLocationRelatedComponentList = componentService.getLocationRelatedComponentList(locationNumber, selectedIds);
            }

            $q.all(requests).then(result => {
                const components = result.getLocationRelatedComponentList ? result.getLocationRelatedComponentList : [];
                const equipments = result.getItemsTreeByRelatedEntityId ? result.getItemsTreeByRelatedEntityId : [];
                extendComponents(components);
                $scope.equipmentTreeData = equipments;
                $scope.componentTreeData = createComponentTree(components);

                if(selectedIds.equipmentId){
                    $scope.equipCompSelectedIds = selectedIds.equipmentId;
                } else {
                    $scope.equipCompSelectedIds = [...selectedIds.cageIds, ...selectedIds.ringIds, ...selectedIds.netIds, ...selectedIds.lineIds];
                }
            })
            .finally(() => $scope.equipCompListLoading = false);
        }

        function mapSelectedEquipComponents() {
            if ((($scope.componentTreeData && $scope.componentTreeData.length) || ($scope.equipmentTreeData && $scope.equipmentTreeData.length)) && $scope.equipCompSelectedIds != null) {
                $scope.workOrderLog.nets = $scope.componentTreeData[0].items.filter((el) => $scope.equipCompSelectedIds.includes(el.value)).map(x => { return {id: x.value}});
                $scope.workOrderLog.rings = $scope.componentTreeData[1].items.filter((el) => $scope.equipCompSelectedIds.includes(el.value)).map(x => { return {ringId: x.value}});
                $scope.workOrderLog.cages =  $scope.componentTreeData[2].items.filter((el) => $scope.equipCompSelectedIds.includes(el.value)).map(x => { return {cageId: x.value}});
                $scope.workOrderLog.lines =  $scope.componentTreeData[3].items.filter((el) => $scope.equipCompSelectedIds.includes(el.value)).map(x => { return {lineId: x.value}});

                if($scope.equipCompSelectedIds != null && $scope.equipCompSelectedIds != "" && !Array.isArray($scope.equipCompSelectedIds)){
                    var selectedEquipment = [findItemByIdInTree($scope.equipmentTreeData, $scope.equipCompSelectedIds)]
                    if(selectedEquipment){
                        $scope.workOrderLog.equipments = selectedEquipment.map(x => { return {equipmentId: x.value}});
                    } else {
                        $scope.workOrderLog.equipments = [];
                    }
                } else {
                    $scope.workOrderLog.equipments = [];
                }
            }
        }

        function findItemByIdInTree(list, id) {
            for (const n of list) {
              const res = n.value === id || n == id ? n : findItemByIdInTree(n.items, id);
              if (res) return res;
            }
        }

        function extendComponents(components){
            components && components.length && components.filter(x => x.label).forEach(component => {
                    if(component.componentType === componentElementType.Ring){
                        const label = `${component.label} | ${$translate.instant('ORDER_PROCESS_COMPONENT_CAGE')}:`;
                        component.label = component.additionalInfo ? `${label} ${component.additionalInfo}` : `${label} -`;
                    }
                });

            components = _.orderBy(components, ['label'], ['asc']);
        }

        function createComponentTree(components){

            // Main folder
            $scope.nets = [];
            $scope.rings = [];
            $scope.cages = [];
            $scope.lines = [];
            var netFolder = {text: 'Nets', value: null, disabled: 'disabled', items: []};
            var ringFolder = {text: 'Rings', value: null, disabled: 'disabled', items: []};
            var cageFolder = {text: 'Cages', value: null, disabled: 'disabled', items: []};
            var lineFolder = {text: 'Lines', value: null, disabled: 'disabled', items: []};
            var tree = [netFolder, ringFolder, cageFolder, lineFolder];
            components.forEach(comp => {
                if(comp.componentType === componentElementType.Net){
                    netFolder.items.push({
                        text: comp.label,
                        value: comp.id,
                        items: []
                    });
                    $scope.nets.push(comp);
                }

                if(comp.componentType === componentElementType.Ring){
                    ringFolder.items.push({
                        text: comp.label,
                        value: comp.id,
                        items: []
                    });
                    $scope.rings.push(comp);
                }

                if(comp.componentType === componentElementType.Cage){
                    cageFolder.items.push({
                        text: comp.label,
                        value: comp.id,
                        items: []
                    });
                    $scope.cages.push(comp);
                }

                if(comp.componentType === componentElementType.Line){
                    lineFolder.items.push({
                        text: comp.label,
                        value: comp.id,
                        items: []
                    });
                    $scope.lines.push(comp);
                }
            });
            return tree;
        };

        function createUserDepTree(departments, users){
            // Create main folders
            var departmentFolder = {text: $translate.instant('G_DEPARTMENTS'), value: null, disabled: 'disabled', items: []};
            var userFolder = {text: $translate.instant('G_USERS'), value: null, disabled: 'disabled', items: []};
            // Create Dep tree
              var depList = [];
              departments.forEach((dep) => {
                depList.push({
                    text: dep.name,
                    value: dep.departmentId,
                    parentId: dep.parentDepartmentId,
                    items: []
                });
              });

            // Create user tree
            var userTree = [];
            users.forEach(user => {
                userTree.push({
                    text: user.displayName,
                    value: user.userId,
                    items: []
                });
            });

            var rootTree = listToTree(depList);
            departmentFolder.items = rootTree;
            userFolder.items = userTree;
            var tree = [];
            tree.push(departmentFolder);
            tree.push(userFolder);
            return tree;
        }

        function listToTree(list) {
            var map = {}, node, roots = [], i;

            for (i = 0; i < list.length; i += 1) {
              node = list[i];
              if (node.parentId !== null && Boolean(list[map[node.parentId]])) {
                // if you have dangling branches check that map[node.parentId] exists
                list[map[node.parentId]].items.push(node);
              } else {
                roots.push(node);
              }
            }
            return roots;
          }

        function initModal() {
            var currentDate = new Date();

            switch (modalType) {
                case formTypes.add:
                    workOrderService.getNextWoNumberByContractorId($rootScope.authData.contractorId).then(data => {
                            $scope.workOrderLog = {
                                logActionId: $scope.defaultLogActions.defaultWorkOrderLogActionId,
                                woNumber: data,
                                description: '',
                                status: statuses ? statuses[0] : null,
                                createdOn: currentDate,
                                dueDateObject: workOrderLog ? workOrderLog.dueDateObject : null,
                                doneOnObject: workOrderLog ? workOrderLog.doneOnObject : null,
                                doneToObject: workOrderLog ? workOrderLog.doneToObject : null,
                                comment: '',
                                title: '',
                                workOrderTags: [],
                                workOrderData: { priorityCode: null },
                                parentId: workOrderLog ? workOrderLog.parentId : null
                            };
                            $scope.createdByDisplay = $rootScope.authData.displayName;
                            $scope.createdOnDisplay = moment($scope.workOrderLog.createdOn).format('DD.MM.YYYY HH:mm');

                            $scope.workOrderLog.doneOnObject = $scope.workOrderLog.doneOnObject || new Date(currentDate.getFullYear(), currentDate.getMonth(),currentDate.getDate() + 14, 7),

                            $scope.workOrderLog.doneToObject = $scope.workOrderLog.doneToObject || new Date(moment($scope.workOrderLog.doneOnObject).set({'hours': 19}));

                            if (caseData) {
                                $scope.selectedDepartmentId = caseData.selectedDepartmentId || workOrderLog.selectedDepartmentId;
                                intModalWhenOpenFromCase();
                            }
                        }, () =>  $windowInstance.close());
                    break;
                case formTypes.edit:
                    var cratedByUser = workOrderLog && workOrderLog.createdBy != 'NAVIAQ_SYSTEM' ? users.find(u => u.userName == workOrderLog.createdBy) : null;
                    if (cratedByUser) {
                        $scope.createdByDisplay = cratedByUser.displayName;
                    } else {
                        if (workOrderLog && workOrderLog.createdBy == 'NAVIAQ_SYSTEM') {
                            $scope.createdByDisplay = $translate.instant('G_SYSTEM_GENERATED');
                        } else {
                            $scope.createdByDisplay = workOrderLog.createdBy;
                        }
                    }

                    if($scope.workOrderItems && $scope.workOrderItems.length > 0){
                        var itemWithFiles = $scope.workOrderItems.find(x => x.files.length > 0);
                        if(itemWithFiles){
                            $scope.documents = itemWithFiles.files.filter(d => !d.naviaqMediaId).map(function (obj) {
                                return {
                                    id: obj.id,
                                    fileName: obj.fileName,
                                    url: mediaService.createMediaUrlMediaBank(obj.mediaId),
                                    mediaId: obj.mediaId,
                                    base64Content:  null,
                                    createdOn: obj.createdOn,
                                    description: obj.description,
                                    selectedComponentId: null,
                                    displayText: null,
                                    FileType: 0
                                }
                            });
                        }
                    }

                    $scope.workOrderLog = workOrderLog;
                    $scope.workOrderLog.doneToObject = $scope.workOrderLog.doneTo
                    $scope.workOrderLog.doneOnObject = $scope.workOrderLog.doneOn
                    $scope.workOrderLog.dueDateObject = $scope.workOrderLog.dueDate

                    $scope.selectedTags = workOrderLog.workOrderTags;
                    $scope.selectedDepartmentId = workOrderLog.departmentId;
                    $scope.isOperationLogAdded = true;

                    $scope.createdOnDisplay = moment($scope.workOrderLog.createdOn).format('DD.MM.YYYY HH:mm');

                    if (dateUtility.isDefaultDate(workOrderLog.dueDateObject)) {
                        workOrderLog.dueDateObject = null;
                    }

                    if (dateUtility.isDefaultDate(workOrderLog.doneToObject)) {
                        workOrderLog.doneToObject = null;
                    }

                    getLogActions();
                    break;
                default:
                    $windowInstance.close();
                    break;
            }
            htmlElementService.checkIfElementExists("wo-content", 50).then(() => {
                if(!$scope.canEdit){
                    $("#wo-content").find("select").prop( "disabled", true );
                    $("#wo-content").find("select").attr('disabled','disabled');
                }
            });
        }

        function intModalWhenOpenFromCase() {
            $scope.workOrderLog.title = caseData.title;
            if (caseData.departmentId) {
                $scope.selectedDepartmentId = caseData.departmentId;
            }

            if (caseData.dueDateObject) {
                $scope.workOrderLog.dueDateObject = caseData.dueDateObject;
            }

            var departmentDropDownList = $("#wo-department").data("kendoDropDownList");
            departmentDropDownList.value($scope.selectedDepartmentId);
            $scope.firstLoadOfEquipments = true;
            departmentDropDownList.trigger("change");
        }

        function ok() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'workOrderDetailsModal',
                destination: '#work-order-details-modal'
            });
            if ($scope.workOrderLog.operationLogId && modalType === formTypes.add && !$scope.workOrderDetails.$dirty) {
                $windowInstance.close($scope.workOrderLog.operationLogId);
                return;
            }

            if ($scope.validator.validate()) {
                var status = $scope.workOrderLog.status;
                if (status.enum) {
                    $scope.workOrderLog.status = status.value;
                }

                var now = new Date();
                $scope.workOrderLog.editedOn = dateUtility.toIsoString(now);

                var authData = $rootScope.authData;
                $scope.workOrderLog.editedBy = authData.username;
                $scope.workOrderLog.dueDate = $scope.workOrderLog.dueDateObject ? dateUtility.toIsoString($scope.workOrderLog.dueDateObject) : null;
                $scope.workOrderLog.doneOn = $scope.workOrderLog.doneOnObject ? dateUtility.toIsoString($scope.workOrderLog.doneOnObject) : null;
                $scope.workOrderLog.doneTo = $scope.workOrderLog.doneToObject ? dateUtility.toIsoString($scope.workOrderLog.doneToObject) : null;

                var selectedDep = $scope.filteredDepartments.find(d => d.departmentId == $scope.assignSelectedIds.toString());
                if(selectedDep){
                    if($scope.workOrderLog.workOrderData){
                        $scope.workOrderLog.workOrderData.assignedDepartmentId = $scope.assignSelectedIds.toString();
                    } else {
                        $scope.workOrderLog.workOrderData = { assignedDepartmentId: $scope.assignSelectedIds.toString() };
                    }
                    $scope.workOrderLog.assignedUser = null;
                } else {
                    var selectedUserId = $scope.assignSelectedIds.toString();
                    $scope.workOrderLog.assignedUser = users.filter(user =>user.userId === selectedUserId);

                    if($scope.workOrder && $scope.workOrder.workOrderData){
                        $scope.workOrder.workOrderData.assignedDepartmentId = null;
                    }
                }

                $scope.workOrderLog.workOrderTags = $scope.selectedTags.map(tag => {
                    if (modalType === formTypes.edit) {
                        tag.operationLogId = $scope.workOrderLog.operationLogId;
                    }
                    tag.createdBy = authData.username;
                    tag.createdOn = now;
                    tag.status = 1;
                    tag.workOrderTag = {
                        workOrderTagId: tag.workOrderTagId,
                        tag: tag.tag,
                        contractorId: $rootScope.authData.contractorId,
                        status: 0,
                        createdById: $scope.authData.userId,
                        createdOn: dateUtility.toIsoString(now)
                    };
                    return tag;
                });

                $scope.workOrderLog.departmentId = $scope.selectedDepartmentId ? $scope.selectedDepartmentId : '00000000-0000-0000-0000-000000000000';

                mapSelectedEquipComponents();

                if (modalType === formTypes.add && !$scope.isOperationLogAdded) {
                    $scope.workOrderLog.contractorId = authData.contractorId;
                    $scope.workOrderLog.createdBy = authData.username;

                    workOrderService.addWorkOrderLog($scope.workOrderLog).then(response => {
                        if (response) {
                            $scope.workOrderLog.operationLogId = response.operationLogId;
                            $scope.workOrderLog.woNumber = response.woNumber;

                            $scope.isOperationLogAdded = true;
                            $scope.workOrderDetails.$dirty = false;

                            $rootScope.$broadcast(`${$scope.workOrderUploaderId}Broadcast`, $scope.workOrderLog.operationLogId, $scope.authData.contractorId);
                        } else {
                            close();
                        }
                    }, error =>close(error))
                    .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'workOrderDetailsModal'));
                } else {
                    workOrderService.updateWorkOrderLog($scope.workOrderLog).then(response => {
                            $rootScope.$broadcast(`${$scope.workOrderUploaderId}Broadcast`, response, $scope.authData.contractorId);
                            response && $windowInstance.close(response);
                            close();
                        },error => close(error))
                        .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'workOrderDetailsModal'));
                }
            }else {
                $rootScope.$broadcast('hideBusyIndicator', 'workOrderDetailsModal');
            }
        }

        function close() {
            $windowInstance.close();
        }

        function setDateToNow(name) {
            var now = new Date();
            $scope.workOrderLog[name + 'Object'] = now;
        }

        function getLogActions() {
            var filter = {
                contractorId: $rootScope.authData.contractorId,
                logActionTypes: ['WorkOrderLog'],
                relatedDepartmentId: $scope.selectedDepartmentId
            };

            if(workOrderLog && workOrderLog.operationLogId){
                filter.selectedLogActionIds = [];
                filter.selectedLogActionIds.push(workOrderLog.logActionId);
            }

            logActionService.getActiveLogActionsAsDropdownByFilter(filter)
                .then(function (data) {
                    var logActions = data.filter(l => l.status === logActionStatus.active || (workOrderLog && l.logActionId == workOrderLog.logActionId));
                    $scope.logActions = logActions;
                    $scope.logActionDropDownOptions.dataSource.read();
                    if(workOrderLog && workOrderLog.logAction.logActionId){
                        $scope.workOrderLog.logActionId = workOrderLog.logAction.logActionId;
                    }
                }, function (error) {
                    $scope.logActions = [];
                    $scope.logActionDropDownOptions.dataSource.read();
                });
        }

        function revalidateAll() {
            $scope.okClickedOnce && $scope.$applyAsync(() => $scope.validator &&$scope.validator.validate());
        }
    }
})();
