(function () {
    angular.module('NaviaqWebApp').factory('authInterceptorService', authInterceptorService);

    authInterceptorService.$inject = ['$rootScope', '$q', '$injector', 'localStorageAuthWrapper', 'serviceUrls'];

    function authInterceptorService($rootScope, $q, $injector, localStorageAuthWrapper, serviceUrls) {
        return {
            request: request,
            responseError: responseError
        };

        function request(config) {
            config.headers = config.headers || {};

            var authData = localStorageAuthWrapper.getAuthData();
            var isBarentsWatchRequest = config.url.includes('barentswatch.no');
            var isAzureBlobRequest = config.url.includes('blob.core.windows.net');
            if (authData && !isBarentsWatchRequest && !isAzureBlobRequest) {
                //Read this code after token based auth is finished
                config.headers.Authorization = authData.token;
            }

            return config;
        }

        function responseError(rejection) {
            const $state = $injector.get('$state');

            if ($state.current.name !== 'login'
                && rejection.status === 401
                && rejection.config.url.includes(serviceUrls.webApiBaseUrl)) {
                const authService = $injector.get('authService');

                authService.logout();
                $state.go('login', {
                    returnState: $state.current.name,
                    returnParams: JSON.stringify($state.params)
                });
            } else if (rejection.status === 403) {
                const url = rejection.config && rejection.config.url ? rejectionUrlHandler(rejection.config.url) : '';
                $rootScope.$broadcast('permissionDenied', url);
            } else if(rejection.status === 500 && rejection.data != null
                && rejection.data.Description == 'XSS error'){
                    $rootScope.$broadcast('crossSiteScriptingError');
                    return $q.reject(rejection);
            } else {
                return $q.reject(rejection);
            }
        }

        function rejectionUrlHandler(url) {
            let parsedUrl = url.substring(url.lastIndexOf('/') + 1);

            if (parsedUrl.includes('admin')) {
                parsedUrl = parsedUrl.substring(0, parsedUrl.lastIndexOf('n') + 1)
            }

            $rootScope.$broadcast('hideAllBusyIndicator');

            return parsedUrl;
        }
    }
})();
