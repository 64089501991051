(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ConfirmDialogController', confirmDialogController);

    confirmDialogController.$inject = ['$scope', '$windowInstance', 'content', 'okBtnText', 'cancelBtnText', '$translate'];

    function confirmDialogController($scope, $windowInstance, content, okBtnText, cancelBtnText, $translate) {
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.content = content ? content : $translate.instant('MENU_MODAL_LOGOUT_MSG', null, null);
        $scope.okBtnText = okBtnText ? okBtnText : $translate.instant('G_YES', null, null);
        $scope.cancelBtnText = cancelBtnText ? cancelBtnText : $translate.instant('G_NO', null, null);

        initController();
        function initController() {
            $windowInstance.rendered.then(function () {
                $('.k-overlay').click(cancel);
            });
        }

        function ok() {
            $windowInstance.close(true);
        }

        function cancel() {
            $windowInstance.close(false);
        }
    }
}) ();
