(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('bargeService', bargeService);

        bargeService.$inject = [
        '$q',
        '$http',
        'serviceUrls',
    ];

    function bargeService(
        $q,
        $http,
        serviceUrls,
    ) {
        const bargeApiServiceUrl = `${serviceUrls.webApiBaseUrl}/barge`;

        return {
            getBargesByContractorId,
        };

        function getBargesByContractorId(contractorId) {
            const deferred = $q.defer();
            const url = `${bargeApiServiceUrl}?contractorId=${contractorId}`;

            $http.get(url)
                 .then(response => {
                     response && response.data && deferred.resolve(response.data);
                     deferred.reject();
                 })
                 .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
