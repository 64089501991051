(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ServiceFormsOptionDetailsController', ServiceFormsOptionDetailsController);

    ServiceFormsOptionDetailsController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        'kendoUtility',
        'serviceFormService',
        'formTypes',
        'formType',
        'serviceFormOption',
        '$windowInstance',
    ];

    function ServiceFormsOptionDetailsController(
        $q,
        $scope,
        $rootScope,
        $translate,
        kendoUtility,
        serviceFormService,
        formTypes,
        formType,
        serviceFormOption,
        $windowInstance,
    ) {
        $scope.serviceFormOption = [];
        $scope.addNewOptionItem = addNewOptionItem;
        $scope.saveServiceFormOption = saveServiceFormOption;
        $scope.close = close;

        $scope.serviceFormOptionControlsListBoxOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.serviceFormOptions);
                    }
                }
            }),
            dataTextField: 'name',
            dataValueField: 'value',
            toolbar: {
                position: 'right',
                tools: ['moveUp', 'moveDown']
            },
            reorder: onReorder,
            remove: onRemove,
            template: dataItem => {
                return `<div class="col-md-12">
                            <span class="col-md-3">${dataItem.value}</span>
                            <span class="col-md-5 service-form-options-field-key">${dataItem.name.slice(0, 30)}</span>
                            <span class="col-md-2 text-center"><input type="checkbox" class="hidden-checkbox" ${dataItem.hidden ? 'checked' : ''} ng-model="${dataItem.hidden}" k-ng-model="${dataItem.hidden}" value="${dataItem.hidden}" name="is-hidden"/></span>
                        </div>`
            }
        };
        initController();
        function initController() {
            setTimeout(() => {
                $(".hidden-checkbox").change( event => {
                    hiddenValueChange(event);
                })
            }, 1000);
            $scope.serviceFormOptions = formType == formTypes.edit ? serviceFormOption.options  : [];
            extendServiceFormOptions();
            $scope.serviceFormOptionControlsListBoxOptions.dataSource.read();
            initModal();
        }

        $scope.hiddenValueChange = hiddenValueChange;

        function hiddenValueChange(event){
            $scope.isDirty = true;
            var text = $(event.target).parents("li").find(".service-form-options-field-key").text()
            var index = $scope.serviceFormOptions.findIndex(x => x.name.slice(0, 30) === text);
            if(index !== -1){
                $scope.serviceFormOptions[index].hidden = $scope.serviceFormOptions[index].hidden ? false : true;
            }
        }

        function initModal(){
            $scope.optionName = serviceFormOption ? serviceFormOption.name : '';
            $scope.value = getNextSequenceNumber();
        }

        function getNextSequenceNumber(){
            if($scope.serviceFormOptions && $scope.serviceFormOptions.length){
                var orderedOptionList = _.orderBy($scope.serviceFormOptions, ['value'], ['asc']);
                var lastItem = orderedOptionList[orderedOptionList.length - 1];
                return lastItem.value + 1
            } else {
                return 0;
            }
        }

        function extendServiceFormOptions(){
            $scope.serviceFormOptions = $scope.serviceFormOptions.map(s => ({
                ...s,
                hidden: s.hidden ? s.hidden : false
            }));
        }

        function onReorder(e) {
            $scope.isDirty = true;
            let movedItem = e.sender
            .dataItems()
            .find(d => d.uid === e.items[0].id)
            .toJSON();
            var elementIdx = $scope.serviceFormOptions.findIndex(s => s.name == movedItem.name);
            moveElementInArray(elementIdx, elementIdx + e.offset);
        }

        function moveElementInArray(old_index, new_index) {
            if (new_index >= $scope.serviceFormOptions.length) {
                var k = new_index - $scope.serviceFormOptions.length + 1;
                while (k--) {
                    $scope.serviceFormOptions.push(undefined);
                }
            }
            $scope.serviceFormOptions.splice(new_index, 0, $scope.serviceFormOptions.splice(old_index, 1)[0]);
            return $scope.serviceFormOptions;
        };

        function onRemove(e) {
            $scope.isDirty = true;

            let movedItem = e.sender
                .dataItems()
                .find(d => d.uid === e.items[0].id)
                .toJSON();

            _.remove($scope.serviceFormOptions, function(serviceFormField) {
            return serviceFormField.value === movedItem.value;
            });
            $scope.value = getNextSequenceNumber();
        }

        function addNewOptionItem(){
            if(!$scope.validator.validate()){
                return;
            }

            var serviceFormOptionItem = {
                value : $scope.value,
                name: $scope.name
            };

            $scope.serviceFormOptions.push(serviceFormOptionItem);
            $scope.value = getNextSequenceNumber();
            $scope.serviceFormOptionControlsListBoxOptions.dataSource.read();
            $scope.isDirty = true;
        }

        function saveServiceFormOption(){
            if ($scope.isDirty) {

                if(!$scope.mainValidator.validate()){
                    return;
                }

                $scope.serviceFormOptionObjList = [];
                $scope.serviceFormOptions.forEach(function (control) {
                    $scope.serviceFormOptionObjList.push({
                        value : control.value,
                        name: control.name,
                        hidden: control.hidden
                    });
                });

                var templateJsonObj = JSON.stringify($scope.serviceFormOptionObjList);

                var serviceFormOptions = {
                    name: $scope.optionName,
                    options: templateJsonObj,
                    serviceFormOptionsId : serviceFormOption && serviceFormOption.id ? serviceFormOption.id : '00000000-0000-0000-0000-000000000000'
                };
                serviceFormService.addOrUpdateServiceFormOption(serviceFormOptions).then(function() {
                    close(true);
                });
            }
        }

        function close(result){
            $windowInstance.close(result);
        }
    }
})();
