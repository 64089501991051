(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('delicingH202ReportDirective', delicingH202ReportDirective);

    delicingH202ReportDirective.$inject = [];

    function delicingH202ReportDirective() {
        return {
            controller: [
                '$scope',
                '$q',
                '$rootScope',
                '$stateParams',
                '$translate',
                'anonymousReportService',
                delicingH202ReportDirective
            ],
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: 'app/report/delicingReport/delicing-H202-report.html'
        };

        function delicingH202ReportDirective(
            $scope,
            $q,
            $rootScope,
            $stateParams,
            $translate,
            anonymousReportService
        ) {
            $scope.orderId = $stateParams.OrderId;

            $rootScope.$on('delicingH202ReportRequested', function () {
                initController();
            });

            function initController(){
                var rquests = {
                    getDelicingReportContent: anonymousReportService.getDelicingReportContent($scope.orderId, 'H202'),
                }

                $q.all(rquests).then(function(results){
                    const reportContent = results.getDelicingReportContent;
                    $("#delicing-h202-grid").kendoGrid({
                        toolbar: ["excel"],
                        excel: {
                        allPages: true
                        },
                        dataSource: {
                        transport: {
                            read: e => e.success([]),
                        },
                        },
                        excelExport: function(e) {
                            var workbook = e.workbook;

                            e.workbook.fileName = $translate.instant('REPORT_DELICING_H202_REPORT');

                            var sheet = workbook.sheets[0];
                            sheet.columns = [];
                            for (let index = 0; index < 20; index++) {
                                sheet.columns.push({
                                    autoWidth: true
                                });
                            }

                            sheet.rows = sheet.rows.concat(getTitleRow());
                            sheet.rows = sheet.rows.concat(getOrderRelatedDataRows(reportContent.order));
                            sheet.rows = sheet.rows.concat(getEmptyRow());
                            sheet.rows = sheet.rows.concat(getServiceFormDataRows(reportContent.serviceFormData, mapServiceFormOptionList(reportContent.serviceFormOptions)));
                            sheet.rows = sheet.rows.concat(getEmptyRow());
                            sheet.rows = sheet.rows.concat(getCommentRow(reportContent.skipperMessagesOperationLog));
                        },
                    });
                    $("#delicing-h202-grid").data("kendoGrid").saveAsExcel();
                });
            }

            function createCell(value, color, background, vAlign = null, hAlign = null, bold = null, colSpan = null, fontSize =  null,  underline = null){
                return {
                    colSpan: colSpan ? colSpan : 1,
                    rowSpan: 1,
                    bold: bold ? bold : false,
                    underline: underline ? underline : false,
                    background: background,
                    color: color,
                    fontSize: fontSize ? fontSize : 14,
                    vAlign: vAlign ? vAlign : "center",
                    hAlign: hAlign ? hAlign : "center",
                    value: value,
                }
            }

            function getTitleRow(){
                var row = {
                    cells: [],
                    type: "data"
                };

                row.cells.push(createCell($translate.instant('DELICING_FORM_H202_TITLE'), "#0d5572", "#fff", "center", "center", true, 9, 18, true));

                return row;
            }

            function getOrderRelatedDataRows(order){
                var rows = [];
                var firstRow = {
                    cells: [],
                    type: "data"
                };

                firstRow.cells.push(createCell($translate.instant('DELICING_FORM_H202_DEPARTMENT'), "#000", "#d1eef9", "center", "left", true));
                firstRow.cells.push(createCell(order.orderDepartments && order.orderDepartments.length && order.orderDepartments[0].department ? order.orderDepartments[0].department.name : '', "#000", "#d1eef9", "center", "center", false, 2));
                firstRow.cells.push(createCell($translate.instant('DELICING_FORM_H202_CUSTOMER'), "#000", "#d1eef9", "center", "left", true));
                firstRow.cells.push(createCell(order.customer ? order.customer.name : '', "#000", "#d1eef9", "center", "center", false, 2));


                var secondRow = {
                    cells: [],
                    type: "data"
                };

                secondRow.cells.push(createCell($translate.instant('DELICING_FORM_H202_ORDER_DATE'), "#000", "#bfbfbf", "center", "left", true));
                secondRow.cells.push(createCell(moment(order.orderDate).format('DD.MM.YYYY HH:mm'), "#000", "#bfbfbf", "center", "center", false, 2));
                secondRow.cells.push(createCell($translate.instant('DELICING_FORM_H202_LOCATION'), "#000", "#bfbfbf", "center", "left", true));
                secondRow.cells.push(createCell(order.location ? order.location.name : '', "#000", "#bfbfbf", "center", "center", false, 2));

                rows.push(firstRow);
                rows.push(secondRow);

                return rows;
            }

            function getEmptyRow(){
                var row = {
                    cells: [],
                    type: "data"
                };

                return row;
            }

            function getServiceFormDataRows(serviceFormData, optionsLists){
                var rows = [];

                var fieldCount = 38;
                for (let index = 0; index < fieldCount; index++) {
                    rows.push({
                        cells: [],
                        type: "data"
                    });
                }

                //titles
                rows[0].cells.push(createCell($translate.instant('DELICING_FORM_H202_CAGE_NUMBER'), "#000", "#d1eef9", "center", "left", true));
                rows[1].cells.push(createCell($translate.instant('DELICING_FORM_H202_RING_CIRCUMFERENCE'), "#000", "#bfbfbf", "center", "left", true));
                rows[2].cells.push(createCell($translate.instant('DELICING_FORM_H202_DONE_DATE'), "#000", "#d1eef9", "center", "left", true));
                rows[3].cells.push(createCell($translate.instant('DELICING_FORM_H202_DONETIME'), "#000", "#bfbfbf", "center", "left", true));
                rows[4].cells.push(createCell($translate.instant('DELICING_FORM_H202_TEMP'), "#000", "#d1eef9", "center", "left", true));
                rows[5].cells.push(createCell($translate.instant('DELICING_FORM_H202_STARV_DAYS'), "#000", "#bfbfbf", "center", "left", true));
                rows[6].cells.push(createCell($translate.instant('DELICING_FORM_H202_MATURE_BEFORE'), "#000", "#d1eef9", "center", "left", true));
                rows[7].cells.push(createCell($translate.instant('DELICING_FORM_H202_ACTIVE_BEFORE'), "#000", "#bfbfbf", "center", "left", true));
                rows[8].cells.push(createCell($translate.instant('DELICING_FORM_H202_TOTAL_BEFORE'), "#000", "#d1eef9", "center", "left", true));
                rows[9].cells.push(createCell($translate.instant('DELICING_FORM_H202_MATURE_AFTER'), "#000", "#bfbfbf", "center", "left", true));
                rows[10].cells.push(createCell($translate.instant('DELICING_FORM_H202_ACTIVE_AFTER'), "#000", "#d1eef9", "center", "left", true));
                rows[11].cells.push(createCell($translate.instant('DELICING_FORM_H202_TOTAL_AFTER'), "#000", "#bfbfbf", "center", "left", true));
                rows[12].cells.push(createCell($translate.instant('DELICING_FORM_H202_EFF_KJM'), "#000", "#d1eef9", "center", "left", true));
                rows[13].cells.push(createCell($translate.instant('DELICING_FORM_H202_EFF_BEV'), "#000", "#bfbfbf", "center", "left", true));
                rows[14].cells.push(createCell($translate.instant('DELICING_FORM_H202_EFF_TOT'), "#000", "#d1eef9", "center", "left", true));
                rows[15].cells.push(createCell($translate.instant('DELICING_FORM_H202_NET_VOLUME'), "#000", "#bfbfbf", "center", "left", true));
                rows[16].cells.push(createCell($translate.instant('DELICING_FORM_H202_PREDOSE_PPM'), "#000", "#d1eef9", "center", "left", true));
                rows[17].cells.push(createCell($translate.instant('DELICING_FORM_H202_H202_PREDOSE_LITERS'), "#000", "#bfbfbf", "center", "left", true));
                rows[18].cells.push(createCell($translate.instant('DELICING_FORM_H202_WANTED_PPM_MAIN_DOSE'), "#000", "#d1eef9", "center", "left", true));
                rows[19].cells.push(createCell($translate.instant('DELICING_FORM_H202_MAIN_DOSE_LITERS'), "#000", "#bfbfbf", "center", "left", true));
                rows[20].cells.push(createCell($translate.instant('DELICING_FORM_H202_MEASURED_PPM_MAIN_DOSE'), "#000", "#d1eef9", "center", "left", true));
                rows[21].cells.push(createCell($translate.instant('DELICING_FORM_H202_LITER'), "#000", "#92d050", "center", "left", true));
                rows[22].cells.push(createCell($translate.instant('DELICING_FORM_H202_CONTAINER_VOLUME'), "#000", "#d1eef9", "center", "left", true));
                rows[23].cells.push(createCell($translate.instant('DELICING_FORM_H202_TYPE_OF_TARP'), "#000", "#bfbfbf", "center", "left", true));
                rows[24].cells.push(createCell($translate.instant('DELICING_FORM_H202_AGREED_PPM'), "#000", "#d1eef9", "center", "left", true));
                rows[25].cells.push(createCell($translate.instant('DELICING_FORM_H202_AGREED_PPM_TIME'), "#000", "#bfbfbf", "center", "left", true));
                rows[26].cells.push(createCell($translate.instant('DELICING_FORM_H202_HOLD_TIME'), "#000", "#d1eef9", "center", "left", true));
                rows[27].cells.push(createCell($translate.instant('DELICING_FORM_H202_HOLDING_TIME_CLOSED'), "#000", "#bfbfbf", "center", "left", true));
                rows[28].cells.push(createCell($translate.instant('DELICING_FORM_H202_DEATHS_AFTER_TREATEMENT'), "#000", "#ffff00", "center", "left", true));
                rows[29].cells.push(createCell($translate.instant('DELICING_FORM_H202_AVERAGE_WEIGHT'), "#000", "#bfbfbf", "center", "left", true));
                rows[30].cells.push(createCell($translate.instant('DELICING_FORM_H202_BIOMASS_TONNES'), "#000", "#d1eef9", "center", "left", true));
                rows[31].cells.push(createCell($translate.instant('DELICING_FORM_H202_DENSITY'), "#000", "#bfbfbf", "center", "left", true));
                rows[32].cells.push(createCell($translate.instant('DELICING_FORM_H202_AIM_DEPTH'), "#000", "#d1eef9", "center", "left", true));
                rows[33].cells.push(createCell($translate.instant('DELICING_FORM_H202_SALINITY'), "#000", "#bfbfbf", "center", "left", true));
                rows[34].cells.push(createCell($translate.instant('DELICING_FORM_H202_O2_PURESEA'), "#000", "#d1eef9", "center", "left", true));
                rows[35].cells.push(createCell($translate.instant('DELICING_FORM_H202_O2_MIN_VALUE'), "#000", "#bfbfbf", "center", "left", true));
                rows[36].cells.push(createCell($translate.instant('DELICING_FORM_H202_O2_MAX_VALUE'), "#000", "#d1eef9", "center", "left", true));
                rows[37].cells.push(createCell($translate.instant('DELICING_FORM_H202_AQUIS_LITERS'), "#000", "#bfbfbf", "center", "left", true));

                serviceFormData = serviceFormData.map(x => ({...x, parsedData: JSON.parse(x.data)}));
                serviceFormData = _.orderBy(serviceFormData, 'parsedData.summary_donedate', 'parsedData.summary_donetime');
                var sumOfLiters = 0;
                var sumOfDeaths = 0;
                var sumOfBiomassTonnes = 0;

                serviceFormData.forEach(data => {
                    rows[0].cells.push(createCell(data.operationLog && data.operationLog.cages && data.operationLog.cages.length ? data.operationLog.cages[0].cageNumber : '', "#000", "#d1eef9"));
                    var ringCircumference = data.operationLog && data.operationLog.cages && data.operationLog.cages.length && data.operationLog.cages[0].ring
                        && data.operationLog.cages[0].ring && data.operationLog.cages[0].ring.radius ?
                            data.operationLog.cages[0].ring.radius * 2 * Math.PI :
                            '';

                    rows[1].cells.push(createCell(ringCircumference, "#000", "#bfbfbf"));
                    rows[2].cells.push(createCell(moment(data.parsedData.summary_donedate).format('DD.MM.YYYY'), "#000", "#d1eef9"));
                    rows[3].cells.push(createCell(moment(data.parsedData.summary_donetime).format('HH:mm'), "#000", "#bfbfbf"));
                    rows[4].cells.push(createCell(data.parsedData.summary_temp, "#000", "#d1eef9"));
                    rows[5].cells.push(createCell(data.parsedData.summary_starvedays, "#000", "#bfbfbf"));
                    rows[6].cells.push(createCell(data.parsedData.summary_maturebefore, "#000", "#d1eef9"));
                    rows[7].cells.push(createCell(data.parsedData.summary_activebefore, "#000", "#bfbfbf"));
                    var beforeTotal =  (data.parsedData.summary_maturebefore > 0 ? data.parsedData.summary_maturebefore : 0) + (data.parsedData.summary_activebefore > 0 ? data.parsedData.summary_activebefore : 0);
                    rows[8].cells.push(createCell(beforeTotal, "#000", "#d1eef9"));
                    rows[9].cells.push(createCell(data.parsedData.summary_matureafter, "#000", "#bfbfbf"));
                    rows[10].cells.push(createCell(data.parsedData.summary_activeafter, "#000", "#d1eef9"));
                    var afterTotal = (data.parsedData.summary_matureafter > 0 ? data.parsedData.summary_matureafter : 0) + (data.parsedData.summary_activeafter > 0 ? data.parsedData.summary_activeafter : 0);
                    rows[11].cells.push(createCell(afterTotal.toFixed(1), "#000", "#bfbfbf"));
                    var EffKjm = data.parsedData.summary_maturebefore && data.parsedData.summary_matureafter ?
                        (data.parsedData.summary_maturebefore - data.parsedData.summary_matureafter) / data.parsedData.summary_maturebefore * 100 :
                        0;
                    rows[12].cells.push(createCell(EffKjm.toFixed(1), "#000", "#d1eef9"));
                    var EffBev = data.parsedData.summary_activebefore && data.parsedData.summary_activeafter ?
                        (data.parsedData.summary_activebefore - data.parsedData.summary_activeafter) / data.parsedData.summary_activebefore * 100 :
                        0;
                    rows[13].cells.push(createCell(EffBev.toFixed(1), "#000", "#bfbfbf"));
                    var EffTot = beforeTotal && afterTotal ? (beforeTotal - afterTotal) / beforeTotal * 100 : 0;
                    rows[14].cells.push(createCell(EffTot, "#000", "#d1eef9"));
                    rows[15].cells.push(createCell(data.parsedData.summary_netvolume, "#000", "#bfbfbf"));
                    rows[16].cells.push(createCell(data.parsedData.summary_predoseppm, "#000", "#d1eef9"));
                    rows[17].cells.push(createCell(data.parsedData.summary_h2o2predoseliters, "#000", "#bfbfbf"));
                    rows[18].cells.push(createCell(data.parsedData.summary_wantedppmmaindose, "#000", "#d1eef9"));
                    rows[19].cells.push(createCell(data.parsedData.summary_h202maindoseliters, "#000", "#bfbfbf"));
                    rows[20].cells.push(createCell(data.parsedData.summary_measuredppmmaindose, "#000", "#d1eef9"));
                    var h202Liter = (data.parsedData.summary_h2o2predoseliters > 0 ? data.parsedData.summary_h2o2predoseliters : 0) + (data.parsedData.summary_h202maindoseliters > 0 ? data.parsedData.summary_h202maindoseliters : 0);
                    sumOfLiters = (sumOfLiters > 0 ? sumOfLiters : 0) + (h202Liter > 0 ? h202Liter : 0);
                    rows[21].cells.push(createCell(h202Liter, "#000", "#92d050"));
                    rows[22].cells.push(createCell(data.parsedData.summary_containervolume, "#000", "#d1eef9"));
                    var tarpTypeOptions = optionsLists.find((oL) => oL.name == 'tarpType');
                    var selectedtarpType = '';
                    if(tarpTypeOptions && tarpTypeOptions.options){
                        var option = tarpTypeOptions.options.find((x) => x.value === data.parsedData.summary_typeoftarp);
                        if(option && option.name){
                            selectedtarpType = option.name;
                        }
                    }
                    rows[23].cells.push(createCell(selectedtarpType, "#000", "#bfbfbf"));
                    rows[24].cells.push(createCell(data.parsedData.summary_agreedppm, "#000", "#d1eef9"));
                    rows[25].cells.push(createCell(data.parsedData.summary_agreedtime, "#000", "#bfbfbf"));
                    rows[26].cells.push(createCell(data.parsedData.summary_holdingtime, "#000", "#d1eef9"));
                    rows[27].cells.push(createCell(data.parsedData.summary_holdingtimeclosed, "#000", "#bfbfbf"));
                    sumOfDeaths = (sumOfDeaths > 0 ? sumOfDeaths : 0) + (data.parsedData.summary_deathsaftertreatment > 0 ? data.parsedData.summary_deathsaftertreatment : 0);
                    rows[28].cells.push(createCell(data.parsedData.summary_deathsaftertreatment, "#000", "#ffff00"));
                    rows[29].cells.push(createCell(data.parsedData.summary_averageweight, "#000", "#bfbfbf"));
                    sumOfBiomassTonnes = (sumOfBiomassTonnes > 0 ? sumOfBiomassTonnes : 0) + (data.parsedData.summary_biomasstonnes > 0 ? data.parsedData.summary_biomasstonnes : 0);
                    rows[30].cells.push(createCell(data.parsedData.summary_biomasstonnes, "#000", "#d1eef9"));
                    var density = data.parsedData.summary_biomasstonnes && data.parsedData.summary_containervolume ? (data.parsedData.summary_biomasstonnes * 1000) / data.parsedData.summary_containervolume : 0;
                    rows[31].cells.push(createCell(density.toFixed(1), "#000", "#bfbfbf"));
                    rows[32].cells.push(createCell(data.parsedData.summary_aimdepth, "#000", "#d1eef9"));
                    rows[33].cells.push(createCell(data.parsedData.summary_salinity, "#000", "#bfbfbf"));
                    rows[34].cells.push(createCell(data.parsedData.summary_o2puresea, "#000", "#d1eef9"));
                    rows[35].cells.push(createCell(data.parsedData.summary_o2minvalue, "#000", "#bfbfbf"));
                    rows[36].cells.push(createCell(data.parsedData.summary_o2maxvalue, "#000", "#d1eef9"));
                    rows[37].cells.push(createCell(data.parsedData.summary_aquisliters, "#000", "#bfbfbf"));
                });

                rows[21].cells.push(createCell(sumOfLiters.toFixed(1), "#000", "#92d050"));
                rows[28].cells.push(createCell(sumOfDeaths.toFixed(1), "#000", "#ffff00"));
                rows[30].cells.push(createCell(sumOfBiomassTonnes.toFixed(1), "#000", "#d1eef9"));

                return rows;
            }

            function getCommentRow(skipperMessages){
                var row = {
                    cells: [],
                    type: "data"
                };

                row.cells.push(createCell($translate.instant('DELICING_FORM_H202_COMMENT'), "#000", "#fff", "center", "left", true));
                row.cells.push(createCell(skipperMessages, "#000", "#fff", "center", "center", false, 8));

                return row;
            }

            function mapServiceFormOptionList(options)
            {
                return options.map(o => ({
                    id:o.serviceFormOptionsId,
                    name:o.name,
                    options:JSON.parse(o.options)
                }));
            }
        }
    }
})();
