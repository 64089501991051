(function () {
    'use strict';

    angular
      .module('NaviaqWebApp')
      .controller('componentLinkDialog', componentLinkDialog);

    componentLinkDialog.$inject = [
      '$scope',
      '$rootScope',
      'orderService',
      '$windowInstance',
      'selectedComponentLabel',
      'selectedComponentId',
      'itemTreeService',
      'forOrder',
      'orderId'
    ];

    function componentLinkDialog(
      $scope,
      $rootScope,
      orderService,
      $windowInstance,
      selectedComponentLabel,
      selectedComponentId,
      itemTreeService,
      forOrder,
      orderId
    ) {
      var langId = 'no';

      $scope.$on('langChanged', (event, code) => {
          langId = code;
      });
      $scope.addLinkToComponent = addLinkToComponent;
      $scope.close = close;

      $scope.selectedComponentLabel = selectedComponentLabel;
      $scope.selectedComponentId = selectedComponentId;

      initController();
      function initController() {

      }

      function addLinkToComponent(){
        if($scope.link){
          $rootScope.$broadcast('showBusyIndicator', {
              id: 'addLinkToComponent',
              destination: '.component-link-dialog'
          });
          if(forOrder){
            orderService.uploadVideoForOrder($scope.link, $rootScope.authData.userId, orderId).then(i => {
              if($scope.linkDescription){
                i.description = $scope.linkDescription;
                itemTreeService.updateItemFileWebApi(i).then(() =>{
                  close(i);
                  $rootScope.$broadcast('hideBusyIndicator', 'addLinkToComponent');
                });
              }else{
                close(i);
                $rootScope.$broadcast('hideBusyIndicator', 'addLinkToComponent');
              }
            });
          } else {
            orderService.setVideoUrlOnComponent($scope.selectedComponentId, $scope.link, $rootScope.authData.userId, orderId).then(i => {
              if($scope.linkDescription){
                i.description = $scope.linkDescription;
                itemTreeService.updateItemFileWebApi(i).then(() =>{
                  close(i);
                  $rootScope.$broadcast('hideBusyIndicator', 'addLinkToComponent');
                });
              }else{
                close(i);
                $rootScope.$broadcast('hideBusyIndicator', 'addLinkToComponent');
              }
            });
          }
        }
      }

      function close(params = null){
        $windowInstance.close(params);
      }
    }
  })();
