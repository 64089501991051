angular.module('NaviaqWebApp').directive('fitToWindow', ['$window', function ($window) {
    'use strict';

    return {
        link: function (scope, element, attrs) {
            var headerHeight = element[0].querySelector('.dashboard-header').clientHeight;
            
            setContentHeight();

            angular.element($window).bind('resize', function () {
                setContentHeight();
            });

            function setContentHeight() {
                var documentHeight = $window.innerHeight;
                var contentHeight = documentHeight - headerHeight - 100;
                var contentElement = element[0].querySelector('.dashboard-content');
                contentElement.style.height = contentHeight + 'px';
            }
        }
    };
}]);
