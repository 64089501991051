(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('WarningDialogController', warningDialogController);

    warningDialogController.$inject = ['$scope', '$windowInstance', 'content', '$translate', '$sce'];

    function warningDialogController($scope, $windowInstance, content, $translate, $sce) {
        $scope.cancel = cancel;
        $scope.centent = content ?  $sce.trustAsHtml(content) : $translate.instant('MENU_MODAL_LOGOUT_MSG', null, null);

        initController();
        function initController() {
            $windowInstance.rendered.then(function () {
                $('.k-overlay').click(cancel);
            });
        }

        function cancel() {
            $windowInstance.close();
        }
    }
}) ();
