(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('detectInputChanges', function() {
      return {
        restrict: 'A',
        link: function(scope, element) {
          element.on('input', function() {
            scope.isDirty = true;
            console.log(element);
            scope.$apply();
          });
        }
      };
    });
})();
