(function () {
    'use strict';
    angular.module('NaviaqWebApp').directive('datePickerElement', datePickerElement);

    datePickerElement.$inject = [];

    function datePickerElement() {
        return {
            controller: ['$scope', 'htmlElementService', '$translate', datePickerElement],
            replace: true,
            restrict: 'E',
            templateUrl: 'app/shared/directives/date-picker-element.html',
            scope: {
                elementId: '=elementId',
                customClass: '=customClass',
                customKOptions: '=customKOptions',
                showNowBtn: '=showNowBtn',
                isReadOnly: '=isReadOnly',
                isRequired: '=isRequired',
                requiredMsg: '=requiredMsg',
                kendoDataModel: '=kendoDataModel',
                changeFunctionName: '=changeFunctionName',
                datePicker: '=?datePicker'
            },
        };

        function datePickerElement($scope, htmlElementService, $translate) {

            htmlElementService.checkIfElementExists($scope.elementId)
            .then(function () {

                var options = {
                    value: $scope.kendoDataModel ? new Date($scope.kendoDataModel) : null,
                    format: "dd.MM.yyyy",
                    change: function (e) {
                        $scope.$applyAsync(() => {
                            var value = $scope.datePicker.value();
                            $scope.kendoDataModel = value;
                            var dateChangeFunction = $scope.$parent[$scope.changeFunctionName];
                            if (dateChangeFunction) {
                                dateChangeFunction(value);
                            }
                        });
                    }
                };

                if($scope.customKOptions){
                    var defaultProperties = ['value', 'format', 'change'];
                    Object.keys($scope.customKOptions).forEach(function(key,index) {
                        if(!defaultProperties.includes(key)){
                            options[key] = $scope.customKOptions[key];
                        }
                     });
                }

                $scope.datePicker = $(`#${$scope.elementId}`).kendoDatePicker(options).data("kendoDatePicker");
                initDirective();
            });

            initDirective();
            function initDirective()
            {
                $scope.$watch('isReadOnly', function () {
                    var datepicker = $(`#${$scope.elementId}`).data("kendoDatePicker");
                    if (datepicker && !$scope.isReadOnly) {
                        datepicker.enable(true);
                    } else if(datepicker){
                        datepicker.enable(false);
                    }
                });

                $scope.$watch('kendoDataModel', function () {
                    var datepicker = $(`#${$scope.elementId}`).data("kendoDatePicker");
                    if (datepicker && $scope.kendoDataModel) {
                        datepicker.value(new Date($scope.kendoDataModel));
                    }
                });

                var validator = $scope.$parent.validator;
                if(validator && validator.options){
                    var dateRule = function(input) {
                        if(input.is(`[name="${$scope.elementId}"]`)){
                            var val = input.val();
                            var inputDate = formatDate(val);
                            if(val && inputDate == "Invalid Date"){
                                return false
                            } else {
                                return true;
                            }
                        } else {
                            return true
                        }
                    };
                    validator.options.rules["dateValidator"] = dateRule;
                    validator.options.messages["dateValidator"] = $translate.instant('G_DATE_INVALID_MSG');
                }
            }

            function formatDate(dateString){
                var reggie = /(\d{2})\.(\d{2})\.(\d{4})/;
                var dateArray = reggie.exec(dateString);
                if(!dateArray || dateArray.length != 4){
                    return 'Invalid Date';
                }

                var year = Number(dateArray[3]);
                var month = Number(dateArray[2]);
                var day = Number(dateArray[1]);

                const lastDay = moment(`${year}-${month}-01`, 'YYYY-MM-DD').endOf('month').format('D');
                if((day < 1 || day > lastDay) || (month < 1 && month > 12)){
                    return 'Invalid Date';
                }

                return new Date(
                    (+dateArray[3]),
                    (+dateArray[2])-1,
                    (+dateArray[1])
                );
            }

            $scope.setDateToNow = function() {
                $scope.$applyAsync(() => {
                    $scope.datePicker.value(new Date());
                    $scope.kendoDataModel = new Date();
                });
            }
        }
    }
})();
