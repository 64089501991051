(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('mainHistoryService', mainHistoryService);

    mainHistoryService.$inject = ['$q', '$http', 'serviceUrls'];

    function mainHistoryService($q, $http, serviceUrls) {
        const locationLogWebApiUrl = `${serviceUrls.webApiBaseUrl}/locationLog`;
        const netWebApiUrl = `${serviceUrls.webApiBaseUrl}/net`;
        const ringWebApiUrl = `${serviceUrls.webApiBaseUrl}/ring`;

        return {
            landBaseTypeId: '8b573629-ba21-4a04-ad78-9ff337a56948',
            getNetHistoryByFilter: getNetHistoryByFilter,
            getRingHistoryByFilter: getRingHistoryByFilter,
            getLocationLogsByFilter: getLocationLogsByFilter
        };
        
        function getLocationLogsByFilter(locationNumber, fromDate, toDate) {
            const deferred = $q.defer();

            fromDate = moment(fromDate).format('YYYY-MM-DDTHH:mm:ss');
            toDate = moment(toDate).add(1, 'd').startOf('d').add(-1, 's').format('YYYY-MM-DDTHH:mm:ss');

            $http.get(`${locationLogWebApiUrl}/locationLogsByDateRangeFilter?locationNumber=${locationNumber}&fromDate=${fromDate}&toDate=${toDate}`)
            .then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getNetHistoryByFilter(locationNumber, fromDate, toDate) {
            const deferred = $q.defer();

            fromDate = moment(fromDate).format('YYYY-MM-DDTHH:mm:ss');
            toDate = moment(toDate).add(1, 'd').startOf('d').add(-1, 's').format('YYYY-MM-DDTHH:mm:ss');

            $http.get(`${netWebApiUrl}/historyByFilter?locationNumber=${locationNumber}&fromDate=${fromDate}&toDate=${toDate}`)
            .then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getRingHistoryByFilter(locationNumber, fromDate, toDate) {
            const deferred = $q.defer();

            fromDate = moment(fromDate).format('YYYY-MM-DDTHH:mm:ss');
            toDate = moment(toDate).add(1, 'd').startOf('d').add(-1, 's').format('YYYY-MM-DDTHH:mm:ss');

            $http.get(`${ringWebApiUrl}/historyByFilter?locationNumber=${locationNumber}&fromDate=${fromDate}&toDate=${toDate}`)
            .then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
