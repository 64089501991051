(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OperationLogExtDetailsModalController', OperationLogExtDetailsModalController);

    function OperationLogExtDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        $translate,
        authService,
        departmentService,
        logService,
        itemTreeService,
        userService,
        mediaService,
        formTypes,
        dateUtility,
        logActionTypes,
        formType,
        departmentId,
        relatedItemId,
        operationLog,
        listService,
        timeBankService,
        confirmDialogService,
        DepartmentStatus,
        sanitizeService
    ){
        $scope.authData = authService.getAuthData();
        $scope.logActionTypes = logActionTypes;
        $scope.formType = formType;
        $scope.formTypes = formTypes;
        $scope.departments = [];
        $scope.logActions = [];
        $scope.users = [];
        $scope.listElements = [];
        $scope.departmentId = departmentId;
        $scope.relatedItemId = relatedItemId;
        $scope.operationLogDate = operationLog ? kendo.parseDate(operationLog.doneOn) : new Date();
        $scope.doneOnFieldRequiredMsg = $translate.instant('OPERATIONLOG_GRID_DONEON_REQUIRED');
        $scope.itemElapsedInterval = null;
        $scope.hasNewLogAdded = false;
        $scope.isLogOverlapped = false;
        $scope.operationLogDescription = "";
        $scope.showErrorForDescription = false;
        $scope.logActionPairs = null;
        $scope.activeOperationLog = null;
        $scope.hasUserSelector = false;

        // File handling
        $scope.operationLogAttachmentItem = null;
        $scope.operationLogUploaderId = 'operationLogUploader';
        $scope.documents = [];

        
        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'loadOperationLogModal',
                destination: '.details-modal'
            });

            let requests = {
                getLogActionPairs: logService.getLogActionPairsByContractorId($scope.authData.contractorId)
            };

            // Fetch operation log if opened as edit or read only
            if ($scope.formType === $scope.formTypes.edit
                || $scope.formType === $scope.formTypes.readOnly
            ){
                requests.getOperationLogById = logService.getOperationLogById(operationLog.operationLogId);
            }
        
            $q.all(requests).then((result) => {
                if (result.getOperationLogById)
                {
                    operationLog = result.getOperationLogById;
                    
                    //initModal
                    $scope.operationLogDescription = operationLog.description;
                    $scope.operationLogComment = operationLog.comment;
                    $scope.operationLogValue = operationLog.value;
                
                    $scope.selectedLogAction = operationLog.logAction;

                    $scope.operationLogDepartments.dataSource.read();
                    $scope.operationLogDepartments.refresh();
                
                    
                    // Fetch List associated with LogAction LogAction on operationlog has one
                    if (operationLog.logAction && operationLog.logAction.listId) {
                        listService.getListElementsByListId(operationLog.logAction.listId)
                        .then((result) => {
                            $scope.listElements = result;
                            if ($scope.formType === $scope.formTypes.edit) {
                                $scope.selectedListElement = $scope.listElements.find((le => le.value === operationLog.description));
                            }
                            
                            $scope.listElementsOptions.dataSource.read();
                        });
                    }
                    
                    // Fetch checklist items for operationlog
                    logService.getCheckListItemsByOperationLogWebAPI(operationLog.logActionId, operationLog.operationLogId).then(function (items) {
                        var formattedItems = [];
                        for (var i = 0; i < items.length; ++i) {
                            if(items[i].isActive){
                                items[i].value = items[i].checkListValue ? items[i].checkListValue.numericValue : null;
                                formattedItems.push(formatChecklistItem(items[i]));
                            }
                        }
                        formattedItems = _.sortBy(formattedItems, 'description');

                        $scope.checkListGrid.dataSource.data(formattedItems);
                        $scope.checkListGrid.refresh();
                    });

                    // Try fetching attachment Item related to this operationlog with ItemFiles, and push them to kendo files widget
                    itemTreeService.getItemsByRelatedEntityIdWebApi(operationLog.contractorId, operationLog.operationLogId)
                    .then(items => {
                        $scope.operationLogAttachmentItem = _.find(items, item => item.category.keyName == "ATTACHMENT");
                        if ($scope.operationLogAttachmentItem) {
                            $scope.documents = $scope.operationLogAttachmentItem.files.filter(d => !d.naviaqMediaId).map(function (obj) {
                                return {
                                    id: obj.id,
                                    fileName: obj.fileName,
                                    url: mediaService.createMediaUrlMediaBank(obj.mediaId),
                                    mediaId: obj.mediaId,
                                    base64Content:  null,
                                    createdOn: obj.createdOn,
                                    description: obj.description,
                                    selectedComponentId: null,
                                    displayText: null,
                                    FileType: 0
                                }
                            });
                        }
                    });
                }

                $scope.logActionPairs = result.getLogActionPairs;
            }).finally(function () {
                $rootScope.$broadcast('hideBusyIndicator', 'loadOperationLogModal');
            });
        }

        /** Begin log overlap check code */
        function checkForLogOverlap() {
            if($scope.selectedPerson 
                && $scope.selectedPerson.userId 
                && $scope.selectedLogAction 
                && $scope.selectedLogAction.logActionId
            ){
                if($scope.selectedLogAction 
                    && $scope.selectedLogAction.logActionType == logActionTypes.timeBankLog
                ){
                    var startLog = $scope.logActionPairs.find(x => x.startLogActionId == $scope.selectedLogAction.logActionId);
                    var endLog = $scope.logActionPairs.find(x => x.endLogActionId == $scope.selectedLogAction.logActionId);
                    if(startLog){
                        checkActiveLog();
                        $scope.diffTimeInformation = null;
                    } else if(endLog && endLog.balanceType.includes('Hourly')){
                        $scope.activeOperationLog = null;
                        $scope.diffTimeInformation = null;
                        $('.k-primary').prop( "disabled", false );
                        $('.k-primary').removeClass( "k-state-disabled");
                        checkTimeDifference(endLog.startLogActionId);
                    }

                } else {
                    $scope.activeOperationLog = null;
                    $scope.diffTimeInformation = null;
                    $('.k-primary').prop( "disabled", false );
                    $('.k-primary').removeClass( "k-state-disabled");
                }
            }
        }

        function checkActiveLog(){
            if(!$scope.selectedDepartment || !$scope.selectedDepartment.departmentId){
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'checkActioveLog',
                destination: '.details-modal'
            });

            timeBankService.checkActiveLog($scope.selectedPerson.userId, $scope.selectedLogAction.logActionId, $scope.selectedDepartment.departmentId).then(function(activeStartLog){
                if(activeStartLog){
                    $scope.activeOperationLog = activeStartLog;
                    $('.k-primary').prop( "disabled", true );
                    $('.k-primary').addClass( "k-state-disabled");
                } else {
                    $scope.activeOperationLog = null;
                    $('.k-primary').prop( "disabled", false );
                    $('.k-primary').removeClass( "k-state-disabled");
                }
            }).finally(function () {
                $rootScope.$broadcast('hideBusyIndicator', 'checkActioveLog');
            });
        }

        function checkTimeDifference(startActionId){
            if(!$scope.selectedDepartment || !$scope.selectedDepartment.departmentId){
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logDetailsModalController',
                destination: '.k-widget.k-window'
            });
            timeBankService.checkActiveLog($scope.selectedPerson.userId, startActionId, $scope.selectedDepartment.departmentId)
            .then(function (startOperationLog) {
                if(startOperationLog){
                    $scope.startLogDoneOnDate = startOperationLog.doneOn;
                    if($scope.operationLogDate){
                        var diff = ((kendo.parseDate($scope.operationLogDate) - kendo.parseDate($scope.startLogDoneOnDate.substring(0, 16))) / (1000 * 60 * 60));
                        var hrs = parseInt(diff.toFixed(10));
                        var min = Math.round((Number(diff)-hrs) * 60);
                        var timeDifference = hrs == 0 ? min + ' ' + $translate.instant('G_MINUTE')
                        : hrs + ' ' + $translate.instant('G_HOURS')  + ' ' + min + ' ' + $translate.instant('G_MINUTE');

                        $scope.diffTimeInformation = {
                            description : timeDifference,
                            hours : hrs,
                            minutes : min
                        };
                    }
                }
            }).finally(function () {
                $rootScope.$broadcast('hideBusyIndicator', 'logDetailsModalController');
            });
        }
        /** End log overlap check code */

        $scope.operationLogDepartmentsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => {
                        departmentService.getDepartmentsWebapi($scope.authData.contractorId, true, true).then((departments) => {
                            var departmentToSelect = _.find(departments, { departmentId: $scope.departmentId });
            
                            if (departmentToSelect) {
                                $scope.selectedDepartment = departmentToSelect;
                                $scope.departmentId = departmentToSelect.departmentId;
                            }

                            if ($scope.formType === $scope.formTypes.readOnly
                                && departmentToSelect === undefined && operationLog.department) {
            
                                $scope.departmentId = operationLog.department.departmentId;
                                departments.push({
                                    departmentId: operationLog.department.departmentId,
                                    name: operationLog.department.name
                                });
                            }
                            departments = _.sortBy(departments, 'name');
            
                            e.success(departments.filter(d => d.status === DepartmentStatus.Active || d.departmentId == departmentId));
                            
                            loadLogActions();
                        });
                    }
                }
            }),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            noDataTemplate: 'Ingen treff',
            select: (e) => {
                if (e && e.dataItem) {
                    $scope.itemElapsedInterval = null;
                }
            },
            change: () =>{
                if ($scope.selectedDepartment) {
                    $scope.departmentId = $scope.selectedDepartment.departmentId;
                    $scope.operationLogEquipment.dataSource.read();
                    $scope.operationLogEquipment.refresh();
                }
                checkForLogOverlap();
                loadLogActions();
            }
        };

        $scope.operationLogEquipmentOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => {
                        if ($scope.departmentId) {
                            itemTreeService.getItemsByRelatedEntityIdWebApi($scope.authData.contractorId, $scope.departmentId, true).then((departmentEquipment) => {
                                if ($scope.relatedItemId) {
                                    $scope.selectedEquipment = departmentEquipment.find((e) => e.id === $scope.relatedItemId);
                                }
                                e.success(_.sortBy(departmentEquipment, ['description']));
                            });
                        } else {
                            e.success([]);
                        }
                    }
                }
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            noDataTemplate: 'Ingen treff',
            select:(e) => {
                if (e && e.dataItem && e.dataItem.validityInterval) {
                    $scope.itemElapsedInterval = e.dataItem.validityInterval.intervalElapsed;
                }
                else {
                    $scope.itemElapsedInterval = null;
                }
            }
        };

        $scope.operationLogLogActionsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.logActions)
                }
            }),
            dataValueField: 'logActionId',
            dataTextField: 'description',
            noDataTemplate: 'Ingen treff',
            change: () => {
                //getLogActionListElements
                $scope.isLogOverlapped = false;
                if ($scope.selectedLogAction && $scope.selectedLogAction.listId) {
                    listService.getListElementsByListId($scope.selectedLogAction.listId)
                    .then((result) => {
                        $scope.listElements = result;

                        if ($scope.formType === $scope.formTypes.edit) {
                            $scope.selectedListElement = $scope.listElements.find((le => le.value === operationLogToEdit.description));
                        }

                        $scope.listElementsOptions.dataSource.read();
                        isDescriptionValid();
                    });
                }
                checkForLogOverlap();
                // setUserFieldVisibility
                if($scope.selectedLogAction.logActionType == logActionTypes.timeBankLog || $scope.selectedLogAction.userListNeeded){
                    $scope.$applyAsync(() => {
                        $scope.hasUserSelector = true;
                    });

                } else {
                    $scope.$applyAsync(() => {
                        $scope.hasUserSelector = false;
                    });
                
                }

                //LogAction should only change at addition
                if ($scope.formType === $scope.formTypes.add) {
                    var checkListItems = [];
                    if ($scope.selectedLogAction && $scope.selectedLogAction.checkListItems) {
                        for (var i = 0; i < $scope.selectedLogAction.checkListItems.length; ++i) {
                            checkListItems.push(formatChecklistItem($scope.selectedLogAction.checkListItems[i]));
                        }
                        checkListItems = _.sortBy(checkListItems, 'description');
                    }
                    if ($scope.checkListGrid) {
                        $scope.checkListGrid.dataSource.data(checkListItems);
                        $scope.checkListGrid.refresh();
                    }
    
    
                }
                if ($scope.selectedLogAction && $scope.selectedLogAction.logActionType !== logActionTypes.timeBankLog) {
                    $scope.selectedPerson = null;
                }
    
            
            }
        };

        
        $scope.operationLogPersonOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => {
                        userService.getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId], includeConglomerateUsers: true}).then(function (users) {
                            if ($scope.formType === $scope.formTypes.add) {
                                var personToSelect = _.find(users, {
                                    userName: $scope.authData.username
                                });
                                $scope.selectedPerson = personToSelect;
                            }
                            if ($scope.formType === $scope.formTypes.edit) {
                                if (operationLog.assignedUser && operationLog.assignedUser[0]) {
                                    var personToSelect = _.find(users, { userId: operationLog.assignedUser[0].userId });
                                    $scope.selectedPerson = personToSelect;
                                }
                            }
            
                            // filter users not relevant for current contractor
                            var filteredUsers = users.filter(u =>
                                u.userId == ($scope.selectedPerson != null ? $scope.selectedPerson.userId : null) ||
                                u.contractorId == $scope.authData.contractorId
                            );
            
                            e.success(filteredUsers);
                        }, function () {
                            e.success([]);
                        });
                    }
            
                },
                sort: [{ field: "displayName", dir: "asc" }]
            }),
            dataValueField: 'userId',
            dataTextField: 'displayName',
            noDataTemplate: 'Ingen treff',
            change: (e) => {
               checkForLogOverlap();
            }
        };

        $scope.listElementsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.listElements),
                },
            }),
            dataValueField: 'value',
            dataTextField: 'displayName',
            change: () => {
                isDescriptionValid();
            }
        };

        $scope.checklistGridOptions = {
            height: 423,
            dataSource: {
                data: [],
                schema: {
                    model: {
                        id: "checkListValueId",
                        fields: {
                            checkListValueId: { editable: false, nullable: true, type: 'string' },
                            boolValue: { type: 'boolean', editable: false },
                            notOk: { type: 'boolean', editable: false },
                            notApplicable: { type: 'boolean', editable: false },
                            stringValue: { type: 'string' },
                            description: { type: 'string', editable: false },
                            additionalInformation: { type: 'string', editable: false },
                            measurementUnitDescription: { type: 'string', editable: false }
                        }
                    }
                }
            },
            sortable: true,
            filterable: true,
            pageable: false,
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            columns: [{
                field: 'boolValue',
                title: $translate.instant('G_CHECKLIST_BOOLVALUE'),
                template: '<span class="center-content" style="padding: 20px"><input type="radio" name="#=checkListItemId#" class="check-done" #= boolValue ? \'checked="checked"\' : ""# ng-click="itemChecked(\'#:checkListItemId#\', $event,\'boolValue\')" /></span>',
                width: 50,
                sortable: false,
                filterable: false
            }, {
                field: 'notOk',
                title: $translate.instant('G_CHECKLIST_NOTOK'),
                template: '<span class="center-content" style="padding: 19px"><input type="radio" name="#=checkListItemId#" class="check-done" #= notOk ? \'checked="checked"\' : ""# ng-click="itemChecked(\'#:checkListItemId#\',  $event,\'notOk\')" /></span>',
                width: 60,
                sortable: false,
                filterable: false
            }, {
                field: 'notApplicable',
                title: $translate.instant('G_CHECKLIST_NOTAPPLICABLE'),
                template: '<span class="center-content" style="padding: 20px"><input type="radio" name="#=checkListItemId#" class="check-done" #= notApplicable ? \'checked="checked"\' : ""# ng-click="itemChecked(\'#:checkListItemId#\', $event,\'notApplicable\')" /></span>',
                width: 80,
                sortable: false,
                filterable: false
            }, {
                field: 'description',
                title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_DESCRIPTION'),
                editable: false,
                template: '<span kendo-tooltip k-content="\'{{ dataItem.description }}\'" >{{ dataItem.description }}</span>',

            }, {
                field: 'additionalInformation',
                title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_INFO'),
                editable: false,
                template: '<span kendo-tooltip k-content="\'{{ dataItem.additionalInformation }}\'" >{{ dataItem.additionalInformation }}</span>',

            }, {
                field: 'value',
                title: $translate.instant('G_VALUE'),
                width: 80,
            }, {
                field: 'measurementUnitDescription',
                title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_MEASUREMENTTYPE'),
                width: 80
            }]
        };

        //Public functions
        $scope.itemChecked = itemChecked;
        $scope.close = close;
        $scope.saveAndNew = saveAndNew;
        $scope.saveAndClose = saveAndClose;
        $scope.hasUserAssignRole = hasUserAssignRole;
        $scope.isDescriptionValid = isDescriptionValid;
        
        function isDescriptionValid(){
            var showError = false;
            if (!$scope.hasModule('RequiredDescriptionInLog')) {
                showError = false;
                return;
            }
            if ($scope.selectedLogAction && $scope.selectedLogAction.isDescriptionRequired !== null
                && $scope.selectedLogAction.isDescriptionRequired){
                    if ($scope.selectedLogAction.listId && $scope.selectedListElement){
                        showError = false;
                    } else if (!$scope.selectedLogAction.listId && $scope.operationLogDescription && $scope.operationLogDescription.trim().length > 0){
                        showError = false;
                    }
                    else {
                        $scope.operationLogDescription = "";
                        showError = true;
                    }
            }
            else {
                showError = false;
            }
            $scope.$applyAsync(function () {
                $scope.showErrorForDescription =  showError;
            });
        }
        
        // marks an item as checked when clicked in the grid
        function itemChecked(itemId, event, valueType) {
            var checkedItem = _.find($scope.checkListGrid.dataItems(), { checkListItemId: itemId });
            var targetValue = (event.target.value == 'on');
            if (checkedItem.notOk == targetValue && valueType == 'notOk'
                || checkedItem.notApplicable == targetValue && valueType == 'notApplicable'
                || checkedItem.boolValue == targetValue && valueType == 'boolValue') {
                $('input[name$="' + itemId + '"]').prop("checked", false);
                checkedItem.notOk = false;
                checkedItem.notApplicable = false;
                checkedItem.boolValue = false;
            } else {
                checkedItem.notOk = valueType == 'notOk';
                checkedItem.notApplicable = valueType == 'notApplicable';
                checkedItem.boolValue = valueType == 'boolValue';
            }
        }

        function close(result) {
            if ($scope.hasNewLogAdded) {
                $rootScope.$broadcast('newLogAdded');
            }
            $windowInstance.close(result);
        }

        function hasUserAssignRole() {
            if ( $scope.formType === $scope.formTypes.edit){
                if($scope.selectedLogAction &&
                $scope.selectedLogAction.logActionType == logActionTypes.timeBankLog &&
                ($scope.authData.isSuperuser || $scope.authData.roles.find((x) => x.key === 'AssignUserToLog'))){
                    return true;
                }
            }else if($scope.authData.isSuperuser || $scope.authData.roles.find((x) => x.key === 'AssignUserToLog')){
                return true;
            }
            return false;
        }

        function saveOperationLog() {
            var deferred = $q.defer();
            if ($scope.validator.validate()) {
                var now = new Date();

                var isEdit = $scope.formType === $scope.formTypes.edit;
                var isTimeBankType = $scope.selectedLogAction.logActionType == 4 ? true : false;

                var currentOperationLog = {
                    contractorId: $scope.authData.contractorId,
                    departmentId: $scope.operationLogDepartments.value(),
                    logActionId: $scope.selectedLogAction.logActionId,
                    logAction: {
                        description: $scope.selectedLogAction.description,
                        hasValue: $scope.selectedLogAction.hasValue,
                        isPrivate: $scope.selectedLogAction.isPrivate,
                        logActionId: $scope.selectedLogAction.logActionId,
                        valueName: $scope.selectedLogAction.valueName,
                        status: $scope.selectedLogAction.status,
                        logActionType : $scope.selectedLogAction.logActionType
                    },
                    itemId: $scope.selectedEquipment ? $scope.selectedEquipment.id : null,
                    description: $scope.selectedLogAction.listId ? ($scope.selectedListElement ? $scope.selectedListElement.value : null) : $scope.operationLogDescription,
                    comment: $scope.operationLogComment,
                    createdOn: isEdit ? dateUtility.toJson(operationLog.createdOn) : dateUtility.toJson(now),
                    createdBy: isEdit ? operationLog.createdBy : $scope.authData.username,
                    editedOn: dateUtility.toJson(now),
                    editedBy: $scope.authData.username,
                    status: 0,
                    doneOn: moment(new Date($scope.operationLogDate)).format('YYYY-MM-DD HH:mm:ss'),
                    value: $scope.operationLogValue,
                    assignedUser: [{
                        userId: $scope.selectedPerson ? $scope.selectedPerson.userId : $scope.authData.userId,
                        role: isTimeBankType ? 'TimeBankUser': 'Assigned',
                        createdBy: $scope.authData.username,
                        createdOn: dateUtility.toJson(now),
                        status: 0
                    }]
                };
                
                let operationLogPromise;
                
                if ($scope.formType === $scope.formTypes.add) { 
                    operationLogPromise = logService.addOperationLogWebApi(currentOperationLog);
                } else if ($scope.formType === $scope.formTypes.edit) {
                    currentOperationLog.OperationLogId = operationLog.operationLogId;
                    operationLogPromise = logService.updateOperationLogWebApi(currentOperationLog)
                } else {
                    deferred.resolve();
                }
                
                operationLogPromise
                .then((operationLogId) => {
                    saveCheckListItems(operationLogId)
                    .then(() => deferred.resolve(operationLogId))
                    .catch(error => {
                        console.error(`Failed on saving checklist items for OperationLogId ${operationLogId} formType: ${$scope.formType}, error: ${error}`)
                        deferred.reject(error);    
                    })
                })
                .catch(error => {
                    console.error(`Failed on operationlog save formType: ${$scope.formType}, error: ${error}`)
                    deferred.reject(error);
                });

            } else {
                deferred.reject();
            }


            return deferred.promise;
        }

        function saveAndNew() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'OperationLogExtDetailsModal',
                destination: '#operation-log-form',
                overlay: true
            });
            isDescriptionValid();
            if ($scope.showErrorForDescription) {
                $rootScope.$broadcast('hideBusyIndicator', 'OperationLogExtDetailsModal');
                return;
            }

            if ($scope.validator.validate()) {

                $scope.hasNewLogAdded = true;

                saveOperationLog().then(function () {
                    //Reset values
                    $scope.formType = $scope.formTypes.add;
                    $scope.operationLogDate = new Date();

                    $scope.operationLogDepartments.dataSource.read();
                    $scope.operationLogDepartments.refresh();

                    $scope.operationLogEquipment.dataSource.read();
                    $scope.operationLogEquipment.refresh();

                    $scope.operationLogLogActions.dataSource.read();
                    $scope.operationLogLogActions.refresh();

                    $scope.operationLogPerson.dataSource.read();
                    $scope.operationLogPerson.refresh();

                    $scope.selectedLogAction = null;
                    $scope.selectedPerson = null;

                    $scope.operationLogDescription = null;
                    $scope.operationLogComment = null;
                    $scope.operationLogDate = new Date();
                    $scope.operationLogValue = null;

                    $scope.operationLogAttachmentItem = null;

                }).catch(error => {
                    if(error && error.status == 400){
                        $scope.isLogOverlapped = true;
                    }
                }).finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'OperationLogExtDetailsModal');
                });
            }
            else {
                $rootScope.$broadcast('hideBusyIndicator', 'OperationLogExtDetailsModal');
            }
        }

        function saveAndClose() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'OperationLogExtDetailsModal',
                destination: '#operation-log-form',
                overlay: true
            });
            isDescriptionValid();
            if ($scope.showErrorForDescription) {
                $rootScope.$broadcast('hideBusyIndicator', 'OperationLogExtDetailsModal');
                return;
            }
            if ($scope.validator.validate()) {

                saveOperationLog().then(function (operationLogId) {
                    // UplaodFiles
                    var currentContractorId = operationLog ? operationLog.contractorId : $scope.authData.contractorId;
                    $rootScope.$broadcast(`${$scope.operationLogUploaderId}Broadcast`, operationLogId, currentContractorId);
                    $rootScope.$broadcast('newLogAdded');
                    close(operationLogId);
                }).catch(error => {
                    if(error && error.status == 400){
                        $scope.isLogOverlapped = true;
                    }
                }).finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'OperationLogExtDetailsModal');
                });
            }
            else {
                $rootScope.$broadcast('hideBusyIndicator', 'OperationLogExtDetailsModal');
            }
        }

        function formatChecklistItem(item) {
            item.value = '';
            item.boolValue = false;
            item.notApplicable = false;
            item.notOk = false;
            if (item.checkListValues.length > 0) {
                item.checkListValue = item.checkListValues[0];
                if (item.checkListValue.checkListValueId) {
                    item.checkListValueId = item.checkListValue.checkListValueId;
                }

                if (item.checkListValue.numericValue !== null && item.checkListValue.numericValue !== undefined) {
                    item.value += item.checkListValue.numericValue;
                    item.value += ' ';
                }

                if (item.checkListValue.stringValue !== null && item.checkListValue.stringValue !== undefined) {
                    item.value += item.checkListValue.stringValue;
                }

                item.boolValue = item.checkListValue.boolValue;
                item.notOk = item.checkListValue.notOk;
                item.notApplicable = item.checkListValue.notApplicable;

            }

            if (item.measurementUnitType) {
                item.measurementUnitDescription = item.measurementUnitType.measurementUnitDescription;
            }

            return item;
        }

        function saveCheckListItems(operationLogId) {
            var deferred = $q.defer();

            $scope.checkListGrid.saveChanges();

            var checkListItems = $scope.checkListGrid.dataItems(),
                upsertPromises = [];
            for (var i = 0; i < checkListItems.length; ++i) {

                var checklistValue = checkListItems[i].checkListValue;
                if(!checklistValue){
                    checklistValue = {
                        numericValue: checkListItems[i].value,
                        stringValue: checkListItems[i].stringValue
                    };
                } else {
                    if ($.isNumeric(checkListItems[i].value)) {
                        checklistValue.numericValue = checkListItems[i].value;
                    }
                }

                var now = new Date();
                var checkListValue = {
                    CheckListValueId: checkListItems[i].checkListValueId,
                    CheckListItemId: checkListItems[i].checkListItemId,
                    OperationLogId: operationLogId,
                    NumericValue: checklistValue.numericValue,
                    StringValue: checklistValue.stringValue,
                    BoolValue: checkListItems[i].boolValue,
                    NotApplicable: checkListItems[i].notApplicable,
                    NotOk: checkListItems[i].notOk,
                    EditedOn: dateUtility.toIsoString(now),
                    EditedBy: $rootScope.authData.displayName,
                    relatedEquipmentItemId: checkListItems[i].relatedEquipmentItemId
                };

                //Save only if already exists, or has values
                if (checkListValue.CheckListValueId || checkListValue.BoolValue || checkListValue.NotApplicable || checkListValue.NotOk
                    || checkListValue.NumericValue || checkListValue.StringValue) {
                    upsertPromises.push(logService.upsertCheckListValue(checkListValue));
                }
            }

            $q.all(upsertPromises).then(function (data) {
                deferred.resolve(data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function loadLogActions(){
            if($scope.selectedDepartment && $scope.selectedDepartment.departmentId){
                logService.getLogActionsFilteredByIsActiveField($scope.authData.contractorId, $scope.selectedDepartment.departmentId, true, operationLog && operationLog.logActionId ? operationLog.logActionId : null).then(function(logActions){
                    $scope.logActions = logActions.filter(logAction =>
                        logAction.logActionId == (operationLog !== null ? operationLog.logActionId : null) ||
                        logAction.contractorId == $scope.authData.contractorId
                    );
                    $scope.operationLogLogActionsOptions.dataSource.read();

                     //LogActionId
                    if ($scope.formType === $scope.formTypes.edit) {
                        var logActionToSelect = _.find($scope.logActions, { logActionId: operationLog.logActionId });
                        $scope.selectedLogAction = logActionToSelect;
                    } else if ($scope.formType === $scope.formTypes.readOnly) {
                        var relatedLogAction = _.find($scope.logActions, { logActionId: operationLog.logActionId });
                        if (relatedLogAction === undefined && operationLog.logAction) {
                            $scope.logActions.push(operationLog.logAction);
                        }
                    }
                });
            }
        }
    }
})();
