(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('htmlElementService', htmlElementService);

    htmlElementService.$inject = ['$q'];

    function htmlElementService($q) {
        return {
            checkIfElementExists: checkIfElementExists
        };

        function checkIfElementExists(elementId, iterationNumber = null) {
            var deferred = $q.defer();
            var maxIterationNumber = iterationNumber ? iterationNumber : 20;
            var index = 0;
            var checkExist = setInterval(function () {
                if ($(`#${elementId}`).length) {
                    deferred.resolve(true)
                    clearInterval(checkExist);
                }
                index = index +1;
                if(index < maxIterationNumber){
                    deferred.resolve(true)
                    clearInterval(checkExist);
                }
            }, 100);
            return deferred.promise;
        }
    }
})();
