angular.module('NaviaqWebApp').directive('orderInfo', [function () {
    'use strict';

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/report/directives/order-info.html'
    };
}]);

angular.module('NaviaqWebApp').directive('orderInfoFlexbox', [function () {
    'use strict';

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/report/directives/order-info-flexbox.html'
    };
}]);
