angular.module('NaviaqWebApp').directive('orderOperationLogs', [function () {
    'use strict';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            order: '=',
            operationLogs: '='
        },
        templateUrl: 'app/report/directives/order-operation-logs.html',
        link: function (scope, element, attrs) {
        }
    };
}]);
