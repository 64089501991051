(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('contractorService', contractorService);

    contractorService.$inject = ['$q', '$http', 'serviceUrls'];

    function contractorService($q, $http, serviceUrls) {
        const contractorsBaseApiUrl = `${serviceUrls.webApiBaseUrl}/contractor`;

        return {
            getContractors: getContractors,
            getDefaultWarningDays: getDefaultWarningDays,
            getContractorsForConglomerate: getContractorsForConglomerate,
            getContractorsWithinConglomerate,
            getContractorsWithModuleIds,
            getConglomeratesWithModuleIds,
            updateContractorModules,
            updateConglomerateModules,
            getContractorsForLocation,
            getDefaultLogActions
        };        

        function getContractors() {
            const deferred = $q.defer();

            $http
                .get(`${contractorsBaseApiUrl}`)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getDefaultWarningDays(id) {
            const deferred = $q.defer();
            const requestUrl = `${contractorsBaseApiUrl}/defaultWarningDays?id=${id}`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function getContractorsForLocation(locationNumber)
        {
            const deferred = $q.defer();

            $http
                .get(`${contractorsBaseApiUrl}/forLocationByLocationNumber?locationNumber=${locationNumber}`)
                .then((response) => {
                    if (response.data) {
                        deferred.resolve(response.data);
                    }

                    deferred.reject();
                })
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getContractorsForConglomerate(conglomerateName) {
            const deferred = $q.defer(),
                url = `${contractorsBaseApiUrl}/byConglomerateName?conglomerateName=${conglomerateName}`;

            $http
                .get(url)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));
            return deferred.promise;
        }

        function getContractorsWithinConglomerate(contractorId) {
            const deferred = $q.defer();

            $http
                .get(`${contractorsBaseApiUrl}/contractorsWithinConglomerate?contractorId=${contractorId}`)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getContractorsWithModuleIds() {
            const deferred = $q.defer();

            $http
                .get(`${contractorsBaseApiUrl}/contractorsWithModuleIds`)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getConglomeratesWithModuleIds() {
            const deferred = $q.defer();

            $http
                .get(`${contractorsBaseApiUrl}/conglomeratesWithModuleIds`)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function updateContractorModules(contractor) {
            const deferred = $q.defer();

            $http
                .put(`${contractorsBaseApiUrl}/contractorModules`, contractor)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function updateConglomerateModules(conglomerate) {
            const deferred = $q.defer();

            $http
                .put(`${contractorsBaseApiUrl}/conglomerateModules`, conglomerate)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getDefaultLogActions(contractorId) {
            const deferred = $q.defer();

            $http
                .get(`${contractorsBaseApiUrl}/defaultLogActions?contractorId=${contractorId}`)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
