(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('WorkOrdersController', workOrdersController);

    workOrdersController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$state',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'userService',
        'departmentService',
        'workOrderService',
        'workOrderStatuses',
        'workOrderDetailsModalService',
        'noUser',
        'locationService',
        '$stateParams',
        'userConfigurationService'
    ];

    function workOrdersController(
        $q,
        $rootScope,
        $scope,
        $state,
        $kWindow,
        $translate,
        kendoUtility,
        userService,
        departmentService,
        workOrderService,
        workOrderStatuses,
        workOrderDetailsModalService,
        noUser,
        locationService,
        $stateParams,
        userConfigurationService
    ) {
        var canChangeState = false;

        $scope.selectAllDepartments = selectAllDepartments;
        $scope.unSelectAllDepartments = unSelectAllDepartments;
        $scope.selectAllLocations = selectAllLocations;
        $scope.unSelectAllLocations = unSelectAllLocations;
        $scope.selectAllUsers = selectAllUsers;
        $scope.unSelectAllUsers = unSelectAllUsers;
        $scope.departmentSearchType = 0;
        $scope.locationSearchType = 0;
        $scope.userSearchType = 0;
        $scope.displayDepartmentSelector = true;
        $scope.displayLocationSelector = true;
        $scope.woNo = $stateParams.woNo;

        window.onbeforeunload = function () {
            if ($('#work-orders-grid').data('kendoGrid').dataSource.hasChanges()) {
                return 'Ønsker du å fortsette uten å lagre?';
            }
        };

        $scope.$watch('departmentSearchType', function() {
            departmentTypeChanges();
        });

        $scope.$watch('locationSearchType', function() {
            locationTypeChanges();
        });

        $scope.$watch('userSearchType', function() {
            userTypeChanges();
        });

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#work-orders-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return 'Du har endringer som ikke er lagret. Ønsker du å fortsette uten å lagre?';
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        var workOrderLogs = [];
        var closedWorkOrderLogs = [];
        var dateOfLastFetchingClosedWorkOrders = new Date(-8640000000000000);
        $scope.workOrderLogs = [];
        $scope.usersByContractorId = [];
        $scope.departments = [];
        $scope.userAndDepSelectorSource = [];
        $scope.sumByStatuses = {};
        $scope.fromDateObject = null;
        $scope.toDateObject = null;
        $scope.statusFilters = [
            false,
            false,
            false,
            false,
            false,
            false
        ];
        $scope.departmentSelectorSource = [];
        $scope.locationSelectorSource = [];
        $scope.selectedDepartments = [];
        $scope.selectedLocations = [];
        $scope.selectedUsers = [];
        $scope.isReadOnly = false;

        $scope.departmentSelectorOptions = {
            dataSource: {
                batch: true,
                transport: {
                    read: (e) => e.success($scope.departmentSelectorSource),
                },
            },
            dataValueField: 'id',
            dataTextField: 'label',
            autoClose: false,
            height: kendoUtility.calculateRemainingSpace(-200),
            filter: $scope.departmentSearchType == 0 ? 'contains' : 'startswith',
            tagMode: 'single',
            itemTemplate: "<input type='checkbox'/> #:data.label#",
            headerTemplate: '<div class="filter-option-label">'+ $translate.instant('G_SEARCH_OPTION') +'</div><label class="search-option-item"><input type="radio" ng-model="departmentSearchType" value="0">'+ $translate.instant('G_SEARCH_OPTION_CONTAINS') +'</label><label class="search-option-item"><input type="radio" ng-model="departmentSearchType" value="1">'+ $translate.instant('G_SEARCH_OPTION_START_WITH') +'</label><hr><ul class="dropdown-menu-header"><li><a ng-click="selectAllDepartments()" id="selectAllDepartments" class="ng-binding"><span class="glyphicon glyphicon-ok"></span>  '+ $translate.instant('G_SELECT_ALL') +'</a></li><li><a ng-click="unSelectAllDepartments()" id="unSelectAllDepartments" class="ng-binding"><span class="glyphicon glyphicon-remove"></span>  '+ $translate.instant('G_UNSELECT_ALL') +'</a></li></ul>',
            dataBound: function() {
                var items = this.ul.find("li");
                setTimeout(function() {
                    checkInputs(items);
                });
              },
              change: function() {
                var items = this.ul.find("li");
                checkInputs(items);
                $scope.applyFilter();
              }
            };

        $scope.locationSelectorOptions = {
            dataSource: {
                batch: true,
                transport: {
                    read: (e) => e.success($scope.locationSelectorSource),
                },
            },
            dataValueField: 'id',
            dataTextField: 'label',
            autoClose: false,
            height: kendoUtility.calculateRemainingSpace(-200),
            filter: $scope.locationSearchType == 0 ? 'contains' : 'startswith',
            tagMode: 'single',
            itemTemplate: "<input type='checkbox'/> #:data.label#",
            headerTemplate: '<div class="filter-option-label">'+ $translate.instant('G_SEARCH_OPTION') +'</div><label class="search-option-item"><input type="radio" ng-model="locationSearchType" value="0">'+ $translate.instant('G_SEARCH_OPTION_CONTAINS') +'</label><label class="search-option-item"><input type="radio" ng-model="locationSearchType" value="1">'+ $translate.instant('G_SEARCH_OPTION_START_WITH') +'</label><hr><ul class="dropdown-menu-header"><li><a ng-click="selectAllLocations()" id="selectAllLocations" class="ng-binding"><span class="glyphicon glyphicon-ok"></span>  '+ $translate.instant('G_SELECT_ALL') +'</a></li><li><a ng-click="unSelectAllLocations()" id="unSelectAllLocations" class="ng-binding"><span class="glyphicon glyphicon-remove"></span>  '+ $translate.instant('G_UNSELECT_ALL') +'</a></li></ul>',
            dataBound: function() {
                var items = this.ul.find("li");
                setTimeout(function() {
                  checkInputs(items);
                });
              },
              change: function() {
                var items = this.ul.find("li");
                checkInputs(items);
                $scope.applyFilter();
              }
        };

        $scope.userAndDepSelectorOptions = {
            dataSource: {
                batch: true,
                transport: {
                    read: (e) => e.success($scope.userAndDepSelectorSource),
                },
            },
            dataValueField: 'id',
            dataTextField: 'label',
            autoClose: false,
            height: kendoUtility.calculateRemainingSpace(-200),
            filter: $scope.userSearchType == 0  ? 'contains' : 'startswith',
            tagMode: 'single',
            itemTemplate: "<input type='checkbox'/> #:data.label#",
            headerTemplate: '<div class="filter-option-label">'+ $translate.instant('G_SEARCH_OPTION') +'</div><label class="search-option-item"><input type="radio" ng-model="userSearchType" value="0">'+ $translate.instant('G_SEARCH_OPTION_CONTAINS') +'</label><label class="search-option-item"><input type="radio" ng-model="userSearchType" value="1">'+ $translate.instant('G_SEARCH_OPTION_START_WITH') +'</label><hr><ul class="dropdown-menu-header"><li><a ng-click="selectAllUsers()" id="selectAllUsers" class="ng-binding"><span class="glyphicon glyphicon-ok"></span>  '+ $translate.instant('G_SELECT_ALL') +'</a></li><li><a ng-click="unSelectAllUsers()" id="unSelectAllUsers" class="ng-binding"><span class="glyphicon glyphicon-remove"></span>  '+ $translate.instant('G_UNSELECT_ALL') +'</a></li></ul>',
            dataBound: function() {
                var items = this.ul.find("li");
                setTimeout(function() {
                  checkInputs(items);
                });
              },
              change: function() {
                var items = this.ul.find("li");
                checkInputs(items);
                $scope.applyFilter();
              }
        };

        $scope.isCalendar = false;
        $scope.isClosedOrdersToggled = false;
        $scope.$watch('isClosedOrdersToggled', function () {
            if ($scope.isClosedOrdersToggled) {
                getClosedOrders();
            } else {
                filterOutClosedOrders();
            }
        });
        $scope.exportToExcel = exportToExcel;
        $scope.setStatusFilter = setStatusFilter;
        $scope.changeView = changeView;
        $scope.openWorkOrderDetailsModal = openWorkOrderDetailsModal;
        const system = 'NaviaqWebApp';
        const pageName='WorkOrder';
        $scope.saveUserConfiguration = saveUserConfiguration;
        $scope.initController = initController;

        initController();

        function initController() {
            $rootScope.$on('refreshWorkOrders', function () {
                getWorkOrders();
            });

            kendoUtility.calculateAndSetMaxWidth($('#work-orders-content-container'));

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'workOrdersGrid',
                destination: '#work-orders-content-container'
            });

            //read only if the user has the readOnly permission, but nothing else
            $scope.isReadOnly = $scope.hasPermission('WorkOrderReadOnly') &&
                !$scope.hasPermission('WorkOrderAdmin') &&
                !$scope.hasPermission('WorkOrderEquipmentRead') &&
                !$scope.hasPermission('WorkOrderComponentRead');

            var workOrderFilter = {
                contractorId: $rootScope.authData.contractorId,
                statuses: [0, 1, 2, 3],
                assignedUserIds: !$scope.hasPermission('WorkOrderAdmin') &&
                    !$scope.hasPermission('WorkOrderEquipmentRead') &&
                    !$scope.hasPermission('WorkOrderComponentRead') &&
                    !$scope.isReadOnly
                        ? [$rootScope.authData.userId] : []
            }

            $scope.displayDepartmentSelector = $scope.hasPermission('WorkOrderEquipmentRead') || $scope.hasPermission('WorkOrderAdmin') || $scope.isReadOnly;
            $scope.displayLocationSelector = $scope.hasPermission('WorkOrderComponentRead') || $scope.hasPermission('WorkOrderAdmin') || $scope.isReadOnly;

            if(!$scope.hasPermission('WorkOrderAdmin') &&
                !$scope.hasPermission('WorkOrderEquipmentRead') &&
                !$scope.hasPermission('WorkOrderComponentRead') &&
                $scope.hasPermission('WorkOrderView') && $scope.isReadOnly){
                workOrderFilter.assignedUserIds = [$rootScope.authData.userId]
            }

            var requests = {
                getWorkOrderLogsByFilter: workOrderService.getWorkOrderLogsByFilter(workOrderFilter),
                getUsersByContractorIdWebApi: userService.getUsersByContractorIdWebApi($rootScope.authData.contractorId),
                getDepartmentsWebapi: departmentService.getDepartmentsWebapi($rootScope.authData.contractorId, true),
                getLocationsThroughCustomersByContractorId: locationService.getLocationsThroughCustomersByContractorId($rootScope.authData.contractorId),
            }
            $q.all(requests).then(function (data) {
                $scope.departments = data.getDepartmentsWebapi;
                $scope.departmentSelectorSource = $scope.departments.map(function (department) {
                    return {
                        id: department.departmentId,
                        label: department.name
                    };
                });
                $scope.departmentSelectorSource = _.orderBy($scope.departmentSelectorSource, function (department) {
                    return department.label.toLowerCase();
                  }, 'asc');

                $scope.departmentSelector.dataSource.read();

                $scope.locations = data.getLocationsThroughCustomersByContractorId;
                $scope.locationSelectorSource = $scope.locations.map(function (location) {
                    return {
                        id: location.locationNumber,
                        label: location.name
                    };
                });
                $scope.locationSelectorSource = _.orderBy($scope.locationSelectorSource, function (location) {
                    return location.label.toLowerCase();
                  }, 'asc');
                $scope.locationSelector.dataSource.read();

                $scope.usersByContractorId = data.getUsersByContractorIdWebApi
                    .filter(u => u.userName).filter(u => u.roles.some(r => r.permissions.some(p => p.key == 'WorkOrderView')))
                    .concat([noUser])
                    .sort(function (a, b) {
                        if (a.displayName < b.displayName) { return -1; }
                        if (a.displayName > b.displayName) { return 1; }
                        return 0;
                    });
                $scope.userSelectorSource = $scope.usersByContractorId.map(function (user) {
                    return {
                        id: user.userId,
                        label: user.displayName,
                        role: user.role
                    };
                });

                $scope.departmentSelectorSource = $scope.departments.map(function (dep) {
                    return {
                        id: dep.departmentId,
                        label: dep.name,
                        role: null
                    };
                });

                $scope.userAndDepSelectorSource = $scope.userSelectorSource.concat($scope.departmentSelectorSource);
                $scope.userAndDepSelector.dataSource.read();

                $scope.departmentSelectorOptions.scrollableHeight = calculateDropDownHeight($scope.departmentSelectorSource.length);
                $('#department-selector > div > ul').height(calculateDropDownHeight($scope.departmentSelectorSource.length));

                $scope.locationSelectorOptions.scrollableHeight = calculateDropDownHeight($scope.locationSelectorSource.length);
                $('#location-selector > div > ul').height(calculateDropDownHeight($scope.locationSelectorSource.length));

                $scope.userAndDepSelectorOptions.scrollableHeight = calculateDropDownHeight($scope.userAndDepSelectorSource.length);
                workOrderLogs = extendWorkOrderLogs(data.getWorkOrderLogsByFilter);
                $scope.workOrderLogs = workOrderLogs;

                if($scope.woNo){
                    openWorkorderFromUrl();
                }

                getUserConfigurationSettings();

                calculateSumOfStatuses();
                $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
                $rootScope.$broadcast('workOrdersFetched');
            }, function () {
                $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
            });
        }

        function openWorkorderFromUrl(){
            var workorder = $scope.workOrderLogs.find(d => d.woNumber == $scope.woNo)
            if(workorder){
                $state.go('workorders', {'woNo': workorder.woNumber}, {notify: false});
                workOrderDetailsModalService.createWorkOrderDetailsResolve(workorder)
                .then(function (resolve) {
                    var modal = workOrderDetailsModalService.openWorkOrderDetailsModal(resolve);
                    modal.result.then(function (result) {
                        if (result) {
                            getWorkOrders();
                        }
                    }).finally(function (response) {
                        $state.go('workorders', {'woNo': ''}, {notify: false});
                    });
                });
            } else {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 550
                    },
                    templateUrl: 'app/shared/popups/information-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'InformationModalController',
                    resolve: {
                        content: () => {
                            return $translate.instant('WORKORDER_NOT_FOUND_WO_FROM_URL_MSG').toString().replace('[woNo]', $scope.woNo);
                        }
                    }
                });
                windowInstance.result.then(function (result) {
                    if (result) {
                        getWorkOrders();
                    }
                }).finally(function (response) {
                    $state.go('workorders', {'woNo': ''}, {notify: false});
                });
            }
        }

        function exportToExcel() {
            var grid = $("#work-orders-grid").data("kendoGrid");
            grid.saveAsExcel();
        }

        function getWorkOrders() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'workOrdersGrid',
                destination: '#work-orders-content-container'
            });
            var workOrderFilter = {
                contractorId: $rootScope.authData.contractorId,
                statuses: [0, 1, 2, 3],
                assignedUserIds: !$scope.hasPermission('WorkOrderAdmin') &&
                    !$scope.hasPermission('WorkOrderEquipmentRead') &&
                    !$scope.hasPermission('WorkOrderComponentRead') &&
                    !$scope.isReadOnly
                        ? [$rootScope.authData.userId] : []
            };

            if(!$scope.hasPermission('WorkOrderAdmin') &&
                !$scope.hasPermission('WorkOrderEquipmentRead') &&
                !$scope.hasPermission('WorkOrderComponentRead') &&
                $scope.hasPermission('WorkOrderView') && $scope.isReadOnly){
                workOrderFilter.assignedUserIds = [$rootScope.authData.userId]
            }
            workOrderService.getWorkOrderLogsByFilter(workOrderFilter).then(function (data) {
                workOrderLogs = extendWorkOrderLogs(data);
                $scope.applyFilter();
                $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
            }, function () {
                $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
            });
        }

        function changeView(name) {
            $scope.isCalendar = name === 'calendar';
            if ($scope.isCalendar) {
                $rootScope.$broadcast('refreshWorkOrderCalendar');
            }
        }

        function getClosedOrders() {
            if (dateOfLastFetchingClosedWorkOrders < new Date(new Date().setHours(0, 0, 0, 0))) {
                dateOfLastFetchingClosedWorkOrders = new Date();
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'workOrdersGrid',
                    destination: '#work-orders-content-container'
                });

                var workOrderFilter = {
                    contractorId: $rootScope.authData.contractorId,
                    statuses: [4, 5],
                    assignedUserIds: !$scope.hasPermission('WorkOrderAdmin') &&
                        !$scope.hasPermission('WorkOrderEquipmentRead') &&
                        !$scope.hasPermission('WorkOrderComponentRead') &&
                        !$scope.isReadOnly
                            ? [$rootScope.authData.userId] : []
                }

                if(!$scope.hasPermission('WorkOrderAdmin') &&
                    !$scope.hasPermission('WorkOrderEquipmentRead') &&
                    !$scope.hasPermission('WorkOrderComponentRead') &&
                    $scope.hasPermission('WorkOrderView') && $scope.isReadOnly){
                    workOrderFilter.assignedUserIds = [$rootScope.authData.userId]
                }
                workOrderService.getWorkOrderLogsByFilter(workOrderFilter).then(function (data) {
                    closedWorkOrderLogs = data.map(function (wo) {
                        if (!wo.assignedUser || !wo.assignedUser[0]) {
                            wo.assignedUser = [noUser];
                        }
                        return wo;
                    });
                    extendWorkOrderLogs(closedWorkOrderLogs);
                    workOrderLogs = workOrderLogs.concat(closedWorkOrderLogs);
                    $scope.applyFilter();
                    $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
                }, function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
                });
            } else {
                workOrderLogs = workOrderLogs.concat(closedWorkOrderLogs);
                $scope.applyFilter();
            }
        }

        function filterOutClosedOrders() {
            $scope.statusFilters[4] = false;
            $scope.statusFilters[5] = false;
            workOrderLogs = workOrderLogs.filter(function (log) {
                return log.status !== 4 && log.status !== 5;
            });
            $scope.applyFilter();
        }

        function calculateSumOfStatuses() {
            workOrderStatuses.forEach(function (status) {
                $scope.sumByStatuses[status.meaning] = $scope.workOrderLogs.filter(function (log) {
                    return log.status === status.value;
                }).length;
            });
        }

        function setStatusFilter(index) {
            $scope.statusFilters[index] = !$scope.statusFilters[index];
            $scope.applyFilter();
        }

        function getFiltered() {
            return workOrderLogs.filter(function (log) {
                return isBetweenDates(log.dueDate, $scope.fromDateObject, $scope.toDateObject) &&
                    isDepartmentToggled(log.departmentId) &&
                    isLocationToggled(log.locationNumber) &&
                    isUserSelected(log.assignedUserOrDep);
            });
        }

        function getFilteredByStatuses(woLogs) {
            return woLogs.filter(function (log) {
                return isStatusSelected(log.status, !$scope.statusFilters.some(Boolean));
            });
        }

        $scope.applyFilter = function() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'applyFilterBusyIndicator',
                destination: '#main-view',
                overlay: true
            });

            $scope.workOrderLogs = getFiltered();
            calculateSumOfStatuses();
            $scope.workOrderLogs = getFilteredByStatuses($scope.workOrderLogs);
            afterFiltering();

            $rootScope.$broadcast('hideBusyIndicator', 'applyFilterBusyIndicator');
        }

        function isBetweenDates(actual, start, end) {
            if (!actual) {
                return true; //always show those which has no value
            }
            if (start || end) {
                var actualObject = new Date(actual);
                if (start && end) {
                    start.setHours(0, 0, 0, 0);
                    end.setHours(23, 59, 59, 999);
                    return start < actualObject && actualObject < end;
                } else if (!end) {
                    start.setHours(0, 0, 0, 0);
                    return start < actualObject;
                } else {
                    end.setHours(23, 59, 59, 999);
                    return actualObject < end;
                }
            } else {
                return true;
            }
        }

        function isDepartmentToggled(departmentId) {
            return !$scope.selectedDepartments || $scope.selectedDepartments.length <= 0 ?
                true :
                $scope.selectedDepartments.findIndex(function (department) {
                    return departmentId === department;
                }) > -1;
        }

        function isLocationToggled(locationNumber) {
            return !$scope.selectedLocations || $scope.selectedLocations.length <= 0 ?
                true :
                $scope.selectedLocations.findIndex(function (loc) {
                    return loc == locationNumber;
                }) > -1;
        }

        function isStatusSelected(index, allFilterUnset) {
            return allFilterUnset ? true : $scope.statusFilters[index];
        }

        function isUserSelected(assignedUserOrDep) {
            var selectedUserAndDepIds = $scope.selectedUsersOrDeps;
            if (!selectedUserAndDepIds) return true;

            if (assignedUserOrDep) {
                if (assignedUserOrDep.role && assignedUserOrDep.role == 'Assigned')
                {
                    return !$scope.selectedUsersOrDeps || $scope.selectedUsersOrDeps.length <= 0  ? true : selectedUserAndDepIds.indexOf(assignedUserOrDep.id) > -1;
                } else if (assignedUserOrDep.role == null) {
                    return  $scope.selectedUsersOrDeps.length <= 0 ? true : selectedUserAndDepIds.indexOf(assignedUserOrDep.id) > -1;
                }
            }
            return selectedUserAndDepIds.indexOf(noUser.userId) > -1;
        }

        function afterFiltering() {
            kendoUtility.adjustGridHeight('#work-orders-grid', $scope.workOrderLogs.length);
            $rootScope.$broadcast('workOrdersFetched');
        }

        function calculateDropDownHeight(numberOfElements) {
            var result = numberOfElements * 20 + 80;
            return result;
        }

        function arrayUnique(array) {
            var a = array.concat();
            for(var i=0; i<a.length; ++i) {
                for(var j=i+1; j<a.length; ++j) {
                    if(a[i] === a[j])
                        a.splice(j--, 1);
                }
            }

            return a;
        }

        function extendWorkOrderLogs(logs) {
            if(!$scope.hasPermission('WorkOrderAdmin') && ($scope.hasPermission('WorkOrderEquipmentRead') || $scope.hasPermission('WorkOrderComponentRead'))){
                var filteredLogs = [];
                if($scope.hasPermission('WorkOrderEquipmentRead')){
                    var equipmentLogs = logs.filter(x => {
                        return x.hasEquipmentAttached
                    });
                    filteredLogs = filteredLogs.concat(equipmentLogs);
                }
                if($scope.hasPermission('WorkOrderComponentRead')){
                    var componentLogs = logs.filter(x => {
                        return x.hasComponentAttached
                    });
                    filteredLogs = filteredLogs.concat(componentLogs);
                }
                logs = arrayUnique(filteredLogs);
            }

            return logs.map(function (wo) {
                if ((!wo.assignedUser || !wo.assignedUser[0])
                    && wo.workOrderData && wo.workOrderData.assignedDepartmentId) {
                    var selectedDep = $scope.userAndDepSelectorSource.find(d => d.id == wo.workOrderData.assignedDepartmentId);
                    wo.assignedUserOrDep = selectedDep ? selectedDep : $scope.userAndDepSelectorSource.find(ud => ud.id == noUser.userId);
                } else if (wo.assignedUser && wo.assignedUser[0]) {
                    wo.assignedUserOrDep =  wo.assignedUser.length && wo.assignedUser[0].user ? $scope.userAndDepSelectorSource.find(d => d.id ==  wo.assignedUser[0].user.userId) : null;
                } else {
                    wo.assignedUserOrDep =  $scope.userAndDepSelectorSource.find(d => d.id == noUser.userId);
                }

                wo.departmentOrLocationName = wo.department.departmentId === '00000000-0000-0000-0000-000000000000' ? wo.locationName : wo.department.name;
                wo.isExpired = wo.dueDate && new Date(wo.dueDate) < new Date(new Date().setHours(0, 0, 0, 0));
                wo.parentObject = wo.parent;
                wo.woTagIdsList = workOrderService.getWorkOrderTagsObjectOfWorkOrder(wo).workOrderTagId;
                wo.woTagNamesList = workOrderService.getWorkOrderTagsObjectOfWorkOrder(wo).tag;
                return wo;
            }).sort(function (a, b) {
                return b.woNumber - a.woNumber;
            });
        }

        function checkInputs(elements) {
            elements.each(function() {
                  var element = $(this);
              var input = element.children("input");
              input.prop("checked", element.hasClass("k-state-selected"));
            });
        }

        function selectAllDepartments(){
            $scope.selectedDepartments = $scope.departmentSelectorSource.map(x => x.id);
            var items = $('#department-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", true);
              });
              $scope.applyFilter();
            });
        }

        function unSelectAllDepartments(){
            $scope.selectedDepartments = [];
            var items = $('#department-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", false);
              });
              $scope.applyFilter();
            });
        }

        function selectAllLocations(){
            $scope.selectedLocations = $scope.locationSelectorSource.map(x => x.id);
            var items = $('#location-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", true);
              });
              $scope.applyFilter();
            });
        }

        function unSelectAllLocations(){
            $scope.selectedLocations = [];
            var items = $('#location-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", false);
              });
              $scope.applyFilter();
            });
        }

        function selectAllUsers(){
            $scope.selectedUsers = $scope.userAndDepSelectorSource.map(x => x.id);
            var items = $('#user-and-dep-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", true);
              });
              $scope.applyFilter();
            });
        }

        function unSelectAllUsers(){
            $scope.selectedUsers = [];
            var items = $('#user-and-dep-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", false);
              });
              $scope.applyFilter();
            });
        }

        function departmentTypeChanges(){
            var dorpodown = $("#department-selector").data("kendoMultiSelect");
            dorpodown.setOptions({
                filter: $scope.departmentSearchType == 0 ? 'contains' : 'startswith'
            });
        }

        function locationTypeChanges(){
            var dorpodown = $("#location-selector").data("kendoMultiSelect");
            dorpodown.setOptions({
                filter: $scope.locationSearchType == 0 ? 'contains' : 'startswith'
            });
        }

        function userTypeChanges(){
            var dorpodown = $("#user-and-dep-selector").data("kendoMultiSelect");
            dorpodown.setOptions({
                filter: $scope.userSearchType == 0 ? 'contains' : 'startswith'
            });
        }

        function openWorkOrderDetailsModal() {
            workOrderDetailsModalService.createWorkOrderDetailsResolve()
                .then(function (resolve) {
                   if(resolve){
                    $state.go('workorders', {'woNo': resolve.woNumber}, {notify: false});
                   }
                    var modal = workOrderDetailsModalService.openWorkOrderDetailsModal(resolve);
                    modal.result.then(function (result) {
                        if (result) {
                            getWorkOrders();
                        }
                    }).finally(function (response) {
                        $state.go('workorders', {'woNo': ''}, {notify: false});
                    });
                });
        }

        function getUserConfigurationSettings() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'getFiltersBusyIndicator',
                destination: '#main-view',
                overlay: true
            });

            userConfigurationService.getUserConfigurationSettings(system, pageName)
                         .then((data) => {
                            if(data) {
                                const userConfiguration = JSON.parse(data);
                                mapUserConfigurationSettings(userConfiguration);
                                selectCheckbox('department');
                                selectCheckbox('location');
                                selectCheckbox('user-and-dep');
                            }
                            $scope.applyFilter();
                         })
                         .catch(error => console.log(error))
                         .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'getFiltersBusyIndicator'));
        }

        function mapUserConfigurationSettings(userConfiguration) {
            $scope.fromDateObject = userConfiguration.fromDate ? new Date(userConfiguration.fromDate) : null;
            $scope.toDateObject = userConfiguration.toDate ? new Date(userConfiguration.toDate) : null;
            $scope.departmentSearchType = userConfiguration.departmentSearchType,
            $scope.selectedDepartments = userConfiguration.department;
            $scope.selectedLocations = userConfiguration.location;
            $scope.selectedUsersOrDeps = userConfiguration.assignedTo;
            $scope.isClosedOrdersToggled = userConfiguration.showHistory;
            $scope.statusFilters = userConfiguration.statusFilters ? userConfiguration.statusFilters : $scope.statusFilters;
        }

        function selectCheckbox(dropdown) {
            let foundIndexList = [];

            if (($scope.selectedDepartments && $scope.departmentSelectorSource) || ($scope.selectedUsers && $scope.userAndDepSelectorSource) || ($scope.locationSelectorSource && $scope.selectedLocation)) {
                switch (dropdown) {
                    case 'department':
                        $scope.selectedDepartments && $scope.selectedDepartments.length && $scope.selectedDepartments.forEach(source => foundIndexList.push($scope.departmentSelectorSource.findIndex(x => x.id === source)));
                        break;
                    case 'location':
                        $scope.selectedLocation && $scope.selectedLocation.length && $scope.selectedLocation.forEach(source => foundIndexList.push($scope.locationSelectorSource.findIndex(x => x.id === source)));
                        break;
                    case 'user-and-dep':
                        $scope.selectedUsersOrDeps && $scope.selectedUsersOrDeps.length && $scope.selectedUsersOrDeps.forEach(source => foundIndexList.push($scope.userAndDepSelectorSource.findIndex(x => x.id === source)));
                        break;
                }

                let items = $(`#${dropdown}-selector_listbox`).children();

                if (foundIndexList.length) {
                    foundIndexList.forEach(i => {
                        if(i > -1) {
                            let input = $(items[i]).children("input");
                            input.prop("checked", true);
                        }
                    })
                }
            }
        }

        function saveUserConfiguration() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'saveFiltersBusyIndicator',
                destination: '#work-orders-content-container',
                overlay: true
            });

            const userConfiguration = createUserConfiguration();
            userConfiguration.settings = JSON.stringify(userConfiguration.settings);

            userConfigurationService.addUserConfiguration(userConfiguration)
                         .catch(error => console.log(error))
                         .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'saveFiltersBusyIndicator'));
        }

        function createUserConfiguration() {
            return {
                createdOn: new Date(),
                userId: $rootScope.authData.userId,
                pageName: pageName,
                system: system,
                settings: {
                    fromDate: $scope.fromDateObject,
                    toDate: $scope.toDateObject,
                    departmentSearchType: $scope.departmentSearchType,
                    department: $scope.selectedDepartments,
                    locationSearchType: $scope.locationSearchType,
                    selectedLocation: $scope.selectedLocations,
                    assignedTo: $scope.selectedUsersOrDeps,
                    showHistory: $scope.isClosedOrdersToggled,
                    statusFilters: $scope.statusFilters
                }
            };
        }
    }
})();
