(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('mapObjectsLayerService', MapObjectsLayerService);

    MapObjectsLayerService.$inject = ['$q', '$timeout', '$rootScope', 'mapObjectType', 'mapUtility', 'esriLoader', 'authService', 'locationService'];

    function MapObjectsLayerService($q, $timeout, $rootScope, mapObjectType, mapUtility, esriLoader, authService, locationService) {
        var readyDeferred = null,
            defaultIconSize = 16,
            selectedIconSize = 28,
            lineDistanceTreshold = 8;

        var mapObjectsLayerService = {
            createLayerOnAdd: false,
            mapObjectsLayer: null,
            mapBargeObjectsLayer: null,
            mapCageObjectsLayer: null,
            layerCreationInProgress: false,
            layers: [],
            selectedLines: [],
            selectedGraphic: null,
            visibleLineGraphics: [],

            ready: function () {
                if (readyDeferred === null) {
                    readyDeferred = $q.defer();
                }

                return readyDeferred.promise;
            }
        };

        esriLoader.require([
            'esri/Color',
            'esri/Graphic',
            'esri/geometry/Point',
            'esri/geometry/ScreenPoint',
            'esri/geometry/Polyline',
            'esri/geometry/Polygon',
            'esri/geometry/Circle',
            'esri/geometry/SpatialReference',
            'esri/symbols/SimpleLineSymbol',
            'esri/symbols/SimpleFillSymbol',
            'esri/symbols/SimpleMarkerSymbol',
            'esri/symbols/PictureMarkerSymbol',
            'esri/symbols/TextSymbol',
            'esri/layers/GraphicsLayer'
        ], function (Color, Graphic, Point, ScreenPoint, Polyline, Polygon, Circle, SpatialReference, SimpleLineSymbol,
            SimpleFillSymbol, SimpleMarkerSymbol, PictureMarkerSymbol, TextSymbol, GraphicsLayer) {
                var currentLocation = null,
                    zoomTreshold = 13,
                    layers = mapObjectsLayerService.layers,
                    bargeFillColor = [169, 169, 169, 1],
                    cageFillColor = [149, 157, 158, 1],
                    lineFillColor = [0, 0, 0],
                    netFillColor = [128, 128, 128, 0.8125],
                    ringOutlineColor = [0, 0, 0, 1.0],
                    lineSelectedColor = [192, 7, 7],
                    polygonSelectedColor = [245, 157, 158, 1];

                if (readyDeferred === null) {
                    readyDeferred = $q.defer();
                }

                function createLocationGraphics(location, layerIds) {
                    var graphics = [],
                        texts = [];

                    //Add fix objects
                    var cageGraphicsAndTexts = createCageGraphics(location, layerIds);
                    if (location.MapLayers) {
                        for (var i = 0; i < location.MapLayers.length; i++) {
                            if (layerIds.indexOf(location.MapLayers[i].MapLayerId) !== -1) {
                                var mapObjects = createMapLayerGraphics(location.MapLayers[i]);

                                for (var j = 0; j < mapObjects.graphicObjects.length; ++j) {
                                    graphics.push(mapObjects.graphicObjects[j]);
                                }

                                for (var k = 0; k < mapObjects.textObjects.length; ++k) {
                                    texts.push(mapObjects.textObjects[k]);
                                }

                                for (var l = 0; l < cageGraphicsAndTexts.texts.length; ++l) {
                                    texts.push(cageGraphicsAndTexts.texts[l]);
                                }
                            }
                        }

                    }
                    return {
                        graphics: graphics,
                        bargeGraphics: createBargeGraphics(location, layerIds),
                        cageGraphics: cageGraphicsAndTexts.graphics,
                        netGraphics: createNetGraphics(location),
                        ringGraphics: createRingGraphics(location),
                        texts: texts
                    };
                };

                function createMapLayerGraphics(layer) {
                    var layerObjectGraphics = [];
                    var layerTextGraphics = [];
                    var lineObjects = [];
                    var pointObjects = [];

                    for (var q = 0; q < layer.MapLayerObjects.length; q++) {
                        switch (layer.MapLayerObjects[q].ObjectType) {
                            case 1:
                                lineObjects.push(layer.MapLayerObjects[q]);
                                let mapLayerObjectClone = Object.assign({}, layer.MapLayerObjects[q]);

                                if (mapLayerObjectClone) {
                                    mapLayerObjectClone.MapLayerObjectPoints = mapLayerObjectClone.MapLayerObjectPoints && mapLayerObjectClone.MapLayerObjectPoints.length ?
                                        mapLayerObjectClone.MapLayerObjectPoints.filter(x => x.Symbol && x.IsSymbolDisplayed) :
                                        [];

                                    if (mapLayerObjectClone.MapLayerObjectPoints && mapLayerObjectClone.MapLayerObjectPoints.length) {
                                        pointObjects.push(mapLayerObjectClone);
                                    }
                                }
                                break;
                            case 3:
                                pointObjects.push(layer.MapLayerObjects[q]);
                                break;
                        }
                    }

                    for (var m = 0; m < lineObjects.length; m++) {
                        var lineObjs = createMapLayerObjectGraphic(lineObjects[m]);
                        for (var n = 0; n < lineObjs.length; n++) {
                            layerObjectGraphics.push(lineObjs[n]);
                        }
                    }

                    for (var o = 0; o < pointObjects.length; o++) {
                        var pointObjs = createPointGraphicOfObject(pointObjects[o]);

                        if(pointObjs){
                            if(pointObjs.graphics && pointObjs.graphics.length){
                                for (var p = 0; p < pointObjs.graphics.length; p++) {
                                    layerObjectGraphics.push(pointObjs.graphics[p]);
                                }
                            }

                            if(pointObjs.texts && pointObjs.texts.length){
                                for (var s = 0; s < pointObjs.texts.length; s++) {
                                    layerTextGraphics.push(pointObjs.texts[s]);
                                }
                            }
                        }
                    }

                    return {
                        graphicObjects: layerObjectGraphics,
                        textObjects: layerTextGraphics
                    };
                }

                function createMapLayerObjectGraphic(mapLayerObject) {
                    switch (mapLayerObject.ObjectType) {
                        case 3:
                            return createPointGraphicOfObject(mapLayerObject);
                        case 1:
                            return createLineGraphicOfObject(mapLayerObject);
                        case 0:
                            return createPolygonGraphicOfObject(mapLayerObject);
                        case 2:
                            return createPolyLineGraphic(mapLayerObject);
                        default:
                            return createPointGraphicOfObject(mapLayerObject);
                    }
                }

                function createPointGraphicOfObject(maplayerObject) {
                    var pointGraphics = [];
                    var pointTexts = [];

                    if (maplayerObject.MapLayerObjectPoints && (!maplayerObject.Lines.length || maplayerObject.Lines[0].UsageState === 2)) {
                        for (var i = 0; i < maplayerObject.MapLayerObjectPoints.length; i++) {
                            if (maplayerObject.MapLayerObjectPoints.length &&
                                    maplayerObject.MapLayerObjectPoints[i].Symbol &&
                                    maplayerObject.MapLayerObjectPoints[i].Symbol.Description &&
                                    maplayerObject.MapLayerObjectPoints[i].Symbol.Description.toLowerCase() !== 'brunsirkel') {
                                var symbolUri = mapUtility.getImageBasedOnDescription((maplayerObject.MapLayerObjectPoints[i].Symbol.Description));
                                var pictureMarkerSymbol = new PictureMarkerSymbol({
                                    url: symbolUri,
                                    width: defaultIconSize,
                                    height: defaultIconSize
                                });

                                var pointGeometry = new Point(
                                    maplayerObject.MapLayerObjectPoints[i].X,
                                    maplayerObject.MapLayerObjectPoints[i].Y,
                                    new SpatialReference({ wkid: maplayerObject.MapLayerObjectPoints[i].SpatialReference })
                                );

                                var attributes = {
                                    mapLayerObjectPointId: maplayerObject.MapLayerObjectPoints[i].MapLayerObjectPointId,
                                    mapLayerObjectId: maplayerObject.MapLayerObjectPoints[i].MapLayerObjectId,
                                    description: maplayerObject.MapLayerObjectPoints[i].Description,
                                    Symbol: maplayerObject.MapLayerObjectPoints[i].Symbol,
                                    SymbolDescription: maplayerObject.MapLayerObjectPoints[i].Symbol.Description,
                                    objectType: 'Point',
                                    id: maplayerObject.Lines && maplayerObject.Lines.length ? maplayerObject.Lines[0].LineId : null,
                                    selected: false
                                };
                                attributes.relatedObject = maplayerObject;

                                var pointGraphic = new Graphic(pointGeometry, pictureMarkerSymbol, attributes);

                                pointGraphics.push(pointGraphic);

                                if (maplayerObject.MapLayerObjectPoints[i].Description && !(maplayerObject.MapLayerObjectPoints[i].MapLayerObjectPointSymbolId === 12 || maplayerObject.MapLayerObjectPoints[i].MapLayerObjectPointSymbolId === 14)) {
                                    var textGeometry = pointGeometry;
                                    var textSymbol = new TextSymbol(maplayerObject.MapLayerObjectPoints[i].Description);
                                    textSymbol.font = '12pt';
                                    textSymbol.xoffset = 20;
                                    textSymbol.yoffset = -4;

                                    var textGraphic = new Graphic(textGeometry, textSymbol, attributes);
                                    pointTexts.push(textGraphic);
                                }
                            }
                        }
                    }


                    return {
                        graphics: pointGraphics,
                        texts: pointTexts
                    };
                }

                function createPolygonGraphicOfObject(maplayerObject) {
                    var polygonGraphics = [];
                    var pointList = [];

                    for (var i = 0; i < maplayerObject.MapLayerObjectPoints.length; i++) {
                        pointList.push(maplayerObject.MapLayerObjectPoints[i]);
                    }

                    pointList.sort(function (a, b) { return parseInt(a.RenderSequence) - parseInt(b.RenderSequence); });

                    var poly = new Polygon(new SpatialReference({ wkid: 32233 }));

                    var pathPoints = [];

                    for (var j = 0; j < pointList.length; j++) {
                        var pointGeometry = new Point(pointList[j].X, pointList[j].Y, new SpatialReference({ wkid: 32233 }));
                        pathPoints.push(pointGeometry);
                    }

                    poly.addRing(pathPoints);

                    var borderColor = Color.fromHex(maplayerObject.MapLayerObjectRendering.BorderColor);
                    borderColor.a = 0.6;

                    var fillColor = Color.fromHex(maplayerObject.MapLayerObjectRendering.FillColor);
                    fillColor.a = 0.6;

                    var fillSymbol = new SimpleFillSymbol(
                        SimpleFillSymbol.STYLE_SOLID,
                        new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, borderColor, 2), fillColor
                    );

                    var attributes = [];

                    for (var k = 0; k < pointList.length; k++) {
                        var atrributeDesc = "MapLayerObjectPointDescription_".concat(k.toString);
                        attributes.push({ atrributeDesc: pointList[k].Description });
                    }

                    attributes.push({ "MapLayerObjectId": maplayerObject.MapLayerObjectId });
                    attributes.push({ "TypeDescription": maplayerObject.ObjectType });
                    attributes.push({ "MapLayerId": maplayerObject.MapLayerId });
                    attributes.push({ "ObjectType": maplayerObject.ObjectType });

                    var polygonGraphic = new Graphic(poly, fillSymbol, attributes);

                    polygonGraphics.push(polygonGraphic);
                    return polygonGraphics;
                }

                function createLineGraphicOfObject(maplayerObject) {
                    return createPolyLineGraphic(maplayerObject, 'Line');
                }

                function createPolyLineGraphic(maplayerObject, objectType) {
                    var polylineGraphics = [];
                    var pointList = [];

                    for (var i = 0; i < maplayerObject.MapLayerObjectPoints.length; i++) {
                        pointList.push(maplayerObject.MapLayerObjectPoints[i]);
                    }
                    pointList.sort(function (a, b) { return parseInt(a.RenderSequence) - parseInt(b.RenderSequence); });

                    var polyLine = new Polyline(new SpatialReference({ wkid: 32233 }));

                    var pathPoints = [];
                    for (var j = 0; j < pointList.length; j++) {
                        var pointGeometry = new Point(pointList[j].X, pointList[j].Y, new SpatialReference({ wkid: 32233 }));
                        pathPoints.push(pointGeometry);
                    }

                    polyLine.addPath(pathPoints);

                    var attributes = {};
                    for (var k = 0; k < pointList.length; k++) {
                        var atrributeDesc = "MapLayerObjectPointDescription_".concat(k.toString);
                        attributes.atrributeDesc = pointList[k].Description;
                    }

                    attributes.mapLayerObjectId = maplayerObject.MapLayerObjectId;
                    attributes.typeDescription = maplayerObject.ObjectType;
                    attributes.mapLayerId = maplayerObject.MapLayerId;
                    attributes.relatedObject = maplayerObject;

                    if (!objectType) {
                        objectType = 'Polyline';
                    }

                    attributes.objectType = objectType;

                    var simpleLineSymbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color(lineFillColor), 1);
                    simpleLineSymbol.width = 1;

                    var polyLineGraphic = new Graphic(polyLine, simpleLineSymbol, attributes);
                    polylineGraphics.push(polyLineGraphic);

                    return polylineGraphics;
                }

                function createCircleGraphic(point, radius, fillSymbol) {
                    var circle,
                        ring,
                        pts,
                        angle;

                    circle = new Polygon(new SpatialReference({ wkid: 32233 }));
                    ring = []; // point that make up the circle
                    pts = 720; // number of points on the circle
                    angle = 360 / pts; // used to compute points on the circle

                    for (var i = 1; i <= pts; i++) {
                        // convert angle to raidans
                        var radians = i * angle * Math.PI / 180;
                        // add point to the circle
                        ring.push([point.x + radius * Math.cos(radians), point.y + radius * Math.sin(radians)]);
                    }
                    ring.push(ring[0]); // start point needs to == end point

                    circle.addRing(ring);
                    return new Graphic(circle, fillSymbol);
                }

                function createBargeGraphics(location, layerIds) {
                    var bargeGraphics = [];

                    _.forEach(location.Barges, function (barge) {
                        if (layerIds.indexOf(barge.MapLayerId) !== -1) {
                            var mapLayer = _.find(location.MapLayers, { MapLayerId: barge.MapLayerId });

                            if (mapLayer) {
                                var bargeGraphic = createBargeGraphic(mapLayer, barge, bargeFillColor);
                                if (bargeGraphic) {
                                    bargeGraphics.push(bargeGraphic);
                                }
                            }
                        }
                    });

                    return bargeGraphics;
                }

                function createBargeGraphic(mapLayer, barge, fillColor) {
                    var bargePolygonGraphic = null;

                    var relatedObjects = _.filter(mapLayer.MapLayerObjects, function (mapLayerObject) {
                        return _.find(barge.BargeObjectLink, { MapLayerObjectId: mapLayerObject.MapLayerObjectId });
                    });

                    if (relatedObjects && relatedObjects.length === 4) {
                        var pathPoints = [];
                        var bargePolygon = new Polygon(new SpatialReference({ wkid: 32233 }));

                        for (var j = 0; j < relatedObjects.length; j++) {
                            if (relatedObjects[j].MapLayerObjectPoints && relatedObjects[j].MapLayerObjectPoints.length > 0) {
                                var pointGeometry = new Point(relatedObjects[j].MapLayerObjectPoints[0].X, relatedObjects[j].MapLayerObjectPoints[0].Y, new SpatialReference({ wkid: 32233 }));
                                pathPoints.push(pointGeometry);
                            }
                        }

                        if (pathPoints.length === 4) {
                            pathPoints = orderPolygonPoints(pathPoints);
                            bargePolygon.addRing(pathPoints);

                            var fillSymbol = new SimpleFillSymbol({
                                color: fillColor,
                                style: 'solid',
                                outline: {
                                    color: [0, 0, 0, 0.0],
                                    width: 0
                                }
                            });

                            var attributes = {
                                id: barge.BargeId,
                                bargeId: barge.BargeId,
                                mapLayerId: barge.MapLayerId,
                                mapLayer: mapLayer,
                                relatedObject: barge,
                                objectType: mapObjectType.Barge
                            };

                            bargePolygonGraphic = new Graphic(bargePolygon, fillSymbol, attributes);
                        }
                    }

                    return bargePolygonGraphic;
                }

            function createCageGraphics(location, layerIds) {
                var cageGraphics = [],
                    cageTextGraphics = [];

                _.forEach(location.Cages, function (cage) {
                    if (layerIds.indexOf(cage.MapLayerId) !== -1) {
                        var mapLayer = _.find(location.MapLayers, { MapLayerId: cage.MapLayerId });

                        if (mapLayer) {
                            var cageGraphicAndText = createCageGraphic(mapLayer, cage, cageFillColor);

                            if (cageGraphicAndText.graphic && cageGraphicAndText.text) {
                                cageGraphics.push(cageGraphicAndText.graphic);
                                cageTextGraphics.push(cageGraphicAndText.text);
                            }
                        }
                    }
                });

                return {
                    graphics: cageGraphics,
                    texts: cageTextGraphics
                };
            }

                function createCageGraphic(mapLayer, cage, fillColor) {
                    var cagePolygonGraphic = null,
                        cageTextGraphic = null;

                    var relatedObjects = _.filter(mapLayer.MapLayerObjects, function (mapLayerObject) {
                        return _.find(cage.CageObjectLink, { MapLayerObjectId: mapLayerObject.MapLayerObjectId });
                    });

                    if (relatedObjects && relatedObjects.length === 4) {
                        var pathPoints = [];
                        var cagePolygon = new Polygon(new SpatialReference({ wkid: 32233 }));

                        for (var j = 0; j < relatedObjects.length; j++) {
                            if (relatedObjects[j].MapLayerObjectPoints && relatedObjects[j].MapLayerObjectPoints.length > 0) {
                                var pointGeometry = new Point(relatedObjects[j].MapLayerObjectPoints[0].X, relatedObjects[j].MapLayerObjectPoints[0].Y, new SpatialReference({ wkid: 32233 }));
                                pathPoints.push(pointGeometry);
                            }
                        }

                        if (pathPoints.length === 4) {
                            pathPoints = orderPolygonPoints(pathPoints);
                            cagePolygon.addRing(pathPoints);

                            var fillSymbol = new SimpleFillSymbol({
                                color: fillColor,
                                style: 'solid',
                                outline: {
                                    color: [0, 0, 0, 0.0],
                                    width: 0
                                }
                            });

                            var attributes = {
                                id: cage.CageId,
                                cageId: cage.CageId,
                                mapLayerId: cage.MapLayerId,
                                mapLayer: mapLayer,
                                relatedObject: cage,
                                objectType: mapObjectType.Cage
                            };

                            cagePolygonGraphic = new Graphic(cagePolygon, fillSymbol, attributes);

                            var centerX = _.sumBy(pathPoints, 'x') / pathPoints.length,
                                centerY = _.sumBy(pathPoints, 'y') / pathPoints.length;
                            var textGeometry = new Point(centerX, centerY, new SpatialReference({ wkid: 32233 }));

                            var textSymbol = new TextSymbol(cage.CageNumber ? cage.CageNumber : '');
                            textSymbol.font = '22pt';
                            textSymbol.color = [255, 255, 255, 0.75];
                            textSymbol.yoffset = -6;

                            cageTextGraphic = new Graphic(textGeometry, textSymbol);
                        }
                    }

                    return {
                        graphic: cagePolygonGraphic,
                        text: cageTextGraphic
                    };
                }

                function createRingGraphics(location) {
                    var ringGraphics = [];
                    _.forEach(location.Rings, function (ring) {
                        ringGraphics.push(createRingGraphic(ring, ringOutlineColor));
                    });

                    return ringGraphics;
                }

                function createRingGraphic(ring, outlineColor) {
                    var point = new Point({
                        x: ring.X,
                        y: ring.Y,
                        spatialReference: new SpatialReference({ wkid: 32233 })
                    });

                    var fillSymbol = new SimpleFillSymbol({
                        color: [0, 0, 0, 0],
                        style: 'solid',
                        outline: {
                            color: outlineColor,
                            width: 2
                        }
                    });

                    var ringGraphic = createCircleGraphic(point, ring.Radius, fillSymbol);
                    ringGraphic.attributes = {
                        ringId: ring.RingId,
                        relatedObject: ring,
                        objectType: mapObjectType.Ring
                    };

                    return ringGraphic;
                }

                function createNetGraphics(location) {
                    var netGraphics = [];

                    //Get circle net graphics
                    var circleNets = _.filter(location.Nets, { NetType: 0 });
                    _.forEach(circleNets, function (net) {
                        netGraphics.push(createNetGraphic(net, netFillColor));
                    });

                    return netGraphics;
                }

                function createNetGraphic(net, fillColor) {
                    var point = new Point({
                        x: net.X,
                        y: net.Y,
                        spatialReference: new SpatialReference({ wkid: 32233 })
                    });

                    var fillSymbol = new SimpleFillSymbol({
                        color: fillColor,
                        style: 'solid',
                        outline: {
                            color: [0, 0, 0, 0],
                            width: 0
                        }
                    });

                    var netGraphic = createCircleGraphic(point, net.Circumferens ? (net.Circumferens / 6.4) : 0.0, fillSymbol);
                    netGraphic.attributes = {
                        id: net.Id,
                        netId: net.NetId,
                        relatedObject: net,
                        objectType: mapObjectType.Net
                    };

                    return netGraphic;
                }

                function getImageBasedOnDescription(symbolDescription) {
                    var lowercaseDescription = symbolDescription.toLowerCase();
                    switch (lowercaseDescription) {
                        case 'anker':
                            return '../img/map/Anker.png';
                        case 'blåkryss':
                            return '../img/map/Blaakryss.png';
                        case 'blåramme':
                            return '../img/map/Blaaramme.png';
                        case 'brunsirkel':
                            return '../img/map/Brunsirkel.png';
                        case 'enfisk':
                            return '../img/map/Enfisk.png';
                        case 'fiskestim':
                            return '../img/map/Fiskestim.png';
                        case 'flyvrak':
                            return '../img/map/Flyvrak.png';
                        case 'garnstart':
                            return '../img/map/Garnstart.png';
                        case 'garnstopp':
                            return '../img/map/Garnstopp.png';
                        case 'grønnkryss':
                            return '../img/map/Gronnkryss.png';
                        case 'grønnpyramide':
                            return '../img/map/Gronnpyramide.png';
                        case 'grønnramme':
                            return '../img/map/Gronnramme.png';
                        case 'gulfare':
                            return '../img/map/Gulfare.png';
                        case 'gulkryss':
                            return '../img/map/Gulkryss.png';
                        case 'gulpyramide':
                            return '../img/map/Gulpyramide.png';
                        case 'gulramme':
                            return '../img/map/Gulramme.png';
                        case 'kryss':
                            return '../img/map/Kryss.png';
                        case 'notmerke':
                            return '../img/map/Notmerke.png';
                        case 'punkt':
                            return '../img/map/Punkt.png';
                        case 'pyramide':
                            return '../img/map/Pyramide.png';
                        case 'rødpyramide':
                            return '../img/map/Rodpyramide.png';
                        case 'rødramme':
                            return '../img/map/Rodramme.png';
                        case 'spørsmål':
                            return '../img/map/Sporsmaal.png';
                        case 'vrak1':
                            return '../img/map/Vrak1.png';
                        case 'vrak2':
                            return '../img/map/Vrak2.png';
                        default:
                            return '../img/map/poi_black.png';
                    }
                }

                function getDrawingOrder(relatedGraphic) {
                    if (relatedGraphic && relatedGraphic.attributes) {
                        var objectType = relatedGraphic.attributes.objectType;

                        switch (objectType) {
                            case mapObjectType.Net:
                                return 1;
                            case mapObjectType.Ring:
                                return 2;
                        }
                    }

                    return 5;
                }

                function deselectGraphic(resultGraphic) {
                    if (mapObjectsLayerService.selectedLines.length > 0) {
                        if (currentLocation && resultGraphic && resultGraphic.attributes && resultGraphic.attributes.objectType === 'Line') {
                            var selectedLineGraphic = _.find(mapObjectsLayerService.selectedLines, function (line) {
                                return line.attributes.mapLayerObjectId === resultGraphic.attributes.mapLayerObjectId;
                            });

                            if (selectedLineGraphic) {
                                var unselectedLineGraphic = createPolyLineGraphic(selectedLineGraphic.attributes.relatedObject, 'Line');

                                selectedLineGraphic.layer.remove(selectedLineGraphic);
                                mapObjectsLayerService.mapObjectsLayer.addMany(unselectedLineGraphic);
                            }
                        }
                    }

                    if (mapObjectsLayerService.selectedGraphic && mapObjectsLayerService.selectedGraphic.attributes
                        && resultGraphic.attributes.objectType !== 'Line') {
                        var selectedGraphicAttributes = mapObjectsLayerService.selectedGraphic.attributes;

                        if (mapObjectsLayerService.selectedGraphic.attributes.objectType === 'Point') {
                            var pointGeometry = new Point(
                                mapObjectsLayerService.selectedGraphic.geometry.x,
                                mapObjectsLayerService.selectedGraphic.geometry.y,
                                new SpatialReference({ wkid: 32233 })
                            );

                            var symbolUri = getImageBasedOnDescription(mapObjectsLayerService.selectedGraphic.attributes.symbol.Description);
                            var pictureMarkerSymbol = new PictureMarkerSymbol(symbolUri, defaultIconSize, defaultIconSize);

                            var unselectedGraphic = new Graphic(pointGeometry, pictureMarkerSymbol, resultGraphic.attributes);

                            mapObjectsLayerService.selectedGraphic.layer.remove(mapObjectsLayerService.selectedGraphic);
                            mapObjectsLayerService.mapObjectsLayer.add(unselectedGraphic);
                        } else if (mapObjectsLayerService.selectedGraphic.attributes.objectType === mapObjectType.Net) {
                            var unselectedNetGraphic = createNetGraphic(
                                mapObjectsLayerService.selectedGraphic.attributes.relatedObject,
                                netFillColor);

                            mapObjectsLayerService.selectedGraphic.layer.remove(mapObjectsLayerService.selectedGraphic);
                            mapObjectsLayerService.mapObjectsLayer.add(unselectedNetGraphic);
                        } else if (mapObjectsLayerService.selectedGraphic.attributes.objectType === mapObjectType.Barge) {
                            if (selectedGraphicAttributes.mapLayer && selectedGraphicAttributes.relatedObject) {
                                var unselectedBargeGraphic = createBargeGraphic(selectedGraphicAttributes.mapLayer, selectedGraphicAttributes.relatedObject, bargeFillColor);

                                mapObjectsLayerService.selectedGraphic.layer.remove(mapObjectsLayerService.selectedGraphic);
                                mapObjectsLayerService.mapBargeObjectsLayer.add(unselectedBargeGraphic);
                            }
                        } else if (mapObjectsLayerService.selectedGraphic.attributes.objectType === mapObjectType.Cage) {
                            if (selectedGraphicAttributes.mapLayer && selectedGraphicAttributes.relatedObject) {
                                var unselectedCageGraphic = createCageGraphic(selectedGraphicAttributes.mapLayer, selectedGraphicAttributes.relatedObject, cageFillColor).graphic;

                                mapObjectsLayerService.selectedGraphic.layer.remove(mapObjectsLayerService.selectedGraphic);
                                mapObjectsLayerService.mapCageObjectsLayer.add(unselectedCageGraphic);
                            }
                        } else if (mapObjectsLayerService.selectedGraphic.attributes.objectType === mapObjectType.Ring && selectedGraphicAttributes.relatedObject) {
                            var unselectedRingGraphic = createRingGraphic(selectedGraphicAttributes.relatedObject, ringOutlineColor);

                            mapObjectsLayerService.selectedGraphic.layer.remove(mapObjectsLayerService.selectedGraphic);
                            mapObjectsLayerService.mapRingObjectsLayer.add(unselectedRingGraphic);
                        }
                    }
                }

                function selectGraphic(resultGraphic) {
                    var pictureMarkerSymbol = null,
                        pointGeometry = null,
                        symbolUri = null;

                    if (resultGraphic && resultGraphic.attributes) {
                        if (resultGraphic.attributes.objectType === 'Point') {
                            if (!mapObjectsLayerService.selectedGraphic || resultGraphic.attributes.mapLayerObjectPointId !==
                                mapObjectsLayerService.selectedGraphic.attributes.mapLayerObjectPointId) {

                                pointGeometry = new Point(
                                    resultGraphic.geometry.x,
                                    resultGraphic.geometry.y,
                                    new SpatialReference({ wkid: 32233 })
                                );

                                symbolUri = getImageBasedOnDescription(resultGraphic.attributes.symbol.Description);
                                pictureMarkerSymbol = new PictureMarkerSymbol(symbolUri, selectedIconSize, selectedIconSize);

                                var selectedGraphic = new Graphic(pointGeometry, pictureMarkerSymbol, resultGraphic.attributes);

                                resultGraphic.layer.remove(resultGraphic);
                                resultGraphic.layer.add(selectedGraphic);

                                mapObjectsLayerService.selectedGraphic = selectedGraphic;

                                $rootScope.$broadcast('mapObjectSelected', selectedGraphic);
                            } else {
                                mapObjectsLayerService.selectedGraphic = null;
                            }
                        } else if (resultGraphic.attributes.objectType === 'Line') {
                            var selectedLineGraphic = _.find(mapObjectsLayerService.selectedLines, function (line) {
                                return line.attributes.mapLayerObjectId === resultGraphic.attributes.mapLayerObjectId;
                            });

                            if (selectedLineGraphic) {
                                var selectedGraphicIndex = mapObjectsLayerService.selectedLines.indexOf(selectedLineGraphic);
                                if (selectedGraphicIndex !== -1) {
                                    mapObjectsLayerService.selectedLines.splice(selectedGraphicIndex, 1);
                                }
                            } else {
                                var line = new Polyline(new SpatialReference({ wkid: 32233 }));
                                line.addPath(resultGraphic.geometry.paths[0]);

                                var simpleLineSymbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color(lineSelectedColor), 1);
                                simpleLineSymbol.width = 1;

                                var lineGraphic = new Graphic(line, simpleLineSymbol, resultGraphic.attributes);

                                resultGraphic.layer.remove(resultGraphic);
                                resultGraphic.layer.add(lineGraphic);

                                mapObjectsLayerService.selectedLines.push(lineGraphic);

                                $rootScope.$broadcast('mapObjectSelected', lineGraphic);
                            }
                        } else if (resultGraphic.attributes.objectType === mapObjectType.Net) {
                            if (!mapObjectsLayerService.selectedGraphic || resultGraphic.attributes.netId !==
                                mapObjectsLayerService.selectedGraphic.attributes.netId) {

                                var selectedNetGraphic = createNetGraphic(resultGraphic.attributes.relatedObject, polygonSelectedColor);

                                resultGraphic.layer.remove(resultGraphic);
                                resultGraphic.layer.add(selectedNetGraphic);

                                mapObjectsLayerService.selectedGraphic = selectedNetGraphic;

                                $rootScope.$broadcast('mapObjectSelected', selectedNetGraphic);
                            } else {
                                mapObjectsLayerService.selectedGraphic = null;
                            }
                        } else if (resultGraphic.attributes.objectType === mapObjectType.Barge) {
                            var selectedBargeGraphic = selectGraphicObject(resultGraphic, mapObjectsLayerService.mapBargeObjectsLayer, createBargeGraphic);

                            $rootScope.$broadcast('mapObjectSelected', selectedBargeGraphic);
                        } else if (resultGraphic.attributes.objectType === mapObjectType.Cage) {
                            var selectedCageGraphic = selectGraphicObject(resultGraphic, mapObjectsLayerService.mapCageObjectsLayer, createCageGraphic);

                            $rootScope.$broadcast('mapObjectSelected', selectedCageGraphic);
                        } else if (resultGraphic.attributes.objectType === mapObjectType.Ring) {
                            if (!mapObjectsLayerService.selectedGraphic || resultGraphic.attributes.ringId !==
                                mapObjectsLayerService.selectedGraphic.attributes.ringId) {

                                var selectedRingGraphic = createRingGraphic(resultGraphic.attributes.relatedObject, lineSelectedColor);

                                resultGraphic.layer.remove(resultGraphic);
                                resultGraphic.layer.add(selectedRingGraphic);

                                mapObjectsLayerService.selectedGraphic = selectedRingGraphic;

                                $rootScope.$broadcast('mapObjectSelected', selectedRingGraphic);
                            } else {
                                mapObjectsLayerService.selectedGraphic = null;
                            }
                        } else {
                            mapObjectsLayerService.selectedGraphic = null;
                        }
                    }
                }

                function selectGraphicObject(resultGraphic, graphicLayer, createRelatedGraphic) {
                    if (!mapObjectsLayerService.selectedGraphic || resultGraphic.attributes.id !==
                        mapObjectsLayerService.selectedGraphic.attributes.id) {

                        if (resultGraphic.attributes.relatedObject && resultGraphic.attributes.mapLayer) {
                            var selectedGraphic = createRelatedGraphic(
                                resultGraphic.attributes.mapLayer,
                                resultGraphic.attributes.relatedObject,
                                polygonSelectedColor);

                            if (resultGraphic.attributes.objectType === mapObjectType.Cage) {
                                selectedGraphic = selectedGraphic.graphic;
                            }

                            mapObjectsLayerService.selectedGraphic = selectedGraphic;

                            graphicLayer.add(selectedGraphic);
                            resultGraphic.layer.remove(resultGraphic);

                            return selectedGraphic;
                        }
                    } else {
                        mapObjectsLayerService.selectedGraphic = null;
                    }
                }

                function getNearestLineAndDistance(screenPoint) {
                    var nearestDistance = 1000000,
                        nearestLine = null;

                    var mapObjectLayer = _.find(mapObjectsLayerService.layers, { name: 'MapObjectGraphicsLayer' });
                    if (mapObjectLayer && mapObjectLayer.layer && mapObjectLayer.layer.graphics) {
                        var lines = _.filter(mapObjectLayer.layer.graphics.items, function (item) {
                            return item && item.attributes && item.attributes.objectType === 'Line';
                        });

                        for (var i = 0; i < lines.length; ++i) {
                            var line = lines[i];
                            var mapPoint = mapObjectsLayerService.mapView.toMap(screenPoint);

                            if (mapPoint && mapPoint.x && mapPoint.y && line && line.geometry && line.geometry.paths
                                && line.geometry.paths.length === 1 && line.geometry.paths[0].length === 2
                                && line.geometry.paths[0][0].length === 2 && line.geometry.paths[0][1].length === 2) {
                                var lp1 = line.geometry.paths[0][0],
                                    lp2 = line.geometry.paths[0][1];

                                var distance = mapUtility.distanceToLineSegment(lp1[0], lp1[1], lp2[0], lp2[1], mapPoint.x, mapPoint.y);

                                if (distance < nearestDistance) {
                                    nearestLine = line;
                                    nearestDistance = distance;
                                }
                            }
                        }
                    }

                    return {
                        line: nearestLine,
                        distance: nearestDistance
                    }
                }

                //Important: requires the polygon to be convex
                function orderPolygonPoints(points) {
                    //Calculate center point
                    var centerX = _.sumBy(points, 'x') / points.length,
                        centerY = _.sumBy(points, 'y') / points.length,
                        loopGuard = 32;

                    var sortedList = [],
                        currentPoint = points[0],
                        pointsToInclude = points.slice(1);

                    sortedList.push(currentPoint);

                    var i = 0;
                    while (pointsToInclude.length > 0 && i < loopGuard) {
                        var smallestAngle = 360,
                            elementIndex = -1;

                        for (var j = 0; j < pointsToInclude.length; ++j) {
                            var p12 = Math.sqrt(Math.pow((centerX - currentPoint.x), 2) + Math.pow((centerY - currentPoint.y), 2));
                            var p13 = Math.sqrt(Math.pow((centerX - pointsToInclude[j].x), 2) + Math.pow((centerY - pointsToInclude[j].y), 2));
                            var p23 = Math.sqrt(Math.pow((currentPoint.x - pointsToInclude[j].x), 2) + Math.pow((currentPoint.y - pointsToInclude[j].y), 2));

                            var angleInDegree = Math.acos(((Math.pow(p12, 2)) + (Math.pow(p13, 2)) - (Math.pow(p23, 2))) / (2 * p12 * p13)) * 180 / Math.PI;

                            if (angleInDegree < smallestAngle) {
                                smallestAngle = angleInDegree;
                                elementIndex = j;
                            }
                        }

                        currentPoint = pointsToInclude[elementIndex];
                        sortedList.push(pointsToInclude[elementIndex]);
                        pointsToInclude.splice(elementIndex, 1);

                        ++i;
                    }

                    return sortedList;
                }

                mapObjectsLayerService.setup = function (mapService) {
                    //In case mapView is already initialized, then set mapView
                    if (mapService._mapView) {
                        mapObjectsLayerService.mapView = mapService._mapView;
                    }

                    $rootScope.$on('locationLoad', mapObjectsLayerService.onLocationLoad);

                    //Setup event handlers
                    mapService.on('mapview-create', function (evt, mapView) {
                        mapObjectsLayerService.mapView = mapView;
                    });

                    mapService.on('visibility-toggle', function (evt) {
                        if (evt && evt.layerName) {
                            mapObjectsLayerService.toggleVisibility(evt.layerName, evt.layerType);
                        }
                    });

                    mapService.on('zoom', function (zoomValue) {
                        if (zoomValue % 1 === 0) {
                            if (zoomValue < zoomTreshold) {
                                for (var i = 0; i < layers.length; ++i) {
                                    layers[i].layer.visible = false;
                                }
                            } else {
                                for (var j = 0; j < layers.length; ++j) {
                                    layers[j].layer.visible = layers[j].visible;
                                }
                            }
                        }
                    });

                    mapService._mapView.on('click', function (evt) {
                        if (mapObjectsLayerService.mapView.zoom >= zoomTreshold) {
                            var screenPoint = new ScreenPoint(evt.screenPoint.x, evt.screenPoint.y);

                            mapService._mapView.hitTest(screenPoint).then(function (response) {
                                if (response.results.length) {
                                    var relatedResults = response.results.filter(function (result) {
                                        return result.graphic && result.graphic.attributes && result.graphic.layer.layerType === 'MapObjectGraphics';
                                    });

                                    if (relatedResults.length > 0) {
                                        var resultGraphics = _.sortBy(_.map(relatedResults, 'graphic'), getDrawingOrder);
                                        var resultGraphic = resultGraphics[0];

                                        deselectGraphic(resultGraphic);
                                        selectGraphic(resultGraphic);
                                    }
                                } else {
                                    var nearest = getNearestLineAndDistance(screenPoint);
                                    if (nearest.line && nearest.distance < lineDistanceTreshold) {
                                        deselectGraphic(nearest.line);
                                        selectGraphic(nearest.line);
                                    }
                                }
                            });
                        }
                    });

                    mapObjectsLayerService.onMouseMove = function (evt, mapView, hitResponse) {
                        if (hitResponse && hitResponse.results.length > 0) {
                            var mapObjectGraphicResult = _.find(hitResponse.results, function (result) {
                                return result && result.graphic && result.graphic.layer
                                    && result.graphic.layer.layerType === 'MapObjectGraphics';
                            });

                            if (mapObjectGraphicResult) {
                                document.body.style.cursor = 'pointer';
                            } else {
                                document.body.style.cursor = 'default';
                            }
                        } else {
                            if (currentLocation && mapObjectsLayerService.mapView.zoom >= zoomTreshold) {
                                var screenPoint = new ScreenPoint(evt.offsetX, evt.offsetY);
                                var nearest = getNearestLineAndDistance(screenPoint);
                                if (nearest.line && nearest.distance < lineDistanceTreshold) {
                                    document.body.style.cursor = 'pointer';
                                } else {
                                    document.body.style.cursor = 'default';
                                }
                            } else {
                                document.body.style.cursor = 'default';
                            }
                        }
                    }
                };

                mapObjectsLayerService.createLayers = function (locationId, layerIds, layersVisibility) {
                    var authData = authService.getAuthData();

                    if (authData.isAuth) {
                        $rootScope.$broadcast('showBusyIndicator', {
                            id: 'mapObjectsLoading',
                            destination: '#main-view',
                            message: 'Laster inn kartdata',
                            overlay: false,
                            positionClass: 'bottom-left-inline'
                        });

                        mapObjectsLayerService.layerCreationInProgress = true;
                        locationService.getLocationByLocationNumber(locationId).then(function (locationData) {
                            if (locationData) {
                                var textLayerVisibility = false;

                                if (layersVisibility) {
                                    var textVisibility = _.find(layersVisibility, { name: 'MapObjectTextLayer' });
                                    textLayerVisibility = textVisibility ? textVisibility.visible : false;
                                }

                                $rootScope.$broadcast('mapObjectsLayerCreated', locationData);

                                if (!layerIds) {
                                    layerIds = _.map(_.filter(locationData.MapLayers, 'Show'), 'MapLayerId');
                                }

                                var locationGraphics = createLocationGraphics(locationData, layerIds);

                                var mapBargeObjectsLayer = new GraphicsLayer();
                                mapBargeObjectsLayer.name = 'MapBargeObjectGraphicsLayer';
                                mapBargeObjectsLayer.layerType = 'MapObjectGraphics';

                                var mapCageObjectsLayer = new GraphicsLayer();
                                mapCageObjectsLayer.name = 'MapCageObjectGraphicsLayer';
                                mapCageObjectsLayer.layerType = 'MapObjectGraphics';

                                var mapNetObjectsLayer = new GraphicsLayer();
                                mapNetObjectsLayer.name = 'MapNetObjectGraphicsLayer';
                                mapNetObjectsLayer.layerType = 'MapObjectGraphics';

                                var mapRingObjectsLayer = new GraphicsLayer();
                                mapRingObjectsLayer.name = 'MapRingObjectGraphicsLayer';
                                mapRingObjectsLayer.layerType = 'MapObjectGraphics';

                                var mapObjectsLayer = new GraphicsLayer();
                                mapObjectsLayer.name = 'MapObjectGraphicsLayer';
                                mapObjectsLayer.layerType = 'MapObjectGraphics';

                                var mapObjectsTextLayer = new GraphicsLayer();
                                mapObjectsTextLayer.name = 'MapObjectTextLayer';

                                mapObjectsLayerService.mapView.map.add(mapCageObjectsLayer, 2);
                                mapCageObjectsLayer.addMany(locationGraphics.cageGraphics);

                                mapObjectsLayerService.mapView.map.add(mapBargeObjectsLayer, 3);
                                mapBargeObjectsLayer.addMany(locationGraphics.bargeGraphics);

                                mapObjectsLayerService.mapView.map.add(mapNetObjectsLayer, 4);
                                mapNetObjectsLayer.addMany(locationGraphics.netGraphics);

                                mapObjectsLayerService.mapView.map.add(mapRingObjectsLayer, 5);
                                mapRingObjectsLayer.addMany(locationGraphics.ringGraphics);

                                mapObjectsLayerService.mapView.map.add(mapObjectsLayer, 6);
                                mapObjectsLayer.addMany(locationGraphics.graphics);

                                mapObjectsLayerService.mapView.map.add(mapObjectsTextLayer, 7);
                                mapObjectsTextLayer.addMany(locationGraphics.texts);

                                layers.push({
                                    name: 'MapBargeObjectGraphicsLayer',
                                    location: locationId,
                                    layer: mapBargeObjectsLayer,
                                    visible: true
                                });

                                layers.push({
                                    name: 'MapCageObjectGraphicsLayer',
                                    location: locationId,
                                    layer: mapCageObjectsLayer,
                                    visible: true
                                });

                                layers.push({
                                    name: 'MapNetObjectGraphicsLayer',
                                    location: locationId,
                                    layer: mapNetObjectsLayer,
                                    visible: true
                                });

                                layers.push({
                                    name: 'MapRingObjectGraphicsLayer',
                                    location: locationId,
                                    layer: mapRingObjectsLayer,
                                    visible: true
                                });

                                layers.push({
                                    name: 'MapObjectGraphicsLayer',
                                    location: locationId,
                                    layer: mapObjectsLayer,
                                    visible: true
                                });

                                layers.push({
                                    name: 'MapObjectTextLayer',
                                    location: locationId,
                                    layer: mapObjectsTextLayer,
                                    visible: textLayerVisibility
                                });

                                $timeout(function () {
                                    mapObjectsTextLayer.visible = textLayerVisibility;
                                }, 100);

                                currentLocation = locationData;
                                mapObjectsLayerService.layerCreationInProgress = false;

                                mapObjectsLayerService.mapObjectsLayer = mapObjectsLayer;
                                mapObjectsLayerService.mapBargeObjectsLayer = mapBargeObjectsLayer;
                                mapObjectsLayerService.mapCageObjectsLayer = mapCageObjectsLayer;
                                mapObjectsLayerService.mapRingObjectsLayer = mapRingObjectsLayer;

                                $rootScope.$broadcast('hideBusyIndicator', 'mapObjectsLoading');
                            }
                        }, function() {
                            $rootScope.$broadcast('hideBusyIndicator', 'mapObjectsLoading');
                        });
                    }
                };

                mapObjectsLayerService.recreateLayers = function (locationId) {
                    var layersToRecreate = _.filter(layers, { location: locationId });

                    _.forEach(layersToRecreate, function (layer) {
                        var removedLayer = layers.splice(layers.indexOf(layer), 1);

                        removedLayer.removeAll();
                        mapObjectsLayerService.mapView.map.remove(layer.layer);
                    });

                    mapObjectsLayerService.createLayers(locationId);
                };

                mapObjectsLayerService.hasLayers = function (locationId) {
                    return _.find(layers, { location: locationId }) !== undefined;
                };

                mapObjectsLayerService.destroyLayers = function (locationNumber) {
                    for (var i = layers.length - 1; i >= 0; i--) {
                        if (layers[i].locationId === locationNumber) {
                            mapObjectsLayerService.mapView.map.layers.remove(layers[i].layer);

                            var removedLayer = layers.splice(i, 1);
                            removedLayer.removeAll();
                        }
                    }
                };

                mapObjectsLayerService.destroyAllLayers = function () {
                    for (var i = layers.length - 1; i >= 0; i--) {
                        mapObjectsLayerService.mapView.map.layers.remove(layers[i].layer);
                        layers.splice(i, 1);
                    }
                };

                mapObjectsLayerService.toggleVisibility = function (layerName, layerType) {
                    for (var i = 0; i < layers.length; ++i) {
                        if (layers[i].name === layerName || (layers[i].layer.layerType && layers[i].layer.layerType === layerType)) {
                            layers[i].layer.visible = !layers[i].layer.visible;
                            layers[i].visible = !layers[i].visible;
                        }
                    }
                };

                mapObjectsLayerService.onLocationLoad = function (evt, loadParams) {
                    //Clear selected graphic and lines
                    mapObjectsLayerService.selectedLines = [];
                    mapObjectsLayerService.selectedGraphic = null;

                    if (loadParams.redrawLayers) {
                        if (!mapObjectsLayerService.layerCreationInProgress) {
                            mapObjectsLayerService.destroyAllLayers();

                            mapObjectsLayerService.createLayers(
                                loadParams.locationId,
                                loadParams.layersToDisplay,
                                loadParams.layersVisibility
                            );
                        }
                    } else {
                        if (layers.length === 0) {
                            mapObjectsLayerService.createLayers(loadParams.locationId);
                        } else {
                            if (!mapObjectsLayerService.layerCreationInProgress) {
                                mapObjectsLayerService.destroyAllLayers();
                                mapObjectsLayerService.createLayers(loadParams.locationId);
                            }
                        }
                    }
                };

                readyDeferred.resolve(mapObjectsLayerService);
            });

        return mapObjectsLayerService;
    }
})();
