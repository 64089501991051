(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('WorkOrdersGridController', workOrdersGridController);

    workOrdersGridController.$inject = [
        '$scope',
        '$q',
        '$rootScope',
        '$timeout',
        '$translate',
        'dateUtility',
        'kendoUtility',
        'workOrderService',
        'workOrderDetailsModalService',
        'workOrderStatuses',
        'caseDetailsModalService',
        'noUser',
        '$state',
        'sanitizeService'
    ];

    function workOrdersGridController(
        $scope,
        $q,
        $rootScope,
        $timeout,
        $translate,
        dateUtility,
        kendoUtility,
        workOrderService,
        workOrderDetailsModalService,
        workOrderStatuses,
        caseDetailsModalService,
        noUser,
        $state,
        sanitizeService
    ) {
        $scope.canEdit = $scope.hasPermission('WorkOrderEdit') || $scope.hasPermission('WorkOrderAdmin') || $scope.hasPermission('WorkOrderEquipmentRead') || $scope.hasPermission('WorkOrderComponentRead') ? true : false;


        const workOrdersGridColumns = langId => {

            let columns = [
                {
                    field: 'operationLogId',
                    hidden: true
                },
                {
                    title: '&nbsp;',
                    width: 50,
                    command: {
                        text: ' ',
                        template:
                            '<i class="fa fa-pencil-alt fa-1-5x grid-icon" ng-click="editWorkOrderLog($event)"></i>',
                    }
                },
                {
                    title: $translate.instant('WORKORDER_GRID_WONUMBER', null, null, langId),
                    field: 'woNumber',
                    width: 45,
                    template: function (dataItem) {
                        if (dataItem.woNumber) {
                            return '<div class="text-center" style="font-weight:bold">' + dataItem.woNumber + '</div>';
                        }
                        return '';
                    }
                },
                {
                    title: $translate.instant('WORKORDER_GRID_RELATED_TO', null, null, langId),
                    field: 'parentObject',
                    width: 50,
                    template: function (dataItem) {
                        if (dataItem.parentObject) {
                            var currentCaseId = dataItem.parentObject.caseId;
                            return '<a class="k-link" data-type="case" data-caseId="' +
                                currentCaseId +
                                '" ng-click="openRelatedCase(dataItem.parentObject)">Sak: ' +
                                currentCaseId +
                                '</a>';
                        } else if (dataItem.itemId) {
                            return '<a class="k-link" data-type="item" data-itemid="' +
                                dataItem.itemId +
                                '" data ng-click="openRelatedItem(dataItem)">Utstyr</a>';
                        }
                        return '';
                    }
                },
                {
                    title: $translate.instant('WORKORDER_GRID_DEPARTMENT_OR_LOCATION', null, null, langId),
                    field: 'departmentOrLocationName',
                    width: 75,
                    filterable: false
                },
                {
                    title: $translate.instant('WORKORDER_GRID_LOGACTION', null, null, langId),
                    field: 'logAction.description',
                    width: 80,
                    filterable: {
                        multi: true,
                        search: true
                    },
                    template: function (dataItem) {
                        return '<span kendo-tooltip k-content="\'{{ dataItem.logAction.description }}\'">{{dataItem.logAction.description}}</span>';
                    }
                },
                {
                    title: $translate.instant('WORKORDER_GRID_TITLE', null, null, langId),
                    field: 'title',
                    width: 300,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        if (dataItem.title) {
                            return dataItem.title;
                        }
                        return '';
                    }
                },
                {
                    title: $translate.instant('WORKORDER_GRID_ASSIGNED_TO', null, null, langId),
                    field: 'assignedUserOrDep',
                    sortable: {
                        compare: function(a, b) {
                            if(!a.assignedUserOrDep){
                                return 1;
                            }
                            if(!b.assignedUserOrDep){
                                return -1;
                            }

                            if(a.assignedUserOrDep.label < b.assignedUserOrDep.label){
                                return -1;
                            }

                            if(a.assignedUserOrDep.label > b.assignedUserOrDep.label){
                                return 1;
                            } else{
                                return 0;
                            }
                        }
                    },
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }

                        if(dataItem.assignedUserOrDep){
                            return dataItem.assignedUserOrDep.label;
                        }

                        return 'Ikke tildelt';
                    },
                    editor: assignedUserDropDownEditor,
                    width: 100,
                    filterable: false
                },
                {
                    title: $translate.instant('WORKORDER_GRID_CREATEDON', null, null, langId),
                    field: 'createdOn',
                    filterable: {
                        extra: true
                    },
                    template: function (dataItem) {
                        return  moment(dataItem.createdOn).format('DD.MM.YYYY');
                    },
                    width: 60
                },
                {
                    title: $translate.instant('WORKORDER_GRID_DONEON', null, null, langId),
                    field: 'doneOn',
                    filterable: {
                        extra: true
                    },
                    template: function (dataItem) {
                        return dataItem.doneOn ? moment(dataItem.doneOn).format('DD.MM.YYYY') : "";
                    },
                    width: 60
                },
                {
                    title: $translate.instant('WORKORDER_GRID_DONETO', null, null, langId),
                    field: 'doneTo',
                    filterable: {
                        extra: true
                    },
                    template: function (dataItem) {
                        return dataItem.doneTo ? moment(dataItem.doneTo).format('DD.MM.YYYY') : "";
                    },
                    width: 60,

                },
                {
                    title: $translate.instant('WORKORDER_GRID_DUEDATE', null, null, langId),
                    field: 'dueDate',
                    template: function (dataItem) {
                        return dataItem.dueDate ? moment(dataItem.dueDate).format('DD.MM.YYYY') : "";
                    },
                    width: 60,
                    filterable: false
                },
                {
                    title: $translate.instant('WORKORDER_GRID_STATUS', null, null, langId),
                    field: 'status',
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return workOrderStatuses.find(s => s.value == dataItem.status).text;
                    },
                    editor: statusDropDownEditor,
                    width: 70,
                    filterable: false
                },
                {
                    title: $translate.instant('WORKORDER_GRID_COMMENT', null, null, langId),
                    field: 'comment',
                    width: 150,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        if (dataItem.comment) {
                            return dataItem.comment;
                        }
                        return '';
                    }
                },
                {
                    title: $translate.instant('WORKORDER_GRID_TAGS', null, null, langId),
                    field: 'woTagIdsList',
                    width: 75,
                    template: function (dataItem) {
                        return dataItem.woTagNamesList;
                    },
                    filterable: {
                        ui : createWoTagsMultiSelect
                    },
                },
                {
                    title: $translate.instant('WORKORDER_GRID_PRIORITY_CODE', null, null, langId),
                    field: 'priorityCode',
                    width: 75,
                    template: function (dataItem) {
                        if (dataItem.workOrderData && dataItem.workOrderData.priorityCode) {
                            dataItem.priorityCode = dataItem.workOrderData.priorityCode;
                            return dataItem.priorityCode;
                        }
                        else {
                            dataItem.priorityCode = ''
                            return '';
                        }
                    },
                    filterable: {
                        extra: false,
                        multi: true
                    },
                }
            ];

            return columns;
        };

        $scope.$on('langChanged', (event, code) => {
            $scope.workOrdersGridOptions = workOrdersGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.workOrdersGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#work-orders-grid')]);
            langId = code;
        });

        var workOrdersDataSource = new kendo.data.DataSource({
            batch: $scope.canEdit,
            transport: {
                read: e => e.success($scope.workOrderLogs),
                update: e => onWorkOrdersGridUpdate(e)
            },
            schema: {
                model: {
                    id: 'operationLogId',
                    fields: {
                        operationLogId: { editable: false, nullable: false },
                        woNumber: { editable: false, type: 'number' },
                        parentObject: { editable: false },
                        'department.name': { editable: false },
                        'logAction.description': { editable: false },
                        title: { editable: $scope.canEdit },
                        assignedUserOrDep: { editable: $scope.canEdit, type: 'object' },
                        createdOn: { editable: false, type: 'date' },
                        dueDate: { editable: false, type: 'date' },
                        doneOn: { editable: false, type: 'date' },
                        doneTo: { editable: false, type: 'date' },
                        status: { editable: $scope.canEdit },
                        comment: { editable: $scope.canEdit },
                        woTagsList: { type: "string", editable: false },
                        priorityCode: {type: "string", editable: false, nullable: true}
                    }
                },
                parse: function (d) {
                    $.each(d, function (idx, elem) {
                        elem.createdOn = dateUtility.formatDate(elem.createdOn);
                        elem.dueDate = dateUtility.formatDate(elem.dueDate);
                    });
                    return d;
                }
            }
        });

        const workOrdersGridOptions = langId => ({
            dataSource: workOrdersDataSource,
            toolbar: $scope.canEdit ? ['save', 'cancel'] : null,
            excel: {
                allPages: true
            },
            excelExport: e => onExcelExport(e),
            columns: workOrdersGridColumns(langId),
            filterable: {
                name: "FilterMenu",
                extra: false,
                operators:{
                    string:{
                      contains: $translate.instant('G_SEARCH_OPTION_CONTAINS', null, null, langId),
                    }
                },
            },
            sortable: true,
            resizable: true,
            selectable: false,
            editable: $scope.canEdit,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NODATA', null, null, langId) //'Ingen treff'
            },
            height: kendoUtility.calculateRemainingSpace(),
            dataBound: onDataBound
        });

        $scope.tooltipOptions = {
            filter: 'td:nth-child(3)',
            content: function (e) {
                var target = e.target,
                    relatedLink = $($(target).find('a')),
                    type = relatedLink.data('type');

                var data = $('#work-orders-grid').data().kendoGrid.dataSource.view().toJSON();
                if (type === 'case') {
                    var caseId = relatedLink.data('caseid');
                    var log = data.filter(function (log) {
                        return log.parentObject && log.parentObject.caseId === caseId.toString();
                    })[0];
                    if (log && log.parentObject) {
                        return log.parentObject.description + '<br />' + log.parentObject.comment;
                    }
                } else if (type === 'item') {
                    var relatedWorkOrder = _.find(data, { itemId: relatedLink.data('itemid') });
                    if (relatedWorkOrder && relatedWorkOrder.item && relatedWorkOrder.item.description) {
                        return 'Utstyr: ' + relatedWorkOrder.item.description;
                    }
                }

                return '';
            },
            show: function (e) {
                if (e.sender.content[0].textContent.length > 0) {
                    e.sender.popup.element.removeClass('k-tooltip-hidden');
                }
                e.sender.popup.element.addClass('k-tooltip-nowrap');
            },
            hide: function (e) {
                e.sender.popup.element.addClass('k-tooltip-hidden');
            }
        };

        $scope.editWorkOrderLog = editWorkOrderLog;
        $scope.openRelatedCase = openRelatedCase;
        $scope.openRelatedItem = openRelatedItem;

        $scope.workOrdersGridOptions = workOrdersGridOptions($translate.use());

        initController();
        function initController() {
            $timeout(function () {
                toggleChangesButtons(true);
                $('a.k-button.k-button-icontext.k-grid-cancel-changes').on('click', function () {
                    $timeout(function () {
                        toggleChangesButtons(true);
                    }, 100);
                });
            }, 100);

            $rootScope.$on('workOrdersFetched', function () {
                $scope.workOrdersGridOptions.dataSource.read();
            });

            $('#work-orders-grid').bind('keydown', function (e) {
                if (e.keyCode === 13) {
                    $('input').blur();
                }
                if (e.keyCode === 27) {
                    $scope.workOrdersGridOptions.dataSource.cancelChanges();
                }
            });
        }

        function editWorkOrderLog(event) {
            var sender = event.currentTarget;
            var row = angular.element(sender).closest("tr");
            var dataItem = $("#work-orders-grid").data("kendoGrid").dataItem(row).toJSON();
            $state.go('workorders', {'woNo': dataItem.woNumber}, {notify: false});
            var workOrderLog = workOrderDetailsModalService.createWorkOrderLog(dataItem);
            workOrderDetailsModalService.createWorkOrderDetailsResolve(workOrderLog)
                .then(function (resolve) {
                    var modal = workOrderDetailsModalService.openWorkOrderDetailsModal(workOrderLog, $scope.canEdit);
                    modal.result.then(function (result) {
                        if (result) {
                            $rootScope.$broadcast('refreshWorkOrders');
                        }
                    });
                });
        }

        function statusDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    dataTextField: 'text',
                    dataValueField: 'value',
                    dataSource: {
                        data: workOrderService.getAllowedStatuses($scope, workOrderStatuses)
                    },
                    select: function (e) {
                        if (e.dataItem.isDisabled) {
                            e.preventDefault();
                        }
                    },
                    template: kendo.template($("#wo-status-template").html())
                });
        }

        function assignedUserDropDownEditor(container, options) {
            var dropdownlist = $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    dataTextField: 'label',
                    dataValueField: 'id',
                    filter: 'contains',
                    dataSource: new kendo.data.DataSource({
                        data: $scope.userAndDepSelectorSource
                    })
                });

            if (options.model.assignedUserAndDep && options.model.assignedUserAndDep.id) {
                dropdownlist.select(options.model.assignedUserAndDep.id);
            }
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('a.k-button.k-button-icontext.k-grid-save-changes');
            var cancelChangesButton = $('a.k-button.k-button-icontext.k-grid-cancel-changes');

            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function openRelatedCase(caseData) {
            var caseToOpen = caseDetailsModalService.createCase(caseData.toJSON());
            caseDetailsModalService.createCaseDetailsResolve(caseToOpen)
                .then(function (resolve) {
                    var modal = caseDetailsModalService.openCaseDetailsModal(resolve);
                    modal.result.then(function (response) {
                        if (response) {
                            $rootScope.$broadcast('refreshWorkOrders');
                        }
                    });
                });
        }

        function openRelatedItem(dataItem) {
            if (dataItem && dataItem.departmentId && dataItem.item) {
                var relatedItemLink = window.location.origin
                    + '/equipments?DepartmentId='
                    + dataItem.departmentId
                    + '&ItemDescription='
                    + encodeURIComponent(dataItem.item.description);

                window.open(relatedItemLink, '_blank');
            }
        }

        //Private functions
        function onDataBound() {
            var data = this.dataSource.view();
            for (var i = 0; i < data.length; i++) {
                if (data[i].isExpired) {
                    var row = this.tbody.find("tr[data-uid='" + data[i].uid + "']");
                    row.css('color', 'red');
                    this.expandRow(row);
                }
            }

            kendoUtility.adjustGridHeight('#work-orders-grid', $scope.workOrderLogs.length);
        }

        function onExcelExport(e) {
            e.workbook.fileName = 'TO Oversikt - ' + moment(new Date()).format('DD.MM HH.mm') + '.xlsx';

            let sheet = e.workbook.sheets[0];
            if (e.data.length === sheet.rows.length - 1) {
                let dateColumns = ['Dato inn', 'Planlagt utfort', 'Frist'];
                for (let rowIndex = 0; rowIndex < sheet.rows.length; rowIndex++) {
                    let row = sheet.rows[rowIndex];
                    for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
                        var cell = row.cells[cellIndex];
                        if (row.type === 'data') {
                            if (dateColumns.indexOf(sheet.rows[0].cells[cellIndex].value) > -1) {
                                cell.format = 'dd.MM hh:mm';
                            }
                            // Assigned to (user and department)
                            if (cellIndex == 5) {
                                if (cell.value && cell.value.label) {
                                    cell.value = cell.value.label;
                                }
                            }
                        }
                    }
                }
            }
        }

        function onWorkOrdersGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'workOrdersGrid',
                destination: '#work-orders-grid'
            });

            let logs = [];

            e.data.models.forEach(function (log) {
                if(log.assignedUserOrDep){
                    var selectedDep = $scope.departmentSelectorSource.find(s => s.id == log.assignedUserOrDep.id);
                    if(selectedDep){
                        if (log.workOrderData) {
                            log.workOrderData.assignedDepartmentId = log.assignedUserOrDep.id;
                        }
                        log.assignedUser = [];
                    } else {
                        if(log.assignedUserOrDep.id == noUser.userId){
                            log.assignedUser = [];
                            if (log.workOrderData) {
                                log.workOrderData.assignedDepartmentId = null;
                            }
                        } else {
                            var selectedUser = $scope.usersByContractorId.filter(function (user) {
                                        return user.userId === log.assignedUserOrDep.id
                                            && log.assignedUserOrDep.id !== noUser.userId;
                                    });
                            log.assignedUser =  selectedUser;
                            if (log.workOrderData) {
                                log.workOrderData.assignedDepartmentId = null;
                            }
                        }
                    }
                }
                log.editedBy = $rootScope.authData.username;
                log.editedOn = new Date();
                logs.push(log);
            });

            workOrderService.updateWorkOrderLogs(logs).then(function (response) {
                if (response) {
                    toggleChangesButtons(true);
                    e.success();
                    $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
                } else {
                    e.error();
                    $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
                }
            }, function (error) {
                e.error(error);
                $rootScope.$broadcast('hideBusyIndicator', 'workOrdersGrid');
            });
        }

        function readTags(e) {
            $q.all([workOrderService.getWorkOrderTagsByContractorId($rootScope.authData.contractorId)]).then(function(data) {
                $scope.tags = data[0];
                e.success($scope.tags);
            })
            .catch(error => e.success([]));
        }

        function createWoTagsMultiSelect(element) {
            element.removeAttr("data-bind");

            element.kendoMultiSelect({
                dataSource: {
                    transport: {
                        read: readTags
                    }
                },
                dataTextField: "tag",
                dataValueField: "workOrderTagId",
                change: function(e) {
                    var filter = { logic: "or", filters: [] };
                    var values = this.value();
                    $.each(values, function(i, v) {
                    filter.filters.push({field: "woTagIdsList", operator: "contains", value: v });
                    });
                    workOrdersDataSource.filter(filter);
              }
            });
          }
    }
})();
