(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('reportInformationController', reportInformationController);

    reportInformationController.$inject = [
        '$scope',
        '$rootScope',
        'arrivalReport',
        'dateUtility',
        'userService',
        'reportService',
        '$stateParams',
        'htmlElementService'
    ];

    function reportInformationController(
        $scope,
        $rootScope,
        arrivalReport,
        dateUtility,
        userService,
        reportService,
        $stateParams,
        htmlElementService
    ) {
        $scope.orderId = $stateParams.OrderId;
        $scope.sender = null;
        $scope.sentTime = null;
        $scope.recipients = null;
        $scope.showfinalReportNotExistsMsg = false;
        $scope.isLoaded = false;


        initController();
        function initController()
        {
            htmlElementService.checkIfElementExists('report-info-modal').then(function (result) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'reportInfoModal',
                destination: '#report-info-modal',
            });
            });

            userService.getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId]}).then(function(users) {
                if(arrivalReport){
                    var sender = users.find(u => u.userName == arrivalReport.sendingUser);
                    var emails = arrivalReport.recieverEmailData ? JSON.parse(arrivalReport.recieverEmailData) : arrivalReport.recieverEmailData;

                    $scope.sender = sender ? sender.displayName : '';
                    $scope.sentTime = dateUtility.formatDateTime(arrivalReport.sentTime);
                    $scope.recipients = emails ? emails.join(', ') : '';
                    $scope.isLoaded = true;
                    $rootScope.$broadcast('hideBusyIndicator', 'reportInfoModal');
                } else {
                    reportService.getFinalReportHistoryByOrderId($scope.orderId).then(function(history){
                        if(history != ''){
                        var sender = users.find(u => u.userId == history.senderId);
                        var emails = history.recieverEmailData ? JSON.parse(history.recieverEmailData) : '';

                        $scope.sender = sender ? sender.displayName : '';
                        $scope.sentTime = dateUtility.formatDateTime(history.sentTime);
                        $scope.recipients = emails ? emails.join(', ') : '';
                        } else {
                            $scope.showfinalReportNotExistsMsg = true;
                        }
                        $scope.isLoaded = true;
                    }).finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'reportInfoModal');
                    });
                }
            });
        }
    }
})();
