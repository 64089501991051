(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OrderTrackController', OrderTrackController);

    OrderTrackController.$inject = ['$scope', '$state', '$stateParams', '$rootScope', 'orderService', 'anteoGeodataService', 'mapUtility'];

    function OrderTrackController($scope, $state, $stateParams, $rootScope, orderService, anteoGeodataService, mapUtility) {
        $scope.contractorId = $stateParams.ContractorId;
        $scope.orderId = $stateParams.OrderId;
        $scope.locationNumber = null;

        $scope.goBack = goBack;
        $scope.initController = initController;
        $scope.initController();

        function initController() {
            if ($scope.contractorId && $scope.orderId) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'rightPane',
                    destination: '#right-pane'
                });

                orderService.getExtendedOrderByOrderId($scope.orderId).then(function (order) {
                    $rootScope.$broadcast('setSplitterSize', {
                        left: '60%',
                        right: '40%'
                    });
                    $scope.orderNumber = order.externalOrderId;
                    $scope.customerName = order.customer ? order.customer.name : '';
                    $scope.processName = order.orderProcess.name;

                    //Add departments
                    if (order.departments && order.departments.length > 0) {
                        order.departmentNames = order.departments[0].name;

                        for (var j = 1; j < order.departments.length; ++j) {
                            order.departmentNames += ', ' + order.departments[j].name;
                        }
                    }

                    $scope.departmentNames = order.departmentNames;
                    $scope.contactPerson = order.contactPersons && order.contactPersons[0] ? order.contactPersons[0].firstName + " " + order.contactPersons[0].lastName : "";
                    $scope.projectLeader = order.projectManagerObject ? order.projectManagerObject.displayName : '';
                    $scope.description = order.orderDescription;

                    if (order.locationNumber && order.locationName) {
                        $scope.location = order.locationNumber + ' - ' + order.locationName;
                    } else {
                        $scope.location = '';
                    }

                    $scope.locationNumber = order.locationNumber;
                    $scope.startDate = moment(order.orderDate).format('DD.MM.YYYY');

                    if (order.expireDate) {
                        $scope.endDate = moment(order.expireDate).format('DD.MM.YYYY');
                    }

                    if (order.departments[0] && order.departments[0].MMSI) {
                        $scope.mmsi = order.departments[0].MMSI;
                    }
                    var anteoFrom = moment(order.orderDate).format('YYYY-MM-DDTHH:mm:ss'),
                        anteoTo = moment().add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');

                    if (order.expireDate) {
                        anteoTo = moment(order.expireDate).format('YYYY-MM-DDTHH:mm:ss');
                    }

                    if ($scope.mmsi) {
                        anteoGeodataService.getGeoJsonData($scope.mmsi, true, anteoFrom, anteoTo).then(function(geoData) {
                            if (geoData && geoData.features) {
                                $scope.geoData = geoData;
                                $scope.name = geoData.features[0].properties.name;
                                $scope.mmsi = geoData.features[0].properties.mmsi;
                                $scope.navStat = geoData.features[0].properties.navStat;
                                $scope.type = geoData.features[0].properties.type;

                                var center = mapUtility.getCenterFromDegrees(geoData.features[1].geometry.coordinates);
                                $scope.centerOfCoordinates = mapUtility.convertToUTM33N(center[1], center[0]);

                                var geodataLines = [];
                                for (var j = 0; j < geoData.features.length; ++j) {
                                    var currentFeature = geoData.features[j],
                                        convertedCoordinates = [];

                                    if (currentFeature.geometry && currentFeature.geometry.type === 'LineString') {
                                        for (var i = 0; i < currentFeature.geometry.coordinates.length; ++i) {
                                            convertedCoordinates.push(mapUtility.convertToUTM33N(
                                                currentFeature.geometry.coordinates[i][1],
                                                currentFeature.geometry.coordinates[i][0]
                                            ));
                                        }

                                        geodataLines.push({
                                            coordinates: convertedCoordinates,
                                            properties: currentFeature.properties
                                        });
                                    }
                                }

                                $rootScope.$broadcast(
                                    'zoomToLocation',
                                    { x: $scope.centerOfCoordinates.X, y: $scope.centerOfCoordinates.Y },
                                    null,
                                    null,
                                    7
                                );

                                $rootScope.$broadcast('drawTracking', geodataLines);
                            } else {
                                $scope.noData = true;
                            }

                            $rootScope.$broadcast('hideBusyIndicator', 'rightPane');;
                        });
                    } else {
                        $rootScope.$broadcast('hideBusyIndicator', 'rightPane');
                    }
                });
            }



        }

        function goBack () {
            $state.go('main.orderReport', {
                ContractorId: $scope.contractorId,
                OrderId: $scope.orderId
            });
        }

        function getParameterByName(name, url) {
            if (!url) {
                url = window.location.href;
            }
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

    }
})();
