(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ContractorModalController', contractorModalController);

    contractorModalController.$inject = ['$q', '$rootScope', '$scope', '$windowInstance', '$translate', 'contractor', 'formType', 'conglomerates', 'locationOwners', 'availableLanguages', 'logActionService'];

    function contractorModalController($q, $rootScope, $scope, $windowInstance, $translate, contractor, formType, conglomerates, locationOwners, availableLanguages, logActionService) {
        const newContractor = {
            name: '',
            locationOwnerId: '',
            cretBy: '',
            cretOn: '',
            modfBy: '',
            modfOn: '',
            isActive: true,
            reportFolder: '',
            conglomerateId: '',
            azureStorageName: '',
            vesselImageUrl: '',
            adTenantId: '',
            hasNaviaqLicense: false,
            defaultWarningDays: 30,
            preferredLanguageId: 'NO'
        }
        $scope.workOrderLogActions = [];
        $scope.deviationLogActions = [];
        $scope.contractor = formType === 'add' ? newContractor : contractor;
        $scope.conglomerates = conglomerates;
        $scope.locationOwners = locationOwners;
        $scope.languages = availableLanguages.map(l => { return { iso: l.id.toUpperCase(), name: l.name } });
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.isDisabled = isDisabled;

        $scope.conglomeratesOptions = {
            dataSource: new kendo.data.DataSource({ data: $scope.conglomerates }),
            dataValueField: 'conglomerateId',
            dataTextField: 'name',
            filter: "contains",
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE')
        };

        $scope.locationOwnersOptions = {
            dataSource: new kendo.data.DataSource({ data: $scope.locationOwners }),
            dataValueField: 'locationOwnerId',
            dataTextField: 'customDataTextField',
            filter: "contains",
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE')
        };

        $scope.languageOptions = {
            dataSource: new kendo.data.DataSource({ data: $scope.languages }),
            dataValueField: 'iso',
            dataTextField: 'name'
        };

        $scope.deviationLogActionOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.deviationLogActions)
                }
            }),
            dataValueField: 'logActionId',
            dataTextField: 'description',
            filter: "contains",
        };

        $scope.workOrderLogActionOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.workOrderLogActions)
                }
            }),
            dataValueField: 'logActionId',
            dataTextField: 'description',
            filter: "contains",
        };

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', { id: 'contractorModalControllerIndicator', destination: '#contractor-form' });
            const contractorId = $scope.contractor ? $scope.contractor.contractorId : null;

            function handleResults(results) {
                if (results) {
                    $scope.workOrderLogActions = results.workOrderLogs;
                    $scope.workOrderLogActionOptions.dataSource.read();

                    $scope.deviationLogActions = results.deviationLogs;
                    $scope.deviationLogActionOptions.dataSource.read();
                }

                if (formType !== 'add' && $scope.contractor) {
                    $scope.selectedLocationOwner = $scope.contractor.locationOwnerId !== "" ? getSelectedLocationOwner() : null;
                    $scope.selectedConglomerate = $scope.contractor.conglomerateId !== "" ? getSelectedConglomerate() : null;
                    $scope.selectedLanguage = $scope.contractor.preferredLanguageId !== "" ? getSelectedLanguage() : null;
                    $scope.selectedDefaultDeviationLogAction = $scope.contractor.defaultDeviationLogActionId ? $scope.deviationLogActions.find(c => c.logActionId === $scope.contractor.defaultDeviationLogActionId) : null;
                    $scope.selectedDefaultWorkOrderLogAction = $scope.contractor.defaultWorkOrderLogActionId ? $scope.workOrderLogActions.find(c => c.logActionId === $scope.contractor.defaultWorkOrderLogActionId) : null;
                }
            }

            if (contractorId) {
                $q.all({
                    workOrderLogs: logActionService.getByContractorIdAndLogActionType(contractorId, 'WorkOrderLog'),
                    deviationLogs: logActionService.getByContractorIdAndLogActionType(contractorId, 'DeviationLog')
                })
                    .then(handleResults)
                    .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'contractorModalControllerIndicator'));
            } else {
                handleResults();
                $rootScope.$broadcast('hideBusyIndicator', 'contractorModalControllerIndicator');
            }
        }


        function getSelectedLocationOwner() {
            return $scope.locationOwners.find(lo => lo.locationOwnerId === $scope.contractor.locationOwnerId);
        }

        function getSelectedConglomerate() {
            return $scope.conglomerates.find(c => c.conglomerateId === $scope.contractor.conglomerateId);
        }

        function getSelectedLanguage() {
            return $scope.languages.find(c => c.iso === $scope.contractor.preferredLanguageId);
        }

        function isDisabled() {
            return ($scope.contractor.name == null || $scope.contractor.name.trim() === '');
        }

        function ok() {
            if ($scope.selectedConglomerate) {
                $scope.contractor.conglomerateId = $scope.selectedConglomerate.conglomerateId;
            }

            if ($scope.selectedDefaultDeviationLogAction) {
                $scope.contractor.defaultDeviationLogActionId = $scope.selectedDefaultDeviationLogAction.logActionId;
            }

            if ($scope.selectedDefaultWorkOrderLogAction) {
                $scope.contractor.defaultWorkOrderLogActionId = $scope.selectedDefaultWorkOrderLogAction.logActionId;
            }

            if ($scope.selectedLanguage) {
                $scope.contractor.preferredLanguageId = $scope.selectedLanguage.iso;
            }

            if ($scope.selectedLocationOwner) {
                $scope.contractor.locationOwnerId = $scope.selectedLocationOwner.locationOwnerId;
            }

            if (formType === 'add') {
                $scope.contractor.cretOn = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
                $scope.contractor.cretBy = $rootScope.authData.username;
            }

            $scope.contractor.modfOn = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
            $scope.contractor.modfBy = $rootScope.authData.username;

            $windowInstance.close({result: $scope.contractor});
        }

        function cancel() {
            $windowInstance.close({result: false});
        }
    }
})();
