angular.module('NaviaqWebApp').factory('mapService', ['$q', '$rootScope', '$stateParams', 'esriLoader', 'mapUtility', 'locationService',
function ($q, $rootScope, $stateParams, esriLoader, mapUtility, locationService) {
    'use strict';

    var readyDeferred = null,
        mapCreatedDeferred = null;
    var mapService = {
        _factories: [],
        _services: [],
        _mapView: null,
        _targetElement: null,
        _initialPosition: null,
        _initialZoom: null,

        ctxMenu: null,

        setInitialPosition: function (position, zoom) {
            mapService._initialPosition = position;
            mapService._initialZoom = zoom;
        },

        mapCreated: function () {
            if (mapCreatedDeferred === null) {
                mapCreatedDeferred = $q.defer();
            }

            return mapCreatedDeferred.promise;
        },

        ready: function () {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }

            return readyDeferred.promise;
        }
    };
    var message;

    esriLoader.require([
        'esri/Map',
        'esri/views/MapView',
        'esri/layers/GraphicsLayer',
        'esri/Graphic',
        'esri/geometry/Point',
        'esri/geometry/ScreenPoint',
        'dojo/on',
        'dijit/Menu'
    ], function (Map, MapView, GraphicsLayer, Graphic, Point, ScreenPoint, on, Menu) {
        if (!readyDeferred) {
            readyDeferred = $q.defer();
        }

        var callbacks = {
            'mousemove': [],
            'click': [],
            'rightclick': [],
            'zoom': [],
            'center': [],
            'visibility-toggle': [],
            'mapview-create': []
        };

        function executeCallbacks(eventName, evt, mapView, hitResponse) {
            if (callbacks[eventName]) {
                for (var i = 0; i < callbacks[eventName].length; ++i) {
                    callbacks[eventName][i](evt, mapView, hitResponse);
                }
            }
        }

        function getScreenPointFromMenuPosition(mapView, box) {
            var x = box.x, y = box.y;
            switch (box.corner) {
                case "TR":
                    x += box.w;
                    break;
                case "BL":
                    y += box.h;
                    break;
                case "BR":
                    x += box.w;
                    y += box.h;
                    break;
            }

            return new ScreenPoint(x - mapView.position[0], y - mapView.position[1]);
        }

        //Internal event handler functions
        function onMouseMove(evt) {
            var screenPoint = new ScreenPoint(evt.offsetX, evt.offsetY);

            //Dispatch only if not dragging
            //TODO: don't dispatch if not zooming
            if (evt.buttons === 0) {
                var hitTestPromise = mapService._mapView.hitTest(screenPoint);

                if (hitTestPromise) {
                    hitTestPromise.then(function (response) {
                        if (response.results && response.results.length > 0) {
                            //TODO: get the graphic with the highest z coordinate
                            executeCallbacks('mousemove', evt, mapService._mapView, response);
                        } else {
                            executeCallbacks('mousemove', evt, mapService._mapView);
                        }
                    });
                }
            }
        }

        function onMouseDown(evt) {
            $rootScope.isDragging = false;
            var clickDetectTimeout = 200;
            setTimeout(() => $rootScope.isDragging = true, clickDetectTimeout);
        }

        function onMouseUp(evt) {
            if (!$rootScope.isDragging)
            {
                var latLng = convertScreenPointToLatLng(evt, mapService, mapUtility);
                var latDeg = mapUtility.convertDecDegreesToDegreesMins(latLng.lat);
                var lngDeg = mapUtility.convertDecDegreesToDegreesMins(latLng.lng);

                var latLngTexts = {
                    lat: latDeg.deg + '° ' + latDeg.min.toPrecision(5).toString().replace('.', ','),
                    lng: lngDeg.deg + '° ' + lngDeg.min.toPrecision(5).toString().replace('.', ',')
                }
                $rootScope.$broadcast('updateCoordinates', latLngTexts);
            }
        }

        function onClick(evt) {
            executeCallbacks('click', evt);
        }

        function convertScreenPointToLatLng(evt) {
            var screenPoint = { x: evt.clientX, y: evt.clientY };
            var coords = mapService._mapView.toMap(screenPoint);
            var latlng = mapUtility.convertToWGS84(coords.x, coords.y);
            return latlng;
        }

        function onRightClick(box) {
            var screenPoint = getScreenPointFromMenuPosition(mapService._mapView, box);
            var evt = {
                offsetX: screenPoint.x,
                offsetY: screenPoint.y
            };

            var hitTestPromise = mapService._mapView.hitTest(screenPoint);
            if (hitTestPromise) {
                hitTestPromise.then(function (response) {
                    if (response.results && response.results.length > 0) {
                        //TODO: get the graphic with the highest z coordinate
                        executeCallbacks('rightclick', evt, mapService._mapView, response);
                    } else {
                        executeCallbacks('rightclick', evt, mapService._mapView);
                    }
                });
            }
        }

        function onZoom(evt) {
            executeCallbacks('zoom', evt);
        }

        function onCenter(evt) {
            executeCallbacks('center', evt);
        }

        var map = new Map();
        mapService.layers = [];

        mapService.createMap = function (mapId) {
            if (mapCreatedDeferred === null) {
                mapCreatedDeferred = $q.defer();
            }

            if (mapService._targetElement && mapService._mapView) {
                $('#' + mapId).replaceWith(mapService._targetElement);

                return;
            }

            if (!mapService._initialPosition || !mapService._initialZoom) {
                mapService.setInitialPosition([15, 63], 4);
            }

            mapService._targetElement = $('#' + mapId);
            mapService._mapView = new MapView({
                container: mapId,
                map: map,
                zoom: mapService._initialZoom,
                center: mapService._initialPosition,
                constraints: {
                    rotationEnabled: false
                }
            });

            mapService._mapView.watch('updating', function (event) {
                $rootScope.$on('searchLocation', function (e, data) {
                    if (!message) {
                        message = data.message;
                    }
                })
                if (event) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'initMap',
                        destination: '#main-view',
                        message: message || 'Henter kart',
                        overlay: false,
                        positionClass: 'bottom-left-inline'
                    });
                } else {
                    message = null;
                    $rootScope.$broadcast('hideBusyIndicator', 'initMap');
                }
            });

            mapService._mapView.then(function () {
                mapCreatedDeferred.resolve();
            });

            //Set mapView for services
            for (var i = 0; i < mapService._services.length; ++i) {
                mapService._services[i].ready().then(function (service) {
                    service.mapView = mapService._mapView;
                });
            }

            on(mapService._mapView.container, 'mousemove', onMouseMove);
            on(mapService._mapView.container, 'click', onClick);
            on(mapService._mapView.container, 'mousedown', onMouseDown);
            on(mapService._mapView.container, 'mouseup', onMouseUp);
            mapService._mapView.watch('zoom', onZoom);
            mapService._mapView.watch('center', onCenter);

            mapService.ctxMenu = new Menu({ onOpen: onRightClick });
            mapService.ctxMenu.startup();
            mapService.ctxMenu.bindDomNode(mapService._mapView.container);

            executeCallbacks('mapview-create', null, mapService._mapView);
        };

        mapService.isInitialized = function () {
            return mapService.layers.length !== 0 || mapService._services.length !== 0;
        };

        mapService.reInitialize = function () {
            mapService.layers = [];
            map.layers.removeAll();

            for (var i = 0; i < mapService._factories.length; ++i) {
                map.layers.add(mapService._factories[i].createLayer());
            }
        }

        mapService.addLayerFactory = function (factories) {
            for (var i = 0; i < factories.length; ++i) {
                if(factories[i]){
                    factories[i].ready().then(function (layerFactory) {
                        if (layerFactory.createLayerOnAdd) {
                            var layer = layerFactory.createLayer();

                            if (layer.onMouseMove) {
                                callbacks.mousemove.push(layer.onMouseMove);
                            }

                            if (layer.onRightClick) {
                                callbacks.rightclick.push(layer.onRightClick);
                            }

                            mapService._factories.push(layerFactory);
                            mapService.layers.push(layer);
                            if (layer.zIndex) {
                                map.add(layer, layer.zIndex);
                            } else {
                                map.add(layer);
                            }
                        }
                    });
                }
            }
        }

        mapService.addLayerService = function (service) {
            service.ready().then(function (service) {
                service.setup(mapService);

                if (service.onMouseMove) {
                    callbacks.mousemove.push(service.onMouseMove);
                }

                mapService._services.push(service);
            });
        }

        mapService.getLayer = function (layerName) {
            for (var i = 0; i < mapService.layers.length; ++i) {
                if (mapService.layers[i].name === layerName) {
                    return mapService.layers[i];
                }
            }

            return null;
        };

        mapService.toggleLayerVisibility = function (layerName, layerType) {
            var layer = mapService.getLayer(layerName);

            if (layer && layer.toggleVisibility) {
                layer.toggleVisibility();
            }

            executeCallbacks('visibility-toggle', { layerName: layerName, layerType: layerType });
        }

        /**
         * Registers a callback function for a specific event.
         * @param {} eventName
         * @param {} callback
         * @returns {}
         */
        mapService.on = function (eventName, callback) {
            if (callbacks[eventName]) {
                callbacks[eventName].push(callback);
            }
        }

        /**
         * Zooms to a given screenPoint.
         * @param {} point
         * @returns {}
         */
        mapService.zoomTo = function (point) {
            var screenPoint = new ScreenPoint(point.x, point.y);
            var locationPoint = mapService._mapView.toMap(screenPoint);

            mapService._mapView.center = locationPoint;
            mapService._mapView.zoom = 13;
        }

        /**
         * Zooms to a given point
         * @param {} point
         * @returns {}
         */
        mapService.zoomToLocation = function (point, zoomLevel) {
            var isNotDefaultLocation = $stateParams.LocationNumber ? !locationService.isDefaultLocation($stateParams.LocationNumber) : true;
            if(isNotDefaultLocation){
                mapService._mapView.center = point;
                mapService._mapView.zoom = zoomLevel || 13;
            }
        }


        /**
        Sets the view to a given target. The target parameter can be one of the following:
            -[longitude, latitude] pair of coordinates
            -Geometry (or array of Geometry[])
            -Graphic (or array of Graphic[])
            -Viewpoint
            -Object with a combination of target, center and scale properties (with target being any of the types listed above). The center property is provided as a convenience to animate the MapView.center and is equivalent to specifying the target with the center Point.
        */
        mapService.goTo = function (target, options) {
            mapService._mapView.goTo(target);
        }
        readyDeferred.resolve();
    });

    return mapService;
}]);

