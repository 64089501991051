(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('userDetailsModalController', userDetailsModalController);

    userDetailsModalController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        '$translate',
        'roleService',
        'userService',
        'contractors',
        'user',
        'currentFormType',
        'formTypes',
        'dateUtility',
        'authService',
        'jobTitleService'
    ];

    function userDetailsModalController(
        $rootScope,
        $scope,
        $windowInstance,
        $translate,
        roleService,
        userService,
        contractors,
        user,
        currentFormType,
        formTypes,
        dateUtility,
        authService,
        jobTitleService
    ) {
        $scope.isContractorDropdownVisible = $rootScope.authData.isSuperuser && currentFormType === formTypes.add;
        $scope.user = {
            userName: '',
            displayName: ''
        };
        $scope.selectedContractor = (currentFormType === formTypes.add ? $rootScope.authData.contractorId : user.contractorId);
        $scope.validTo = null;

        $scope.allRoles = [];
        $scope.userRoles = [];
        $scope.lineManagers = [];
        $scope.jobTitles = [];
        $scope.canSelectJobTitle = $rootScope.authData.isSuperuser || hasUserRole() ? true : false;
        $scope.contractorDropdownOptions = {
            dataSource: {
                data: contractors
            },
            change: function () {
                $('#line-manager').data('kendoDropDownList').dataSource.read();
            },
            enable: !!contractors.length,
            filter: "contains",
            dataValueField: 'contractorId',
            dataTextField: 'name'
        };

        $scope.rolesDropdownOptions = {
            dataSource: {
                transport: {
                    read: readRoles
                }
            },
            dataValueField: 'id',
            dataTextField: 'key',
            optionLabel: $translate.instant('ADMIN_USER_SELECTROLE')
        };

        $scope.lineManagerDropdownOptions = {
            dataSource: {
                transport: {
                    read: readLineManagers,
                    cache: false
                }
            },
            dataValueField: 'userId',
            dataTextField: 'displayName',
            optionLabel: $translate.instant('ADMIN_USER_SELECT_LINE_MANAGER'),
            filter: 'contains'
        };

        $scope.jobTitlesOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readJobTitles,
                },
            }),
            filter: 'contains',
            dataValueField: 'sequenceNumber',
            dataTextField: 'name',
        };

        $scope.addRole = addRole;
        $scope.removeRole = removeRole;
        $scope.close = close;
        $scope.validateForm = validateForm;

        initController();
        function initController() {
            if (currentFormType === formTypes.edit && user) {
                $scope.user = user;
                $scope.validTo = user.validTo ? new Date(user.validTo) : null;

                roleService.getRolesByUserId($scope.user.userId).then(roles => $scope.userRoles = _.orderBy(roles, 'key'));

                if (!($rootScope.authData.isSuperuser || authService.hasRole('TimebankAdministrator')) && authService.hasRole('TimebankLineManager')) {
                    $('#line-manager').data("kendoDropDownList").readonly();
                }

                if (!$scope.canSelectJobTitle) {
                    $('#job-title').data("kendoDropDownList").readonly();
                }
            }
        }

        function hasUserRole() {
            var canAssignRoles = false;
            $rootScope.authData.roles.forEach(role => {
                role.permissions.forEach(permission => {
                    if (permission.key == "UserAdd" || permission.key == "UserEdit") {
                        canAssignRoles = true;
                    }
                });
            });

            return canAssignRoles;
        }

        function addRole() {
            if ($scope.selectedRole) {
                let hasRole = !!$scope.userRoles.find(r => r.id === $scope.selectedRole);
                if (!hasRole) {
                    let relatedRole = $scope.allRoles.find(r => r.id === $scope.selectedRole);
                    $scope.userRoles.push(relatedRole);

                    $scope.userRoles = _.orderBy($scope.userRoles, 'key');
                }
            }
        }

        function removeRole(userRole) {
            if (userRole) {
                let roleIndex = $scope.userRoles.findIndex(r => r.id === userRole.id);
                if (roleIndex >= 0) {
                    $scope.userRoles.splice(roleIndex, 1);
                }
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                if ($rootScope.authData.isSuperuser && $scope.selectedContractor) {
                    $scope.user.contractorId = $scope.selectedContractor;
                }
                if ($scope.validTo) {
                    $scope.user.validTo = dateUtility.toIsoString($scope.validTo);
                }

                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'userDetailsModalIndicator',
                    destination: '#user-details-form',
                    overlay: true
                });
                var user = $scope.user;
                user.roles = $scope.userRoles;

                switch (currentFormType) {
                    case formTypes.add:
                        userService
                            .addUser($scope.user)
                            .then(userId => close({ ...$scope.user, userId, validFrom: new Date() }));
                        break;
                    case formTypes.edit:
                        userService
                            .updateUser($scope.user)
                            .then(user => close(user));
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'userDetailsModalIndicator');

            $windowInstance.close(param);
        }

        //Private functions
        function readRoles(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'loadRoles',
                destination: '#user-details-form',
                overlay: true
            });

            var contractorId = user ? user.contractorId : $rootScope.authData.contractorId;
            userService.getRolesByContractorId(contractorId)
                .then(
                    (roles) => {
                        $scope.allRoles = _.orderBy(roles, 'key');
                        e.success($scope.allRoles);
                    },
                    () => e.success([]))
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'loadRoles'));
        }

        function readLineManagers(e) {
            if ($scope.selectedContractor) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'loadLineManagers',
                    destination: '#user-details-form',
                    overlay: true
                });

                userService.getLineManagersByContractorId($scope.selectedContractor).then(
                    (lineManagers) => {
                        $scope.lineManagers = _.orderBy(lineManagers, 'displayName');
                        e.success($scope.lineManagers);
                    },
                    () => e.success([]))
                    .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'loadLineManagers'));
            } else {
                e.success([]);
            }
        }

        function readJobTitles(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'loadJobTitles',
                destination: '#user-details-form',
                overlay: true
            });

            jobTitleService.getJobTitlesbyContractorId($rootScope.authData.contractorId)
                .then(
                    jobTitles => {
                        $scope.jobTitles = jobTitles.sort((a, b) => {
                            return a.sequenceNumber.toString().localeCompare(b.sequenceNumber.toString());
                        });
                        e.success($scope.jobTitles);
                    },
                    () => e.success([]))
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'loadJobTitles'));
        }
    }
})();
