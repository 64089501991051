(function () {
	'use strict';

	angular.module('NaviaqWebApp').factory('mapUtility', mapUtility);

	mapUtility.$inject = [];

	var utm33N = '+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs',
		wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

	function mapUtility() {
		var service = {
			convertToUTM33N: convertToUTM33N,
			convertToWGS84: convertToWGS84,
            convertToUTM33NFromWGS84: convertToUTM33NFromWGS84,
			getCenterFromDegrees: getCenterFromDegrees,
			distanceToLineSegment: distanceToLineSegment,
			convertDecDegreesToDegreesMins: convertDecDegreesToDegreesMins,
			convertDegreesMinsToDecDegrees: convertDegreesMinsToDecDegrees,
            convertToDDM: convertToDDM,
            getImageBasedOnDescription:getImageBasedOnDescription,
		};

		return service;

		function convertDecDegreesToDegreesMins(decDeg) {
			var numDeg = Number(decDeg);
			if (numDeg) {
				var deg = parseInt(numDeg);
				var min = (decDeg - deg) * 60;
				return {
					deg,
					min
				}
			} else {
				return { deg: 0, min: 0 };
			}

		}

		function convertDegreesMinsToDecDegrees(degrees, minutes) {
			degrees = Number(degrees);
			minutes = Number(minutes);
			return degrees + minutes / 60;
		}

        function convertToUTM33NFromWGS84(lat, lng) {
            var projectedCoords = proj4(wgs84, utm33N, [lng, lat]);
            return {
                latitude: projectedCoords[0],
                longitude: projectedCoords[1]
            };
        }

		function convertToUTM33N(lat, lng) {
			var projectedCoords = proj4(utm33N, [lng, lat]);
			return {
				X: projectedCoords[0],
				Y: projectedCoords[1]
			};
		}

		function convertToWGS84(x, y) {
			var projectedCoords = proj4(utm33N, wgs84, [x, y]);

			return {
				lat: projectedCoords[1],
				lng: projectedCoords[0]
			}
		}

        /**
        * Get a center latitude,longitude from an array of like geopoints
        *
        * @param array data 2 dimensional array of latitudes and longitudes
        * For Example:
        * $data = array
        * (
        *   0 = > array(45.849382, 76.322333),
        *   1 = > array(45.843543, 75.324143),
        *   2 = > array(45.765744, 76.543223),
        *   3 = > array(45.784234, 74.542335)
        * );
        */
		function getCenterFromDegrees(data) {
			if (!(data.length > 0)) {
				return false;
			}

			var num_coords = data.length;

			var X = 0.0;
			var Y = 0.0;
			var Z = 0.0;

			for (var i = 0; i < data.length; i++) {
				var lat = data[i][0] * Math.PI / 180;
				var lon = data[i][1] * Math.PI / 180;

				var a = Math.cos(lat) * Math.cos(lon);
				var b = Math.cos(lat) * Math.sin(lon);
				var c = Math.sin(lat);

				X += a;
				Y += b;
				Z += c;
			}

			X /= num_coords;
			Y /= num_coords;
			Z /= num_coords;

			var lon = Math.atan2(Y, X);
			var hyp = Math.sqrt(X * X + Y * Y);
			var lat = Math.atan2(Z, hyp);

			var newX = (lat * 180 / Math.PI);
			var newY = (lon * 180 / Math.PI);

			return new Array(newX, newY);
		}

		function distanceToLineSegment(lx1, ly1, lx2, ly2, px, py) {
			return Math.sqrt(distanceSquaredToLineSegment(lx1, ly1, lx2, ly2, px, py));
		}

		function distanceSquaredToLineSegment(lx1, ly1, lx2, ly2, px, py) {
			var ldx = lx2 - lx1,
				ldy = ly2 - ly1,
				lineLengthSquared = ldx * ldx + ldy * ldy;
			return distanceSquaredToLineSegment2(lx1, ly1, ldx, ldy, lineLengthSquared, px, py);
		}

		function distanceSquaredToLineSegment2(lx1, ly1, ldx, ldy, lineLengthSquared, px, py) {
			var t; // t===0 at line pt 1 and t ===1 at line pt 2
			if (!lineLengthSquared) {
				// 0-length line segment. Any t will return same result
				t = 0;
			}
			else {
				t = ((px - lx1) * ldx + (py - ly1) * ldy) / lineLengthSquared;

				if (t < 0)
					t = 0;
				else if (t > 1)
					t = 1;
			}

			var lx = lx1 + t * ldx,
				ly = ly1 + t * ldy,
				dx = px - lx,
				dy = py - ly;
			return dx * dx + dy * dy;
		}

        function convertToDDM(coord) {
            var absCoord = Math.abs(coord);
            var degrees = truncate(absCoord);
            var values = ((absCoord - degrees) * 60).toFixed(4).toString().replace(',', '.').split('.');
            var minutes = values[0] ? Number(values[0]) : 0
            var seconds = values[1] ? Number(values[1]) : 0

            return {
                degrees: coord < 0 ? -1 * degrees : degrees,
                minutes: minutes,
                seconds: seconds
            };
        }

        function truncate(n) {
            return n > 0 ? Math.floor(n) : Math.ceil(n);
        }

        function getImageBasedOnDescription(symbolDescription) {
            var lowercaseDescription = symbolDescription.toLowerCase();
            switch (lowercaseDescription) {
                case 'currentpositionactive':
                    return '/img/map/map-marker.png';
                case 'currentpositioninactive':
                    return '/img/map/-marker-grayscale.png';
                case 'anker':
                    return '/img/map/Anker.png';
                case 'blåkryss':
                    return '/img/map/Blaakryss.png';
                case 'blåramme':
                    return '/img/map/Blaaramme.png';
                case 'brunsirkel':
                    return '/img/map/Brunsirkel.png';
                case 'enfisk':
                    return '/img/map/Enfisk.png';
                case 'fiskestim':
                    return '/img/map/Fiskestim.png';
                case 'flyvrak':
                    return '/img/map/Flyvrak.png';
                case 'garnstart':
                    return '/img/map/Garnstart.png';
                case 'garnstopp':
                    return '/img/map/Garnstopp.png';
                case 'grønnkryss':
                    return '/img/map/Gronnkryss.png';
                case 'grønnpyramide':
                    return '/img/map/Gronnpyramide.png';
                case 'grønnramme':
                    return '/img/map/Gronnramme.png';
                case 'gulfare':
                    return '/img/map/Gulfare.png';
                case 'gulkryss':
                    return '/img/map/Gulkryss.png';
                case 'gulpyramide':
                    return '/img/map/Gulpyramide.png';
                case 'gulramme':
                    return '/img/map/Gulramme.png';
                case 'kryss':
                    return '/img/map/Kryss.png';
                case 'notmerke':
                    return '/img/map/Notmerke.png';
                case 'punkt':
                case 'point':
                    return '/img/map/Punkt.png';
                case 'pyramide':
                    return '/img/map/Pyramide.png';
                case 'rødpyramide':
                    return '/img/map/Rodpyramide.png';
                case 'rødramme':
                    return '/img/map/Rodramme.png';
                case 'spørsmål':
                    return '/img/map/Sporsmaal.png';
                case 'vrak1':
                    return '/img/map/Vrak1.png';
                case 'vrak2':
                    return '/img/map/Vrak2.png';
                default:
                    return '/img/map/poi_black.png';
            }
        }
	}
})();
