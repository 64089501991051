(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ServiceFormsEditorController', ServiceFormsEditorController);

    ServiceFormsEditorController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        'serviceFormService',
        'formTypes',
        'kendoUtility',
        '$kWindow'
    ];

    function ServiceFormsEditorController(
        $q,
        $scope,
        $rootScope,
        $translate,
        serviceFormService,
        formTypes,
        kendoUtility,
        $kWindow
    ) {
        $scope.serviceForms = [];
        $scope.addNewServiceForm = addNewServiceForm;
        $scope.openOptionsModal= openOptionsModal;

        const serviceFormsGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.serviceForms)
                }
            }),
            columns: [
                {
                    field: 'ServiceFormId',
                    hidden: true
                },
                {
                    field: 'logActionDescription',
                    title: $translate.instant('SERVICEFORM_EDITOR_ACTION_DESCRIPTION', null, null, langId)
                },
                {
                    command: [
                        {
                            name: 'editServiceForm',
                            click: e => editServiceForm(e),
                            template: '<a class="k-grid-editServiceForm"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteServiceForm',
                            click: e => deleteServiceForm(e),
                            template: '<a class="k-grid-deleteServiceForm"><i class="fas fa-trash-alt"></i></a>'
                        }
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.serviceFormsGridOptions = serviceFormsGridOptions($translate.use());

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'serviceFormBusyIndicator',
                destination: '#service-forms'
            });

            $q.all([serviceFormService.getServiceFormTemplates($rootScope.authData.contractorId)]).then(function(results){
                $scope.serviceForms = results[0];
                extendServiceForms();
                $scope.serviceFormsGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'serviceFormBusyIndicator');
            });

        }

        function extendServiceForms(){
            $scope.serviceForms.forEach(function (serviceForm) {
                serviceForm.logActionDescription = serviceForm.logAction.description;
            });
        }

        function editServiceForm(event) {
            const serviceForm = getServiceFromGrid(event);
            openServiceFormsDetailsModal(formTypes.edit, serviceForm);
        }

        function deleteServiceForm(event) {
            const serviceForm = getServiceFromGrid(event);
            confirmDeleteDialog(serviceForm.serviceFormId);
        }

        function confirmDeleteDialog(serviceFormId) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('SERVICEFORM_EDITOR_CONFIRM_MSG');
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                    serviceFormService.deleteServiceForm(serviceFormId).then(function(){
                        initController();
                    });
                }
            });
        }

        function getServiceFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#service-forms-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function openServiceFormsDetailsModal(formType, serviceForm) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('SERVICEFORM_EDITOR_TITLE'),
                    resizable: true,
                    visible: false,
                    width: 800
                },
                templateUrl: 'app/serviceForms/service-forms-editor-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ServiceFormsEditorDetailsController',
                resolve: {
                    formType: () => formType == 'edit' ? formType : formTypes.add,
                    serviceForm: () => serviceForm
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateServiceFormGrid();
                }
            });
        }

        function openServiceFormsOptionsModal(formType, serviceForm) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('SERVICEFORM_EDITOR_OPTIONS_TITLE'),
                    resizable: true,
                    visible: false,
                    width: 1000,
                    height: 500
                },
                templateUrl: 'app/serviceForms/service-forms-options-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ServiceFormsOptionsController',
                resolve: {
                }
            });

            windowInstance.result.then(function (result) {
            });
        }

        function updateServiceFormGrid(){
            initController();
        }

        function addNewServiceForm(){
            openServiceFormsDetailsModal();
        }

        function openOptionsModal(){
            openServiceFormsOptionsModal();
        }
    }
})();
