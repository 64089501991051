(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('delicingKombiReportDirective', delicingKombiReportDirective);

    delicingKombiReportDirective.$inject = [];

    function delicingKombiReportDirective() {
        return {
            controller: [
                '$scope',
                '$q',
                '$rootScope',
                '$stateParams',
                '$translate',
                'anonymousReportService',
                delicingKombiReportDirective
            ],
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: 'app/report/delicingReport/delicing-kombi-report.html'
        };

        function delicingKombiReportDirective(
            $scope,
            $q,
            $rootScope,
            $stateParams,
            $translate,
            anonymousReportService,
        ) {
            $scope.orderId = $stateParams.OrderId;

            $rootScope.$on('delicingKombiReportRequested', function () {
                initController();
            });

            function initController(){
                var rquests = {
                    getDelicingReportContent: anonymousReportService.getDelicingReportContent($scope.orderId, 'Kombi'),
                }

                $q.all(rquests).then(function(results){
                    const reportContent = results.getDelicingReportContent;
                    $("#delicing-kombi-grid").kendoGrid({
                        toolbar: ["excel"],
                        excel: {
                        allPages: true
                        },
                        dataSource: {
                        transport: {
                            read: e => e.success([]),
                        },
                        },
                        excelExport: function(e) {
                            var workbook = e.workbook;

                            e.workbook.fileName = $translate.instant('REPORT_DELICING_COMBI_REPORT');

                            var sheet = workbook.sheets[0];
                            sheet.columns = [];
                            for (let index = 0; index < 20; index++) {
                                sheet.columns.push({
                                    autoWidth: true
                                });
                            }

                            sheet.rows = sheet.rows.concat(getTitleRow());
                            sheet.rows = sheet.rows.concat(getOrderRelatedDataRows(reportContent.order));
                            sheet.rows = sheet.rows.concat(getEmptyRow());
                            sheet.rows = sheet.rows.concat(getServiceFormDataRows(reportContent.serviceFormData, mapServiceFormOptionList(reportContent.serviceFormOptions)));
                            sheet.rows = sheet.rows.concat(getEmptyRow());
                            sheet.rows = sheet.rows.concat(getCommentRow(reportContent.skipperMessagesOperationLog));
                        },
                    });
                    $("#delicing-kombi-grid").data("kendoGrid").saveAsExcel();
                });
            }

            function createCell(value, color, background, vAlign = null, hAlign = null, bold = null, colSpan = null, fontSize =  null,  underline = null){
                return {
                    colSpan: colSpan ? colSpan : 1,
                    rowSpan: 1,
                    bold: bold ? bold : false,
                    underline: underline ? underline : false,
                    background: background,
                    color: color,
                    fontSize: fontSize ? fontSize : 14,
                    vAlign: vAlign ? vAlign : "center",
                    hAlign: hAlign ? hAlign : "center",
                    value: value,
                }
            }

            function getTitleRow(){
                var row = {
                    cells: [],
                    type: "data"
                };

                row.cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_TITLE'), "#0d5572", "#fff", "center", "center", true, 9, 18, true));

                return row;
            }

            function getOrderRelatedDataRows(order){
                var row = {
                    cells: [],
                    type: "data"
                };

                row.cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_CUSTOMER'), "#000", "#bfbfbf", "center", "left", true));
                row.cells.push(createCell(order.customer ? order.customer.name : '', "#000", "#bfbfbf", "center", "center", false, 2));
                row.cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_ORDER_START_DATE'), "#000", "#bfbfbf", "center", "left", true));
                row.cells.push(createCell(moment(order.orderDate).format('DD.MM.YYYY HH:mm'), "#000", "#bfbfbf", "center", "center", false, 2));
                row.cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_LOCATION'), "#000", "#bfbfbf", "center", "left", true));
                row.cells.push(createCell(order.location ? order.location.name : '', "#000", "#bfbfbf", "center", "center", false, 2));

                return row;
            }

            function getEmptyRow(){
                var row = {
                    cells: [],
                    type: "data"
                };

                return row;
            }

            function getServiceFormDataRows(serviceFormData, optionsLists){
                var rows = [];

                var fieldCount = 21;
                for (let index = 0; index < fieldCount; index++) {
                    rows.push({
                        cells: [],
                        type: "data"
                    });
                }

                //titles
                rows[0].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_CAGE_NUMBER'), "#000", "#d1eef9", "center", "left", true));
                rows[1].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_RING_SIZE'), "#000", "#bfbfbf", "center", "left", true));
                rows[2].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_DATE'), "#000", "#d1eef9", "center", "left", true));
                rows[3].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_BIOMASS'), "#000", "#bfbfbf", "center", "left", true));
                rows[4].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_AVERAGE_WEIGHT'), "#000", "#d1eef9", "center", "left", true));
                rows[5].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_NUMBER'), "#000", "#bfbfbf", "center", "left", true));
                rows[6].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_TEMPERATURE'), "#000", "#d1eef9", "center", "left", true));
                rows[7].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_AIM_DEPTH'), "#000", "#bfbfbf", "center", "left", true));
                rows[8].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_TYPE_OF_TARP'), "#000", "#d1eef9", "center", "left", true));
                rows[9].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_PRESUMED_VOLUME'), "#000", "#bfbfbf", "center", "left", true));
                rows[10].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_START_DOSE'), "#000", "#d1eef9", "center", "left", true));
                rows[11].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_END_DOSE'), "#000", "#bfbfbf", "center", "left", true));
                rows[12].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_TARP_DROPPED'), "#000", "#d1eef9", "center", "left", true));
                rows[13].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_O2_RAW_SEA'), "#000", "#bfbfbf", "center", "left", true));
                rows[14].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_O2_MIN'), "#000", "#d1eef9", "center", "left", true));
                rows[15].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_O2_MAX'), "#000", "#bfbfbf", "center", "left", true));
                rows[16].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_HOLDING_TIME'), "#000", "#d1eef9", "center", "left", true));
                rows[17].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_SALMOSAN_LITERS'), "#000", "#bfbfbf", "center", "left", true));
                rows[18].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_ALPHAMAX_LITERS'), "#000", "#d1eef9", "center", "left", true));
                rows[19].cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_DEATHS'), "#000", "#bfbfbf", "center", "left", true));

                serviceFormData = serviceFormData.map(x => ({...x, parsedData: JSON.parse(x.data)}));
                serviceFormData = _.orderBy(serviceFormData, 'parsedData.summary2_date');

                serviceFormData.forEach(data => {
                    rows[0].cells.push(createCell(data.operationLog && data.operationLog.cages && data.operationLog.cages.length ? data.operationLog.cages[0].cageNumber : '', "#000", "#d1eef9"));
                    var ringCircumference = data.operationLog && data.operationLog.cages && data.operationLog.cages.length && data.operationLog.cages[0].ring
                        && data.operationLog.cages[0].ring && data.operationLog.cages[0].ring.radius ?
                            data.operationLog.cages[0].ring.radius * 2 * Math.PI :
                            '';

                    rows[1].cells.push(createCell(ringCircumference, "#000", "#bfbfbf"));
                    rows[2].cells.push(createCell(moment(data.parsedData.summary2_date).format('DD.MM.YYYY HH:mm'), "#000", "#d1eef9"));
                    rows[3].cells.push(createCell(data.parsedData.summary2_biomasstonnes, "#000", "#bfbfbf"));
                    rows[4].cells.push(createCell(data.parsedData.summary2_avgweight, "#000", "#d1eef9"));
                    rows[5].cells.push(createCell(data.parsedData.summary2_amountfish, "#000", "#bfbfbf"));
                    rows[6].cells.push(createCell(data.parsedData.summary2_temp, "#000", "#d1eef9"));
                    rows[7].cells.push(createCell(data.parsedData.summary2_aimdepth, "#000", "#bfbfbf"));
                    var tarpTypeOptions = optionsLists.find((oL) => oL.name == 'tarpType');
                    var selectedtarpType = '';
                    if(tarpTypeOptions && tarpTypeOptions.options){
                        var option = tarpTypeOptions.options.find((x) => x.value === data.parsedData.summary2_typeoftarp);
                        if(option && option.name){
                            selectedtarpType = option.name;
                        }
                    }
                    rows[8].cells.push(createCell(selectedtarpType, "#000", "#d1eef9"));
                    rows[9].cells.push(createCell(data.parsedData.summary2_presumedvolume, "#000", "#bfbfbf"));
                    rows[10].cells.push(createCell(moment(data.parsedData.summary2_startdose).format('HH:mm'), "#000", "#d1eef9"));
                    rows[11].cells.push(createCell(moment(data.parsedData.summary2_enddose).format('HH:mm'), "#000", "#bfbfbf"));
                    rows[12].cells.push(createCell(moment(data.parsedData.summary2_tarpdropped).format('HH:mm'), "#000", "#d1eef9"));
                    rows[13].cells.push(createCell(data.parsedData.summary2_o2rawsea, "#000", "#bfbfbf"));
                    rows[14].cells.push(createCell(data.parsedData.summary2_o2min, "#000", "#d1eef9"));
                    rows[15].cells.push(createCell(data.parsedData.summary2_o2max, "#000", "#bfbfbf"));
                    var holdingTime = moment(data.parsedData.summary2_tarpdropped).diff(moment(data.parsedData.summary2_enddose), 'minutes')
                    rows[16].cells.push(createCell(holdingTime, "#000", "#d1eef9"));
                    rows[17].cells.push(createCell(data.parsedData.summary2_salmosanliters, "#000", "#bfbfbf"));
                    rows[18].cells.push(createCell(data.parsedData.summary2_alphamaxliters, "#000", "#d1eef9"));
                    rows[19].cells.push(createCell(data.parsedData.summary2_deaths, "#000", "#bfbfbf"));
                });

                return rows;
            }

            function getCommentRow(skipperMessages){
                var row = {
                    cells: [],
                    type: "data"
                };

                row.cells.push(createCell($translate.instant('DELICING_FORM_KOMBI_COMMENT'), "#000", "#fff", "center", "left", true));
                row.cells.push(createCell(skipperMessages, "#000", "#fff", "center", "center", false, 8));

                return row;
            }

            function mapServiceFormOptionList(options)
            {
                return options.map(o => ({
                    id:o.serviceFormOptionsId,
                    name:o.name,
                    options:JSON.parse(o.options)
                }));
            }
        }
    }
})();
