(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('TimeBankController', TimeBankController);

    TimeBankController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$translate',
        '$state',
        'departmentService',
        'userService',
        'localStorageService',
        'authService',
        'operationLogStatus'
    ];

    function TimeBankController(
        $rootScope,
        $scope,
        $q,
        $translate,
        $state,
        departmentService,
        userService,
        localStorageService,
        authService,
        operationLogStatus
    ) {
        $scope.setFilter = setFilter;
        $scope.vessels = [];
        $scope.users = [];
        $scope.usersSource = [];
        $scope.includeInactiveUsers = false;
        $scope.includeInactiveDepartments = false;
        $scope.getUsers = getUsers;
        $scope.getDepartments = getDepartments;

        $scope.$watch('dateFrom', function() {
            var filter = getFilter();
            $rootScope.$broadcast('timeBankFilterChanged', filter, false);
        });

        $scope.$watch('dateTo', function() {
            var filter = getFilter();
            $rootScope.$broadcast('timeBankFilterChanged', filter, false);
        });

        $scope.filterFromDateOptions = {
            change: function(){
                var validator = $('#timebank-filter-form').data("kendoValidator");
                if (!validator.validate()) {
                        return;
                }
            },
            min: new Date(1970, 1 - 1, 1),
        };

        $scope.filterToDateOptions = {
            change: function(){
                var validator = $('#timebank-filter-form').data("kendoValidator");
                if (!validator.validate()) {
                        return;
                }
            },
            min: new Date(1970, 1 - 1, 1),
        };

        // Tab
        $scope.tabStripOptions = {
            animation: false,
            select: function (e) {
                switch (e.item.id) {
                    case 'logs-tab':
                        $scope.users;
                        $state.go('timeBank.logs');
                        setFilter('logs');
                        break;
                    case 'summary-tab':
                        $scope.users;
                        $state.go('timeBank.summary');
                        setFilter('summary');
                        break;
                    case 'user-balance-tab':
                        $scope.users;
                        $state.go('timeBank.userBalance');
                        setFilter('userBalance');
                        break;
                    case 'balance-tab':
                        $scope.users;
                        $state.go('timeBank.balance');
                        setFilter('balance');
                        break;
                    case 'sailing-time-tab':
                        $scope.users;
                        $state.go('timeBank.sailingTime');
                        setFilter('sailingTime');
                        break;
                    default:
                        break;
                }
            },
        };

        $scope.tabsVisibility = {
            logs: true,
            summary: Boolean($rootScope.authData.isSuperuser || $scope.hasPermission('TimebankAdmin')),
            userBalance: true,
            balance: true,
            sailingTime: true
        };
        $scope.visibleTabIndex = 0;
        $scope.setVisibleTab = setVisibleTab;

        initController();
        function initController() {
            setDatePickersDefaultValue();
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'timeBankBusyIndicator',
                destination: '#timebank-overview',
                overlay: true,
            });

            $scope.$watch('timeBankTab', function () {
                switch ($state.current.name) {
                    case 'timeBank.logs':
                        $scope.timeBankTab.select(0);
                        $scope.visibleTabIndex = 0;
                        break;
                    case 'timeBank.summary':
                        $scope.timeBankTab.select(1);
                        $scope.visibleTabIndex = 1;
                        break;
                    case 'timeBank.userBalance':
                        $scope.timeBankTab.select(2);
                        $scope.visibleTabIndex = 2;
                        break;
                    case 'timeBank.balance':
                        $scope.timeBankTab.select(3);
                        $scope.visibleTabIndex = 3;
                        break;
                    case 'timeBank.sailingTime':
                        $scope.timeBankTab.select(4);
                        $scope.visibleTabIndex = 4;
                        break;
                    default:
                        $scope.timeBankTab.select(0);
                        $scope.visibleTabIndex = 0;
                        break;
                }
            });

            $q.all([
                getDepartments(true),
                getUsers(true)
            ]).then(function () {
                initFilter();
                initValidator();
                $rootScope.$broadcast('hideBusyIndicator', 'timeBankBusyIndicator');
            });
        }

        function initValidator(){
            $scope.validator = $("#timebank-filter-form");
                        $scope.validator.kendoValidator({
                            rules: {
                                date: function(input) {
                                    if(input.is('[name="timebank-date-to"]')){
                                        var stopDate = moment($scope.dateTo, 'DD-MM-YYYY');
                                        var startDate = moment($scope.dateFrom, 'DD-MM-YYYY');
                                        if($scope.dateFrom && $scope.dateTo && startDate.isAfter(stopDate)){
                                            return false;
                                          } else {
                                              return true;
                                          }
                                    } else {
                                        return true;
                                    }
                                },
                                minDate: function(input) {
                                    if(input.is('[name="timebank-date-to"]')){
                                        var stopDate = moment($scope.dateTo, 'DD-MM-YYYY');
                                        var minDate = moment(new Date(1970,1,1));
                                        if($scope.dateTo && minDate.isAfter(stopDate)){
                                            return false;
                                          } else {
                                              return true;
                                          }
                                    } else if(input.is('[name="timebank-date-from"]')){
                                        var startDate = moment($scope.dateFrom, 'DD-MM-YYYY');
                                        var minDate = moment(new Date(1970,1,1));
                                        if($scope.dateTo && minDate.isAfter(startDate)){
                                            return false;
                                          } else {
                                              return true;
                                          }
                                    }
                                    else {
                                        return true;
                                    }
                                }
                            },
                            messages: {
                                minDate: function(input) {
                                    return  $translate.instant('TIMEBANK_MIN_DATE_CHECK');
                                },
                                date: function(input) {
                                    return $translate.instant('TIMEBANK_DATE_CHECK');
                                }
                            }
                        });
        }

        function setDatePickersDefaultValue(){
            var date = new Date();
            var y = date.getFullYear();
            var m = date.getMonth();
            $scope.dateFrom = moment(new Date(y, m, 1)).format('DD.MM.YYYY');
            $scope.dateTo = moment(new Date(y, m + 1, 0)).format('DD.MM.YYYY');
        }

        function initFilter() {
            var filter = getFilter();
            $rootScope.$broadcast('timeBankFilterChanged', filter, true);
        }

        function setVisibleTab(index) {
            $scope.visibleTabIndex = index;
        }

        function setFilter(state = null) {
            var filter = getFilter();
            if (state === null && filter.departmentIds.length === 0) {
                $scope.departmentIsSelected = false;
            } else {
                $scope.departmentIsSelected = true;
                $rootScope.$broadcast('timeBankFilterChanged', filter, true, state);
            }
        }

        function getFilter()
        {
            return {
                contractorId: $rootScope.authData.contractorId,
                contractorIds: [$rootScope.authData.contractorId],
                departmentIds: $scope.vesselFilter && $scope.vesselFilter.length ? _.map($scope.vesselFilter, (o) => {
                    return o.departmentId;
                }) : [],
                userIds: $scope.userFilter && $scope.userFilter.length ? _.map($scope.userFilter, (o) => {
                    return o.userId;
                }) : [],
                fromDate: moment($scope.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00'),
                toDate: moment($scope.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DDT23:59:59'),
                operationLogStatuses: [operationLogStatus.Registered, operationLogStatus.Approved, operationLogStatus.Closed
                    ,operationLogStatus.Rejected, operationLogStatus.Confirmed]
            };
        }

        function getUsers(selectCurrentUser = false) {
            var fromDate = $scope.dateFrom.split('.').reverse().join('.');
            var toDate = $scope.dateTo.split('.').reverse().join('.');
            var promise = userService.getUsersByContractorIdForTimebankWebApi(fromDate, toDate, $scope.includeInactiveUsers).then(function (users) {
                $scope.users = users;
                $scope.usersSource = users;

                if (!(authService.hasRole('TimebankAdministrator') || authService.hasRole('SuperUser') || $rootScope.authData.isSuperuser)) {
                    if (authService.hasRole('TimebankLineManager')) {
                        $scope.users = _.filter($scope.users, u => {
                            return u.lineManagerId == $rootScope.authData.userId || u.userId == $rootScope.authData.userId;
                        });
                        $scope.usersSource = $scope.users;
                    }
                    else if (authService.hasRole('TimebankUser') || $scope.hasPermission('TimebankSkipper')) {
                        $scope.users = _.filter($scope.users, { userId: $rootScope.authData.userId });
                        $scope.usersSource = $scope.users;
                    }
                    else {
                        $scope.users = []
                        $scope.usersSource = $scope.users;
                    }
                }

                if (selectCurrentUser) {
                    var authData = localStorageService.get('authenticationData');
                    var currentUser = $scope.users.find((u) => u.userId == authData.userId);
                    $scope.userFilter = currentUser ? [currentUser]: [] ;
                }
            })
            return promise;
        }

        function getDepartments(selectCurrentDepartment = false)
        {
            return departmentService.getDepartmentsForDropdown($scope.includeInactiveDepartments).then(function (departments) {
                $scope.vessels = departments;
                $scope.filteredVessels = $scope.vessels;

                if(selectCurrentDepartment){
                    var authData = localStorageService.get('authenticationData');
                    var currentVessel = authData.activeDepartment ? $scope.vessels.find((v) => v.departmentId == authData.activeDepartment.departmentId) : null;
                    $scope.vesselFilter = currentVessel ? [currentVessel]: [];
                }
            });
        }
    }
})();
