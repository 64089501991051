(function() {
    angular.module('NaviaqWebApp').factory('userService', userService);

    userService.$inject = ['$q', '$http', 'serviceUrls'];

    function userService($q, $http, serviceUrls) {
        const consentWebApiUrl = `${serviceUrls.webApiBaseUrl}/consent`;
        const userWebApiUrl = `${serviceUrls.webApiBaseUrl}/user`;

        return {
            addConsent,
            addUser,
            getAllUser,
            getConsentByUserId,
            getLatestConsent,
            getLocationAccessInfo,
            getModulesByContractorId,
            getPermissions,
            getRoles,
            getRolesByContractorId,
            getRoleById,
            getUsersByContractorIdWebApi,
            getUsersAsDropdownByFilter,
            getUsersWithPermissionsAsDropdownByFilter,
            getUsersByContractorIdForTimebankWebApi,
            updateUser,
            userHasConsent,
            addRole,
            updateRole,
            deleteRole,
            getLineManagersByContractorId,
            setUserContractorAndDepartment,
            updateUserPreferredLanguage,
            isMicrosoftAuthUser
        };

        function getAllUser() {
            const deferred = $q.defer(),
            requestUrl = `${userWebApiUrl}/allUser`;

            $http.get(requestUrl).then(
                (response) => deferred.resolve(response.data),
                () => deferred.reject()
            );

            return deferred.promise;
        }

        function getModulesByContractorId(contractorId) {
            const deferred = $q.defer();

            $http.get(`${userWebApiUrl}/modules?contractorId=${contractorId}`)
            .then(
                (response) => deferred.resolve(response.data),
                () => deferred.reject()
            );

            return deferred.promise;
        }

        function getLocationAccessInfo(locationNumber, userId) {
            const deferred = $q.defer();

            $http.get(`${userWebApiUrl}/getLocationAccessInfo?locationNumber=${locationNumber}&userId=${userId}`).then(
                function(response) {
                    deferred.resolve(response.data);
                },
                function() {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function addConsent(userId, consent) {
            let deferred = $q.defer();

            $http.post(consentWebApiUrl, {
                userId: userId,
                consentTextId: consent.consentTextId,
                consentVersion: consent.version
            }).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getConsentByUserId(userId) {
            let deferred = $q.defer();

            $http.get(`${consentWebApiUrl}/byUserId?userId=${userId}`).then(
                (response) => deferred.resolve(response.data),
                () => deferred.reject()
            );

            return deferred.promise;
        }

        function getLatestConsent() {
            let deferred = $q.defer();

            $http.get(`${consentWebApiUrl}/latest`).then(
                (response) => deferred.resolve(response.data),
                () => deferred.reject());

            return deferred.promise;
        }

        function userHasConsent(userId) {
            let deferred = $q.defer();

            $http.get(`${consentWebApiUrl}/userHasConsent?userId=${userId}`).then(
                (response) => deferred.resolve(response.data),
                () => deferred.reject());

            return deferred.promise;
        }

        function getUsersByContractorIdWebApi(contractorId, includeConglomerateUsers = false) {
            const deferred = $q.defer();
            let requestUrl = `${userWebApiUrl}?includeConglomerateUsers=${includeConglomerateUsers}`;

            if (contractorId) {
                requestUrl += `&contractorId=${contractorId}`;
            }

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getUsersAsDropdownByFilter(filter) {
            let deferred = $q.defer(),
            requestUrl = `${userWebApiUrl}/asDropdownByFilter`;

            $http.post(requestUrl, filter)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getUsersWithPermissionsAsDropdownByFilter(filter) {
            let deferred = $q.defer(),
            requestUrl = `${userWebApiUrl}/withPermissionsAsDropdownByFilter`;

            $http.post(requestUrl, filter)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getUsersByContractorIdForTimebankWebApi(fromDate, toDate, includeInactiveUsers) {
            const deferred = $q.defer();
            let requestUrl = `${userWebApiUrl}/allForTimebank`;

            if (fromDate && toDate) {
                requestUrl += `?StartDate=${fromDate}&EndDate=${toDate}&IncludeInactive=${includeInactiveUsers}`;
            }

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addUser(user) {
            const deferred = $q.defer(),
                requestUrl = `${userWebApiUrl}`;

            $http
                .post(requestUrl, user)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateUser(user) {
            const deferred = $q.defer(),
                requestUrl = `${userWebApiUrl}`;

            $http
                .put(requestUrl, user)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function setUserContractorAndDepartment(contractorId, departmentId) {
            const deferred = $q.defer();

            $http
                .put(`${userWebApiUrl}/setUserContractorAndDepartment?contractorId=${contractorId}&departmentId=${departmentId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getRoles() {
            const deferred = $q.defer(),
                requestUrl = userWebApiUrl + '/roles';

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getRolesByContractorId(contractorId) {
            const deferred = $q.defer(),
                requestUrl = userWebApiUrl + `/roles/byContractorId?contractorId=${contractorId}`;

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addRole(role) {
            const deferred = $q.defer(),
                requestUrl = userWebApiUrl + '/roles';

            $http
                .post(requestUrl, role)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateRole(role) {
            const deferred = $q.defer(),
                requestUrl = userWebApiUrl + '/roles';

            $http
                .put(requestUrl, role)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteRole(roleId) {
            const deferred = $q.defer(),
                requestUrl = userWebApiUrl + `/roles?roleId=${roleId}`;

            $http
                .delete(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getRoleById(roleId) {
            const deferred = $q.defer(),
                requestUrl = `${userWebApiUrl}/role?roleId=${roleId}`;

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getPermissions() {
            const deferred = $q.defer(),
                requestUrl = userWebApiUrl + '/permissions';

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getLineManagersByContractorId(contractorId) {
            const deferred = $q.defer();

            $http
                .get(`${userWebApiUrl}/lineManagersByContractorId?contractorId=${contractorId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateUserPreferredLanguage(languageIso) {
            const deferred = $q.defer();

            $http
                .put(`${userWebApiUrl}/preferredLanguage?languageIso=${languageIso}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function isMicrosoftAuthUser(userId) {
            const deferred = $q.defer();

            $http.get(`${userWebApiUrl}/isMicrosoftAuthUser?userId=${userId}`)
                .then((result) => deferred.resolve(result.data), () => deferred.reject());

            return deferred.promise;
        }
    }
})();
