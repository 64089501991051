(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .controller(
            'creditInformationDialogController',
            creditInformationDialogController
        );

        creditInformationDialogController.$inject = [
        '$scope',
        '$translate',
        '$windowInstance',
    ];

    function creditInformationDialogController(
        $scope,
        $translate,
        $windowInstance
    ) {

        initController();
        function initController() {
        }


    }
})();
