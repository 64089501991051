(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('DepartmentLogsModalController', departmentLogsModalController);

    departmentLogsModalController.$inject = ['$scope', '$translate', 'dateUtility', 'logPairs', 'kendoUtility'];

    function departmentLogsModalController($scope, $translate, dateUtility, logPairs, kendoUtility) {
        var departmentLogsGridOptions = (langId) => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success(logPairs),
                },
            }),
            columns: [
                {
                    field: 'personName',
                    title: $translate.instant('TIMEBANK_LOG_GRID_PERSON', null, null, langId),
                },
                {
                    field: 'departmentName',
                    title: $translate.instant('TIMEBANK_LOG_GRID_DEPARTMENT', null, null, langId),
                },
                {
                    field: 'startDateTime',
                    title: $translate.instant('TIMEBANK_LOG_GRID_START_DATE', null, null, langId),
                    template: function (dataItem) {
                        return dateUtility.formatDateTime(dataItem.startDateTime);
                    },
                },
                {
                    field: 'startActionName',
                    title: $translate.instant('TIMEBANK_LOG_GRID_START_ACTION', null, null, langId),
                },
                {
                    field: 'startActionComment',
                    title: $translate.instant('TIMEBANK_LOG_GRID_START_COMMENT', null, null, langId),
                },
                {
                    field: 'stopDateTime ',
                    title: $translate.instant('TIMEBANK_LOG_GRID_STOP_DATE', null, null, langId),
                    template: function (dataItem) {
                        return dateUtility.formatDateTime(dataItem.stopDateTime);
                    },
                },
                {
                    field: 'stopActionName ',
                    title: $translate.instant('TIMEBANK_LOG_GRID_STOP_ACTION', null, null, langId),
                },
                {
                    field: 'stopActionComment',
                    title: $translate.instant('TIMEBANK_LOG_GRID_STOP_COMMENT', null, null, langId),
                },
                {
                    field: 'sum',
                    title: $translate.instant('TIMEBANK_LOG_GRID_SUM', null, null, langId),
                },
                {
                    field: 'status',
                    title: $translate.instant('TIMEBANK_LOG_GRID_STATUS', null, null, langId),
                    template: function (dataItem) {
                        switch (dataItem.status) {
                            case 0:
                                return $translate.instant('TIMEBANK_STATUS_REGISTERED', null, null, langId);
                            case 1:
                                return $translate.instant('TIMEBANK_STATUS_APPROVED', null, null, langId);
                            case 3:
                                return $translate.instant('TIMEBANK_STATUS_COMPLETED', null, null, langId);
                            case 5:
                                return $translate.instant('TIMEBANK_STATUS_REJECTED', null, null, langId);
                            default:
                                '';
                        }
                    },
                },
            ],
            height: 470,
            editable: false,
            filterable: true,
            resizable: true,
            sortable: true,
        });

        $scope.departmentLogsGridOptions = departmentLogsGridOptions($translate.use());
    }
})();
