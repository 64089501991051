(function () {
    angular.module('NaviaqWebApp').factory('orderService', orderService);

    orderService.$inject = ['$q', '$rootScope', '$http', 'serviceUrls', 'OrderStatus', 'dateUtility', 'objectPropertyNameHandlerService'];

    function orderService($q, $rootScope, $http, serviceUrls, OrderStatus, dateUtility, objectPropertyNameHandlerService) {
        const orderApiUrl = `${serviceUrls.webApiBaseUrl}/order`;

        return {
            getOrderByOrderId,
            getExtendedOrderByOrderId,
            getOrderWithActivityData,
            getOrderStatusText,
            getOrderStatusValues,
            getVesselOrders,
            getOrderProcessesByContractorId,
            getOrderProcessByOrderProcessId,
            addOrderProcess,
            updateOrderProcess,
            getOrdersForBatchSave,
            getOrdersByLocationNumber,
            getOrderDocumentsByOrderId,
            getOrderVideos,
            uploadVideoForOrder,
            GetAzureBlobSASTokenAndContainerName,
            getOrderProcessByOrderId,
            getServiceFormDataByOrderId,
        };

        function getOrdersByLocationNumber(filter){
            const deferred = $q.defer();
            const requests = {
                getOrdersByLocationNumberFromServer: getOrdersByLocationNumberFromServer(filter)
            };
            $q.all(requests)
                .then(function (result) {
                    if (result) {
                        const orders = result.getOrdersByLocationNumberFromServer;
                        orders.forEach((order) => {
                            extendOrder(order);
                        });
                        deferred.resolve(orders);
                    } else {
                        deferred.resolve(null);
                    }
                })
                .catch((error) => console.error(error))
                .finally(() => {});

            return deferred.promise;
        }

        function getOrdersFromServer(filter) {
            const deferred = $q.defer();

            const requestUrl = `${orderApiUrl}/withmetadata/filter`;
            $http
                .post(requestUrl, filter)
                .then((response) => {
                    deferred.resolve(
                        objectPropertyNameHandlerService.setCamelCaseToPascalCase(
                            response.data
                        )
                    );
                })
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getOrdersForBatchSave(contractorId) {
            const deferred = $q.defer();

            const requestUrl = `${orderApiUrl}/forOperationLogBatchSave?contractorId=${contractorId}`;
            $http
                .get(requestUrl)
                .then((response) => {
                    deferred.resolve(response.data)
                })
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getOrdersByLocationNumberFromServer(filter) {
            const deferred = $q.defer();

            const requestUrl = `${orderApiUrl}/webApp/byLocationNumber`;
            $http
                .post(requestUrl, filter)
                .then((response) => {
                    deferred.resolve(
                        objectPropertyNameHandlerService.setCamelCaseToPascalCase(
                            response.data
                        )
                    );
                })
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function extendOrder(order) {
            if (order.OrderProcessId && order.OrderProcess) {
                order.OrderProcessName = order.OrderProcess.Name;
            }

            if(order.OrderDepartments && order.OrderDepartments.length){
                var departments = order.OrderDepartments
                .map((orderDepartment) => {
                    return orderDepartment.Department;
                });

                order.Departments = [];
                if (departments && departments.length) {
                    var departmentNames = [];
                    departments.forEach((department) => {
                        departmentNames.push(department.Name);
                        order.Departments.push(department);
                    });
                    order.DepartmentNames = departmentNames.join(', ');
                }
            }

            let orderDisplayText = order.ExternalOrderId;

            if (order.Customer) {
                order.ContactPersonDisplayName = order.Customer.Name;
            }

            if (order.Location) {
                order.LocationName = order.Location.Name;
                orderDisplayText = `${orderDisplayText} ${order.Location.Name}`;
            }

            if (order.OrderProcess) {
                orderDisplayText = `${orderDisplayText} ${order.OrderProcess}`;
            }

            order.OrderDisplayText = orderDisplayText;

            if (order.ProjectManagerObject) {
                order.ProjectManager = order.ProjectManagerObject.DisplayName;
                order.ProjectManagerId = order.ProjectManagerObject.UserId;
            }
        }


        function getOrderWithActivityData(orderId) {
            const deferred = $q.defer();

            $http.get(`${orderApiUrl}/extendedOrderByOrderId?orderId=${orderId}&includeActivityLines=true`).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function getOrderByOrderId(orderId) {
            const deferred = $q.defer();
            const url = `${orderApiUrl}/byOrderId?orderId=${orderId}`;

            $http
                .get(url)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getExtendedOrderByOrderId(orderId) {
            const deferred = $q.defer();

            $http
                .get(`${orderApiUrl}/extendedOrderByOrderId?orderId=${orderId}`)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getOrderStatusText(orderStatus) {
            switch (orderStatus) {
                case OrderStatus.InProgress:
                    return 'Pågår';
                case OrderStatus.Active:
                    return 'Aktiv';
                case OrderStatus.Completed:
                    return 'Fullført';
                case OrderStatus.ReadyForInvoicing:
                    return 'Klar til fakturering';
                case OrderStatus.Invoiced:
                    return 'Fakturert';
                case OrderStatus.Quote:
                    return 'Tillbud';
                case OrderStatus.Confirmed:
                    return 'Bekreftet';
                case OrderStatus.Planned:
                    return 'Planlagt';
                case OrderStatus.Cancelled:
                    return 'Kansellert';
                case OrderStatus.Archived:
                    return 'Arkiv';
                case OrderStatus.ShallNotBeInvoiced:
                    return 'Skal ikke faktureres';
                case OrderStatus.Deleted:
                    return 'Slettet';
                case 11:
                    return 'Månedsfaktura';
                case 12:
                    return 'Internfaktura';
                default:
                    return '';
            }
        }

        function getOrderStatusValues() {
            const orderStatusValues = [];

            for (const status in OrderStatus) {
                if (OrderStatus.hasOwnProperty(status)) {
                    orderStatusValues.push({
                        key: status,
                        value: OrderStatus[status],
                        text: this.getOrderStatusText(OrderStatus[status]),
                    });
                }
            }

            return orderStatusValues;
        }

        function getVesselOrders(filter) {
            const deferred = $q.defer();

            $http.post(`${orderApiUrl}/vesselOrdersByFilter`, filter).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function getOrderProcessesByContractorId(contractorId)
        {
            const deferred = $q.defer();
            const url = orderApiUrl + '/process?contractorId=' + contractorId;

            $http.get(url).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getOrderProcessByOrderProcessId(orderProcessId)
        {
            const deferred = $q.defer();
            const url = orderApiUrl + `/process/byOrderProcessId?orderProcessId=${orderProcessId}`;

            $http.get(url).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function addOrderProcess(orderProcess)
        {
            const deferred = $q.defer();
            const url = orderApiUrl + '/process';

            $http.post(url, orderProcess).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function updateOrderProcess(orderProcess){
            const deferred = $q.defer();
            const url = orderApiUrl + '/process';

            $http.put(url, orderProcess).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getOrderDocumentsByOrderId(orderId) {
            const deferred = $q.defer();

            $http.get(`${orderApiUrl}/orderDocumentsByOrderId?orderId=${orderId}`).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getOrderVideos(orderId) {
            const deferred = $q.defer();

            $http.get(`${orderApiUrl}/orderVideos?orderId=${orderId}`).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function uploadVideoForOrder(url, userId, orderId, size, fileName){
            const deferred = $q.defer();
            const requestUrl = `${orderApiUrl}/uploadVideoForOrder`;
            const uploadVideoObject = {
                userId: userId,
                size: size,
                orderId: orderId,
                url: url,
                fileName: fileName,
            }
            $http
                .post(requestUrl, uploadVideoObject)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function GetAzureBlobSASTokenAndContainerName(contractorId = null) {
            const deferred = $q.defer();
            var contractorIdParam = contractorId ? contractorId : $rootScope.authData.contractorId;
            $http.get(`${orderApiUrl}/azureBlobSASTokenAndContainerName?contractorId=${contractorIdParam}`).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getOrderProcessByOrderId(contractorId, orderId){
            const deferred = $q.defer();

            $http.get(`${orderApiUrl}/processByOrderId?contractorId=${contractorId}&orderId=${orderId}`).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getServiceFormDataByOrderId(orderId, reportType){
            const deferred = $q.defer();

            $http.get(`${orderApiUrl}/serviceFormDataByOrderId?orderId=${orderId}&reportType=${reportType}`).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }
    }
})();
