(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('fileHandlingService', fileHandlingService);

    fileHandlingService.$inject = ['$q', 'commonUtility', 'itemTreeService'];

    function fileHandlingService($q, commonUtility, itemTreeService) {
        var service = {
            asyncEncodeImageFile,
            asyncReadGenericFile,
            asyncReadGenericFileAsArrayBuffer,
            addItemFile,
            validationFileExtension,
            asyncReadGenericFileWithName
        };

        return service;

        function addItemFile(fileName, mediaId, relatedItemId, userId, documentId) {
            var deferred = $q.defer();

            var itemFile = {
                Id: documentId ? documentId : commonUtility.uuidv4(),
                CreatedById: userId,
                CreatedOn: new Date(),
                MediaId: mediaId,
                RelatedItemId: relatedItemId,
                FileName: fileName
            };

            itemTreeService.addItemFileWebApi(itemFile)
                .then(() => {
                    deferred.resolve(itemFile.Id);
                }).catch(() => {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function asyncReadGenericFileWithName(file) {
            if (commonUtility.isFileWithoutExtension(file)) {
                commonUtility.fileWithoutExtensionAlert();
                return;
            }

            return $q((resolve, reject) => {
                let reader = new FileReader();
                reader.onloadend = () => resolve({base64Content: reader.result, fileName: file.name});
                reader.onerror = reject;
                reader.readAsDataURL(file);
            })
        }

        function asyncEncodeImageFile(file) {
            if (commonUtility.isFileWithoutExtension(file)) {
                commonUtility.fileWithoutExtensionAlert();
                return;
            }

            return $q((resolve, reject) => {
                let reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            })
        }

        function asyncReadGenericFile(file) {
            if (commonUtility.isFileWithoutExtension(file)) {
                commonUtility.fileWithoutExtensionAlert();
                return;
            }

            return $q((resolve, reject) => {
                let reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            })
        }

        function asyncReadGenericFileAsArrayBuffer(file) {
            if (commonUtility.isFileWithoutExtension(file)) {
                commonUtility.fileWithoutExtensionAlert();
                return;
            }

            return $q((resolve, reject) => {
                let reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            })
        }

        function validationFileExtension(file) {
            var blockedExtensions = ['exe', 'dll', 'vbs', 'sh', 'bin'];
            var extension = file.name.substring(file.name.lastIndexOf(".") + 1);
            if(extension){
                if(blockedExtensions.includes(extension.toLowerCase())){
                    toaster.pop('error', '', $translate.instant('G_SELECTED_FILE_EXTENSION_IS_NOT_SUPPORTED'));
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }
    }
})();
