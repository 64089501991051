(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('styrkTitleService', styrkTitleService);

    styrkTitleService.$inject = ['$q', '$http', 'serviceUrls'];

    function styrkTitleService($q, $http, serviceUrls) {
        const StyrkTitleBaseApiUrl = `${serviceUrls.webApiBaseUrl}/styrkTitle`;

        return {
            getStyrkTitles
        };

        function getStyrkTitles() {
            const deferred = $q.defer();
            const url = StyrkTitleBaseApiUrl;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
