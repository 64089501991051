(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('anteoGeodataService', anteoGeodataService);

    anteoGeodataService.$inject = ['$q', '$http'];

    function anteoGeodataService($q, $http) {
        return {
            getGeoJsonData: getGeoJsonData
        };

        /*
        Parameters:
            mmsi
            maxEvents (how many points we need), start with 500
            showValves
            from (order date from)
            to (order date to)
            https://extapi.anteo.no/api/doJsonWellTrack?mmsi=257347000&showValves=true&maxEvents=500&from=2018-01-23+10:00:00&to=2018-01-23+10:02:00
        */
        function getGeoJsonData(mmsi, showValves, anteoFrom, anteoTo) {
            var deferred = $q.defer();

            var url = 'api/GeoData?mmsi=' + mmsi + '&showValves=' + showValves + '&from=' + anteoFrom + '&to=' + anteoTo;
            $http.get(url).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
