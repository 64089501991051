(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ChangePasswordController', ChangePasswordController);

    function ChangePasswordController($q, $rootScope, $scope, $windowInstance, authService, $translate, $kWindow) {

        $scope.newPassword = '';
        $scope.oldPassword = '';
        $scope.confirmPassword = '';

        $scope.passwordLength = 8;
        $scope.numberLength = 1;
        $scope.capitalLetterLength = 1;

        $scope.pattern = '(?=.*[0-9])(?=.*[A-Z])(.*)';
        $scope.numberPattern = '[0-9]+';
        $scope.capitalPattern = '[A-Z]+';

        $scope.minRequiredPasswordIsValid = true;
        $scope.minNumberInPasswordIsValid = true;
        $scope.minCapitalInPasswordIsValid = true;
        $scope.confirmPasswordIsValid = true;
        $scope.regexMatchIsValid = true;
        $scope.failed = false;

        $scope.changePassword = changePassword;
        $scope.closeModal = closeModal;

        initModal();

        function initModal() {
            $scope.authData = authService.getAuthData();
            $scope.$watch('[newPassword, confirmPassword]', function () {
                customValidation(false);
            });
        };

        function changePassword(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                $scope.failed = false;
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'passwordChange',
                    destination: 'change-password-form',
                });
                customValidation(true).then(function () {
                    if (!$scope.customValidationSuccess) {
                        $rootScope.$broadcast('hideBusyIndicator', 'passwordChange');
                        return;
                    }

                    var windowInstance = $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            resizable: false,
                            visible: false,
                            width: 300
                        },
                        templateUrl: 'app/shared/popups/confirm-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ConfirmModalController',
                        resolve: {
                            content: function () {
                                return $translate.instant('G_AREYOUSURE');
                            },
                            primaryBtnText: function () {
                                return null;
                            },
                            secondaryBtnText: function () {
                                return null;
                            }
                        }
                    });
                    windowInstance.result.then(function (response) {
                        if (response) {
                            authService.changePassword($scope.authData.username, $scope.oldPassword, $scope.newPassword, $scope.confirmPassword).then(function (result) {
                                if (result) {
                                    $windowInstance.close(true);
                                }
                            })
                            .catch(() => $scope.failed = true)
                            .finally(() => {
                                $rootScope.$broadcast('hideBusyIndicator', 'passwordChange');
                            });
                        }
                    });
                });
                $rootScope.$broadcast('hideBusyIndicator', 'passwordChange');
            }
        };



        function closeModal() {
            $windowInstance.close();
        };

        function customValidation(validateUser) {
            var deferred = $q.defer();
            $scope.customValidationSuccess = false;
            var pattern = new RegExp($scope.pattern);
            if  ($scope.newPassword)
            {
                $scope.minRequiredPasswordIsValid = $scope.newPassword.length >= $scope.passwordLength;
                $scope.minNumberInPasswordIsValid = ($scope.newPassword.match(new RegExp($scope.numberPattern, 'g')) || []).length >= $scope.numberLength;
                $scope.minCapitalInPasswordIsValid = ($scope.newPassword.match(new RegExp($scope.capitalPattern, 'g')) || []).length >= $scope.capitalLetterLength;
            }
            $scope.confirmPasswordIsValid = $scope.newPassword === $scope.confirmPassword;
            $scope.regexMatchIsValid = pattern.test($scope.newPassword);

            if (validateUser && $scope.oldPassword) {
                authService.validateUser($scope.authData.username, $scope.oldPassword).then(function (result) {
                    $scope.wrongPassword = !result;

                    $scope.customValidationSuccess = $scope.confirmPasswordIsValid
                        && $scope.minNumberInPasswordIsValid
                        && $scope.minRequiredPasswordIsValid
                        && $scope.minCapitalInPasswordIsValid
                        && $scope.regexMatchIsValid
                        && !$scope.wrongPassword;
                    deferred.resolve($scope.customValidationSuccess);

                });
            }
            return deferred.promise;
        };
    }
})();
