(function() {
    angular.module('NaviaqWebApp').factory('operationService', operationService);

    operationService.$inject = ['$q', '$http', 'serviceUrls'];

    function operationService($q, $http, serviceUrls) {
        const operationApiUrl = `${serviceUrls.webApiBaseUrl}/operation`; 

        const operationService = {
            addOperation: addOperation,
            getOperationTypes: getOperationTypes,
            updateOperation: updateOperation,
            addOperationType:addOperationType,
            updateOperationType:updateOperationType
        };

        return operationService;

        function addOperation(operation) {
            const deferred = $q.defer();

            $http.post(`${operationApiUrl}`, operation).then(function() {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getOperationTypes(contractorId) {
            const deferred = $q.defer();

            $http.get(`${operationApiUrl}/typesByContractorId?contractorId=${contractorId}`).then(function(response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateOperation(operation) {
            const deferred = $q.defer();

            $http.put(`${operationApiUrl}`, operation).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function addOperationType(operationType) {
            const deferred = $q.defer();

            $http.post(`${operationApiUrl}/type`, operationType).then(function() {
                deferred.resolve();
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateOperationType(operationType) {
            const deferred = $q.defer();

            $http.put(`${operationApiUrl}/type`, operationType).then(function () {
                deferred.resolve();
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }        
    }
})();
