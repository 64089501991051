(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OperationTypesAdminController', OperationTypesAdminController);

    OperationTypesAdminController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'operationService',
        'formTypes',
        'operationTypeStatus',
        'commonUtility',
        'htmlElementService'
    ];

    function OperationTypesAdminController(
        $q,
        $rootScope,
        $scope,
        $kWindow,
        $translate,
        kendoUtility,
        operationService,
        formTypes,
        operationTypeStatus,
        commonUtility,
        htmlElementService
    ) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        var gridHeight;

        $scope.editOperationType = editOperationType;
        $scope.addOperationType = addOperationType;
        $scope.archiveOperationType = archiveOperationType;

        const operationTypesGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        $rootScope.$broadcast('showBusyIndicator', {
                            id: 'operationTypeAdminBusyIndicator',
                            destination: '#operation-types-grid',
                            overlay: true
                        });

                        operationService.getOperationTypes($scope.authData.contractorId).then(function (operationTypes) {
                            $scope.operationTypes = operationTypes;
                            e.success(operationTypes);
                        }).catch(() => e.success([])
                        ).finally(() => $rootScope.$broadcast('hideBusyIndicator', 'operationTypeAdminBusyIndicator'));
                    }
                }
            }),
            columns: [
                {
                    field: 'id',
                    hidden: true,
                    template: '<span data-event-id="#:id#">#:#<span>'
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_OPERATIONTYPE_NAME', null, null, langId)
                },
                {
                    field: '', title: '', sortable: false, filterable: false, width: 100,
                    template: '<div class="actions">'
                        + '<i class="fa fa-pencil" aria-hidden="true" ng-click="editOperationType(\'#:id#\')" ></i>'
                        + '<span kendo-tooltip k-content="Arkivere" ></span><i class="fa fa-archive" aria-hidden="true" ng-click="archiveOperationType(\'#:id#\')" ></i>'
                        + '</div>'
                }
            ],
            dataBinding: function () {
                gridHeight = $('#operation-types-admin-view').height();
            },
            dataBound: function () {
                if (this.content.height() > gridHeight - this.content.offset().top) {
                    var newContentHeight = $('#operation-types-admin-view').height() - 5;
                    this.content.height(newContentHeight);
                }
            },
            animation: false,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            groupable: false,
            resizable: true,
            filterable: {
                name: "FilterMenu",
                extra: true
            }
        });

        $scope.operationTypesGridOptions = operationTypesGridOptions($translate.use());      

        initController();
        function initController() {
            $scope.$on('langChanged', (_, langId) => {
                $scope.operationTypesGridOptions = operationTypesGridOptions(langId);
                const optionTypesGrid = kendoUtility.createWidget('kendoGrid', $scope.operationTypesGridOptions);
                kendoUtility.changeLangOfWidget(langId, [() => optionTypesGrid('#operation-types-grid')]);
            });            
            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });
        }

        function addOperationType() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_OPERATIONTYPE_ADD'),
                    resizable: true,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/administration/operationTypes/operation-type-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationTypeDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.add;
                    },
                    operationType: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    reloadOperationTypesGrid();
                }
            });
        }        

        function editOperationType(operationTypeId) {
            var operationType = _.find($scope.operationTypes, function (d) {
                return d.id == operationTypeId;
            });

            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_OPERATIONTYPE_EDIT'),
                    resizable: true,
                    height: 120,
                    width: 565,
                    visible: false
                },
                templateUrl: 'app/administration/operationTypes/operation-type-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationTypeDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.edit;
                    },
                    operationType: function () {
                        return operationType;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    reloadOperationTypesGrid();
                }
            });
        }

        function archiveOperationType(operationTypeId) {

            var windowInstance = $("<div />").kendoWindow({
                title: $translate.instant('G_CONFIRM'),
                resizable: false,
                modal: true
            });

            windowInstance.data('kendoWindow')
                .content($('#archive-confirmation').html())
                .center().open();

            $('.archive-message')
                .text($translate.instant('ADMIN_OPERATIONTYPE_ARCHIVE_MESSAGE'));
            $('.archive-confirm')
                .text($translate.instant('G_YES'));
            $('.archive-cancel')
                .text($translate.instant('G_NO'));

            windowInstance
                .find('.archive-confirm,.archive-cancel')
                .click(function () {
                    if ($(this).hasClass('archive-confirm')) {
                        var operationType = _.find($scope.operationTypes, function (d) {
                            return d.id == operationTypeId;
                        });

                        operationType.status = operationTypeStatus.Archived;
                        operationService.updateOperationType(operationType).then(function () {
                            reloadOperationTypesGrid();
                            windowInstance.data('kendoWindow').close();
                        });
                    } else {
                        windowInstance.data('kendoWindow').close();
                    }

                })
                .end();
        }

        //Private functions
        function reloadOperationTypesGrid() {
            $scope.operationTypesGrid.dataSource.read();
            $scope.operationTypesGrid.refresh();
        }
    }
})();
