(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('locationOwnerDetailsModalController', locationOwnerDetailsModalController);

    locationOwnerDetailsModalController.$inject = ['$scope', '$rootScope', '$q', '$windowInstance', 'formType', 'formTypes', 'locationOwnerId', 'locations', 'locationOwnerIds', 'adminLocationOwnerService'];

    function locationOwnerDetailsModalController($scope, $rootScope, $q, $windowInstance, formType, formTypes, locationOwnerId, locations, locationOwnerIds, adminLocationOwnerService) {
        $scope.isAdd = formType == formTypes.add;
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.uniqueValidator = uniqueValidator;
        $scope.selectedLocationNumbers = [];
        $scope.locationOwner = { locationOwnerId: null, name: '' };
        $scope.locationOwnerIdIsValid = true;
        $scope.locations = locations;

        $scope.locationsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.locations)
                }
            }),
            dataValueField: 'locationNumber',
            dataTextField: 'displayName',
            filter: 'contains'
        };

        initController();
        function initController() {
            $scope.locationsOptions.dataSource.read();

            if (!$scope.isAdd) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'locationOwnerLoad',
                    destination: '#location-owner-form',
                    overlay: true,
                });

                $q.all({
                    getLocationOwner: adminLocationOwnerService.getLocationOwner(locationOwnerId)
                })
                    .then((result) => {
                        if (result.getLocationOwner) {
                            $scope.locationOwner = result.getLocationOwner;

                            $scope.selectedLocationNumbers = $scope.locationOwner.locationLocationOwners.map(c => c.locationNumber);
                        }
                    })
                    .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'locationOwnerLoad'));
            }
        }

        function ok() {
            if (!$scope.validator.validate() || !uniqueValidator()) {
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationOwnerSave',
                destination: '#location-owner-form',
                overlay: true,
            });

            const locationOwnerDto = {
                locationOwnerId: $scope.locationOwner.locationOwnerId,
                name: $scope.locationOwner.name,
                locationNumbers: $('#locations').data("kendoMultiSelect").value()
            };

            const request = {
                request: $scope.isAdd ?
                    adminLocationOwnerService.addLocationOwner(locationOwnerDto) :
                    adminLocationOwnerService.updateLocationOwner(locationOwnerDto)
            };

            $q.all(request)
                .then((response) => {
                    if (response.request) {
                        $windowInstance.close(true);
                    }
                })
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'locationOwnerSave'));
        }

        function cancel() {
            $windowInstance.close();
        }

        function uniqueValidator() {
            if ($scope.isAdd) {
                if (!$scope.locationOwner.locationOwnerId) {
                    $scope.locationOwnerIdIsValid = true;
                } else {
                    $scope.locationOwnerIdIsValid = !locationOwnerIds.find((x) => {
                        return x === $scope.locationOwner.locationOwnerId
                    });
                }
            }
            else {
                $scope.locationOwnerIdIsValid = true;
            }

            return $scope.locationOwnerIdIsValid;
        }
    }
})();
