(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('listsAdministrationController', listsAdministrationController);

    listsAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'listService',
        'commonUtility',
        'sanitizeService',
    ];

    function listsAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        listService,
        commonUtility,
        sanitizeService,
    ) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#lists-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#lists-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });


        var listsAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.lists),
                update: e => onListGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        description: { editable: true },
                        placeHolderId: { editable: true },
                    }
                },
            }
        });

        const listsAdministrationGridOptions = langId => ({
            dataSource: listsAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_LISTS_DESCRIPTION', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtonsList(false);
                        }
                        return sanitizeService.sanitizingStringContent(dataItem.description);
                    },
                },
                {
                    field: 'placeHolderId',
                    title: $translate.instant('ADMIN_LISTS_ID', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtonsList(false);
                        }
                        return sanitizeService.sanitizingStringContent(dataItem.placeHolderId);
                    },
                },
                {
                    command: [
                        {
                            name: 'editList',
                            click: e => editList(e),
                            template: '<a class="k-grid-editList"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteList',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                deleteList(data.id);
                            },
                            template: '<a class="k-grid-deleteList"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: false,
            selectable: true,
            persistSelection: true,
            change: function () {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'listElementAdministrationGrid',
                    destination: '#list-element-administration-grid'
                });
                $scope.selectedList = this.dataItem(this.select());
                $scope.listElements = $scope.selectedList.listElements;
                if (!$scope.listElements) {
                    $scope.listElements = [];
                }
                toggleChangesButtonsListElement(true);
                $scope.listElementAdministrationGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'listElementAdministrationGrid');
            }
        });

        var listElementAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.listElements),
                update: e => onListElementGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        displayName: { editable: true },
                    }
                },
            }
        });

        const listElementAdministrationGridOptions = langId => ({
            dataSource: listElementAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'displayName',
                    title: $translate.instant('ADMIN_LISTS_DISPLAY_TEXT', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtonsListElement(false);
                        }
                        return sanitizeService.sanitizingStringContent(dataItem.displayName);
                    },
                },
                {
                    command: [
                        {
                            name: 'editListElement',
                            click: e => editListElement(e),
                            template: '<a class="k-grid-editListElement"><i class="fas fa-edit"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: false
        });

        $scope.listsAdministrationGridOptions = listsAdministrationGridOptions($translate.use());
        $scope.listElementAdministrationGridOptions = listElementAdministrationGridOptions($translate.use());

        $scope.lists = [];
        $scope.listElements = [];
        $scope.selectedList = {}

        $scope.addNewList = addNewList;
        $scope.saveChangesList = saveChangesList;
        $scope.cancelChangesList = cancelChangesList;
        $scope.editList = editList;
        $scope.toggleChangesButtonsList = toggleChangesButtonsList;

        $scope.addNewListElement = addNewListElement;
        $scope.saveChangesListElement = saveChangesListElement;
        $scope.cancelChangesListElement = cancelChangesListElement;
        $scope.editListElement = editListElement;
        $scope.toggleChangesButtonsListElement = toggleChangesButtonsListElement;

        $scope.$on('langChanged', (event, code) => {
            $scope.listsAdministrationGridOptions = listsAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.listsAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#lists-administration-grid')]);
            langId = code;
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'listsAdministrationGrid',
                destination: '#lists-administration-grid'
            });
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'listElementAdministrationGrid',
                destination: '#list-element-administration-grid'
            });
            toggleChangesButtonsList(true);
            toggleChangesButtonsListElement(true);
            commonUtility.setAdminTabMenuFullWidth();
            let requests = {};
            requests.getListsByContractorId = listService.getListsByContractorId($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    $scope.lists = data.getListsByContractorId;
                    $scope.selectedList = $scope.lists[0];
                    $scope.listElements = $scope.selectedList.listElements;

                    $scope.listsAdministrationGridOptions.dataSource.read();
                    $scope.listElementAdministrationGridOptions.dataSource.read();
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'listsAdministrationGrid');
                    $rootScope.$broadcast('hideBusyIndicator', 'listElementAdministrationGrid');
                });
        }

        function openListsDetailsModal(formType, list) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: list ? list.description : $translate.instant('ADMIN_LISTS_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/administration/lists/lists-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'listsDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    list: () => list,
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    if (formType === formTypes.add) {
                        $scope.lists = $scope.lists.concat([result]);
                    } else {
                        $scope.lists = $scope.lists.map(u => (u.id === result.id ? result : u));
                    }
                    $scope.listsAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function addNewList() {
            openListsDetailsModal(formTypes.add, null)
        }

        function editList(event) {
            var list = getListsFromGrid(event)

            openListsDetailsModal(formTypes.edit, list)
        }

        function getListsFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#lists-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function saveChangesList() {
            $scope.listsAdministrationGridOptions.dataSource.sync();
        }

        function cancelChangesList() {
            toggleChangesButtonsList(true)
            $scope.listsAdministrationGridOptions.dataSource.read();
        }

        function toggleChangesButtonsList(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-lists');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-lists');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function openListElementDetailsModal(formType, listElement, listId) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: listElement ? listElement.displayName : $translate.instant('ADMIN_LISTS_NEW_ELEMENT'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/administration/lists/list-element-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'listElementDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    listElement: () => listElement,
                    listId: () => listId,
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    if (formType === formTypes.add) {
                        $scope.listElements.push(result);
                    } else {
                        $scope.listElements = $scope.listElements.map(u => (u.id === result.id ? result : u));
                    }
                    $scope.listElementAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function addNewListElement() {
            openListElementDetailsModal(formTypes.add, null, $scope.selectedList.id)
        }

        function editListElement(event) {
            var listElement = getListElementFromGrid(event)
            openListElementDetailsModal(formTypes.edit, listElement, $scope.selectedList.id)
        }

        function getListElementFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#list-element-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function saveChangesListElement() {
            $scope.listElementAdministrationGridOptions.dataSource.sync();
        }

        function cancelChangesListElement() {
            toggleChangesButtonsListElement(true)
            $scope.listElementAdministrationGridOptions.dataSource.read();
        }

        function toggleChangesButtonsListElement(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-list-element');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-list-element');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function deleteList(listId){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                    listService.deleteList(listId).then(result => {
                        if(result){
                            $scope.lists = $scope.lists.filter(l => l.id !== listId);
                            $scope.listsAdministrationGridOptions.dataSource.read();
                        }else{
                            alert($translate.instant('ADMIN_LISTS_DELETE_FAILED_MESSAGE'))
                        }
                    });
                }
            });
        }

        function onListGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'listsAdministrationGrid',
                destination: '#lists-administration-grid'
            });
            listService.updateList(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtonsList(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'listsAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'listsAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'listsAdministrationGrid');
                });
        }

        function onListElementGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'listElementAdministrationGrid',
                destination: '#list-element-administration-grid'
            });
            listService.updateListElement(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtonsListElement(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'listElementAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'listElementAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'listElementAdministrationGrid');
                });
        }
    }
})();
