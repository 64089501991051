(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('conglomerateDetailsModalController', conglomerateDetailsModalController);

    conglomerateDetailsModalController.$inject = ['$scope', '$rootScope', '$q', 'htmlElementService', '$windowInstance', 'formType', 'formTypes', 'conglomerate', 'adminConglomerateService'];

    function conglomerateDetailsModalController($scope, $rootScope, $q, htmlElementService, $windowInstance, formType, formTypes, conglomerate, adminConglomerateService) {
        const isAdd = formType == formTypes.add;
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.contractors = [];
        $scope.selectedContractors = [];
        $scope.selectedContractorIds = [];
        $scope.conglomerate = conglomerate || { name: '', contractors: [] };

        $scope.contractorsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.contractors)
                }
            }),
            dataValueField: 'contractorId',
            dataTextField: 'name',
            filter: 'contains'
        };

        initController();
        function initController() {
            htmlElementService.checkIfElementExists("conglomerate-form", 100).then(() => {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'conglomerateLoad',
                    destination: '#conglomerate-form',
                    overlay: true,
                });
    
                adminConglomerateService.getContractors($scope.conglomerate.contractors.map(x => x.contractorId))
                    .then((result) => {
                        $scope.contractors = result;

                        $scope.contractorsMultiselect.dataSource.read();
    
                        if (!isAdd) {
                            $scope.selectedContractorIds = $scope.contractors.map(x => x.contractorId).filter(x => $scope.conglomerate.contractors.map(c => c.contractorId).includes(x));
                        }

                        $scope.contractorsMultiselect.dataSource.read();
                    })
                    .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'conglomerateLoad'));
                });
        }

        function ok() {
            if (!$scope.validator.validate()) {
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'conglomerateSave',
                destination: '#conglomerate-form',
                overlay: true,
            });

            if ($scope.selectedContractorIds && $scope.selectedContractorIds.length) {
                $scope.conglomerate.contractors = $scope.contractors.filter(x => $scope.selectedContractorIds.includes(x.contractorId));
            } else {
                $scope.conglomerate.contractors = [];
            }

            const request = {
                request: isAdd ?
                    adminConglomerateService.addConglomerate($scope.conglomerate) :
                    adminConglomerateService.updateConglomerate($scope.conglomerate)
            };

            $q.all(request)
                .then((response) => {
                    if (isAdd && response.request) {
                        $scope.conglomerate.conglomerateId = response.request;
                    }

                    $windowInstance.close($scope.conglomerate);
                })
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'conglomerateSave'));
        }

        function cancel() {
            $windowInstance.close();
        }
    }
})();
