(function() {
    'use strict';

    angular.module('NaviaqWebApp').directive('languageSelector', languageSelector);

    languageSelector.$inject = ['$rootScope', '$translate', 'kendoUtility', 'availableLanguages', 'userService'];

    function languageSelector($rootScope, $translate, kendoUtility, availableLanguages, userService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/language-selector.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.languages = availableLanguages;
            const langKey = localStorage['NG_TRANSLATE_LANG_KEY'];
            scope.lang = scope.languages.find(l => l.id == langKey);

            scope.setLanguage = lang => {
                $translate.use(lang.id);
                scope.lang = lang;

                userService.updateUserPreferredLanguage(lang.id.toUpperCase()).then(() => {
                    kendoUtility.applyLanguage(lang.id, () => {
                        $rootScope.$broadcast('langChanged', lang.id);
                    });    
                });
            };
        }
    }
})();
