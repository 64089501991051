(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('orderConfirmationReportController', orderConfirmationReportController);

    orderConfirmationReportController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$q',
        '$http',
        'anonymousReportService',
        'mediaService',
        'objectPropertyNameHandlerService'
    ];

    function orderConfirmationReportController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $q,
        $http,
        anonymousReportService,
        mediaService,
        objectPropertyNameHandlerService
    ) {

        $scope.printReport = printReport;
        $scope.goBack = goBack;
        $scope.orderId = $stateParams.OrderId;
        $scope.reportLogoImageUrl = '';
        $scope.reportFooterImageUrl = '';
        $scope.deliveryConditionUrl = null;

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#arrival-report-container').addClass('print-view-container');
        }


        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'orderConfirmationIndicator',
                destination: '#main-view',
                overlay: true
            });

            var requests = {
                getOrderConfirmationContent: anonymousReportService.getOrderConfirmationContent($scope.orderId),
            }
            $q.all(requests).then(function(result){
                $scope.orderConfirmation = result.getOrderConfirmationContent.orderConfirmation;
                $scope.order = $scope.orderConfirmation && $scope.orderConfirmation.orderData ? objectPropertyNameHandlerService.setPascalCaseToCamelCase(JSON.parse($scope.orderConfirmation.orderData)) : null;

                if (result.getOrderConfirmationContent.deliveryCondition && result.getOrderConfirmationContent.deliveryCondition.mediaId) {
                    $scope.deliveryConditionUrl = mediaService.createMediaUrlMediaBank(result.getOrderConfirmationContent.deliveryCondition.mediaId);
                }

                if($scope.order){
                    $scope.order.locationName = $scope.order.location ?  $scope.order.location.name : '';
                    $scope.order.orderProcessName = $scope.order.orderProcess ?  $scope.order.orderProcess.name : '';
                    $scope.order.customerName = $scope.order.customer ?  $scope.order.customer.name : '';
                    $scope.order.customerAddress = $scope.order.customer ?  $scope.order.customer.address : '';

                    if($scope.order.contactPersons){
                        $scope.order.contactPersonName =  $scope.order.contactPersons && $scope.order.contactPersons.length ?  $scope.order.contactPersons.map(c => c.firstName + " " + c.lastName).join(', ') : '';
                    } else if($scope.order.contactPerson) {
                        $scope.order.contactPersonName =  $scope.order.contactPerson ?  `${$scope.order.contactPerson.firstName} ${$scope.order.contactPerson.lastName}` : '';
                    } else {
                        $scope.order.contactPersonName = null;
                    }

                    $scope.order.orderDate = moment(new Date($scope.order.orderDate)).format('DD.MM.YYYY HH:mm');
                    $scope.order.projectManagerName = $scope.order.projectManagerObject ? $scope.order.projectManagerObject.displayName : '';

                    $scope.currentDate = moment(new Date()).format('DD.MM.YYYY HH:mm')
                }

                $scope.contractorId = $scope.order ? $scope.order.contractorId : null;
                if ($scope.contractorId) {
                    $scope.reportLogoImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-order-confirmation-${$scope.contractorId}.png`;
                    $http.get($scope.reportLogoImageUrl, { headers: { 'Anonymous': '' }, responseType: 'blob' })
                        .then(() => {
                            $scope.reportLogoImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-order-confirmation-${$scope.contractorId}.png`;
                        })
                        .catch(() => {
                            $scope.reportLogoImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-e083f410-67ab-e611-a3b0-f23cffe3d187.png`; // default naviaq image
                        })

                    $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer-${$scope.contractorId}.png`;
                    $http.get($scope.reportFooterImageUrl, { headers: { 'Anonymous': '' }, responseType: 'blob' })
                        .then(() => {
                            $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer-${$scope.contractorId}.png`;
                        })
                        .catch(() => {
                            $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer.png`; //default footer
                        })
                } else {
                    $scope.reportLogoImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-e083f410-67ab-e611-a3b0-f23cffe3d187.png`; // default naviaq image
                    $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer.png`; //default footer
                }

                $rootScope.$broadcast('hideBusyIndicator', 'orderConfirmationIndicator');
            });
        }

        function printReport() {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#order-confirmation-report-container').addClass('print-view-container');
            document.title = "Order-confirmation - " + kendo.toString(new Date(), 'HH:mm dd.MM.yyyy');
            window.print();

            setTimeout(function(){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'orderConfirmationIndicator',
                    destination: '#main-view',
                    overlay: true
                });

                $rootScope.showNavbar = true;
                $scope.printView = false;
                document.title = "Naviaq Webinnsyn";
                $('#main-view').removeClass('print-view');
                $('#order-confirmation-report-container').removeClass('print-view-container');
                $rootScope.$broadcast('hideBusyIndicator', 'orderConfirmationIndicator');
            }, 1);
        }

        function goBack() {
            $state.go('main.orderReport', {
                ContractorId: $scope.contractorId,
                OrderId: $scope.orderId
            });
        }
    }
})();
