(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentJobsGroupModalController', EquipmentJobsGroupModalController);

    EquipmentJobsGroupModalController.$inject = [
        '$scope',
        '$rootScope',
        '$q',
        '$translate',
        'equipmentJobDefinitionService',
        'selectedEquipmentId',
        'formTypes',
        '$kWindow',
        'confirmDialogService'
    ];

    function EquipmentJobsGroupModalController(
        $scope,
        $rootScope,
        $q,
        $translate,
        equipmentJobDefinitionService,
        selectedEquipmentId,
        formTypes,
        $kWindow,
        confirmDialogService
    ) {
        $scope.jobDefinitionGroups = [];
        $scope.addEquipmentJobDefinitionGroup = addEquipmentJobDefinitionGroup;

        $scope.equipmentJobsGroupGridOptions = {
            sortable: true,
            filterable: true,
            pageable: false,
            scrollable: {
                virtual: true,
            },
            height: 500,
            width: 'auto',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.jobDefinitionGroups),
                },
            }),
            columns: [
                {
                    field: 'equipmentJobDefinitionGroupId',
                    hidden: true,
                },
                {
                    field: 'name',
                    title: 'Name',
                },
                {
                    command: [
                        {
                            name: 'editEquipmentJobsGroup',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest('tr');
                                var parent = $(e.target).closest('#equipment-jobs-group-grid');
                                var data = parent.data('kendoGrid').dataItem(row).toJSON();
                                editEquipmentJobsGroup(data);
                            },
                            template: '<a class="k-grid-command-item k-grid-editEquipmentJobsGroup"><i class="fas fa-edit"></i></a>',
                        },
                        {
                            name: 'deleteEquipmentJobsGroup',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest('tr');
                                var parent = $(e.target).closest('#equipment-jobs-group-grid');
                                var data = parent.data('kendoGrid').dataItem(row).toJSON();
                                deleteEquipmentJobsGroup(data.equipmentJobDefinitionGroupId);
                            },
                            template: '<a class="k-grid-command-item k-grid-deleteEquipmentJobsGroup"><i class="fas fa-trash"></i></a>',
                        }
                    ],
                    width: 80,
                },
            ],
        };

        initController();
        function initController() {
            var requests = {
                getEquipmentJobDefinitionGroupsByEquipmentItemId:
                    equipmentJobDefinitionService.getEquipmentJobDefinitionGroupsByEquipmentItemId(selectedEquipmentId),
            };

            $q.all(requests).then(function (result) {
                $scope.jobDefinitionGroups = _.orderBy(result.getEquipmentJobDefinitionGroupsByEquipmentItemId, ['name'], ['asc']);
                $scope.equipmentJobsGroupGridOptions.dataSource.read();
            });
        }

        function addEquipmentJobDefinitionGroup(){
            openEquipmentJobDefinitionGroupModal(formTypes.add);
        }

        function editEquipmentJobsGroup(equipmentJobDefinitionGroup) {
            openEquipmentJobDefinitionGroupModal(formTypes.edit, equipmentJobDefinitionGroup);
        }

        function openEquipmentJobDefinitionGroupModal(formType, equipmentJobDefinitionGroup = null){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: formType == 'add' ? $translate.instant('EQUIPMENT_JOB_GROUP_ADD_MODAL_TITLE') : $translate.instant('EQUIPMENT_JOB_GROUP_EDIT_MODAL_TITLE'),
                    resizable: false,
                    minHeight: 150,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/equipments/equipment-job-group-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'EquipmentJobGroupDetailsModalController',
                resolve: {
                    equipmentJobDefinitionGroup: () => equipmentJobDefinitionGroup,
                    selectedEquipmentId: () => selectedEquipmentId,
                    formType: () => formType,
                }
            });
            windowInstance.result.then(() => initController());
        }

        function deleteEquipmentJobsGroup(equipmentJobDefinitionGroupId){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'jobDefinitionGroupDelete',
                        destination: '#jobs-group-modal',
                        overlay: true
                    });

                    equipmentJobDefinitionService.deleteEquipmentJobDefinitionGroup(equipmentJobDefinitionGroupId).then(function(){
                        initController();
                        $rootScope.$broadcast('hideBusyIndicator', 'jobDefinitionGroupDelete');
                    }).catch(function(error){
                        $rootScope.$broadcast('hideBusyIndicator', 'jobDefinitionGroupDelete');
                        confirmDialogService.openWarningDialog($translate.instant('EQUIPMENT_JOB_GROUP_DELETE_WARNING_MSG'), $translate.instant('G_WARNING'));
                    });
                }
            });
        }
    }
})();
