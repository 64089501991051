(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('anonymousReportService', anonymousReportService);

    anonymousReportService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function anonymousReportService(
        $q,
        $http,
        serviceUrls,
    ) {
        const anonymousReportApiUrl = `${serviceUrls.webApiBaseUrl}/anonymousReport`;
        const ordersBaseApiUrl = `${serviceUrls.webApiBaseUrl}/order`;

        return {
            getOrderReportContent,
            downloadOrderRelatedVideo,
            moveFileFromArchivedToHotAccessTier,
            getArrivalReportContent,
            getOrderConfirmationContent,
            getOperationLogReportContent,
            getFinalReportContent,
            getMooringReportContent,
            getDelicingReportContent,
            getOrderWorkReportContent
        };

        function getOrderReportContent(orderId) {
            const deferred = $q.defer();

            $http
                .get(`${anonymousReportApiUrl}/orderReportContent?orderId=${orderId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function downloadOrderRelatedVideo(video) {
            const deferred = $q.defer();
            const requestUrl = `${ordersBaseApiUrl}/fileDownloadLink`;

            $http
                .post(requestUrl, video)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function moveFileFromArchivedToHotAccessTier(orderId, video) {
            const deferred = $q.defer();
            const requestUrl = `${ordersBaseApiUrl}/moveFileFromArchivedToHotAccessTier/${orderId}`;
            $http
                .post(requestUrl, video)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getArrivalReportContent(orderId, reportId) {
            const deferred = $q.defer();

            $http
                .get(`${anonymousReportApiUrl}/arrivalReportContent?orderId=${orderId}&reportId=${reportId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getOrderConfirmationContent(orderId) {
            const deferred = $q.defer();

            $http
                .get(`${anonymousReportApiUrl}/orderConfirmationContent?orderId=${orderId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getOperationLogReportContent(orderId, contractorId) {
            const deferred = $q.defer();

            $http
                .get(`${anonymousReportApiUrl}/operationLogReportContent?orderId=${orderId}&contractorId=${contractorId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getFinalReportContent(orderId) {
            const deferred = $q.defer();

            $http
                .get(`${anonymousReportApiUrl}/finalReportContent?orderId=${orderId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getMooringReportContent(orderId) {
            const deferred = $q.defer();

            $http
                .get(`${anonymousReportApiUrl}/mooringReportContent?orderId=${orderId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDelicingReportContent(orderId, reportType) {
            const deferred = $q.defer();

            $http
                .get(`${anonymousReportApiUrl}/delicingReportContent?orderId=${orderId}&reportType=${reportType}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getOrderWorkReportContent(contractorId, orderId) {
            const deferred = $q.defer();

            $http
                .get(`${anonymousReportApiUrl}/orderWorkReportContent?orderId=${orderId}&contractorId=${contractorId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
