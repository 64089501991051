(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CustomersAdministrationController', CustomersAdministrationController);

    CustomersAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'userService',
        'customerService',
        'dateUtility',
        'unitService',
        'commonUtility',
        'htmlElementService',
        'sanitizeService'
    ];

    function CustomersAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        userService,
        customerService,
        dateUtility,
        unitService,
        commonUtility,
        htmlElementService,
        sanitizeService
    ) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#customers-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#customers-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });


        var customersAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.customers),
                update: e => onCustomersGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'customerId',
                    fields: {
                        externalCustomerId: { editable: true, validation: { required: true } },
                        name: { editable: true, validation: { required: true } },
                        address: { editable: true },
                        postalCode: { editable: true },
                        contactperson: { editable: true },
                        phone: { editable: true },
                        cellular: { editable: true },
                        email: { editable: true },
                    }
                },
            }
        });

        const customersAdministrationGridOptions = langId => ({
            dataSource: customersAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'customerId',
                    hidden: true
                },
                {
                    field: 'externalCustomerId',
                    title: $translate.instant('ADMIN_CUSTOMERS_CUSTOMER_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.externalCustomerId ? dataItem.externalCustomerId : '';
                    },
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_CUSTOMERS_NAME', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.name ? dataItem.name : '';
                    },
                },
                {
                    field: 'address',
                    title: $translate.instant('ADMIN_CUSTOMERS_ADDRESS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.address ? dataItem.address : '';
                    },
                },
                {
                    field: 'postalCode',
                    title: $translate.instant('ADMIN_CUSTOMERS_POST_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.postalCode ? dataItem.postalCode : '';
                    },
                },
                {
                    field: 'contactperson',
                    title: $translate.instant('ADMIN_CUSTOMERS_CONTACT_PERSON', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.contactperson ? dataItem.contactperson : '';
                    },
                },
                {
                    field: 'phone',
                    title: $translate.instant('ADMIN_CUSTOMERS_PHONE', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.phone ? dataItem.phone : '';
                    },
                },
                {
                    field: 'cellular',
                    title: $translate.instant('ADMIN_CUSTOMERS_MOBIL_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.cellular ? dataItem.cellular : '';
                    },
                },
                {
                    field: 'email',
                    title: $translate.instant('ADMIN_CUSTOMERS_EMAIL', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.email ? dataItem.email : '';
                    },
                },
                {
                    command: [
                        {
                            name: 'editCustomer',
                            click: e => editCustomer(e),
                            template: '<a class="k-grid-editCustomer"><i class="fas fa-edit"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                 $scope.$applyAsync(function () {
                    sanitizeService.sanitizingGridContent(e);
                });
            },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true,
            detailInit: detailInit,
            detailTemplate: kendo.template($('#customer-unit-agreements-template').html()),
        });

        const unitAgreementsGridOptions = data => ({
            dataSource: data,
            noRecords: true,
            filterable: true,
            sortable: true,
            messages: { noRecords: $translate.instant('G_NORESULTS') },
            columns: [
                {
                    hidden: true,
                    field: 'id'
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_CUSTOMERS_DESCRIPTION'),
                    width: 400
                },
                {
                    field: 'standardPrice',
                    title: $translate.instant('ADMIN_CUSTOMERS_STANDARD_PRICE')
                },
                {
                    field: 'unitPrice',
                    title: $translate.instant('ADMIN_CUSTOMERS_UNIT_PRICE'),
                },
                {
                    field: 'fromDate',
                    title: $translate.instant('ADMIN_CUSTOMERS_DATE_FROM'),
                    template: function (dataItem) {
                        return (dataItem.fromDate ? dateUtility.formatDate(dataItem.fromDate) : '');
                    },
                },
                {
                    field: 'toDate',
                    title: $translate.instant('ADMIN_CUSTOMERS_DATE_TO'),
                    template: function (dataItem) {
                        return (dataItem.toDate ? dateUtility.formatDate(dataItem.toDate) : '');
                    },
                },
                {
                    field: 'comment',
                    title: $translate.instant('ADMIN_CUSTOMERS_COMMENT'),
                },
                {
                    field: 'lastCommentedById',
                    title: $translate.instant('ADMIN_CUSTOMERS_LAST_COMMENTED_BY'),
                    template: function (dataItem) {
                        const user = $scope.users.find(u => u.userId == dataItem.lastCommentedById);
                        return dataItem && dataItem.lastCommentedById && user && user.displayName ? user.displayName : "";
                    }
                },
                {
                    command: [
                        {
                            name: 'editCustomerUnitAgreement',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest("tr");
                                var parent = $(e.target).closest(".unit-agreements-grid");
                                var data = parent.data("kendoGrid").dataItem(row).toJSON();
                                editCustomerUnitAgreement(data.id, data.customerId, data.unit.contractorId, parent);
                            },
                            template: '<a class="k-grid-editCustomerUnitAgreement"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteCustomerUnitAgreement',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest("tr");
                                var parent = $(e.target).closest(".unit-agreements-grid");
                                var data = parent.data("kendoGrid").dataItem(row).toJSON();
                                deleteCustomerUnitAgreement(data.id, data.unit.contractorId, parent);
                            },
                            template: '<a class="k-grid-deleteCustomerUnitAgreement"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 80
                }
            ]
        });

        $scope.unitAgreementsGridOptions = unitAgreementsGridOptions([]);
        $scope.customersAdministrationGridOptions = customersAdministrationGridOptions($translate.use());
        $scope.customers = [];
        $scope.addNewCustomer = addNewCustomer;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editCustomer = editCustomer;

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.customersAdministrationGridOptions = customersAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.customersAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#customers-administration-grid')]);
            langId = code;

        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersAdministrationGrid',
                destination: '#customers-administration-grid'
            });
            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });
            toggleChangesButtons(true)
            let requests = {};
            requests.getCustomers = customerService.getCustomersByContractorId($rootScope.authData.contractorId);
            requests.getUsers = userService.getAllUser();
            $q.all(requests)
                .then(data => {
                    $scope.customers = data.getCustomers;
                    $scope.users = data.getUsers;
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.customersAdministrationGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                });
        }

        function openCustomersDetailsModal(formType, customer) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: customer ? customer.name : $translate.instant('ADMIN_CUSTOMERS_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/administration/customers/customers-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CustomersDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    customer: () => customer,
                }
            });
        }

        function addNewCustomer() {
            const window = openCustomersDetailsModal(formTypes.add);
            window.result.then(result => {
                if (result) {
                    $scope.customers = $scope.customers.concat([result]);
                    $scope.customersAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function editCustomer(event) {
            const window = openCustomersDetailsModal(formTypes.edit, getCustomerFromGrid(event));
            window.result.then(result => handleUpdate(result));
        }

        function getCustomerFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#customers-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function handleUpdate(customer) {
            if (customer) {
                $scope.customers = $scope.customers.map(u => (u.customerId === customer.customerId ? customer : u));
                $scope.customersAdministrationGridOptions.dataSource.read();
            }
        }

        function detailInit(e) {
            var customerId = e.data.customerId;
            customerService.getCustomersUnitAgreements(customerId).then(function (data) {
                extendCustomerUnitAgreements(data)
                data = data.sort((a, b) => a.description.localeCompare(b.description));
                $scope.customerUnitAgreements = data;
                const element = e.detailRow.find('.unit-agreements-grid');
                var table = $(element);
                $scope.unitAgreementsGridOptions = unitAgreementsGridOptions(getCustomerUnitAgreementDataSource(data));
                element.kendoGrid($scope.unitAgreementsGridOptions);
                $(`#${customerId}`)
                    .text($translate.instant('G_NEWITEM'))
                    .click(() => addCustomerUnitAgreement(customerId, e.data.contractorId, table));
            });
        }

        function extendCustomerUnitAgreements(unitAgreements) {
            _.forEach(unitAgreements, function (item) {
                if (item.unit) {
                    item.standardPrice = item.unit.standardPrice;
                    item.description = item.unit.description;
                }
            });
        }

        function getCustomerUnitAgreementDataSource(unitAgreements){
            return new kendo.data.DataSource({
                transport: {
                    read: e => e.success(unitAgreements),
                },
                schema: {
                    model: {
                        id: 'id',
                        fields: {
                            description: { editable: false},
                            standardPrice: { editable: false},
                            unitPrice: { editable: false},
                            fromDate: { editable: false, type: 'date'},
                            toDate: { editable: false, type: 'date'},
                            comment: { editable: false},
                            lastCommentedById: { editable: false},
                        }
                    },
                }
            });
        }

        function openCustomerUnitAgreement(currentFormType, customerUnitAgreement, customerId, units, contractorId, table) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_CUSTOMERS_ADD_UNIT_AGREEMENT'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/administration/customers/customers-unit-agreement-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'customersUnitAgreementModalController',
                resolve: {
                    currentFormType: () => currentFormType,
                    customerUnitAgreement: () => customerUnitAgreement,
                    customerId: () => customerId,
                    units: () => units
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateUnitAgreementGrid(customerId, contractorId, table);
                }
            });
        }

        function addCustomerUnitAgreement(customerId, contractorId, table) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersAdministrationGrid',
                destination: '#customers-administration-grid'
            });
            let requests = {};
            requests.getUnitsByContractorId = unitService.getUnitsAsDropdownByContractorId(contractorId);
            $q.all(requests)
                .then(data => {
                    var units = data.getUnitsByContractorId;
                    openCustomerUnitAgreement(formTypes.add, null, customerId, units, contractorId, table)
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                });
        }

        function editCustomerUnitAgreement(unitAgreementId, customerId, contractorId, table) {

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersAdministrationGrid',
                destination: '#customers-administration-grid'
            });
            customerService.getCustomerUnitAgreementById(unitAgreementId).then(function(unitAgreement){
                if(unitAgreement)
                {
                    let requests = {};
                    requests.getUnitsByContractorId = unitService.getUnitsAsDropdownByContractorId(contractorId);
                    $q.all(requests)
                        .then(data => {
                            var units = data.getUnitsByContractorId;
                            openCustomerUnitAgreement(formTypes.edit, unitAgreement, customerId, units, contractorId, table)
                        })
                        .catch(error => console.error(error))
                        .finally(() => {
                            $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                        });
                }
            });
        }

        function deleteCustomerUnitAgreement(unitAgreementId, contractorId, table) {

            customerService.getCustomerUnitAgreementById(unitAgreementId).then(function(unitAgreement){
                if(unitAgreement)
                {
                    const windowInstance = $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            resizable: false,
                            visible: false,
                            width: 300
                        },
                        templateUrl: 'app/shared/popups/confirm-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ConfirmModalController',
                        resolve: {
                            content: function(){
                                return $translate.instant('G_CONFIRM_DELETE');
                            },
                            primaryBtnText: function () {
                                return null;
                            },
                            secondaryBtnText: function () {
                                return null;
                            }
                        }
                    });
                    windowInstance.result.then(function(response) {
                        if (response) {
                            $rootScope.$broadcast('showBusyIndicator', {
                                id: 'customersAdministrationGrid',
                                destination: '#customers-administration-grid'
                            });
                            customerService.deleteUnitAgreement(unitAgreement.id).then(function(result){
                                if(result){
                                    updateUnitAgreementGrid(unitAgreement.customerId, contractorId, table);
                                }

                            })
                            .finally(function() {
                                $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                            });
                        }
                    });
                }
            });
        }

        function updateUnitAgreementGrid(customerId, contractorId, table){
            customerService.getCustomersUnitAgreements(customerId).then(function (data) {
                extendCustomerUnitAgreements(data)
                $scope.customerUnitAgreements = data;
                const element = table;
                element.kendoGrid('destroy').empty();
                $scope.unitAgreementsGridOptions = unitAgreementsGridOptions(getCustomerUnitAgreementDataSource(data));
                element.kendoGrid($scope.unitAgreementsGridOptions);
            });
        }

        function saveChanges() {
            $scope.customersAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.customersAdministrationGridOptions.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-customers');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-customers');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onCustomersGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersAdministrationGrid',
                destination: '#customers-administration-grid'
            });
            customerService.updateCustomer(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'customersAdministrationGrid');
                });

        }
    }
})();
