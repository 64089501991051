(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CaseListReportController', CaseListReportController);

    CaseListReportController.$inject = [
        '$q',
        '$state',
        '$scope',
        '$rootScope',
        '$translate',
        'caseService',
        'dateUtility',
        'kendoUtility',
        'workflowStatuses',
        'severityList',
        'noUser'
    ];

    function CaseListReportController(
        $q,
        $state,
        $scope,
        $rootScope,
        $translate,
        caseService,
        dateUtility,
        kendoUtility,
        workflowStatuses,
        severityList,
        noUser) {

        $scope.goBack = goBack;
        $scope.printReport = printReport;

        const caseGridColumns = langId => {
            let columns = [
                {
                    field: 'operationLogId',
                    hidden: true
                },
                {
                    title: $translate.instant('CASE_GRID_CASEID', null, null, langId),
                    field: 'caseId',
                    width: 90
                },
                {
                    title: $translate.instant('CASE_GRID_CASETITLE', null, null, langId),
                    field: 'title'
                },
                {
                    title: $translate.instant('G_VESSEL', null, null, langId),
                    field: 'department.name',
                    width: 100
                },
                {
                    title: $translate.instant('CASE_GRID_LOGACTION_DESCRIPTION', null, null, langId),
                    field: 'logAction.description',
                    width: 80
                },
                {
                    title: $translate.instant('CASE_GRID_DESCRIPTION', null, null, langId),
                    field: 'description'
                },
                {
                    title: $translate.instant('CASE_GRID_REGISTERED_BY', null, null, langId),
                    template: function (dataItem) {
                        if (Array.isArray(dataItem.assignedUser.toJSON())) {
                            var createdBy = dataItem.assignedUser.filter(function (user) {
                                return user.role === 'Registered';
                            });
                            if (createdBy.length > 0) {
                                return createdBy[0].user.displayName;
                            }
                            return '';
                        }
                        return '';
                    },
                    width: 120
                },
                {
                    title: $translate.instant('CASE_GRID_ASSIGNED_TO', null, null, langId),
                    template: function (dataItem) {
                        if (Array.isArray(dataItem.assignedUser.toJSON())) {
                            var assignedTo = dataItem.assignedUser.filter(function (user) {
                                return user.role === 'Assigned';
                            });
                            if (assignedTo.length > 0) {
                                return assignedTo[0].user ? assignedTo[0].user.displayName : noUser.displayName;
                            }
                            return '';
                        }
                        return '';
                    },
                    width: 120
                },
                {
                    title: $translate.instant('CASE_GRID_ASSIGNED_TO', null, null, langId),
                    field: 'createdOn',
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.createdOn);
                    },
                    width: 80
                },
                {
                    title: $translate.instant('CASE_GRID_DONE_ON', null, null, langId),
                    field: 'doneOn',
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.doneOn);
                    },
                    width: 80
                },
                {
                    title: $translate.instant('CASE_GRID_DUE_DATE', null, null, langId),
                    field: 'dueDate',
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.dueDate);
                    },
                    width: 80
                },
                {
                    title: $translate.instant('CASE_GRID_STATUS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.workflowStatuses && dataItem.workflowStatuses[0]) {
                            var status = workflowStatuses.filter(function (status) {
                                return status.value === dataItem.workflowStatuses[0].workflowStatusId;
                            })[0];
                            return status.text;
                        }
                        return '';
                    },
                    width: 120
                },
                {
                    title: $translate.instant('CASE_GRID_SEVERITY', null, null, langId),
                    template: function (dataItem) {
                        var severity = severityList.filter(function (severity) {
                            return dataItem.severity === severity.value;
                        })[0];
                        return severity.text;
                    },
                    width: 80
                },
                {
                    title: $translate.instant('CASE_GRID_COMPONENT', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.operationLogMapObjectReferences && dataItem.operationLogMapObjectReferences[0]) {
                            return dataItem.operationLogMapObjectReferences[0].operationLogMapObjectReferenceId;
                        }
                        return '';
                    },
                    hidden: true
                },
                {
                    title: $translate.instant('CASE_GRID_LOCATION', null, null, langId),
                    hidden: true
                },
                {
                    title: $translate.instant('CASE_GRID_DOCUMENT_COUNT', null, null, langId),
                    hidden: true
                }];
            return columns;
        };

        const caseGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (e) {
                        e.success($scope.deviations);
                    }
                },
                schema: {
                    model: {
                        id: 'operationLogId',
                        fields: {
                            doneOn: { editable: false, type: 'date' },
                            dueDate: { editable: false, type: 'date' },
                            createdOn: { editable: false, type: 'date' }
                        }
                    },
                    parse: function (d) {
                        $.each(d, function (idx, elem) {
                            elem.doneOn = dateUtility.formatDateTime(elem.doneOn);
                            elem.dueDate = dateUtility.formatDateTime(elem.dueDate);
                            elem.createdOn = dateUtility.formatDateTime(elem.createdOn);
                        });
                        return d;
                    }
                }
            }),
            columns: caseGridColumns(langId),
            scrollable: false,
            sortable: false,
            selectable: false,
            editable: false,
            resizable: false,
            filterable: false,
            noRecords: false,
            messages: {
                noRecords: $translate.instant('G_NODATA', null, null, langId)
            },
            height: kendoUtility.calculateRemainingSpace(),
            dataBound: function () {
                kendoUtility.adjustGridHeight('#case-grid', $scope.deviations.length);
            }
        });

        $scope.caseGridOptions = caseGridOptions($translate.use());

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
        }

        initController();
        var deviations = [];

        $scope.deviations = [];
        $scope.sumByStatuses = [];
        $scope.printView = false;

        function initController() {
            $('.report').toggleClass('wide-report');
            let requests = {};
            requests.getCasesByFilter = caseService.getCasesByFilter({
                assignedUserIds: !$scope.hasPermission('DeviationLogAdmin') ? [$rootScope.authData.userId] : [],
                contractorId: $rootScope.authData.contractorId,
                workFlowStatusIds: workflowStatuses.filter(function (status) {
                    return status.meaning !== 'Closed';
                }).map(function (status) {
                    return status.value;
                })
            })
            $q.all(requests)
                .then(data => {
                    deviations = extendDeviations(data.getCasesByFilter);
                    $scope.deviations = deviations

                    $scope.caseGridOptions.dataSource.read();
                });
        }

        function extendDeviations(deviations) {
            return deviations.map(function (deviation) {
                var noUserCopy = angular.copy(noUser);
                noUserCopy.role = 'Assigned';
                if (!deviation.assignedUser || !deviation.assignedUser[0]) {
                    deviation.assignedUser = [noUserCopy];
                }
                if (deviation.assignedUser && deviation.assignedUser.length === 1 && deviation.assignedUser[0].role === 'Registered') {
                    deviation.assignedUser.push(noUserCopy);
                }
                deviation.isExpired = deviation.dueDate && new Date(deviation.dueDate) < new Date(new Date().setHours(0, 0, 0, 0));

                if (dateUtility.isDefaultDate(deviation.dueDate)) {
                    deviation.dueDate = '';
                }

                return deviation;
            }).sort(function (a, b) {
                return new Date(b.createdOn) - new Date(a.createdOn);
            });
        }

        function goBack() {
            $state.go('main.orderReport', {
                ContractorId: $scope.contractorId,
                OrderId: $scope.orderId
            });
        }

        function printReport() {
            var printUrl = window.location.origin + '/case-list-report-print?PrintView=true'
            var fileName = 'Case list report - ' + moment().format('DD.MM.YYYY') + '.pdf';

            var pdfUrl = window.location.origin + '/api/print?printUrl=' + encodeURIComponent(printUrl)
                + '&fileName=' + fileName;
            window.open(pdfUrl, '_blank');
        }
    }
})();
