angular.module('NaviaqWebApp').factory('orderTrackLayerFactory', ['$q', '$rootScope', 'esriLoader',
    function ($q, $rootScope, esriLoader) {
        var readyDeferred = null;

        /**
         * Initializes the order track layer ready function.
         */
        var orderTrackLayerFactory = {
            createLayerOnAdd: true,
            layerCreationInProgress: false,

            ready: function () {
                if (readyDeferred === null) {
                    readyDeferred = $q.defer();
                }

                return readyDeferred.promise;
            }
        };

        esriLoader.require([
            'esri/Color',
            'esri/layers/GraphicsLayer',
            'esri/Graphic',
            'esri/geometry/Point',
            'esri/geometry/Polyline',
            'esri/geometry/ScreenPoint',
            'esri/symbols/SimpleLineSymbol',
            'esri/geometry/SpatialReference',
        ], function (Color, GraphicsLayer, Graphic, Point, Polyline, ScreenPoint, SimpleLineSymbol, SpatialReference) {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }

            orderTrackLayerFactory.createLayer = function () {
                orderTrackLayerFactory.layerCreationInProgress = true;

                var layer = new GraphicsLayer();
                layer.name = 'OrderTrackLayer';
                layer.zIndex = 4;
                //Define additional functions
                layer.toggleVisibility = function () {
                    layer.visible = !layer.visible;
                };

                $rootScope.$on('drawTracking', function(event, geodataLines) {
                    layer.removeAll();

                    for (var i = 0; i < geodataLines.length; ++i) {
                        var coordinates = geodataLines[i].coordinates;
                        var polyLine = new Polyline(new SpatialReference({ wkid: 32233 }));
                        var pathPoints = [];

                        //Create one polyline for each subline
                        for (var j = 0; j < coordinates.length; j++) {
                            var pointGeometry = new Point(coordinates[j].X, coordinates[j].Y, new SpatialReference({ wkid: 32233 }));
                            pathPoints.push(pointGeometry);
                        }

                        polyLine.addPath(pathPoints);

                        var color;
                        if (geodataLines[i].properties && geodataLines[i].properties.valve === 'Open') {
                            color = new Color([98, 178, 0]);
                        } else {
                            color = new Color([17, 0, 180]);
                        }

                        var simpleLineSymbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, color, 1);
                        simpleLineSymbol.width = 2;

                        var polyLineGraphic = new Graphic(polyLine, simpleLineSymbol, {});
                        layer.add(polyLineGraphic);
                    }
                });

                $rootScope.$on('clearTracking', function (event) {
                    layer.removeAll();
                });

                orderTrackLayerFactory.layerCreationInProgress = false;

                return layer;
            };

            readyDeferred.resolve(orderTrackLayerFactory);
        });

        return orderTrackLayerFactory;
    }]);
