(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationInfoDetailsController', LocationInfoDetailsController);

    LocationInfoDetailsController.$inject = ['$q', '$scope', '$rootScope', '$stateParams', 'locationService'];

    function LocationInfoDetailsController($q, $scope, $rootScope, $stateParams, locationService) {
        $scope.LocationName = '';
        $scope.LocationNumber = '';
        $scope.LocationOwner = '';
        $scope.LocationMtb = '';
        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationLog',
                destination: '#right-pane',
                overlay: false
            });

            let requests = {};
            requests.getLocationBaseByLocationNumber = locationService.getLocationBaseByLocationNumber($stateParams.LocationNumber);
            if(!locationService.isDefaultLocation($stateParams.LocationNumber)){
                requests.getLocationMTBFromBarentswatch = locationService.getLocationMTBFromBarentswatch($stateParams.LocationNumber);
            }
            $q.all(requests)
                .then(data => {
                    var location = data.getLocationBaseByLocationNumber;
                    if (location) {
                        $scope.locationStatus = location.status;
                        $scope.LocationName = location.name;
                        $scope.LocationNumber = location.locationNumber;
                        $scope.ShouldGenerateWorkOrderOnComponents = location.shouldGenerateWorkOrderOnComponents;
                        $scope.WorkOrderCreationDays = location.workOrderCreationDays;

                        _.each(location.locationLocationOwners, function (owner) {
                            if ($scope.LocationOwner !== '') {
                                $scope.LocationOwner += ', ';
                            }
                            $scope.LocationOwner += owner.locationOwner.name;
                        });
                    }

                    $scope.LocationMtb = data.getLocationMTBFromBarentswatch
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
                });
        }
    }
})();
