(function () {
    'use strict';

    angular
      .module('NaviaqWebApp').directive('componentEquipmentSelector', componentEquipmentSelector);

    componentEquipmentSelector.$inject = [];

    function componentEquipmentSelector() {
        return {          
            controller: ['$scope', componentEquipmentSelector],
            replace: true,
            restrict: 'E',
            templateUrl: 'app/shared/directives/component-equipment-selector.html',
            scope: {
                componentEquipmentSelectorId: "=componentEquipmentSelectorId",
                componentTreeData: "=componentTreeData",
                equipmentTreeData: "=equipmentTreeData",
                placeholder: "=placeholder",
                footerTemplate: "=footerTemplate",
                selectedIds: "=selectedIds",
                dataLoadingProcess: "=dataLoadingProcess",
                isReadOnly: "=isReadOnly"
            }
        };

        function componentEquipmentSelector($scope) {
            $scope.dropdownId = $scope.componentEquipmentSelectorId + '_dropdown';
            $scope.displayComponentTree = true;
            $scope.isMultiselect = true;
            $scope.treeData = [];
            $scope.dataLoaded = false;
         
            var oldEquipmentSelectedItem = null;
            var oldComponentsSelectedItems = [];

            $scope.$watch('componentTreeData', function() {
                if($scope.displayComponentTree && $scope.componentTreeData){
                    $scope.treeData = $scope.componentTreeData;
                    $scope.isMultiselect = true;
                }             
            });

            $scope.$watch('equipmentTreeData', function() {
                if(!$scope.displayComponentTree  && $scope.equipmentTreeData){
                    $scope.treeData = $scope.equipmentTreeData;
                    $scope.isMultiselect = false;

                }   
            });

            $scope.$watch('selectedIds', function() {
                if (Array.isArray($scope.selectedIds) && $scope.selectedIds.length > 0 && findItemByIdInTree($scope.componentTreeData, $scope.selectedIds[0]) && !$scope.displayComponentTree) {
                    $scope.treeData =  $scope.componentTreeData;
                    $scope.isMultiselect = true;
                    $scope.displayComponentTree = true;
                } else if (!Array.isArray($scope.selectedIds) && findItemByIdInTree($scope.equipmentTreeData, $scope.selectedIds) && $scope.displayComponentTree) {
                    $scope.treeData = $scope.equipmentTreeData;
                    $scope.isMultiselect = false;
                    $scope.displayComponentTree = false;
                }
            });

            $scope.toggleChanged = function(){
                if($scope.displayComponentTree){                   
                    oldEquipmentSelectedItem = JSON.parse(JSON.stringify($scope.selectedIds));
                    $scope.treeData = $scope.componentTreeData;
                    $scope.isMultiselect = true;
                    $scope.selectedIds = JSON.parse(JSON.stringify(oldComponentsSelectedItems));
                } else {
                    oldComponentsSelectedItems = JSON.parse(JSON.stringify($scope.selectedIds));
                    $scope.treeData = $scope.equipmentTreeData;
                    $scope.isMultiselect = false;
                    $scope.selectedIds = JSON.parse(JSON.stringify(oldEquipmentSelectedItem));
                }
            };

            function findItemByIdInTree(list, id) {
                for (const n of list) {
                  const res = n.value === id || n == id ? n : findItemByIdInTree(n.items, id);
                  if (res) return res;
                }
            }
        }
    }
})();