angular.module('NaviaqWebApp').factory('vesselLayerFactory', ['$q', '$rootScope', 'esriLoader', 'authService', 'departmentService',
function ($q, $rootScope, esriLoader, authService, departmentService) {
    var readyDeferred = null;

    /**
     * Initializes the vessel layer ready function.
     */
    var vesselLayerFactory = {
        createLayerOnAdd: true,

        ready: function () {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }

            return readyDeferred.promise;
        }
    }

    esriLoader.require([
        'esri/layers/GraphicsLayer',
        'esri/Graphic',
        'esri/symbols/PictureMarkerSymbol',
        'esri/geometry/Point',
        'esri/geometry/ScreenPoint'
    ], function (GraphicsLayer, Graphic, PictureMarkerSymbol, Point, ScreenPoint) {
        if (readyDeferred === null) {
            readyDeferred = $q.defer();
        }

        vesselLayerFactory.createLayer = function () {
            var layer = new GraphicsLayer();
            layer.name = 'VesselLayer';
            layer.zIndex = 3;

            //Define additional functions
            layer.toggleVisibility = function () {
                layer.visible = !layer.visible;
            }

            layer.onMouseMove = function (evt, mapView, hitResponse) {
                if (hitResponse) {
                    var graphic = hitResponse.results[0].graphic;

                    if (graphic && graphic.layer && graphic.layer.name === layer.name) {
                        var point = new Point(graphic.geometry.x, graphic.geometry.y);
                        var graphicScreenPoint = mapView.toScreen(point);

                        $rootScope.$broadcast('showVesselPopup', {
                            left: graphicScreenPoint.x + mapView.position[0] + 20,
                            top: graphicScreenPoint.y + mapView.position[1] + 10,
                            timestamp: graphic.attributes.timestamp || '',
                            shipname: graphic.attributes.shipname || '',
                            mmsi: graphic.attributes.mmsi || '',
                            speed: graphic.attributes.speed || '',
                            course: graphic.attributes.course || ''
                        });
                    }
                } else {
                    $rootScope.$broadcast('hideVesselPopup');
                }
            };

            layer.onRightClick = function (evt, mapView, hitResponse) {
                if (hitResponse) {
                    var graphic = hitResponse.results[0].graphic;

                    if (graphic.layer.name === layer.name) {
                        var point = new Point(graphic.geometry.x, graphic.geometry.y);
                        var screenPoint = mapView.toScreen(point);

                        $rootScope.$broadcast('showContextMenu', {
                            item: graphic.attributes,
                            mapPosition: mapView.position,
                            screenPoint: screenPoint,
                            type: 'Vessel'
                        });
                    }
                }
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'initVesselLayer',
                destination: '#main-view',
                message: 'Henter fartøy',
                overlay: false,
                positionClass: 'bottom-left-inline'
            });

            var authData = authService.getAuthData();
            if (authData.isAuth) {
                //Initialize graphics
                departmentService.getVesselLocations(authService.getAuthData.contractorId).then(function(vessels) {
                    for (var i = 0; i < vessels.length; ++i) {
                        var vesselSymbol = new PictureMarkerSymbol({
                            url: 'img/icons/orange_boat_32_32.png',
                            width: '24px',
                            height: '24px'
                        });

                        if (vessels[i].course) {
                            vesselSymbol.angle = parseInt(vessels[i].course);
                        }

                        var markerGraphic = new Graphic({
                            geometry: new Point(vessels[i].lon, vessels[i].lat),
                            symbol: vesselSymbol,
                            attributes: {
                                lat: vessels[i].lastChar,
                                lon: vessels[i].lon,
                                course: vessels[i].course,
                                mmsi: vessels[i].mmsi,
                                shipname: vessels[i].shipname,
                                speed: vessels[i].speed,
                                timestamp: vessels[i].timeStamp,
                                departmentId: vessels[i].departmentId,
                                contractorId: vessels[i].contractorId,
                                callSign: vessels[i].callsign,
                                length: vessels[i].length,
                                width: vessels[i].width,
                                draught: vessels[i].draught,
                                grt: vessels[i].grt,
                                dwt: vessels[i].dwt,
                                yearBuilt: vessels[i].yearBuilt
                            }
                        });

                        layer.add(markerGraphic);
                    }

                    $rootScope.$broadcast('hideBusyIndicator', 'initVesselLayer');
                }, function() {
                    $rootScope.$broadcast('hideBusyIndicator', 'initVesselLayer');
                });
            } else {
                $rootScope.$broadcast('hideBusyIndicator', 'initVesselLayer');
            }

            return layer;
        }

        readyDeferred.resolve(vesselLayerFactory);
    });

    return vesselLayerFactory;
}]);
