(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('VesselDetailsModalController', VesselDetailsModalController);

    function VesselDetailsModalController($q, $scope, $rootScope, $windowInstance, $translate, htmlElementService, authService, departmentService, formTypes, DepartmentStatus,
        formType, department, departments, technicalManagers, locations, departmentTypes, mediaService, departmentMainTypes, $kWindow, confirmDialogService, bargeService, supplierService, electricity, users) {
        kendo.culture('no-NO');
        $scope.authData = authService.getAuthData();
        $scope.department = null;
        $scope.formType = formType;
        $scope.isEditForm = formType === formTypes.edit ? true : false;
        $scope.parentDepartments = [];
        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;
        $scope.uploadVesselFile = uploadVesselFile;
        $scope.handleFiles = handleFiles;
        $scope.selectedDepartmentChildren = [];
        $scope.barges = [];
        $scope.suppliers = [];
        $scope.bargeInformation = null;
        $scope.reason = '';

        $scope.technicalManagerOptions = {
            dataSource: new kendo.data.DataSource({
                data: technicalManagers,
                schema: {
                    parse: function (response) {
                        for (var i = 0; i < response.length; i++) {
                            var item = response[i];
                            item.Name = item.firstName + ' ' + item.lastName;
                        }
                        return response;
                    }
                }
            }),
            dataValueField: 'personId',
            dataTextField: 'Name',
            optionLabel: $translate.instant('ADMIN_VESSEL_TECHNICAL_MANAGER_SELECT')
        };

        $scope.usersOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (e) {
                        e.success(users);
                    }
                }
            }),
            dataValueField: 'userId',
            dataTextField: 'displayName',
            optionLabel: $translate.instant('ADMIN_VESSEL_TECHNICAL_MANAGER_SELECT')
        };

        $scope.departmentMainTypeOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (e) {
                        e.success(departmentMainTypes);
                    }
                }
            }),
            dataValueField: 'value',
            dataTextField: 'text',
            change: function () {
                setValidationRules();
                setFieldsVisibility();
                if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 3){
                    loadBargesByContractorId();
                }
            }
        };

        $scope.departmentStatusOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (e) {
                        e.success(getDepartmentStatuses());
                    }
                }
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            change: function () {
                if ($scope.selectedDepartmentStatus && $scope.selectedDepartmentStatus.id !== DepartmentStatus.Active) {
                    // required
                    vesselForm.reason.$valid = false;
                    vesselForm.reason.required = true;
                } else{
                    vesselForm.reason.$valid = true;
                    vesselForm.reason.required = false;
                }
            }
        };

        const getDistinctAndSortedLocations = (locations) => {
            let distinctLocations = [...new Map(locations.map((item) => [item['locationNumber'], item])).values()];
            return _.sortBy(distinctLocations, 'name');
        }

        $scope.locationOptions = {
            dataSource: new kendo.data.DataSource({
                data: getDistinctAndSortedLocations(locations)
            }),
            dataTextField: 'name',
            dataValueField: 'locationNumber',
            optionLabel: $translate.instant('G_SELECT_LOCATION')
        };

        $scope.bargeOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.barges)
                }
            }),
            dataTextField: 'name',
            dataValueField: 'bargeId',
            optionLabel: $translate.instant('ADMIN_VESSEL_DEPARTMENT_BARGE_SELECT')
        };

        $scope.electricityOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success(electricity)
                }
            }),
            dataTextField: 'text',
            dataValueField: 'value'
        };

        $scope.supplierOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.suppliers)
                }
            }),
            dataTextField: 'name',
            dataValueField: 'supplierId',
            optionLabel: $translate.instant('ADMIN_VESSEL_DEPARTMENT_SUPPLIER_SELECT')
        };

        $scope.departmentTypeOptions = {
            dataSource: new kendo.data.DataSource({
                data: departmentTypes
            }),
            dataValueField: 'id',
            dataTextField: 'name_NO',
            optionLabel: $translate.instant('ADMIN_VESSEL_DEPARTMENT_TYPE_SELECT')
        };

        $scope.parentDepartmentOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (e) {
                        e.success($scope.parentDepartments);
                    }
                }
            }),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            optionLabel: $translate.instant('ADMIN_VESSEL_PARENT_DEPARTMENT_SELECT')
        };

        $scope.fieldVisibility = {
            externalId: false,
            departmentType : false,
            parentDepartmentSelector: true,
            workHoursPerDay: true,
            location: false,
            mmsi: false,
            callSign: false,
            departmentFreeType: false,
            shipyardNumber: false,
            ownerName: false,
            ownerAddress: false,
            ownerPhone: false,
            ownerWebAddress: false,
            technicalManager: false,
            departmentPhone: false,
            departmentEmail: false,
            departmentLocation: false,
            deliveryDate: false,
            warrantyExpireDate: false,
            bargeSelector: false,
            supplierSelector: false,
            builtYear: false,
            builtNumber: false,
            widthInMeters: false,
            depthInMeters: false,
            lengthWithDepotInMeters: false,
            lengthWithoutDepotInMeters: false,
            silageCapacityInSquareMeters: false,
            electricitySelector: false,
            buildingMaterial: false,
            attachmentPointInParagraph: false,
            weightEmpty: false,
            lightShipWeight: false,
            userSelector: false
        };

        initController();
        function initController() {
            htmlElementService.checkIfElementExists("vessel-form")
                .then(function (result) {
                    initValidator();
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'vesselsModalBusyIndicator',
                        destination: '#vessel-form',
                        overlay: true
                    });

                    var reuqests = {}
                    if(department && department.bargeId){
                        reuqests.getBargesByContractorId = bargeService.getBargesByContractorId($scope.authData.contractorId)
                        reuqests.getBargeSuppliers = supplierService.getBargeSuppliers()
                    }

                    $q.all(reuqests).then(function(result){
                        if(department && department.bargeId){
                            $scope.barges = result.getBargesByContractorId;
                            $scope.bargeOptions.dataSource.read();
                            $scope.suppliers = result.getBargeSuppliers;
                            $scope.supplierOptions.dataSource.read();
                        }

                        var childDepartmentIds = [];
                        if(department){
                            getChildDepartments(department, childDepartmentIds);
                        }
                        var parentDepartments = departments.filter(d => (department != null ? d.departmentId !== department.departmentId : true) && !childDepartmentIds.includes(d.departmentId));
                        $scope.parentDepartments = _.orderBy(parentDepartments,'name');
                        $scope.isActive = $scope.isEditForm ? department.isActive : true;
                        $scope.parentDepartmentOptions.dataSource.read();

                        // set normal work hours per day min/max value
                        var numerictextbox = $("#department-nomal-work-hours").data("kendoNumericTextBox");
                        numerictextbox.min(0);
                        numerictextbox.max(24);

                        if (formType === formTypes.edit && department) {
                            var childDepartmentIds = [];
                            getChildDepartments(department, childDepartmentIds);
                            var parentDepartments = departments.filter(d => (department != null ? d.departmentId !== department.departmentId : true) && !childDepartmentIds.includes(d.departmentId));
                            $scope.parentDepartments = _.orderBy(parentDepartments,'name');
                            $scope.parentDepartmentOptions.dataSource.read();
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    initModal(department);
                                    setDepType();
                                    $rootScope.$broadcast('hideBusyIndicator', 'vesselsModalBusyIndicator');
                                });
                            }, 800);
                        }
                        else {
                            $scope.selectedDepartmentStatus = getDepartmentStatuses().find(x => x.id === 1);
                            $scope.selectedMainDepartmentType = departmentMainTypes[2];
                            $scope.eligibleForOrders = true;
                            $rootScope.$broadcast('hideBusyIndicator', 'vesselsModalBusyIndicator');
                        }
                    })
                    .catch(function(){
                        $rootScope.$broadcast('hideBusyIndicator', 'vesselsModalBusyIndicator');
                    });
                });
        }

        function loadBargesByContractorId(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'vesselsModalBusyIndicator',
                destination: '#vessel-form',
                overlay: true
            });

            var reuqests = {
                getBargesByContractorId: bargeService.getBargesByContractorId($scope.authData.contractorId),
                getBargeSuppliers: supplierService.getBargeSuppliers()
            }

            $q.all(reuqests).then(function(result){
                $scope.barges = result.getBargesByContractorId;
                $scope.bargeOptions.dataSource.read();
                $scope.suppliers = result.getBargeSuppliers;
                $scope.supplierOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'vesselsModalBusyIndicator');
            })
            .catch(function(){
                $rootScope.$broadcast('hideBusyIndicator', 'vesselsModalBusyIndicator');
            });
        }

        function initValidator(){
            $scope.validator = $("#vessel-form");
                        $scope.validator.kendoValidator({
                            rules: {
                                externalIdMaxLength: function(input) {
                                    if(input.is('[name="department-external-id"]')){
                                        var val = input.val();
                                        var maxIntValue = 2147483647;
                                      if(val > maxIntValue){
                                        return false;
                                      } else {
                                          return true;
                                      }
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            messages: {
                                externalIdMaxLength: function(input) {
                                    return $translate.instant('ADMIN_VESSEL_GRID_ID_ERROR_MSG');
                                },
                            }
                        });
        }

        function setDepType(){
            if(department.mmsi && !department.locationNumber && !department.bargeId){
                $scope.selectedMainDepartmentType = departmentMainTypes[0];
            } else if(department.locationNumber && !department.mmsi && !department.bargeId) {
                $scope.selectedMainDepartmentType = departmentMainTypes[1];
            } else if(!department.mmsi && !department.locationNumber && !department.bargeId){
                $scope.selectedMainDepartmentType = departmentMainTypes[2];
            } else if(department.bargeId){
                $scope.selectedMainDepartmentType = departmentMainTypes[3];
            } else {
                $scope.selectedMainDepartmentType = departmentMainTypes[2];
            }
            setValidationRules();
            setFieldsVisibility();
        }

        function setFieldsVisibility(){
            if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 0){
                $scope.$applyAsync(function () {
                    $scope.fieldVisibility = {
                        externalId: true,
                        departmentType: true,
                        parentDepartmentSelector: true,
                        workHoursPerDay: true,
                        location: false,
                        mmsi: true,
                        callSign: true,
                        departmentFreeType: true,
                        shipyardNumber: true,
                        ownerName: true,
                        ownerAddress: true,
                        ownerPhone: true,
                        ownerWebAddress: true,
                        technicalManager: true,
                        departmentPhone: true,
                        departmentEmail: true,
                        departmentLocation: true,
                        deliveryDate: true,
                        warrantyExpireDate: true,
                        bargeSelector: false,
                        supplierSelector: false,
                        builtYear: false,
                        builtNumber: false,
                        widthInMeters: false,
                        depthInMeters: false,
                        lengthWithDepotInMeters: false,
                        lengthWithoutDepotInMeters: false,
                        silageCapacityInSquareMeters: false,
                        electricitySelector: false,
                        buildingMaterial: false,
                        attachmentPointInParagraph: false,
                        weightEmpty: false,
                        lightShipWeight: false,
                        userSelector: false
                    };
                });
            } else if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 1){
                $scope.$applyAsync(function () {
                    $scope.fieldVisibility = {
                        externalId: true,
                        departmentType: false,
                        parentDepartmentSelector: true,
                        workHoursPerDay: true,
                        location: true,
                        mmsi: false,
                        callSign: false,
                        departmentFreeType: false,
                        shipyardNumber: false,
                        ownerName: false,
                        ownerAddress: false,
                        ownerPhone: false,
                        ownerWebAddress: false,
                        technicalManager: false,
                        departmentPhone: false,
                        departmentEmail: false,
                        departmentLocation: false,
                        deliveryDate: false,
                        warrantyExpireDate: false,
                        bargeSelector: false,
                        supplierSelector: false,
                        builtYear: false,
                        builtNumber: false,
                        widthInMeters: false,
                        depthInMeters: false,
                        lengthWithDepotInMeters: false,
                        lengthWithoutDepotInMeters: false,
                        silageCapacityInSquareMeters: false,
                        electricitySelector: false,
                        buildingMaterial: false,
                        attachmentPointInParagraph: false,
                        weightEmpty: false,
                        lightShipWeight: false,
                        userSelector: false
                    };
                });
            } else if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 2){
                $scope.$applyAsync(function () {
                    $scope.fieldVisibility = {
                        externalId: true,
                        departmentType: false,
                        parentDepartmentSelector: true,
                        workHoursPerDay: true,
                        location: false,
                        mmsi: false,
                        callSign: false,
                        departmentFreeType: false,
                        shipyardNumber: false,
                        ownerName: false,
                        ownerAddress: false,
                        ownerPhone: false,
                        ownerWebAddress: false,
                        technicalManager: false,
                        departmentPhone: false,
                        departmentEmail: false,
                        departmentLocation: false,
                        deliveryDate: false,
                        warrantyExpireDate: false,
                        bargeSelector: false,
                        supplierSelector: false,
                        builtYear: false,
                        builtNumber: false,
                        widthInMeters: false,
                        depthInMeters: false,
                        lengthWithDepotInMeters: false,
                        lengthWithoutDepotInMeters: false,
                        silageCapacityInSquareMeters: false,
                        electricitySelector: false,
                        buildingMaterial: false,
                        attachmentPointInParagraph: false,
                        weightEmpty: false,
                        lightShipWeight: false,
                        userSelector: false
                    };
                });
            } else if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 3){
                $scope.$applyAsync(() => {
                    $scope.fieldVisibility = {
                        externalId: true,
                        departmentType: false,
                        parentDepartmentSelector: true,
                        workHoursPerDay: false,
                        location: false,
                        mmsi: false,
                        callSign: false,
                        departmentFreeType: false,
                        shipyardNumber: false,
                        ownerName: false,
                        ownerAddress: false,
                        ownerPhone: false,
                        ownerWebAddress: false,
                        technicalManager: false,
                        departmentPhone: true,
                        departmentEmail: true,
                        departmentLocation: false,
                        deliveryDate: true,
                        warrantyExpireDate: true,
                        bargeSelector: true,
                        supplierSelector: true,
                        builtYear: true,
                        builtNumber: true,
                        widthInMeters: true,
                        depthInMeters: true,
                        lengthWithDepotInMeters: true,
                        lengthWithoutDepotInMeters: true,
                        silageCapacityInSquareMeters: true,
                        electricitySelector: true,
                        buildingMaterial: true,
                        attachmentPointInParagraph: true,
                        weightEmpty: true,
                        lightShipWeight: true,
                        userSelector: true
                    };
                });
            }
        }

        function setValidationRules() {
            if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 0){
                // required
                vesselForm.departmentMMSI.$valid = false;
                vesselForm.departmentMMSI.required = true;
                // not reqquired
                vesselForm.location.$valid = true;
                vesselForm.location.required = false;
            } else if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 1){
                // required
                vesselForm.location.$valid = false;
                vesselForm.location.required = true;

                    // not reqquired
                vesselForm.departmentMMSI.$valid = true;
                vesselForm.departmentMMSI.required = false;
            } else if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 2){
                // not reqquired
                vesselForm.departmentMMSI.$valid = true;
                vesselForm.departmentMMSI.required = false;
                vesselForm.location.$valid = true;
                vesselForm.location.required = false;
            } else if($scope.selectedMainDepartmentType && $scope.selectedMainDepartmentType.value == 3){
                // required
                vesselForm.barge.$valid = false;
                vesselForm.barge.required = true;

                // not reqquired
                vesselForm.departmentMMSI.$valid = true;
                vesselForm.departmentMMSI.required = false;
                vesselForm.location.$valid = true;
                vesselForm.location.required = false;
            }
        }

        function initModal(department) {
            $scope.department = department;
            $scope.originalLocationNumber = $scope.department.locationNumber
            $scope.deliveryDate = department.departmentInfo && department.departmentInfo.deliveryDate ? moment(department.departmentInfo.deliveryDate, 'DD.MM.YYYY') : null;
            $scope.warrantyExpireDate = department.departmentInfo && department.departmentInfo.warrantyExpireDate ? moment(department.departmentInfo.warrantyExpireDate, 'DD.MM.YYYY') : null;

            $scope.selectedTechnicalManager = technicalManagers.find(function (manager) {
                return manager.personId == department.departmentInfo.technicalManagerId;
            });

            $scope.department.location = locations.find(function (location) {
                return location.locationNumber == department.locationNumber;
            });

            $scope.selectedParentDepartment = $scope.parentDepartments.find(function (parent) {
                return parent.departmentId == department.parentDepartmentId;
            });

            $scope.selectedDepartmentType = departmentTypes.find(function (type) {
                return type.id == department.departmentTypeId;
            });
            $scope.eligibleForOrders = (department.status === 0 ? true : false);

            if(department.barge != null && department.barge.bargeInformation != null){
                $scope.bargeInformation = department.barge.bargeInformation;
                $scope.selectedUser = users.find(x => x.userId == department.barge.bargeInformation.technicalManagerId);
                if($scope.bargeInformation.supplierId != null){
                    $scope.bargeInformation.supplier = $scope.suppliers.find(x => x.supplierId == $scope.bargeInformation.supplierId);
                }

                if($scope.bargeInformation.electricity){
                    $scope.bargeInformation.electricityObj = electricity.find(x => x.value == $scope.bargeInformation.electricity);
                }

                var hasDepartmentBargeInList = $scope.barges.some(x => x.bargeId == department.barge.bargeId);
                if(!hasDepartmentBargeInList){
                    $scope.barges.push(department.barge);
                    $scope.bargeOptions.dataSource.read();
                }
            }

            $scope.selectedDepartmentStatus = getDepartmentStatuses().find(function (status) {
                return status.id == department.status;
            });
            $scope.eligibleForOrders = department.canHaveOrders;
        }

        function createDepartmentObj(){
            if($scope.selectedMainDepartmentType.value == 0){
                // Vessel
               var department = {
                    departmentId : $scope.department ? $scope.department.departmentId : '00000000-0000-0000-0000-000000000000',
                    parentDepartmentId: ($scope.selectedParentDepartment ? $scope.selectedParentDepartment.departmentId : null),
                    locationNumber: null,
                    externalId: $scope.department.externalId,
                    name: $scope.department.name,
                    contractorId: $scope.authData.contractorId,
                    departmentTypeId: ($scope.selectedDepartmentType && $scope.selectedDepartmentType.id != '' ? $scope.selectedDepartmentType.id : null),
                    canHaveOrders: $scope.eligibleForOrders === true,
                    mmsi: $scope.department.mmsi,
                    normalWorkHoursPerDay: $scope.department.normalWorkHoursPerDay ? $scope.department.normalWorkHoursPerDay.toString().replace(',', '.') : null,
                    departmentInfo: $scope.department.departmentInfo ? $scope.department.departmentInfo : {},
                    status: $scope.selectedDepartmentStatus && $scope.selectedDepartmentStatus.id !== '' ? $scope.selectedDepartmentStatus.id : null,
                    reason: $scope.reason
               };
               department.departmentInfo.technicalManagerId = $scope.selectedTechnicalManager && $scope.selectedTechnicalManager.personId != '' ? $scope.selectedTechnicalManager.personId : null;
               department.departmentInfo.deliveryDate = ($scope.deliveryDate ? moment($scope.deliveryDate).format('YYYY-MM-DDT00:00:00') : null);
               department.departmentInfo.warrantyExpireDate = ($scope.warrantyExpireDate ? moment($scope.warrantyExpireDate).format('YYYY-MM-DDT00:00:00') : null);
               return department;
            } else if($scope.selectedMainDepartmentType.value == 1){
                //location
                var locDep = {
                    departmentId : $scope.department ? $scope.department.departmentId : '00000000-0000-0000-0000-000000000000',
                    parentDepartmentId: ($scope.selectedParentDepartment ? $scope.selectedParentDepartment.departmentId : null),
                    locationNumber: $scope.department && $scope.department.location
                        && $scope.department.location.locationNumber ? $scope.department.location.locationNumber : null,
                    externalId: $scope.department && $scope.department.externalId ? $scope.department.externalId : null,
                    name: $scope.department.name,
                    contractorId: $scope.authData.contractorId,
                    departmentTypeId: null,
                    canHaveOrders: $scope.eligibleForOrders === true,
                    mmsi: null,
                    normalWorkHoursPerDay: $scope.department.normalWorkHoursPerDay ? $scope.department.normalWorkHoursPerDay.toString().replace(',', '.') : null,
                    departmentInfo: {
                        departmentId: $scope.department ? $scope.department.departmentId : '00000000-0000-0000-0000-000000000000',
                        defaultMediaId: $scope.department.departmentInfo && $scope.department.departmentInfo.defaultMediaId ? $scope.department.departmentInfo.defaultMediaId : null
                    },
                    status: $scope.selectedDepartmentStatus && $scope.selectedDepartmentStatus.id !== '' ? $scope.selectedDepartmentStatus.id : null,
                    reason: $scope.reason
               };
               return locDep;
            } else if($scope.selectedMainDepartmentType.value == 2){
                //org
                var orgDep = {
                    departmentId : $scope.department ? $scope.department.departmentId : '00000000-0000-0000-0000-000000000000',
                    parentDepartmentId: ($scope.selectedParentDepartment ? $scope.selectedParentDepartment.departmentId : null),
                    locationNumber: null,
                    externalId: $scope.department && $scope.department.externalId ? $scope.department.externalId : null,
                    name: $scope.department && $scope.department.name ? $scope.department.name : null,
                    contractorId: $scope.authData.contractorId,
                    departmentTypeId: null,
                    canHaveOrders: $scope.eligibleForOrders === true,
                    mmsi: null,
                    normalWorkHoursPerDay: $scope.department.normalWorkHoursPerDay ? $scope.department.normalWorkHoursPerDay.toString().replace(',', '.') : null,
                    departmentInfo: {
                        departmentId: $scope.department ? $scope.department.departmentId : '00000000-0000-0000-0000-000000000000',
                        defaultMediaId: $scope.department.departmentInfo && $scope.department.departmentInfo.defaultMediaId ? $scope.department.departmentInfo.defaultMediaId : null
                    },
                    status: $scope.selectedDepartmentStatus && $scope.selectedDepartmentStatus.id !== '' ? $scope.selectedDepartmentStatus.id : null,
                    reason: $scope.reason
               };
               return orgDep;
            } else if($scope.selectedMainDepartmentType.value == 3){
                //barge
                var bargeDep = {
                    departmentId : $scope.department && $scope.department.departmentId ? $scope.department.departmentId : '00000000-0000-0000-0000-000000000000',
                    parentDepartmentId: ($scope.selectedParentDepartment ? $scope.selectedParentDepartment.departmentId : null),
                    externalId: $scope.department && $scope.department.externalId ? $scope.department.externalId : null,
                    name: $scope.department && $scope.department.name ? $scope.department.name : null,
                    contractorId: $scope.authData.contractorId,
                    departmentTypeId: null,
                    mmsi: null,
                    normalWorkHoursPerDay: null,
                    bargeId: $scope.department.barge.bargeId,
                    departmentInfo: {
                        departmentId: $scope.department && $scope.department.departmentId ? $scope.department.departmentId : '00000000-0000-0000-0000-000000000000',
                        defaultMediaId: $scope.department.departmentInfo && $scope.department.departmentInfo.defaultMediaId ? $scope.department.departmentInfo.defaultMediaId : null,
                        width: $scope.department.departmentInfo && $scope.department.departmentInfo.width ? $scope.department.departmentInfo.width : null,
                        depth: $scope.department.departmentInfo && $scope.department.departmentInfo.depth ? $scope.department.departmentInfo.depth : null,
                        phone: $scope.department.departmentInfo && $scope.department.departmentInfo.phone ? $scope.department.departmentInfo.phone : null,
                        email: $scope.department.departmentInfo && $scope.department.departmentInfo.email ? $scope.department.departmentInfo.email : null,
                        deliveryDate: $scope.deliveryDate ?  moment($scope.deliveryDate).format('YYYY-MM-DDT00:00:00') : null,
                        warrantyExpireDate: $scope.warrantyExpireDate ?  moment($scope.warrantyExpireDate).format('YYYY-MM-DDT00:00:00') : null,
                    },
                    barge:{
                        bargeId: $scope.department.barge.bargeId,
                        name: $scope.department && $scope.department.name ? $scope.department.name : null,
                        bargeInformation: {
                            locationNumber: $scope.department.barge.bargeInformation.locationNumber,
                            supplierId:$scope.bargeInformation.supplier ? $scope.bargeInformation.supplier.supplierId : null,
                            productionYear: $scope.bargeInformation.productionYear,
                            idNumber: $scope.bargeInformation.idNumber,
                            lengthWithDepotInMeters: $scope.bargeInformation.lengthWithDepotInMeters,
                            lengthWithoutDepotInMeters: $scope.bargeInformation.lengthWithoutDepotInMeters,
                            silageCapacityInSquareMeters: $scope.bargeInformation.silageCapacityInSquareMeters,
                            feedSiloCapacity: $scope.bargeInformation.feedSiloCapacity,
                            electricity: $scope.bargeInformation.electricityObj ? $scope.bargeInformation.electricityObj.value : null,
                            buildingMaterial: $scope.bargeInformation.buildingMaterial,
                            attachmentPointInParagraph: $scope.bargeInformation.attachmentPointInParagraph,
                            weightEmpty: $scope.bargeInformation.weightEmpty,
                            lightShipWeight: $scope.bargeInformation.lightShipWeight,
                            technicalManagerId: $scope.selectedUser.userId
                        },
                        isActive: $scope.department.barge.isActive
                    },
                    isActive: $scope.isActive,
                    status: $scope.selectedDepartmentStatus && $scope.selectedDepartmentStatus.id !== '' ? $scope.selectedDepartmentStatus.id : null,
                    reason: $scope.reason
               };
               return bargeDep;
            }
        }

        function validateForm(event) {
            event.preventDefault();
            var validator = $('#vessel-form').data("kendoValidator");
            if (validator.validate()) {
                if (formType === formTypes.add) {
                   var newDepartment = createDepartmentObj();
                   departmentService.addDepartmentWebApi(newDepartment).then(() => $windowInstance.close(true));
                } else {
                    var editedDepartment = createDepartmentObj();
                    if (editedDepartment.location && editedDepartment.location.locationNumber !== $scope.originalLocationNumber && editedDepartment.location.locationNumber && editedDepartment.location.locationNumber !== '') {
                        departmentService.addDepartmentHistoryElementWebApi({
                            moveDate: new Date(),
                            departmentID: editedDepartment.departmentId,
                            locationNumber: editedDepartment.location.locationNumber,
                            userId: $scope.authData.userId
                        })
                    }
                    const selectedDepartmentId = $scope.department ? $scope.department.departmentId : '00000000-0000-0000-0000-000000000000';

                    departmentService.getDepartmentTreeByDepartmentId(selectedDepartmentId, true).then(response => {
                        if (response && response.children && response.children.length && ($scope.selectedDepartmentStatus && $scope.selectedDepartmentStatus.id !== DepartmentStatus.Active)) {
                            $scope.selectedDepartmentChildren = response.children;
                            openConfirmParentDepartment()
                        } else {
                            departmentService.updateDepartmentWebApi(editedDepartment).then(() => {
                                if ($scope.selectedDepartmentStatus.id !== $scope.department.status) {
                                    const window = $kWindow.open({
                                        windowTemplateUrl: 'app/shared/modal-base.html',
                                        templateUrl: 'app/department/department-equipment-job-status-modal.html',
                                        controller: 'departmentEquipmentJobStatusModal',
                                        resolve: {
                                            departmentId: () => $scope.department.departmentId,
                                            status: () => $scope.selectedDepartmentStatus.id,
                                        },
                                        options: {
                                            actions: [],
                                            modal: true,
                                            draggable: true,
                                            movable: true,
                                            resizable: true,
                                            minHeight: 250,
                                            minWidth: 300,
                                            width: 950,
                                            height: 550,
                                            visible: false,
                                            title: $scope.selectedDepartmentStatus.id === DepartmentStatus.Active ?
                                                $translate.instant('EQUIPMENT_JOB_TITLE_ACTIVE_JOBS') :
                                                $translate.instant('EQUIPMENT_JOB_TITLE_PAUSE_JOBS'),
                                            position: {
                                                top: "33%", // or "100px"
                                                left: "33%"
                                            }
                                        }
                                    });

                                    window.result.then(result => {
                                        if (result) {
                                            $windowInstance.close(true);
                                        }
                                    });
                                } else {
                                    $windowInstance.close(true);
                                }
                            });
                        }
                    });

                }
            }
        }

        function closeModal() {
            $windowInstance.close();
        }

        function uploadVesselFile() {
            $('#vessel-file-input').click();
        }

        function handleFiles() {
            if ($scope.fileArray[0]) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'vesselImageUpload',
                    destination: '#vessel-form'
                });

                var file = $scope.fileArray[0];

                mediaService.asyncEncodeImageFile(file)
                    .then(function (encodedImage) {
                        mediaService.uploadFileWebApi(file.name, encodedImage)
                            .then(function (newMediaId) {

                                $scope.department.departmentInfo.defaultMediaId = newMediaId;
                                $scope.department.departmentInfo.mediaUrl = mediaService.createMediaUrl(department.departmentInfo.defaultMediaId)
                                $scope.department.hasImage = true;

                                if (formType === formTypes.add) {
                                    // do nothing
                                }
                                else {
                                    $scope.department.departmentInfo.warrantyExpireDate = ($scope.department.departmentInfo.warrantyExpireDate ? moment($scope.department.departmentInfo.warrantyExpireDate, 'DD.MM.YYYY').format() : null);

                                    departmentService.updateDepartmentWebApi($scope.department).then(function (response){
                                        $rootScope.$broadcast('hideBusyIndicator', 'vesselImageUpload');
                                    });
                                }
                            }, function (error) {
                                $rootScope.$broadcast('hideBusyIndicator', 'vesselImageUpload');
                            });
                    }, function (error) {
                        $rootScope.$broadcast('hideBusyIndicator', 'vesselImageUpload');
                    });
            }
        }

        function openConfirmParentDepartment() {
            const params = { modalWidth: 550, modalHeight: 200 };
            let title =  $translate.instant('ADMIN_VESSEL_DEPARTMENT_CHANGE_PARENT_TITLE');
            title = title.replace("x", $scope.department.name);

            let content = $translate.instant('ADMIN_VESSEL_DEPARTMENT_CHANGE_PARENT_MODAL_CONTENT');
            content = content.replace("x", $scope.department.name);

            let okBtnText = $translate.instant('ADMIN_VESSEL_DEPARTMENT_CHANGE_PARENT_SET_INACTIVE');
            let cancelBtnText = $translate.instant('ADMIN_VESSEL_DEPARTMENT_CHANGE_PARENT_MODIFY_CHILDREN');

            confirmDialogService.open(params, content, title, okBtnText, cancelBtnText).then((response) => {
                response && departmentService.updateParentChildDepartmentsWebApi($scope.department.departmentId, '').then(() => $windowInstance.close(true));
                !response && openChangeChildDepartment().then(() => $windowInstance.close(true));
            });
        }

        function openChangeChildDepartment() {
            let parentDepartments = _.orderBy($scope.parentDepartments.filter(d => $scope.department != null ? d.departmentId !== $scope.department.departmentId : true), 'name');
            parentDepartments = parentDepartments.filter(pd => $scope.selectedDepartmentChildren.every(c => c.departmentId !== pd.departmentId));

            const defaults = {
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: $translate.instant('ADMIN_VESSEL_DEPARTMENT_CONFIRM_CHANGE_PARENT_TITLE'),
                    width: 550,
                    overlay: true
                },
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/administration/vessels/vessel-parent-change-modal.html',
                controller: 'VesselParentChangeModalController',
                resolve: { selectedDepartment: () => $scope.department, parentDepartments: () => parentDepartments }
            };

            const modal = $kWindow.open(defaults);

            return modal.result;
        }

        function getChildDepartments(dep, childDepartmentIds){
           if(dep){
                var mainChildDepartments = departments.filter(x => x.parentDepartmentId == dep.departmentId);
                for (var i = 0; i < mainChildDepartments.length; i++) {
                    var childDepartment = mainChildDepartments[i];
                    childDepartmentIds.push(childDepartment.departmentId);
                    getChildDepartments(childDepartment, childDepartmentIds);
                }
            }
        }

        function getDepartmentStatuses() {
            return Object.entries({
                Inactive: 0,
                Active: 1,
                Paused: 2,
            }).map(e => {
                return {
                    id: e[1],
                    name: getStatusNameByValue(e[1])
                };
            });
        }

        function getStatusNameByValue(value)
        {
            if(value === 0){
                return $translate.instant('G_INACTIVE');
            } else if(value === 1){
                return $translate.instant('G_ACTIVE');
            } else if(value === 2){
                return $translate.instant('ADMIN_VESSEL_GRID_STATUS_PAUSED');
            }
        }
    }
})();
