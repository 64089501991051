(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('WorkorderReportController', workorderReportController);

    workorderReportController.$inject = [
        '$q',
        '$state',
        '$scope',
        '$rootScope',
        '$stateParams',
        'workflowStatuses',
        'dateUtility',
        'logService',
        'workOrderService',
        'workOrderStatuses',
        'userService'
    ];

    function workorderReportController(
        $q,
        $state,
        $scope,
        $rootScope,
        $stateParams,
        workflowStatuses,
        dateUtility,
        logService,
        workOrderService,
        workOrderStatuses,
        userService
    ) {
        $scope.goBack = goBack;
        $scope.printReport = printReport;

        $scope.workorder = {};

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
        }

        initController();
        $scope.printView = false;
        $scope.reportImageUrl = '../img/reports/NAVIAQ/Images/Logo-width-1200.png';

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'workorderReportIndicator',
                destination: '#workorder-report-container'
            });
            $q.all([workOrderService.getWorkOrderById($stateParams.operationLogId),
                logService.getSubTasksByOperationLogId($stateParams.operationLogId),
                userService.getUsersAsDropdownByFilter({contractorIds: [$rootScope.authData.contractorId]})])
            .then(function(results){
                $scope.workorder = results[0];
                $scope.subTasks = results[1];
                $scope.users = results[2];
                extendWorkorder($scope.workorder);
                extendSubTasks($scope.subTasks);
            }).finally(function(){
                $rootScope.$broadcast('hideBusyIndicator','workorderReportIndicator');
            });

            window.onafterprint = function () {
                $rootScope.showNavbar = true;
                $scope.printView = false;
                $('#main-view').removeClass('print-view');
            };
        }

        function extendWorkorder(workorderObject) {
            workorderObject.tagNames = workOrderService.getWorkOrderTagsOfWorkOrder(workorderObject);

            //status
            var woStatus = workOrderStatuses.find(s => s.value == workorderObject.status);
            workorderObject.statusText = woStatus ? woStatus.text : '';

            //assigned user name
            var assignedUser = workorderObject.assignedUser.find((obj) => {
                return obj.status === 0 && obj.role === 'Assigned';
            });
            if (assignedUser && assignedUser.user && assignedUser.user.displayName) {
                assignedUser = assignedUser.user.displayName;
            }
            workorderObject.assignedUserName = assignedUser;

            // createdBy user
            var cUser = $scope.users.find(u => u.userName == workorderObject.createdBy);
            if(cUser){
                workorderObject.createdByDisplay = cUser.displayName;
            } else {
                workorderObject.createdByDisplay = '';
            }

            workorderObject.formattedCreatedOn = dateUtility.formatDateTime(workorderObject.createdOn);
            workorderObject.formattedDoneOn = dateUtility.formatDateTime(workorderObject.doneOn);
            workorderObject.formattedDoneTo = dateUtility.formatDateTime(workorderObject.doneTo);
            workorderObject.formattedDueDate = dateUtility.formatDateTime(workorderObject.dueDate);
        }

        function extendSubTasks(tasks){
            _.forEach(tasks, function (task) {
                var status = workflowStatuses.find(s => s.value == task.taskStatus);
                if(status){
                    task.statusText = status.text;
                } else {
                    task.statusText = '';
                }

            });
        }


        function goBack() {
            $state.go('main.orderReport', {
                ContractorId: $scope.contractorId,
                OrderId: $scope.orderId,
            });
        }

        function printReport() {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');

            window.print();
        }
    }
})();
