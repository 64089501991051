(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('rolesDetailsModalController', rolesDetailsModalController);

    rolesDetailsModalController.$inject = ['$scope', '$q', '$rootScope', '$windowInstance', '$translate', 'userService', 'currentRoleId', 'formType', 'formTypes', 'authService'];

    function rolesDetailsModalController($scope, $q, $rootScope, $windowInstance, $translate, userService, currentRoleId, formType, formTypes, authService) {
        $scope.isSuperUser = authService.getAuthData().isSuperuser;
        $scope.permissions = [];
        $scope.currentRole = [];
        $scope.currentRole.permissions = [];
        $scope.isEdit = formType == formTypes.edit;
        $scope.isVisibleForConglomerate = false;
        $scope.isSystemRole = false;

        $scope.assignedPermissionsListboxOptions = {
            connectWith: 'role-permission-connection-unassigned',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.currentRole.permissions)
                }
            }),
            dataTextField: 'key',
            dataValueField: 'id',
            remove: e => {
                let removedItems = e.dataItems.map(di => di.toJSON());
                $scope.currentRole.permissions = $scope.currentRole.permissions.filter(
                    p => !removedItems.map(ri => ri.id).includes(p.id)
                );
            },
            add: e => {
                let addedItems = e.dataItems.map(di => di.toJSON());
                $scope.currentRole.permissions = $scope.currentRole.permissions.concat(addedItems);
            },
            selectable: 'multiple',
            template: dataItem => {
                var key = dataItem.key;
                var description = dataItem.description || '';
                return key + `<span class='permission-description'>${description}</span>`;
            },
        };

        $scope.unassignedPermissionsListboxOptions = {
            connectWith: 'role-permission-connection-assigned',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.permissions)
                }
            }),
            dataTextField: 'key',
            dataValueField: 'id',
            template: dataItem => {
                var key = dataItem.key;
                var description = dataItem.description || '';
                return key + `<span class='permission-description'>${description}</span>`;
            },
            selectable: 'multiple',
            toolbar: {
                tools: $scope.isSystemRole && !$scope.isSuperUser ? [] : ['transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']
            }
        };

        $scope.close = close;
        $scope.ok = ok;

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'rolesDetails',
                destination: '#role-permission-connection-unassigned'
            });

            $q.all([
                userService.getPermissions()]).then((data) => {
                    $scope.permissions = data[0].filter(p => !$scope.currentRole.permissions.map(rp => rp.id).includes(p.id));
                    $scope.permissions = $scope.permissions.sort((a, b) => {
                        return a.key.localeCompare(b.key);
                    });

                    if (formType == formTypes.edit) {
                        userService.getRoleById(currentRoleId).then((role) => {
                            $scope.currentRole = role;
                            initModal();
                        });
                    } else {
                        $scope.currentRole = [];
                        $scope.currentRole.permissions = [];
                        $scope.unassignedPermissionsListboxOptions.dataSource.read();
                        $scope.assignedPermissionsListboxOptions.dataSource.read();
                    }

                }).finally(() => $rootScope.$broadcast('hideBusyIndicator', 'rolesDetails'));
        }

        function initModal() {
            $scope.roleName = $scope.currentRole.key;
            $scope.isVisibleForConglomerate = $scope.currentRole.isVisibleForConglomerate;
            $scope.isSystemRole = $scope.currentRole.isSystem;

            $scope.unassignedPermissionsListboxOptions.dataSource.read();
            $scope.assignedPermissionsListboxOptions.dataSource.read();

            $('#role-permission-connection-unassigned').data('kendoListBox').refresh();
            $('#role-permission-connection-assigned').data('kendoListBox').refresh();
        }

        function close(param) {
            $windowInstance.close(param);
        }

        function ok() {
            switch (formType) {
                case formTypes.add:
                    var role = createRoleObject();
                    userService
                        .addRole(role)
                        .then(data => close(data))
                        .catch(() => {
                            var errorText = $translate.instant('ADMIN_ROLE_ERROR');
                            alert(errorText);
                        });
                    break;
                case formTypes.edit:
                    $scope.currentRole.key = $scope.roleName;
                    $scope.currentRole.isVisibleForConglomerate = $scope.isVisibleForConglomerate;
                    $scope.currentRole.isSystem = $scope.isSystemRole;
                    userService
                        .updateRole($scope.currentRole)
                        .then(data => close(data))
                        .catch(() => {
                            var errorText = $translate.instant('ADMIN_ROLE_ERROR');
                            alert(errorText);
                        });
                default:
                    break;
            }
        }

        function createRoleObject() {
            return {
                key: $scope.roleName,
                isSystem: $scope.isSystemRole,
                contractorId: $scope.isSystemRole ? null : $rootScope.authData.contractorId,
                isVisibleForConglomerate: $scope.isVisibleForConglomerate,
                isReadOnly: false,
                permissions: $scope.currentRole.permissions
            };
        }

        $scope.isSystemRoleChanged = () => {
            $scope.isSystemRole = !$scope.isSystemRole;
        }
    }
})();
