(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ServiceFormsEditorDetailsController', ServiceFormsEditorDetailsController);

    ServiceFormsEditorDetailsController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        '$translate',
        'serviceFormService',
        'formTypes',
        'kendoUtility',
        'serviceForm',
        'formType',
        'serviceFormTypes',
        'logActionService',
        'PreLoadDataOptions',
        'authService',
        'serviceFormReportTypes',
        'itemTreeService',
        'sanitizeService'
    ];

    function ServiceFormsEditorDetailsController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        $translate,
        serviceFormService,
        formTypes,
        kendoUtility,
        serviceForm,
        formType,
        serviceFormTypes,
        logActionService,
        PreLoadDataOptions,
        authService,
        serviceFormReportTypes,
        itemTreeService,
        sanitizeService
    ) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.logActions = [];
        $scope.serviceFormControls = [];
        $scope.serviceFormsOptions = [];
        $scope.serviceFormNumberFields = [];
        $scope.serviceFormDateFields = [];
        $scope.skillCategories = [];
        $scope.isDropDownType = false;
        $scope.isDropDownTypeWithPreLoadData = false;
        $scope.isInformationAreaType = false;
        $scope.isHeaderType = false;
        $scope.hasDefaultInputValue = false;
        $scope.hasDefaultCheckboxValue = false;
        $scope.hasDefaultDateTimeValue = false;
        $scope.hasDefaultDateValue = false;
        $scope.isNumericAggregationType = false;
        $scope.isDateAggregationType = false;
        $scope.hasItemCategorySelector = false;
        $scope.isDirty = false;
        $scope.isEditModal = formType == formTypes.edit ? true : false;
        $scope.hasDelicingModule = authService.hasModule('DelicingReport')
        $scope.serviceFormReportTypes = serviceFormReportTypes;
        $scope.selectedReportType = null;

        $scope.name = '';
        $scope.newFieldIsHidden = false;
        $scope.newFieldIsRequired = false;
        $scope.addNewField = addNewField;
        $scope.saveServiceForm = saveServiceForm;
        $scope.close = close;
        $scope.hideRequiredCheckBox = false;

        $scope.operations = [
            {
                text: $translate.instant('SERVICEFORM_EDITOR_DETAILS_OPTIONS_SUN'),
                value: 0
            },
            {
                text: $translate.instant('SERVICEFORM_EDITOR_DETAILS_OPTIONS_AVG'),
                value: 1
            }
        ];

        $scope.partsOfDate = [
            {
                text: $translate.instant('SERVICEFORM_EDITOR_DETAILS_OPTIONS_YEAR'),
                value: 0
            },
            {
                text: $translate.instant('SERVICEFORM_EDITOR_DETAILS_OPTIONS_MONTH'),
                value: 1
            },
            {
                text: $translate.instant('SERVICEFORM_EDITOR_DETAILS_OPTIONS_DAY'),
                value: 2
            },
            {
                text: $translate.instant('SERVICEFORM_EDITOR_DETAILS_OPTIONS_HOUR'),
                value: 3
            },
            {
                text: $translate.instant('SERVICEFORM_EDITOR_DETAILS_OPTIONS_MIN'),
                value: 4
            }
        ];

        $scope.operationDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.operations);
                    }
                }
            }),
            dataValueField: 'value',
            dataTextField: 'text'
        };

        $scope.reportTypeDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.serviceFormReportTypes);
                    }
                }
            }),
            change: e => {
                $scope.isDirty = true;
            },
            dataValueField: 'name',
            dataTextField: 'name'
        };

        $scope.logActionDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.logActions);
                    }
                }
            }),
            change: e => {
                var logAction = $scope.logActions.find(x => x.logActionId === $scope.selectedLogAction);
                if(logAction){
                    $scope.name = logAction.description;
                    $scope.$apply();
                    $scope.isDirty = true;
                }
            },
            dataValueField: 'logActionId',
            dataTextField: 'description'
        };

        $scope.serviceFormPreLoadDataOptionsDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(PreLoadDataOptions);
                    }
                }
            }),
            dataValueField: 'Value',
            dataTextField: 'Name'
        };

        $scope.dateFieldDropDownOptions = {
            filter: "contains",
            dataSource: {
                batch: true,
                transport: {
                    read: e => e.success($scope.serviceFormDateFields)
                }
            },
            dataTextField: 'text',
            dataValueField: 'key',
        };

        $scope.datePartsDropDownOptions = {
            filter: "contains",
            dataSource: {
                batch: true,
                transport: {
                    read: e => e.success($scope.partsOfDate)
                }
            },
            dataTextField: 'text',
            dataValueField: 'value',
        };


        $scope.fieldsMultiselectOptions = {
            filter: "contains",
            dataSource: {
                batch: true,
                transport: {
                    read: e => e.success($scope.serviceFormNumberFields)
                }
            },
            dataTextField: 'key',
            dataValueField: 'id',
        };

        $scope.numberPartsDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.serviceFormNumberFields);
                    }
                }
            }),
            dataTextField: 'key',
            dataValueField: 'id',
        };

        $scope.genericCompetencyMultiSelectOptions = {
            filter: "contains",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.skillCategories)
                }
            }),
            dataTextField: 'name',
            dataValueField: 'id',
        };

        $scope.serviceFormControlsListBoxOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.serviceFormControls);
                    }
                }
            }),
            dataTextField: 'description',
            dataValueField: 'key',
            toolbar: {
                position: 'right',
                tools: ['moveUp', 'moveDown', 'remove']
            },
            reorder: onReorder,
            remove: onRemove,
            template: dataItem => {
                return `<div class="col-md-12" style="padding: 0;">
                            <span class="col-md-3 with-tooltip">${dataItem.label}</span>
                            <span class="col-md-2">${dataItem.type}</span>
                            <span class="col-md-2 service-form-field-key with-tooltip">${dataItem.key}</span>
                            <span class="col-md-1" style="text-align: right;">
                                <input type="checkbox" class="required-checkbox" ${dataItem.isRequiredForSending ? 'checked' : ''}
                                    ng-model="${dataItem.isRequiredForSending}" k-ng-model="${dataItem.isRequiredForSending}"
                                    value="${dataItem.isRequiredForSending}" name="is-required"
                                    style="display: ${dataItem.type === 'multilevelDiveDepth' || dataItem.type === 'netwashtime' || dataItem.type === 'inspection-select' ? 'none' : 'inherited'}"
                                />
                            </span>
                            <span class="col-md-1 hidden-span">
                                <input type="checkbox" class="hidden-checkbox" ${dataItem.hidden ? 'checked' : ''} ng-model="${dataItem.hidden}" k-ng-model="${dataItem.hidden}" value="${dataItem.hidden}" name="is-hidden"/>
                            </span>
                        </div>`;
            }
        };

        $scope.hiddenValueChange = hiddenValueChange;

        function hiddenValueChange(event){
            $scope.isDirty = true;
            var text = $(event.target).parents("li").find(".service-form-field-key").text()
            var index = $scope.serviceFormControls.findIndex(x => x.key.slice(0, 20) === text);
            if(index !== -1){
                $scope.serviceFormControls[index].hidden = $scope.serviceFormControls[index].hidden ? false : true;
            }
        }

        $scope.requiredValueChange = requiredValueChange;

        function requiredValueChange(event){
            $scope.isDirty = true;
            var text = $(event.target).parents("li").find(".service-form-field-key").text()
            var index = $scope.serviceFormControls.findIndex(x => x.key.slice(0, 20) === text);
            if(index !== -1){
                $scope.serviceFormControls[index].isRequiredForSending = $scope.serviceFormControls[index].isRequiredForSending ? false : true;
            }
        }

        $scope.typeOfControlDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                data: serviceFormTypes
            }),
            dataValueField: 'Value',
            dataTextField: 'Name',
            change: function () {
                if($scope.selectedTypeOfControl == 0 || $scope.selectedTypeOfControl == 1){
                    $scope.hasDefaultInputValue = true;
                    $scope.isDropDownType = false;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.$apply();
                }else if ($scope.selectedTypeOfControl == 2 ) {
                    $scope.hasDefaultCheckboxValue = true
                    $scope.isDropDownType = false;
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.$apply();
                } else if ($scope.selectedTypeOfControl == 3 || $scope.selectedTypeOfControl == 4) {
                    $scope.isDropDownType = true;
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.$apply();
                } else if ($scope.selectedTypeOfControl == 5 || $scope.selectedTypeOfControl == 6){
                    $scope.dropdownPreLoadData.dataSource.data(PreLoadDataOptions);
                    $scope.dropdownPreLoadData.refresh();
                    $scope.isDropDownTypeWithPreLoadData = true;
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.$apply();
                }  else if ($scope.selectedTypeOfControl == 7){
                    $scope.hasDefaultDateTimeValue = true;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.$apply();
                }  else if ($scope.selectedTypeOfControl == 8){
                    $scope.hasDefaultDateValue = true;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.$apply();
                } else if($scope.selectedTypeOfControl == 10){
                    $scope.hasDefaultDateValue = false;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = true;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.$apply();
                } else if($scope.selectedTypeOfControl == 16){
                    $scope.hasDefaultDateValue = false;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.isInformationAreaType = true;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.$apply();
                } else if($scope.selectedTypeOfControl == 17){
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = true;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    // load fields
                    var listBoxValues = $scope.serviceFormControlsListBoxOptions.dataSource.data();
                    if(listBoxValues){
                        var fields = listBoxValues.filter(l => l.type == 'number').map(field => ({
                            key: field.key,
                            text: field.label
                        }));
                    }
                    $scope.serviceFormNumberFields = fields;
                    $scope.fieldsMultiselect.dataSource.read();
                    $scope.operationDropDownOptions.dataSource.read();

                } else if($scope.selectedTypeOfControl == 18) {
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.hasItemCategorySelector = false;
                    $scope.isDateAggregationType = true;
                    // load dropdowns
                    var listBoxValues = $scope.serviceFormControlsListBoxOptions.dataSource.data();
                    if(listBoxValues){
                         var fields = listBoxValues.filter(l => l.type == 'date' || l.type == 'datetime').map(field => ({
                             key: field.key,
                             text: field.label
                         }));

                         var numberFields = listBoxValues.filter(l => l.type == 'number').map(field => ({
                            key: field.key,
                            text: field.label
                        }));
                    }
                    $scope.serviceFormNumberFields = numberFields;
                    $scope.serviceFormDateFields = fields;
                    $scope.numberPartsDropDown.dataSource.read();
                    $scope.dateFieldDropdown.dataSource.read();
                    $scope.datePartsDropdown.dataSource.read();
                } else if($scope.selectedTypeOfControl == 19) {
                    $scope.dropdownPreLoadData.dataSource.data(PreLoadDataOptions.filter(p => p.isShowForCompetency == true));
                    $scope.dropdownPreLoadData.refresh();
                    $scope.isDropDownTypeWithPreLoadData = true;
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.hasItemCategorySelector = true;
                    $scope.$apply();
                }
                else {
                    $scope.hasDefaultInputValue = false;
                    $scope.isDropDownType = false;
                    $scope.isDropDownTypeWithPreLoadData = false;
                    $scope.hasDefaultCheckboxValue = false;
                    $scope.hasDefaultDateTimeValue = false;
                    $scope.hasDefaultDateValue = false;
                    $scope.isInformationAreaType = false;
                    $scope.isHeaderType = false;
                    $scope.isNumericAggregationType = false;
                    $scope.isDateAggregationType = false;
                    $scope.$apply();
                }
            }
        };

        $scope.serviceFormOptionsDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.serviceFormsOptions);
                    }
                }
            }),
            dataValueField: 'serviceFormOptionsId',
            dataTextField: 'name'
        };

        initController();
        function initController() {
            setTimeout(() => {
                $(".hidden-checkbox").on("change", event => {
                    hiddenValueChange(event);
                })
                $(".required-checkbox").on("change", event => {
                    requiredValueChange(event);
                })

                $("#type-of-control").on("change", (event) => {
                    var controlTypeValue = event.currentTarget.value;
                    if (controlTypeValue === "11" || controlTypeValue === "12" || controlTypeValue === "13")
                    {
                        $scope.hideRequiredCheckBox = true;
                    }
                    else {
                        $scope.hideRequiredCheckBox = false;
                    }
                });
            }, 1000);

            $q.all([serviceFormService.getServiceFormOptions(),
                logActionService.getActiveLogActionsAsDropdownByFilter({contractorId: $rootScope.authData.contractorId, status: 0}),
                itemTreeService.getSkillCategories($rootScope.authData.contractorId)]).then(function(results){
                $scope.serviceFormsOptions = results[0];
                $scope.logActions = results[1];
                $scope.skillCategories = results[2];
                $scope.serviceFormOptionsDropDownOptions.dataSource.read();
                $scope.serviceFormPreLoadDataOptionsDropDownOptions.dataSource.read();
                $scope.logActionDropDownOptions.dataSource.read();
                $scope.genericCompetencyMultiSelectOptions.dataSource.read();

                if(formType == formTypes.edit){
                    $scope.selectedLogAction = serviceForm.logActionId;
                    $scope.selectedReportType = serviceForm.reportType;
                    $scope.showOnMooringReport = serviceForm.showOnMooringReport;
                    if(!serviceForm.name){
                        var logAction = $scope.logActions.find(x => x.logActionId === $scope.selectedLogAction);
                        if(logAction){
                            $scope.name = logAction.description;
                        }
                    } else{
                        $scope.name = serviceForm.name;
                    }
                    $scope.serviceForm = serviceForm;
                    ExtendServiceFormControls();
                }
            });
        }

        function ExtendServiceFormControls(){
            $scope.formTemplateElements = JSON.parse(serviceForm.formTemplate);
            $scope.formTemplateElements.forEach(function (element) {
                var serviceFormField = {
                    key : element.key,
                    type: element.type,
                    label: element.label,
                    isRequiredForSending: element.isRequiredForSending || null,
                    optionsName: element.optionsName || null,
                    optionsType: element.optionsType || null,
                    defaultValue: element.defaultValue || null,
                    competencies: element.competencies ||null,
                    hidden: element.hidden || null,
                    numericFields: element.numericFields || null,
                    operation: element.operation ||null,
                    dateField: element.dateField || null,
                    partOfDate: element.partOfDate || null,
                }
                $scope.serviceFormControls.push(serviceFormField);
            });
            $scope.serviceFormControlsListBoxOptions.dataSource.read();
        }

        function onReorder(e) {
            $scope.isDirty = true;
            let movedItem = e.sender
            .dataItems()
            .find(d => d.uid === e.items[0].id)
            .toJSON();
            var elementIdx = $scope.serviceFormControls.findIndex(s => s.key == movedItem.key);
            moveElementInArray(elementIdx, elementIdx + e.offset);
        }

        function moveElementInArray(old_index, new_index) {
            if (new_index >= $scope.serviceFormControls.length) {
                var k = new_index - $scope.serviceFormControls.length + 1;
                while (k--) {
                    $scope.serviceFormControls.push(undefined);
                }
            }
            $scope.serviceFormControls.splice(new_index, 0, $scope.serviceFormControls.splice(old_index, 1)[0]);
            return $scope.serviceFormControls;
        };

        function onRemove(e) {
            $scope.isDirty = true;

            let movedItem = e.sender
                .dataItems()
                .find(d => d.uid === e.items[0].id)
                .toJSON();

            _.remove($scope.serviceFormControls, function(serviceFormField) {
            return serviceFormField.key === movedItem.key;
            });
        }

        $scope.serviceFormChanged = function(){
            $scope.isDirty = true;
        }

        function addNewField(){

            if($scope.selectedTypeOfControl
                && ($scope.selectedTypeOfControl == 3 || $scope.selectedTypeOfControl == 4)){
                    serviceForms.dropdownOptions.$valid = false;
                    serviceForms.dropdownOptions.required = true;
            }

            if($scope.selectedTypeOfControl
                && ($scope.selectedTypeOfControl == 5 || $scope.selectedTypeOfControl == 6)){
                    serviceForms.dropdownPreLoadDataOptions.$valid = false;
                    serviceForms.dropdownPreLoadDataOptions.required = true;
            }

            if($scope.selectedTypeOfControl
                && ($scope.selectedTypeOfControl == 5 || $scope.selectedTypeOfControl == 6)){
                    serviceForms.dropdownPreLoadDataOptions.$valid = false;
                    serviceForms.dropdownPreLoadDataOptions.required = true;
            }

            if($scope.selectedTypeOfControl && ($scope.selectedTypeOfControl == 17)){
                    serviceForms.fieldsMultiSelect.$valid = false;
                    serviceForms.fieldsMultiSelect.required = true;

                    serviceForms.operationDropdowns.$valid = false;
                    serviceForms.operationDropdowns.required = true;
            }

            if($scope.selectedTypeOfControl && ($scope.selectedTypeOfControl == 18)){
                serviceForms.dateFieldDropdowns.$valid = false;
                serviceForms.dateFieldDropdowns.required = true;

                serviceForms.numberPartsDropDown.$valid = false;
                serviceForms.numberPartsDropDown.required = true;

                serviceForms.datePartsDropdowns.$valid = false;
                serviceForms.datePartsDropdowns.required = true;
            }

            if(sanitizeService.hasXssContentInString($scope.key)){
                $scope.key = null;
                serviceForms.controlKey.$valid = false;
                return;
            } else if(sanitizeService.hasXssContentInString($scope.label)){
                $scope.label = null;
                serviceForms.controlName.$valid = false;
                return;
            } else if($scope.defaultInputValue && sanitizeService.hasXssContentInString($scope.defaultInputValue)){
                $scope.defaultInputValue = null;
                return;
            } else if($scope.defaultCheckBoxValue && sanitizeService.hasXssContentInString($scope.defaultCheckBoxValue)){
                $scope.defaultCheckBoxValue = null;
                return;
            } else if(!$scope.validator.validate()){
                return;
            }

            var selectedType = serviceFormTypes.find(s => s.Value == $scope.selectedTypeOfControl);
            if($scope.selectedTypeOfControl && $scope.selectedTypeOfControl == 17){
                var serviceFormField = {
                    key : $scope.key,
                    type: selectedType.type,
                    label: $scope.label,
                    isRequiredForSending: $scope.newFieldIsRequired || null,
                    hidden: $scope.newFieldIsHidden || null,
                    numericFields: $scope.selectedFields.length ? $scope.selectedFields.map(f => f.key) : null,
                    operation: $scope.selectedOperation
                };
            } else if($scope.selectedTypeOfControl && $scope.selectedTypeOfControl == 18){
                var serviceFormField = {
                    key : $scope.key,
                    type: selectedType.type,
                    label: $scope.label,
                    isRequiredForSending: $scope.newFieldIsRequired || null,
                    hidden: $scope.newFieldIsHidden || null,
                    numericFields: $scope.numberField ? [$scope.numberField] : null,
                    dateField: $scope.selectedDateField ? $scope.selectedDateField : null,
                    partOfDate: $scope.selectedDateParts ? $scope.selectedDateParts : null,
                };
            } else if($scope.selectedTypeOfControl && $scope.selectedTypeOfControl == 19){
                var selectedCompetencies = $scope.selectedGenericCompetences.map(c => c.id);
                var serviceFormField = {
                    key : $scope.key,
                    type: selectedType.type,
                    label: $scope.label,
                    isRequiredForSending: $scope.newFieldIsRequired || null,
                    optionsType: $scope.selectedPreLoadDataOptions || null,
                    competencies: selectedCompetencies,
                    defaultValue: saveDefaultValue(),
                    hidden: $scope.newFieldIsHidden || null
                };
            } else {
                var serviceFormField = {
                    key : $scope.key,
                    type: selectedType.type,
                    label: $scope.label,
                    isRequiredForSending: $scope.newFieldIsRequired || null,
                    optionsType: $scope.selectedPreLoadDataOptions || null,
                    optionsName: $scope.selectedOptions || null,
                    defaultValue: saveDefaultValue(),
                    hidden: $scope.newFieldIsHidden || null
                };
            }

            $scope.serviceFormControls.push(serviceFormField);
            $scope.serviceFormControlsListBoxOptions.dataSource.read();
            $scope.isDirty = true;
        }

        function saveServiceForm (){
            if ($scope.isDirty) {

                if(!$scope.mainValidator.validate()){
                    return;
                }
                $scope.serviceFormObjList = [];
                $scope.serviceFormControls.forEach(function (control) {
                    if(control.type == 'select' || control.type == 'multiselect'){
                        $scope.serviceFormObjList.push({
                            key : control.key,
                            type: control.type,
                            label: control.label,
                            isRequiredForSending: control.isRequiredForSending,
                            optionsType: control.optionsType,
                            optionsName: control.optionsName,
                            defaultValue: control.defaultValue,
                            hidden: control.hidden
                        });
                    } else  if(control.type == 'inspection-select'){
                        $scope.serviceFormObjList.push({
                            key : control.key,
                            type: control.type,
                            label: control.label,
                            isRequiredForSending: control.isRequiredForSending,
                            optionsType: null,
                            optionsName: null,
                            defaultValue: null,
                            hidden: control.hidden
                        });
                    } else if(control.type == 'numericAggregation'){
                        $scope.serviceFormObjList.push({
                            key : control.key,
                            type: control.type,
                            label: control.label,
                            isRequiredForSending: control.isRequiredForSending,
                            hidden: control.hidden,
                            numericFields: control.numericFields,
                            operation: control.operation
                        });
                    } else if(control.type == 'dateAggregation'){
                        $scope.serviceFormObjList.push({
                            key : control.key,
                            type: control.type,
                            label: control.label,
                            isRequiredForSending: control.isRequiredForSending,
                            hidden: control.hidden,
                            dateField: control.dateField,
                            partOfDate: control.partOfDate,
                            numericFields: control.numericFields
                        });
                    } else if(control.type == 'genericCompetency'){
                        $scope.serviceFormObjList.push({
                            key : control.key,
                            type: control.type,
                            label: control.label,
                            competencies: control.competencies,
                            isRequiredForSending: control.isRequiredForSending,
                            optionsType: control.optionsType,
                            hidden: control.hidden,
                        });
                    } else {
                        $scope.serviceFormObjList.push({
                            key : control.key,
                            type: control.type,
                            label: control.label,
                            isRequiredForSending: control.isRequiredForSending,
                            defaultValue: control.defaultValue,
                            hidden: control.hidden
                        });
                    }
                });

                var templateJsonObj = JSON.stringify($scope.serviceFormObjList);

                if(formType == formTypes.add){
                    var serviceForm = {
                        formTemplate: templateJsonObj,
                        logActionId: $scope.selectedLogAction,
                        name: $scope.name,
                        showOnMooringReport: $scope.showOnMooringReport,
                        reportType: $scope.selectedReportType
                    };

                    serviceFormService.addServiceForm(serviceForm).then(function() {
                        close(true);
                    });

                } else {
                    $scope.serviceForm.formTemplate = templateJsonObj;
                    $scope.serviceForm.name = $scope.name;
                    $scope.serviceForm.showOnMooringReport = $scope.showOnMooringReport;
                    $scope.serviceForm.reportType = $scope.selectedReportType;

                    serviceFormService.updateServiceForm($scope.serviceForm).then(function() {
                        close(true);
                    });
                }
            }
        }

        function saveDefaultValue(){
            if($scope.selectedTypeOfControl == 0 || $scope.selectedTypeOfControl == 1){
                return $scope.defaultInputValue;
            } else if($scope.selectedTypeOfControl == 2){
                return $scope.defaultCheckBoxValue;
            } else if($scope.selectedTypeOfControl == 7){
                return $scope.defaultDateTime ? moment($scope.defaultDateTime).format('YYYY-MM-DDTHH:mm:ss') : null;
            } else if($scope.selectedTypeOfControl == 8){
                return $scope.defaultDate ? moment($scope.defaultDate).format('YYYY-MM-DDT00:00:00') : null;
            }

            return null;
        }

        function close(result){
            $windowInstance.close(result);
        }
    }
})();
