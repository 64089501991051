(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ResourcesAdministrationController', resourcesAdministrationController);

    resourcesAdministrationController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        '$kWindow',
        'commonUtility',
        'htmlElementService',
        'kendoUtility',
        'adminResourceService',
        'formTypes',
        'authService',
        'baseDataService',
        'sanitizeService'];

    function resourcesAdministrationController(
        $q,
        $scope,
        $rootScope,
        $translate,
        $kWindow,
        commonUtility,
        htmlElementService,
        kendoUtility,
        adminResourceService,
        formTypes,
        authService,
        baseDataService,
        sanitizeService) {
        $scope.addResource = addResource;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.resources = [];
        $scope.measurementUnitType = [];
        $scope.isSuperUser = authService.getAuthData().isSuperuser;

        const resourcesAdministrationGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.resources),
                    update: e => resourceGridUpdate(e)
                },
                schema: {
                    model: {
                        id: 'resourceId',
                        fields: {
                            resourceId: { editable: false },
                            resourceDescription: { editable: true, validation: { required: true } },
                            measurementUnitDescription: { editable: true, validation: { required: true } },
                        }
                    },
                }
            }),
            columns: [
                { field: 'resourceId', hidden: true },
                { field: 'resourceDescription', title: $translate.instant('G_NAME', null, null, langId),
                  template: dataItem => {
                        dataItem.dirty && toggleChangesButtons(false);
                        return dataItem.resourceDescription ? dataItem.resourceDescription : '';
                  }
                },
                { field: 'measurementUnitTypeId', title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_MEASUREMENTTYPE', null, null, langId),
                  template: dataItem => {
                    if (dataItem.measurementUnitTypeId !== null) {
                        var measurementObject = null;
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        measurementObject = $scope.measurementUnitType.find(obj => obj.measurementUnitTypeId === dataItem.measurementUnitTypeId);

                        if (dataItem.dirty && dataItem.measurementUnitTypeId && dataItem.measurementUnitType.measurementUnitTypeId) {
                            dataItem.measurementUnitTypeId = measurementObject.measurementUnitTypeId;
                        }

                        if (measurementObject && measurementObject !== null && measurementObject.measurementUnitDescription !== null) {
                            return measurementObject.measurementUnitDescription;
                        }
                        return '';
                    }
                    return '' ;
                  },
                  editor: measurementUnitTypeDropDownEditor,
                },
                {
                    command: [
                        { name: 'updateResource', click: e => updateResource(e), template: '<a class="k-grid-updateResource"><i class="fa fa-edit grid-icon"></i></a>' },
                        { name: 'deleteResource', click: e => deleteResource(e), template:'<a class="k-grid-deleteResource"><i class="fas fa-trash"></i></a>' },
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.resourcesAdministrationGridOptions = resourcesAdministrationGridOptions($translate.use());

        $scope.$on('langChanged', (_, langId) => {
            $scope.resourcesAdministrationGridOptions = resourcesAdministrationGridOptions(langId);
            const resourcesAdministrationGrid = kendoUtility.createWidget('kendoGrid', $scope.resourcesAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(langId, [() => resourcesAdministrationGrid('#resources-administration-grid')]);
        });

        initController();

        function initController() {
            if ($scope.isSuperUser) {
                showBusyIndicator('resourcesAdministrationGrid');
                toggleChangesButtons(true);
                htmlElementService.checkIfElementExists("filter-group").then(() => commonUtility.calculateAdminTabMenuWidth());

                const requests = {
                    getAllResources: adminResourceService.getAllResources(),
                    getMeasurementUnitTypesWebapi: baseDataService.getMeasurementUnitTypesWebapi()
                }

                $q.all(requests)
                    .then(data => {
                        $scope.measurementUnitType = data.getMeasurementUnitTypesWebapi.sort((a, b) => a.measurementUnitDescription.localeCompare(b.measurementUnitDescription));
                        $scope.resources = data.getAllResources.sort((a, b) => a.resourceDescription.localeCompare(b.resourceDescription));
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        $scope.resourcesAdministrationGridOptions.dataSource.read();
                        hideBusyIndicator('resourcesAdministrationGrid');
                    });
            }
        }

        function toggleChangesButtons(disabled) {
            const saveChangesButton = $('button.k-button.k-grid-save-changes-resource');
            const cancelChangesButton = $('button.k-button.k-grid-cancel-changes-resource');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function saveChanges() {
            toggleChangesButtons(true);
            $scope.resourcesAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true);
            $scope.resourcesAdministrationGridOptions.dataSource.read();
        }

        function addResource() {
            const addModal = openResourceModal(formTypes.add);
            addModal.result.then(response => {
                if (response.result) {
                    initController();
                }
            })
        }

        function resourceGridUpdate(e) {
            showBusyIndicator('resourceGridUpdate');

            const dataItem = e.data;
            const resource = {
                resourceDescription: dataItem.resourceDescription,
                measurementUnitTypeId: dataItem.measurementUnitTypeId,
                resourceId: dataItem.resourceId
            };

            adminResourceService.updateResource(resource)
                .then(response => {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                    } else {
                        e.error();
                    }
                }, error =>e.error(error))
                .finally(() => {
                    hideBusyIndicator('resourceGridUpdate');
                    initController();
                });
        }

        function updateResource(event) {
            const resource = getResourceFromGrid(event);
            const updateModal = openResourceModal(formTypes.edit, resource);

            resource && updateModal.result.then(response => {
                if (response.result) {
                   initController();
                }
            });
        }

        function deleteResource(event){
            const resource = getResourceFromGrid(event);
            const deleteModal = openDeleteModal();

            resource && deleteModal.result.then(response => {
                if (response) {
                    showBusyIndicator('deleteResource');
                    adminResourceService.deleteResource(resource.resourceId)
                                        .then(result => result && initController())
                                        .finally(() => hideBusyIndicator('deleteResource'));
                }
            });
         }

        function openResourceModal(formType, resource = null) {
            const title = formType === formTypes.add ? 'ADMIN_RESOURCE_GRID_ADD_RESOURCE' : 'ADMIN_RESOURCE_GRID_EDIT_RESOURCE';

            return $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            title: $translate.instant(title),
                            resizable: false,
                            visible: false,
                            width: 550
                        },
                        templateUrl: 'app/shared/popups/resource-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ResourceModalController',
                        resolve: {
                            formType: () => formType,
                            resource: () => resource,
                            measurementUnitType: () => $scope.measurementUnitType
                        }
                   });
        }

        function openDeleteModal() {
            return $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            title: $translate.instant('ADMIN_RESOURCE_GRID_DELETE_RESOURCE'),
                            resizable: false,
                            visible: false,
                            width: 300
                        },
                        templateUrl: 'app/shared/popups/confirm-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ConfirmModalController',
                        resolve: {
                            content: function (){
                                return $translate.instant('G_CONFIRM_DELETE', null, null);
                            },
                            primaryBtnText: function () {
                                return null;
                            },
                            secondaryBtnText: function () {
                                return null;
                            }
                        }
                   });
        }

        function measurementUnitTypeDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE'),
                    dataTextField: 'measurementUnitDescription',
                    dataValueField: 'measurementUnitTypeId',
                    dataSource: { data: $scope.measurementUnitType }
                });
        }

        function getResourceFromGrid(event) {
            event.preventDefault();

            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');

            return $("#resources-administration-grid").data("kendoGrid").dataItem(row).toJSON();
        }

        function showBusyIndicator(id) {
            $rootScope.$broadcast('showBusyIndicator', { id: id, destination: '#resources-administration-grid' });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', id);
        }
    }
})();
