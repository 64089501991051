(function () {
    'use strict';
    angular.module('NaviaqWebApp').directive('dropdownTreeControlElement', dropdownTreeControlElement);

    dropdownTreeControlElement.$inject = [];

    function dropdownTreeControlElement() {
        return {
            controller: ['$rootScope', '$scope', 'kendoUtility', dropdownTreeControlElement],
            replace: true,
            restrict: 'E',
            templateUrl: 'app/shared/directives/dropdown-tree-control-element.html',
            scope: {
                dropdownId: "=dropdownId",
                treeData: "=treeData",
                placeholder: "=placeholder",
                selectedIds: "=selectedIds",
                isMultiselect: "=isMultiselect",
                hasGroupSelection: "=hasGroupSelection",
                equipmentListLoading: "=equipmentListLoading",
                isReadOnly: "=isReadOnly",
                listLoading: "=listLoading"
            }
        };

        function dropdownTreeControlElement($rootScope, $scope, kendoUtility) {
            $scope.oldSelectedIds = $scope.selectedIds;

            $scope.$watch('treeData', function() {
                var treeData = $scope.treeData ? $scope.treeData : [];
                $scope.itemDropdownTree._getSelection()._clearValue();
                $scope.selectOptions.dataSource.data(treeData);
                if($scope.selectedIdsClone && $scope.selectedIdsClone.length > 0){
                    $scope.selectedIds = $scope.selectedIdsClone;
                    $scope.oldSelectedIds = JSON.parse(JSON.stringify($scope.selectedIds));
                    $scope.selectedIdsClone = [];
                }
            });

            $scope.$watch('isMultiselect', function() {
                if( $scope.itemDropdownTree){
                    if($scope.isMultiselect){
                        var multiselectCheckbox = {
                            checkChildren: $scope.isMultiselect,
                            template: "# if(item.disabled != 'disabled'){# <input type='checkbox' name='checkedFiles[#= item.id #]' value='true' />#}#"
                        }
                    } else {
                        var singleselectCheckbox = false;
                    }

                    $scope.selectOptions.checkboxes = $scope.isMultiselect ? multiselectCheckbox : singleselectCheckbox;
                    $scope.selectOptions.autoClose = $scope.isMultiselect ? false : true;
                    kendoUtility.rebindDropDownTree('kendoDropDownTree', $scope.selectOptions, $scope.dropdownId, !$scope.isMultiselect);

                    if($scope.selectedIds && $scope.selectedIds.length){
                        var dropdownTree = $(`#${$scope.dropdownId}`).data("kendoDropDownTree");
                        if(dropdownTree){
                            dropdownTree.value($scope.selectedIds);
                        }
                    }

                }
            });

            if($scope.hasGroupSelection){
                var checkbox = $scope.hasCheckbox;
                if($scope.isMultiselect) {
                    checkbox = {
                        checkChildren: $scope.isMultiselect,
                        template: "# if(item.hasChildren && item.children.hasChildren){# <input type='checkbox'  name='checkedFiles[#= item.id #]' value='true' />#}#"
                    }
                }
            } else {
                if($scope.isMultiselect) {
                    var checkbox = {
                        checkChildren: $scope.isMultiselect,
                        template: "# if(item.disabled != 'disabled'){# <input type='checkbox' name='checkedFiles[#= item.id #]' value='true' />#}#"
                    }
                } else {
                    var checkbox = false;
                }
            }


            $scope.selectOptions = {
                placeholder: $scope.placeholder,
                dataValueField: 'value',
                dataTextField: 'text',
                filter: "contains",
                valuePrimitive: false,
                checkboxes: checkbox,
                autoClose: !$scope.isMultiselect,
                autoBind: true,
                dataSource: new kendo.data.HierarchicalDataSource({ data: $scope.treeData}),
                select: function(e){
                    var item = e.sender.dataItem(e.node);
                    if(item && item.disabled){
                      e.preventDefault();
                    } else {
                        var tree = $(`#${$scope.dropdownId}`).data("kendoDropDownTree");
                        tree.trigger("change");
                    }
                },
                change: selectionChanged,
                template: kendo.template($("#tree-template").html()),
                dataBound: function (e) {
                    $(".disabled-tree-element").parent().click(false);
                }
            };

            function selectionChanged(e){
                var items = this.value();
                if ($scope.isMultiselect && Array.isArray(items)) {

                    var selectedItem = getFirstDiffrenceItem(items, $scope.oldSelectedIds);
                    var unSelectedItem = getFirstDiffrenceItem($scope.oldSelectedIds, items);

                    var selectedItemIsObject = selectedItem ? typeof selectedItem === 'object' : false;
                    var unSelectedItemIsObject = unSelectedItem ? typeof unSelectedItem === 'object' : false;

                    if(selectedItem){
                        if(selectedItemIsObject){
                            var selectedAGroup = true;
                        } else {
                            var item = findItemByIdInTree($scope.treeData, selectedItem);
                            if(item && item.items.length > 0){
                                var selectedAGroup = true;
                            } else if(item){
                                var selectedAGroup = false;
                            }
                        }
                    }

                    if(unSelectedItem){
                        if(unSelectedItemIsObject){
                            var unSelectedAGroup = true;
                        } else {
                            var item = findItemByIdInTree($scope.treeData, unSelectedItem);
                            if(item && item.items.length > 0){
                                var unSelectedAGroup = true;
                            } else if(item){
                                var unSelectedAGroup = false;
                            }
                        }
                    }

                    // select group check
                    var treeDataItems = e.sender.dataSource.data();

                    if($scope.hasCheckbox){
                        if(selectedAGroup){
                            var selectedItemObj = findItemByIdInTree(treeDataItems, selectedItem);
                            if(selectedItemObj && selectedItemObj.items){
                                traverseTree(selectedItemObj.items)
                            }
                        }

                        if(selectedItem) {
                            var selectedItemObj = findItemByIdInTree(treeDataItems, selectedItem);
                            checkingCategories(treeDataItems, selectedItem);
                            checkingMainItems(treeDataItems);
                        }
                    }
                    // end

                    // Unselect group check
                    if($scope.hasCheckbox){
                    if(unSelectedAGroup){
                        var unSelectedItemObj = findItemByIdInTree(treeDataItems, unSelectedItem);
                        var groupItems = [unSelectedItem];
                        collectAllItemsInGroup(unSelectedItemObj.items, groupItems);
                        $scope.selectedIds = $scope.selectedIds.filter(i => !groupItems.includes(i));
                    }

                    if(unSelectedItem){
                        var unSelectedItemObj = findItemByIdInTree(treeDataItems, unSelectedItem);
                        unCheckingCategories(treeDataItems, unSelectedItemObj);
                    }
                    }

                    // end
                    _.defer(() => {
                        $scope.$apply(() => {
                            $scope.selectedIds = items;
                            $scope.selectedIds = $scope.selectedIds.filter(onlyUnique);
                            $scope.oldSelectedIds = $scope.selectedIds;
                        });
                    });

                } else {
                    _.defer(() => {
                        $scope.$apply(() => {
                            $scope.selectedIds = items;
                        });
                    });
                }

                $rootScope.$broadcast('dropdownTreeSelectedChanged', {selectedIds :$scope.selectedIds, dropdownId: $scope.dropdownId});
                setGroupVisibility();
            }

            function setGroupVisibility(){
                var dropdowntree = $(document.querySelector(`[dropdown-id="${$scope.dropdownId}"]`))[0];
                if(dropdowntree){
                    var listElements = dropdowntree.querySelectorAll('li.k-button');
                    var dropdownTreeAsTree = $(`#${$scope.dropdownId}`).data("kendoDropDownTree");
                    var categorires = [];
                    collectAllGroupItems(dropdownTreeAsTree.dataSource.data(), categorires)
                    listElements.forEach(element => {
                        var textContent = element.textContent;
                        if(categorires.includes(textContent))
                        {
                            element.style.cssText += 'display:none';
                        }
                    });
                }
            }

            function findItemByIdInTree(list, id) {
                for (const n of list) {
                  const res = n.value === id || n == id ? n : findItemByIdInTree(n.items, id);
                  if (res) return res;
                }
            }

            function checkingMainItems(list){
                for (const n of list) {
                    if(n.value){
                        if(n.isCategory == false && n.items && n.items.length > 0){
                            var res = hasSelectItemInChildren(n.items);
                            if(res){
                                $scope.selectedIds.push(n.value);
                            }
                        }
                    }

                    if(n.items && n.items.length > 0){
                        checkingMainItems(n.items);
                    }
                  }
            }

            function hasSelectItemInChildren(list){
                for (const n of list) {
                    if(n.value){
                        if($scope.selectedIds.includes(n.value) && !n.isCategory){
                            return n;
                        } else {
                            if(n.items && n.items.length > 0){
                                var res = hasSelectItemInChildren(n.items);
                                if(res){
                                    return res;
                                }
                            }
                        }
                    } else {
                        if(n.items && n.items.length > 0){
                            var res = hasSelectItemInChildren(n.items);
                            if(res){
                                return res;
                            }
                        }
                    }
                  }
            }

            function hasUnSelectItemInChildren(list){
                for (const n of list) {
                    if(n.value){
                        if($scope.selectedIds.includes(n.value) || n.disabled != null){
                            var res = hasUnSelectItemInChildren(n.items || []);
                            if(!res && n.items && n.items.length > 0){
                                $scope.selectedIds.push(n.value);
                            } else if(res) {
                                return res
                            }
                        } else {
                            return n;
                        }
                    } else {
                        if($scope.selectedIds.includes(n) || n.disabled != null){
                            var res = hasUnSelectItemInChildren(n.items || []);
                            if(!res && n.items && n.items.length > 0){
                                $scope.selectedIds.push(n);
                            } else if(res) {
                                return res
                            }
                        } else {
                            return n;
                        }
                    }
                  }
            }

            function checkingCategories(list, searchedItem){
                var foundedMainCategory = null;
                for (const mainItem of list) {
                    var res = mainItem == searchedItem || mainItem.value == searchedItem ? mainItem : findItemByIdInTree(mainItem.items, searchedItem);
                    if(res){
                        foundedMainCategory = mainItem;
                        break;
                    }
                }

                if(foundedMainCategory){
                    var foundedItemIsMainCategory = foundedMainCategory == searchedItem || foundedMainCategory.value == searchedItem ? true : false;
                    if(foundedItemIsMainCategory){
                        var hasUnSelectedChild = foundedMainCategory.items.find(i => $scope.selectedIds.includes(i.value));
                        if(!hasUnSelectedChild){
                            if(foundedMainCategory.value){
                                $scope.selectedIds.push(foundedMainCategory.value);
                            } else {
                                $scope.selectedIds.push(foundedMainCategory);
                            }
                        }
                    } else {
                        var res = hasUnSelectItemInChildren(foundedMainCategory.items);
                        if(!res && foundedMainCategory.items && foundedMainCategory.items.length > 0){
                            if(foundedMainCategory.value){
                                $scope.selectedIds.push(foundedMainCategory.value);
                            } else {
                                $scope.selectedIds.push(foundedMainCategory);
                            }
                        }
                    }
                }
            }

            function unCheckingCategories(list, searchedItem){
                var foundedMainCategory = null;
                for (const mainItem of list) {
                    var res = mainItem == searchedItem || mainItem.value == searchedItem ? mainItem : findItemByIdInTree(mainItem.items, searchedItem);
                    if(res){
                        foundedMainCategory = mainItem;
                        break;
                    }
                }

                if(foundedMainCategory){
                    var mainCategoryItem = $scope.selectedIds.find(x => x == foundedMainCategory || x == foundedMainCategory.value);
                    if(mainCategoryItem){
                        $scope.selectedIds = $scope.selectedIds.filter(x => x != mainCategoryItem);
                    }

                    if(foundedMainCategory.items && foundedMainCategory.items.length > 0) {
                        unSelectingSubCategories(foundedMainCategory.items, searchedItem);
                    }
                }
            }

            function unSelectingSubCategories(items, searchedItem){
                for (const item of items) {
                   if(item.items && item.items.length > 0){
                        var hasPerentItemsList = items.some(i => i == searchedItem || i.value == searchedItem);
                        var hasInItemList = item.items.some(i => i == searchedItem || i.value == searchedItem);
                    if(hasInItemList && item.disabled != null){
                        var unselectedIds = $scope.selectedIds.find(x => x == item || x == item.value);
                        if(unselectedIds){
                            $scope.selectedIds = $scope.selectedIds.filter(x => x != unselectedIds);
                        }
                    } else {
                        if(!hasPerentItemsList){
                            var unselectedIds = $scope.selectedIds.find(x => x == item || x == item.value);
                            if(unselectedIds && item.disabled != null){
                                $scope.selectedIds = $scope.selectedIds.filter(x => x != unselectedIds);
                            }
                            unSelectingSubCategories(item.items, searchedItem);
                        }
                    }
                   }
                 }
            }

            function collectAllItemsInGroup(list, collection){
                list.forEach(item => {
                    if(item.value){
                        collection.push(item.value);
                    } else {
                        collection.push(item);
                    }

                    if(item.items && item.items.length > 0){
                        collectAllItemsInGroup(item.items, collection);
                    }
                });
            }

            function collectAllGroupItems(list, collection){
                list.forEach(item => {
                    if(item.disabled && item.disabled == "disabled"){
                        collection.push(item.text);
                    }
                    if(item.items && item.items.length > 0){
                        collectAllGroupItems(item.items, collection);
                    }
                });
            }

            function traverseTree(arr){
                arr.forEach(el => {
                    $scope.selectedIds.push(el.value)
                    if(el.items){
                        traverseTree(el.items);
                    }
                });
            };

            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }

            function getFirstDiffrenceItem(array1, array2){
                var returnElement = null;
                array1 && array1.forEach(element => {
                    var item1 = typeof element === 'object' ? element.text : element;
                    var hasItemIn = false;
                    array2 && array2.forEach(element2 => {
                        var item2 = typeof element2 === 'object' ? element2.text : element2;
                        if(item1 == item2){
                            hasItemIn = true;
                            return;
                        }
                    });
                    if(!hasItemIn){
                        returnElement = element;
                        return;
                    }
                });
                return returnElement;
            }
        }
    }
})();
