(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('queryStringService', queryStringService);

    queryStringService.$inject = [];

    function queryStringService() {
        return {
            getParameterByName: getParameterByName,
            removeURLParameter: removeURLParameter,
            updateQueryString: updateQueryString,
        };

        function getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
            var results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        function removeURLParameter(parameter, url) {
            var urlparts = url.split('?');
            if (urlparts.length >= 2) {
                var prefix = encodeURIComponent(parameter) + '=';
                var pars = urlparts[1].split(/[&;]/g);
                for (var i = pars.length; i-- > 0; ) {
                    if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                        pars.splice(i, 1);
                    }
                }
                url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
                return url;
            } else {
                return url;
            }
        }

        function updateQueryString(url, key, value) {
            if (!url) url = window.location.href;
            if (value === '') {
                url = removeURLParameter(key, url);
            } else {
                url = updateQueryStringParameter(key, value, url);
            }
            window.history.pushState(null, null, url);
        }

        function updateQueryStringParameter(key, value, uri) {
            var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
            var separator = uri.indexOf('?') !== -1 ? '&' : '?';
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + '=' + value + '$2');
            } else {
                return uri + separator + key + '=' + value;
            }
        }
    }
})();
