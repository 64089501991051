(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('adminResourceService', adminResourceService);

    adminResourceService.$inject = ['$q', '$http', 'serviceUrls'];

    function adminResourceService($q, $http, serviceUrls) {
        const suppliersWebApiUrl = `${serviceUrls.webApiBaseUrl}/adminResource`;

        return {
            getAllResources: getAllResources,
            addResource: addResource,
            updateResource: updateResource,
            deleteResource: deleteResource
        };

        function getAllResources() {
            var deferred = $q.defer();
            var requestUrl = `${suppliersWebApiUrl}/all`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function addResource(supplier) {
            const deferred = $q.defer();
            const requestUrl = `${suppliersWebApiUrl}`;
            $http
                .post(requestUrl, supplier)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateResource(supplier) {
            const deferred = $q.defer();
            const requestUrl = `${suppliersWebApiUrl}`;
            $http
                .put(requestUrl, supplier)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteResource(id) {
            const deferred = $q.defer();
            const requestUrl = `${suppliersWebApiUrl}?id=${id}`;
            $http
                .delete(requestUrl)
                .then(response => response && response.data && deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
