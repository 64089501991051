(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationSharingEditModalController', LocationSharingEditModalController);

    LocationSharingEditModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        '$translate',
        'locationService',
        'contractorService',
        'locations'
    ];

    function LocationSharingEditModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        $translate,
        locationService,
        contractorService,
        locations
    ) {

        $scope.closeModal = closeModal;
        $scope.save = save;
        $scope.removeAssignedContractor = removeAssignedContractor;
        $scope.locations = locations;
        $scope.locationNumbers = locations.map(l => l.locationNumber);
        $scope.assignedContractors = [];
        $scope.contractors = [];
        $scope.asBatchModal = $scope.locationNumbers && $scope.locationNumbers.length > 1 ? true : false;

        $scope.contractorDropdownListOptions = {
          dataSource: new kendo.data.DataSource({
              transport: {
                  read: (e) => e.success($scope.contractors),
              }
          }),
          dataValueField: 'contractorId',
          dataTextField: 'name',
          optionLabel: $translate.instant('ADMIN_LOCATION_SETTING_TAB_CONTRACTOR_CONTRACTORS_DROPDOWN_SELECT'),
          filter: 'contains',
          change: () => {
            if($scope.selectedContractor && $scope.selectedContractor.contractorId){
              $scope.contractors = _.reject($scope.contractors, c => c.contractorId == $scope.selectedContractor.contractorId);
              $scope.assignedContractors.push($scope.selectedContractor);
              $scope.assignedContractors = _.orderBy($scope.assignedContractors, ['name'], ['asc']);
              $scope.contractors = _.orderBy($scope.contractors, ['name'], ['asc']);
              $scope.assignedContractorsListOptions.dataSource.read();
              $scope.contractorDropdownListOptions.dataSource.read();
            }
          }
      };

        $scope.assignedContractorsListOptions = {
          dataSource: new kendo.data.DataSource({
            transport: {
              read: e => e.success($scope.assignedContractors),
          }
          }),
          template:
          `<div class="row assigned-contractor-item">
              <div class="col-xs-10 col-md-10 contractor-name">#:name#</div>
              <div class="col-xs-2 col-md-2 delete-icon"><a href="" ng-click="removeAssignedContractor('#:contractorId#')"><i class="fas fa-trash-alt" aria-hidden="true"></i></a></div>
          </div>`,
          selectable: false
      };

          initController();
          function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
              id: 'locationContractorSettingsLoad',
              destination: '#location-settings-tab',
              overlay: true,
            });

            if($scope.asBatchModal){
                var requests = {
                    getAllContractors: contractorService.getContractors(),
                  }
            } else {
                var requests = {
                    getAllContractors: contractorService.getContractors(),
                    getContractorsForLocation: contractorService.getContractorsForLocation($scope.locationNumbers[0])
                  }
            }

            $q.all(requests)
              .then((results) => {
                $scope.sourceContractors = results.getAllContractors;
                $scope.assignedContractors = [];
                if(!$scope.asBatchModal){
                    $scope.$applyAsync(function () {
                        $scope.contractors = _.reject($scope.sourceContractors, c => $scope.assignedContractors.map(ac => ac.contractorId).includes(c.contractorId));
                        $scope.contractors = _.orderBy($scope.contractors, ['name'], ['asc']);
                        $scope.contractorDropdownListOptions.dataSource.read();

                        $scope.assignedContractors = results.getContractorsForLocation;
                        $scope.assignedContractors = _.orderBy($scope.assignedContractors, ['name'], ['asc']);
                        $scope.assignedContractorsListOptions.dataSource.read();
                    });
                } else {
                    $scope.$applyAsync(function () {
                        $scope.contractors = _.orderBy($scope.sourceContractors, ['name'], ['asc']);
                        $scope.contractorDropdownListOptions.dataSource.read();

                        var locationContractorIds = $scope.locations.flatMap(x => x.contractorLocations.map(m => m.contractorId));
                        $scope.assignedContractors = $scope.contractors.filter(x => locationContractorIds.includes(x.contractorId));
                        $scope.assignedContractors = _.orderBy($scope.assignedContractors, ['name'], ['asc']);
                        $scope.assignedContractorsListOptions.dataSource.read();
                    });
                }
              }).finally(function(){
                $rootScope.$broadcast(
                    'hideBusyIndicator',
                    'locationContractorSettingsLoad'
                );
            });
          }

          function removeAssignedContractor(contractorId){
            var deletedContractor =  $scope.sourceContractors.find(sc => sc.contractorId == contractorId);
            $scope.contractors.push(deletedContractor);

            $scope.assignedContractors = _.reject($scope.assignedContractors, c => c.contractorId == contractorId);
            $scope.assignedContractors = _.orderBy($scope.assignedContractors, ['name'], ['asc']);
            $scope.contractors = _.orderBy($scope.contractors, ['name'], ['asc']);
            $scope.assignedContractorsListOptions.dataSource.read();
            $scope.contractorDropdownListOptions.dataSource.read();
          }

          function save(){
            $rootScope.$broadcast('showBusyIndicator', {
              id: 'locationContractorSettingsSave',
              destination: '#location-settings-tab',
              overlay: true,
            });

            var assignedContractorIds = $scope.assignedContractors.map(a => a.contractorId);
            if(!$scope.asBatchModal){
                locationService.sharingLocationWithContractors($scope.locationNumbers[0], assignedContractorIds).then(function(){
                    $windowInstance.close(true);
                });
            } else {
                var filter = {
                    locationNumbers: $scope.locationNumbers,
                    contractorIds: assignedContractorIds
                }
                locationService.batchSharingLocationsWithContractors(filter).then(function(){
                    $windowInstance.close(true);
                });
            }
          }

          function closeModal(){
            $windowInstance.close();
          }

    }
})();
