(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OrderRequestTypeDetailsModalController', orderRequestTypeDetailsModalController);

    orderRequestTypeDetailsModalController.$inject = ['$scope', '$rootScope', '$windowInstance', '$translate', 'formType', 'formTypes', 'orderRequestType', 'adminOrderRequestTypeService'];

    function orderRequestTypeDetailsModalController($scope, $rootScope, $windowInstance, $translate, formType, formTypes, orderRequestType, adminOrderRequestTypeService) {    
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.orderRequestType = orderRequestType || null;
      
        initController();
        function initController() {
            if(formType == formTypes.edit){
                $scope.key =  $scope.orderRequestType.key;
            }
        }

        function ok() {
            if(!$scope.validator.validate()) {
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'orderRequestTypeSave',
                destination: '#order-request-type-form',
                overlay: true,
            });
       
            if (formType == formTypes.add) {
                var orderRequestTypeObj ={
                    key: $scope.key
                };
                adminOrderRequestTypeService.addOrderRequestTypes(orderRequestTypeObj).then(function(orderRequestTypeResponse){
                    $windowInstance.close(orderRequestTypeResponse);
                }).finally(function(){
                    $rootScope.$broadcast('hideBusyIndicator', 'orderRequestTypeSave');
                });
            } else {
                $scope.orderRequestType.key = $scope.key;
                adminOrderRequestTypeService.updateOrderRequestTypes($scope.orderRequestType).then(function(orderRequestTypeResponse){
                    $windowInstance.close(orderRequestTypeResponse);
                }).finally(function(){
                    $rootScope.$broadcast('hideBusyIndicator', 'orderRequestTypeSave');
                });
            }
        }

        function cancel() {
            $windowInstance.close();
        }
    }
})();
