(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('VesselParentChangeModalController', VesselParentChangeModalController);

    function VesselParentChangeModalController($scope, $rootScope, $windowInstance, $translate, departmentService, selectedDepartment, parentDepartments) {
        $scope.parentDepartments = parentDepartments;
        $scope.confirm = confirm;
        $scope.cancel = cancel;
        $scope.selectedParentDepartmentId =  null;
        const emptyGuid = '00000000-0000-0000-0000-000000000000';

        $scope.parentDepartmentOptions = {
            dataSource: new kendo.data.DataSource({transport: { read: (e) => e.success($scope.parentDepartments) }} ),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            optionLabel: $translate.instant('ADMIN_VESSEL_PARENT_DEPARTMENT_SELECT'),
        };

        function confirm() {
            showBusyIndicator("updateVesselsBusyIndicator","vessel-parent-form");

            departmentService
                .updateParentChildDepartmentsWebApi(selectedDepartment.departmentId, $scope.selectedParentDepartmentId && $scope.selectedParentDepartmentId.departmentId ? $scope.selectedParentDepartmentId.departmentId : emptyGuid)
                .then((response) => $windowInstance.close({ result: true, data: response }))
                .catch((error) => console.log(error))
                .finally(() => hideBusyIndicator("updateVesselsBusyIndicator"));
        }

        function cancel() {
            $windowInstance.close(true);
        }

        function showBusyIndicator(id, destination) {
            $rootScope.$broadcast('showBusyIndicator', { id: id, destination: `#${destination}`, overlay: true });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', id);
        }
    }
})();
