(function () {
    angular.module('NaviaqWebApp').factory('userConfigurationService', userConfigurationService);

    userConfigurationService.$inject = ['$q', '$http','serviceUrls'];

    function userConfigurationService($q, $http, serviceUrls) {
        const userConfigurationApiUrl = `${serviceUrls.webApiBaseUrl}/userConfiguration`;

        return { getUserConfigurationSettings, addUserConfiguration };

        function getUserConfigurationSettings(system, pageName) {
            const deferred = $q.defer();
            const url = `${userConfigurationApiUrl}/?system=${system}&pageName=${pageName}`;

            $http.get(url)
                 .then(response => deferred.resolve(response.data))
                 .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addUserConfiguration(userConfiguration) {
            const deferred = $q.defer();

            $http.post(userConfigurationApiUrl, userConfiguration)
                 .then(response => deferred.resolve(response.data))
                 .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
