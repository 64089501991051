(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('serviceFormService', serviceFormService);

    serviceFormService.$inject = ['$q', '$http', 'serviceUrls'];

    function serviceFormService($q, $http, serviceUrls) {
        const serviceFormWebApiUrl = `${serviceUrls.webApiBaseUrl}/serviceForm`;

        return {
            getServiceFormOptions: getServiceFormOptions,
            getServiceFormTemplates: getServiceFormTemplates,
            addServiceForm: addServiceForm,
            updateServiceForm: updateServiceForm,
            addOrUpdateServiceFormOption: addOrUpdateServiceFormOption,
            deleteServiceForm: deleteServiceForm,
            deleteServiceFormOptions: deleteServiceFormOptions
        };

        function getServiceFormTemplates(contractorId) {
            var deferred = $q.defer(),
            requestUrl = serviceFormWebApiUrl + `/byContractorId?contractorId=${contractorId}`;

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));
                return deferred.promise;
        }

        function getServiceFormOptions() {
            var deferred = $q.defer();
            var requestUrl = `${serviceFormWebApiUrl}/formoptions`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    var options = _.map(response.data, function(o){
                        return {
                            id:o.serviceFormOptionsId,
                            name:o.name,
                            options:JSON.parse(o.options)
                        }
                    });
                    deferred.resolve(options);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addOrUpdateServiceFormOption(serviceFormOption){
            var deferred = $q.defer(),
            requestUrl = serviceFormWebApiUrl + `/formoptions`;

            $http
                .post(requestUrl,serviceFormOption)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));
                return deferred.promise;
        }

        function deleteServiceForm(serviceFormId){
            var deferred = $q.defer(),
            requestUrl = serviceFormWebApiUrl + `?serviceFormId=${serviceFormId}`;

            $http
                .delete(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));
                return deferred.promise;
        }

        function deleteServiceFormOptions(id){
            var deferred = $q.defer(),
            requestUrl = serviceFormWebApiUrl + `/formoptions?serviceFormOptionsId=${id}`;

            $http
                .delete(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));
                return deferred.promise;
        }


        function addServiceForm(serviceForm){
            var deferred = $q.defer(),
            requestUrl = serviceFormWebApiUrl + `/template`;

            $http
                .post(requestUrl,serviceForm)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));
                return deferred.promise;
        }

        function updateServiceForm(serviceForm){
            var deferred = $q.defer(),
            requestUrl = serviceFormWebApiUrl + `/template`;

            $http
                .put(requestUrl,serviceForm)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));
                return deferred.promise;
        }
    }
})();
