(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('BalanceController', BalanceController);

    BalanceController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$state',
        '$translate',
        'timeBankService',
        'dateUtility',
        'kendoUtility',
        'months'
    ];

    function BalanceController(
        $rootScope,
        $scope,
        $q,
        $state,
        $translate,
        timeBankService,
        dateUtility,
        kendoUtility,
        months
    ) {
        $scope.balances =  [];
        $scope.years = [];
        $scope.totalBalanceSum = 0;
        $scope.exportToExcel = exportToExcel;
        $scope.useFilter = useFilter;

        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];

        $scope.$on('langChanged', (e, code) => {
            $scope.balanceGridOptions = balanceGridOptions(code);
            var grid = kendoUtility.createWidget('kendoGrid', $scope.balanceGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#balance-grid')]);
            langId = code;
        });

        $scope.$on('timeBankFilterChanged', function (event, filter, isUpdate, state) {
            $scope.filter = angular.copy(filter);
            if(isUpdate && (state == 'balance' || (!state && $state.current.name == 'timeBank.balance'))){
                loadFilteredBalance();
            }

        });

        $scope.BalanceYearDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.years);
                    }
                },
            }),
            dataValueField: 'value',
            dataTextField: 'description',
            noDataTemplate: 'Ingen treff',
        };

        var balanceGridOptions = (langId) => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.balances),
                },
                schema:{
                    model: {
                        fields: {
                            balanceValue: { type: "number" },
                            sumWork: { type: "number" }
                        }
                    }
                },
                group:
                [{ field: 'userDisplayName' , aggregates:
                    [ { field: "balanceValue", aggregate: "sum",},
                     { field: "sumWork", aggregate: "sum" }]
               },
                 { field: 'balanceMonth', aggregates:
                     [ { field: "balanceValue", aggregate: "sum" },
                     { field: "sumWork", aggregate: "sum" }]
                 }]
            }),
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'userDisplayName',
                    hidden: true,
                    groupHeaderTemplate: dataItem => {
                        return dataItem.value;
                    }
                },
                {
                    field: 'balanceMonth',
                    hidden: true,
                    groupHeaderTemplate: dataItem => {
                        return months[dataItem.value - 1];
                    }
                },
                {
                    field: 'departmentName',
                    title: $translate.instant('TIMEBANK_BALANCE_VESSEL', null, null, langId),
                },
                {
                    field: 'logActionPairType',
                    title: $translate.instant('TIMEBANK_BALANCE_LOGACTIONPAIR', null, null, langId),
                },
                {
                    field: 'startDate',
                    title: $translate.instant('TIMEBANK_BALANCE_STARTDATE', null, null, langId),
                    template: function (dataItem) {
                        return dateUtility.formatDateTime(dataItem.startDate);
                    },
                },
                {
                    field: 'stopDate ',
                    title: $translate.instant('TIMEBANK_BALANCE_STOPDATE', null, null, langId),
                    template: function (dataItem) {
                        return dateUtility.formatDateTime(dataItem.stopDate);
                    },
                },
                {
                    field: 'sumWork',
                    title: $translate.instant('TIMEBANK_BALANCE_SUMWORK', null, null, langId),
                    groupFooterTemplate: function(e) {
                        if((e.group && e.group.field == "balanceMonth") || e.field == "balanceMonth") {
                            return "Sum " + months[e.value - 1] +  ": " + e.sumWork.sum + " "
                            + (e.sumWork.sum > 1 ? $translate.instant('TIMEBANK_BALANCE_DAYS', null, null, langId)
                            : $translate.instant('TIMEBANK_BALANCE_DAY', null, null, langId));
                        } else {
                            return '';
                        }
                    }
                },
                {
                    field: 'sumUnit',
                    title: $translate.instant('TIMEBANK_BALANCE_SUMUNIT', null, null, langId),
                    template: function (dataItem) {
                        if(dataItem.sumWork && dataItem.sumWork > 1){
                            return $translate.instant('TIMEBANK_BALANCE_DAYS', null, null, langId);
                        } else {
                            return $translate.instant('TIMEBANK_BALANCE_DAY', null, null, langId);
                        }
                    }
                },
                {
                    field: 'workDays',
                    title: $translate.instant('TIMEBANK_BALANCE_WORKDAYS', null, null, langId),
                    groupFooterTemplate: function(e) {
                        if(e.field == "balanceMonth") {
                            return '15,20';
                        } else {
                            return '';
                        }
                    }
                },
                {
                    field: 'balanceValue',
                    title: $translate.instant('TIMEBANK_BALANCE_BALANCE', null, null, langId),
                    template: function (dataItem) {
                        if(dataItem.balanceValue){
                            return dataItem.balanceValue.toFixed(2);
                        } else {
                            return '';
                        }
                    },
                    groupFooterTemplate: function(e) {
                        if( (e.group && e.group.field == "balanceMonth") || e.field == "balanceMonth") {
                           if(e.balanceValue.sum){
                                e.balanceValue.sum = e.sumWork.sum - 15.20 + e.balanceValue.sum;
                                return $translate.instant('TIMEBANK_BALANCE_BALANCE', null, null, langId) + " "
                                + months[(e.group ? e.group.value  : e.value) - 1] +  ": " + e.balanceValue.sum.toFixed(2);
                           } else{
                                e.balanceValue.sum = e.sumWork.sum - 15.20;
                               return $translate.instant('TIMEBANK_BALANCE_BALANCE', null, null, langId) + " "
                               + months[(e.group ? e.group.value  : e.value) - 1] +  ": " + e.balanceValue.sum.toFixed(2);
                           }
                        } else {

                            if(!e.balanceValue.sum){
                                e.balanceValue.sum = 0;
                            }

                            if(e.balanceValue.sum || e.balanceValue.sum == 0){
                                var monthCount = e.group ? e.group.items.length : e.items.length;
                                e.balanceValue.sum = e.sumWork.sum - (15.20 * monthCount) + e.balanceValue.sum;
                                $scope.totalBalanceSum += e.balanceValue.sum;
                                return $translate.instant('TIMEBANK_BALANCE_BALANCE', null, null, langId) + " "
                                + (e.group ? e.group.value  : e.value) +  ": " + e.balanceValue.sum.toFixed(2);
                            } else {
                                var monthCount = e.group ? e.group.items.length : e.items.length;
                                e.balanceValue.sum = e.sumWork.sum - (15.20 * monthCount);
                                return $translate.instant('TIMEBANK_BALANCE_BALANCE', null, null, langId) + " "
                                + (e.group ? e.group.value  : e.value) +  ": " + e.balanceValue.sum.toFixed(2);
                            }
                        }
                    }
                },
                {
                    field: 'description',
                    title: $translate.instant('TIMEBANK_BALANCE_DESCRIPTION', null, null, langId)
                }
            ],
            editable: false,
            filterable: true,
            groupable: false,
            resizable: true,
            sortable: true,
        });
        $scope.balanceGridOptions = balanceGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.totalBalanceSum = 0;
            $scope.balanceGridOptions = balanceGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.balanceGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#balance-grid')]);
        });

        initController();

        function initController() {
            loadYears();
            $scope.selectedYear = $scope.years.find(y => y.value == new Date().getFullYear()).value;
            $scope.BalanceYearDropdownOptions.dataSource.read();
        }

        function loadFilteredBalance() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'timeBankBalanceBusyIndicator',
                destination: '#balance-grid',
                overlay: true,
            });

            $scope.totalBalanceSum = 0;
            $scope.filter.balanceYear = $scope.selectedYear || new Date().getFullYear();
            $scope.filter.fromDate = new Date($scope.filter.balanceYear + '-01-01');
            $scope.filter.toDate = new Date($scope.filter.balanceYear + '-12-31');

            $q.all([timeBankService.getTimebankBalaces($scope.filter)]).then(function (results) {
                $scope.balances = results[0];
                extendBalances();
                $scope.balanceGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'timeBankBalanceBusyIndicator');
            });
        }

        function extendBalances(){
            _.forEach($scope.balances, function (balance) {
               balance.sumWork = balance.sum;
            });
        }

        function useFilter(){
            loadFilteredBalance();
        }

        function exportToExcel(grid){
            $scope.totalBalanceSum = 0;
            grid.saveAsExcel();
        }

        function loadYears(){
            var currentYear = new Date().getFullYear();
            for (var i = currentYear; i >= 2017; i--) {
                $scope.years.push({
                    "description" : i,
                    "value" : i
                });
            }
        }
    }
})();
