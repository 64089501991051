(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('locationLogService', locationLogService);

    locationLogService.$inject = ['$q', '$http', 'serviceUrls'];

    function locationLogService($q, $http, serviceUrls) {
        const locationLogApiUrl = `${serviceUrls.webApiBaseUrl}/locationLog`;

        return {
            getLocationLogsByFilter,
            getLocationLogByMapObject
        };

        function getLocationLogsByFilter(filter) {
            const deferred = $q.defer();

            $http.post(`${locationLogApiUrl}/locationLogsByFilter`, filter)
                .then(
                    (response) => deferred.resolve(response.data),
                    () => deferred.reject()
                );

            return deferred.promise;
        }

        function getLocationLogByMapObject(mapObject) {
            const deferred = $q.defer();

            $http.post(`${locationLogApiUrl}/byMapObject`, {
                bargeId: mapObject.bargeId,
                cageId: mapObject.cageId,
                netId: mapObject.Id,
                ringId: mapObject.ringId,
                mapLayerObjectId: mapObject.mapLayerObjectId,
                mapObjectType: mapObject.objectType
            }).then(
                (response) => deferred.resolve(response.data),
                () => deferred.reject()
            );

            return deferred.promise;
        }
    }
})();
