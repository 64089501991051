(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('adminConglomerateService', adminConglomerateService);

    adminConglomerateService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function adminConglomerateService(
        $q,
        $http,
        serviceUrls,
    ) {
        const adminConglomerateApiUrl = `${serviceUrls.webApiBaseUrl}/adminConglomerate`;

        return {
            getConglomerates,
            getContractors,
            addConglomerate,
            updateConglomerate,
            deleteConglomerate
        };

        function getConglomerates() {
            const deferred = $q.defer();

            $http
                .get(adminConglomerateApiUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getContractors(selectedIds) {
            const deferred = $q.defer();

            $http({
                url: `${adminConglomerateApiUrl}/contractors`,
                method: "GET",
                params: { selectedIds: selectedIds }
            })
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addConglomerate(conglomerate) {
            const deferred = $q.defer();

            $http
                .post(adminConglomerateApiUrl, conglomerate)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateConglomerate(conglomerate) {
            const deferred = $q.defer();

            $http
                .put(adminConglomerateApiUrl, conglomerate)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteConglomerate(id) {
            const deferred = $q.defer();

            $http
                .delete(`${adminConglomerateApiUrl}?id=${id}`)
                .then(response => response && response.data && deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
