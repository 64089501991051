(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('jobDefinitionService', jobDefinitionService);

    jobDefinitionService.$inject = ['$q', '$http', 'serviceUrls'];

    function jobDefinitionService($q, $http, serviceUrls) {
        const webApiUrl = `${serviceUrls.webApiBaseUrl}/jobDefinition`;

        const service = {
            addJobDefinition: addJobDefinition,
            updateJobDefinition: updateJobDefinition
        };

        return service;

        function addJobDefinition(jobDefinition) {
            const deferred = $q.defer();

            $http.post(webApiUrl, jobDefinition).then(function(response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateJobDefinition(jobDefinition) {
            const deferred = $q.defer();

            $http.put(webApiUrl, jobDefinition).then(function(response) {
                deferred.resolve(response.data);
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
