(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CustomersDetailsModalController', CustomersDetailsModalController);

    CustomersDetailsModalController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'customer',
        'currentFormType',
        'formTypes',
        'customerService',
    ];

    function CustomersDetailsModalController(
        $rootScope,
        $scope,
        $windowInstance,
        customer,
        currentFormType,
        formTypes,
        customerService,
    ) {
        $scope.customer = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        initController();

        function initController() {
            if (currentFormType === formTypes.edit && customer) {
                $scope.customer = customer;
            }

            if (currentFormType === formTypes.add) {
                $scope.customer.contractorId = $rootScope.authData && $rootScope.authData.contractorId ? $rootScope.authData.contractorId : null;
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'customerDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        customerService.addCustomer($scope.customer)
                            .then(customer => close($scope.customer))
                            .catch(() => close());
                        break;
                    case formTypes.edit:
                        customerService.updateCustomer($scope.customer)
                            .then(customer => close($scope.customer))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'customerDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
