(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('workOrderDetailsModalService', workOrderDetailsModalService);

    workOrderDetailsModalService.$inject = ['$q', '$rootScope', '$kWindow', '$translate', 'departmentService', 'userService', 'formTypes',
        'workOrderStatuses', 'noUser', '$state', 'serviceUrls', '$http'];

    function workOrderDetailsModalService($q, $rootScope, $kWindow, $translate, departmentService, userService, formTypes,
        workOrderStatuses, noUser, $state, serviceUrls, $http) {

        var departments = null
        var users = null;
        var caseData = null;

        var webApiBaseUrl = serviceUrls.webApiBaseUrl;

        var service = {
            createWorkOrderLog: createWorkOrderLog,
            createWorkOrderDetailsResolve: createWorkOrderDetailsResolve,
            openWorkOrderDetailsModal: openWorkOrderDetailsModal,
            getWorkOrderLogFromServer: getWorkorderLogFromServer,
            flush: flush
        };

        return service;

        function flush(){
            users = null;
            departments = null;
        };

        function createWorkOrderLog(workOrder) {
            workOrder.editedOn = new Date();
            workOrder.editedBy = $rootScope.authData.username;
            workOrder.doneOnObject = new Date(workOrder.doneOn);
            workOrder.dueDateObject = new Date(workOrder.dueDate);
            workOrder.doneToObject = new Date(workOrder.doneTo);
            return workOrder;
        }

        function flush(){
            departments = null;
            users = null;
        }

        function createWorkOrderDetailsResolve(workOrderLog) {
            var deferred = $q.defer();
            if (!departments || !users) {
                $q.all([
                    departmentService.getDepartmentsWebapi($rootScope.authData.contractorId, true),
                    userService.getUsersByContractorIdWebApi($rootScope.authData.contractorId)
                ]).then(function (data) {
                    departments = data[0];

                    var modifiedData = data[1].concat([noUser]).sort(function (a, b) {
                        if (a.displayName < b.displayName) { return -1; }
                        if (a.displayName > b.displayName) { return 1; }
                        return 0;
                    });
                    users = modifiedData;

                    deferred.resolve(workOrderLog);
                }, function (error) {
                    deferred.reject(error);
                });
            } else {
                deferred.resolve(workOrderLog);
            }

            return deferred.promise;
        }

        function openWorkOrderDetailsModal(workOrderLog, caseData = null, canEdit = true) {
            var modal = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('WORKORDER_MODAL_TITLE'),
                    resizable: true,
                    visible: false,
                    minWidth: 1050,
                    maxHeight: 700
                },
                templateUrl: 'app/workOrders/work-order-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'WorkOrderDetailsModalController',
                resolve: {
                    statuses: () => workOrderStatuses,
                    departments: () => departments,
                    workOrderLog: () => workOrderLog,
                    modalType: () => workOrderLog ? workOrderLog.operationLogId ? formTypes.edit : formTypes.add : formTypes.add,
                    users: () => users,
                    caseData: () => caseData,
                    canEdit: () => canEdit
                }
            });

            if($state.current.name === "workorders"){
                modal.result.finally(function (response) {
                    $state.go('workorders', {'woNo': ''}, {notify: false});
                });
            }

            return modal;
        }

        function getWorkorderLogFromServer(operationLogId) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/workOrderLog/byOperationLogId?operationLogId=' + operationLogId;
            $http.get(url).then(response => {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
