(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('mooringReportController', mooringReportController);

    mooringReportController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$q',
        '$translate',
        '$http',
        'mapUtility',
        'mediaService',
        'anonymousReportService'
    ];

    function mooringReportController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $q,
        $translate,
        $http,
        mapUtility,
        mediaService,
        anonymousReportService
    ) {
        $scope.printReport = printReport;
        $scope.orderId = $stateParams.OrderId;
        $scope.mooringReport = {};
        $scope.componentTreeHistory = {};
        $scope.reportImageUrl = '';
        $scope.reportFooterImageUrl = '';
        $scope.departmentImageURL = '';
        $scope.documentType = -1;
        $scope.contractorId = '';

        $scope.bottomLines = [];
        $scope.frameLines = [];
        $scope.henfeetLines = [];
        $scope.buoyLines = [];

        $scope.displayBottomLinesText = false;
        $scope.displayFrameLinesText = false;
        $scope.displayHenfeetLinesText = false;
        $scope.displayBuoyLinesText = false;

        $scope.bottomLinesPage = 0;
        $scope.frameLinesPage = 0;
        $scope.henfeetLinesPage = 0;
        $scope.buoyLinesPage = 0;
        $scope.componentTablePages = 0;

        $scope.lastIndexOfCases = 0;
        $scope.getIndexOfCurrentCases = getIndexOfCurrentCases;
        function getIndexOfCurrentCases(cases) {
            var index = $scope.mooringReport.captainMessagesCasesAndDivers.indexOf(cases);
            $scope.lastIndexOfCases = $scope.lastIndexOfCases < index + 1 ? index + 1 : $scope.lastIndexOfCases;
            return index;
        }

        $scope.lastIndexOfComponentTables = 0;
        $scope.getIndexOfCurrentComponentTable = function(componentTable) {
            var index = $scope.mooringReport && $scope.mooringReport.componentTables ? $scope.mooringReport.componentTables.indexOf(componentTable) : 0;
            $scope.lastIndexOfComponentTables = $scope.lastIndexOfComponentTables < index + 1 ? index + 1 : $scope.lastIndexOfComponentTables;
            return index;
        }

        $scope.lastIndexOfCoordinates = 0;
        $scope.getIndexOfCurrentCoordinate = function(anchorOrBoltCoordinates) {
            var index = $scope.mooringReport && $scope.mooringReport.anchorOrBoltCoordinateLists ? $scope.mooringReport.anchorOrBoltCoordinateLists.indexOf(anchorOrBoltCoordinates) : 0;
            $scope.lastIndexOfCoordinates = $scope.lastIndexOfCoordinates < index + 1 ? index + 1 : $scope.lastIndexOfCoordinates;
            return index;
        }

        $scope.lastIndexOfHoldingForces = 0;
        $scope.getIndexOfCurrentHoldingForce = function(anchorOrBoltHoldingForces) {
            var index = $scope.mooringReport && $scope.mooringReport.anchorOrBoltHoldingForceLists ? $scope.mooringReport.anchorOrBoltHoldingForceLists.indexOf(anchorOrBoltHoldingForces) : 0;
            $scope.lastIndexOfHoldingForces = $scope.lastIndexOfHoldingForces < index + 1 ? index + 1 : $scope.lastIndexOfHoldingForces;
            return index;
        }

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#mooring-report-container').addClass('print-view-container');
        }

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'mooringReportIndicator',
                destination: '#main-view',
                overlay: true,
            });

            $('html').addClass('auto-overflow');

            var request = {
                getMooringReportContent: anonymousReportService.getMooringReportContent($scope.orderId),
            };
            $q.all(request).then(function (result) {
                var mooringReportContent = result.getMooringReportContent;

                if (mooringReportContent.order) {
                    $scope.contractorId = mooringReportContent.order.contractorId;
                    getOrderData(mooringReportContent.order, mooringReportContent.locationReference);
                    getOrderDepartment(mooringReportContent.order)
                }
                if (mooringReportContent.serviceFormDataWithTemplate && mooringReportContent.serviceFormDataWithTemplate.length && mooringReportContent.serviceFormOptions) {
                    getServiceForms(mooringReportContent.serviceFormDataWithTemplate, mooringReportContent.serviceFormOptions, mooringReportContent.usersByContractorId, mooringReportContent.lineWithLineComponents);
                }
                if (mooringReportContent.skipperMessage || (mooringReportContent.deviations && mooringReportContent.deviations.length)) {
                    handleCaptainMessageAndCaseAndDiverPage(mooringReportContent.skipperMessage, mooringReportContent.deviations);
                }

                if(mooringReportContent.orderLineComponentHistory){
                    getComponentTables(mooringReportContent.orderLineComponentHistory);
                }

                $rootScope.$broadcast('mooringReportFooterData', $scope.mooringReport);
                $rootScope.$broadcast('hideBusyIndicator', 'mooringReportIndicator');
            });
        }

        function getOrderData(order, locationReference) {
            if(order && order.contactPersons
                && order.contactPersons.length > 0){
                    order.contactPerson = order.contactPersons[0];
            } else {
                order.contactPerson = null;
            }
            $scope.mooringReport.order = order;
            $scope.reportImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-${$scope.mooringReport.order.contractorId}.png`;
            $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer-${$scope.mooringReport.order.contractorId}.png`;

            $http.get($scope.reportImageUrl, {headers: {'Anonymous' : ''}, responseType: 'blob'})
                .then(response =>
                    {
                        $scope.reportImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-${$scope.mooringReport.order.contractorId}.png`;
                    })
                .catch(error => {
                    $scope.reportImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-e083f410-67ab-e611-a3b0-f23cffe3d187.png`; // default naviaq image
                })


            if ($scope.mooringReport.order.senderUser && $scope.mooringReport.order.senderUser.person) {
                var person = $scope.mooringReport.order.senderUser.person;
                var user = $scope.mooringReport.order.senderUser;
                $scope.mooringReport.sender = {};
                $scope.mooringReport.sender.name = user.displayName;
                $scope.mooringReport.sender.email = user.userName;
                $scope.mooringReport.sender.phone = person.mobilePhoneNumber
                    ? person.mobilePhoneNumber
                    : person.phoneNumber;
            }
            if ($scope.mooringReport.order.projectManagerObject && $scope.mooringReport.order.projectManagerObject.person) {
                var projectManagerPerson = $scope.mooringReport.order.projectManagerObject.person;
                var projectManagerUser = $scope.mooringReport.order.projectManagerObject;

                $scope.mooringReport.order.projectManagerName = projectManagerUser.displayName;
                $scope.mooringReport.order.projectManagerEmail = projectManagerUser.userName;
                $scope.mooringReport.order.projectManagerPhone = projectManagerPerson.mobilePhoneNumber
                    ? projectManagerPerson.mobilePhoneNumber
                    : projectManagerPerson.phoneNumber;
            }

            if (locationReference && locationReference.locationReferenceContent) {
                $scope.mooringReport.order.locationReferenceContent = locationReference.locationReferenceContent;
            }
            $scope.documentType = $scope.mooringReport.order.orderProcess && $scope.mooringReport.order.orderProcess.documentType > -1
                ? $scope.mooringReport.order.orderProcess.documentType
                : -1;
            $scope.mooringReport.order.orderProcessName = $scope.mooringReport.order.orderProcess ? $scope.mooringReport.order.orderProcess.name : '';
            setOrderDisplayName();

            if ($scope.mooringReport.order.orderedDate) {
                var orderedDate = new Date($scope.mooringReport.order.orderedDate);
            }
            if ($scope.mooringReport.order.orderDate) {
                var orderDate = new Date($scope.mooringReport.order.orderDate);
            }
            if ($scope.mooringReport.order.expireDate) {
                var expireDate = new Date($scope.mooringReport.order.expireDate);
            }
            if (orderDate) {
                $scope.mooringReport.order.orderDateString = moment(orderDate).format('DD.MM.YYYY');
                $scope.mooringReport.order.orderDateHoursAndMinutesString = moment(orderDate).format('HH:mm');
            }
            if (expireDate) {
                $scope.mooringReport.order.expireDateString = moment(expireDate).format('DD.MM.YYYY');
                $scope.mooringReport.order.expireDateHoursAndMinutesString = moment(expireDate).format('HH:mm');
            }
            if (orderedDate) {
                $scope.mooringReport.order.orderedDateString = moment(orderedDate).format('DD.MM.YYYY');
            }
        }

        function getOrderDepartment(order) {
            let departmentImageURLs = [];
            const orderDepartment = order.orderDepartment;
            const departments = order.orderDepartments.map((x) => {
                if (x.department && x.department.departmentInfo && x.department.departmentInfo.defaultMediaId) {
                    departmentImageURLs.push(getMediaUrl(x.department.departmentInfo.defaultMediaId));
                }
                return x.department;
            });

            if (orderDepartment) {
                setMooringReportOrder(orderDepartment);
                $scope.departmentImageURL = orderDepartment.departmentInfo && orderDepartment.departmentInfo.defaultMediaId
                    ? getMediaUrl(orderDepartment.departmentInfo.defaultMediaId) : '';
            } else if (departments.length) {
                setMooringReportOrder(departments[0]);
                $scope.departmentImageURL = departmentImageURLs.length ? departmentImageURLs[0] : '';
            }
        }

        function setMooringReportOrder (department) {
            $scope.mooringReport.order.departmentName = department.name;
            $scope.mooringReport.order.departmentEmail = department.departmentInfo && department.departmentInfo.email ? department.departmentInfo.email : '';
            $scope.mooringReport.order.departmentPhone = department.departmentInfo && department.departmentInfo.phone
                                        ? department.departmentInfo.phone
                                        : department.departmentInfo && department.departmentInfo.ownerPhone ? department.departmentInfo.ownerPhone : '';
        }

        function getMultiSelectOptionsValues(template, key, selectedItemIndexes, serviceFormOptions) {
            var selectedOptions = [];
            if (template) {
                _.forEach(selectedItemIndexes, function (selectedItemIndex) {
                    var option = serviceFormOptions.find((s) => s.name == template.optionsName);
                    if (option && option.options && option.options.length >= selectedItemIndex) {
                        selectedOptions.push(option.options[selectedItemIndex].name);
                    }
                });
            }
            if (selectedOptions.length > 0) {
                return selectedOptions.join(', ');
            } else {
                return '';
            }
        }

        function setReferencedValues(template, serviceFormData, users) {
            var userTemplateKeys = template.filter(field => field.optionsType === 'user').map(field => field.key);
            var requests = [];
            _.forEach(userTemplateKeys, key => {
                if (serviceFormData[key] && serviceFormData[key].length) {
                    var user = users.find(x => x.userId === serviceFormData[key])
                    if (user) {
                        var formValue = user.displayName;
                        serviceFormData[key] = formValue;
                    }
                }
            });

            return $q.all(requests);
        }

        function formatBuoysCoordinates(buoys){
            if(buoys && buoys.length){
                buoys.forEach(buoy => {
                    if(buoy.firstPoint){
                        const lat = mapUtility.convertToDDM(buoy.firstPoint.y);
                        const long = mapUtility.convertToDDM(buoy.firstPoint.x);

                        buoy.firstPoint.x = `${long.degrees}°${long.minutes}.${long.seconds}`;
                        buoy.firstPoint.y = `${lat.degrees}°${lat.minutes}.${lat.seconds}`;
                    }
                })
            }
        }

        function getServiceForms(serviceFormDataWithTemplate, serviceFormOptions, users, lineWithLineComponents) {
            serviceFormOptions.forEach(serviceFormOption => {
                if(serviceFormOption.options){
                    serviceFormOption.options = JSON.parse(serviceFormOption.options);
                }
            });

            var serviceFormDataWithTemplate = serviceFormDataWithTemplate.reverse().find(x => x.serviceForm.showOnMooringReport || x.serviceForm.name === "MooringReportLog2") //TODO: NEED TO REMOVE NAME CHECK
            if(serviceFormDataWithTemplate && serviceFormDataWithTemplate.data && serviceFormDataWithTemplate.formTemplate){
                var serviceFormData = JSON.parse(serviceFormDataWithTemplate.data);
                var template = JSON.parse(serviceFormDataWithTemplate.formTemplate);
                var serviceForm = [];

                formatBuoysCoordinates(serviceFormData.moorningreport_buoys);
                setReferencedValues(template, serviceFormData, users).then(() => {
                    jQuery.each(serviceFormData, function (dataFieldName, dataFieldValue) {
                        var matchingTemplate = _.find(template, { key: dataFieldName });
                        if (matchingTemplate) {
                            var formValue = '';
                            switch (matchingTemplate.type) {
                                case 'select':
                                    switch (matchingTemplate.optionsType) {
                                        case 'department':
                                            formValue = dataFieldValue;
                                            break;
                                        case 'logaction':
                                            formValue = dataFieldValue;
                                            break;
                                        case 'user':
                                            formValue = dataFieldValue;
                                            break;
                                        default: {
                                            break;
                                        }
                                    }
                                    if (matchingTemplate.optionsName && serviceFormOptions) {
                                        var optionsLists = serviceFormOptions;
                                        var options = optionsLists.find((oL) => oL.name == matchingTemplate.optionsName)
                                            .options;
                                        if(options){
                                            var selectedOption = options.find((x) => x.value === dataFieldValue);
                                            if (selectedOption && selectedOption.name) {
                                                formValue = selectedOption.name;
                                            }
                                        }
                                    }
                                    break;
                                case 'checkbox':
                                    if (dataFieldValue) {
                                        formValue = $translate.instant('G_YES');
                                    } else {
                                        formValue = $translate.instant('G_NO');
                                    }
                                    break;
                                case 'multiselect':
                                    formValue = getMultiSelectOptionsValues(
                                        matchingTemplate,
                                        dataFieldName,
                                        dataFieldValue,
                                        serviceFormOptions
                                    );
                                    break;
                                case 'time':
                                    if (dataFieldValue) {
                                        formValue = moment(new Date(dataFieldValue)).format('HH:mm');
                                    }
                                    break;
                                case 'datetime':
                                    if (dataFieldValue) {
                                        formValue = moment(new Date(dataFieldValue)).format('DD.MM.YYYY HH:mm');
                                    }
                                    break;
                                case 'date':
                                    if (dataFieldValue) {
                                        formValue = moment(new Date(dataFieldValue)).format('DD.MM.YYYY');
                                    }
                                    break;
                                default:
                                    formValue = dataFieldValue;
                                    break;
                            }

                            if(!matchingTemplate.hidden){
                                var serviceFormFieldName = matchingTemplate.label
                                    ? matchingTemplate.label
                                    : matchingTemplate.key;
                                serviceForm.push({ key: dataFieldName, name: serviceFormFieldName, value: formValue });
                            }
                        }
                    });

                    //Header
                    var headers = [];
                    var keysOfServiceFormData = serviceForm.map(x => x.key);
                    var syncedTempalte = template.filter(x => keysOfServiceFormData.includes(x.key) || x.type == 'header');
                    syncedTempalte.forEach((templateElement, i) => {
                        if (templateElement.type === "header" && !templateElement.hidden) {
                            headers.push({ key: templateElement.key, label: templateElement.label, index: i })
                        }
                    });

                    if (headers.length) {
                        headers.forEach(header => {
                            serviceForm.splice(header.index, 0, { key: header.key, label: header.label, type: "header" });
                        });
                    }

                    var serviceFormKeyList = syncedTempalte.map(s => s.key);
                    serviceForm = serviceForm.sort(function(a, b){
                        return serviceFormKeyList.indexOf(a.key) - serviceFormKeyList.indexOf(b.key);
                    });

                    if(serviceForm.length) {
                        $scope.mooringReport.serviceFormGeneral = {};
                        $scope.mooringReport.serviceFormGeneral.user = serviceForm.find(x => x.key === "mooringreport_user").value;
                        $scope.mooringReport.serviceFormGeneral.periodFrom = serviceForm.find(x => x.key === "mooringreport_periodfrom").value;
                        $scope.mooringReport.serviceFormGeneral.periodTo = serviceForm.find(x => x.key === "mooringreport_periodto").value;
                        $scope.mooringReport.serviceFormGeneral.vesselsDesc = serviceForm.find(x => x.key === "mooringreport_vesselsdesc").value;
                        $scope.mooringReport.serviceFormGeneral.seabedMethodDesc = serviceForm.find(x => x.key === "mooringreport_seabedmethoddesc").value;
                        $scope.mooringReport.serviceFormGeneral.maxLoadTestDesc = serviceForm.find(x => x.key === "mooringreport_maxloadtesddesc").value;
                        $scope.mooringReport.serviceFormGeneral.buoyCoordinateLists = serviceFormData.moorningreport_buoys;

                        $scope.mooringReport.serviceFormChanges = {};
                        $scope.mooringReport.serviceFormChanges.facilityChanges = serviceForm.find(x => x.key === "mooringreport_facilitychanges").value;
                        $scope.mooringReport.serviceFormChanges.bargeChanges = serviceForm.find(x => x.key === "mooringreport_bargechanges").value;

                        $scope.mooringReport.serviceFormMapImages = {};
                        var plannedImage = serviceForm.find(x => x.key === "mooringreport_plannedimg");
                        if(plannedImage){
                            $scope.mooringReport.serviceFormMapImages.plannedImage = getMediaUrl(plannedImage.value.mediaId);
                        }

                        var asBuiltImage = serviceForm.find(x => x.key === "mooringreport_asbuiltimg");
                        if(plannedImage){
                            $scope.mooringReport.serviceFormMapImages.asBuiltImage = getMediaUrl(asBuiltImage.value.mediaId);
                        }

                        if(lineWithLineComponents){
                            handleAnchorsAndBolts(lineWithLineComponents);
                        }
                    } else {
                        $scope.mooringReport.serviceFormGeneral = null;
                        $scope.mooringReport.serviceFormChanges = null;
                        $scope.mooringReport.serviceFormMapImages = null;
                    }
                })
            } else {
                $scope.mooringReport.serviceFormGeneral = null;
                $scope.mooringReport.serviceFormChanges = null;
                $scope.mooringReport.serviceFormMapImages = null;
            }

        }

        function initializeCaptainMessagesCasesAndDiversObject(){
            return {
                captainMessages: [],
                cases: [],
                divers: []
            }
        }

        function handleCaptainMessageAndCaseAndDiverPage(skipperMessage, deviations){
            $scope.mooringReport.captainMessagesCasesAndDivers = [];
            var captainMessagesCasesAndDivers = initializeCaptainMessagesCasesAndDiversObject();
            $scope.maxRowCount = 28;
            $scope.rowCount = 0;

            if(skipperMessage){
                $scope.maxRowCount = 28;
                var captainMessages = skipperMessage;
                captainMessagesCasesAndDivers.captainMessages = [{comment: captainMessages}];
                $scope.rowCount = 4;
            }

            if(deviations && deviations.length){
                var cases = deviations
                    .filter(c => (c.workflowStatuses.length && c.workflowStatuses[0].workflowStatusId === '11111111-1111-1111-1111-111111111111') &&
                        (c.description.toLowerCase().includes('kundeavvik') || (c.logAction && c.logAction.isCustomerDeviation)))
                    .map((deviation) => {
                        var componentNumber = getComponentIdFromCase(deviation);
                        return {
                            ...deviation,
                            componentNumber: componentNumber,
                        };
                    });

                while(cases.length){
                    if(cases.length + $scope.rowCount > $scope.maxRowCount){
                        var caseList = JSON.parse(JSON.stringify(cases))
                        caseList = caseList.splice(0, $scope.maxRowCount - $scope.rowCount);
                        captainMessagesCasesAndDivers.cases = caseList;
                        cases = cases.splice($scope.maxRowCount - $scope.rowCount, cases.length);
                        $scope.mooringReport.captainMessagesCasesAndDivers.push(captainMessagesCasesAndDivers);
                        captainMessagesCasesAndDivers = initializeCaptainMessagesCasesAndDiversObject();
                        $scope.rowCount = 1 + cases.length;
                    } else {
                        captainMessagesCasesAndDivers.cases = cases;
                        $scope.rowCount = $scope.rowCount + cases.length;
                        cases = [];
                    }
                }
                $scope.mooringReport.captainMessagesCasesAndDivers.push(captainMessagesCasesAndDivers);
            }
        }

        function getComponentIdFromCase(deviation) {
            var componentNumber = '';
            if (deviation.rings && deviation.rings.length) {
                componentNumber = deviation.rings[0].serialNumber;
            } else if (deviation.nets && deviation.nets.length) {
                componentNumber = deviation.nets[0].netId;
            } else if (deviation.cages && deviation.cages.length) {
                componentNumber = deviation.cages[0].cageNumber;
            } else if (deviation.lines && deviation.lines.length) {
                componentNumber = deviation.lines[0].description;
            }
            return componentNumber;
        }

        function getMediaUrl(mediaId) {
            return mediaService.createMediaUrlMediaBank(mediaId);
        }

        function setOrderDisplayName() {
            $scope.mooringReport.order.orderDisplayText =
                ($scope.mooringReport.order.customer && $scope.mooringReport.order.customer.name
                    ? $scope.mooringReport.order.customer.name
                    : '') +
                ($scope.mooringReport.order.orderProcess && $scope.mooringReport.order.orderProcess.name
                    ? ' - ' + $scope.mooringReport.order.orderProcess.name
                    : '');
        }

        function handleAnchorsAndBolts(lines){
            var anchorOrBoltCoordinateLists = [];
            var anchorOrBoltCoordinates = [];

            var anchorOrBoltHoldingForceLists = [];
            var anchorOrBoltHoldingForces = [];

            var hasBuoyList = $scope.mooringReport.serviceFormGeneral.buoyCoordinateLists && $scope.mooringReport.serviceFormGeneral.buoyCoordinateLists.length;

            lines.forEach(line => {
                var anchorOrBolt = line.locationLogs.find(x => x.resourceTypeName === 'Anker' || x.resourceTypeName === 'Bolt');
                if(anchorOrBolt){
                    var latFormatted = null;
                    var longFormatted = null;

                    if(anchorOrBolt.x && anchorOrBolt.y){
                        var lat = mapUtility.convertToDDM(anchorOrBolt.y);
                        var long = mapUtility.convertToDDM(anchorOrBolt.x);

                        latFormatted = `${lat.degrees}°${lat.minutes}.${lat.seconds}`
                        longFormatted = `${long.degrees}°${long.minutes}.${long.seconds}`
                    }
                    var coordinate = {
                        lineDescription: line.line.description,
                        lat: latFormatted,
                        long: longFormatted
                    }

                    anchorOrBoltCoordinates.push(coordinate);
                    if(anchorOrBoltCoordinates.length === 24 || (hasBuoyList && anchorOrBoltCoordinates.length === 20)){
                        anchorOrBoltCoordinateLists.push({
                            hasBuoyList: hasBuoyList,
                            list: _.orderBy(anchorOrBoltCoordinates, 'lineDescription')
                        });
                        anchorOrBoltCoordinates = [];
                        hasBuoyList = false;
                    }

                    var holdingForce = {
                        lineDescription: line.line.description,
                        appliedLoad: round(anchorOrBolt.holdingForce_Original / anchorOrBolt.holdingForce_Tested, 1),
                        holdingForceOriginal: anchorOrBolt.holdingForce_Original,
                        holdingForceTested: anchorOrBolt.holdingForce_Tested,
                        holdingForceTime: anchorOrBolt.holdingForce_Time
                    }

                    anchorOrBoltHoldingForces.push(holdingForce);
                    if(anchorOrBoltHoldingForces.length === 25){
                        anchorOrBoltHoldingForceLists.push(_.orderBy(anchorOrBoltHoldingForces, 'lineDescription'));
                        anchorOrBoltHoldingForces = [];
                    }
                }
            });
            anchorOrBoltCoordinateLists.push({
                hasBuoyList: hasBuoyList,
                list: _.orderBy(anchorOrBoltCoordinates, 'lineDescription')
            });
            $scope.mooringReport.anchorOrBoltCoordinateLists = anchorOrBoltCoordinateLists;

            anchorOrBoltHoldingForceLists.push(_.orderBy(anchorOrBoltHoldingForces, 'lineDescription'));
            $scope.mooringReport.anchorOrBoltHoldingForceLists = anchorOrBoltHoldingForceLists;
        }

        function round(value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        }

        function getComponentTables(lineComponents){
            lineComponents = _.orderBy(lineComponents, 'line.description');

            var groups = _.groupBy(lineComponents, (x) => x.lineId)

            var lineComponentGroups = [];

            _.forOwn(groups, (value, key) => {
                if (Array.isArray(value)) {
                    value = _.orderBy(value, 'lineSequenceNumber');

                    var lineType = value[0] && value[0].line ? value[0].line.lineType : 0;
                    var lineDescription = value[0] && value[0].line ? value[0].line.description : '';

                    value.forEach(lineComponent => {
                        lineComponent.measurementType = lineComponent.measurementUnitType &&
                            lineComponent.measurementUnitType.measurementUnitDescription
                            ? lineComponent.measurementUnitType.measurementUnitDescription
                            : '',
                        lineComponent.insertedTimestamp = moment(new Date(lineComponent.insertedTimestamp)).format('DD.MM.YYYY HH:mm');
                        lineComponent.unitDescription = lineComponent.unit && lineComponent.unit.description ? lineComponent.unit.description : '';
                        lineComponent.unitBatchDescription = lineComponent.unitBatch && lineComponent.unitBatch.batch ? lineComponent.unitBatch.batch : '';
                        lineComponent.lineType = lineType;

                        if(!lineType && lineComponent.line){
                            lineType = lineComponent.line.lineType;
                        }
                    });
                    lineComponentGroups.push({
                        lineId: key,
                        lineComponents: value,
                        lineType: lineType,
                        lineDescription: lineDescription
                    });
                }
            });

            var groupsByType = _.groupBy(lineComponentGroups, (x) => x.lineType);

            $scope.mooringReport.componentTables = [];

            var maxLengthOfBottomLines = groupsByType[0] && groupsByType[0].map(x => x.lineComponents) ? Math.max(...groupsByType[0].map(x => x.lineComponents).map(x => x.length)) : 0;
            var maxLengthOfFrameLines = groupsByType[1] && groupsByType[1].map(x => x.lineComponents) ? Math.max(...groupsByType[1].map(x => x.lineComponents).map(x => x.length)) : 0;
            var maxLengthOfHenfeetLines = groupsByType[2] && groupsByType[2].map(x => x.lineComponents) ? Math.max(...groupsByType[2].map(x => x.lineComponents).map(x => x.length)) : 0;
            var maxLengthOfBuoyLines = groupsByType[3] && groupsByType[3].map(x => x.lineComponents) ? Math.max(...groupsByType[3].map(x => x.lineComponents).map(x => x.length)) : 0;

            $scope.currentRowCount = 1; //starts from one cause at the first component page there is the main title which takes approximately 1 row


            $scope.bottomLines = setUpComponentPages(maxLengthOfBottomLines, groupsByType[0], $scope.bottomLines, true, false, false, false, 0);
            $scope.frameLines = setUpComponentPages(maxLengthOfFrameLines, groupsByType[1], $scope.frameLines, false, true, false, false, 1);
            $scope.henfeetLines = setUpComponentPages(maxLengthOfHenfeetLines, groupsByType[2], $scope.henfeetLines, false, false, true, false, 2);
            $scope.buoyLines = setUpComponentPages(maxLengthOfBuoyLines, groupsByType[3], $scope.buoyLines, false, false, false, true, 3);

            addComponentTablesPage();
        }

        function setUpComponentPages(maxLengthOfLines, lineList, linesForThePage, displayBottomLinesText, displayFrameLinesText, displayHenfeetLinesText, displayBuoyLinesText, mode){
            var maxRowsPerPage = 11;
            var maxColumnsParTable = 10;

            for (let index = 0; index < Math.ceil(maxLengthOfLines/maxColumnsParTable); index++) {
                $scope.currentRowCount = $scope.currentRowCount + 1; //increase because of the header of the table
                if(index === 0){
                    if(displayBottomLinesText){
                        $scope.bottomLinesPage = $scope.componentTablePages;
                        $scope.displayBottomLinesText = true;
                    }
                    if(displayFrameLinesText){
                        $scope.frameLinesPage = $scope.componentTablePages;
                        $scope.displayFrameLinesText = true;
                    }
                    if(displayHenfeetLinesText){
                        $scope.henfeetLinesPage = $scope.componentTablePages;
                        $scope.displayHenfeetLinesText = true;
                    }
                    if(displayBuoyLinesText){
                        $scope.buoyLinesPage = $scope.componentTablePages;
                        $scope.displayBuoyLinesText = true;
                    }
                }
                var lines = JSON.parse(JSON.stringify(lineList))
                lines.forEach(line => {
                    line.lineComponents = line.lineComponents.splice(index * maxColumnsParTable, maxColumnsParTable);
                });

                var columns = [];
                for (let columnIndex = (index * maxColumnsParTable) + 1; columnIndex < (index * maxColumnsParTable) + maxColumnsParTable + 1; columnIndex++) {
                    columns.push(columnIndex);
                }

                if($scope.currentRowCount + lines.length + 1 >= maxRowsPerPage){ //+1 because in the next iteration it would get the +1 because of the header
                    while($scope.currentRowCount + lines.length + 1 >= maxRowsPerPage){
                        var linesToSave = JSON.parse(JSON.stringify(lines))
                        if($scope.currentRowCount + lines.length + 1 === maxRowsPerPage){
                            lines = [];
                            $scope.currentRowCount = 0;
                        } else{
                            linesToSave = linesToSave.splice(0,  maxRowsPerPage - $scope.currentRowCount);
                            lines = lines.splice(maxRowsPerPage - $scope.currentRowCount, lines.length);
                            $scope.currentRowCount = 1;
                        }

                        linesForThePage.push({
                            lines: linesToSave,
                            columns: columns
                        });

                        if(mode === 0){
                            $scope.bottomLines = linesForThePage;
                        } else if(mode === 1){
                            $scope.frameLines = linesForThePage;
                        } else if(mode === 2){
                            $scope.henfeetLines = linesForThePage;
                        } else if(mode === 3){
                            $scope.buoyLines = linesForThePage;
                        }

                        addComponentTablesPage();
                        linesForThePage = [];
                    }
                }
                $scope.currentRowCount = $scope.currentRowCount + lines.length;

                if(lines.length){
                    linesForThePage.push({
                        lines: lines,
                        columns: columns
                    });
                }
            }

            return linesForThePage;
        }

        function addComponentTablesPage(){
            $scope.mooringReport.componentTables.push({
                displayBottomLinesText: $scope.displayBottomLinesText,
                displayFrameLinesText: $scope.displayFrameLinesText,
                displayHenfeetLinesText: $scope.displayHenfeetLinesText,
                displayBuoyLinesText: $scope.displayBuoyLinesText,
                bottomLines: $scope.bottomLines,
                frameLines: $scope.frameLines,
                henfeetLines: $scope.henfeetLines,
                buoyLines: $scope.buoyLines
            });

            $scope.bottomLines = [];
            $scope.frameLines = [];
            $scope.henfeetLines = [];
            $scope.buoyLines = [];

            $scope.displayBottomLinesText = false;
            $scope.displayFrameLinesText = false;
            $scope.displayHenfeetLinesText = false;
            $scope.displayBuoyLinesText = false;

            $scope.componentTablePages = $scope.componentTablePages + 1;
        }

        $scope.$on('$stateChangeStart', function (event, next) {
            $('html').removeClass('auto-overflow');
        });

        function printReport() {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#mooring-report-container').addClass('print-view-container');
            document.title =
                'Mooring-report-' + $scope.mooringReport.order && $scope.mooringReport.order.orderDisplayText
                    ? $scope.mooringReport.order.orderDisplayText
                    : '' + kendo.toString(new Date(), 'HH:mm dd.MM.yyyy');

            window.print();

            setTimeout(function () {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'mooringReportIndicator',
                    destination: '#main-view',
                    overlay: true,
                });

                $rootScope.showNavbar = true;
                $scope.printView = false;
                document.title = 'Naviaq Webinnsyn';
                $('#main-view').removeClass('print-view');
                $('#mooring-report-container').removeClass('print-view-container');
                $rootScope.$broadcast('hideBusyIndicator', 'mooringReportIndicator');
            }, 1);
        }
    }
})();
