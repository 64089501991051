(function() {
    'use strict';

    angular.module('NaviaqWebApp').controller('RolesAdministrationController', rolesAdministrationController);

    rolesAdministrationController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        '$kWindow',
        'userService',
        'kendoUtility',
        'commonUtility',
        'htmlElementService',
        'contractorService',
        'formTypes',
        'authService'
    ];

    function rolesAdministrationController($q, $scope, $rootScope, $translate, $kWindow, userService, kendoUtility, commonUtility, htmlElementService, contractorService, formTypes, authService) {
        const isSuperUser = authService.getAuthData().isSuperuser;
        const rolesAdministrationGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.roles)
                },
                schema: {
                    model: {
                        id: 'id',
                        fields: {
                            isReadOnly: { type: "boolean", editable: false },
                            isVisibleForConglomerate: { type: "boolean", editable: false }
                        }
                    }
                },
                group: { field: "contractorName" }
            }),
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'key',
                    title: $translate.instant('ADMIN_ROLE_TITLE', null, null, langId)
                },
                {
                    field: 'contractorName',
                    title: $translate.instant('ADMIN_ROLE_CONTRACTOR_NAME', null, null, langId),
                    groupHeaderTemplate: (dataItem) => {
                        if (dataItem.value != '') {
                            return $translate.instant('ADMIN_ROLE_CONTRACTOR_NAME', null, null, langId) + ': ' + dataItem.value
                        } else {
                            return $translate.instant('ADMIN_ROLE_GROUP_SYSTEM_ROLE', null, null, langId);
                        }
                    }
                },
                {
                    field: 'isSystem',
                    title: $translate.instant('ADMIN_ROLE_SYSTEM_ROLE', null, null, langId),
                    template: (dataItem) => {
                        switch (dataItem.isSystem) {
                            case false:
                                return $translate.instant('G_NO', null, null, langId);
                            case true:
                                return $translate.instant('G_YES', null, null, langId);                          
                            default:
                                '';
                        }
                    }
                },
                {
                    field: 'isVisibleForConglomerate',
                    title: $translate.instant('ADMIN_ROLE_VISIBLE_CONGLOMERATE', null, null, langId),
                    template: (dataItem) => {
                        switch (dataItem.isVisibleForConglomerate) {
                            case false:
                                return $translate.instant('G_NO', null, null, langId);
                            case true:
                                return $translate.instant('G_YES', null, null, langId);                          
                            default:
                                '';
                        }
                    }
                },
                {
                    command: [
                        {
                            name: 'editRole',
                            click: e => editRole(e),
                            template: '<a class="k-grid-editRole"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteRole',
                            click: e => deleteRole(e),
                            template: '<a class="k-grid-deleteRole"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 100,
                }
            ],
            height: kendoUtility.calculateRemainingSpace(-40),            
            resizable: true,
            filterable: true,
            dataBound: (e) => {
                var dataItems = e.sender.dataSource.data();
                for (var i = 0; i < dataItems.length; ++i) {
                    if (dataItems[i].isSystem && !isSuperUser) {
                        var tr = $('#roles-administration-grid').find('[data-uid="' + dataItems[i].uid + '"]');
                        $(tr).find('.k-grid-deleteRole').hide();
                    }
                }
            }
        });
        $scope.rolesAdministrationGridOptions = rolesAdministrationGridOptions($translate.use());
        $scope.roles = [];

        $scope.editRole = editRole;
        $scope.addRole = addRole;

        $scope.$on('langChanged', (event, code) => {
            $scope.rolesAdministrationGridOptions = rolesAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.rolesAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#roles-administration-grid')]);
        });

        initController();
        function initController() {
            htmlElementService.checkIfElementExists("filter-group")
                .then(() => {
                    commonUtility.calculateAdminTabMenuWidth();
                });

            updateRolesGrid();
        }

        function extendRoles() {
            $scope.roles.forEach((role) => {
                if (role.contractorId && !role.isSystem) {
                    var contractor = $scope.contractors.find(x => x.contractorId == role.contractorId);
                    role.contractorName = contractor.name;
                } else {
                    role.contractorName = '';
                }
            });
        }

        function addRole(){
            openRoleDetailsModal(null, formTypes.add);
        }

        function editRole(event){
            const role = getRoleFromGrid(event);
            openRoleDetailsModal(role, formTypes.edit);
        }

        function deleteRole(event){
            const role = getRoleFromGrid(event);
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE');
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                   userService.deleteRole(role.id).then(function(){
                        updateRolesGrid();
                   });
                }
            });

        }

        function openRoleDetailsModal(role, formType) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: formType == formTypes.edit ? role.key : $translate.instant('ADMIN_ROLE_ADD_ROLE'),
                    resizable: true,
                    visible: false,
                    width: 700
                },
                templateUrl: 'app/administration/roles/roles-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'rolesDetailsModalController',
                resolve: {
                    currentRoleId: () => formType == formTypes.edit ? role.id : null,
                    formType: () => formType,
                }
            });

            windowInstance.result.then(function (response) {
                if (response) {
                    updateRolesGrid();
                }
            });
        }

        function updateRolesGrid() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'rolesAdministration',
                destination: '#roles-administration-grid'
            });

            $q.all([userService.getRolesByContractorId($rootScope.authData.contractorId),
            contractorService.getContractors()]).then(function (results) {
                $scope.roles = results[0];
                $scope.contractors = results[1];
                extendRoles();
                $scope.rolesAdministrationGridOptions.dataSource.read();
            })
                .catch(error => console.error(error))
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'rolesAdministration'));
        }

        function getRoleFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#roles-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }
    }
})();
