(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('personService', personService);

    personService.$inject = ['$q', '$http', 'serviceUrls'];

    function personService($q, $http, serviceUrls) {
        const personWebApiUrl = `${serviceUrls.webApiBaseUrl}/person`;

        return {
            getPersons: getPersons,
            getContactPersonsByContractorId: getContactPersonsByContractorId,
            getPersonsByContractorId: getPersonsByContractorId,
            addPerson: addPerson,
            updatePerson: updatePerson,
            deletePerson: deletePerson
        };

        function getPersons() {
            var deferred = $q.defer();
            var requestUrl = `${personWebApiUrl}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getContactPersonsByContractorId(contractorId) {
            var deferred = $q.defer();
            var requestUrl = `${personWebApiUrl}/contactPersonsByContractorId?contractorId=${contractorId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getPersonsByContractorId(contractorId) {
            var deferred = $q.defer();
            var requestUrl = `${personWebApiUrl}/byContractorId?contractorId=${contractorId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addPerson(person) {
            const deferred = $q.defer();
            const requestUrl = `${personWebApiUrl}`;
            $http
                .post(requestUrl, person)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updatePerson(person) {
            const deferred = $q.defer();
            const requestUrl = `${personWebApiUrl}`;
            $http
                .put(requestUrl, person)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deletePerson(personId) {
            const deferred = $q.defer();
            const requestUrl = `${personWebApiUrl}?personId=${personId}`;
            $http
                .delete(requestUrl,)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
