(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('DayCounterDetailsModalController', dayCounterDetailsModalController);

    dayCounterDetailsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        '$translate',
        'dayCounter',
        'currentFormType',
        'formTypes',
        'dayCounterService',
        'selectableData'
    ];

    function dayCounterDetailsModalController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        $translate,
        dayCounter,
        currentFormType,
        formTypes,
        dayCounterService,
        selectableData
    ) {
        $scope.dayCounter = {};
        $scope.selectableData = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        initController();

        function initController() {
            if (currentFormType === formTypes.edit && dayCounter) {
                $scope.dayCounter = dayCounter;
            }

            $scope.selectableData.selectableDepartments = {
                dataSource: {
                    data: selectableData.selectableDepartments
                },
                dataTextField: 'name',
                dataValueField: 'departmentId',
                optionLabel: $translate.instant('G_SELECT_VESSEL'),
            };
            $scope.selectableData.selectableLogActions = {
                dataSource: {
                    data: selectableData.selectableLogActions
                },
                dataTextField: 'description',
                dataValueField: 'logActionId',
                optionLabel: $translate.instant('G_SELECT_CHECKLIST'),
            };
            $scope.selectableData.selectableDepartments.selectedValue = getDepartment()
            $scope.selectableData.selectableLogActions.selectedValue = getLogAction()
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'dayCounterDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        $scope.dayCounter.createdBy = $rootScope.authData.userId;
                        $scope.dayCounter.createdByName = $rootScope.authData.displayName;
                        $scope.dayCounter.contractorId = $rootScope.authData.contractorId;
                        $scope.dayCounter.createdOn = new Date();
                        $scope.dayCounter.logActionName = getLogAction().logActionId
                        $scope.dayCounter.departmentName = getDepartment().departmentId
                        dayCounterService
                            .addDayCounter($scope.dayCounter)
                            .then(dayCounter => close($scope.dayCounter))
                            .catch(() => close());
                        break;
                    case formTypes.edit:
                        $scope.dayCounter.logActionName = getLogAction().logActionId
                        $scope.dayCounter.departmentName = getDepartment().departmentId
                        dayCounterService
                            .updateDayCounter($scope.dayCounter)
                            .then(dayCounter => close($scope.dayCounter))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }
        function getLogAction() {
            return $scope.selectableData.selectableLogActions.dataSource.data.find(obj => {
                return obj.logActionId === $scope.dayCounter.logActionId
            });
        }
        function getDepartment() {
            return $scope.selectableData.selectableDepartments.dataSource.data.find(obj => {
                return obj.departmentId === $scope.dayCounter.departmentId
            });
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'dayCounterDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
