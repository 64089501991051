(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('componentService', componentService);

        componentService.$inject = [
        '$q',
        '$http',
        'serviceUrls',
    ];

    function componentService(
        $q,
        $http,
        serviceUrls,
    ) {
        const cageApiBaseUrl = `${serviceUrls.webApiBaseUrl}/cage`;
        const ringApiBaseUrl = `${serviceUrls.webApiBaseUrl}/ring`;
        const netApiBaseUrl = `${serviceUrls.webApiBaseUrl}/net`;
        const lineApiBaseUrl = `${serviceUrls.webApiBaseUrl}/line`;
        const componentApiBaseUrl = `${serviceUrls.webApiBaseUrl}/component`;

        return {
            getCagesByLocationNumberAsDropDown,
            getRingsByLocationNumberAsDropDown,
            getNetsByLocationNumberAsDropDown,
            getLinesByLocationNumberAsDropDown,
            getLocationRelatedComponentList
        };

        function getCagesByLocationNumberAsDropDown(locationNumber, selectedIds = null) {
            const deferred = $q.defer();
            const url = `${cageApiBaseUrl}/dropdown?locationNumber=${locationNumber}`;

            $http.post(url, selectedIds)
                 .then(response => {
                     response && response.data && deferred.resolve(response.data);
                     deferred.reject();
                 })
                 .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getRingsByLocationNumberAsDropDown(locationNumber, selectedIds = null) {
            const deferred = $q.defer();
            const url = `${ringApiBaseUrl}/dropdown?locationNumber=${locationNumber}`

            $http.post(url, selectedIds)
                 .then(response => {
                     response && response.data && deferred.resolve(response.data);
                     deferred.reject();
                 })
                 .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getNetsByLocationNumberAsDropDown(locationNumber, selectedIds = null) {
            const deferred = $q.defer();
            const url = `${netApiBaseUrl}/dropdown?locationNumber=${locationNumber}`;

            $http.post(url, selectedIds)
                 .then(response => {
                     response && response.data && deferred.resolve(response.data);
                     deferred.reject();
                 })
                 .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getLinesByLocationNumberAsDropDown(locationNumber, selectedIds = null) {
            const deferred = $q.defer();
            const url = `${lineApiBaseUrl}/asDropDown?locationNumber=${locationNumber}`;
            $http.post(url, selectedIds)
                 .then(response => {
                     response && response.data && deferred.resolve(response.data);
                     deferred.reject();
                 })
                 .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getLocationRelatedComponentList(locationNumber, selectedIds = null ) {
            const deferred = $q.defer();
            const url = `${componentApiBaseUrl}/locationRelatedComponentList?locationNumber=${locationNumber}`;

            $http.post(url, selectedIds)
                 .then(response => deferred.resolve(response.data), () => deferred.reject());
            return deferred.promise;
        }
    }
})();
