(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LogActionPairsController', logActionPairsController);

    logActionPairsController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'commonUtility',
        'htmlElementService',
        'logActionPairsService',
        'logActionService',
        'balanceTypes',
        'formTypes',
        'sanitizeService'
    ];

    function logActionPairsController(
        $q,
        $rootScope,
        $scope,
        $kWindow,
        $translate,
        kendoUtility,
        commonUtility,
        htmlElementService,
        logActionPairsService,
        logActionService,
        balanceTypes,
        formTypes,
        sanitizeService
    ) {

        $scope.logActions = [];
        $scope.logActionPairs = [];

        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.addLogActionPairs = addLogActionPairs;
        $scope.openExternalCodeModal = openExternalCodeModal

        var logActionPairsDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.logActionPairs),
                update: e => logActionPairsGridUpdate(e)
            },
            schema: {
                model: {
                    id: 'logActionPairsId',
                    fields: {
                        startLogActionDescription: { editable: true },
                        endLogActionDescription: { editable: true },
                        isVisibleInExport: { editable: true, type: "boolean"},
                        multiplier: { editable: true, type: "number", validation: { min: 1, max: 500, required: false } },
                    }
                }
            }
        });

        const logActionPairsGridOptions = langId => ({
            dataSource: logActionPairsDataSource,
            animation: false,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'logActionPairsId',
                    hidden: true
                },
                {
                    field: 'startLogActionId',
                    title: $translate.instant('LOGACTIONPAIRS_DETAILS_START_ACTION', null, null, langId),
                    width: 150,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                            var logActionObject = $scope.logActions.find(obj => {
                                return obj.logActionId === dataItem.startLogActionId
                            });
                            dataItem.startLogActionDescription = logActionObject.description;
                            dataItem.startLogActionId = logActionObject.logActionId;
                            return dataItem.startLogActionDescription;
                        }
                        return dataItem.startLogActionDescription
                    },
                    editor: actionPairDropDownEditor,
                },
                {
                    field: 'endLogActionId',
                    title: $translate.instant('LOGACTIONPAIRS_DETAILS_END_ACTION', null, null, langId),
                    width: 150,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                            var logActionObject = $scope.logActions.find(obj => {
                                return obj.logActionId === dataItem.endLogActionId
                            });
                            dataItem.endLogActionDescription = logActionObject.description;
                            dataItem.endLogActionId = logActionObject.logActionId;
                            return dataItem.endLogActionDescription;
                        }
                        return dataItem.endLogActionDescription
                    },
                    editor: actionPairDropDownEditor,
                },
                {
                    field: 'balanceType',
                    title: $translate.instant('LOGACTIONPAIRS_DETAILS_BALANCE_TYPE', null, null, langId),
                    width: 150,
                    template: function (dataItem) {
                        var balanceTypeObject = balanceTypes.find(obj => {
                            return obj.value === dataItem.balanceType
                        });

                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                            dataItem.balanceType = balanceTypeObject.value;
                            return balanceTypeObject.name;
                        }

                        if(balanceTypeObject){
                            return balanceTypeObject.name
                        } else {
                            return '';
                        }
                    },
                    editor: balanceTypeDropDownEditor,
                },
                {
                    field: 'multiplier',
                    title: $translate.instant('LOGACTIONPAIRS_MULTIPLIER', null, null, langId),
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.multiplier ? dataItem.multiplier + '%' : '';
                    },
                    width: 150,
                },
                {
                    field: 'isVisibleInExport',
                    title: $translate.instant('LOGACTIONPAIRS_DETAILS_INCLUDE_IN_PAYCHECK_EXPORT', null, null, langId),
                    width: 100,
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.isVisibleInExport ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-pairs-export-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    field: 'isActive',
                    title: $translate.instant('LOGACTIONPAIRS_DETAILS_VISIBLE_ACTIVE', null, null, langId),
                    width: 100,
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.isActive ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-pairs-active-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    command: [
                        {
                            name: 'editLogActionPairs',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                editLogActionPairs(data.logActionPairsId);
                            },
                            template: '<a class="k-grid-editLogActionPairs"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteLogActionPairs',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                deleteLogActionPairs(data.logActionPairsId);
                            },
                            template: '<a class="k-grid-deleteLogActionPairs"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 60
                }
            ]
        });
        $scope.logActionPairsGridOptions = logActionPairsGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.logActionsGridOptions = logActionPairsGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.logActionPairsGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#logActionPairsGrid')]);
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionPairsIndicator',
                destination: '#logActionPairsGrid',
                overlay: true
            });

            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });

            toggleChangesButtons(true);
            addCheckboxChangeEventListener();
            loadLogActionPairs();
        }

        function extendLogactionPairs(){
            _.forEach($scope.logActionPairs, function (pair) {
                if(pair.startLogAction){
                    pair.startLogActionDescription = pair.startLogAction.description;
                } else{
                    pair.startLogActionDescription = "";
                }

                if(pair.endLogAction){
                    pair.endLogActionDescription = pair.endLogAction.description;
                } else{
                    pair.endLogActionDescription = "";
                }
            });
        }

        function addCheckboxChangeEventListener() {
            $("#administration-content-container").on("change", ".logaction-pairs-export-checkbox", function (e) {
                var grid = $("#logActionPairsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.isVisibleInExport = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false);
            });

            $("#administration-content-container").on("change", ".logaction-pairs-active-checkbox", function (e) {
                var grid = $("#logActionPairsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.isActive = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false);
            });
        }

        function actionPairDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: 'Action',
                    dataTextField: 'description',
                    dataValueField: 'logActionId',
                    dataSource: {
                        data: $scope.logActions
                    }
                });
        }

        function balanceTypeDropDownEditor(container, options) {
            var hasLogForPair = options.model.hasOperationLogForPair;
            var disabled = hasLogForPair ? 'disabled' : '';
            $(`<input required ${disabled} name="${options.field}"/>`)
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: 'Balance type',
                    dataTextField: 'name',
                    dataValueField: 'value',
                    dataSource: {
                        data: balanceTypes
                    }
                });
        }

        function saveChanges() {
            $scope.logActionPairsGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true);
            $scope.logActionPairsGridOptions.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('#save-logactionPairs-button');
            var cancelChangesButton = $('#cancel-logactionPairs-button');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function logActionPairsGridUpdate(e) {
            var dataItem = e.data;

            var logPairIsUnique = true;
            _.forEach($scope.logActionPairs, function (pair) {
               if(dataItem.startLogActionId == pair.startLogActionId
                && dataItem.endLogActionId == pair.endLogActionId
                && dataItem.logActionPairsId != pair.logActionPairsId){
                    logPairIsUnique = false;
               }
            });

            if(!logPairIsUnique){
                $scope.notUniqueErrorMsg = true;
                e.error();
                return;
            }
            $scope.notUniqueErrorMsg = false;

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionPairsGridAdministrationGrid',
                destination: '#logActionPairsGrid'
            });

            var logActioPairs = {
                logActionPairsId: dataItem.logActionPairsId,
                startLogActionId: dataItem.startLogActionId,
                endLogActionId: dataItem.endLogActionId,
                isVisibleInExport: dataItem.isVisibleInExport,
                isActive: dataItem.isActive,
                multiplier: dataItem.multiplier,
                balanceType: dataItem.balanceType
            };

            logActionPairsService.updateLogActionPairs(logActioPairs)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                    } else {
                        e.error();
                    }
                }, function (error) {
                    e.error(error);
                }).finally(function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'logActionPairsGridAdministrationGrid');
                });
        }

        function addLogActionPairs(){
            openLogActionPairsModal(formTypes.add);
        }

        function editLogActionPairs(logActionPairsId){
            var editedLineObject = $scope.logActionPairs.find(l => l.logActionPairsId == logActionPairsId);
            if(editedLineObject){
                openLogActionPairsModal(formTypes.edit, editedLineObject);
            }
        }

        function deleteLogActionPairs(logActionPairsId){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                        logActionPairsService.deleteLogActionPairs(logActionPairsId).then(function(){
                        loadLogActionPairs();
                    });
                }
            });
        }

        function openLogActionPairsModal(formType, logActionPairs = null) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: formType == formTypes.add ? $translate.instant('LOGACTIONPAIRS_DETAILS_TITLE_ADD')
                        : $translate.instant('LOGACTIONPAIRS_DETAILS_TITLE_EDIT'),
                    resizable: true,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/administration/logActionPairs/logAction-pairs-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LogActionPairsDetailsModalController',
                resolve: {
                    formType: function () {
                        return formType;
                    },
                    logActionPairs: function () {
                        return logActionPairs;
                    },
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    loadLogActionPairs();
                }
            });
        }

        function openExternalCodeModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('EXTERNALCODE_TITLE', null, null),
                    resizable: true,
                    width: 900,
                    height: 500,
                    visible: false
                },
                templateUrl: 'app/administration/logActionPairs/externalCode/external-code-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ExternalCodeController',
                resolve: {}
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    loadLogActionPairs();
                }
            });
        }

        function loadLogActionPairs(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionPairsIndicator',
                destination: '#logActionPairsGrid',
                overlay: true
            });

            var requests = {
                getLogActionPairs: logActionPairsService.getLogActionPairs($rootScope.authData.contractorId),
                getActiveLogActionsAsDropdownByFilter: logActionService.getActiveLogActionsAsDropdownByFilter({contractorId: $rootScope.authData.contractorId})
            };

            $q.all(requests)
                .then(function (results) {
                $scope.logActionPairs = results.getLogActionPairs;
                $scope.logActions = results.getActiveLogActionsAsDropdownByFilter;
                $scope.notUniqueErrorMsg = false;
                extendLogactionPairs();
            }).finally(() => {
                $scope.logActionPairsGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'logActionPairsIndicator');
            });
        }
    }
})();
