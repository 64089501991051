(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('logPairDetailsModal', logPairDetailsModal);

    logPairDetailsModal.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'logPair',
        'logService',
        'formTypes',
        'timeBankService'
    ];

    function logPairDetailsModal(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        logPair,
        logService,
        formTypes,
        timeBankService
    ) {
        $scope.startOperationLog = null;
        $scope.stopOperationLog = null;
        $scope.endDate = null;
        $scope.startDate = null;
        $scope.setVisibleTab = setVisibleTab;
        $scope.visibleTabIndex = 0;
        $scope.formType = formTypes.edit;
        $scope.logType = 'timebank';
        $scope.hasEndLog = false;

        $scope.tabStripOptions = {
            animation: false,
            select: function (e) {
                switch (e.item.id) {
                    case 'start-tab':
                        break;
                    case 'stop-tab':
                        break;
                    default:
                        break;
                }
            }
        };

        $rootScope.$on('dateChanged', (event, date, logPairItem) => {
                if(logPairItem == 'startlog'){
                    $scope.startDate = date;
                } else {
                    $scope.endDate = date;
                }
            });

        $scope.$on('closeLogPairEditor', function (event, result) {
            $windowInstance.close(result);
        });

        $scope.$on('setStatusToRegistered', function (event, result) {

            var toUpdate = {
                logIdPairs: [{
                  startId: logPair.startOperationLogId,
                  stopId: logPair.stopOperationLogId
                }],
                status: 0,
                rejectReason: null,
                stopDate: null
              };

            timeBankService.updateLogPairStatus(toUpdate).then(function(){
                if(result == true){
                    $windowInstance.close(result);
                }
            });
        });

        initController();

        function initController(){
            $scope.$watch('logPairTab', function () {
                if($scope.logPairTab){
                    $scope.logPairTab.select(0);
                }
            });

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'LogPairBusyIndicator',
                destination: '.k-widget.k-window',
                overlay: true,
            });

            $scope.hasEndLog = logPair.stopOperationLogId != '00000000-0000-0000-0000-000000000000' ? true : false;

            var requests = {}
            requests.startOperationLog = logService.getOperationLogById(logPair.startOperationLogId),
            requests.stopOperationLog = $scope.hasEndLog ? logService.getOperationLogById(logPair.stopOperationLogId) : null,

            $q.all(requests).then(function (results) {
                $scope.startOperationLog = results.startOperationLog;
                $scope.stopOperationLog = results.stopOperationLog;
                $scope.startDate = $scope.startOperationLog.doneOn;
                $scope.endDate = $scope.hasEndLog ? $scope.stopOperationLog.doneOn : null;
                $rootScope.$broadcast('hideBusyIndicator', 'LogPairBusyIndicator');
            });
        }

        function setVisibleTab(index) {
            $scope.visibleTabIndex = index;
        }
    }
})();
