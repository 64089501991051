(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('fileUploader', fileUploader);

    fileUploader.$inject = [];

    function fileUploader() {
        return {
            controller: ['$rootScope', '$scope', '$q', 'fileHandlingService', 'commonUtility', 'itemTreeService', '$translate', fileUploader],
            replace: true,
            restrict: 'E',
            templateUrl: 'app/shared/directives/file-uploader-directive.html',
            scope: {
                documents: '=documents',
                uploaderId: '=uploaderId',
                multipleUpload: "=multipleUpload"
            },
        };

        function fileUploader($rootScope, $scope, $q, fileHandlingService, commonUtility, itemTreeService, $translate) {
            $scope.openPicturesModal = openPicturesModal;
            $scope.handleImageUpload = handleImageUpload;
            $scope.deleteItemFile = deleteItemFile;

            $scope.imageUploadBtnId = $scope.uploaderId + 'UploadImageInput';
            $scope.documents = $scope.documents ? $scope.documents : [];

            setTimeout(function () {
                $scope.$on(`${$scope.uploaderId}Broadcast`, function (_, relatedEntityId, contractorId, relatedItemId = null) {
                    saveFiles(relatedEntityId, contractorId, relatedItemId);
                });

                $scope.$on(`${$scope.uploaderId}OpenFileSelector`, function (_, file) {
                    openFileSelectorModal(file);
                });

                //Set multiple file upload
                if ($scope.multipleUpload) {
                    Array.prototype.forEach.call(document.getElementsByClassName('file-upload-selector'),
                        item => item.setAttribute("multiple", true));
                } 
            }, 500);

            initDirective();
            function initDirective() {}

            function openPicturesModal() {
                $(`#${$scope.imageUploadBtnId}`).click();
            }

            function handleImageUpload(e) {
                _.forEach($scope.fileArray, function (file) {
                    if (!fileHandlingService.validationFileExtension(file)) {
                        $scope.fileArray = [];
                    }
                });

                $scope.base64Contents = [];
                var requests = [];
                _.forEach($scope.fileArray, function (file) {
                    requests.push(fileHandlingService.asyncReadGenericFileWithName(file));
                });

                $q.all(requests).then(function (base64Files) {
                    _.forEach(base64Files, function (file) {
                        $scope.documents.push({
                            id: null,
                            fileName: file.fileName,
                            Base64FileName: commonUtility.base64Encode(file.fileName),
                            url: null,
                            base64Content: file.base64Content,
                            createdOn: new Date(),
                            description: null,
                            selectedComponentId: null,
                            displayText: null,
                        });
                    });
                    $rootScope.$broadcast(`${$scope.uploaderId}AddNewFileToSelector`);
                });
            }

            function deleteItemFile(itemFileId, fileName) {
                if(!itemFileId){
                    $scope.documents = _.reject($scope.documents, file => file.fileName === fileName);
                } else {
                    if(confirm($translate.instant('G_FILE_DELETE_CONFIRMATION_MSG'))){
                        itemTreeService.deleteItemFileWebApi(itemFileId)
                        .then(() => {
                            $scope.documents = _.reject($scope.documents, file => file.id === itemFileId);
                        });
                    }
                }
            }

            function saveFiles(relatedEntityId, contractorId, relatedItemId) {
                var unUploadedFiles = $scope.documents.filter(d => d.mediaId == null);
                itemTreeService.uploadFiles(unUploadedFiles, relatedEntityId, contractorId, relatedItemId);
            }
        }
    }
})();
