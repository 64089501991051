(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LoginController', LoginController);

    LoginController.$inject = [
        '$state',
        '$rootScope',
        '$scope',
        '$stateParams',
        '$kWindow',
        'appVersion',
        'environmentConfig',
        'authService',
        'userService',
        'queryStringService',
        'locationService'
    ];

    function LoginController(
        $state,
        $rootScope,
        $scope,
        $stateParams,
        $kWindow,
        appVersion,
        environmentConfig,
        authService,
        userService,
        queryStringService,
        locationService
    ) {
        $scope.authError = true;
        $scope.appVersion = appVersion;
        $scope.username = '';
        $scope.password = '';
        $scope.lockedUserError = false;
        $scope.deactivatedUserError = false;
        $scope.tenantNotSupported = false;
        $scope.hasMsalToken = window.location.hash && window.location.hash.substring(1).split('=').find(x => x === 'code') === 'code'
        $scope.microsoftAuthData = null;

        $scope.redirectForgotPassword = redirectForgotPassword;
        $scope.login = login;
        $scope.microsoftLogin = microsoftLogin;
        $scope.$watch('[username, password]', function () {
            $scope.authError = false;
        });

        const msalConfig = {
            auth: {
                clientId: 'd6f6ca3e-7926-4fda-b42a-f0176d517d86'
            }
        };

        const msalInstance = new msal.PublicClientApplication(msalConfig);

        function microsoftLogin() {
            handleRedirect();
        }

        initController()

        function initController(){
            if($scope.hasMsalToken && !$scope.microsoftAuthData){
                handleRedirect();
            }
        }

        function handleRedirect(){
            msalInstance.handleRedirectPromise()
            .then((response) => {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'login',
                        destination: 'body',
                        message: 'Logger inn...',
                    });
                    $scope.microsoftAuthData = response;
                    authService.loginWithMicrosoft(
                        $scope.microsoftAuthData.account.username,
                        $scope.microsoftAuthData.account.name,
                        $scope.microsoftAuthData.account.tenantId,
                        $scope.microsoftAuthData.account.localAccountId,
                        ).then(
                        function (authData) {
                            if (authData) {
                                //Indicate that the user parameters changed which are used to load map objects etc.
                                $rootScope.initRequired = true;

                                if (queryStringService.getParameterByName('site') && authData.token) {
                                    let url = `${queryStringService.getParameterByName('site').replaceAll('~','').replaceAll('2F', '/')}?token=${authData.token}&userId=${authData.userId}`;

                                    if ($stateParams.redirectUrl) {
                                        url += `&redirectUrl=${$stateParams.redirectUrl}`;
                                    }

                                    window.location.href = url;
                                }else {
                                    if ($stateParams.returnState) {
                                        $state.go($stateParams.returnState, JSON.parse($stateParams.returnParams));
                                    } else {
                                        $state.go('main');
                                    }

                                    if (!environmentConfig.disableGDPRConsent) {
                                        checkUserConsent(authData);
                                    }
                                }
                            } else {
                                $scope.authError = true;
                            }
                        },
                        function (errorResponse) {
                            if (errorResponse) {
                                $scope.authError = false;
                                if (errorResponse.isLockedOut) {
                                    $scope.lockedUserError = true;
                                    $scope.deactivatedUserError = false;
                                    $scope.tenantNotSupported = false;
                                }
                                else if (errorResponse.isDeactived) {
                                    $scope.deactivatedUserError = true;
                                    $scope.lockedUserError = false;
                                    $scope.tenantNotSupported = false;
                                }
                                else if (errorResponse.TenantNotSupported) {
                                    $scope.deactivatedUserError = false;
                                    $scope.lockedUserError = false;
                                    $scope.tenantNotSupported = true;
                                }
                            }
                            else {
                                $scope.authError = true;
                                $scope.lockedUserError = false;
                                $scope.deactivatedUserError = false;
                                $scope.tenantNotSupported = false;
                            }

                            $rootScope.$broadcast('hideBusyIndicator', 'login');
                        }
                    )
                    .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'login'));
                } else {
                    msalInstance.loginRedirect({
                        scopes: ['user.read', 'files.read.all']
                    });
                }
            })
            .catch((error) => {
                $scope.authError = true;
            });
        }

        function login(evt) {
            evt.preventDefault();

            if ($scope.username !== '' && $scope.password !== '') {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'login',
                    destination: 'body',
                    message: 'Logger inn...',
                });

                authService.login($scope.username, $scope.password).then(
                    function (authData) {
                        if (authData) {
                            //Indicate that the user parameters changed which are used to load map objects etc.
                            $rootScope.initRequired = true;

                            if (queryStringService.getParameterByName('site') && authData.token) {
                                let url = `${queryStringService.getParameterByName('site').replaceAll('~','').replaceAll('2F', '/')}?token=${authData.token}&userId=${authData.userId}`;

                                if ($stateParams.redirectUrl) {
                                    url += `&redirectUrl=${$stateParams.redirectUrl}`;
                                }

                                window.location.href = url;
                            }else {
                                if ($stateParams.returnState) {
                                    $state.go($stateParams.returnState, JSON.parse($stateParams.returnParams));
                                } else {
                                    $state.go('main');
                                }

                                if (!environmentConfig.disableGDPRConsent) {
                                    checkUserConsent(authData);
                                }
                            }
                        } else {
                            $scope.authError = true;
                        }
                    },
                    function (errorResponse) {
                        if (errorResponse) {
                            $scope.authError = false;
                            if (errorResponse.isLockedOut) {
                                $scope.lockedUserError = true;
                                $scope.deactivatedUserError = false;
                                $scope.tenantNotSupported = false;
                            }
                            else if (errorResponse.isDeactived) {
                                $scope.deactivatedUserError = true;
                                $scope.lockedUserError = false;
                                $scope.tenantNotSupported = false;
                            }
                            else if (errorResponse.TenantNotSupported) {
                                $scope.deactivatedUserError = false;
                                $scope.lockedUserError = false;
                                $scope.tenantNotSupported = true;
                            }
                        }
                        else {
                            $scope.authError = true;
                            $scope.lockedUserError = false;
                            $scope.deactivatedUserError = false;
                            $scope.tenantNotSupported = false;
                        }

                        $rootScope.$broadcast('hideBusyIndicator', 'login');
                    }
                )
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'login'));
            } else {
                $scope.authError = true;
            }
        }

        function checkUserConsent(authData) {
            userService.userHasConsent(authData.userId).then(function (result) {
                if (!result) {
                    $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            title: 'Personvern samtykke',
                            resizable: true,
                            height: 480,
                            width: 600,
                            visible: false,
                            closable: false,
                            actions: ['Maximize', 'Minimize'],
                        },
                        templateUrl: 'app/privacy/GDPR-consent.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ConsentController',
                    });
                }
            });
        }

        function redirectForgotPassword(){
            $state.go('forgotPassword');
        }
    }
})();
