(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('workOrderService', workOrderService);

    workOrderService.$inject = ['$http', '$q', '$translate', 'serviceUrls'];

    function workOrderService($http, $q, $translate, serviceUrls) {
        var webApiBaseUrl = serviceUrls.webApiBaseUrl;

        var service = {
            getWorkOrderById: getWorkOrderById,
            addWorkOrderLog: addWorkOrderLog,
            getNextWoNumberByContractorId: getNextWoNumberByContractorId,
            getWorkOrderLogsByContractorId: getWorkOrderLogsByContractorId,
            getWorkOrderLogsByFilter: getWorkOrderLogsByFilter,
            getWorkOrderLogsByParentId: getWorkOrderLogsByParentId,
            getWorkOrderTags: getWorkOrderTags,
            getWorkOrderTagsByContractorId: getWorkOrderTagsByContractorId,
            addWorkOrderTag,
            deleteWorkOrderTag,
            updateWorkOrderLog: updateWorkOrderLog,
            updateWorkOrderLogParentId: updateWorkOrderLogParentId,
            updateWorkOrderLogs: updateWorkOrderLogs,
            getAllowedStatuses: getAllowedStatuses,
            getWorkOrderTagsOfWorkOrder: getWorkOrderTagsOfWorkOrder,
            getWorkOrderTagsObjectOfWorkOrder: getWorkOrderTagsObjectOfWorkOrder
        };

        return service;

        function getAllowedStatuses(scope, statuses) {
            if (scope.hasPermission('WorkOrderAdmin')) {
                return _.map(statuses, s => {
                    if (s.enum === 'Deleted') {
                        s.isDisabled = true;
                        return s;
                    } else {
                        s.isDisabled = false;
                        return s;
                    }
                });
            } else {
                return _.map(statuses, s => {
                    if (s.enum === 'Completed') {
                        s.isDisabled = false;
                        return s;
                    } else {
                        s.isDisabled = true;
                        return s;
                    }
                });
            }
        }

        function getWorkOrderTagsOfWorkOrder(wo) {
            var woTags = wo.workOrderTags && wo.workOrderTags.length ? wo.workOrderTags : null;
            var tags = _.filter(_.map(woTags, woTag => (woTag && woTag.workOrderTag && woTag.workOrderTag.tag) ? woTag.workOrderTag.tag : null), tag => tag);
            return _.uniqBy(tags, t => t).join(', ');
        }

        function getWorkOrderTagsObjectOfWorkOrder(wo) {
            var woTags = wo.workOrderTags && wo.workOrderTags.length ? wo.workOrderTags : null;
            var tags = _.filter(_.map(woTags, woTag => (woTag && woTag.workOrderTag && woTag.workOrderTag.tag) ? {tag: woTag.workOrderTag.tag, id: woTag.workOrderTag.workOrderTagId} : null), tag => tag.tag);
            var uniqList = _.uniqBy(tags, t => t.tag);
            return   {
                tag: uniqList.map(t => t.tag).join(','),
                workOrderTagId: uniqList.map(t => t.id).join(',')
            }
        }

        function getWorkOrderById(operationLogId) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + `/workorderlog/byOperationLogId?operationLogId=${operationLogId}`;

            $http.get(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function updateWorkOrderLogs(operationLogs) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + '/workorderlog/batch';

            $http.put(requestUrl, operationLogs)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function updateWorkOrderLog(operationLog) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + '/workorderlog';

            $http.put(requestUrl, operationLog)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function updateWorkOrderLogParentId(operationLogId, parentId) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + `/workorderlog/updateParentId?operationLogId=${operationLogId}&parentId=${parentId}`;

            $http.put(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function addWorkOrderLog(operationLog) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + '/workorderlog';

            $http.post(requestUrl, operationLog)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function updateTags(operationLogId, tags) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/workorderlog/tags?operationLogId=' + operationLogId;

            $http.put(url, tags).then(response =>
                deferred.resolve(response),
                error => deferred.reject(error)
            );

            return deferred.promise;
        }

        function getWorkOrderTags() {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + '/workorderlog/tags';

            $http.get(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getWorkOrderTagsByContractorId(contractorId) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + `/workorderlog/tags/byContractorId?contractorId=${contractorId}`;

            $http.get(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function addWorkOrderTag(workOrderTag) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + `/workorderlog/tag`;

            $http.post(requestUrl, workOrderTag)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }


        function deleteWorkOrderTag(workOrderTagId) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + `/workorderlog/tag?workOrderTagId=${workOrderTagId}`;

            $http.delete(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getWorkOrderLogsByContractorId(contractorId) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + '/workorderlog?contractorId=' + contractorId;

            $http.get(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getWorkOrderLogsByFilter(filter) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + '/workorderlog/filter';

            $http.post(requestUrl, filter)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getNextWoNumberByContractorId(contractorId) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + '/workorderlog/nextwonumber?contractorId=' + contractorId;

            $http.get(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getWorkOrderLogsByParentId(parentId) {
            var deferred = $q.defer(),
                requestUrl = webApiBaseUrl + '/workorderlog/children?parentId=' + parentId;

            $http.get(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }
    }
})();
