(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ContractorsAdministrationController', contractorsAdministrationController);

    contractorsAdministrationController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        '$kWindow',
        'commonUtility',
        'htmlElementService',
        'kendoUtility',
        'adminContractorService',
        'formTypes',
        'authService',
        'sanitizeService'];

    function contractorsAdministrationController(
        $q,
        $scope,
        $rootScope,
        $translate,
        $kWindow,
        commonUtility,
        htmlElementService,
        kendoUtility,
        adminContractorService,
        formTypes,
        authService,
        sanitizeService) {
        $scope.addContractor = addContractor;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.contractors = [];
        $scope.conglomerates = [];
        $scope.locationOwners = [];
        $scope.isSuperUser = authService.getAuthData().isSuperuser;

        const contractorsAdministrationGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.contractors),
                    update: e => contractorGridUpdate(e)
                },
                schema: {
                    model: {
                        id: 'contractorId',
                        fields: {
                            contractorId: { editable: false },
                            name: { editable: true, validation: { required: true } },
                            locationOwnerId: { editable: true },
                            cretBy: { editable: false },
                            cretOn: { editable: false },
                            modfBy: { editable: false },
                            modfOn: { editable: false },
                            isActive: { editable: true, type: "boolean" },
                            reportFolder: { editable: true },
                            replicationUser: { editable: true },
                            conglomerateId: { editable: true },
                            azureStorageName: { editable: true },
                            vesselImageUrl: { editable: true },
                            adTenantId: { editable: true },
                            hasNaviaqLicense: { editable: true, type: "boolean" },
                            defaultWarningDays: { editable: true, validation: { required: true } },
                            preferredLanguageId: { editable: false },
                            defaultDeviationLogActionText: { editable: false },
                            defaultWorkOrderLogActionText: { editable: false },
                        }
                    },
                }
            }),
            columns: [
                { field: 'contractorId', hidden: true },
                { field: 'name', title: $translate.instant('G_NAME', null, null, langId),
                  template: dataItem => {
                        dataItem.dirty && toggleChangesButtons(false);
                        return dataItem.name ? dataItem.name : '';
                  }
                },
                { field: 'locationOwnerId', title: $translate.instant('ADMIN_CONTRACTOR_LOCATION_OWNER', null, null, langId),
                  template: dataItem => {
                      if (dataItem.locationOwnerId !== null) {
                          var locationOwnerObject = null;
                          if (dataItem.dirty) {
                              toggleChangesButtons(false);
                          }
                          locationOwnerObject = $scope.locationOwners.find(obj => obj.locationOwnerId === dataItem.locationOwnerId);

                          if (!locationOwnerObject) {
                              locationOwnerObject = $scope.locationOwners.find(obj => obj.locationOwnerId === dataItem.locationOwnerId.locationOwnerId);
                          }

                          if (dataItem.dirty && dataItem.locationOwnerId) {
                              dataItem.locationOwnerId = locationOwnerObject.locationOwnerId;
                          }

                          if (locationOwnerObject && locationOwnerObject !== null && locationOwnerObject.name !== null) {
                              return locationOwnerObject.customDataTextField;
                          }
                          return '';
                      }
                      return '' ;
                  },
                  editor: locationOwnerDropDownEditor,  width: 250
                },
                { field: 'cretBy', title: $translate.instant('G_CREATED_BY', null, null, langId) },
                { field: 'cretOn', title: $translate.instant('G_CREATED_ON', null, null, langId),
                  template: dataItem => moment(dataItem.cretOn).format('DD.MM.YYYY HH:mm')
                },
                { field: 'modfBy', title: $translate.instant('ADMIN_CONTRACTOR_MODIFIED_BY', null, null, langId)},
                { field: 'modfOn', title: $translate.instant('ADMIN_CONTRACTOR_MODIFIED_ON', null, null, langId),
                  template: dataItem => moment(dataItem.modfOn).format('DD.MM.YYYY HH:mm')
                },
                { field: 'isActive', title: $translate.instant('G_ACTIVE', null, null, langId),
                  template: dataItem => {
                        dataItem.dirty && toggleChangesButtons(false);

                        const checked = dataItem.isActive ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="status-checkbox" ${checked} /></div>`;
                  }, width: 50

                },
                { field: 'reportFolder', title: $translate.instant('ADMIN_CONTRACTOR_REPORT_FOLDER', null, null, langId),
                  template: dataItem => {
                    if (dataItem.dirty) {
                        toggleChangesButtons(false);
                        return dataItem.reportFolder ? dataItem.reportFolder: '';
                    }
                    return dataItem.reportFolder ? dataItem.reportFolder : '';
                  }
                },
                { field: 'conglomerateId', title: $translate.instant('ADMIN_CONTRACTOR_CONGLOMERATE', null, null, langId),
                  template: dataItem => {
                    if (dataItem.conglomerateId !== null) {
                        var conglomerateObject = null;
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        conglomerateObject = $scope.conglomerates.find(obj => obj.conglomerateId === dataItem.conglomerateId);

                        if (!conglomerateObject) {
                            conglomerateObject = $scope.conglomerates.find(obj => obj.conglomerateId === dataItem.conglomerateId.conglomerateId);
                        }

                        if (dataItem.dirty && dataItem.conglomerateId) {
                            dataItem.conglomerateId = conglomerateObject.conglomerateId;
                        }

                        if (conglomerateObject && conglomerateObject !== null && conglomerateObject.name !== null) {
                            return conglomerateObject.name;
                        }
                        return '';
                    }
                    return '' ;
                  },
                  editor: conglomerateDropDownEditor,
                },
                { field: 'azureStorageName', title: $translate.instant('ADMIN_CONTRACTOR_AZURE_STORAGE_NAME', null, null, langId),
                  template: dataItem => {
                      if (dataItem.dirty) {
                          toggleChangesButtons(false);
                          return dataItem.azureStorageName ? dataItem.azureStorageName: '';
                      }
                      return dataItem.azureStorageName ? dataItem.azureStorageName : '';
                  }
                },
                { field: 'vesselImageUrl', title: $translate.instant('ADMIN_CONTRACTOR_DEPARTMENT_IMG_URL', null, null, langId),
                  template: dataItem => {
                      if (dataItem.dirty) {
                          toggleChangesButtons(false);
                          return dataItem.vesselImageUrl ? dataItem.vesselImageUrl: '';
                      }
                      return dataItem.vesselImageUrl ? dataItem.vesselImageUrl : '';
                  }
                },
                { field: 'adTenantId', title: $translate.instant('ADMIN_CONTRACTOR_AD_TENANT', null, null, langId),
                  template: dataItem => {
                      if (dataItem.dirty) {
                          toggleChangesButtons(false);
                          return dataItem.adTenantId ? dataItem.adTenantId: '';
                      }
                      return dataItem.adTenantId ? dataItem.adTenantId : '';
                  }
                },
                {
                    field: 'defaultDeviationLogActionText', title: $translate.instant('ADMIN_CONTRACTOR_DEFAULT_DEVIATION_LOGACTION', null, null, langId)
                },
                {
                    field: 'defaultWorkOrderLogActionText', title: $translate.instant('ADMIN_CONTRACTOR_DEFAULT_WORKORDER_LOGACTION', null, null, langId)
                },
                { field: 'defaultWarningDays', title: $translate.instant('ADMIN_CONTRACTOR_DEFAULT_WARNING_DAYS', null, null, langId),
                  template: dataItem => {
                    const result = dataItem.defaultWarningDays ? dataItem.defaultWarningDays: '';

                      if (dataItem.dirty) {
                        toggleChangesButtons(false);
                      }

                      return result;
                  }
                },
                {
                    field: 'preferredLanguageId', title: $translate.instant('ADMIN_CONTRACTOR_PREFERRED_LANGUAGE', null, null, langId)
                },
                { field: 'hasNaviaqLicense', title: $translate.instant('ADMIN_CONTRACTOR_NAVIAQ_LICENSE', null, null, langId),
                  template: dataItem => {
                        dataItem.dirty && toggleChangesButtons(false);

                        const checked = dataItem.hasNaviaqLicense ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="license-checkbox" ${checked} /></div>`;
                  }, width: 50
                },
                {
                    command: [
                        { name: 'updateContractor', click: e => updateContractor(e), template: '<a class="k-grid-updateContractor"><i class="fa fa-edit grid-icon"></i></a>' },
                        { name: 'deleteContractor', click: e => deleteContractor(e), template:'<a class="k-grid-deleteContractor"><i class="fas fa-trash"></i></a>' },
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.contractorsAdministrationGridOptions = contractorsAdministrationGridOptions($translate.use());

        $scope.$on('langChanged', (_, langId) => {
            $scope.contractorsAdministrationGridOptions = contractorsAdministrationGridOptions(langId);
            const contractorsAdministrationGrid = kendoUtility.createWidget('kendoGrid', $scope.contractorsAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(langId, [() => contractorsAdministrationGrid('#contractors-administration-grid')]);
        });

        initController();

        function initController() {
            if ($scope.isSuperUser) {
                showBusyIndicator('contractorAdministrationGrid');
                toggleChangesButtons(true);
                addCheckboxChangeEventListener();
                htmlElementService.checkIfElementExists("filter-group").then(() => commonUtility.calculateAdminTabMenuWidth());

                const requests = {
                    getAllContractor: adminContractorService.getAllContractor(),
                    getConglomerates: adminContractorService.getConglomerates(),
                    getLocationOwners: adminContractorService.getLocationOwners()
                }

                $q.all(requests)
                .then(data => {
                    $scope.conglomerates = data.getConglomerates.sort((a, b) => a.name.localeCompare(b.name));
                    $scope.locationOwners = data.getLocationOwners.sort((a, b) => a.name.localeCompare(b.name));
                    $scope.contractors = data.getAllContractor.sort((a, b) => a.name.localeCompare(b.name));
                    extendContractors();
                    extendLocationOwners();
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.contractorsAdministrationGridOptions.dataSource.read();
                    hideBusyIndicator('contractorAdministrationGrid');
                });
            }
        }

        function extendLocationOwners() {
            $scope.locationOwners.forEach(lo => lo.customDataTextField = `${lo.locationOwnerId} - ${lo.name}`);
        }

        function extendContractors() {
            $scope.contractors.forEach(x => {
                x.defaultWorkOrderLogActionText = x.defaultWorkOrderLogAction ? x.defaultWorkOrderLogAction.description : null;
                x.defaultDeviationLogActionText = x.defaultDeviationLogAction ? x.defaultDeviationLogAction.description : null;
            });
        }

        function toggleChangesButtons(disabled) {
            const saveChangesButton = $('button.k-button.k-grid-save-changes-contractor');
            const cancelChangesButton = $('button.k-button.k-grid-cancel-changes-contractor');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function addCheckboxChangeEventListener() {
            $("#contractors-administration").on("change", ".status-checkbox", function (e) {
                var grid = $("#contractors-administration-grid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.isActive = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });

            $("#contractors-administration").on("change", ".license-checkbox", function (e) {
                var grid = $("#contractors-administration-grid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.hasNaviaqLicense = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });
        }

        function saveChanges() {
            toggleChangesButtons(true);
            $scope.contractorsAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true);
            $scope.contractorsAdministrationGridOptions.dataSource.read();
        }

        function addContractor() {
            const addModal = openContractorModal(formTypes.add);

            addModal.result.then(response => {
                if (response.result) {
                    showBusyIndicator('addContractor');
                    adminContractorService.addContractor(response.result)
                                          .then(result => result && initController())
                                          .finally(() => hideBusyIndicator('addContractor'));
                }
            })
        }

        function contractorGridUpdate(e) {
            showBusyIndicator('contractorGridUpdate');

            const dataItem = e.data;
            const contractor = {
                contractorId: dataItem.contractorId,
                name: dataItem.name,
                locationOwnerId: dataItem.locationOwnerId,
                cretBy: dataItem.cretBy,
                cretOn: dataItem.cretOn,
                modfOn: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                modfBy: $rootScope.authData.username,
                isActive: dataItem.isActive,
                reportFolder: dataItem.reportFolder,
                conglomerateId: dataItem.conglomerateId,
                azureStorageName: dataItem.azureStorageName,
                vesselImageUrl: dataItem.vesselImageUrl,
                adTenantId: dataItem.adTenantId,
                hasNaviaqLicense: dataItem.hasNaviaqLicense,
                defaultWarningDays: dataItem.defaultWarningDays,
                preferredLanguageId: dataItem.preferredLanguageId
            };

            adminContractorService.updateContractor(contractor)
                .then(response => {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                    } else {
                        e.error();
                    }
                }, error =>e.error(error))
                .finally(() => {
                    hideBusyIndicator('contractorGridUpdate');
                    initController();
                });
        }

        function updateContractor(event) {
            const contractor = getContractorFromGrid(event);
            const updateModal = openContractorModal(formTypes.edit, contractor);

            contractor && updateModal.result.then(response => {
                if (response.result) {
                    showBusyIndicator('updateContractor');
                    adminContractorService.updateContractor(response.result)
                                          .then(result => result && initController())
                                          .finally(() => hideBusyIndicator('updateContractor'));
                }
            });
        }

        function deleteContractor(event){
            const contractor = getContractorFromGrid(event);
            const deleteModal = openDeleteModal();

            contractor && deleteModal.result.then(response => {
                if (response) {
                    showBusyIndicator('deleteContractor');
                    adminContractorService.deleteContractor(contractor.contractorId)
                                          .then(result => result && initController())
                                          .finally(() => hideBusyIndicator('deleteContractor'));
                }
            });
        }

        function openContractorModal(formType, contractor = null) {
            const title = formType === formTypes.add ? 'ADMIN_CONTRACTOR_ADD_CONTRACTOR' : 'ADMIN_CONTRACTOR_EDIT_CONTRACTOR';

            return $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            title: $translate.instant(title),
                            resizable: false,
                            visible: false,
                            width: 550
                        },
                        templateUrl: 'app/shared/popups/contractor-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ContractorModalController',
                        resolve: {
                            formType: () => formType,
                            contractor: () => contractor,
                            locationOwners: () => $scope.locationOwners,
                            conglomerates: () => $scope.conglomerates
                        }
                   });
        }

        function openDeleteModal() {
            return $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            title: $translate.instant('ADMIN_RESOURCE_GRID_DELETE_RESOURCE'),
                            resizable: false,
                            visible: false,
                            width: 300
                        },
                        templateUrl: 'app/shared/popups/confirm-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ConfirmModalController',
                        resolve: {
                            content: function(){
                                return $translate.instant('G_CONFIRM_DELETE', null, null);
                            },
                            primaryBtnText: function () {
                                return null;
                            },
                            secondaryBtnText: function () {
                                return null;
                            }
                        }
                   });
        }

        function conglomerateDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE'),
                    dataTextField: 'name',
                    dataValueField: 'conglomerateId',
                    filter: "contains",
                    dataSource: { data: $scope.conglomerates }
                });
        }

        function locationOwnerDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE'),
                    dataTextField: 'customDataTextField',
                    dataValueField: 'locationOwnerId',
                    filter: "contains",
                    dataSource: { data: $scope.locationOwners }
                });
        }

        function getContractorFromGrid(event) {
            event.preventDefault();

            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');

            return $("#contractors-administration-grid").data("kendoGrid").dataItem(row).toJSON();
        }

        function showBusyIndicator(id) {
            $rootScope.$broadcast('showBusyIndicator', { id: id, destination: '#contractors-administration-grid' });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', id);
        }
    }
})();
