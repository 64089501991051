(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('adminContractorService', adminContractorService);

    adminContractorService.$inject = ['$q', '$http', 'serviceUrls'];

    function adminContractorService($q, $http, serviceUrls) {
        const contractorWebApiUrl = `${serviceUrls.webApiBaseUrl}/adminContractor`;

        return {
            getAllContractor: getAllContractor,
            getConglomerates: getConglomerates,
            getLocationOwners: getLocationOwners,
            addContractor: addContractor,
            updateContractor: updateContractor,
            deleteContractor: deleteContractor
        };

        function getAllContractor() {
            var deferred = $q.defer();
            var requestUrl = `${contractorWebApiUrl}/all`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }        

        function getConglomerates() {
            var deferred = $q.defer();
            var requestUrl = `${contractorWebApiUrl}/conglomerates`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function getLocationOwners() {
            var deferred = $q.defer();
            var requestUrl = `${contractorWebApiUrl}/locationOwners`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function addContractor(contractor) {
            const deferred = $q.defer();
            const requestUrl = `${contractorWebApiUrl}`;
            $http
                .post(requestUrl, contractor)
                .then(response => response && response.data && deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateContractor(contractor) {
            const deferred = $q.defer();
            const requestUrl = `${contractorWebApiUrl}`;
            $http
                .put(requestUrl, contractor)
                .then(response => response && response.data && deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteContractor(id) {
            const deferred = $q.defer();
            const requestUrl = `${contractorWebApiUrl}?id=${id}`;
            $http
                .delete(requestUrl)
                .then(response => response && response.data && deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
