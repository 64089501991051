(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('DocumentTypesAdministrationController', DocumentTypesAdministrationController);

    DocumentTypesAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'documentTypeService',
        'commonUtility',
        'htmlElementService',
        'documentTypeAccess',
        'sanitizeService'
    ];

    function DocumentTypesAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        documentTypeService,
        commonUtility,
        htmlElementService,
        documentTypeAccess,
        sanitizeService
    ) {
        const defaultGroupFilter = [];

        var canChangeState = false;

        window.onbeforeunload = function () {
            if ($('#document-types-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#document-types-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });


        var documentTypesAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.documentTypes),
                update: e => onDocumentTypesGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        description: { editable: true},
                        name: { editable: true}
                    }
                },
            }
        });

        const documentTypesAdministrationGridOptions = langId => ({
            dataSource: documentTypesAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_DOCUMENT_TYPES_GRID_NAME', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.name;
                    }
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_DOCUMENT_TYPES_GRID_DESCRIPTION', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.description;
                    },
                },
                {
                    command: [
                        {
                            name: 'editDocumentType',
                            click: e => editDocumentType(e),
                            template: '<a class="k-grid-editDocumentType"><i class="fas fa-edit"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true
        });
        $scope.documentTypesAdministrationGridOptions = documentTypesAdministrationGridOptions($translate.use());
        $scope.documentTypes = [];
        $scope.addNewDocumentType = addNewDocumentType;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editDocumentType = editDocumentType;

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.documentTypesAdministrationGridOptions = documentTypesAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.documentTypesAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#document-types-administration-grid')]);
            langId = code;
        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'documentTypesAdministrationGrid',
                destination: '#document-types-administration-grid'
            });
            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });
            toggleChangesButtons(true)
            let requests = {};
            requests.documents = documentTypeService.getDocumentTypes($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    $scope.documentTypes = data.documents;
                    extendDocumentType();
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.documentTypesAdministrationGridOptions.dataSource.read();
                });
            $rootScope.$broadcast('hideBusyIndicator', 'documentTypesAdministrationGrid');
        }

        function extendDocumentType()
        {
            _.forEach($scope.documentTypes, function (type) {
                if(type.subCategory){
                    type.subCategoryName = type.subCategory.description;
                }

                var access = documentTypeAccess.find(d => d.id == type.access);
                if(access)
                {
                    type.accessName = access.value;
                }

            });
        }

        function addNewDocumentType() {
            const window = openDocumentTypeDetailsModal(formTypes.add);
            window.result.then(result => {
                if (result) {
                    $scope.documentTypes = $scope.documentTypes.concat([result]);
                    $scope.documentTypesAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function editDocumentType(event){
            const window = openDocumentTypeDetailsModal(formTypes.edit, getDocumentTypeFromGrid(event));
            window.result.then(result => handleUpdate(result));
        }

        function handleUpdate(documentType) {
            if (documentType) {
                $scope.documentTypes = $scope.documentTypes.map(d => (d.id === documentType.id ? documentType : d));
                $scope.documentTypesAdministrationGridOptions.dataSource.read();
            }
        }

        function getDocumentTypeFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#document-types-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function saveChanges() {
            $scope.documentTypesAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.documentTypesAdministrationGridOptions.dataSource.read();
        }

        function openDocumentTypeDetailsModal(formType, documentType) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: documentType ? $translate.instant('ADMIN_DOCUMENT_TYPES_EDIT') : $translate.instant('ADMIN_DOCUMENT_TYPES_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/administration/documentTypes/document-type-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DocumentTypeDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    documentType: () => documentType
                }
            });
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-document-types');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-document-types');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onDocumentTypesGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'documentTypesAdministrationGrid',
                destination: '#document-types-administration-grid'
            });
            documentTypeService.updateDocumentType(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'documentTypesAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'documentTypesAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'documentTypesAdministrationGrid');
                });

        }
    }
})();
