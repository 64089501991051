(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('logActionPairsService', logActionPairsService);

        logActionPairsService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function logActionPairsService(
        $q,
        $http,
        serviceUrls,
    ) {
        var logActionPairsApiUrl = `${serviceUrls.webApiBaseUrl}/logActionPairs`;
        var externalCodeApiUrl = `${serviceUrls.webApiBaseUrl}/logActionPairs/externalCode`;
        return {
            getLogActionPairs : getLogActionPairs,
            addLogActionPairs : addLogActionPairs,
            updateLogActionPairs : updateLogActionPairs,
            deleteLogActionPairs : deleteLogActionPairs,
            getExternalCodes : getExternalCodes,
            addExternalCode : addExternalCode,
            updateExternalCode : updateExternalCode,
            deleteExternalCode : deleteExternalCode
        };

        function getLogActionPairs(contractorId) {
            const deferred = $q.defer();
            var requestUrl = `${logActionPairsApiUrl}?contractorId=${contractorId}`;
            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }


        function updateLogActionPairs(logActioPairs) {
            const deferred = $q.defer();
            var requestUrl = `${logActionPairsApiUrl}`;

            $http
                .put(requestUrl, logActioPairs)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addLogActionPairs(logActioPairs) {
            const deferred = $q.defer();
            var requestUrl = `${logActionPairsApiUrl}`;

            $http
                .post(requestUrl, logActioPairs)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteLogActionPairs(logActioPairsId) {
            const deferred = $q.defer();
            var requestUrl = `${logActionPairsApiUrl}?logActionPairsId=${logActioPairsId}`;
            $http
                .delete(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getExternalCodes(contractorId) {
            const deferred = $q.defer();
            var requestUrl = `${externalCodeApiUrl}?contractorId=${contractorId}`;
            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }


        function addExternalCode(externalCode) {
            const deferred = $q.defer();
            var requestUrl = `${externalCodeApiUrl}`;

            $http
                .post(requestUrl, externalCode)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateExternalCode(externalCode) {
            const deferred = $q.defer();
            var requestUrl = `${externalCodeApiUrl}`;

            $http
                .put(requestUrl, externalCode)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteExternalCode(externalCodeId) {
            const deferred = $q.defer();
            var requestUrl = `${externalCodeApiUrl}?externalCodeId=${externalCodeId}`;
            $http
                .delete(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
