(function () {
    angular.module('NaviaqWebApp').factory('cacheInterceptorService', cacheInterceptorService);

    function cacheInterceptorService() {
        return {
            request: request
        };

        function request(config) {
            config.headers = config.headers || {};

            config.headers['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
            // extra
            config.headers['Cache-Control'] = 'no-cache';
            config.headers['Pragma'] = 'no-cache';

            return config;
        }
    }
})();
