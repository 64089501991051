angular.module('NaviaqWebApp').factory('baseLayerFactory', ['$q', '$rootScope', 'esriLoader', function ($q, $rootScope, esriLoader) {
    'use strict';

    var readyDeferred = null;

    /**
     * Initializes the vessel layer ready function.
     */
    var baseLayerFactory = {
        createLayerOnAdd: true,

        ready: function() {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }

            return readyDeferred.promise;
        }
    };

    esriLoader.require([
        'esri/layers/TileLayer',
        'esri/geometry/ScreenPoint'
    ], function (TileLayer, ScreenPoint) {
        if (readyDeferred === null) {
            readyDeferred = $q.defer();
        }

        baseLayerFactory.createLayer = function() {
            var layer = new TileLayer({
                url: 'https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_WGS84/GeocacheBasis/MapServer'
            });
            layer.name = 'MapLayer';
            layer.zIndex = 1;

            //Define additional functions
            layer.toggleVisibility = function() {
                layer.visible = !layer.visible;
            };

            layer.onRightClick = function(evt, mapView, hitResponse) {
                if (!hitResponse) {
                    $rootScope.$broadcast('showContextMenu', {
                        mapPosition: mapView.position,
                        screenPoint: new ScreenPoint(evt.offsetX, evt.offsetY),
                        type: 'Map'
                    });
                }
            };

            return layer;
        };

        readyDeferred.resolve(baseLayerFactory);
    });

    return baseLayerFactory;
}]);
