(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('listsDetailsModalController', listsDetailsModalController);

    listsDetailsModalController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'list',
        'currentFormType',
        'formTypes',
        'listService',
    ];

    function listsDetailsModalController(
        $rootScope,
        $scope,
        $windowInstance,
        list,
        currentFormType,
        formTypes,
        listService,
    ) {
        $scope.list = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        initController();

        function initController() {
            if (currentFormType === formTypes.edit && list) {
                $scope.list = list;
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'listDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        $scope.list.contractorId = $rootScope.authData.contractorId;
                        listService.addList($scope.list)
                            .then(listId => {
                                $scope.list.id = listId
                                close($scope.list)
                            })
                            .catch(() => close());
                        break;
                    case formTypes.edit:
                        listService.updateList($scope.list)
                            .then(list => close($scope.list))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'listDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
