(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentJobDetailsModalController', EquipmentJobDetailsModalController);

    EquipmentJobDetailsModalController.$inject = [
        '$scope',
        '$windowInstance',
        '$translate',
        'formTypes',
        'logActionTypes',
        'jobDefinitionService',
        'logActionService',
        'userService',
        'dateUtility',
        'selectedEquipment',
        'currentFormType',
        'editedJobDefinition',
        'logActionStatus',
        'kendoUtility'
    ];

    function EquipmentJobDetailsModalController(
        $scope,
        $windowInstance,
        $translate,
        formTypes,
        logActionTypes,
        jobDefinitionService,
        logActionService,
        userService,
        dateUtility,
        selectedEquipment,
        currentFormType,
        editedJobDefinition,
        logActionStatus,
        kendoUtility
    ) {

        $scope.description = '';
        $scope.selectedEquipmentDescription = '';
        $scope.intervalTypes = getIntervalTypes();

        $scope.operationLogLogActionsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readLogActions
                }
            }),
            dataValueField: 'logActionId',
            dataTextField: 'description'
        };

        $scope.warnedUserOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readUsers
                }
            }),
            optionLabel: $translate.instant('EQUIPMENT_JOB_MODAL_SELECTUSER'),
            dataValueField: 'userId',
            dataTextField: 'displayName'
        };

        $scope.jobIntervalTypeOptions = {
            dataSource: {
                data: $scope.intervalTypes
            },
            dataValueField: 'IntervalTypeValue',
            dataTextField: 'IntervalTypeName',
            change: primaryIntervalTypeDropdownSelected,
            select: manualValidation
        };

        $scope.jobSecondaryIntervalTypeOptions = {
            dataSource: {
                data: $scope.intervalTypes
            },
            dataValueField: 'IntervalTypeValue',
            dataTextField: 'IntervalTypeName',
            select: manualValidation
        };

        $scope.validFromDateOptions = {
            min: setValidFromMinDate(),
            change: onDateSelected
        };

        $scope.closeModal = closeModal;
        $scope.save = save;

        $scope.secondaryInterval = secondaryInterval;
        $scope.secondaryIntervalValue = null;
        $scope.secondaryIntervalVisible = false;

        $scope.earlyWarning = null;
        $scope.secondaryEarlyWarning = null;

        $scope.manualValidationSuccess = true;

        $scope.primaryIntervalInvalid = false;
        $scope.secondaryIntervalInvalid = false;

        $scope.primaryIntervalTooLittle = false;
        $scope.secondaryIntervalTooLittle = false;
        $scope.primaryEarlyWarningTooLittle = false;
        $scope.secondaryEarlyWarningTooLittle = false;

        $scope.primaryEarlyWarningMismatch = false;
        $scope.secondaryEarlyWarningMismatch = false;

        $scope.intervalTypeMismatch = false;

        $scope.validFrom = false;
        $scope.validFromWarning = false;
        $scope.onDateSelected = onDateSelected;

        initModal();
        function initModal() {
            $('body').kendoTooltip({
                filter: '#operation-log-logaction_listbox > li.k-item',
                position: 'right',
                content: function(e){
                  var item = $("#operation-log-logaction").data('kendoDropDownList').dataItem($(e.target));
                  return item.description;
                },
            });

            if (selectedEquipment.Description) {
                $scope.selectedEquipmentDescription = selectedEquipment.Description;
            }

            $scope.$watch('[intervalValue,secondaryIntervalValue,earlyWarning,secondaryEarlyWarning]', function () {
                manualValidation();
            });

            if (currentFormType === formTypes.add) {
                $scope.selectedIntervalType = $scope.intervalTypes[0];
                $scope.selectedSecondaryIntervalType = $scope.intervalTypes[0];
            }

            if (currentFormType === formTypes.edit) {
                $scope.description = editedJobDefinition.Description;
                $scope.intervalValue = editedJobDefinition.IntervalValue;
                $scope.secondaryIntervalValue = editedJobDefinition.SecondaryIntervalValue;
                $scope.earlyWarning = editedJobDefinition.EarlyWarning;
                $scope.secondaryEarlyWarning = editedJobDefinition.SecondaryEarlyWarning;
                $scope.resetCounter = editedJobDefinition.ResetCounter;
                $scope.warnUserByEmail = editedJobDefinition.WarnUserByEmail;

                $scope.selectedIntervalType = $scope.intervalTypes[0];
                $scope.selectedIntervalType = $scope.intervalTypes[editedJobDefinition.IntervalType];

                $scope.selectedSecondaryIntervalType = $scope.intervalTypes[0];
                $scope.selectedSecondaryIntervalType = $scope.intervalTypes[editedJobDefinition.SecondaryIntervalType];

                logActionService.getActiveLogActionsAsDropdownByFilter({contractorId: $scope.authData.contractorId}).then(function (logActions) {
                    $scope.selectedLogAction = logActions.find(l => l.logActionId == editedJobDefinition.LogActionId);
                });

                $scope.selectedUser = editedJobDefinition.WarnedUser;
                $scope.validFrom = editedJobDefinition.ValidFrom ? new Date(editedJobDefinition.ValidFrom.substr(6), editedJobDefinition.ValidFrom.substr(3, 2) - 1, editedJobDefinition.ValidFrom.substr(0, 2)) : null;
                $scope.validTo = editedJobDefinition.ValidTo ? new Date(editedJobDefinition.ValidTo.substr(6), editedJobDefinition.ValidTo.substr(3, 2) - 1, editedJobDefinition.ValidTo.substr(0, 2)) : null;

                if ($scope.validFrom !== null && $scope.validFrom < kendo.parseDate(editedJobDefinition.CreatedOn)){
                    $scope.validFrom = editedJobDefinition.CreatedOn;
                }

                if ($scope.secondaryIntervalValue) {
                    $scope.secondaryIntervalVisible = true;

                    if (!$scope.selectedSecondaryIntervalType) {
                        primaryIntervalTypeDropdownSelected();
                    }

                    if (($scope.selectedIntervalType.IntervalTypeValue <= 1 && $scope.selectedSecondaryIntervalType.IntervalTypeValue !== 2)
                        ||
                        ($scope.selectedSecondaryIntervalType.IntervalTypeValue <= 1 && $scope.selectedIntervalType.IntervalTypeValue !== 2)
                    ) {
                        $scope.intervalTypeMismatch = true;
                        $scope.manualValidationSuccess = false;
                    }
                }
            }
        }

        function setValidFromMinDate() {
            if (editedJobDefinition !== null && editedJobDefinition.CreatedOn !== null){
                return editedJobDefinition.CreatedOn
            }
            else {
                return new Date();
            }
        }

        function onDateSelected(e) {
            var datePicker = e.sender;

            var selectedDate = datePicker.value();

            if (selectedDate === null) {
                selectedDate = kendo.parseDate(datePicker.element.val(), datePicker.options.parseFormats);
            }
            var minDate = kendo.parseDate(setValidFromMinDate());

            if(selectedDate < minDate) {
                $scope.validFromWarning = true;
            }
            else {
                $scope.validFromWarning = false;
            }

            $scope.$apply();
        };

        function readLogActions(e) {
            var filter = {
                contractorId: $scope.authData.contractorId,
                LogActionTypes: ['vesselLog', 'operationAndVessel', 'workOrderLog'],
                status: 0
            };

            logActionService.getActiveLogActionsAsDropdownByFilter(filter).then(function (logActions) {
                logActions = logActions;
                e.success(logActions);
            }, function () {
                e.success([]);
            });
        }

        function readUsers(e) {
            userService.getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId]}).then(function (users) {
                e.success(users);
            }, function () {
                e.success([]);
            });
        }

        function save(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {

                if ($scope.validFromWarning){
                    return;
                }

                if (!manualValidation()) {
                    return;
                }

                if (currentFormType === formTypes.add) {
                    jobDefinitionService.addJobDefinition({
                        contractorId: $scope.authData.contractorId,
                        description: $scope.description,
                        logActionId: $scope.operationLogLogActions.value(),
                        intervalValue: $scope.intervalValue,
                        intervalType: $scope.selectedIntervalType.IntervalTypeValue,
                        secondaryIntervalValue: $scope.secondaryIntervalValue,
                        secondaryIntervalType: $scope.selectedSecondaryIntervalType ? $scope.selectedSecondaryIntervalType.IntervalTypeValue : null,
                        earlyWarning: $scope.earlyWarning? $scope.earlyWarning:null,
                        secondaryEarlyWarning: $scope.secondaryEarlyWarning? $scope.secondaryEarlyWarning:null,
                        resetCounter: $scope.resetCounter,
                        warnUserByEmail: $scope.warnUserByEmail,
                        warnedUserId: $scope.selectedUser && $scope.selectedUser.userId !== "" ? $scope.selectedUser.userId : null,
                        validFrom: $scope.validFrom ? dateUtility.toJson($scope.validFrom) : undefined,
                        validTo: $scope.validTo ? dateUtility.toJson($scope.validTo) : undefined,
                        itemId: selectedEquipment.Id,
                        createdById: $scope.authData.userId,
                        lastChangedById: $scope.authData.userId
                    }).then(function () {
                        $windowInstance.close();
                    });
                } else if (currentFormType === formTypes.edit) {
                    jobDefinitionService.updateJobDefinition({
                        jobDefinitionId: editedJobDefinition.JobDefinitionId,
                        contractorId: $scope.authData.contractorId,
                        description: $scope.description,
                        logActionId: $scope.operationLogLogActions.value(),
                        intervalValue: $scope.intervalValue,
                        intervalType: $scope.selectedIntervalType.IntervalTypeValue,
                        secondaryIntervalValue: $scope.secondaryIntervalValue,
                        secondaryIntervalType: $scope.selectedSecondaryIntervalType ? $scope.selectedSecondaryIntervalType.IntervalTypeValue : null,
                        earlyWarning: $scope.earlyWarning? $scope.earlyWarning:null,
                        secondaryEarlyWarning:  $scope.secondaryEarlyWarning? $scope.secondaryEarlyWarning:null,
                        resetCounter: $scope.resetCounter,
                        warnUserByEmail: $scope.warnUserByEmail,
                        warnedUserId: $scope.selectedUser && $scope.selectedUser.userId !== "" ? $scope.selectedUser.userId : null,
                        validFrom: $scope.validFrom ? dateUtility.toJson($scope.validFrom) : undefined,
                        validTo: $scope.validTo ? dateUtility.toJson($scope.validTo) : undefined,
                        itemId: selectedEquipment.Id,
                        createdById: $scope.authData.userId,
                        lastChangedById: $scope.authData.userId
                    }).then(function () {
                        $windowInstance.close();
                    });
                }
            }
        }

        function closeModal() {
            $windowInstance.close();
        }



        function getIntervalTypes() {
            return [
                { IntervalTypeValue: 0, IntervalTypeName: 'måneder' },
                { IntervalTypeValue: 1, IntervalTypeName: 'dager' },
                { IntervalTypeValue: 2, IntervalTypeName: 'timer' }
            ];
        }

        function secondaryInterval() {
            if ($scope.secondaryIntervalVisible === false) {
                // inactive - activate it

                primaryIntervalTypeDropdownSelected();

                $scope.secondaryIntervalVisible = true;

            } else if ($scope.secondaryIntervalVisible) {
                // active - remove it

                $scope.secondaryIntervalVisible = false;

                // clear values
                $scope.secondaryIntervalValue = null;
                $scope.secondaryEarlyWarning = null;

                $("#job-interval-type").data("kendoDropDownList").setDataSource($scope.intervalTypes);
            }

            manualValidation();
        }

        function manualValidation() {

            // read out input values
            var primaryInterval = parseInt($scope.intervalValue);
            var secondaryInterval = parseInt($scope.secondaryIntervalValue);
            var primaryWarning = parseInt($scope.earlyWarning);
            var secondaryWarning = parseInt($scope.secondaryEarlyWarning);

            // check if primary interval is valid
            $scope.primaryIntervalInvalid = $scope.intervalValue ? false : true;

            // check for other errors
            $scope.primaryIntervalTooLittle = primaryInterval <= 0;
            $scope.primaryEarlyWarningTooLittle = primaryWarning <= 0;
            $scope.primaryEarlyWarningMismatch = primaryInterval <= primaryWarning;

            // if secondary value is visible
            if ($scope.secondaryIntervalVisible) {

                // check if secondary interval  is valid
                $scope.secondaryIntervalInvalid = $scope.secondaryIntervalValue ? false : true;

                // check for other errors
                $scope.secondaryIntervalTooLittle = $scope.secondaryIntervalVisible && secondaryInterval <= 0;
                $scope.secondaryEarlyWarningTooLittle = $scope.secondaryIntervalVisible && secondaryWarning <= 0;
                $scope.secondaryEarlyWarningMismatch = $scope.secondaryIntervalVisible && secondaryInterval <= secondaryWarning;

                // if type is set AND types are intersecting
                if ($scope.selectedIntervalType &&
                    (
                        ($scope.selectedIntervalType.IntervalTypeValue <= 1 && $scope.selectedSecondaryIntervalType.IntervalTypeValue !== 2)
                        || ($scope.selectedSecondaryIntervalType.IntervalTypeValue <= 1 && $scope.selectedIntervalType.IntervalTypeValue !== 2)
                    )
                ) {
                    $scope.intervalTypeMismatch = true;
                }
                else {
                    $scope.intervalTypeMismatch = false;
                }
            } else {
                // otherwise clear out validations
                $scope.secondaryIntervalInvalid = false;
                $scope.secondaryIntervalTooLittle = false;
                $scope.secondaryEarlyWarningTooLittle = false;
                $scope.secondaryEarlyWarningMismatch = false;
                $scope.intervalTypeMismatch = false;
            }

            $scope.manualValidationSuccess = !$scope.primaryIntervalInvalid &&
                !$scope.secondaryIntervalInvalid &&
                !$scope.primaryIntervalTooLittle &&
                !$scope.secondaryIntervalTooLittle &&
                !$scope.primaryEarlyWarningTooLittle &&
                !$scope.secondaryEarlyWarningTooLittle &&
                !$scope.primaryEarlyWarningMismatch &&
                !$scope.secondaryEarlyWarningMismatch &&
                !$scope.intervalTypeMismatch;

            return $scope.manualValidationSuccess;
        }

        function primaryIntervalTypeDropdownSelected() {
            if ($scope.selectedIntervalType.IntervalTypeValue === 0 || $scope.selectedIntervalType.IntervalTypeValue === 1) {
                $("#secondary-job-interval-type").data("kendoDropDownList").setDataSource([
                    { IntervalTypeValue: 2, IntervalTypeName: 'timer' }
                ]);
                $scope.selectedSecondaryIntervalType = { IntervalTypeValue: 2, IntervalTypeName: 'timer' };
            }
            else if ($scope.selectedIntervalType.IntervalTypeValue === 2) {
                $("#secondary-job-interval-type").data("kendoDropDownList").setDataSource([
                    { IntervalTypeValue: 0, IntervalTypeName: 'måneder' },
                    { IntervalTypeValue: 1, IntervalTypeName: 'dager' }
                ]);
                $scope.selectedSecondaryIntervalType = { IntervalTypeValue: 0, IntervalTypeName: 'måneder' };
            }
        }

    }
})();
