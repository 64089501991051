(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CaseController', caseController);

    caseController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        '$kWindow',
        'caseService',
        'dateUtility',
        'kendoUtility',
        'caseDetailsModalService',
        'workflowStatuses',
        'logService',
        'workOrderService',
        'departmentService',
        'userService',
        'noUser',
        '$stateParams',
        '$state',
        'workOrderDetailsModalService',
        'htmlElementService',
        'userConfigurationService',
        'severityList',
        'excelExportService'
    ];

    function caseController(
        $q,
        $scope,
        $rootScope,
        $translate,
        $kWindow,
        caseService,
        dateUtility,
        kendoUtility,
        caseDetailsModalService,
        workflowStatuses,
        logService,
        workOrderService,
        departmentService,
        userService,
        noUser,
        $stateParams,
        $state,
        workOrderDetailsModalService,
        htmlElementService,
        userConfigurationService,
        severityList,
        excelExportService
    ) {
        var deviations = [];
        var closedDeviations = [];
        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
        var workOrders = [];

        $scope.deviations = [];
        $scope.sumByStatuses = [];
        $scope.assignedUserIdsFilter = [];

        $scope.selectAllDepartments = selectAllDepartments;
        $scope.unSelectAllDepartments = unSelectAllDepartments;
        $scope.selectAllUsers = selectAllUsers;
        $scope.unSelectAllUsers = unSelectAllUsers;
        $scope.departmentSearchType = 0;
        $scope.userSearchType = 0;
        $scope.caseNo = $stateParams.caseNo;
        $scope.ttipOptions = getTooltipOptions();

        function getTooltipOptions() {
            var tooltipOptions = {
                filter: 'td .case-related-work-order-numbers-container, td span.tooltip-content',
                autoHide: false,
                content: function (e) {
                    var target = e.target;
                    var className = target.length ? target[0].className : null;
                    if(target && className == 'case-related-work-order-numbers-container'){
                        var woNumbers = $(target).text() ? $(target).text().split(' ') : null;
                        var template = "";
                        var relatedWorkOrder = workOrders.find(x => x.woNumber === woNumbers[0]);
                        var relatedWorkOrders = relatedWorkOrder ? workOrders.filter(x => x.parentId === relatedWorkOrder.parentId) : [];


                        relatedWorkOrders.forEach(workOrder => {
                            if(workOrder){
                                template = template + `<div class="case-related-work-order-numbers" onclick="clickWorkOrderNumberOnDeviationTable('${workOrder.operationLogId}')">${workOrder.woNumber}</div></br>`
                            }
                        });

                        return template;
                    } else {
                        return $(target).text() ? $(target).text() : '';
                    }
                },
                show: function (e) {
                    if (e.sender.content[0].textContent.length > 0) {
                        e.sender.popup.element.removeClass('k-tooltip-hidden');
                    }
                    e.sender.popup.element.addClass('k-tooltip-nowrap');
                },
                hide: function (e) {
                    if(!$(e.sender.popup.element).hasClass("k-tooltip")){
                        e.sender.popup.element.addClass('k-tooltip-hidden');
                    }
                }
            };
            return tooltipOptions;
        }

        $scope.$watch('departmentSearchType', function() {
            departmentTypeChanges();
        });


        $scope.$watch('userSearchType', function() {
            userTypeChanges();
        });

        const caseGridColumns = langId => {
            let columns = [
                {
                    field: 'operationLogId',
                    hidden: true
                },
                {
                    title: '&nbsp;',
                    width: 40,
                    command: {
                        text: ' ',
                        name: 'edit',
                        click: function (e) {
                            e.preventDefault();
                            editCase(e);
                        },
                        template: function () {
                            return '<a class="fa fa-1-5x fa-pencil-alt grid-icon k-grid-edit"></a>';
                        }
                    }
                },
                {
                    title: '&nbsp;',
                    width: 40,
                    command: {
                        text: ' ',
                        name: 'history',
                        click: function (e) {
                            e.preventDefault();
                            openHistory(e);
                        },
                        template: function () {
                            return '<a class="fa fa-1-5x fa-clipboard-list grid-icon k-grid-history"></a>';
                        }
                    }
                },
                {
                    title: $translate.instant('CASE_GRID_CASEID', null, null, langId),
                    field: 'caseId',
                    width: 120,
                    template: function (dataItem) {
                        if (dataItem.caseId) {
                            return '<div class="pull-right" style="padding-right:4px">' + dataItem.caseId + '</div>';
                        }
                        return '';
                    }
                },
                {
                    title: $translate.instant('CASE_GRID_CASETITLE', null, null, langId),
                    field: 'title'
                },
                {
                    title: $translate.instant('G_VESSEL', null, null, langId),
                    field: 'departmentName',
                    filterable: false,
                    width: 100
                },
                {
                    title: $translate.instant('CASE_GRID_LOGACTION_DESCRIPTION', null, null, langId),
                    field: 'logActionDescription',
                    width: 80,
                    template: (dataItem) => {
                        return `<span class="tooltip-content">${dataItem.logActionDescription}</span>`;
                    },
                    filterable: {
                        multi: true,
                        search: true
                    },
                },
                {
                    title: $translate.instant('CASE_GRID_DESCRIPTION', null, null, langId),
                    field: 'caseTypeDescription',
                    filterable: {
                        multi: true,
                        search: true
                    },
                },
                {
                    title: $translate.instant('CASE_GRID_REGISTERED_BY', null, null, langId),
                    field: 'registeredUserName',
                    width: 120
                },
                {
                    title: $translate.instant('CASE_GRID_ASSIGNED_TO', null, null, langId),
                    field: 'assignedUserNameList',
                    width: 120,
                    filterable: false,
                    template: (dataItem) => {
                        return `<span class="tooltip-content">${dataItem.assignedUserNameList}</span>`;
                    },
                },
                {
                    title: $translate.instant('CASE_GRID_DONE_ON', null, null, langId),
                    field: 'doneOn',
                    filterable: {
                        extra: true
                    },
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.doneOn);
                    },
                    width: 80
                },
                {
                    title: $translate.instant('CASE_GRID_DUE_DATE', null, null, langId),
                    field: 'dueDate',
                    filterable: false,
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.dueDate);
                    },
                    width: 80
                },
                {
                    title: $translate.instant('CASE_GRID_STATUS', null, null, langId),
                    filterable: false,
                    field: 'caseStatus',
                    width: 120
                },
                {
                    title: $translate.instant('CASE_GRID_SEVERITY', null, null, langId),
                    filterable: {
                        multi: true,
                        search: true
                    },
                    field: 'severityText',
                    width: 80
                 },
                 {
                     title: $translate.instant('CASE_MODAL_EQUIPMENT', null, null, langId) + "/" + $translate.instant('CASE_GRID_COMPONENT', null, null, langId),
                     field: 'itemDescription',
                     width: 80
                 },
                 {
                    title: $translate.instant('CASE_GRID_AO', null, null, langId),
                    width: 50,
                    field: 'workOrderNumbers',
                    template: function (dataItem) {
                        if(dataItem.workOrders.length){
                            var template = `<div class="case-related-work-order-numbers" onclick="clickWorkOrderNumberOnDeviationTable('${dataItem.workOrders[0].operationLogId}')">${dataItem.workOrders[0].woNumber} </div>`
                            if(dataItem.workOrders.length > 1){
                                template = template + '..'
                            }
                            return `<div class="case-related-work-order-numbers-container">${template}</div>`;
                        } else {
                            return '';
                        }
                    },
                },
                {
                    title: $translate.instant('CASE_GRID_ATTACHMENT', null, null, langId),
                    width: 75,
                    field: 'hasAttachedMedia',
                    template: function (dataItem) {
                        return dataItem.hasAttachedMedia ? '<span class="grid-attachment-indicator fas fa-check"></span>' : '';
                    }
                }
            ];

            if (userHasDeletePermission()) {
                columns.splice(3, 0, {
                    title: '&nbsp;',
                    width: 40,
                    command: {
                        text: ' ',
                        name: 'delete',
                        click: function (e) {
                            e.preventDefault();
                            deleteCase(e);
                        },
                        template: function () {
                            return '<a class="fa fa-1-5x fas fa-trash-alt grid-icon k-grid-delete"></a>';
                        }
                    }
                });
            }
            return columns;
        };

        var caseGridDataSource = new kendo.data.DataSource({
            transport: {
                read: function (e) {

                    e.success($scope.deviations);
                }
            },
            schema: {
                model: {
                    id: 'operationLogId',
                    fields: {
                        caseId: {editable: false, type: 'number'},
                        doneOn: { editable: false, type: 'date' },
                        dueDate: { editable: false, type: 'date' },
                        createdOn: { editable: false, type: 'date' },
                        hasAttachedMedia: {editable: false, type: 'boolean'},
                        hasAttachedWorkOrders: {editable: false, type: 'boolean'},
                        caseTypeId: {editable: false, type: 'string'},
                        logActionId: {editable: false, type: 'string'}
                    }
                },
                parse: function (d) {
                    $.each(d, function (idx, elem) {
                        elem.doneOn = dateUtility.formatDateTime(elem.doneOn);
                        elem.dueDate = dateUtility.formatDateTime(elem.dueDate);
                        elem.createdOn = dateUtility.formatDateTime(elem.createdOn);
                    });
                    return d;
                }
            }
        });

        const caseGridOptions = langId => ({
            dataSource: caseGridDataSource,
            columns: caseGridColumns(langId),
            pageSize: 100,
            scrollable: {
                virtual: true
            },
            sortable: true,
            selectable: false,
            editable: false,
            resizable: true,
            filterable: {
                name: "FilterMenu",
                extra: false,
                operators:{
                    string:{
                      contains: $translate.instant('G_SEARCH_OPTION_CONTAINS', null, null, langId),
                    }
                },
            },
            filterMenuInit: function(e) {
                if (e.field === "logActionDescription" || e.field === "caseTypeDescription" || e.field === 'severityText') {
                    let filterMultiCheck = this.thead.find("[data-field=" + e.field + "]").data("kendoFilterMenu");
                    filterMultiCheck.container.empty();
                    filterMultiCheck.checkSource.sort({field: e.field, dir: "asc"});
                    filterMultiCheck.checkSource.filter([]);
                    filterMultiCheck.checkSource.data(filterMultiCheck.checkSource.view().toJSON());
                    filterMultiCheck.createCheckBoxes();
                }
            },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NODATA', null, null, langId)
            },
            height: kendoUtility.calculateRemainingSpace(),
            dataBound: function () {
                var data = this.dataSource.view();
                for (var i = 0; i < data.length; i++) {
                    var row = this.tbody.find("tr[data-uid='" + data[i].uid + "']");
                    if (data[i].isExpired) {
                        row.css('color', 'red');
                        this.expandRow(row);
                    }

                    if (!data[i].isExpired && data[i].isClosed) {
                        row.css('color', '#58D537');
                        this.expandRow(row);
                    }
                }

                kendoUtility.adjustGridHeight('#case-grid', $scope.deviations.length);
            }
        });


        //begin filter
        // --date
        $scope.statusFilters = [];
        $scope.exportToPDF = exportToPDF;
        $scope.exportToExcel = exportToExcel;
        $scope.clearDateFilters = clearDateFilters;
        $scope.fromDateObject = null;
        $scope.toDateObject = null;
        // --date
        // --dropdowns
        $scope.departmentSelectorSource = [];
        $scope.selectedDepartments = [];
        $scope.users = [];
        $scope.userSelectorSource = [];
        $scope.selectedUsers = [];

        $scope.departmentSelectorOptions = {
            dataSource: {
                batch: true,
                transport: {
                    read: (e) => e.success($scope.departmentSelectorSource),
                },
            },
            dataValueField: 'id',
            dataTextField: 'label',
            autoClose: false,
            height: kendoUtility.calculateRemainingSpace(-200),
            filter: $scope.departmentSearchType && $scope.departmentSearchType == 0 ? 'contains' : 'startswith',
            tagMode: 'single',
            itemTemplate: "<input type='checkbox'/> #:data.label#",
            headerTemplate: '<div class="filter-option-label">'+ $translate.instant('G_SEARCH_OPTION') +'</div><label class="search-option-item"><input type="radio" ng-model="departmentSearchType" value="0">'+ $translate.instant('G_SEARCH_OPTION_CONTAINS') +'</label><label class="search-option-item"><input type="radio" ng-model="departmentSearchType" value="1">'+ $translate.instant('G_SEARCH_OPTION_START_WITH') +'</label><hr><ul class="dropdown-menu-header"><li><a ng-click="selectAllDepartments()" id="selectAllDepartments" class="ng-binding"><span class="glyphicon glyphicon-ok"></span>  '+ $translate.instant('G_SELECT_ALL') +'</a></li><li><a ng-click="unSelectAllDepartments()" id="unSelectAllDepartments" class="ng-binding"><span class="glyphicon glyphicon-remove"></span>  '+ $translate.instant('G_UNSELECT_ALL') +'</a></li></ul>',
            dataBound: function() {
                var items = this.ul.find("li");
                setTimeout(function() {
                  checkInputs(items);
                });
              },
            open: function(e) {
                selectCheckbox('department');
            },
            change: function() {
              var items = this.ul.find("li");
              checkInputs(items);
              $scope.applyFilter();
            }
        };

        $scope.userSelectorOptions = {
            dataSource: {
                batch: true,
                transport: {
                    read: (e) => e.success($scope.userSelectorSource),
                },
            },
            dataValueField: 'id',
            dataTextField: 'label',
            autoClose: false,
            height: kendoUtility.calculateRemainingSpace(-200),
            filter: $scope.userSearchType && $scope.userSearchType == 0  ? 'contains' : 'startswith',
            tagMode: 'single',
            itemTemplate: "<input type='checkbox'/> #:data.label#",
            headerTemplate: '<div class="filter-option-label">'+ $translate.instant('G_SEARCH_OPTION') +'</div><label class="search-option-item"><input type="radio" ng-model="userSearchType" value="0">'+ $translate.instant('G_SEARCH_OPTION_CONTAINS') +'</label><label class="search-option-item"><input type="radio" ng-model="userSearchType" value="1">'+ $translate.instant('G_SEARCH_OPTION_START_WITH') +'</label><hr><ul class="dropdown-menu-header"><li><a ng-click="selectAllUsers()" id="selectAllUsers" class="ng-binding"><span class="glyphicon glyphicon-ok"></span>  '+ $translate.instant('G_SELECT_ALL') +'</a></li><li><a ng-click="unSelectAllUsers()" id="unSelectAllUsers" class="ng-binding"><span class="glyphicon glyphicon-remove"></span>  '+ $translate.instant('G_UNSELECT_ALL') +'</a></li></ul>',
            dataBound: function() {
                var items = this.ul.find("li");
                setTimeout(function() {
                  checkInputs(items);
                });
              },
            open: function(e) {
                selectCheckbox();
            },
            change: function() {
              var items = this.ul.find("li");
              checkInputs(items);
              $scope.applyFilter();
            }
        };

        $scope.editCase = editCase;
        $scope.openHistory = openHistory;
        $scope.deleteCase = deleteCase;
        $scope.openCaseDetailsModal = openCaseDetailsModal;
        $scope.setStatusFilter = setStatusFilter;

        $scope.isClosedOrdersToggled = false;
        $scope.isCustomerDeviationsToggled = false;
        const system = 'NaviaqWebApp';
        const pageName='Cases';
        $scope.saveUserConfiguration = saveUserConfiguration;
        $scope.userConfiguration = {};

        $scope.caseGridOptions = caseGridOptions(langId);
        initController();

        $scope.$watch('isClosedOrdersToggled', function () {
            if ($scope.isClosedOrdersToggled) {
                var filter = {
                    assignedUserIds: $scope.assignedUserIdsFilter,
                    contractorId: $rootScope.authData.contractorId,
                    workFlowStatusIds: workflowStatuses.filter(function (status) {
                        return status.meaning === 'Closed';
                    }).map(function (status) {
                        return status.value;
                    }),
                    getAttachmentStatus: true,
                    getWorkOrderStatus: false,
                    getWorkOrderList: true,
                    getCustomerDeviation: $scope.isCustomerDeviationsToggled
                }
                getFilteredDeviations(filter);
            } else {
                filterOutClosedDeviations();
            }
        });

        $scope.$watch('isCustomerDeviationsToggled', function () {
            if ($scope.isCustomerDeviationsToggled) {
                var filter = {
                    assignedUserIds: $scope.assignedUserIdsFilter,
                    contractorId: $rootScope.authData.contractorId,
                    workFlowStatusIds:
                    (workflowStatuses.filter(function (status) {
                        return $scope.isClosedOrdersToggled ? status.meaning === 'Closed': status.meaning !== 'Closed';
                    }).map(function (status) {
                        return status.value;
                    })),
                    getAttachmentStatus: true,
                    getWorkOrderStatus: false,
                    getWorkOrderList: true,
                    getCustomerDeviation: $scope.isCustomerDeviationsToggled
                }
                getFilteredDeviations(filter);
            } else {
                filterOutCustomerDeviations();
            }
        });

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'casesGrid',
                destination: '#case-grid'
            });

            htmlElementService.checkIfElementExists("case-grid")
            .then(function (result) {
                $('.k-virtual-scrollable-wrap').scroll(function () {
                    if(!$("k-tooltip").hasClass("k-tooltip-hidden")){
                        $(".k-tooltip").addClass("k-tooltip-hidden");
                    }
                });
            });

            kendoUtility.calculateAndSetMaxWidth($('#cases'));
            $scope.statusFilters = workflowStatuses.map(function (status) {
                return {
                    active: false,
                    value: status.value
                };
            });

            let requests = {};
            if($scope.hasPermission('DeviationLogAdmin') || $scope.hasPermission('DeviationLogViewAll')) {
                $scope.assignedUserIdsFilter = [];
            } else {
                $scope.assignedUserIdsFilter = [$rootScope.authData.userId];
            }

            var filter = {
                contractorIds: [$rootScope.authData.contractorId],
                permissions: ['DeviationLogView']
            };

            requests.getUsersWithPermissionsAsDropdownByFilter = userService.getUsersWithPermissionsAsDropdownByFilter(filter);
            requests.getDepartmentsWebapi = departmentService.getDepartmentsWebapi($rootScope.authData.contractorId, true);
            requests.getCaseType = caseService.getCaseTypesByContractorId($rootScope.authData.contractorId);
            requests.getCasesByFilter = caseService.getCasesByFilter({
                assignedUserIds: $scope.assignedUserIdsFilter,
                contractorId: $rootScope.authData.contractorId,
                workFlowStatusIds: workflowStatuses.filter(function (status) {
                    return status.meaning !== 'Closed';
                }).map(function (status) {
                    return status.value;
                }),
                getAttachmentStatus: true,
                getWorkOrderStatus: false,
                getWorkOrderList: true,
                getCustomerDeviation: $scope.isCustomerDeviationsToggled
            });


            $q.all(requests)
                .then(data => {
                    $scope.caseTypes = data.getCaseType;
                    $scope.departmentSelectorSource = data.getDepartmentsWebapi.map(function (department) {
                        return {
                            id: department.departmentId,
                            label: department.name
                        };
                    });
                    $scope.departmentSelectorSource = _.orderBy($scope.departmentSelectorSource, function (department) {
                        return department.label.toLowerCase();
                      }, 'asc');
                    $scope.departmentSelector.dataSource.read();
                    $scope.users = data.getUsersWithPermissionsAsDropdownByFilter;
                    var usersByContractorId = getFilteredUser(data.getUsersWithPermissionsAsDropdownByFilter)
                        .concat([noUser])
                        .sort(function (a, b) {
                            if (a.displayName < b.displayName) { return -1; }
                            if (a.displayName > b.displayName) { return 1; }
                            return 0;
                        });
                    $scope.userSelectorSource = usersByContractorId.map(function (user) {
                        return {
                            id: user.userId,
                            label: user.displayName
                        };
                    });
                    $scope.userSelector.dataSource.read();

                    deviations = extendDeviations(data.getCasesByFilter);
                    if($scope.caseNo){
                        openDeviationFromUrl(deviations, $scope.caseNo);
                    }
                    getUserConfigurationSettings();
                    $rootScope.$broadcast('hideBusyIndicator', 'casesGrid');
                }, function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'casesGrid');
                });

            $rootScope.$on('casesFetched', function () {
                $scope.caseGridOptions.dataSource.read();
            });

        }

        $scope.$on('saveAndClose', () => {
            //at least now it's only loading when something was edited and save&close was clicked in the modal
            //TODO: try to find a way to load only the edited/added row and add into the list.
            loadCases();
        })

        $scope.$on('langChanged', (event, code) => {
            $scope.caseGridOptions = caseGridOptions(code);
            const casesGrid = kendoUtility.createWidget('kendoGrid', $scope.caseGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => casesGrid('#case-grid')]);
            langId = code;
        });

        function openDeviationFromUrl(deviations, caseNo){
            var deviation = deviations.find(d => d.caseId == caseNo);
            if(deviation){
                caseDetailsModalService.createCase(deviation)
                .then((caseData) => {
                    return caseDetailsModalService.createCaseDetailsResolve(caseData)
                }).then((resolve) => {
                    $rootScope.$broadcast('hideBusyIndicator', 'casesGrid');
                    return caseDetailsModalService.openCaseDetailsModal(resolve);
                })
            } else {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 550
                    },
                    templateUrl: 'app/shared/popups/information-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'InformationModalController',
                    resolve: {
                        content: () => {
                            return $translate.instant('CASE_NOT_FOUND_CASE_FROM_URL_MSG').toString().replace('[caseno]', caseNo);
                        }
                    }
                });
            }
        }

        function editCase(event) {
            var sender = event.currentTarget;
            var row = angular.element(sender).closest("tr");
            var dataItem = $("#case-grid").data("kendoGrid").dataItem(row).toJSON();
            $state.go('cases', {'caseNo': dataItem.caseId}, {notify: false});
            caseDetailsModalService.createCase(dataItem)
            .then((caseData) => {
                return caseDetailsModalService.createCaseDetailsResolve(caseData)
            }).then((resolve) => {
                return caseDetailsModalService.openCaseDetailsModal(resolve);
            })
            .then((modal) => {
                $rootScope.$broadcast('hideBusyIndicator', 'caseDetailsControllerModal');
            }, () => $rootScope.$broadcast('hideBusyIndicator', 'caseDetailsControllerModal'))

            ;
        }

        function openHistory(event) {
            var sender = event.currentTarget;
            var row = angular.element(sender).closest("tr");
            var dataItem = $("#case-grid").data("kendoGrid").dataItem(row).toJSON();

            caseService.getCaseHistoryById(dataItem.operationLogId)
            .then((historyData) => {
                var modal = caseDetailsModalService.openCaseHistoryModal(historyData);
                modal.result.then(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'caseDetailsControllerModal');
                }, () => $rootScope.$broadcast('hideBusyIndicator', 'caseDetailsControllerModal'));
            });
        }

        function userHasDeletePermission() {
            if ($rootScope.authData.isSuperuser) {
                return true;
            };

            for (var i = 0; i < $rootScope.authData.roles.length; ++i) {
                for (var j = 0; j < $rootScope.authData.roles[i].permissions.length; ++j) {
                    if ($rootScope.authData.roles[i].permissions[j].key === "DeviationLogAdmin") {
                        return true;
                    }
                }
            }

            return false;
        }

        function deleteCase(event) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 550
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: () => {
                        return $translate.instant('CASE_CONFIRM_DELETE_MODAL_MSG', null, null, langId);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then((response => {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'caseBusyIndicator',
                        destination: '#case-grid',
                        overlay: true,
                    });
                    var dataItem = $("#case-grid").data("kendoGrid").dataItem(angular.element(event.currentTarget).closest("tr"));
                    var operationLogId = dataItem.toJSON().operationLogId;
                    $q.all([
                        workOrderService.getWorkOrderLogsByParentId(operationLogId),
                        logService.getOperationLogById(operationLogId)
                    ])
                    .then((data) => {
                        data[0].forEach((workOrder) => {
                            workOrder.status = 99;
                            workOrderService.updateWorkOrderLog(workOrder);
                        });
                        data[1].status = 99;
                        logService.updateOperationLogWebApi(data[1]);
                    })
                    .then( () => {
                        $scope.caseGridOptions.dataSource.remove(dataItem);
                        $rootScope.$broadcast('hideBusyIndicator', 'caseBusyIndicator');
                    });
                }
            }));
        }

        function openCaseDetailsModal() {
            caseDetailsModalService.createCaseDetailsResolve().then((resolve) => {

                var modal = caseDetailsModalService.openCaseDetailsModal(resolve);
                modal.result.then(function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'caseDetailsControllerModal');
                }, () => $rootScope.$broadcast('hideBusyIndicator', 'caseDetailsControllerModal'));
            });
        }

        function setStatusFilter(index) {
            $scope.statusFilters[index].active = !$scope.statusFilters[index].active;
            $scope.applyFilter();
        }

        $scope.applyFilter = function() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'applyFilterBusyIndicator',
                destination: '#main-view',
                overlay: true
            });

            $scope.deviations = getFiltered();
            calculateSumOfStatuses();
            afterFiltering();

            $rootScope.$broadcast('hideBusyIndicator', 'applyFilterBusyIndicator');
        }

        function getFilteredByStatuses(deviations) {
            return deviations.filter(function (log) {
                if (log.workflowStatuses && log.workflowStatuses[0]) {
                    return isStatusSelected(log.workflowStatuses[0].workflowStatusId,
                        !$scope.statusFilters.map(function (status) { return status.active; }).some(Boolean));
                }
                return false;
            });
        }

        function isStatusSelected(statusId, allFilterUnset) {
            return allFilterUnset ?
                true :
                $scope.statusFilters.filter(function (status) {
                    return status.value === statusId;
                })[0].active;
        }

        function getFiltered() {
            return deviations.filter(function (log) {
                return isBetweenDates(log.dueDate, $scope.fromDateObject, $scope.toDateObject) &&
                    isDepartmentToggled(log.departmentId) &&
                    isUserSelected(log.assignedUserIds) &&
                    log.status != 99;
            });
        }

        function isBetweenDates(actual, start, end) {
            if (!actual) {
                return true; //always show those which has no value
            }
            if (start || end) {
                var actualObject = new Date(actual);
                if (start && end) {
                    start.setHours(0, 0, 0, 0);
                    end.setHours(23, 59, 59, 999);
                    return start < actualObject && actualObject < end;
                } else if (!end) {
                    start.setHours(0, 0, 0, 0);
                    return start < actualObject;
                } else {
                    end.setHours(23, 59, 59, 999);
                    return actualObject < end;
                }
            } else {
                return true;
            }
        }

        function isDepartmentToggled(departmentId) {
            return !$scope.selectedDepartments || $scope.selectedDepartments.length <= 0 ?
                true :
                $scope.selectedDepartments.findIndex(function (department) {
                    return departmentId === department;
                }) > -1;
        }

        function isUserSelected(assignedUsers) {
            var selectedUserIds = $scope.selectedUsers;
            if (!selectedUserIds) return true;

            if (assignedUsers.length) {
                if(!$scope.selectedUsers || $scope.selectedUsers.length <= 0){
                    return true;
                } else {
                    var isInTheList = false;
                    assignedUsers.forEach(assignedUser => {
                        if(selectedUserIds.indexOf(assignedUser) > -1){
                            isInTheList = true;
                        }
                    });
                    return isInTheList;
                };
            }
            return selectedUserIds.indexOf(noUser.userId) > -1;
        }

        function afterFiltering() {
            kendoUtility.adjustGridHeight('#case-grid', $scope.deviations.length);
            $rootScope.$broadcast('casesFetched');
        }

        function calculateSumOfStatuses() {
            workflowStatuses.forEach(function (status) {
                $scope.sumByStatuses[status.meaning] = $scope.deviations.filter(function (log) {
                    return (log.workflowStatuses && log.workflowStatuses[0] && log.status != 99) ? log.workflowStatuses[0].workflowStatusId === status.value : false;
                }).length;
            });
        }

        function getFilteredDeviations(filter) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'casesGrid',
                destination: '#case-grid'
            });
            caseService.getCasesByFilter(filter).then(function (data) {
                closedDeviations = data;
                if($scope.isCustomerDeviationsToggled) { //Remove incoming deviations that already exist locally
                    closedDeviations = closedDeviations.filter(deviation => deviation.logActionIsCustomerDeviation)
                }
                deviations = extendDeviations(deviations.concat(closedDeviations));
                $scope.applyFilter();
                $rootScope.$broadcast('hideBusyIndicator', 'casesGrid');
            }, () => $rootScope.$broadcast('hideBusyIndicator', 'casesGrid'));
        }

        function filterOutClosedDeviations() {
            $scope.statusFilters[2].active = false;
            deviations = deviations.filter(function (deviation) {
                return deviation.workflowStatuses.map(function (status) { return status.workflowStatusId; }).indexOf(workflowStatuses[2].value) === -1;
            });
            $scope.applyFilter();
        }

        function filterOutCustomerDeviations() {
            deviations = deviations.filter(deviation => !deviation.logActionIsCustomerDeviation)
            $scope.applyFilter();
        }

        function extendDeviations(deviations) {
            return deviations.map(function (deviation) {

                var isOpenOrActiveStatus = deviation.workFlowStatusId == workflowStatuses.find(x => x.meaning === "Open").value || deviation.workFlowStatusId == workflowStatuses.find(x => x.meaning === "InProgress").value ? true : false;
                deviation.isExpired = isOpenOrActiveStatus && (deviation.dueDate && new Date(deviation.dueDate) < new Date(new Date().setHours(0, 0, 0, 0)));
                deviation.isClosed = deviation.workFlowStatusId == workflowStatuses.find(x => x.meaning === "Closed").value ? true : false;

                if (dateUtility.isDefaultDate(deviation.dueDate)) {
                    deviation.dueDate = '';
                }

                if (deviation.workflowStatuses && deviation.workflowStatuses[0]) {
                    var status = workflowStatuses.filter(function (status) {
                        return status.value === deviation.workflowStatuses[0].workflowStatusId;
                    })[0];
                    deviation.caseStatus = status.text;
                } else {
                    deviation.caseStatus = '';
                }

                deviation.isOwnDeviation = deviation.assignedUserIds.includes($rootScope.authData.userId) || deviation.registeredUserName === $rootScope.authData.username;

                deviation.caseTypeDescription = deviation.description;

                var caseTypeList = $scope.caseTypes.find(x => x.logActionId == deviation.logActionId);

                var caseType = caseTypeList && caseTypeList.list && caseTypeList.list.listElements ? caseTypeList.list.listElements.find(x => x.displayName == deviation.description) : null;

                deviation.caseTypeId = caseType ? caseType.id : null;

                var workOrderNumbers = deviation.workOrders.map(x => x.woNumber);
                deviation.workOrderNumbers = workOrderNumbers.join(', ');

                workOrders = workOrders.concat(deviation.workOrders);
                deviation.itemDescription = deviation.itemDescription && deviation.itemDescription.replace("TRANSLATE_CAGE:", $translate.instant("ORDER_PROCESS_COMPONENT_CAGE", langId));

                deviation.assignedUserNameList = deviation.assignedUserNames.join(', ');

                return deviation;
            }).sort(function (a, b) {
                return new Date(b.createdOn) - new Date(a.createdOn);
            });
        }

        function loadCases() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'casesGrid',
                destination: '#case-grid'
            });

            return caseService.getCasesByFilter({
                assignedUserIds: $scope.assignedUserIdsFilter,
                contractorId: $rootScope.authData.contractorId,
                workFlowStatusIds: workflowStatuses.filter(function (status) {
                    return status.meaning !== 'Closed';
                }).map(function (status) {
                    return status.value;
                }),
                getAttachmentStatus: true,
                getWorkOrderStatus: false,
                getWorkOrderList: true,
                getCustomerDeviation: $scope.isCustomerDeviationsToggled
            }).then((deviationsResult) => {
                deviations = extendDeviations(deviationsResult);
                $scope.applyFilter();
                $rootScope.$broadcast('hideBusyIndicator', 'casesGrid');
            }, () => $rootScope.$broadcast('hideBusyIndicator', 'casesGrid'));
        }

        function getFilteredUser(users) {
            var notAssignableUsers = users.filter(p => p.permissions.includes('DeviationLogNotAssignable'));

            var filteredUsers = users.filter(function (item) {
                return notAssignableUsers.find(function (item2) {
                    return item.userName == item2.userName;
                }) == undefined;
            });
            return filteredUsers.concat(users.filter(u => !u.userName));
        }

        function checkInputs(elements) {
            elements.each(function() {
                  var element = $(this);
              var input = element.children("input");
              input.prop("checked", element.hasClass("k-state-selected"));
            });
        }

        function selectAllDepartments(){
            $scope.selectedDepartments = $scope.departmentSelectorSource.map(x => x.id);
            var items = $('#department-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", true);
              });
              $scope.applyFilter();
            });
        }

        function unSelectAllDepartments(){
            $scope.selectedDepartments = [];
            var items = $('#department-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", false);
              });
              $scope.applyFilter();
            });
        }

        function selectAllUsers(){
            $scope.selectedUsers = $scope.userSelectorSource.map(x => x.id);
            var items = $('#user-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", true);
              });
              $scope.applyFilter();
            });
        }

        function unSelectAllUsers(){
            $scope.selectedUsers = [];
            var items = $('#user-selector_listbox').children();
            setTimeout(function() {
                items.each(function() {
                    var element = $(this);
                    var input = element.children("input");
                    input.prop("checked", false);
              });
              $scope.applyFilter();
            });
        }

        function departmentTypeChanges(){
            var dorpodown = $("#department-selector").data("kendoMultiSelect");
            dorpodown.setOptions({
                filter: $scope.departmentSearchType == 0 ? 'contains' : 'startswith'
            });
        }

        function userTypeChanges(){
            var dorpodown = $("#user-selector").data("kendoMultiSelect");
            dorpodown.setOptions({
                filter: $scope.userSearchType == 0 ? 'contains' : 'startswith'
            });
        }

        function exportToExcel() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'excelExportBusyIndicator',
                destination: '#cases'
            });

            var dataSource = $scope.caseGrid.dataSource;
            var filters = dataSource.filter() || {};
            var sort = dataSource.sort() || {};
            var allData = dataSource.data();
            var query = new kendo.data.Query(allData);
            var filteredQuery = query.filter(filters);
            var data = filteredQuery.sort(sort).data;
            var operationLogIds = data.map(x => x.operationLogId);

            var requests = {
                getCasesForExcelExportByFilter: caseService.getCasesForExcelExportByFilter(operationLogIds)
            }

            $q.all(requests).then(function (results) {
                var header = [
                    $translate.instant('CASE_GRID_CASEID'),
                    $translate.instant('CASE_GRID_CASETITLE'),
                    $translate.instant('CASE_MODAL_DESCRIPTION'),
                    $translate.instant('CASE_ESTIMATION_COST'),
                    $translate.instant('G_VESSEL'),
                    $translate.instant('CASE_GRID_LOGACTION_DESCRIPTION'),
                    $translate.instant('CASE_GRID_DESCRIPTION'),
                    $translate.instant('CASE_GRID_REGISTERED_BY'),
                    $translate.instant('CASE_GRID_ASSIGNED_TO'),
                    $translate.instant('CASE_GRID_DONE_ON'),
                    $translate.instant('CASE_GRID_DUE_DATE'),
                    $translate.instant('CASE_GRID_STATUS'),
                    $translate.instant('CASE_GRID_SEVERITY'),
                    $translate.instant('CASE_MODAL_EQUIPMENT'),
                    $translate.instant('CASE_GRID_AO'),
                    $translate.instant('CASE_GRID_ATTACHMENT')
                ];

                var rows = results.getCasesForExcelExportByFilter;
                rows.forEach(deviation => {
                    var status = workflowStatuses.find(function (status) {
                        return status.value === deviation.caseStatus;
                    });
                    deviation.caseStatus = status ? status.text : "";

                    var currentSeverity = severityList.find(x => x.value == deviation.severity);
                    deviation.severity = currentSeverity.text;
                    deviation.hasAttachedMedia = deviation.hasAttachedMedia ? $translate.instant('G_TRUE') : $translate.instant('G_FALSE');
                });

                var data = { header: header, rows: rows, filename: $translate.instant('MAIN_LOCATION_PANEL_DEVIATIONLOGS') };
                excelExportService.exportToExcel(data);
            }).finally(function(){$rootScope.$broadcast('hideBusyIndicator', 'excelExportBusyIndicator');});
        }

        function exportToPDF(){
            var printUrl = window.location.origin + '/case-list-report-print?PrintView=true'
            var fileName = 'Case list report - ' + moment().format('DD.MM.YYYY') + '.pdf';

            var pdfUrl = window.location.origin + '/api/print?printUrl=' + encodeURIComponent(printUrl)
                + '&fileName=' + fileName;
            window.open(pdfUrl, '_blank');
        }

        function readCaseType(e){
            $q.all([caseService.getCaseTypesByContractorId($rootScope.authData.contractorId)]).then(function(data) {
                $scope.caseTypes = data[0];
                var caseTypesList = [];

                $scope.caseTypes.forEach((type) => {
                    var itemInList = caseTypesList.map(x => x.displayName);
                    if(type.list && type.list.listElements && type.list.listElements.length > 0){
                        var filteredListElements = type.list.listElements.filter(l => !itemInList.includes(l.displayName));
                        caseTypesList = caseTypesList.concat(filteredListElements);
                    }
                });

                e.success(caseTypesList);
            })
            .catch(error => e.success([]));
        }

        function readLogAction(e){
            $q.all([caseService.getCaseTypesByContractorId($rootScope.authData.contractorId)]).then(function(data) {
                e.success(data[0]);
            })
            .catch(error => e.success([]));
        }

        function createLogActionMultiSelect(element){
            element.removeAttr("data-bind");

            element.kendoMultiSelect({
                dataSource: {
                    transport: {
                        read: readLogAction
                    }
                },
                dataTextField: "description",
                dataValueField: "logActionId",
                change: function(e) {
                    var filter = { logic: "or", filters: [] };
                    var values = this.value();
                    $.each(values, function(i, v) {
                        filter.filters.push({field: "logActionId", operator: "contains", value: v });
                    });

                    var currentFilter = caseGridDataSource.filter();
                    var activeFilters = [];
                    var activeCaseTypeFilter = [];
                     if(currentFilter && currentFilter.filters && currentFilter.filters.length > 0){
                        iterateThroughNestedObj(currentFilter, activeFilters);
                        activeCaseTypeFilter = activeFilters.filter(l => l.field == 'caseTypeId');
                        activeFilters = activeFilters.filter(l => l.field != 'logActionId' && l.field != 'caseTypeId');

                        if(activeCaseTypeFilter && activeCaseTypeFilter.length > 0){
                            var caseTypeFilter = {
                                logic: "or",
                                filters: activeCaseTypeFilter
                            };
                            filter.filters.push(caseTypeFilter);
                            activeFilters.push(logActionFilter);
                            var finalFilter = {
                                logic: "and",
                                filters: activeFilters
                            };
                        } else if(activeFilters && activeFilters.length > 0) {
                            if(filter.filters.length > 0){
                                activeFilters.push(filter);
                            }

                            var finalFilter = {
                                logic: "and",
                                filters: activeFilters
                            };
                        } else {
                            var finalFilter = filter;
                        }
                    } else {
                        var finalFilter = filter;
                    }

                   caseGridDataSource.filter(finalFilter);
              }
            });
        }

        function createCaseTypeMultiSelect(element) {
            element.removeAttr("data-bind");

            element.kendoMultiSelect({
                dataSource: {
                    transport: {
                        read: readCaseType
                    }
                },
                dataTextField: "value",
                dataValueField: "id",
                change: function(e) {
                    var filter = { logic: "or", filters: [] };
                    var values = this.value();

                    $.each(values, function(i, v) {
                        filter.filters.push({field: "caseTypeId", operator: "contains", value: v });
                    });


                    var currentFilter = caseGridDataSource.filter();
                    var activeFilters = [];
                    var activeLogActionFilter = [];
                     if(currentFilter && currentFilter.filters && currentFilter.filters.length > 0){
                        iterateThroughNestedObj(currentFilter, activeFilters);
                        activeLogActionFilter = activeFilters.filter(l => l.field == 'logActionId');
                        activeFilters = activeFilters.filter(l => l.field != 'logActionId' && l.field != 'caseTypeId');

                        if(activeLogActionFilter && activeLogActionFilter.length > 0){

                            var logActionFilters = {
                                logic: "or",
                                filters: activeLogActionFilter
                            };

                            var filters = [];
                            filters.push(logActionFilters);
                            if(filter.filters.length > 0){
                                filters.push(filter);
                            }

                            var logActionFilter = {
                                logic: "and",
                                filters: filters
                            };

                            if(activeFilters && activeFilters.length > 0){
                                activeFilters.push(logActionFilter);
                                var finalFilter = {
                                    logic: "and",
                                    filters: activeFilters
                                };
                            } else {
                                var finalFilter = logActionFilter;
                            }


                        } else if(activeFilters && activeFilters.length > 0) {
                            if(filter.filters.length > 0){
                                activeFilters.push(filter);
                            }
                            var finalFilter = {
                                logic: "and",
                                filters: activeFilters
                            };
                        } else {
                            var finalFilter = filter;
                        }
                    } else {
                        var finalFilter = filter;
                    }
                    caseGridDataSource.filter(finalFilter);
              }
            });
        }

        function iterateThroughNestedObj(obj, array) {
            Object.keys(obj).forEach(function (key) {
                if(key == 'field'){
                    array.push(obj);
                }
                if (typeof obj[key] === 'object') {
                    return iterateThroughNestedObj(obj[key], array);
                }
            });
        }

        function isReadOnlyPermission(){
            if($scope.hasPermission('DeviationLogAdmin') ){
                return false;
            }

            if($scope.hasPermission('DeviationLogEdit') || $scope.hasPermission('DeviationLogEditOnlyOwn')){
                if(($scope.hasPermission('DeviationLogEditOnlyOwn') && caseData && caseData.isOwnDeviation)
                || ($scope.hasPermission('DeviationLogEdit') && !$scope.hasPermission('DeviationLogEditOnlyOwn'))){
                   return false;
                } else {
                    return true;
                }
            } else {
                return true
            }
        }

        $scope.clickWO = function(operationLogId){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'casesGrid',
                destination: '#case-grid'
            });

            $(".k-tooltip").addClass("k-tooltip-hidden")

            var requests = {
                getWorkOrderById: workOrderService.getWorkOrderById(operationLogId),
            };

            $q.all(requests).then((result) => {
               var workOrder = result.getWorkOrderById;
               var workOrderLog = workOrderDetailsModalService.createWorkOrderLog(workOrder);
               workOrderDetailsModalService.createWorkOrderDetailsResolve(workOrderLog)
                    .then(function (resolve) {
                        var modal = workOrderDetailsModalService.openWorkOrderDetailsModal(workOrderLog, null, !isReadOnlyPermission());
                        $rootScope.$broadcast('hideBusyIndicator', 'casesGrid');

                        modal.result.then(function () {
                        });
                    })
                    .catch(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'casesGrid');
                    })
            })
            .catch(() => {
                $rootScope.$broadcast('hideBusyIndicator', 'casesGrid');
            })
        }

        function clearDateFilters() {
            $scope.fromDateObject = null;
            $scope.toDateObject = null;
            $scope.applyFilter();
        }

        function getUserConfigurationSettings() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'getFiltersBusyIndicator',
                destination: '#main-view',
                overlay: true
            });

            userConfigurationService.getUserConfigurationSettings(system, pageName)
                                    .then(data => {
                                        if (data) {
                                            const userConfiguration = JSON.parse(data);
                                            mapUserConfigurationSettings(userConfiguration);
                                        }
                                        $scope.applyFilter();
                                    })
                                    .catch(error => console.log(error))
                                    .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'getFiltersBusyIndicator'));
        }

        function mapUserConfigurationSettings(userConfiguration) {
            $scope.fromDateObject = userConfiguration.fromDate ? new Date(userConfiguration.fromDate) : null;
            $scope.toDateObject = userConfiguration.toDate ? new Date(userConfiguration.toDate) : null;
            $scope.departmentSearchType = userConfiguration.departmentSearchType,
            $scope.selectedDepartments = userConfiguration.department;
            $scope.selectedUsers = userConfiguration.assignedTo;
            $scope.isClosedOrdersToggled = userConfiguration.showHistory;
            $scope.isCustomerDeviationsToggled = userConfiguration.showCustomerDeviations;
            $scope.statusFilters = userConfiguration.statusFilters ? userConfiguration.statusFilters : $scope.statusFilters;
        }

        function selectCheckbox(dropdown = 'user') {
            let foundIndexList = [];

            if (($scope.selectedDepartments && $scope.departmentSelectorSource) || ($scope.selectedUsers && $scope.userSelectorSource)) {
                dropdown === 'department'
                    ? $scope.selectedDepartments && $scope.selectedDepartments.length && $scope.selectedDepartments.forEach(source => foundIndexList.push($scope.departmentSelectorSource.findIndex(x => x.id === source)))
                    : $scope.selectedUsers && $scope.selectedUsers.length && $scope.selectedUsers.forEach(source => foundIndexList.push($scope.userSelectorSource.findIndex(x => x.id === source)));

                let items = $(`#${dropdown}-selector_listbox`).children();
                if (foundIndexList.length) {
                    foundIndexList.forEach(i => {
                        if(i > -1) {
                            let input = $(items[i]).children("input");
                            input.prop("checked", true);
                        }
                    })
                }
            }
        }

        function saveUserConfiguration() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'saveFiltersBusyIndicator',
                destination: '#case-grid',
                overlay: true
            });

            const userConfiguration = createUserConfiruation();
            userConfiguration.settings = JSON.stringify(userConfiguration.settings);

            userConfigurationService.addUserConfiguration(userConfiguration)
                         .catch(error => console.log(error))
                         .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'saveFiltersBusyIndicator'));
        }

        function createUserConfiruation() {
            return {
                createdOn: new Date(),
                userId: $rootScope.authData.userId,
                pageName: pageName,
                system: system,
                settings: {
                    fromDate: $scope.fromDateObject ? $scope.fromDateObject : '',
                    toDate: $scope.toDateObject ? $scope.toDateObject : '',
                    departmentSearchType: $scope.departmentSearchType && $scope.departmentSearchType == 0 ? 'contains' : 'startswith',
                    department: $scope.selectedDepartments ? $scope.selectedDepartments : '',
                    assignedTo: $scope.selectedUsers ? $scope.selectedUsers : '',
                    showHistory: $scope.isClosedOrdersToggled,
                    showCustomerDeviations: $scope.isCustomerDeviationsToggled,
                    statusFilters: $scope.statusFilters
                }
            };
        }
    }
})();
