(function() {
    'use strict';

    angular.module('NaviaqWebApp').controller('UserAdministrationController', userAdministrationController);

    userAdministrationController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$kWindow',
        '$translate',
        'userService',
        'departmentService',
        'contractorService',
        'authService',
        'kendoUtility',
        'dateUtility',
        'formTypes',
        'commonUtility',
        'htmlElementService',
        'jobTitleService'
    ];

    function userAdministrationController(
        $q,
        $scope,
        $rootScope,
        $kWindow,
        $translate,
        userService,
        departmentService,
        contractorService,
        authService,
        kendoUtility,
        dateUtility,
        formTypes,
        commonUtility,
        htmlElementService,
        jobTitleService
    ) {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const yesterday = dateUtility.getYesterday();

        const userAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.users)
            },
            group: $rootScope.authData.isSuperuser ? { field: "contractorName" } : [],
            schema: {
                model: {
                    id: 'userId',
                    fields: {
                        userId: { editable: false},
                        userName: { editable: false},
                        displayName: { editable: false},
                        validFrom: { editable: false, type: 'date'},
                        validTo: { editable: false, type: 'date' },
                        activeDepartmentId: { editable: false },
                        contractorId: { editable: false },
                        active: { editable: false },
                    }
                },
                parse: function (d) {
                    $.each(d, function (idx, elem) {
                        elem.validFrom = dateUtility.formatDate(elem.validFrom);
                        elem.validTo = dateUtility.formatDate(elem.validTo);
                    });
                    return d;
                }
            }
        });

        const userAdministrationGridOptions = langId => ({
            dataSource: userAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'userId',
                    hidden: true
                },
                {
                    field: 'userName',
                    title: $translate.instant('G_USERNAME', null, null, langId)
                },
                {
                    field: 'displayName',
                    title: $translate.instant('G_DISPLAYNAME', null, null, langId)
                },
                {
                    field: 'validFrom',
                    title: $translate.instant('G_VALIDFROM', null, null, langId),
                    template: dataItem => (dataItem.validFrom ? dateUtility.formatDate(dataItem.validFrom) : ''),
                    width: 100
                },
                {
                    field: 'validTo',
                    title: $translate.instant('G_VALIDTO', null, null, langId),
                    template: dataItem => (dataItem.validTo ? dateUtility.formatDate(dataItem.validTo) : ''),
                    width: 100
                },
                {
                    field: 'departmentName',
                    title: $translate.instant('G_DEPARTMENT', null, null, langId),
                },
                {
                    field: 'contractorName',
                    title: $translate.instant('G_CONTRACTOR', null, null, langId),
                    hidden: !$rootScope.authData.isSuperuser,
                },
                {
                    field: 'jobTitle',
                    title: $translate.instant('ADMIN_USER_JOB_TITLE', null, null, langId),
                    template: function (dataItem) {
                        const jobTitle = $scope.jobTitles.find(x => x.sequenceNumber === dataItem.jobTitle);
                        return jobTitle ? jobTitle.name : '';
                    }
                },
                {
                    field: 'active',
                    title: $translate.instant('G_STATUS', null, null, langId),
                    template: dataItem =>
                        dataItem.inactive
                            ? $translate.instant('G_INACTIVE', null, null, langId)
                            : $translate.instant('G_ACTIVE', null, null, langId),
                    width: 100
                },
                {
                    command: [
                        {
                            name: 'editUser',
                            click: e => editUser(e),
                            template: '<a ng-disabled="{{!dataItem.contractorId}}" ng-class="{\'k-state-disabled\' : !dataItem.contractorId}" class="k-grid-editUser"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'toggleUser',
                            click: e => toggleUserStatus(e),
                            template: `<a class="k-grid-toggleUser"><i class="fas fa-ban"></i></a>`
                        },
                        {
                            name: 'setPassword',
                            click: e => setPassword(e),
                            template: `<a class="k-grid-setPassword"><i class="fas fa-key"></i></a>`
                        },
                        {
                            name: 'sendWelcomeEmail',
                            click: e => sendWelcomeEmail(e),
                            template: `<a class="k-grid-sendWelcomeEmail"><i class="fas fa-envelope"></i></a>`
                        }
                    ],
                    width: 150,
                    hidden: !authService.hasPermission('UserAdministrationEdit')
                }
            ],
            dataBound: onDataBound,
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true
        });

        $scope.userAdministrationGridOptions = userAdministrationGridOptions($translate.use());

        $scope.users = [];
        $scope.departments = [];
        $scope.contractors = [];

        $scope.jobTitles = [];

        $scope.addNewUser = addNewUser;
        $scope.editUser = editUser;
        $scope.toggleUserStatus = toggleUserStatus;
        $scope.setPassword = setPassword;
        $scope.sendWelcomeEmail = sendWelcomeEmail;

        $scope.$on('langChanged', (event, code) => {
            $scope.userAdministrationGridOptions = userAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.userAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#user-administration-grid')]);
        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'userAdminGridIndicator',
                destination: '#user-administration-grid'
            });
            htmlElementService.checkIfElementExists("filter-group")
            .then(function () {
                commonUtility.calculateAdminTabMenuWidth();
            });
            let requests = {};
            requests.getUsersByContractorIdWebApi = userService.getUsersByContractorIdWebApi();
            requests.getDepartmentsWebapi = departmentService.getAllDepartmentsWebapi();
            requests.getJobTitlesbyContractorId = jobTitleService.getJobTitlesbyContractorId($rootScope.authData.contractorId);

            if ($rootScope.authData.isSuperuser) {
                requests.getContractors = contractorService.getContractors();
            }
            $q.all(requests)
                .then(data => {
                    $scope.departments = data.getDepartmentsWebapi;
                    $scope.contractors = data.getContractors || [];
                    $scope.users = extendUsers(data.getUsersByContractorIdWebApi);
                    $scope.jobTitles = data.getJobTitlesbyContractorId.sort((a, b) => {
                        return a.sequenceNumber.toString().localeCompare(b.sequenceNumber.toString());
                    });
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.userAdministrationGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'userAdminGridIndicator');
                });
        }

        function addNewUser() {
            const window = openUserDetailsModal(formTypes.add);
            window.result.then(initController);
        }

        function editUser(event) {
            let user = getUserFromGrid(event);

            if (user.contractorId) {
                const window = openUserDetailsModal(formTypes.edit, user);
                window.result.then(initController);
            }
        }

        function toggleUserStatus(event) {
            let user = getUserFromGrid(event);
            user.validTo = isInactive(user.validTo) ? null : dateUtility.toIsoString(yesterday);
            userService
                .updateUser(user)
                .then(initController)
                .catch(error => console.error(error));
        }

        function getUserFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#user-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function openUserDetailsModal(formType, user) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: user ? user.userName : $translate.instant('ADMIN_USER_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/systemAdministration/users/user-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'userDetailsModalController',
                resolve: {
                    contractors: () => $scope.contractors,
                    currentFormType: () => formType,
                    user: () => user
                }
            });
        }

        function extendUsers(users) {
            _.forEach(users, (u) => {
                const contractor = $scope.contractors.find(c => c.contractorId === u.contractorId);
                u.contractorName = contractor ? contractor.name : '';

                const department = $scope.departments.find(d => d.departmentId === u.activeDepartmentId);
                u.departmentName = department ? department.name : '';
            });

            return _.chain(users.map(u => ({ ...u, inactive: isInactive(u.validTo) })))
                .sortBy('userName')
                .sortBy('contractorId')
                .value();
        }

        function isInactive(date) {
            return date ? new Date(date) < today : false;
        }

        function onDataBound(e) {
            const data = this.dataSource.view();
            for (let i = 0; i < data.length; i++) {
                if (data[i].inactive) {
                    const uid = data[i].uid;
                    const row = this.table.find("tr[data-uid='" + uid + "']");
                    let item = row.find('.fas.fa-ban');
                    item.removeClass('fa-ban').addClass('fa-check');
                }
            }
        }

        function setPassword(e) {
            const user = getUserFromGrid(e);

            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: user.userName,
                    resizable: true,
                    visible: false,
                    width: 650
                },
                templateUrl: 'app/systemAdministration/users/new-password-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'newPasswordModalController',
                resolve: {
                    userName: () => user.userName
                }
            });
        }

        function sendWelcomeEmail(e) {
            const user = getUserFromGrid(e);

            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: user.userName,
                    resizable: true,
                    visible: false,
                    width: 650
                },
                templateUrl: 'app/systemAdministration/users/welcome-mail-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'welcomeMailModalController',
                resolve: {
                    userName: () => user.userName
                }
            });
        }
    }
})();
