(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('VesselSharingDetailsModalController', VesselSharingDetailsController);

    function VesselSharingDetailsController($scope, $rootScope, $windowInstance, $translate, dateUtility, htmlElementService, authService, departmentService, formTypes, departments, contractors, formType, departmentSharing) {
        kendo.culture('no-NO');
        $scope.authData = authService.getAuthData();
        $scope.contractors = contractors;
        $scope.departments = departments;
        $scope.departmentSharing = departmentSharing;
        $scope.sharingAlreadyStarted = false;
        $scope.formType = formType;

        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;
        $scope.sharedWithContractorOptions = {
            dataSource: new kendo.data.DataSource({
                data: $scope.contractors
            }),
            dataValueField: 'contractorId',
            dataTextField: 'name',
            optionLabel: $translate.instant('ADMIN_VESSEL_DEPARTMENTSHARING_SHARED_SELECT')
        };
        $scope.departmentOptions = {
            dataSource: new kendo.data.DataSource({
                data: departments
            }),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            optionLabel: $translate.instant('ADMIN_VESSEL_DEPARTMENTSHARING_DEPARTMENT_SELECT')
        };

        $scope.departmentSharingValidatorOptions = {
            rules: {
                greaterdate: function (input) {
                    if (input.data('greaterdateTarget') && input.data('greaterdateThis')) {
                        var targetDate = $scope[input.data('greaterdateTarget')];
                        var thisDate = $scope[input.data('greaterdateThis')];
                        return !targetDate || !thisDate ||
                            moment(thisDate, 'DD.MM.YYYY').isAfter(moment(targetDate, 'DD.MM.YYYY'), 'days');
                    } else {
                        return true;
                    }
                },
                overlap: function (input) {
                    if (input.is('[overlap-validation]')) {
                        if (!$scope.selectedDepartment || !$scope.start) {  //if department or start date is not yet set all is good
                            return true;
                        }
                        var overlappingSharing = _.find($scope.departmentSharings,
                            function (departmentSharing) {
                                if (departmentSharing.departmentId === $scope.selectedDepartment.departmentId &&
                                    (!$scope.departmentSharing || departmentSharing.id !== $scope.departmentSharing.id)) {
                                    var a = !departmentSharing.end ||
                                        moment($scope.start, 'DD.MM.YYYY').diff(moment(departmentSharing.end)) <= 0; //StartA <= EndB
                                    var b = !$scope.end ||
                                        moment(departmentSharing.start).diff(moment($scope.end, 'DD.MM.YYYY')) <= 0; //StartB <= EndA
                                    return a && b;
                                } else {
                                    return false;
                                }
                            });
                        return overlappingSharing === undefined || overlappingSharing === null;
                    } else {
                        return true;
                    }
                },
                aftertoday: function (input) {
                    if (input.is('[aftertoday]') && input.data('aftertodayThis')) {
                        var thisDate = $scope[input.data('aftertodayThis')];
                        return !thisDate || moment(thisDate, 'DD.MM.YYYY').isAfter(moment(), 'days');
                    } else {
                        return true;
                    }
                }
            }
        };

        departmentService.getDepartmentSharingsWebApi($scope.authData.contractorId).then(function (result) {
            $scope.departmentSharings = result;
        });

        if (formType === formTypes.edit && departmentSharing) {
            setTimeout(function () {
                $scope.$apply(function () {
                    initModal(departmentSharing);
                });
            }, 800);
        }

        function initModal(departmentSharing) {
            htmlElementService.checkIfElementExists("department-sharing-form")
                .then(function (result) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'vesselDetailsModalBusyIndicator',
                        destination: '#department-sharing-form',
                        overlay: true
                    });

                    $scope.departmentSharing = departmentSharing;

                    $scope.start = departmentSharing.Start;
                    $scope.end = departmentSharing.End;

                    $scope.sharingAlreadyStarted = !moment($scope.start, 'DD.MM.YYYY').isAfter(moment(), 'days');

                    $scope.departmentSelector.select(function (departmentOption) {
                        if (departmentOption.departmentId === departmentSharing.departmentId) {
                            $scope.selectedDepartment = departmentOption;
                        }
                        return departmentOption.value === departmentSharing.departmentId;
                    });
                    $scope.sharedWithContractorSelector.select(function (sharedWithContractorOption) {
                        if (sharedWithContractorOption.contractorId === departmentSharing.sharedWithId) {
                            $scope.selectedSharedWithContractor = sharedWithContractorOption;
                        }
                        return sharedWithContractorOption.value === departmentSharing.sharedWithId;
                    });
                    $rootScope.$broadcast('hideBusyIndicator', 'vesselDetailsModalBusyIndicator');
                });
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                if (formType === formTypes.add) {
                    $scope.departmentSharing = {
                        departmentId: $scope.selectedDepartment.departmentId,
                        sharedWithId: $scope.selectedSharedWithContractor.contractorId,
                        start: dateUtility.toJson(moment($scope.start, 'DD.MM.YYYY')),
                        end: dateUtility.toJson(moment($scope.end, 'DD.MM.YYYY')),
                        isActive: true
                    };
                    departmentService.addDepartmentSharingWebApi($scope.departmentSharing).then(function (response) {
                        if (response)
                            $windowInstance.close(true);

                    });
                } else {
                    $scope.departmentSharing.departmentId = $scope.selectedDepartment.departmentId;
                    $scope.departmentSharing.sharedWithId = $scope.selectedSharedWithContractor.contractorId;
                    $scope.departmentSharing.start = dateUtility.toJson(moment($scope.start, 'DD.MM.YYYY'));
                    $scope.departmentSharing.end = dateUtility.toJson(moment($scope.end, 'DD.MM.YYYY'));
                    $scope.departmentSharing.Start = dateUtility.toJson(moment($scope.start, 'DD.MM.YYYY'));
                    $scope.departmentSharing.End = dateUtility.toJson(moment($scope.end, 'DD.MM.YYYY'));

                    departmentService.updateDepartmentSharingWebApi($scope.departmentSharing).then(function (response) {
                        if (response)
                            $windowInstance.close(true);
                    });
                }
            }
        }

        function closeModal() {
            $windowInstance.close();
        }
    }
})();
