(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('newPasswordModalController', newPasswordModalController);

    newPasswordModalController.$inject = ['$rootScope', '$scope', '$windowInstance', '$translate', 'adminUserService', 'userName'];

    function newPasswordModalController($rootScope, $scope, $windowInstance, $translate, adminUserService, userName) {
        $scope.password = '';
        $scope.confirmPassword = '';
        $scope.passwordLength = 8;
        $scope.numberLength = 1;
        $scope.capitalLetterLength = 1;

        $scope.pattern = '(?=.*[0-9])(?=.*[A-Z])(.*)';
        $scope.numberPattern = '[0-9]+';
        $scope.capitalPattern = '[A-Z]+';

        $scope.minRequiredPasswordIsValid = true;
        $scope.minNumberInPasswordIsValid = true;
        $scope.minCapitalInPasswordIsValid = true;
        $scope.confirmPasswordIsValid = true;
        $scope.regexMatchIsValid = true;
        $scope.failed = false;

        $scope.validation = validation;
        $scope.resetPassword = resetPassword;
        $scope.close = close;

        function validation() {
            let success = false;
            const pattern = new RegExp($scope.pattern);

            if ($scope.password) {
                $scope.minRequiredPasswordIsValid = $scope.password.length >= $scope.passwordLength;
                $scope.minNumberInPasswordIsValid = ($scope.password.match(new RegExp($scope.numberPattern, 'g')) || []).length >= $scope.numberLength;
                $scope.minCapitalInPasswordIsValid = ($scope.password.match(new RegExp($scope.capitalPattern, 'g')) || []).length >= $scope.capitalLetterLength;
            }
            $scope.confirmPasswordIsValid = $scope.password === $scope.confirmPassword;
            $scope.regexMatchIsValid = pattern.test($scope.password);

            success = $scope.confirmPasswordIsValid
                && $scope.minNumberInPasswordIsValid
                && $scope.minRequiredPasswordIsValid
                && $scope.minCapitalInPasswordIsValid
                && $scope.regexMatchIsValid;

            return success;
        };

        function resetPassword() {
            if ($scope.validator.validate() && validation()) {
                if (!$scope.password || !$scope.confirmPassword) {
                    alert($translate.instant('PASSWORD_RESET_MISSING'));
                    return;
                }

                if ($scope.password !== $scope.confirmPassword) {
                    alert($translate.instant('PASSWORD_RESET_NOT_MATCHING'));
                    return;
                }

                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'passwordSave',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                adminUserService.setPasswordForUser({ userName, password: $scope.password, confirmPassword: $scope.confirmPassword })
                    .then((response) => {
                        if (response.isSuccess) {
                            alert($translate.instant('PASSWORD_RESET_SUCCESSFUL'));
                        }
                        else {
                            alert($translate.instant('PASSWORD_RESET_FAILED'));
                        }
                    })
                    .catch(() => alert($translate.instant('PASSWORD_RESET_FAILED')))
                    .finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'passwordSave');
                        close();
                    });
            }
        }

        function close() {
            $windowInstance.close();
        }
    }
})();
