(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('approveRestLogsModal', approveRestLogsModal);

    approveRestLogsModal.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'fromDate',
        'toDate',
        'userId'
    ];

    function approveRestLogsModal(
        $rootScope,
        $scope,
        $windowInstance,
        fromDate,
        toDate,
        userId
    ) {
        $scope.fromDate = fromDate;
        $scope.toDate = toDate;
        $scope.userId = userId;

        $scope.approveLogs = function(){
            var approveLogsFilter =
            {
                contractorId: $rootScope.authData.contractorId,
                fromDate: moment($scope.fromDate).format('YYYY-MM-DDTHH:mm:ss'),
                toDate: moment($scope.toDate).format('YYYY-MM-DDTHH:mm:ss'),
                userId: $scope.userId
            }
            $scope.close(approveLogsFilter);
        }

        $scope.close = function(param) {
            $windowInstance.close(param);
        }
    }
})();
