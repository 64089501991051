(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('userBalanceDetailsController', userBalanceDetailsController);

    userBalanceDetailsController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'userService',
        'timeBankService',
        'formTypes',
        'currentFormType',
        'userBalance',
        'filter',
    ];

    function userBalanceDetailsController(
        $rootScope,
        $scope,
        $windowInstance,
        userService,
        timeBankService,
        formTypes,
        currentFormType,
        userBalance,
        filter
    ) {
        $scope.userBalance = userBalance;
        $scope.users = [];
        $scope.selectedUser = null;
        $scope.isEdit = currentFormType === formTypes.edit;
        $scope.balanceOnDay = null;

        $scope.usersDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.users),
                },
            }),
            filter: 'contains',
            dataValueField: 'userId',
            dataTextField: 'displayName',
            change: function(){
                return $scope.getUserBalanceForDay();
            },
        };

        $scope.validateForm = validateForm;
        $scope.close = close;

        initController();

        function initController() {
            if (currentFormType === formTypes.add) {
                $scope.userBalance = {
                    userBalanceId: null,
                    balance: null,
                    balanceDate: moment(new Date()).format(),
                    userId: null,
                    comment: null,
                };
            }

            if (currentFormType === formTypes.edit) {
                if (!$scope.userBalance.userId) {
                    $scope.userBalance.userId = $scope.userBalance.user.userId;
                }
            }

            userService.getUsersAsDropdownByFilter({contractorIds: [$rootScope.authData.contractorId]}).then((users) => {
                $scope.users = _.orderBy(users, ['displayName']);

                if (currentFormType === formTypes.edit) {
                    $scope.selectedUser = _.find($scope.users, { userId: $scope.userBalance.userId });

                    $scope.getUserBalanceForDay();
                }

                $scope.usersDropdownOptions.dataSource.read();
            });
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                $scope.userBalance.userId = $scope.selectedUser ? $scope.selectedUser.userId : null;

                if (currentFormType === formTypes.edit) {
                    timeBankService
                        .updateUserBalance($scope.userBalance)
                        .then((result) => {
                            result.user = $scope.selectedUser;
                            close(result);
                        })
                        .catch(() => close());
                }

                if (currentFormType === formTypes.add) {
                    timeBankService
                        .createUserBalance($scope.userBalance)
                        .then((result) => {
                            result.user = $scope.selectedUser;
                            close(result);
                        })
                        .catch(() => close());
                }
            }
        }

        function close(param) {
            $windowInstance.close(param);
        }

        $scope.getUserBalanceForDay = function() {
            if ($scope.selectedUser && $scope.userBalance.balanceDate) {
                timeBankService
                    .getUserBalanceForUserDay({
                        contractorId: filter.contractorId,
                        departmentIds: filter.departmentIds,
                        userIds: [$scope.selectedUser.userId],
                        fromDate: filter.fromDate,
                        toDate: moment($scope.userBalance.balanceDate).format(),
                    })
                    .then((result) => {
                        // correction for not adding the current balance twice in the html during edit
                        if (currentFormType === formTypes.edit) {
                            result = result - $scope.userBalance.balance;
                        }

                        $scope.balanceOnDay = result;
                    });
            }
        }
    }
})();
