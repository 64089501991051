angular.module('NaviaqWebApp').controller('OperationLogsReportController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'anonymousReportService',
    'commonUtility',
    function (
        $rootScope,
        $scope,
        $state,
        $stateParams,
        anonymousReportService,
        commonUtility) {
        'use strict';

        $scope.contractorId = $stateParams.ContractorId ? $stateParams.ContractorId.toLowerCase() : null;
        $scope.orderId = $stateParams.OrderId;
        $scope.reportFolder = $stateParams.ReportFolder;
        $scope.vessels = [];
        $scope.logs = [];
        $scope.reportImageUrl = '';
        $scope.extendLog = extendLog;
        $scope.goBack = goBack;
        $scope.setReportLogo = setReportLogo;
        $scope.reportData = {};
        $scope.printReport = printReport;
        $scope.orderHasDepartment = false;

        initController()
        function initController(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'operationLogReportLoad',
                destination: '#operation-logs-report-container',
                overlay: true,
            });

            anonymousReportService.getOperationLogReportContent($scope.orderId, $scope.contractorId).then(function(data){
                setReportLogo();
                // departments
                $scope.vessels = data.departments;
                // operationLog
                var logs = data.operationLogs

                for (var i = 0; i < logs.length; i++) {
                    extendLog(logs[i]);
                }
                $scope.logs = _.sortBy(_.filter(logs, function (l) { return !l.logAction.isPrivate; }), (log ) => {return log.doneOn}) ;

                // order-info data
                if (data.order && data.order.contactPersons && data.order.contactPersons.length) {
                    data.order.contactPersonNames =  data.order.contactPersons.map(c => c.firstName + " " + c.lastName).join(', ');
                } else {
                    data.order.contactPersonNames = '';
                }

                $scope.reportData.order = data.order;
                $scope.reportData.order.orderDate = moment($scope.reportData.order.orderDate).format('DD.MM.YYYY');
                $scope.reportData.order.expireDate = $scope.reportData.order.expireDate ? moment($scope.reportData.order.expireDate).format('DD.MM.YYYY') : '';
                $scope.reportData.order.orderedDate = $scope.reportData.order.orderedDate ? moment($scope.reportData.order.orderedDate).format('DD.MM.YYYY') : '';
                $scope.reportData.order.projectManagerName = $scope.reportData.order.projectManagerObject ? $scope.reportData.order.projectManagerObject.displayName : '';
                $scope.orderHasDepartment = $scope.reportData.order.orderDepartments && $scope.reportData.order.orderDepartments.length;
            }).finally(function(){
                $rootScope.$broadcast('hideBusyIndicator', 'operationLogReportLoad');
            });
        }

        function goBack() {
            $state.go('main.orderReport', {
                ContractorId: $scope.contractorId,
                OrderId: $scope.orderId
            });
        }

        function setReportLogo() {
            $scope.reportImageUrl = '../img/reports/' + $scope.reportFolder + '/Images/Logo-width-1200.png';
            commonUtility.isImage($scope.reportImageUrl).then(function (result) {
                if (!result) {
                    $scope.reportFolder = 'NAVIAQ';
                    $scope.reportImageUrl = '../img/reports/' + $scope.reportFolder + '/Images/Logo-width-1200.png';
                }
            });

        }
        function extendLog(log) {
            var doneOn = moment(log.doneOn);
            doneOn = doneOn.hours(doneOn.hours());
            log.doneOn = doneOn.toDate();

            var dueDate = log.dueDate ? moment(log.dueDate) : moment(log.doneOn);
            dueDate = dueDate.hours(dueDate.hours());
            log.dueDate = dueDate.toDate();

            log.hasChecklist = log.logAction && log.logAction.checkListItems && log.logAction.checkListItems.length > 0;
            log.hasMissingChecklistItem = false;

            if (log.hasChecklist && log.logAction.checkListItems) {
                for (var i = 0; i < log.logAction.checkListItems.length; ++i) {
                    log.hasMissingChecklistItem = log.status !== 3;
                    if(log.logAction.checkListItems[i].checkListValues.length){
                        log.logAction.checkListItems[i].checkListValue = log.logAction.checkListItems[i].checkListValues.find(v => v.operationLogId == log.operationLogId);
                        log.hasCheckListValues = true;
                    }
                }
            }

            log.assignedUserDisplayName = log.assignedUser ? log.assignedUser.displayName : '';

            log.vesselName = log.departmentId ? log.departmentId.asVesselName() : '';
            log.logActionName = log.logAction ? log.logAction.description : '';
            log.itemDescription = log.item ? log.item.description : '';

            log.orderDisplayName = log.order != null ? log.order.externalOrderId + '-' + (log.order.location != null ? log.order.location.locationName : '') : '';
        }

        String.prototype.asVesselName = function () {
            var searchedId = this,
                vessel = _.find($scope.vessels, function (o) { return o.departmentId === searchedId; });

            if (vessel) {
                return vessel.name;
            }

            return this;
        };

        function printReport() {
            $('#main-view').addClass('print-view');
            $('#operation-logs-report-container').addClass('print-view-container');
            document.title = "Oppdragslogg -" + kendo.toString(new Date(), 'HH:mm dd.MM.yyyy');
            window.print();

            setTimeout(function(){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'OperationLogReportIndicator',
                    destination: '#main-view',
                    overlay: true
                });

                document.title = "Naviaq Webinnsyn";
                $('#main-view').removeClass('print-view');
                $('#operation-logs-report-container').removeClass('print-view-container');
                $rootScope.$broadcast('hideBusyIndicator', 'OperationLogReportIndicator');
            }, 1);
        }

    }]);
