(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentDetailsModalController', EquipmentDetailsModalController);

    EquipmentDetailsModalController.$inject = ['$rootScope', '$scope', '$q', '$stateParams', '$windowInstance', '$translate', 'itemTreeService',
        'userService', 'contractorService', 'formTypes', 'formType', 'departmentId', 'selectedCategory',
        'itemCategories', 'equipment', 'mediaService'];

    function EquipmentDetailsModalController($rootScope, $scope, $q, $stateParams, $windowInstance, $translate, itemTreeService,
        userService, contractorService, formTypes, formType, departmentId, selectedCategory,
        itemCategories, equipment, mediaService) {

        $scope.equipment = null;
        $scope.name = '';
        $scope.formType = formType;
        $scope.selectedItemCategory = selectedCategory;

        $scope.uploadedFileId = null;
        $scope.uploadedFileName = '';
        $scope.isFileRemoved = false;
        $scope.isFileExist = false;
        $scope.disabledCritical = !($rootScope.hasPermission('EquipmentHub_SetCritical') || $rootScope.authData.isSuperuser);
        $scope.fileArray = [];
        $scope.users = [];

        $scope.equipmentDocUploaderId = 'equipmentDocUploader';
        $scope.documents = [];

        $scope.manualValidationSuccess = true;
        $scope.itemValidityIntervalTypeValues = getItemValidityIntervalTypeValues;
        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;
        $scope.validToChanged = validToChanged;

        $scope.itemDocumentOptions = {
            multiple: false
        };

        $scope.itemCategoryOptions = {
            dataSource: new kendo.data.DataSource({
                data: itemCategories
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            optionLabel: $translate.instant('EQUIPMENT_MODAL_SELECT_CATEGORY')
        };

        $scope.itemParentOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readParentItems
                }
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            optionLabel: ' '
        };

        $scope.itemIntervalTypeOptions = {
            dataSource: new kendo.data.DataSource({
                data: $scope.itemValidityIntervalTypeValues
            }),
            dataValueField: 'value',
            dataTextField: 'text',
            optionLabel: $translate.instant('G_SELECT')
        };

        $scope.itemWarningUserOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success(_.sortBy($scope.users, 'displayName'))
                }
            }),
            dataValueField: 'userId',
            dataTextField: 'displayName',
            optionLabel: $translate.instant('EQUIPMENT_MODAL_SELECT_USER')
        };

        $scope.$watch('selectedItemCategory', function () {
            if ($scope.selectedItemCategory) {
                $scope.departmentId = $scope.selectedItemCategory.id;
                if ($scope.itemParents) {
                    $scope.itemParents.dataSource.read();
                    $scope.itemParents.refresh();
                }
            }
        });

        initController();

        function initController() {
            $q.all({
                getDefaultWarningDays: contractorService.getDefaultWarningDays($scope.authData.contractorId),
                users: userService.getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId]})
            })
                .then(data => {
                    if (data && data.getDefaultWarningDays) {
                        $scope.defaultWarningDays = data.getDefaultWarningDays;
                    }

                    $scope.users = data.users;
                    $scope.itemWarningUserOptions.dataSource.read();

                    if (formType === formTypes.edit && equipment) {
                        initModal(equipment);
                    }
                })
                .catch(error => console.error(error));
        }

        function initModal(equipment) {
            $scope.equipment = equipment;
            $scope.name = equipment.name;
            $scope.equipmentComment = equipment.comment;
            $scope.description = equipment.description;
            $scope.estimatedUsageTime = equipment.estimatedUsageTime;
            $scope.url = equipment.url;
            $scope.criticalDocument = equipment.isCritical;

            //Category selector
            $scope.itemCategory.select(function (category) {
                return category.id === equipment.categoryId;
            });

            if (equipment.designation) {
                $scope.model = equipment.designation.modelNumber;
                $scope.serialNumber = equipment.designation.serialNumber;
            }

            if (equipment.validityInterval) {
                $scope.updateElapsedByParent = equipment.validityInterval.updateElapsedByParent;
                $scope.intervalElapsed = equipment.validityInterval.intervalElapsed;
                if (equipment.validityInterval.warningUserId) {
                    $scope.selectedItemWarningUser = _.find($scope.users, { userId: equipment.validityInterval.warningUserId });
                }
                if (equipment.validityInterval.validTo) {
                    $scope.validTo = moment(equipment.validityInterval.validTo, 'DD.MM.YYYY');
                }

                if (equipment.validityInterval.warningTime) {
                    $scope.warningTime = kendo.parseDate(equipment.validityInterval.warningTime);
                }

                for (var i in $scope.itemValidityIntervalTypeValues) {
                    if ($scope.itemValidityIntervalTypeValues[i].value === equipment.validityInterval.intervalType) {
                        $scope.selectedItemIntervalType = $scope.itemValidityIntervalTypeValues[i];
                    }
                }
            }

            //ItemDetails
            if (equipment.itemDetails) {
                var detailObject = JSON.parse(equipment.itemDetails);
                for (var prop in detailObject) {
                    if (detailObject.hasOwnProperty(prop)) {
                        $scope[prop] = detailObject[prop];
                    }
                }
            }

            $scope.documents = $scope.equipment.files.filter(d => !d.naviaqMediaId).map((f) => {
                return {
                    id: f.id,
                    fileName: f.fileName,
                    url: mediaService.createMediaUrlMediaBank(f.mediaId),
                    mediaId: f.mediaId,
                    base64Content:  null,
                    createdOn: f.createdOn,
                    description: f.description,
                    selectedComponentId: null,
                    displayText: null,
                    FileType: 0
                }
            });
        }

        function closeModal() {
            $windowInstance.close();
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                if (!manualValidation()) {
                    return;
                }
                var categoryId = null;
                if ($scope.selectedItemCategory && $scope.selectedItemCategory !== null) {
                    categoryId = $scope.selectedItemCategory.id
                }
                if (formType === formTypes.add) {
                    $scope.equipment = {
                        Name: $scope.name,
                        Description: $scope.description,
                        EstimatedUsageTime: $scope.estimatedUsageTime,
                        Comment: $scope.equipmentComment,
                        CategoryId: categoryId,
                        ContractorId: $scope.authData.contractorId,
                        RelatedEntityId: $stateParams.DepartmentId,
                        ParentId: ($scope.selectedParentItem && $scope.selectedParentItem.id) ? $scope.selectedParentItem.id : null,
                        Url: $scope.url,
                        IsCritical: $scope.criticalDocument,
                        ItemDetails: JSON.stringify({
                            Location: $scope.Location,
                            SWL: $scope.SWL,
                            Weight: $scope.Weight
                        }),
                        Designation: {
                            ModelNumber: $scope.model,
                            SerialNumber: $scope.serialNumber
                        },
                        ValidityInterval: {
                            UpdateElapsedByParent: $scope.updateElapsedByParent,
                            IntervalBase: $scope.intervalBase,
                            IntervalElapsed: $scope.intervalElapsed,
                            WarningTime: $scope.warningTime != null && $scope.selectedItemWarningUser != undefined ? calculateWarningTime() : null,
                            ValidTo: $scope.validTo ? moment($scope.validTo, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') : undefined,
                            IntervalType: $scope.selectedItemIntervalType ? $scope.selectedItemIntervalType.value : undefined,
                            WarningUserId: $scope.selectedItemWarningUser && $scope.selectedItemWarningUser.userId !== "" ? $scope.selectedItemWarningUser.userId : null,
                            WarnUser: $scope.selectedItemWarningUser && $scope.selectedItemWarningUser.userId !== ""
                        }
                    };

                    itemTreeService.addItem($scope.equipment)
                    .then((itemId) => {
                        $rootScope.$broadcast(`${$scope.equipmentDocUploaderId}Broadcast`, itemId, $scope.authData.contractorId, itemId);
                    })
                    .finally(() => {
                        $windowInstance.close(true);
                    });
                } else {
                    $scope.equipment = {
                        Id: equipment.id,
                        Name: $scope.name,
                        Description: $scope.description,
                        EstimatedUsageTime: $scope.estimatedUsageTime,
                        Comment: $scope.equipmentComment,
                        CategoryId: categoryId,
                        RelatedEntityId: $stateParams.DepartmentId,
                        ParentId: ($scope.selectedParentItem && $scope.selectedParentItem.id) ? $scope.selectedParentItem.id : null,
                        Url: $scope.url,
                        IsCritical: $scope.criticalDocument,
                        ItemDetails: JSON.stringify({
                            Location: $scope.Location,
                            SWL: $scope.SWL,
                            Weight: $scope.Weight
                        }),
                        Designation: {
                            ModelNumber: $scope.model,
                            SerialNumber: $scope.serialNumber
                        },
                        ValidityInterval: {
                            UpdateElapsedByParent: $scope.updateElapsedByParent,
                            IntervalElapsed: $scope.intervalElapsed,
                            WarningTime: $scope.warningTime != null && $scope.selectedItemWarningUser != undefined ? calculateWarningTime() : null,
                            ValidTo: $scope.validTo ? moment($scope.validTo, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00') : undefined,
                            IntervalType: $scope.selectedItemIntervalType ? $scope.selectedItemIntervalType.value : undefined,
                            WarningUserId: $scope.selectedItemWarningUser && $scope.selectedItemWarningUser.userId !== "" ? $scope.selectedItemWarningUser.userId : null,
                            WarnUser: $scope.selectedItemWarningUser && $scope.selectedItemWarningUser.userId !== ""
                        }
                    };

                    itemTreeService.updateItemWithChildren($scope.equipment)
                    .then((itemId) => {
                        $rootScope.$broadcast(`${$scope.equipmentDocUploaderId}Broadcast`, itemId, $scope.authData.contractorId, itemId);
                    })
                    .finally(() => {
                        $windowInstance.close(true);
                    });
                }
            }
        }

        function manualValidation() {
            if($scope.warningTime && $scope.selectedItemWarningUser){
                $scope.manualValidationSuccess = calculateWarningTime() >= 0;
                return $scope.manualValidationSuccess;
            } else {
                return true;
            }
        }

        //Private functions
        function getItemValidityIntervalTypeValues() {
            return itemTreeService.getItemValidityIntervalTypeValues();
        }

        function calculateWarningTime() {
            return Math.round((($scope.validTo ? moment($scope.validTo, 'DD.MM.YYYY') : 0) - ($scope.warningTime ? $scope.warningTime : 0)) / (1000 * 60 * 60 * 24));
        }

        function readParentItems(e) {
            itemTreeService.getItemByCategoryId($scope.selectedItemCategory.id, $scope.authData.contractorId, departmentId).then(function (items) {
                items = _.filter(items, function (item) {
                    return item.categoryId === $scope.selectedItemCategory.id;
                });

                if (equipment) {
                    //Filter current item and child item out
                    items = _.filter(items, function (item) {
                        return !checkItemIsChild(items, item);
                    });

                    if (formType === formTypes.edit) {
                        $scope.selectedParentItem = _.find(items, { id: equipment.parentId });
                    }
                }

                e.success(_.sortBy(items, 'description'));
            }, function () {
                e.success([]);
            });
        }

        function checkItemIsChild(items, item){
            if(item.id == equipment.id){
                return true;
            }

            if(item.id != equipment.id && item.parentId == equipment.id){
                return true;
            }

            if(item.parentId){
                var parentItem = items.find(i => i.id == item.parentId);
                 return checkItemIsChild(items, parentItem);
            } else {
                return false;
            }
        }

        function validToChanged() {
            $scope.warningTime = kendo.parseDate(moment($scope.validTo, 'DD.MM.YYYY').subtract($scope.defaultWarningDays, 'd').format());
        }
    }
})();
