(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ArrivalReportController', ArrivalReportController);

    ArrivalReportController.$inject = ['$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$q',
    'dateUtility',
    '$translate',
    '$http',
    'mediaService',
    'anonymousReportService'
];

    function ArrivalReportController($rootScope,
        $scope,
        $state,
        $stateParams,
        $q,
        dateUtility,
        $translate,
        $http,
        mediaService,
        anonymousReportService
        ) {

        $scope.printReport = printReport;
        $scope.reportId = $stateParams.ReportId;
        $scope.orderId = $stateParams.OrderId;
        $scope.contractorId = '';
        $scope.reportLogoImageUrl = '';
        $scope.reportFooterImageUrl = '';
        $scope.signatureImg = null;
        $scope.vetMediaId = null;
        $scope.signatureErrorMsg = true;

        $scope.hygieneMeasures = [
            {
                text: "Dobbel desinfeksjon",//$translate.instant('ARRIVAL_HYGIENE_OPTION_DOUBLE_DISINFECTION'),
                value: 'dobbelDesinf',
                checked: false
            },
            {
                text: "Dobbel vask og desinfeksjon", //$translate.instant('ARRIVAL_HYGIENE_OPTION_DOUBLE_DISINFECTION_AND_WASHING'),
                value: 'dobbelVaskOgDesinfeksjon',
                checked: false
            },
            {
                text: "Karantene 24 timer", //$translate.instant('ARRIVAL_HYGIENE_OPTION_QUARANTINE_24_H'),
                value: 'karantene24Timer',
                checked: false
            },
            {
                text: "Karantene 48 timer", //$translate.instant('ARRIVAL_HYGIENE_OPTION_QUARANTINE_48_H'),
                value: 'karantene48Timer',
                checked: false
            },
            {
                text: "Karantene 24 timer Dykkerdrakter", //$translate.instant('ARRIVAL_HYGIENE_OPTION_QUARANTINE_24_H_DIVING_SUITS'),
                value: 'karanten24TimerDykkerdrakter',
                checked: false
            },
            {
                text: "Hygieneinspeksjon (Se vedlegg)", //$translate.instant('ARRIVAL_HYGIENE_OPTION_HYGIENE_INSPECTION'),
                value: 'hygieneinspeksjon',
                checked: false
            },
            {
                text:  $translate.instant('ARRIVAL_HYGIENE_OPTION_DRYDOCKING'),
                value: 'slipsetting',
                checked: false
            },
            {
                text: "Defloat", // $translate.instant('ARRIVAL_HYGIENE_OPTION_DEFLOAT'),
                value: 'defloat',
                checked: false
            }
        ];

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#arrival-report-container').addClass('print-view-container');
        }


        initController();
        function initController() {

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'arrivalReportIndicator',
                destination: '#main-view',
                overlay: true
            });

            var requests = {
                getArrivalReportContent: anonymousReportService.getArrivalReportContent($scope.orderId, $scope.reportId),
            }

            $q.all(requests).then(function(result){
                $scope.arrivalReport = result.getArrivalReportContent.arrivalReport;
                $scope.contractorId = result.getArrivalReportContent.order ? result.getArrivalReportContent.order.contractorId : null;
                $scope.order = result.getArrivalReportContent.order;

                if(result.getArrivalReportContent.signature){
                    $scope.signatureImg = mediaService.createMediaUrlMediaBank(result.getArrivalReportContent.signature.mediaId);
                    $scope.vetMediaId = result.getArrivalReportContent.signature.mediaId;
                    $scope.signatureErrorMsg = false;
                }

                $scope.reportLogoImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-${$scope.contractorId}.png`;
                $http.get($scope.reportLogoImageUrl, {headers: {'Anonymous' : ''}, responseType: 'blob'})
                    .then(response =>
                        {
                            $scope.reportLogoImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-${$scope.contractorId}.png`;
                        })
                    .catch(error => {
                        $scope.reportLogoImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-e083f410-67ab-e611-a3b0-f23cffe3d187.png`; // default naviaq image
                    })

                $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer-${$scope.contractorId}.png`;
                $http.get($scope.reportFooterImageUrl, {headers: {'Anonymous' : ''}, responseType: 'blob'})
                    .then(response =>
                        {
                            $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer-${$scope.contractorId}.png`;
                        })
                    .catch(error => {
                        $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer.png`; //default footer
                    })

                initReport($scope.arrivalReport);
                $rootScope.$broadcast('hideBusyIndicator', 'arrivalReportIndicator');
            });
        }

        function initReport(arrivalReport)
        {
            if (arrivalReport) {
                var arrivalReportData = JSON.parse(arrivalReport.arrivalReportData);
                $scope.arrivalReport = {
                    departmentName: arrivalReport.department ? ' - ' + arrivalReport.department.name : '',
                    startDate: $scope.order.orderDate ? $scope.order.orderDate : '',
                    lastCustomer : arrivalReportData.lastCustomer,
                    lastLocation : arrivalReportData.lastLocation,
                    previousJobType : arrivalReportData.previousJobType,
                    previousOrderDate: dateUtility.formatDate(arrivalReportData.previousOrderDate),
                    leftLocation : arrivalReportData.selectedOrderId ? (arrivalReportData.leftLocation ? arrivalReportData.leftLocation.replaceAll('/','.') : '-') : moment(arrivalReportData.leftLocation).format('DD.MM.YYYY'),
                    previousDiseaseStatus : arrivalReportData.previousDiseaseStatus,
                    leftComment : arrivalReportData.leftComment,
                    previousGenerations : arrivalReportData.previousGenerations,
                    previousProductionArea : arrivalReportData.previousProductionArea,
                    previousLocationInPDAndILA : arrivalReportData.previousLocationInPDAndILA,
                    otherThingsAffectingSecurity : arrivalReportData.otherThingsAffectingSecurity,

                    boatAndEquipmentCleaned : arrivalReportData.boatAndEquipmentCleaned,
                    hygieneMeasures: arrivalReportData.hygieneMeasures,
                    dateOfLastSlipSeal: arrivalReportData.dateOfLastSlipSeal ? dateUtility.formatDateTime(arrivalReportData.dateOfLastSlipSeal.replaceAll('/','.')) : '-',
                    dateOfLastDefloat: arrivalReportData.dateOfLastDefloat ? dateUtility.formatDateTime(arrivalReportData.dateOfLastDefloat.replaceAll('/','.')) : '-',
                    dateOfLastHygieneCheck: arrivalReportData.dateOfLastHygieneCheck ? dateUtility.formatDateTime(arrivalReportData.dateOfLastHygieneCheck.replaceAll('/','.')) : '-',
                    comment: arrivalReportData.comment,

                    customer : arrivalReportData.customer,
                    location : arrivalReportData.location,
                    actualDiseaseStatus : arrivalReportData.actualDiseaseStatus,
                    actualComment : arrivalReportData.actualComment,
                    actualGenerations : arrivalReportData.actualGenerations,
                    actualProductionArea : arrivalReportData.actualProductionArea,
                    actualLocationInPDAndILA : arrivalReportData.actualLocationInPDAndILA,
                    orderType : arrivalReportData.orderType,
                    newLocatoionOtherThingsAffectingSecurity : arrivalReportData.newLocatoionOtherThingsAffectingSecurity,
                };

                if(arrivalReportData.selectedOrderId){
                    if($scope.arrivalReport.previousOrderDate){
                        $scope.previousOrderTitle = $scope.arrivalReport.previousOrderDate + ' - ' + $scope.arrivalReport.lastCustomer +
                        ' - ' + $scope.arrivalReport.lastLocation;
                    } else
                    {
                        $scope.previousOrderTitle = $scope.arrivalReport.lastCustomer + ' - ' + $scope.arrivalReport.lastLocation;
                    }
                } else {
                    $scope.previousOrderTitle = $translate.instant('ARRIVAL_NO_PREVIOUS_ORDER');
                    $scope.selectedHasNoPreviousOption = true;
                    if(!arrivalReportData.selectedLocation){
                        $scope.selectedNotFromFacilityOption = true;
                    }
                }

                // hygieneMeasures
                if(arrivalReportData.hygieneMeasures && arrivalReportData.hygieneMeasures.length > 0)
                {
                    var selectedHygieneItems = arrivalReportData.hygieneMeasures.map(x => x.value);
                    _.forEach($scope.hygieneMeasures, function (hygieneItem) {
                        if(selectedHygieneItems.includes(hygieneItem.value)){
                            hygieneItem.checked = true;
                        } else {
                            hygieneItem.checked = false;
                        }
                    });
                } else {
                    _.forEach($scope.hygieneMeasures, function (hygieneItem) {
                        hygieneItem.checked = false;
                    });
                }
            }
        }

        function printReport() {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#arrival-report-container').addClass('print-view-container');
            document.title = "Arrival-report-" + kendo.toString(new Date(), 'HH:mm dd.MM.yyyy');
            window.print();

            setTimeout(function(){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'arrivalReportIndicator',
                    destination: '#main-view',
                    overlay: true
                });

                $rootScope.showNavbar = true;
                $scope.printView = false;
                document.title = "Naviaq Webinnsyn";
                $('#main-view').removeClass('print-view');
                $('#arrival-report-container').removeClass('print-view-container');
                $rootScope.$broadcast('hideBusyIndicator', 'arrivalReportIndicator');
            }, 1);
        }
    }
})();
