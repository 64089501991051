angular.module('NaviaqWebApp').directive('orderLines', [function () {
    'use strict';

    return {
        restrict: 'E',
        replace: true,
        scope: {
            orderLines: '=',
            hideOrderlinesColumn: '='
        },
        templateUrl: 'app/report/directives/order-lines.html',
        link: function (scope, element, attrs) {
        }
    };
}]);
