(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('landBaseService', landBaseService);

    landBaseService.$inject = ['$q', '$http', 'serviceUrls'];

    function landBaseService($q, $http, serviceUrls) {
        const landBaseApiUrl = `${serviceUrls.webApiBaseUrl}/location`;

        return {
            getLandBasesByContractorId,
            updateLandBase,
            AddLandBase
        };

        function getLandBasesByContractorId(contractorId) {
            const deferred = $q.defer();
            const url = `${landBaseApiUrl}/landbase?contractorId=${contractorId}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }        

        function AddLandBase(landBase) {
            const deferred = $q.defer(),
            requestUrl = landBaseApiUrl + '/landbase';
            $http
                .post(requestUrl, landBase)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }       

        function updateLandBase(landBase) {
            const deferred = $q.defer(),
            requestUrl = landBaseApiUrl + '/landbase';
            $http
                .put(requestUrl, landBase)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }       
    }
})();
