(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('moveUnitsBatchModalController', moveUnitsBatchModalController);

    moveUnitsBatchModalController.$inject = [
        '$translate',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'units',
        'selectedUnitsbatches',
        'unitService',
        'unitId'
    ];

    function moveUnitsBatchModalController(
        $translate,
        $rootScope,
        $scope,
        $windowInstance,
        units,
        selectedUnitsbatches,
        unitService,
        unitId
    ) {

        $scope.unitOptions = {
            dataSource: new kendo.data.DataSource({
                data: units
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            optionLabel: $translate.instant('ADMIN_CUSTOMERS_SELECT_UNIT')
        };

        initController();
        function initController() {
            $scope.selectedUnit = units.find(u => u.id == unitId);
        }

        $scope.validateForm = function(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'unitDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                if($scope.selectedUnit.id != unitId){
                    unitService.moveUnitBatches($scope.selectedUnit.id, selectedUnitsbatches).then(function(unitId){
                        $windowInstance.close(unitId);
                    })
                    .finally(function(){
                        $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
                    });
                } else {
                    $windowInstance.close();
                    $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
                }

            }
        }

        $scope.close = function(){
            $windowInstance.close();
        }
    }
})();
