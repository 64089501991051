(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('workContractService', workContractService);

        workContractService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function workContractService(
        $q,
        $http,
        serviceUrls
    ) {
        var workContractBaseApiUrl = `${serviceUrls.webApiBaseUrl}/workContract`;

        return {
            getWorkContractById
        };

        function getWorkContractById(workContractId) {
            const deferred = $q.defer();

            var requestUrl = `${workContractBaseApiUrl}?workContractId=${workContractId}`;
            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
