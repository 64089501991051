(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('VesselOverviewController', VesselOverviewController);

    VesselOverviewController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$translate',
        'mediaService',
        'vesselSettings',
        'authService',
        'kendoUtility',
        'departmentService',
        'personService',
        'treeUtility',
        'dateUtility',
        'orderService',
        'timeBankService',
        'logService',
        'vesselDashboardService',
        'timeFilterService',
        'itemTreeService'
    ];

    function VesselOverviewController(
        $q,
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $translate,
        mediaService,
        vesselSettings,
        authService,
        kendoUtility,
        departmentService,
        personService,
        treeUtility,
        dateUtility,
        orderService,
        timeBankService,
        logService,
        vesselDashboardService,
        timeFilterService,
        itemTreeService
    ) {
        $scope.vesselName = '';
        $scope.vessel = null;

        $scope.showTaskCount = true;
        $scope.allWorkOrders = [];
        $scope.filteredWorkOrders = [];

        $scope.openPeriodic = 0;
        $scope.openCorrective = 0;
        $scope.openAlarm = 0;
        $scope.openOther = 0;

        $scope.vesselContact = {};
        $scope.departmentInfo = {};
        $scope.hasVesselImage = false;

        $scope.tabsVisibility = {
            info: true,
            personnel: false,
            orders: false,
            equipments: false,
            dashboard: false
        };

        $scope.dashboardFilterClass = {
            "Scheduled": false,
            "Correction": false,
            "Log": false,
            "Other": false
        };

        $scope.defaultDateTo = new Date();
        $scope.defaultDateFrom = new Date();
        $scope.defaultDateFrom.setDate($scope.defaultDateFrom.getDate() - 7);

        $scope.changeOrderTab = changeOrderTab;
        $scope.getBusyTop = getBusyTop;
        $scope.getMediaUrl = getMediaUrl;
        $scope.getOrders = getOrders;
        $scope.toEquipments = toEquipments;
        $scope.selectDashboardTab = selectDashboardTab;
        $scope.getStatusNameFromJobType = getStatusNameFromJobType;
        $scope.editOperationLog = editOperationLog;
        $scope.mapOperationLogEnumToStatus = mapOperationLogEnumToStatus;

        $rootScope.$broadcast('setSplitterSize', {
            left: '50%',
            right: '50%'
        });

        $scope.tabStripOptions = {
            animation: false
        };

        const categoryFilterOptions = langId => ({
            dataTextField: 'name',
            dataValueField: 'id',
            optionLabel: $translate.instant('G_SHOWALL', null, null, langId),
            dataSource: {
                transport: {
                    read: readCategories
                }
            },
            change: onCategoryChanged
        });
        $scope.categoryFilterOptions = categoryFilterOptions($translate.use());

        const statusFilterOptions = langId => ({
            dataTextField: 'text',
            dataValueField: 'key',
            optionLabel: $translate.instant('G_SHOWALL', null, null, langId),
            dataSource: new kendo.data.DataSource({
                data: orderService.getOrderStatusValues()
            })
        });
        $scope.statusFilterOptions = statusFilterOptions($translate.use());

        const vesselPersonnelGridOptions = langId =>  ({
            dataSource: {
                transport: {
                    read: readPersonnel
                }
            },
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            resizable: true,
            filterable: {
                name: "FilterMenu",
                extra: true
            },
            messages: {
                noRecords: $translate.instant('MAIN_VESSEL_DETAILS_PERSONNEL_NORECORDS', null, null, langId)
            },
            noRecords: true,
            columns: [
                {
                    field: 'displayName',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_PERSONNEL_GRID_NAME', null, null, langId)
                }, {
                    field: 'checkInTime',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_PERSONNEL_GRID_SIGNEDON', null, null, langId),
                    type: 'date',
                    format: "{0:dd.MM.yyyy HH:mm}"
                }, {
                    field: 'checkOutTime',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_PERSONNEL_GRID_SIGNEDOFF', null, null, langId),
                    type: 'date',
                    format: "{0:dd.MM.yyyy HH:mm}"
                }]
        });
        $scope.vesselPersonnelGridOptions = vesselPersonnelGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.vesselPersonnelGridOptions = vesselPersonnelGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.vesselPersonnelGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#vesselPersonnelGrid')]);
        });

        const vesselOrderGridOptions = langId => ({
            dataSource: {
                transport: {
                    read: readVesselOrders
                }
            },
            animation: false,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            resizable: true,
            filterable: {
                name: "FilterMenu",
                extra: true
            },
            columns: [
                {
                    field: 'externalOrderId',
                    title: '#'
                }, {
                    field: 'customerName',
                    title: $translate.instant('ORDER_GRID_CUSTOMER', null, null, langId)
                }, {
                    field: 'locationName',
                    title: $translate.instant('ORDER_GRID_LOCATION', null, null, langId)
                }, {
                    field: 'orderProcessName',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_ORDERS_PROCESS', null, null, langId)
                }, {
                    field: 'orderDate',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_ORDERS_STARTDATE', null, null, langId),
                    format: "{0:dd.MM.yyyy HH:mm}",
                    type: 'date'
                }, {
                    field: 'expireDate',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_ORDERS_ENDDATE', null, null, langId),
                    type: 'date',
                    format: "{0:dd.MM.yyyy HH:mm}"
                }, {
                    field: 'orderStatus',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_ORDERS_STATUS', null, null, langId),
                    template: '#: orderStatus != null? orderStatus.asOrderStatus() : ""#',
                    filterable: {
                        multi: true,
                        messages: {
                            checkAll: $translate.instant('G_SHOWALL', null, null, langId),
                            filter: $translate.instant('G_FILTER', null, null, langId),
                            clear: $translate.instant('G_CLEAR', null, null, langId),
                            selectedItemsFormat: ""
                        },
                        itemTemplate: function (e) {
                            if (e.field === "all") {
                                return "<span><input type='checkbox' name='" + e.field + "' value='' /> <label><span>" + $translate.instant('G_SHOWALL', null, null, langId) + "</span></label></span> <br />";
                            }

                            return "<span><input type='checkbox' id='" + e.field + "_#= data.value #'  name='" + e.field + "' value='#= data.value #'/> <label for='" + e.field + "_#= data.value #'><span>#= data.text #</span></label></span> <br />";
                        },
                        dataSource: new kendo.data.DataSource({
                            data: orderService.getOrderStatusValues()
                        })
                    }
                }, {
                    field: 'orderId',
                    filterable: false,
                    sortable: false,
                    title: ' ',
                    width: '110px',
                    template: '<button class="n-button" ng-click="showOrderDetails(dataItem)">' + $translate.instant('MAIN_VESSEL_DETAILS_ORDERS_SHOWASSIGNMENTS', null, null, langId) +'</button>'
                }]
        });

        $scope.vesselOrderGridOptions = vesselOrderGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.vesselOrderGridOptions = vesselOrderGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.vesselOrderGridOptions);
            $scope.statusFilterOptions = statusFilterOptions(code);
            const statusFilter = kendoUtility.createWidget('kendoDropDownList', $scope.statusFilterOptions);
            kendoUtility.changeLangOfWidget(code, [
                () => mainGrid('#vesselOrderGrid'),
                () => statusFilter('#assigment_status')
            ]);
        });

        $scope.showOrderDetails = showOrderDetails;

        const vesselEquipmentGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                data: []
            }),
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            resizable: true,
            filterable: true,
            columns: [
                {
                    field: 'description',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_EQUIPMENTS_GRID_DESCRIPTION', null, null, langId)
                }, {
                    field: 'categoryName',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_EQUIPMENTS_GRID_SUBGROUP', null, null, langId)
                }, {
                    field: 'modelNumber',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_EQUIPMENTS_GRID_MODULNUMBER', null, null, langId)
                }, {
                    field: 'serialNumber',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_EQUIPMENTS_GRID_SERIALNUMBER', null, null, langId)
                }, {
                    field: 'location',
                    title: $translate.instant('MAIN_VESSEL_DETAILS_EQUIPMENTS_GRID_LOCATION', null, null, langId)
                }]
        });


        $scope.vesselEquipmentGridOptions = vesselEquipmentGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.vesselEquipmentGridOptions = vesselEquipmentGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.vesselEquipmentGridOptions);
            $scope.categoryFilterOptions = categoryFilterOptions(code);
            const categoryFilter = kendoUtility.createWidget('kendoDropDownList', $scope.categoryFilterOptions);
            kendoUtility.changeLangOfWidget(code, [
                () => mainGrid('#vesselEquipmentGrid'),
                () => categoryFilter('#categoryFilter')
            ]);
        });

        $scope.activeUsersDate = new Date();

        $scope.oplogFrom = "";
        $scope.oplogTo = "";

        $scope.getOpLog = getOpLog;
        $scope.getOpLogOfToday = getOpLogOfToday;
        $scope.getOpLogOfThisWeek = getOpLogOfThisWeek;
        $scope.getOpLogOfLastWeek = getOpLogOfLastWeek;
        $scope.getPersonell = getPersonell;

        initController();

        function initController() {
            //Initialize controller
            if (typeof $stateParams.mmsi === "undefined") {
                $state.go("main");
                return;
            }

            // runs when tab loaded
            $scope.$watch("vesseltab", function () {
                switch ($state.current.name) {
                    case "main.vesselDetails":
                        $scope.vesseltab.select(0);
                        break;
                    case "main.vesselPersonel":
                        $scope.vesseltab.select(1);
                        break;
                    case "main.vesselOrders":
                        $scope.vesseltab.select(2);
                        break;
                    case "main.vesselEquipment":
                        $scope.vesseltab.select(3);
                        break;
                    case "main.vesselmLink":
                        $scope.vesseltab.select(4);
                        break;
                }
            });

            setDashboardLoading();

            // load the passed vessel and zoom to it
            departmentService.getVesselLocations(authService.getAuthData.contractorId)
                .then(initializeVessel)
                .then(function (department) {
                    $scope.categoryFilter.dataSource.read();
                })
                .then(function () {
                    getDashboardOrders();
                    selectDashboardTab("Scheduled");
                });

            // Personell
            $("#active-users-date").val(kendo.toString(new Date(), "dd.MM.yyyy"));

            // load default form values
            loadOrderDefaults();
            loadLogDefaults();
        }

        function changeOrderTab(orderType) {
            $scope.showTaskCount = orderType === 'periodic';

            $scope.filteredWorkOrders = _.filter($scope.allWorkOrders, { type: orderType, status: 'open' }).concat(
                _.filter($scope.allWorkOrders, { type: orderType, status: 'closed' })
            );
        }

        function mapOperationLogEnumToStatus(statusId) {
            return vesselDashboardService.mapOperationLogEnumToStatus(statusId);
        }

        function selectDashboardTab(filterOption) {
            if (typeof ($scope.dashboardFilterClass[filterOption]) === "undefined") {
                return;
            }

            $scope.dashboardFilterClass[filterOption] = "active";

            // unset others
            for (var cls in $scope.dashboardFilterClass) {
                if (cls !== filterOption) {
                    $scope.dashboardFilterClass[cls] = "";
                }
            }
        }

        function getStatusNameFromJobType(jobType) {
            return vesselDashboardService.getStatusNameFromJobType(jobType);
        }

        function editOperationLog(log) {
            return vesselDashboardService.editOperationLog(log);
        }

        function setDashboardLoading() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'vesselOplogDashboardIndicator',
                destination: '#right-pane',
                overlay: true
            });
        }

        function getDashboardOrders(fromDate, toDate) {
            if (typeof ($scope.vessel) == "undefined" ||
                $scope.vessel == null) {
                return;
            }

            var filterData = {
                contractorId: $scope.authData.contractorId,
                departmentIds: [$scope.vessel.departmentId],
                fromDate: fromDate || $scope.defaultDateFrom,
                toDate: toDate || $scope.defaultDateTo
            };

            logService.getLogActions($scope.authData.contractorId).then(function (logActions) {

                logService.getOperationLogsByFilterWebAPI(filterData).then(function (allOperationLogs) {
                    $scope.allOperationLogs = _.sortBy(allOperationLogs, function (log) {
                        return new Date(log.createdOn);
                    });

                    _.forEach($scope.allOperationLogs, function (log) {
                        log.icon = vesselDashboardService.getIcon(log);
                        log.dueMessage = vesselDashboardService.getDueDate(log);
                        log = vesselDashboardService.calculateOperationLogTasks(log, logActions);
                    });

                    $scope.openPeriodic = _.filter($scope.allOperationLogs, { logAction: { jobType: vesselDashboardService.getJobTypeFromStatusName("Scheduled") } }).length;
                    $scope.openCorrective = _.filter($scope.allOperationLogs, { logAction: { jobType: vesselDashboardService.getJobTypeFromStatusName("Correction") } }).length;
                    $scope.openAlarm = _.filter($scope.allOperationLogs, { logAction: { jobType: vesselDashboardService.getJobTypeFromStatusName("Log") } }).length;
                    $scope.openOther = $scope.allOperationLogs.length - ($scope.openPeriodic + $scope.openCorrective + $scope.openAlarm);

                    $scope.filteredWorkOrders = $scope.allOperationLogs;

                    $rootScope.$broadcast('hideBusyIndicator', 'vesselOplogDashboardIndicator');
                });
            });
        }


        function getBusyTop() {
            var top = "";
            if ($scope.vesselOrderGrid && $scope.vesselOrderGrid.element && $scope.vesselOrderGrid.element[0]) {
                var rect = $scope.vesselOrderGrid.element[0].getBoundingClientRect(),
                    rtop = rect.top,
                    height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
                top = Math.round((height - rtop) / 2) + "px";
            } else {
                top = '80px';
            }

            return top;
        }

        function getMediaUrl(mid) {
            return mediaService.createMediaUrlMediaBank(mid);
        }

        function getFilter() {
            var from = $scope.assigmentDateFrom,
                to = $scope.assigmentDateTo,
                status = $scope.assigment_status.value(),
                requestedStatuses = [];

            if (!$scope.authData.isAuth) {
                return {};
            }

            if (status === "") {
                var statuses = orderService.getOrderStatusValues();
                requestedStatuses = _.map(statuses, function (o) { return o.key; });
            } else {
                requestedStatuses.push(status);
            }

            return {
                contractorId: $scope.authData.contractorId,
                departmentId: $scope.departmentInfo.departmentId,
                orderStatuses: requestedStatuses,
                fromDate: dateUtility.toJson(from),
                toDate: dateUtility.toJson(moment(to).add(1, 'd').startOf('d').add(-1, 's'))
            };
        }

        function getOrders() {
            this.vesselOrderGrid.dataSource.read();
        }

        function onCategoryChanged(e) {
            if ($scope.vessel && $scope.vessel.departmentId) {
                if ($scope.selectedCategory && $scope.selectedCategory.id) {
                    itemTreeService.getItemForestByCategoryId($scope.selectedCategory.id, $scope.authData.contractorId, $scope.vessel.departmentId).then(function (items) {
                        items.forEach(item => {
                            item.categoryName = item.category && item.category.name ? item.category.name : '';
                        });
                        $scope.vesselEquipmentGrid.dataSource.data(items);
                    });
                } else {
                    $scope.vesselEquipmentGrid.dataSource.data([]);
                }
            }
        }

        function readCategories(e) {
            if (!$scope.vessel || !$scope.vessel.departmentId) {
                e.success([]);
                return;
            }

            itemTreeService.getItemCategoryTreeByKey(vesselSettings.rootCategoryKey, $scope.authData.contractorId, $scope.vessel.departmentId).then(function (categoryTree) {
                var rootCategoryId = categoryTree.id;
                var categories = treeUtility.convertTreeToList(categoryTree, 'children', 'id');
                var topCategories = _.filter(categories, { parentId: rootCategoryId }).sort(function (a, b) {
                    var aName = a.name.toLowerCase();
                    var bName = b.name.toLowerCase();
                    return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
                });
                if (categories && categories.length > 0) {
                    e.success(topCategories);
                }
            });
        }

        function readPersonnel(e) {
            if (!$scope.vessel || !$scope.vessel.departmentId) {
                e.success([]);
                return;
            }

            var selectedDate = new Date();
            if ($scope.activeUsersDate) {
                selectedDate = $scope.activeUsersDate;
            }

            var vesselCrewFilter = {
                DepartmentId: $scope.vessel.departmentId,
                FromDate: selectedDate,
                ToDate: selectedDate
            }

            timeBankService.getCrewInformation(vesselCrewFilter).then(function (results) {
                var persons = results.data;
                _.forEach(persons, extendAttendance);

                e.success(persons);
            }, function() {
                e.success([]);
            });
        }

        function readVesselOrders(e) {
            if (!$scope.vessel || !$scope.vessel.departmentId) {
                e.success([]);
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'vesselOverviewOrders',
                message: 'Henter data...',
                destination: '#vesselOrderGrid',
                overlay: true,
                positionClass: {
                    top: $scope.getBusyTop(),
                    left: '50%'
                }
            });

            var filter = getFilter();

            orderService.getVesselOrders(filter).then(function (orders) {
                orders = _.filter(orders, function (o) {
                    return o.orderDepartments && o.orderDepartments.length && o.orderDepartments.find(x => x.department.departmentId === $scope.vessel.departmentId)
                });

                orders.forEach(order => {
                    order.customerName = order.customer && order.customer.name ? order.customer.name : '';
                    order.locationName = order.location && order.location.name ? order.location.name : '';
                });

                $rootScope.$broadcast('hideBusyIndicator', 'vesselOverviewOrders');
                e.success(orders);
            });
        }

        function toEquipments() {
            $state.go('equipments', {
                DepartmentId: $scope.vessel.departmentId
            });
        }

        function loadOrderDefaults() {
            var to = new Date(),
                from = new Date();

            from.setMonth(from.getMonth() - 3);

            $scope.assigmentDateFrom = from;
            $scope.assigmentDateTo = to;
        }

        function initializeVessel(vessels) {
            var selectedVessel = _.find(vessels, { mmsi: $stateParams.mmsi }),
                deferred = $q.defer();

            if (selectedVessel) {
                $scope.vessel = selectedVessel;
                $scope.vesselName = selectedVessel.shipname;

                if ($scope.vessel.contractorId) {
                    var authData = authService.getAuthData();

                    if (authData.contractorId === $scope.vessel.contractorId) {
                        $scope.tabsVisibility.personnel = true;
                        $scope.tabsVisibility.orders = true;
                        $scope.tabsVisibility.equipments = true;
                        $scope.tabsVisibility.dashboard = true;
                    } else {
                        $scope.tabsVisibility.personnel = false;
                        $scope.tabsVisibility.orders = false;
                        $scope.tabsVisibility.equipments = false;
                        $scope.tabsVisibility.dashboard = false;
                    }
                }

                // load department details
                departmentService.getAllDepartmentsWebapi().then(function (departments) {
                    var department = _.find(departments, { departmentId: $scope.vessel.departmentId });

                    if (department && department.departmentInfo) {
                        $scope.departmentInfo = department.departmentInfo;
                        $scope.departmentInfo.deliveryDate = $scope.departmentInfo.deliveryDate > 0 ? kendo.toString(new Date(parseInt($scope.departmentInfo.deliveryDate.substr(6))), 'dd.MM.yyyy') : "";
                        $scope.departmentInfo.warrantyExpireDate = $scope.departmentInfo.warrantyExpireDate > 0 ? kendo.toString(new Date(parseInt($scope.departmentInfo.warrantyExpireDate.substr(6))), 'dd.MM.yyyy') : "";
                        $scope.hasVesselImage = department.departmentInfo.defaultMediaId !== null && department.departmentInfo.defaultMediaId.length > 0;

                        $scope.vesselOrderGrid.dataSource.read();

                        if (department.departmentInfo.technicalManagerId) {

                            // load related person objects
                            if (department.contractorId) {
                                personService.getPersonsByContractorId(department.contractorId).then(function (persons) {

                                    var technicalManager = _.find(persons, { personId: department.departmentInfo.technicalManagerId });
                                    if (technicalManager !== null) {
                                        $scope.vesselContact = technicalManager;
                                    }
                                });
                            }
                        }
                    }

                    deferred.resolve(department);
                });

                $rootScope.$broadcast('zoomToLocation', { x: selectedVessel.lon, y: selectedVessel.lat }, selectedVessel);
            }

            return deferred.promise;
        }

        function showOrderDetails(dataItem) {
            $state.go('main.orderReport', {
                ContractorId: dataItem.contractorId,
                OrderId: dataItem.orderId
            });
        }

        function loadLogDefaults() {
            var to = new Date(),
                from = new Date();

            from.setDate(from.getDate() - 14);
            to.setDate(to.getDate() + 14);

            $scope.oplogFrom = kendo.toString(from, "dd.MM.yyyy");
            $scope.oplogTo = kendo.toString(to, "dd.MM.yyyy");
        }

        function getOpLog() {
            setDashboardLoading();
            getDashboardOrders();
        }

        function getOpLogOfToday() {
            setDashboardLoading();
            var currentDate = timeFilterService.getDateOfToday();
            $scope.operationLogDateFrom.value(currentDate);
            $scope.operationLogDateTo.value(currentDate);
            getDashboardOrders(currentDate, currentDate);
        }

        function getOpLogOfThisWeek() {
            setDashboardLoading();
            var from = timeFilterService.getStartDateOfThisWeek();
            var to = timeFilterService.getEndDateOfThisWeek();
            $scope.operationLogDateFrom.value(from);
            $scope.operationLogDateTo.value(to);
            getDashboardOrders(from, to);
        }

        function getOpLogOfLastWeek() {
            setDashboardLoading();
            var from = timeFilterService.getStartDateOfLastWeek();
            var to = timeFilterService.getEndDateOfLastWeek();
            $scope.operationLogDateFrom.value(from);
            $scope.operationLogDateTo.value(to);
            getDashboardOrders(from, to);
        }

        function getPersonell() {
            $scope.vesselPersonnelGrid.dataSource.read();
            $scope.vesselPersonnelGrid.refresh();
        }

        function extendAttendance(attendance) {
            if (attendance.signedOn) {
                attendance.checkInTime = new Date(attendance.signedOn);
            }

            if (attendance.signedOff) {
                attendance.checkOutTime = new Date(attendance.signedOff);
            }
        }

        Number.prototype.asOrderStatus = function () {
            return orderService.getOrderStatusText(this);
        };
    }
})();
