(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('TimeBankLogsController', TimeBankLogsController);

    TimeBankLogsController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$state',
        '$translate',
        'timeBankService',
        'dateUtility',
        '$kWindow',
        'kendoUtility',
        'logActionPairsStatus',
        'userService',
        'confirmDialogService'
    ];

    function TimeBankLogsController(
        $rootScope,
        $scope,
        $q,
        $state,
        $translate,
        timeBankService,
        dateUtility,
        $kWindow,
        kendoUtility,
        logActionPairsStatus,
        userService,
        confirmDialogService
    ) {
        $scope.timeBankLogPairs = [];

        $scope.userTimebankPermissions = getUserPermissions();
        $scope.isSkipperUser = isSkipper();
        $scope.acceptAll = acceptAll;
        $scope.editOperationLogs = editOperationLogs;

        $scope.showRestLogSwitchChanged = showRestLogSwitchChanged;

        $scope.isSuperUser = $rootScope.authData.isSuperuser;

        $scope.hasAdminPermission = Boolean($scope.isSuperUser || $scope.hasPermission('TimebankAdmin'));
        $scope.canApproveLog = $scope.isSuperUser || $scope.userTimebankPermissions.timebankEditApprovedLog || $scope.userTimebankPermissions.timebankAdmin || $scope.userTimebankPermissions.timebankApprove;
        $scope.hasSeagoingServiceModule = $scope.hasModule('SeagoingService');
        


        $scope.$on('timeBankFilterChanged', (event, filter, isUpdate, state) => {
            $scope.filter = filter;
            if(isUpdate && (state == 'logs' || (!state && $state.current.name == 'timeBank.logs'))){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'timeBankLogsBusyIndicator',
                    destination: '#timebank-logs-overview-grid',
                    overlay: true,
                });
                loadFilteredLogs(filter);
            }
        });

        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];

        $scope.$on('langChanged', (e, code) => {
            $scope.timebankLogsOverviewGridOptions = timebankLogsOverviewGridOptions(code);
            var grid = kendoUtility.createWidget('kendoGrid', $scope.timebankLogsOverviewGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#timebank-logs-overview-grid')]);
            langId = code;
        });

        $scope.tooltipOptions = {
            filter: '.k-grid-icon-tooltip',
            content: function (e) {
                return e.target.context.dataset.tooltiptext;
            },
            position: "top",
            showAfter: 500
        };

        var timebankLogsOverviewGridOptions = (langId) => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.timeBankLogPairs),
                },
                schema: {
                    model: {
                        id: 'logActionPairsId',
                        fields: {
                            status: { editable: false, type: "number"}
                        }
                    }
                },
            }),
            columns: [
                {
                    field: 'personName',
                    title: $translate.instant('TIMEBANK_LOG_GRID_PERSON', null, null, langId),
                },
                {
                    field: 'departmentName',
                    title: $translate.instant('TIMEBANK_LOG_GRID_DEPARTMENT', null, null, langId),
                },
                {
                    field: 'startDateTime',
                    title: $translate.instant('TIMEBANK_LOG_GRID_START_DATE', null, null, langId),
                    template: (dataItem) => {
                        return dateUtility.formatDateTime(dataItem.startDateTime);
                    },
                },
                {
                    field: 'startActionName',
                    title: $translate.instant('TIMEBANK_LOG_GRID_START_ACTION', null, null, langId),
                    template: (dataItem) =>{
                        return `<span kendo-tooltip k-content="\'${dataItem.startActionName}\'">${dataItem.startActionName}</span>`;
                    }
                },
                {
                    field: 'startActionComment',
                    title: $translate.instant('TIMEBANK_LOG_GRID_START_COMMENT', null, null, langId),
                    template: (dataItem) => {
                        if(dataItem.startActionId){
                            return dataItem.startActionComment ? dataItem.startActionComment : "";
                        }
                    }
                },
                {
                    field: 'stopDateTime ',
                    title: $translate.instant('TIMEBANK_LOG_GRID_STOP_DATE', null, null, langId),
                    template: (dataItem) => {
                        if(dataItem.stopActionId){
                            return dateUtility.formatDateTime(dataItem.stopDateTime);
                        } else {
                            return '';
                        }
                    },
                },
                {
                    field: 'stopActionName ',
                    title: $translate.instant('TIMEBANK_LOG_GRID_STOP_ACTION', null, null, langId),
                    template: (dataItem) =>{
                        return `<span kendo-tooltip k-content="\'${dataItem.stopActionName}\'">${dataItem.stopActionName}</span>`;
                    }
                },
                {
                    field: 'stopActionComment',
                    title: $translate.instant('TIMEBANK_LOG_GRID_STOP_COMMENT', null, null, langId),
                    template: (dataItem) => {
                        if(dataItem.stopActionId){
                            return dataItem.stopActionComment ? dataItem.stopActionComment : "";
                        } else {
                            return $translate.instant('TIMEBANK_LOG_GRID_ACTIV_COMMENT', null, null, langId);
                        }
                    },
                },
                {
                    field: 'jobTitle',
                    title: $translate.instant('ADMIN_USER_JOB_TITLE', null, null, langId),
                    hidden: !$scope.hasSeagoingServiceModule
                },
                {
                    field: 'styrkTitle',
                    title: $translate.instant('ADMIN_JOB_TITLE_STYRKTITLE', null, null, langId),
                    hidden: !$scope.hasSeagoingServiceModule
                },
                {
                    field: 'description',
                    title: $translate.instant('TIMEBANK_LOG_GRID_DESCRTIPTION', null, null, langId),
                },
                {
                    field: 'createdBy',
                    title: $translate.instant('TIMEBANK_LOG_GRID_CREATED_BY', null, null, langId),
                },
                {
                    field: 'balanceType',
                    title: $translate.instant('TIMEBANK_LOG_GRID_BALANCE_TYPE', null, null, langId),
                    template: (dataItem) => {
                        switch (dataItem.balanceType) {
                            case 'Daily':
                                return $translate.instant('TIMEBANK_BALANCE_TYPE_DAILY', null, null, langId);
                            case 'Hourly':
                                return $translate.instant('TIMEBANK_BALANCE_TYPE_HOURLY', null, null, langId);
                            case 'Absence Daily':
                                return $translate.instant('TIMEBANK_BALANCE_TYPE_DAILY_ABSENCE', null, null, langId);
                            case 'Absence Hourly':
                                return $translate.instant('TIMEBANK_BALANCE_TYPE_HOURLY_ABSENCE', null, null, langId);
                            case 'Daily - Full Day':
                                return $translate.instant('TIMEBANK_BALANCE_TYPE_FULL_DAY', null, null, langId);
                            default:
                                '';
                        }
                    }
                },
                {
                    field: 'sum',
                    title: $translate.instant('TIMEBANK_LOG_GRID_SUM', null, null, langId),
                    width: 70,
                    template: (dataItem) => {
                        if(dataItem.stopActionId){
                            return dataItem.sum.toFixed(2);
                        } else {
                            return '';
                        }
                    }
                },
                {
                    field: 'status',
                    title: $translate.instant('TIMEBANK_LOG_GRID_STATUS', null, null, langId),
                    template: (dataItem) => {
                        switch (dataItem.status) {
                            case 0:
                                return $translate.instant('TIMEBANK_STATUS_REGISTERED', null, null, langId);
                            case 1:
                                return $translate.instant('TIMEBANK_STATUS_APPROVED', null, null, langId);
                            case 4:
                                return $translate.instant('TIMEBANK_STATUS_CLOSED', null, null, langId);
                            case 5:
                                return $translate.instant('TIMEBANK_STATUS_REJECTED', null, null, langId);
                            case 6:
                                return $translate.instant('TIMEBANK_LOG_TOOLTIP_CONFIRMED', null, null, langId);
                            default:
                                '';
                        }
                    },
                    filterable: {
                        ui: function (element) {
                            element.kendoDropDownList({
                                dataSource: logActionPairsStatus,
                                dataTextField: "text",
                                dataValueField: "value",
                                defaultValue: $translate.instant('TIMEBANK_STATUS_SELECT_STATUS', null, null, langId)
                            });
                        }
                    }

                },
                {
                    field: 'rejectReason',
                    title: $translate.instant('TIMEBANK_LOG_GRID_REASON_OF_REJECTION', null, null, langId),
                    width: 70,
                },
                {
                    width: 40,
                    command: {
                        name: 'approveLogPair',
                        click: (e) => setLogActionStatus(e, $scope.isSkipperUser ? 6 : 1),
                        template: `<a class="row-icon k-grid-approveLogPair k-grid-icon-tooltip" data-tooltipText="${$scope.isSkipperUser ? $translate.instant('TIMEBANK_LOG_TOOLTIP_CONFIRMED', null, null, langId) : $translate.instant('TIMEBANK_LOG_TOOLTIP_APPROVE', null, null, langId)}"><i class="fas fa-check-circle"></i></a>`,
                    },
                    hidden: false,
                },
                {
                    width: 40,
                    command: {
                        name: 'reopenLogPair',
                        click: (e) => setLogActionStatus(e, 0),
                        template: `<a class="row-icon k-grid-reopenLogPair k-grid-icon-tooltip" data-tooltipText="${$translate.instant('TIMEBANK_LOG_TOOLTIP_REOPEN', null, null, langId)}"><i class="fas fa-dot-circle"></i></a>`,
                        },
                    hidden: false,
                },
                {
                    width: 40,
                    command: {
                        name: 'rejectLogPair',
                        click: (e) => setLogActionStatus(e, 5),
                        template: `<a class="row-icon k-grid-rejectLogPair k-grid-icon-tooltip" data-tooltipText="${$translate.instant('TIMEBANK_LOG_TOOLTIP_REJECT', null, null, langId)}"><i class="fas fa-times-circle"></i></a>`,
                    },
                    hidden: false,
                },
                {
                    width: 40,
                    command: {
                        name: 'viewDepartmentLogLogPair',
                        click: (e) => viewDepartmentLogs(e),
                        template: `<a class="row-icon k-grid-viewDepartmentLogLogPair k-grid-icon-tooltip" data-tooltipText="${$translate.instant('TIMEBANK_LOG_TOOLTIP_SHOW_DEPARTMENT_LOG', null, null, langId)}"><i class="fas fa-info-circle"></i></a>`,
                    },
                    hidden: false,
                },
                {
                    width: 40,
                    command: {
                        name: 'viewEntityHistory',
                        click: viewEntityHistories,
                        template: `<a class="row-icon k-grid-viewEntityHistory k-grid-icon-tooltip" data-tooltipText="${$translate.instant('TIMEBANK_LOG_TOOLTIP_VIEW_ENTITY_HISTORY', null, null, langId)}"><i class="fas fa-history"></i></a>`,
                    },
                    hidden: false,
                },
                {
                    width: 40,
                    command: {
                        name: 'editOperationLogs',
                        click: (e) => editOperationLogs(e),
                        template: `<a class="row-icon k-grid-editOperationLogs k-grid-icon-tooltip" data-tooltipText="${$translate.instant('TIMEBANK_LOG_TOOLTIP_EDIT', null, null, langId)}"><i class="fas fa-edit"></i></a>`,
                    },
                    hidden: false
                },
                {
                    width: 40,
                    command: {
                        name: 'deleteOperationLogs',
                        click: (e) => setLogActionStatus(e, 99),
                        template: `<a class="row-icon k-grid-deleteOperationLogs k-grid-icon-tooltip" data-tooltipText="${$translate.instant('TIMEBANK_LOG_TOOLTIP_DELETE', null, null, langId)}"><i class="fas fa-trash-alt"></i></a>`
                    },
                    hidden: false
                }
            ],
            editable: false,
            filterable: true,
            resizable: true,
            sortable: true,
            height: kendoUtility.calculateRemainingSpace(-40),
            dataBound: (e) => {

                // Command buttons conditional visibility
                var dataItems = e.sender.dataSource.data();
                for (var i = 0; i < dataItems.length; ++i) {

                    if (!$scope.isSuperUser) {
                        var tr = $('#timebank-logs-overview-grid').find('[data-uid="' + dataItems[i].uid + '"]');

                        switch (dataItems[i].status) {

                            case 0: // Registered
                                disableCommandButton($(tr).find('.k-grid-reopenLogPair'));
                                if (!$scope.userTimebankPermissions.timebankAdmin) {
                                    disableCommandButton($(tr).find('.k-grid-deleteOperationLogs'));
                                }
                                if (!$scope.userTimebankPermissions.timebankApprove && !$scope.isSkipperUser) {
                                    disableCommandButton($(tr).find('.k-grid-approveLogPair'));
                                    disableCommandButton($(tr).find('.k-grid-rejectLogPair'));
                                }

                                if($scope.isSkipperUser && dataItems[i].userId != $rootScope.authData.userId){
                                    disableCommandButton($(tr).find('.k-grid-editOperationLogs'));
                                }

                                if(!$scope.userTimebankPermissions.timebankEdit && dataItems[i].userId != $rootScope.authData.userId){
                                    disableCommandButton($(tr).find('.k-grid-editOperationLogs'));
                                }

                                break;

                            case 1: // Approved
                                disableCommandButton($(tr).find('.k-grid-approveLogPair'));
                                if (!$scope.userTimebankPermissions.timebankEditApprovedLog) {
                                    disableCommandButton($(tr).find('.k-grid-reopenLogPair'));
                                    disableCommandButton($(tr).find('.k-grid-rejectLogPair'));
                                    disableCommandButton($(tr).find('.k-grid-editOperationLogs'));
                                    disableCommandButton($(tr).find('.k-grid-deleteOperationLogs'));
                                }
                                break;

                            case 4: // Closed
                                // Disable everything except view department log, only superuser should be able to edit closed things.
                                disableCommandButton($(tr).find('.k-grid-approveLogPair'));
                                disableCommandButton($(tr).find('.k-grid-rejectLogPair'));
                                disableCommandButton($(tr).find('.k-grid-reopenLogPair'));
                                disableCommandButton($(tr).find('.k-grid-deleteOperationLogs'));
                                disableCommandButton($(tr).find('.k-grid-editOperationLogs'));
                                break;

                            case 5: // Rejected
                            disableCommandButton($(tr).find('.k-grid-approveLogPair'));
                            disableCommandButton($(tr).find('.k-grid-rejectLogPair'));
                                if (!$scope.userTimebankPermissions.timebankAdmin) {
                                    disableCommandButton($(tr).find('.k-grid-reopenLogPair'));
                                    disableCommandButton($(tr).find('.k-grid-deleteOperationLogs'));
                                    disableCommandButton($(tr).find('.k-grid-editOperationLogs'));
                                }
                                break;
                            case 6: // Confirmed by skipper
                                if($scope.isSkipperUser){
                                    disableCommandButton($(tr).find('.k-grid-approveLogPair'));
                                    disableCommandButton($(tr).find('.k-grid-rejectLogPair'));
                                    disableCommandButton($(tr).find('.k-grid-editOperationLogs'));
                                    disableCommandButton($(tr).find('.k-grid-deleteOperationLogs'));
                                } else {
                                    if (!$scope.userTimebankPermissions.timebankEditApprovedLog) {
                                        disableCommandButton($(tr).find('.k-grid-reopenLogPair'));
                                        disableCommandButton($(tr).find('.k-grid-rejectLogPair'));
                                        disableCommandButton($(tr).find('.k-grid-editOperationLogs'));
                                        disableCommandButton($(tr).find('.k-grid-deleteOperationLogs'));

                                    }

                                    if (!$scope.userTimebankPermissions.timebankEditApprovedLog
                                        && !$scope.userTimebankPermissions.timebankAdmin
                                        && !$scope.userTimebankPermissions.timebankApprove) {
                                        disableCommandButton($(tr).find('.k-grid-approveLogPair'));
                                    }
                                }
                                break;
                        }

                        if ($scope.showRestLogs)
                        {
                            if (dataItems[i].status === 1 || dataItems[i].status === 4){
                                var tr = $('#timebank-logs-overview-grid').find('[data-uid="' + dataItems[i].uid + '"]');
                                disableCommandButton($(tr).find('.k-grid-editOperationLogs'));
                            }

                            if(!$scope.hasAdminPermission){
                                var tr = $('#timebank-logs-overview-grid').find('[data-uid="' + dataItems[i].uid + '"]');
                                disableCommandButton($(tr).find('.k-grid-approveLogPair'));
                                disableCommandButton($(tr).find('.k-grid-reopenLogPair'));
                                disableCommandButton($(tr).find('.k-grid-rejectLogPair'));
                            }
                        }

                    }
                }
            }
        });

        $scope.timebankLogsOverviewGridOptions = timebankLogsOverviewGridOptions($translate.use());

        initController();
        function initController() {}

        function viewEntityHistories(e) {
            e.preventDefault();

            const data = $('#timebank-logs-overview-grid')
                .data('kendoGrid')
                .dataItem(angular.element(e.currentTarget).closest('tr'))
                .toJSON();

            const ids = [];

            if (data.startOperationLogId !== '00000000-0000-0000-0000-000000000000') {
                ids.push(data.startOperationLogId);
            }

            if (data.stopOperationLogId !== '00000000-0000-0000-0000-000000000000') {
                ids.push(data.stopOperationLogId);
            }

            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ENTITY_HISTORY_MODAL_TITLE', null, null, langId),
                    visible: false,
                    width: 1000,
                    height: 400
                },
                templateUrl: 'app/timebank/logs/timebank-history-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'timeBankHistoryModal',
                resolve: {
                    ids: () => ids
                },
            });
        }

        function viewDepartmentLogs(e) {
            e.preventDefault();
            var data = $('#timebank-logs-overview-grid')
                .data('kendoGrid')
                .dataItem(angular.element(e.currentTarget).closest('tr'))
                .toJSON();

                userService.getUsersAsDropdownByFilter({contractorIds: [$rootScope.authData.contractorId]})
                .then((users) => {
                    var userIds = _.map(users, (o) => {
                        return o.userId;
                    });
                    timeBankService
                        .getDepartmentLogs({
                            userIds: userIds,
                            contractorId: $rootScope.authData.contractorId,
                            departmentIds: [data.departmentId],
                            fromDate: data.startDateTime,
                            toDate: data.stopDateTime,
                        })
                        .then((result) => {
                            $kWindow.open({
                                options: {
                                    modal: true,
                                    movable: false,
                                    title: $translate.instant('TIMEBANK_DEPARTMENT_LOGS_TITLE', null, null, langId),
                                    resizable: true,
                                    visible: false,
                                    width: 1000,
                                    height: 500
                                },
                                templateUrl: 'app/timebank/logs/department-logs-modal.html',
                                windowTemplateUrl: 'app/shared/modal-base.html',
                                controller: 'DepartmentLogsModalController',
                                resolve: {
                                    logPairs: () => result,
                                },
                            });
                        });
                    });
        }

        function loadFilteredLogs(filter) {
            var needOnlyRestLogs = false;
            if($scope.showRestLogs){
                needOnlyRestLogs = true;
            } else {
                filter.includeRestLogs = false;
            }

            $q.all([timeBankService.getTimeBankOperationLogPairsForUsers(filter, needOnlyRestLogs)]).then((results) => {
                $scope.timeBankLogPairs = results[0];
                $scope.timebankLogsOverviewGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'timeBankLogsBusyIndicator');
            });
        }

        function isSkipper(){
            if($rootScope.authData.isSuperuser){
                return false;
            }

            if ($scope.userTimebankPermissions.timebankAdmin == true) {
                return false;
            }

            return $scope.userTimebankPermissions.timeBankSkipper && $scope.userTimeBankPermissions.timeBankView;
        }

        function editOperationLogs(event) {
            var windowInstance = openLogPairsDetailsModal(getLogPairFromGrid(event));

            windowInstance.result.then(() => {
                loadFilteredLogs($scope.filter);
            });
        }

        function setLogActionStatus(e, status) {
            e.preventDefault();

            var data = $('#timebank-logs-overview-grid')
            .data('kendoGrid')
            .dataItem(angular.element(e.currentTarget).closest('tr'))
            .toJSON();

            if (status == 5) {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 550
                    },
                    templateUrl: 'app/shared/popups/confirm-reason-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmReasonModalController',
                    resolve: {
                        content: () => {
                            return $translate.instant('TIMEBANK_LOG_REJECT_MSG', null, null, langId);
                        }
                    }
                });
                windowInstance.result.then((response) => {
                    if (response) {
                        disabledTimebankLogRow(e);
                    var toUpdate = {
                        logIdPairs: [{ startId: data.startOperationLogId, stopId: data.stopOperationLogId }],
                        status: status,
                        rejectReason: response,
                        stopDate: $scope.filter.toDate
                    };

                    timeBankService
                        .updateLogPairStatus(toUpdate)
                        .then(updateLogsGrid)
                        .catch(function(error){
                           console.log(error);
                        });
                    }
                });
            }
            else if (status == 99) {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 550
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: () => {
                            return $translate.instant('TIMEBANK_LOG_DELETE_MSG', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then((response) => {
                    if (response) {
                        disabledTimebankLogRow(e);
                        var toUpdate = {
                            logIdPairs: [{ startId: data.startOperationLogId, stopId: data.stopOperationLogId }],
                            status: status,
                            rejectReason: response,
                            stopDate: $scope.filter.toDate
                        };

                        timeBankService
                        .updateLogPairStatus(toUpdate)
                        .then(updateLogsGrid)
                        .catch(function(error){
                            console.log(error);
                         });
                    }
                });
            } else if (status == 0) {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 550
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: () => {
                            return $translate.instant('TIMEBANK_LOG_REOPEN_MSG', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then((response) => {
                    if (response) {
                        disabledTimebankLogRow(e);
                        var toUpdate = {
                            logIdPairs: [{ startId: data.startOperationLogId, stopId: data.stopOperationLogId }],
                            status: status,
                            rejectReason: '',
                            stopDate: $scope.filter.toDate
                        };

                        timeBankService
                        .updateLogPairStatus(toUpdate)
                        .then(updateLogsGrid)
                        .catch(function(error){
                            var warningContent = $translate.instant('TIMEBANK_LOG_APPROVE_OVERLAPPED_WARNING_CONTENT').replace('<status>', $translate.instant('TIMEBANK_STATUS_REGISTERED').toLowerCase());
                            confirmDialogService.openWarningDialog(warningContent, $translate.instant('TIMEBANK_LOG_APPROVE_OVERLAPPED_WARNING_TITLE'));
                            enableTimebankLogRow(e);
                        });
                    }
                });
            } else if (status == 1 && (!data.stopOperationLogId || data.stopOperationLogId == '00000000-0000-0000-0000-000000000000')) {
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 550
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: () => {
                            return $translate.instant('TIMEBANK_LOG_APPROVE_ACTIVE_LOG_ALERT') + ' ' + dateUtility.formatDate($scope.filter.toDate);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });

                windowInstance.result.then((response) => {
                    if (response) {
                        disabledTimebankLogRow(e);
                        var toUpdate = {
                            logIdPairs: [{ startId: data.startOperationLogId, stopId: data.stopOperationLogId }],
                            status: status,
                            rejectReason: '',
                            stopDate: $scope.filter.toDate
                        };

                        timeBankService
                        .updateLogPairStatus(toUpdate)
                        .then(updateLogsGrid)
                        .catch(function(error)
                        {
                            var warningContent = $translate.instant('TIMEBANK_LOG_APPROVE_OVERLAPPED_WARNING_CONTENT').replace('<status>', $translate.instant('TIMEBANK_STATUS_APPROVED')).toLowerCase();
                            confirmDialogService.openWarningDialog(warningContent, $translate.instant('TIMEBANK_LOG_APPROVE_OVERLAPPED_WARNING_TITLE'));
                            enableTimebankLogRow(e);
                        });
                    }
                });
            } else {
                disabledTimebankLogRow(e);
                var toUpdate = {
                    logIdPairs: [{ startId: data.startOperationLogId, stopId: data.stopOperationLogId }],
                    status: status,
                    rejectReason: null,
                    stopDate: $scope.filter.toDate
                };

                timeBankService
                    .updateLogPairStatus(toUpdate)
                    .then(updateLogsGrid)
                    .catch(function(error){
                        var warningContent = $translate.instant('TIMEBANK_LOG_APPROVE_OVERLAPPED_WARNING_CONTENT').replace('<status>', $translate.instant('TIMEBANK_STATUS_APPROVED')).toLowerCase();
                        confirmDialogService.openWarningDialog(warningContent, $translate.instant('TIMEBANK_LOG_APPROVE_OVERLAPPED_WARNING_TITLE'));
                            enableTimebankLogRow(e);
                    });
            }
        }

        function disabledTimebankLogRow(e){
            var sender = e.currentTarget;
            var row = angular.element(sender).closest("tr");
            disableCommandButton($(row[0]).find('.k-grid-approveLogPair'));
            disableCommandButton($(row[0]).find('.k-grid-reopenLogPair'));
            disableCommandButton($(row[0]).find('.k-grid-rejectLogPair'));
            disableCommandButton($(row[0]).find('.k-grid-viewDepartmentLogLogPair'));
            disableCommandButton($(row[0]).find('.k-grid-editOperationLogs'));
            disableCommandButton($(row[0]).find('.k-grid-approveLogPair'));
            disableCommandButton($(row[0]).find('.k-grid-deleteOperationLogs'));
        }

        function enableTimebankLogRow(e){
            var sender = e.currentTarget;
            var row = angular.element(sender).closest("tr");
            enableCommandButton($(row[0]).find('.k-grid-approveLogPair'));
            enableCommandButton($(row[0]).find('.k-grid-reopenLogPair'));
            enableCommandButton($(row[0]).find('.k-grid-rejectLogPair'));
            enableCommandButton($(row[0]).find('.k-grid-viewDepartmentLogLogPair'));
            enableCommandButton($(row[0]).find('.k-grid-editOperationLogs'));
            enableCommandButton($(row[0]).find('.k-grid-approveLogPair'));
            enableCommandButton($(row[0]).find('.k-grid-deleteOperationLogs'));
        }

        function getLogPairFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#timebank-logs-overview-grid').data('kendoGrid').dataItem(row).toJSON();
        }

        function openLogPairsDetailsModal(logPair) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('TIMEBANK_EDIT_TEXT'),
                    resizable: true,
                    visible: false,
                    width: 535,
                    height: 655,
                },
                templateUrl: 'app/timebank/logs/log-pair-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'logPairDetailsModal',
                resolve: {
                    logPair: () => logPair,
                },
            });
        }

        function acceptAll() {
            if (confirm($scope.isSkipperUser ? $translate.instant('TIMEBANK_SET_CONFIRMED_ALL_QUESTION', null, null, langId) : $translate.instant('TIMEBANK_SET_APPROVE_ALL_QUESTION', null, null, langId))) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'timeBankLogsBusyIndicator',
                    destination: '#timebank-logs-overview-grid',
                    overlay: true,
                });

                let filteredTimeBankLogPairs = $scope.timeBankLogPairs.filter(t => t.status === 6 || t.status === 0);

                var toUpdate = {
                    logIdPairs: _.map(filteredTimeBankLogPairs, p => {
                        return { startId: p.startOperationLogId, stopId: p.stopOperationLogId };
                    }),
                    status: $scope.isSkipperUser ? 6 : 1,
                    stopDate: $scope.filter.toDate
                };

                timeBankService
                    .updateLogPairStatus(toUpdate)
                    .then(() => {
                        _.forEach(filteredTimeBankLogPairs, (p) => {
                            p.status = $scope.isSkipperUser ? 6 : 1;
                        });
                        $scope.timebankLogsOverviewGridOptions.dataSource.read();
                    })
                    .catch(function(error){
                        if(error.data && error.data.length > 0){
                            var overlappedPairs = error.data.map(d => `${d.startLog.logAction.description} - ${d.endLog.logAction.description} ${moment(d.startLog.doneOn).format('DD.MM.YYYY')} - ${moment(d.endLog.doneOn).format('DD.MM.YYYY')}`);
                            var warningContent = $translate.instant('TIMEBANK_LOG_APPROVE_ALL_OVERLAPPED_WARNING_CONTENT') + '<br/>' + overlappedPairs.join('<br/>');
                            confirmDialogService.openWarningDialog(warningContent, $translate.instant('TIMEBANK_LOG_APPROVE_OVERLAPPED_WARNING_TITLE'));
                            console.log(error);
                        }
                    })
                    .finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'timeBankLogsBusyIndicator');
                    });
            }
        }

        function updateLogsGrid(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'timeBankLogsBusyIndicator',
                destination: '#timebank-logs-overview-grid',
                overlay: true,
            });
            loadFilteredLogs($scope.filter);
        }

        function disableCommandButton(button){
            button.prop( "disabled", true );
            button.addClass( "k-state-disabled");
        }

        function enableCommandButton(button){
            button.prop( "disabled", false );
            button.removeClass( "k-state-disabled");
        }

        function getUserPermissions(){
            var userPermissions = {
                isSuperUser: false,
                timebankEditApprovedLog: false,
                timebankAdmin: false,
                timebankApprove: false,
                timebankEdit: false,
                timebankView: false
            }

            if ($rootScope.authData.isSuperuser) { return _.mapValues(userPermissions, () => true )};

            $rootScope.authData.roles.forEach(role => {
                role.permissions.forEach(permission => {
                    switch (permission.key) {
                        case "TimebankEditApprovedLog": userPermissions.timebankEditApprovedLog = true; break;
                        case "TimebankAdmin":           userPermissions.timebankAdmin           = true; break;
                        case "TimebankApprove":         userPermissions.timebankApprove         = true; break;
                        case "TimebankEdit":            userPermissions.timebankEdit            = true; break;
                        case "TimebankView":            userPermissions.timebankView            = true; break;
                        case "TimebankSkipper":            userPermissions.timebankSkipper            = true; break;
                    }
                });
            });

            return userPermissions;
        }

        function showRestLogSwitchChanged(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'timeBankLogsBusyIndicator',
                destination: '#timebank-logs-overview-grid',
                overlay: true,
            });
            loadFilteredLogs($scope.filter);
        }
    }
})();
