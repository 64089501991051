(function () {
    angular.module('NaviaqWebApp').factory('qPool', qPool);

    qPool.$inject = ['$q'];

    function qPool($q) {
        var deferredDict = [];

        var service = {
            defer: defer
        };

        return service;

        function defer(callerName, keyValues) {
            //Without arguments fallback to $q.defer
            if (arguments.length === 0) {
                return $q.defer();
            }

            var item = getItemByCompositeKey(callerName, keyValues),
                deferred = null;

            if (item) {
                var itemRemoved = removeIfCompleted(item);
                if (!itemRemoved) {
                    deferred = item.value;
                    deferred.isFirst = false;
                } else {
                    item = null;
                }
            }

            //Either deferred doesn't exist or it was evicted
            if (!item) {
                deferred = $q.defer();

                deferred.isFirst = true;
                deferred.hasCompleted = hasCompleted;

                addCompositeKey(callerName, keyValues, deferred);
            }

            return deferred;
        }

        //Private functions
        function addCompositeKey(callerName, keyValues, deferred) {
            var meta = {
                id: generateGuid(),
                callerName: callerName,
                keyValuesJson: JSON.stringify(keyValues),
                createdAt: new Date()
            };

            deferredDict.push({ meta: meta, value: deferred });
        }

        function getItemByCompositeKey(callerName, keyValues) {
            var keyValuesJson = JSON.stringify(keyValues);
            var item = _.find(deferredDict, function (item) {
                return item && item.meta
                    && item.meta.callerName === callerName
                    && item.meta.keyValuesJson === keyValuesJson;
            });

            return item;
        }

        function hasCompleted() {
            var deferred = this;
            if (deferred.promise && deferred.promise.$$state && deferred.promise.$$state.status === 0) {
                return false;
            }

            return true;
        }

        function removeIfCompleted(item) {
            var deferred = item.value || null;
            if (deferred && deferred.promise && deferred.promise.$$state && deferred.promise.$$state.status !== 0) {
                removeById(item.meta.id);

                return true;
            }

            return false;
        }

        function removeById(id) {
            deferredDict = _.remove(deferredDict, function (item) { return item.meta.id === id; });
        }

        function generateGuid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        }
    }
})();
