angular.module('NaviaqWebApp').factory('locationLayerFactory', ['$q', '$rootScope', '$state', 'esriLoader', 'authService', 'contractorService', 'locationService', 'orderService', 'mapUtility',
    function ($q, $rootScope, $state, esriLoader, authService, contractorService, locationService, orderService, mapUtility) {
    'use strict';

    var readyDeferred = null;

    /**
     * Initializes the vessel layer ready function.
     */
    var locationLayerFactory = {
        createLayerOnAdd: true,

        ready: function () {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }

            return readyDeferred.promise;
        }
    }

    esriLoader.require([
        'esri/geometry/Point',
        'esri/geometry/ScreenPoint',
        'mcl/MarkerClusterLayer'
    ], function (Point, ScreenPoint, MarkerClusterLayer) {
        if (readyDeferred === null) {
            readyDeferred = $q.defer();
        }

        locationLayerFactory.createLayer = function() {
            var clusterLayer = new MarkerClusterLayer({ maxZoomLevel: 13 });
            clusterLayer.name = 'LocationLayer';
            clusterLayer.zIndex = 15;

            clusterLayer.toggleVisibility = function () {
                clusterLayer.visible = !clusterLayer.visible;
            }

            clusterLayer.onMouseMove = function (evt, mapView, hitResponse) {
                if (hitResponse) {
                    var graphic = hitResponse.results[hitResponse.results.length-1].graphic;

                    if (graphic.attributes && graphic.attributes.type === 'singleLocation') {
                        var point = new Point(graphic.geometry.x, graphic.geometry.y);
                        var graphicScreenPoint = mapView.toScreen(point);

                        $rootScope.$broadcast('showLocationPopup', {
                            left: graphicScreenPoint.x + mapView.position[0] + 20,
                            top: graphicScreenPoint.y + mapView.position[1] + 10,
                            name: graphic.attributes.point.name,
                            number: graphic.attributes.point.locationNumber,
                            owners: graphic.attributes.point.locationAccessInfo.locationOwners
                        });
                    }
                } else {
                    $rootScope.$broadcast('hideLocationPopup');
                }
            }

            clusterLayer.onRightClick = function (evt, mapView, hitResponse) {
                if (hitResponse) {
                    var graphic = hitResponse.results[hitResponse.results.length - 1].graphic;

                    if (graphic.layer.name === clusterLayer.name) {
                        var point = new Point(graphic.geometry.x, graphic.geometry.y);
                        var screenPoint = mapView.toScreen(point);

                        if (!graphic.attributes || (!graphic.attributes.isCluster && !(graphic.attributes.type === 'sublocation'))) {
                            $rootScope.$broadcast('showContextMenu', {
                                item: graphic.attributes.point,
                                mapPosition: mapView.position,
                                screenPoint: screenPoint,
                                type: 'Location'
                            });

                        } else if (graphic.attributes && graphic.attributes.type === 'sublocation') {
                            var item = graphic.attributes.point;
                            item.LocationNumber = graphic.attributes.id;

                            $rootScope.$broadcast('showContextMenu', {
                                item: item,
                                mapPosition: mapView.position,
                                screenPoint: screenPoint,
                                focusPoint: mapView.toScreen(graphic.attributes.point),
                                type: 'Location'
                            });
                        }
                    }
                }
            }

            clusterLayer.upsertLocationData = locations => {
                locations.forEach(location => {
                    const coordinates = mapUtility.convertToUTM33NFromWGS84(location.y, location.x);

                    location.lng = coordinates.latitude;
                    location.lat = coordinates.longitude;
                });

                clusterLayer.updateData(locations);
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'initLocationLayer',
                destination: '#main-view',
                message: 'Henter lokasjon',
                overlay: false,
                positionClass: 'bottom-left-inline'
            });

            var authData = authService.getAuthData();

            if (authData.isAuth) {
                locationService.getAvailableLocationsByUserId().then(locations => {                   
                    clusterLayer.upsertLocationData(locations);

                    $rootScope.$broadcast('hideBusyIndicator', 'initLocationLayer');
                }, () => $rootScope.$broadcast('hideBusyIndicator', 'initLocationLayer'));
            } else {
                if ($state.current.name === 'main.orderReport') {
                    if ($state.params.ContractorId && $state.params.OrderId) {
                        orderService.getOrderByOrderId($state.params.OrderId)
                            .then(function(order) {
                                if (order.locationNumber) {
                                    locationService
                                        .getLocationByLocationNumber(order.locationNumber)
                                        .then(clusterLayer.upsertLocationData);
                                }
                            });
                    }
                } else if ($state.current.name === 'main.locationOrders') {
                    if ($state.params.LocationNumber) {
                        locationService.getLocationByLocationNumber($state.params.LocationNumber)
                            .then(clusterLayer.upsertLocationData);
                    }
                }

                $rootScope.$broadcast('hideBusyIndicator', 'initLocationLayer');
            }
            return clusterLayer;
        };

        readyDeferred.resolve(locationLayerFactory);
    });

    return locationLayerFactory;
}]);
