(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('VesselSharingController', VesselSharingController);

    VesselSharingController.$inject = [
        '$scope',
        '$rootScope',
        '$q',
        '$kWindow',
        '$translate',
        'formTypes',
        'departmentService',
        'contractorService'
    ];

    function VesselSharingController(
        $scope,
        $rootScope,
        $q,
        $kWindow,
        $translate,
        formTypes,
        departmentService,
        contractorService
    ) {
        $scope.showAllEnabled = false;
        $scope.addDepartmentSharing = addDepartmentSharing;
        $scope.editDepartmentSharing = editDepartmentSharing;
        $scope.deleteDepartmentSharing = deleteDepartmentSharing;
        $scope.toggleShowAll = toggleShowAll;

        var departmentSharingDataSource = new kendo.data.DataSource({
            transport: {
                read: readDepartmentSharings,
            },
            group:
            {
                field: 'departmentName'
            },
            sort: [
                { field: 'departmentName', dir: 'asc' },
                { field: 'End', dir: 'asc' }
            ],
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        departmentName: { editable: false },
                        mmsi: { editable: false },
                        contractorName: { editable: false },
                        Start: { editable: false },
                        End: { editable: false },
                    }
                },
            }
        });

        const departmentSharingGridOptions = langId => ({
            sortable: true,
            scrollable: true,
            filterable: true,
            pageable: false,
            dataSource: departmentSharingDataSource,
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'departmentName',
                    title: $translate.instant('G_VESSEL', null, null, langId),
                    width: '100px'
                },
                {
                    field: 'mmsi',
                    title: $translate.instant('G_MMSI', null, null, langId),
                    width: '100px'
                },
                {
                    field: 'contractorName',
                    title: $translate.instant('ADMIN_SHARING_GRID_SHAREDWITH', null, null, langId),
                    width: '100px'
                },
                {
                    field: 'Start',
                    title: $translate.instant('ADMIN_SHARING_GRID_STARTDATE', null, null, langId),
                    width: '100px'
                },
                {
                    field: 'End',
                    title: $translate.instant('ADMIN_SHARING_GRID_ENDDATE', null, null, langId),
                    width: '100px'
                },
                {
                    command: [
                        {
                            name: 'editDepartmentSharing',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                editDepartmentSharing(data.id);
                            },
                            template: '<a class="k-grid-editDepartmentSharing"><i class="fas fa-edit"></i></a>'
                            // template: '<div>alma</div>'
                        },
                        {
                            name: 'deleteDepartmentSharing',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                deleteDepartmentSharing(data);
                            },
                            template: `<a class="k-grid-deleteDepartmentSharing"><i class="fas fa-trash"></i></a>`
                        },
                    ],
                    width: 30,
                }
            ],
        });

        $scope.departmentSharingGridOptions = departmentSharingGridOptions($translate.use());

        function readDepartmentSharings(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'departmentSharingIndicator',
                message: 'Henter-data...',
                destination: '#departmentSharingGrid',
                overlay: true
            });
            var requests = {};
            requests.getDepartmentSharingsWebApi = departmentService.getDepartmentSharingsWebApi($scope.authData.contractorId)
            requests.getDepartments = departmentService.getDepartmentsWebapi($scope.authData.contractorId);
            requests.getContractors = contractorService.getContractors();
            $q.all(requests)
                .then(function (data) {
                    $scope.departmentSharings = data.getDepartmentSharingsWebApi;
                    $scope.departments = data.getDepartments;
                    $scope.contractors = data.getContractors;

                    _.forEach($scope.departmentSharings, function (departmentSharing) {
                        extendDepartmentSharing(departmentSharing);
                    });

                    $scope.shownDepartmentSharings = filterDepartmentSharings();
                    e.success($scope.shownDepartmentSharings);
                    $rootScope.$broadcast('hideBusyIndicator', 'departmentSharingIndicator');
                });
        }

        function filterDepartmentSharings() {
            if ($scope.showAllEnabled) {
                return $scope.departmentSharings;
            }
            return _.filter($scope.departmentSharings,
                function (departmentSharing) {
                    return !moment(departmentSharing.End, 'DD.MM.YYYY').isBefore(moment(), 'days');
                });
        }

        function updateDepartmentSharings() {
            if ($scope.departmentSharingGrid) {
                $scope.departmentSharingGrid.dataSource.read();
                $scope.departmentSharingGrid.refresh();
            }
        }

        function toggleShowAll() {
            $scope.showAllEnabled = !$scope.showAllEnabled;
            updateDepartmentSharings();
        }

        function addDepartmentSharing(langId) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: true,
                    title: $translate.instant('ADMIN_VESSEL_NEW_DEPARTMENT', null, null, langId),
                    resizable: true,
                    height: 330,
                    width: 360,
                    visible: false
                },
                templateUrl: 'app/administration/vessels/vessel-sharing-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'VesselSharingDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.add;
                    },
                    departmentSharing: function () {
                        return null;
                    },
                    departments: function () {
                        return $scope.departments;
                    },
                    contractors: function () {
                        return $scope.contractors;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateDepartmentSharings();
                }
            });
        }

        function editDepartmentSharing(departmentSharingId) {
            var departmentSharing = _.find($scope.departmentSharings, function (ds) {
                return ds.id === departmentSharingId;
            });

            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: true,
                    title: 'Endre deling',
                    resizable: true,
                    height: 320,
                    width: 565,
                    visible: false
                },
                templateUrl: 'app/administration/vessels/vessel-sharing-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'VesselSharingDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.edit;
                    },
                    departmentSharing: function () {
                        return departmentSharing;
                    },
                    departments: function () {
                        return $scope.departments;
                    },
                    contractors: function () {
                        return $scope.contractors;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateDepartmentSharings();
                }
            });
        }

        function deleteDepartmentSharing(departmentSharing) {
            if (departmentSharing.CanDelete) {
                var windowInstance = $("<div />").kendoWindow({
                    title: "Bekrefte",
                    resizable: false,
                    modal: true
                });

                windowInstance.data("kendoWindow")
                    .content($("#delete-confirmation").html())
                    .center().open();

                windowInstance
                    .find(".delete-confirm,.delete-cancel")
                    .click(function () {
                        if ($(this).hasClass("delete-confirm")) {
                            departmentService.deleteDepartmentSharingByIdWebApi(departmentSharing.id).then(function () {
                                updateDepartmentSharings();
                                windowInstance.data("kendoWindow").close();
                            });
                        } else {
                            windowInstance.data("kendoWindow").close();
                        }

                    })
                    .end();
            }
        }

        function extendDepartmentSharing(departmentSharing) {
            departmentSharing.Start = moment(departmentSharing.start).format('DD.MM.YYYY');

            departmentSharing.End = departmentSharing.end ? moment(departmentSharing.end).format('DD.MM.YYYY') : '';

            if (departmentSharing.department) {
                departmentSharing.departmentName = departmentSharing.department.name;
                departmentSharing.mmsi = departmentSharing.department.mmsi;
            }

            if (departmentSharing.sharedWith) {
                departmentSharing.contractorName = departmentSharing.sharedWith.name;
            }

            departmentSharing.CanDelete = moment(departmentSharing.Start, 'DD.MM.YYYY').isAfter(moment(), 'days');   //can only remove those that start after today
        }
    }
})();
