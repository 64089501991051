(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ExternalCodeController', externalCodeController);

    externalCodeController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$kWindow',
        '$windowInstance',
        '$translate',
        'kendoUtility',
        'commonUtility',
        'htmlElementService',
        'logActionPairsService',
        'formTypes',
        'externalCodeTypes'
    ];

    function externalCodeController(
        $q,
        $rootScope,
        $scope,
        $kWindow,
        $windowInstance,
        $translate,
        kendoUtility,
        commonUtility,
        htmlElementService,
        logActionPairsService,
        formTypes,
        externalCodeTypes
    ) {
        $scope.externalCodes = [];

         $scope.addExternalCode = addExternalCode;
        var externalCodeDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.externalCodes)
            }
        });

        const externalCodeGridOptions = langId => ({
            dataSource: externalCodeDataSource,
            animation: false,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            columns: [
                {
                    field: 'externalCodeId',
                    hidden: true
                },
                {
                    field: 'value',
                    title: $translate.instant('G_VALUE', null, null),
                    width: 100,
                },
                {
                    field: 'externalCodeTypeDescription',
                    title: $translate.instant('EXTERNALCODE_TYPE', null, null),
                    width: 100,
                },
                {
                    command: [
                        {
                            name: 'editExternalCode',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                editExternalCode(data.externalCodeId);
                            },
                            template: '<a class="k-grid-editExternalCode"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteExternalCode',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                deleteExternalCode(data.externalCodeId);
                            },
                            template: '<a class="k-grid-deleteExternalCode"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 60
                }
            ]
        });
        $scope.externalCodeGridOptions = externalCodeGridOptions($translate.use());

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'externalcodeIndicator',
                destination: '#external-code-view',
                overlay: true
            });

            $q.all([logActionPairsService.getExternalCodes($rootScope.authData.contractorId)]).then(function (results) {
                $scope.externalCodes = results[0];
                extendExternalCode();
            }).finally(() => {
                $scope.externalCodeGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'externalcodeIndicator');
            });
        }

        function extendExternalCode(){
            _.forEach($scope.externalCodes, function (extCode) {
                extCode.externalCodeTypeDescription = externalCodeTypes.find(e => e.id == extCode.externalCodeType).value;
            });
        }

        function addExternalCode(){
            openExternalCodeDetailsModal(formTypes.add);
        }

        function editExternalCode(externalCodeId){
            var editedLineObject = $scope.externalCodes.find(l => l.externalCodeId == externalCodeId);
            if(editedLineObject){
                openExternalCodeDetailsModal(formTypes.edit, editedLineObject);
            }
        }

        function openExternalCodeDetailsModal(formType, externalCode = null){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: formType == formTypes.add ? $translate.instant('EXTERNALCODE_ADD_NEW', null, null)
                        : $translate.instant('EXTERNALCODE_EDIT_NEW', null, null),
                    resizable: true,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/administration/logActionPairs/externalCode/external-code-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ExternalCodeDetailsController',
                resolve: {
                    formType: function () {
                        return formType;
                    },
                    externalCode: function () {
                        return externalCode;
                    },
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    UpdateExternalCodeGrid();
                }
            });
        }

        function UpdateExternalCodeGrid(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'externalcodeIndicator',
                destination: '#external-code-view',
                overlay: true
            });

            $q.all([logActionPairsService.getExternalCodes($rootScope.authData.contractorId)]).then(function (results) {
                $scope.externalCodes = results[0];
                extendExternalCode();
            }).finally(() => {
                $scope.externalCodeGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'externalcodeIndicator');
            });
        }

        function deleteExternalCode(externalCodeId){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                        logActionPairsService.deleteExternalCode(externalCodeId).then(function(){
                            UpdateExternalCodeGrid();
                    });
                }
            });
        }
    }
})();
