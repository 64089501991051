(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('unitsDetailsModalAdministrationController', unitsDetailsModalAdministrationController);

    unitsDetailsModalAdministrationController.$inject = [
        '$q',
        '$translate',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'unit',
        'currentFormType',
        'formTypes',
        'unitService',
        'unitGroups',
        'measurementTypes',
        'resources',
        'departmentService',
        'supplierService'
    ];

    function unitsDetailsModalAdministrationController(
        $q,
        $translate,
        $rootScope,
        $scope,
        $windowInstance,
        unit,
        currentFormType,
        formTypes,
        unitService,
        unitGroups,
        measurementTypes,
        resources,
        departmentService,
        supplierService
    ) {
        $scope.unit = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        $scope.unitGroupsOptions = {
            dataSource: new kendo.data.DataSource({
                data: unitGroups
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            optionLabel: $translate.instant('ADMIN_UNITS_SELECT_PRODUCT_GROUP')
        };

        $scope.measurementTypesOptions = {
            dataSource: new kendo.data.DataSource({
                data: measurementTypes
            }),
            dataValueField: 'measurementUnitTypeId',
            dataTextField: 'measurementUnitDescription',
            optionLabel: $translate.instant('ADMIN_UNITS_SELECT_MEASUREMENT_UNIT')
        };

        $scope.resourcesOptions = {
            dataSource: new kendo.data.DataSource({
                data: resources
            }),
            dataValueField: 'resourceId',
            dataTextField: 'resourceDescription',
            optionLabel: $translate.instant('ADMIN_UNITS_SELECT_RESOURCE')
        };

        $scope.departmentTypesMultiselectOptions = {
            filter: "startswith",
            dataValueField: 'id',
            dataTextField: 'name_NO',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.departmentTypes)
                }
            })
        };

        $scope.suppliersOptions = {
            dataValueField: 'supplierId',
            dataTextField: 'name',
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.suppliers)
                }
            }),
            optionLabel: ' ',
            filter: 'contains',
            change: () => {
                if ($scope.selectedSupplier.supplierId === '') {
                    $scope.selectedSupplier = null;
                }
            }
        };

        $scope.selectedUnitGroup = null;
        $scope.selectedSupplier = null;
        $scope.selectedMeasurementTypes = null;
        $scope.selectedResources = null;
        $scope.departmentTypes = [];
        $scope.suppliers = [];

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitDetailsModalIndicator',
                destination: '.k-widget.k-window',
                overlay: true
            });

            if (currentFormType === formTypes.edit && unit) {
                $scope.unit = unit;
            }

            $scope.selectedUnitGroup = unitGroups.find(function (unitGroup) {
                return unitGroup.id == $scope.unit.unitGroupId;
            });

            $scope.selectedMeasurementTypes = measurementTypes.find(function (measurementType) {
                return measurementType.measurementUnitTypeId == $scope.unit.measurementUnitTypeId;
            });

            $scope.selectedResources = resources.find(function (resource) {
                return resource.resourceId == $scope.unit.resourceId;
            });

            $q.all({
                departmentTypes: departmentService.getDepartmentTypesWebApi(),
                suppliers: supplierService.getSuppliers()
            }).then((result) => {
                $scope.departmentTypes = result.departmentTypes;
                $scope.suppliers = result.suppliers;

                if (currentFormType === formTypes.edit) {
                    let relateddepartmentTypeIds = _.map(unit.departmentTypes,'id');
                    $scope.selectedDepartmentTypes = result.departmentTypes.filter(d => relateddepartmentTypeIds.includes(d.id));
                    $scope.selectedSupplier = result.suppliers.find(s => s.supplierId === unit.supplierId);
                }

                $scope.departmentTypesMultiselectOptions.dataSource.read();
                $scope.suppliersOptions.dataSource.read();

                $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
            })
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'unitDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                $scope.unit.unitGroupId = $scope.selectedUnitGroup ? $scope.selectedUnitGroup.id : null;
                $scope.unit.measurementUnitTypeId = $scope.selectedMeasurementTypes ? $scope.selectedMeasurementTypes.measurementUnitTypeId : null;
                $scope.unit.resourceId = $scope.selectedResources ? $scope.selectedResources.resourceId : null;
                $scope.unit.departmentTypes = $scope.selectedDepartmentTypes;
                $scope.unit.supplierId = $scope.selectedSupplier ? $scope.selectedSupplier.supplierId : null;

                switch (currentFormType) {
                    case formTypes.add:
                        $scope.unit.contractorId = $rootScope.authData.contractorId;

                        unitService.addUnit($scope.unit)
                            .then(unit => close($scope.unit))
                            .catch(() => close());
                        break;
                    case formTypes.edit:

                        unitService.updateUnit($scope.unit)
                            .then(unit => close($scope.unit))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
