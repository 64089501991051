(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ServiceCardReportController', ServiceCardReportController);

    ServiceCardReportController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$q',
        'reportService',
        'anonymousReportService',
        'commonUtility'];

    function ServiceCardReportController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $q,
        reportService,
        anonymousReportService,
        commonUtility) {

        $scope.isServiceCardReport = true;
        $scope.contractorId = $stateParams.ContractorId;
        $scope.orderId = $stateParams.OrderId;
        $scope.reportFolder = $stateParams.ReportFolder;
        $scope.reportImageUrl = '';
        $scope.reportData = {};

        $scope.goBack = goBack;
        $scope.setReportLogo = setReportLogo;
        $scope.controlTypes = reportService.getControlTypeValues();

        $scope.printReport = printReport;
        $scope.rotateReport = rotateReport;
        $scope.toggleCheckList = toggleCheckList;
        $scope.toggleMainComponents = toggleMainComponents;
        $scope.title = '';
        $scope.printView = false;

        //Hide navbar for print view
        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;

            $('#main-view').addClass('print-view');
        }

        initController();
        function initController() {
            if ($scope.contractorId && $scope.orderId) {
                $rootScope.$broadcast('showMainBusyIndicator', 'orderWorkReport');
            }

            //Calculate height for #report-details-container
            if (!$scope.printView) {
                var bodyHeight = $('body').height(),
                    newContainerHeight = bodyHeight - 60;

                $('#service-card-report-container').height(newContainerHeight);
            }

            getOrderWorkReportData();
        }

        function getOrderWorkReportData() {
            $q.all([
                anonymousReportService.getOrderWorkReportContent($scope.contractorId, $scope.orderId)
            ]).then(function (data) {
                if (data[0] && data[0].order) {
                    $scope.reportData = data[0];
                    setReportLogo();
                    $scope.reportData.order.orderDate = moment($scope.reportData.order.orderDate).format('DD.MM.YYYY');
                    $scope.reportData.order.expireDate = $scope.reportData.order.expireDate ? moment($scope.reportData.order.expireDate).format('DD.MM.YYYY') : '';
                    $scope.reportData.order.orderedDate = $scope.reportData.order.orderedDate ? moment($scope.reportData.order.orderedDate).format('DD.MM.YYYY') : '';

                    if ($scope.reportData.stretchData) {
                        $scope.reportData.stretchData.date = $scope.reportData.stretchData.date
                            ? moment($scope.reportData.stretchData.date).format('DD.MM.YYYY')
                            : '';
                        $scope.hasStretchActivity = true;

                        var stretchData = $scope.reportData.stretchData;
                        if (stretchData.jumpTestApprovedText === 'GODKJENT' &&
                            stretchData.fellingstauApprovedText === 'GODKJENT' &&
                            stretchData.halfDepthApprovedText === 'GODKJENT' &&
                            stretchData.bottomApprovedText === 'GODKJENT') {

                            $scope.reportData.stretchData.approvedAccordingToNS9415 = true;
                            $scope.title = 'Servicekort';
                        } else {
                            $scope.title = 'Arbeidsrapport underkjent not';
                        }
                    } else {
                        $scope.title = 'Arbeidsrapport underkjent not';
                    }

                    reportService.extendMapObjectWithActivites($scope.reportData.mapObjectWithActivitiesList, $scope.controlTypes);

                    if ($scope.reportData.mapObjectWithActivitiesList && $scope.reportData.mapObjectWithActivitiesList[0]) {
                        //Set only map object for displaying net
                        $scope.mapObjectWithActivities = $scope.reportData.mapObjectWithActivitiesList[0];

                        if ($scope.mapObjectWithActivities.activities &&
                            $scope.mapObjectWithActivities.activities.length > 0) {

                            var signatureActivity = _.find($scope.mapObjectWithActivities.activities, function (act) {
                                return act.activity && act.activity.description === 'Signatur';
                            });
                            $scope.signatureActivity = signatureActivity;

                            if ($scope.mapObjectWithActivities.operationLogs
                                && $scope.mapObjectWithActivities.operationLogs.length > 0) {

                                $scope.hasMapObjectOperationLogs = true;
                                $scope.mapObjectOperationLogs = _.orderBy($scope.mapObjectWithActivities.operationLogs, 'DoneOn', 'desc');

                                for (var i = 0; i < $scope.mapObjectOperationLogs.length; ++i) {
                                    var currentLog = $scope.mapObjectOperationLogs[i];
                                    currentLog.logAction.checkListItems = _.sortBy(currentLog.logAction.checkListItems, function (checkListItem) {
                                        if (checkListItem && checkListItem.description) {
                                            return checkListItem.description.toLowerCase();
                                        }

                                        return '';
                                    });
                                }
                            }

                            if ($scope.mapObjectWithActivities.deviationLogs
                                && $scope.mapObjectWithActivities.deviationLogs.length > 0) {

                                $scope.hasMapObjectDeviationLogs = true;
                                $scope.mapObjectDeviationLogs = _.orderBy($scope.mapObjectWithActivities.deviationLogs, 'DoneOn', 'desc');

                                for (var j = 0; j < $scope.mapObjectDeviationLogs.length; ++j) {
                                    var currentDeviation = $scope.mapObjectDeviationLogs[j];

                                    if (currentDeviation.logAction && currentDeviation.logAction.checkListItems) {
                                        currentDeviation.logAction.checkListItems = _.sortBy(currentDeviation.logAction.checkListItems, function (checkListItem) {
                                            if (checkListItem && checkListItem.description) {
                                                return checkListItem.description.toLowerCase();
                                            }

                                            return '';
                                        });
                                    }
                                }
                            }
                        }
                    }

                    //Filter order lines by the following
                    // 1) Only where amount > 0
                    // 2) The type is kg, stk, meter or liter
                    // 3) The Unit has a batch
                    var orderLines = $scope.reportData.order.orderLines;
                    if ($scope.reportData.order.orderLines && $scope.reportData.order.orderLines.length > 0) {
                        orderLines = _.filter(orderLines, function (orderLine) {
                            if (orderLine.amount > 0 && orderLine.unit && orderLine.unit.measurementUnitDescription) {

                                var desc = orderLine.unit.measurementUnitDescription.toLowerCase();
                                if (desc === 'stk' || desc === 'kg' || desc === 'meter' || desc === 'liter') {
                                    var orderLineBatch = _.find(orderLine.unit.unitBatches, { UnitBatchId: orderLine.unitBatchId});
                                    if (orderLineBatch) {
                                        return true;
                                    }
                                }
                            }

                            return false;
                        });

                        $scope.filteredOrderLines = _.orderBy(orderLines, 'Unit.description');
                        $scope.hasOrderLines = $scope.filteredOrderLines.length > 0;
                    }

                    if ($scope.printView && !$scope.hasMapObjectOperationLogs && !$scope.hasMapObjectDeviationLogs) {
                        var $pages = $('#service-card-report-container .page');
                        if ($pages.length > 1) {
                            $($pages[1]).hide();
                        }
                    }
                }

                $rootScope.$broadcast('hideBusyIndicator', 'orderWorkReport');
            }, function () {
                $rootScope.$broadcast('hideBusyIndicator', 'orderWorkReport');
            });
        }

        function setReportLogo() {
            if ($scope.reportFolder) {
                if ($scope.reportFolder.toLowerCase() === 'hepso') {
                    $scope.reportImageUrl = '../img/reports/Hepso/Images/Logo-width-1200.png';
                } else {
                    $scope.reportImageUrl = '../img/reports/' + $scope.reportFolder + '/Images/Logo-width-1200.png';
                }
            } else {
                $scope.reportImageUrl = '../img/reports/NAVIAQ/Images/Logo-width-1200.png';
            }

            commonUtility.isImage($scope.reportImageUrl).then(function (result) {
                if (!result) {
                    $scope.reportFolder = 'NAVIAQ';
                    $scope.reportImageUrl = '../img/reports/' + $scope.reportFolder + '/Images/Logo-width-1200.png';
                }
            });
        }

        function toggleCheckList() {
            $('.paging-turn-text').toggle();
            $('.map-object-head').toggleClass('paging-off');
            $('#orderLines').toggleClass('paging-off');
        }

        function toggleMainComponents() {
            $('.toggle-main-components-button-text').toggle();
            $('.main-components-data').toggle();
        }

        function printReport() {
            var currentParams = $state.params;

            if (currentParams.ContractorId && currentParams.OrderId && currentParams.ReportFolder) {
                var printUrl = window.location.origin + '/service-card-print?'
                    + 'ContractorId=' + currentParams.ContractorId
                    + '&OrderId=' + currentParams.OrderId
                    + '&ReportFolder=' + currentParams.ReportFolder
                    + '&PrintView=true';

                var fileName = 'Servicekort';
                if ($scope.mapObjectWithActivities && $scope.mapObjectWithActivities.mapObjectDescription) {
                    fileName += ' ' + $scope.mapObjectWithActivities.mapObjectDescription.replace(/#/gi, '');
                }

                fileName += ' - ' + moment().format('DD.MM.YYYY') + '.pdf';

                var pdfUrl = window.location.origin + '/api/print?printUrl=' + encodeURIComponent(printUrl)
                    + '&fileName=' + fileName;
                window.open(pdfUrl, '_blank');
            }
        }

        function rotateReport() {
            $('.report').toggleClass('wide-report');
        }

        function goBack() {
            $state.go('main.orderReport', {
                ContractorId: $scope.contractorId,
                OrderId: $scope.orderId
            });
        }
    }
})();
