(function() {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .controller('LocationInfoModalController', checkListItemDetailsModalController);

    checkListItemDetailsModalController.$inject = [
        '$scope',
        'locationService',
        '$rootScope',
        '$stateParams',
        '$windowInstance'
    ];

    function checkListItemDetailsModalController(
        $scope,
        locationService,
        $rootScope,
        $stateParams,
        $windowInstance
    )
    {
        $scope.editInformationModal = [];
        $scope.editInformationModal.owner = '';
        $scope.validateForm = validateForm;
        $scope.closeModal = closeModal;

        initController();
        function initController() {

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'editLocation',
                destination: '.k-window-content',
                overlay: true
            });

            locationService.getLocationBaseByLocationNumber($stateParams.LocationNumber).then(function (location) {
                if (location) {
                    $scope.location = location;

                    $scope.editInformationModal.locationId = location.locationNumber;

                    $scope.editInformationModal.locality = location.locationName;
                    $scope.editInformationModal.workOrderCreationdays = location.workOrderCreationDays;
                    $scope.editInformationModal.status = location.status;
                    $scope.editInformationModal.generateWorkOrder = location.shouldGenerateWorkOrderOnComponents;

                    _.each(location.locationOwners, function (owner) {
                        if ($scope.editInformationModal.owner !== '') {
                            $scope.editInformationModal.owner += ', ';
                        }
                        $scope.editInformationModal.owner += owner.name;
                    });
                }
            });
            $rootScope.$broadcast('hideBusyIndicator', 'editLocation');
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {

                $scope.location.shouldGenerateWorkOrderOnComponents = $scope.editInformationModal.generateWorkOrder;
                $scope.location.workOrderCreationDays = $scope.editInformationModal.workOrderCreationdays;
                $scope.location.status = $scope.editInformationModal.status;

                locationService.updateLocation($scope.location).then(function (response) {
                    $windowInstance.close(true);
                });
            }
        }

        function closeModal() {
            $windowInstance.close();
        }
    }
})();
