(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LandBaseDetailsModalController', LandBaseDetailsModalController);

    LandBaseDetailsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        '$kWindow',
        '$translate',
        'landBase',
        'currentFormType',
        'formTypes',
        'landBaseService',
        'mapUtility'
    ];

    function LandBaseDetailsModalController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        $kWindow,
        $translate,
        landBase,
        currentFormType,
        formTypes,
        landBaseService,
        mapUtility
    ) {
        $scope.landBase = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        initController();

        function initController() {
            if (currentFormType === formTypes.edit && landBase) {
                $scope.landBase = landBase;
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'landBaseDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        $scope.landBase.contractorId = $rootScope.authData.contractorId;
                        $scope.landBase.status = 0;
                        $scope.landBase.shouldGenerateWorkOrderOnComponents = 0;
                        $scope.landBase.x = mapUtility.convertDegreesMinsToDecDegrees($scope.landBase.xDegree.replace(',', '.'), $scope.landBase.xMinutes.replace(',', '.'));
                        $scope.landBase.y = mapUtility.convertDegreesMinsToDecDegrees($scope.landBase.yDegree.replace(',', '.'), $scope.landBase.yMinutes.replace(',', '.'));
                        $scope.landBase.spatialReferenceType = 'WGS_1984';
                        landBaseService
                        .AddLandBase($scope.landBase)
                        .then(function(response){
                            if(response == -1){
                                openWarningDialog($translate.instant('ADMIN_LANDBASES_WARNING_MSG_TITLE'), $translate.instant('ADMIN_LANDBASES_WARNING_MSG_CONENT'));
                                close($scope.landBase);
                            } else{
                                close($scope.landBase);
                            }
                        })
                        .catch(() => close());
                        break;
                    case formTypes.edit:
                        $scope.landBase.x = mapUtility.convertDegreesMinsToDecDegrees($scope.landBase.xDegree.replace(',', '.'), $scope.landBase.xMinutes.replace(',', '.'));
                        $scope.landBase.y = mapUtility.convertDegreesMinsToDecDegrees($scope.landBase.yDegree.replace(',', '.'), $scope.landBase.yMinutes.replace(',', '.'));
                        landBaseService
                        .updateLandBase($scope.landBase)
                        .then(function(response){
                            if(response == -1){
                                openWarningDialog($translate.instant('ADMIN_LANDBASES_WARNING_MSG_TITLE'), $translate.instant('ADMIN_LANDBASES_WARNING_MSG_CONENT'));
                                close($scope.landBase);
                            } else {
                                close($scope.landBase);
                            }
                        })
                        .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function openWarningDialog(title, content) {
            var defaults = {
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: title ? title : $translate.instant('G_CONFIRM'),
                    width: 450,
                    height: 120,
                    overlay: true
                },
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/shared/modals/warning-dialog.html',
                controller: 'WarningDialogController',
                resolve: {
                    content: function () {
                        return content;
                    },
                }
            };
            var params = params ? _.merge(defaults, {}) : defaults;
            var modal = $kWindow.open(params);
            return modal.result;
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'landBaseDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
