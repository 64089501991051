(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('supplierService', supplierService);

    supplierService.$inject = ['$q', '$http', 'serviceUrls'];

    function supplierService($q, $http, serviceUrls) {
        const suppliersWebApiUrl = `${serviceUrls.webApiBaseUrl}/supplier`;

        return {
            getSuppliers: getSuppliers,
            getBargeSuppliers: getBargeSuppliers
        };

        function getSuppliers() {
            var deferred = $q.defer();
            var requestUrl = `${suppliersWebApiUrl}`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function getBargeSuppliers() {
            var deferred = $q.defer();
            var requestUrl = `${suppliersWebApiUrl}/bargeSuppliers`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
