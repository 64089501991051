(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ConfirmModalController', confirmModalController);

    confirmModalController.$inject = ['$scope', '$windowInstance', 'content', '$sce', '$translate', 'primaryBtnText', 'secondaryBtnText'];

    function confirmModalController($scope, $windowInstance, content, $sce, $translate, primaryBtnText, secondaryBtnText) {
        $scope.content = $sce.trustAsHtml(content);

        $scope.ok = ok;
        $scope.cancel = cancel;

        $scope.primaryBtnText = primaryBtnText ? primaryBtnText : $translate.instant('G_CONFIRM');
        $scope.secondaryBtnText =secondaryBtnText ? secondaryBtnText : $translate.instant('G_CANCEL');

        function ok() {
            $windowInstance.close(true);
        }

        function cancel() {
            $windowInstance.close(false);
        }
    }
})();
