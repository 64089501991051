(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('adminLocationOwnerService', adminLocationOwnerService);

    adminLocationOwnerService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function adminLocationOwnerService(
        $q,
        $http,
        serviceUrls,
    ) {
        const url = `${serviceUrls.webApiBaseUrl}/adminLocationOwner`;

        return {
            getLocationOwners,
            getLocationOwner,
            getLocations,
            addLocationOwner,
            updateLocationOwner,
            deleteLocationOwner
        };

        function getLocationOwners() {
            const deferred = $q.defer();

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getLocationOwner(id) {
            const deferred = $q.defer();

            $http
                .get(`${url}/${id}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getLocations() {
            const deferred = $q.defer();

            $http
                .get(`${url}/locations`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addLocationOwner(locationOwner) {
            const deferred = $q.defer();

            $http
                .post(url, locationOwner)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateLocationOwner(locationOwner) {
            const deferred = $q.defer();

            $http
                .put(url, locationOwner)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteLocationOwner(locationOwnerId) {
            const deferred = $q.defer();

            $http
                .delete(`${url}/${locationOwnerId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
