(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('FinalReportController', FinalReportController);

    FinalReportController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$q',
        '$translate',
        'deviationStatuses',
        'inspectionOptions',
        '$http',
        '$kWindow',
        'mediaService',
        '$sce',
        'anonymousReportService',
        'confirmDialogService'
    ];

    function FinalReportController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $q,
        $translate,
        deviationStatuses,
        inspectionOptions,
        $http,
        $kWindow,
        mediaService,
        $sce,
        anonymousReportService,
        confirmDialogService
    ) {
        $scope.printReport = printReport;
        $scope.openComponentFilter = openComponentFilter;
        $scope.orderId = $stateParams.OrderId;
        $scope.finalReport = {};
        $scope.componentTreeHistory = {};
        $scope.reportImageUrl = '';
        $scope.reportFooterImageUrl = '';
        $scope.departmentImageURL = '';
        $scope.documentType = -1;
        $scope.diverData = [];
        $scope.contractorId = '';
        $scope.componentFilter = null;
        $scope.isFroy = $state.current.name === "finalReportFroy"
        $scope.showLightBox = false;
        $scope.imageUrl = '';

        $scope.lastIndexOfCases = 0;
        $scope.getIndexOfCurrentCases = getIndexOfCurrentCases;
        function getIndexOfCurrentCases(cases) {
            var index = $scope.finalReport.captainMessagesCasesAndDivers.indexOf(cases);
            $scope.lastIndexOfCases = $scope.lastIndexOfCases < index + 1 ? index + 1 : $scope.lastIndexOfCases;
            return index;
        }

        $scope.lastIndexOfServiceForm = 0;
        $scope.getIndexOfCurrentServiceForm = getIndexOfCurrentServiceForm;
        function getIndexOfCurrentServiceForm(serviceForm) {
            var index = $scope.finalReport.serviceForms.indexOf(serviceForm);
            $scope.lastIndexOfServiceForm = $scope.lastIndexOfServiceForm < index + 1 ? index + 1 : $scope.lastIndexOfServiceForm;
            return index;
        }

        $scope.lastIndexOfLineComponents = 0;
        $scope.getIndexOfCurrentLineComponent = getIndexOfCurrentLineComponent;
        function getIndexOfCurrentLineComponent(lineComponent) {
            var index = $scope.finalReport.lineComponentGroups.indexOf(lineComponent);
            $scope.lastIndexOfLineComponents = $scope.lastIndexOfLineComponents < index + 1 ? index + 1 : $scope.lastIndexOfLineComponents;
            return index;
        }

        $scope.lastIndexOfCaseImage = 0;
        $scope.getIndexOfCurrentCaseImage = getIndexOfCurrentCaseImage;
        function getIndexOfCurrentCaseImage(caseImage) {
            var index = $scope.finalReport.caseImages.indexOf(caseImage);
            $scope.lastIndexOfCaseImage = $scope.lastIndexOfCaseImage < index + 1 ? index + 1 : $scope.lastIndexOfCaseImage;
            return index;
        }

        $scope.lastIndexOfComponentImage = 0;
        $scope.getOIndexOfCurrentComponentImage = getOIndexOfCurrentComponentImage;
        function getOIndexOfCurrentComponentImage(componentImage) {
            var index = $scope.finalReport.componentImages.indexOf(componentImage);
            $scope.lastIndexOfComponentImage = $scope.lastIndexOfComponentImage < index + 1 ? index + 1 : $scope.lastIndexOfComponentImage;
            return index;
        }

        $scope.sanitizeAndFormatNewlines = function(text) {
            function sanitizeHtml(input) {
                if(input){
                    var output = input.toString()
                    // Allow only <br> tags
                    .replace(/<[^br][^>]*>/g, '')
                    // Convert newline characters to <br>
                    .replace(/\n/g, '<br>');
                    return output;
                } else {
                    return '';
                }
            }
            // Need to trustAsHtml for angular to allow HTML formatting of text
            return $sce.trustAsHtml(sanitizeHtml(text || ''));
        };

        if ($state.params.PrintView && $state.params.PrintView === 'true') {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#final-report-container').addClass('print-view-container');
        }

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'finalReportIndicator',
                destination: '#main-view',
                overlay: true,
            });

            $scope.loadingDataParts = {
                collectComponentIsLoaded: false,
                orderDataIsLoaded: false,
                orderDepartmentIsLoaded: false,
                serviceFormIsLoaded: false,
                caseImageIsLoaded: false,
                captainMessageAndCaseAndDiverPageIsLoaded: false,
                componentImageIsLoaded: false,
                lineComponentIsLoaded: false,
                componentTreeIsLoaded: false,
            };
            $scope.disabledPrintBtn = true;

            $('html').addClass('auto-overflow');

            var request = {
                getFinalReportContent: anonymousReportService.getFinalReportContent($scope.orderId),
            };
            $q.all(request).then(function (result) {
                var finalReportContent = result.getFinalReportContent;
                if(!$scope.componentFilter && finalReportContent.order){
                    collectComponents(finalReportContent.order);
                } else {
                    dataPartLoadingCheck({name: 'collectComponentIsLoaded', value: true});
                }

                if (finalReportContent.order) {
                    $scope.contractorId = finalReportContent.order.contractorId;
                    getOrderData(finalReportContent.order, finalReportContent.locationReference);
                } else {
                    dataPartLoadingCheck({name: 'orderDataIsLoaded', value: true});
                }

                if (finalReportContent.order) {
                    getOrderDepartment(finalReportContent.order);
                } else {
                    dataPartLoadingCheck({name: 'orderDepartmentIsLoaded', value: true});
                }

                if (finalReportContent.serviceFormDataWithTemplate && finalReportContent.serviceFormDataWithTemplate.length && finalReportContent.serviceFormOptions) {
                    getServiceForms(finalReportContent.serviceFormDataWithTemplate, finalReportContent.serviceFormOptions,
                        finalReportContent.usersByContractorId, finalReportContent.logActionsByContractorId, finalReportContent.departmentByContractorId);
                } else {
                    dataPartLoadingCheck({name: 'serviceFormIsLoaded', value: true});
                }

                if (finalReportContent.deviations && finalReportContent.deviations.length
                    && finalReportContent.deviationDocuments && finalReportContent.deviationDocuments.length) {
                    getCaseImages(finalReportContent.deviations, finalReportContent.deviationDocuments);
                } else {
                    dataPartLoadingCheck({name: 'caseImageIsLoaded', value: true});
                }

                if (finalReportContent.skipperMessage || (finalReportContent.deviations && finalReportContent.deviations.length)) {
                    handleCaptainMessageAndCaseAndDiverPage(finalReportContent.skipperMessage, finalReportContent.deviations);
                } else {
                    dataPartLoadingCheck({name: 'captainMessageAndCaseAndDiverPageIsLoaded', value: true});
                }

                if (finalReportContent.componentWithRelatedFile && finalReportContent.componentWithRelatedFile.length) {
                    getComponentImages(finalReportContent.componentWithRelatedFile);
                } else {
                    dataPartLoadingCheck({name: 'componentImageIsLoaded', value: true});
                }

                if (finalReportContent.orderLineComponentHistory && finalReportContent.orderLineComponentHistory.length) {
                    getLineComponents(finalReportContent.orderLineComponentHistory)
                } else {
                    dataPartLoadingCheck({name: 'lineComponentIsLoaded', value: true});
                }

                if (finalReportContent.componentTreeJSON) {
                    setComponentTreeHistory(finalReportContent.componentTreeJSON)
                } else {
                    dataPartLoadingCheck({name: 'componentTreeIsLoaded', value: true});
                }

                $rootScope.$broadcast('finalReportFooterData', $scope.finalReport);
            });
        }

        function getOrderData(order, locationReference) {
            if(order && order.contactPersons
                && order.contactPersons.length > 0){
                    order.contactPerson = order.contactPersons[0];
            } else {
                order.contactPerson = null;
            }
            $scope.finalReport.order = order;
            $scope.reportImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-${$scope.finalReport.order.contractorId}.png`;
            $scope.reportFooterImageUrl = `https://naviaqas.blob.core.windows.net/logos/footer-${$scope.finalReport.order.contractorId}.png`;

            $http.get($scope.reportImageUrl, {headers: {'Anonymous' : ''}, responseType: 'blob'})
                .then(response =>
                    {
                        $scope.reportImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-${$scope.finalReport.order.contractorId}.png`;
                    })
                .catch(error => {
                    $scope.reportImageUrl = `https://naviaqas.blob.core.windows.net/logos/logo-e083f410-67ab-e611-a3b0-f23cffe3d187.png`; // default naviaq image
                })


            if ($scope.finalReport.order.senderUser && $scope.finalReport.order.senderUser.person) {
                var person = $scope.finalReport.order.senderUser.person;
                var user = $scope.finalReport.order.senderUser;
                $scope.finalReport.sender = {};
                $scope.finalReport.sender.name = user.displayName;
                $scope.finalReport.sender.email = user.userName;
                $scope.finalReport.sender.phone = person.mobilePhoneNumber
                    ? person.mobilePhoneNumber
                    : person.phoneNumber;
            }
            if ($scope.finalReport.order.projectManagerObject && $scope.finalReport.order.projectManagerObject.person) {
                var projectManagerPerson = $scope.finalReport.order.projectManagerObject.person;
                var projectManagerUser = $scope.finalReport.order.projectManagerObject;

                $scope.finalReport.order.projectManagerName = projectManagerUser.displayName;
                $scope.finalReport.order.projectManagerEmail = projectManagerUser.userName;
                $scope.finalReport.order.projectManagerPhone = projectManagerPerson.mobilePhoneNumber
                    ? projectManagerPerson.mobilePhoneNumber
                    : projectManagerPerson.phoneNumber;
            }

            if (locationReference && locationReference.locationReferenceContent) {
                $scope.finalReport.order.locationReferenceContent = locationReference.locationReferenceContent;
            }
            dataPartLoadingCheck({name: 'orderDataIsLoaded', value: true});

            $scope.documentType = $scope.finalReport.order.orderProcess && $scope.finalReport.order.orderProcess.documentType > -1
                ? $scope.finalReport.order.orderProcess.documentType
                : -1;
            $scope.finalReport.order.orderProcessName = $scope.finalReport.order.orderProcess ? $scope.finalReport.order.orderProcess.name : '';
            setOrderDisplayName();

            if ($scope.finalReport.order.orderedDate) {
                var orderedDate = new Date($scope.finalReport.order.orderedDate);
            }
            if ($scope.finalReport.order.orderDate) {
                var orderDate = new Date($scope.finalReport.order.orderDate);
            }
            if ($scope.finalReport.order.expireDate) {
                var expireDate = new Date($scope.finalReport.order.expireDate);
            }
            if (orderDate) {
                $scope.finalReport.order.orderDateString = moment(orderDate).format('DD.MM.YYYY');
                $scope.finalReport.order.orderDateHoursAndMinutesString = moment(orderDate).format('HH:mm');
            }
            if (expireDate) {
                $scope.finalReport.order.expireDateString = moment(expireDate).format('DD.MM.YYYY');
                $scope.finalReport.order.expireDateHoursAndMinutesString = moment(expireDate).format('HH:mm');
            }
            if (orderedDate) {
                $scope.finalReport.order.orderedDateString = moment(orderedDate).format('DD.MM.YYYY');
            }
        }

        function getOrderDepartment(order) {
            let departmentImageURLs = [];
            const orderDepartment = order.orderDepartment;
            const departments = order.orderDepartments.map((x) => {
                if (x.department && x.department.departmentInfo && x.department.departmentInfo.defaultMediaId) {
                    departmentImageURLs.push(getMediaUrl(x.department.departmentInfo.defaultMediaId));
                }
                return x.department;
            });

            if (orderDepartment) {
                setMooringReportOrder(orderDepartment);
                $scope.departmentImageURL = orderDepartment.departmentInfo && orderDepartment.departmentInfo.defaultMediaId
                    ? getMediaUrl(orderDepartment.departmentInfo.defaultMediaId) : '';
            } else if (departments.length) {
                setMooringReportOrder(departments[0]);
                $scope.departmentImageURL = departmentImageURLs.length ? departmentImageURLs[0] : '';
            }
            dataPartLoadingCheck({name: 'orderDepartmentIsLoaded', value: true});
        }

        function setMooringReportOrder (department) {
            $scope.finalReport.order.departmentName = department.name;
            $scope.finalReport.order.departmentEmail = department.departmentInfo && department.departmentInfo.email ? department.departmentInfo.email : '';
            $scope.finalReport.order.departmentPhone = department.departmentInfo && department.departmentInfo.phone
                                        ? department.departmentInfo.phone
                                        : department.departmentInfo && department.departmentInfo.ownerPhone ? department.departmentInfo.ownerPhone : '';
        }

        function getMultiSelectOptionsValues(template, key, selectedItemIndexes, serviceFormOptions) {
            var selectedOptions = [];
            if (template) {
                _.forEach(selectedItemIndexes, function (selectedItemIndex) {
                    var option = serviceFormOptions.find((s) => s.name == template.optionsName);
                    if (option && option.options && option.options.length >= selectedItemIndex) {
                        selectedOptions.push(option.options[selectedItemIndex].name);
                    }
                });
            }
            if (selectedOptions.length > 0) {
                return selectedOptions.join(', ');
            } else {
                return '';
            }
        }

        function setReferencedValues(template, serviceFormData, users, logActions, departments) {
            var userTemplateKeys = template.filter(field => field.optionsType === 'user' || field.optionsType === 'diver' || field.optionsType === 'diverLeader').map(field => field.key);
            var requests = [];
            _.forEach(userTemplateKeys, key => {
                if (serviceFormData[key] && serviceFormData[key].length) {
                    var user = users.find(x => x.userId === serviceFormData[key])
                    if (user) {
                        var formValue = user.displayName;
                        serviceFormData[key] = formValue;
                    }
                }
            });

            var logActionKeys = _.map(_.filter(template, { optionsType: 'logaction' }), 'key');
            _.forEach(logActionKeys, key => {
                if (serviceFormData[key] && serviceFormData[key].length) {
                    var logAction = logActions.find(x => x.logActionId === serviceFormData[key])
                    if (logAction) {
                        formValue = logAction.description;
                        serviceFormData[key] = formValue;
                    }
                }
            });

            var departmentKeys = _.map(_.filter(template, { optionsType: 'logaction' }), 'key');
            _.forEach(departmentKeys, key => {
                if (serviceFormData[key] && serviceFormData[key].length) {
                    var department = departments.find(x => x.departmentId === serviceFormData[key])
                    if (department) {
                        formValue = department.name;
                        serviceFormData[key] = formValue;
                    }
                }
            });

            return $q.all(requests);
        }

        function getServiceForms(serviceFormDataWithTemplate, serviceFormOptions, users, logActions, departments) {
            var serviceFormList = [];

            serviceFormOptions.forEach(serviceFormOption => {
                if(serviceFormOption.options){
                    serviceFormOption.options = JSON.parse(serviceFormOption.options);
                }
            });

            var resultServiceForms = useFilterOnServiceForms(serviceFormDataWithTemplate.filter(s => !s.serviceForm.showOnMooringReport || s.serviceForm.showOnMooringReport == false)).reverse();
            var callTime = 0;
            if(resultServiceForms.length){
                _.forEach(resultServiceForms, (serviceFormDataWithTemplate) => {
                    if (
                        serviceFormDataWithTemplate &&
                        serviceFormDataWithTemplate.data &&
                        serviceFormDataWithTemplate.formTemplate
                    )

                    var serviceFormName = serviceFormDataWithTemplate && serviceFormDataWithTemplate.serviceForm && serviceFormDataWithTemplate.serviceForm.name ?
                        serviceFormDataWithTemplate.serviceForm.name :
                        serviceFormDataWithTemplate && serviceFormDataWithTemplate.operationLog && serviceFormDataWithTemplate.operationLog.logAction && serviceFormDataWithTemplate.operationLog.logAction.description ?
                            serviceFormDataWithTemplate.operationLog.logAction.description :
                            '';

                    var serviceFormData = JSON.parse(serviceFormDataWithTemplate.data);
                    var template = JSON.parse(serviceFormDataWithTemplate.formTemplate);
                    var serviceForm = [];

                    setReferencedValues(template, serviceFormData, users, logActions, departments).then(() => {
                        var multilevelDiveDepthElements = [];
                        jQuery.each(serviceFormData, function (dataFieldName, dataFieldValue) {
                            var matchingTemplate = _.find(template, { key: dataFieldName });
                            if (matchingTemplate) {
                                var formValue = '';
                                switch (matchingTemplate.type) {
                                    case 'select':
                                        switch (matchingTemplate.optionsType) {
                                            case 'department':
                                                formValue = dataFieldValue;
                                                break;
                                            case 'logaction':
                                                formValue = dataFieldValue;
                                                break;
                                            case 'diver':
                                            case 'diverLeader':
                                            case 'user':
                                                formValue = dataFieldValue;
                                                break;
                                            default: {
                                                break;
                                            }
                                        }
                                        if (matchingTemplate.optionsName && serviceFormOptions) {
                                            var optionsLists = serviceFormOptions;
                                            var options = optionsLists.find((oL) => oL.name == matchingTemplate.optionsName)
                                                .options;
                                            if(options){
                                                var selectedOption = options.find((x) => x.value === dataFieldValue);
                                                if (selectedOption && selectedOption.name) {
                                                    formValue = selectedOption.name;
                                                }
                                            }
                                        }
                                        break;
                                    case 'checkbox':
                                        if (dataFieldValue) {
                                            formValue = $translate.instant('G_YES');
                                        } else {
                                            formValue = $translate.instant('G_NO');
                                        }
                                        break;
                                    case 'multiselect':
                                        formValue = getMultiSelectOptionsValues(
                                            matchingTemplate,
                                            dataFieldName,
                                            dataFieldValue,
                                            serviceFormOptions
                                        );
                                        break;
                                    case 'inspection-select':
                                        selectedOption =  inspectionOptions.find(i => i.value == dataFieldValue);
                                        if(selectedOption){
                                            if(dataFieldValue != 3){
                                                formValue = selectedOption.name
                                            } else {
                                                var freeText = serviceFormData[dataFieldName + '_freeText'];
                                                if(freeText){
                                                    formValue = selectedOption.name + ' - ' + freeText;
                                                } else {
                                                    formValue = selectedOption.name
                                                }
                                            }
                                        } else {
                                            formValue = '';
                                        }
                                        break;
                                    case 'inspection-select-strict':
                                        selectedOption =  inspectionOptions.find(i => i.value == dataFieldValue);
                                        if(selectedOption){
                                            if(dataFieldValue != 3 && dataFieldValue != 2){
                                                formValue = selectedOption.name
                                            } else {
                                                var freeText = serviceFormData[dataFieldName + '_freeText'];
                                                if(freeText){
                                                    formValue = selectedOption.name + ' - ' + freeText;
                                                } else {
                                                    formValue = selectedOption.name
                                                }
                                            }
                                        } else {
                                            formValue = '';
                                        }
                                        break;
                                    case 'time':
                                        if (dataFieldValue) {
                                            formValue = moment(new Date(dataFieldValue)).format('HH:mm');
                                        }
                                        break;
                                    case 'datetime':
                                        if (dataFieldValue) {
                                            formValue = moment(new Date(dataFieldValue)).format('DD.MM.YYYY HH:mm');
                                        }
                                        break;
                                    case 'date':
                                        if (dataFieldValue) {
                                            formValue = moment(new Date(dataFieldValue)).format('DD.MM.YYYY');
                                        }
                                        break;
                                    case 'dateAggregation':
                                        var field = template.find(t => t.key == matchingTemplate.dateField);
                                        if (dataFieldValue && field) {
                                            var format = field.type == 'datetime'? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY";
                                            formValue = moment(new Date(dataFieldValue)).format(format);
                                        }
                                        break;
                                    case 'netwashtime':
                                        if (dataFieldValue) {
                                            formValue = moment(new Date(dataFieldValue)).format('DD.MM.YYYY');
                                        }
                                        break;
                                    default:
                                        formValue = dataFieldValue;
                                        break;
                                }

                                if(!matchingTemplate.hidden){
                                    var serviceFormFieldName = matchingTemplate.label
                                        ? matchingTemplate.label
                                        : matchingTemplate.key;
                                    serviceForm.push({ key: dataFieldName, name: serviceFormFieldName, value: formValue });
                                }

                                if ((matchingTemplate.optionsType && matchingTemplate.optionsType === 'diver') || (matchingTemplate.type == 'genericCompetency')) {
                                    var diverCertKey = `${dataFieldName}_diveCertNo`;
                                    var healthExpireKey = `${dataFieldName}_healthExpire`;

                                    if (!matchingTemplate.hidden){
                                        if(serviceFormData[diverCertKey]){
                                            serviceForm.push({ key: diverCertKey, name: `Sertifikat ${serviceFormFieldName.toLowerCase()}`, value: serviceFormData[diverCertKey] });
                                        }

                                        if(serviceFormData[healthExpireKey]){
                                            serviceForm.push({ key: healthExpireKey, name: `Helseattest ${serviceFormFieldName.toLowerCase()}`, value: serviceFormData[healthExpireKey] });
                                        }
                                    } else if (matchingTemplate.hidden && formValue && !$scope.diverData.find(x => x.diverName === formValue)){
                                        $scope.diverData.push({
                                            diverName: formValue,
                                            certificate: serviceFormData[diverCertKey],
                                            healthTestExpire: serviceFormData[healthExpireKey]
                                        })
                                    }
                                }

                                if ((matchingTemplate.optionsType && matchingTemplate.optionsType === 'diverLeader') || (matchingTemplate.type == 'genericCompetency')) {
                                    var diverLeaderCertKey = `${dataFieldName}_diveLeaderCertNo`;
                                    var diverLeaderCompetenceKey = `${dataFieldName}_diverLeaderCompetence`;

                                    if (!matchingTemplate.hidden){
                                        if(serviceFormData[diverLeaderCertKey]){
                                            serviceForm.push({ key: diverLeaderCertKey, name: `${$translate.instant('FINAL_REPORT_DIVER_CERT')}`, value: serviceFormData[diverLeaderCertKey] });
                                        }

                                        if(serviceFormData[diverLeaderCompetenceKey]){
                                            serviceForm.push({ key: diverLeaderCompetenceKey, name: `${$translate.instant('FINAL_REPORT_DIVER_LEADER_CERT')}`, value: serviceFormData[diverLeaderCompetenceKey] });
                                        }
                                    } else if (matchingTemplate.hidden && formValue && !$scope.diverData.find(x => x.diverName === formValue)){
                                        $scope.diverData.push({
                                            diverName: formValue,
                                            certificate: serviceFormData[diverLeaderCertKey],
                                            healthTestExpire: serviceFormData[diverLeaderCompetenceKey]
                                        })
                                    }
                                }
                            }

                            // Depth Controls
                            var multilevelDiveDepthControls = template.filter(t => t.type == 'multilevelDiveDepth');
                            if(!matchingTemplate && multilevelDiveDepthControls){
                                var matchingDepthControl = multilevelDiveDepthControls.find(d => dataFieldName.includes(d.key + '_'));
                                if(matchingDepthControl && !matchingDepthControl.hidden){
                                    if(dataFieldName.includes('_depthTime')){
                                        // Time new depth
                                        formValue = moment(new Date(dataFieldValue)).format('HH:mm');
                                        multilevelDiveDepthElements.push({ key: dataFieldName.replace('_depthTime_','_depthTime'), name: 'Ankomst ny dybde', value: formValue });
                                    }

                                    if(dataFieldName.includes('_newDepth')){
                                        //New depth
                                        formValue = dataFieldValue;
                                        multilevelDiveDepthElements.push({ key: dataFieldName, name: 'Ny dybde', value: formValue });
                                    }

                                    if(dataFieldName.includes('_foriaterNewDepth')){
                                        // Foriater ny dybde
                                        formValue = moment(new Date(dataFieldValue)).format('HH:mm');
                                        multilevelDiveDepthElements.push({ key: dataFieldName, name: 'Foriater ny dybde', value: formValue });
                                    }

                                    if(dataFieldName.includes('_timeAtDepth')){
                                        //Time at deapth
                                        formValue = dataFieldValue;
                                        multilevelDiveDepthElements.push({ key: dataFieldName, name: 'Tid på dybde', value: formValue });
                                    }

                                    if(dataFieldName.includes('_additionInTime')){
                                        // Addition in time
                                        formValue = dataFieldValue;
                                        multilevelDiveDepthElements.push({ key: dataFieldName, name: 'Tillegg i tid (N2 fra forrige dybde)', value: formValue });
                                    }

                                    if(dataFieldName.includes('_n2Group')){
                                        //n2 group
                                        formValue = dataFieldValue;
                                        multilevelDiveDepthElements.push({ key: dataFieldName, name: 'N2 gruppe', value: formValue });
                                    }
                                }
                            }
                        });

                        //Header
                        var headers = [];

                        var keysOfServiceFormData = serviceForm.map(x => x.key);
                        var syncedTempalte = template.filter(x => keysOfServiceFormData.includes(x.key) || x.type == 'header');

                        syncedTempalte.forEach((templateElement, i) => {
                            if (templateElement.type === "header" && !templateElement.hidden) {
                                headers.push({ key: templateElement.key, label: templateElement.label, index: i })
                            }
                        });

                        if (headers.length) {
                            headers.forEach(header => {
                                serviceForm.splice(header.index, 0, { key: header.key, label: header.label, type: "header" });
                            });
                        }

                        // Information control
                        var infoControls = [];
                        var keysOfServiceFormData = serviceForm.map(x => x.key);
                        var syncedTempalte = template.filter(x => keysOfServiceFormData.includes(x.key) || x.type == 'information');
                        syncedTempalte.forEach((templateElement, i) => {
                            if (templateElement.type === "information" && !templateElement.hidden) {
                                infoControls.push({ key: templateElement.key, label: templateElement.label, index: i });
                            }
                        });

                        if (infoControls.length) {
                            infoControls.forEach(info => {
                                serviceForm.splice(info.index, 0, { key: info.key, label: info.label, type: "information" });
                            });
                        }

                        var serviceFormKeyList = syncedTempalte.map(s => s.key);
                        serviceForm = serviceForm.sort(function(a, b){
                            return serviceFormKeyList.indexOf(a.key.replace('_diveCertNo', '').replace('_healthExpire', '').replace('_diveLeaderCertNo', '').replace('_diverLeaderCompetence', '')) - serviceFormKeyList.indexOf(b.key.replace('_diveCertNo', '').replace('_healthExpire', '').replace('_diveLeaderCertNo', '').replace('_diverLeaderCompetence', ''));
                        });

                        // push new depth controls to list
                        var multilevelDiveDepthElementKeys = multilevelDiveDepthElements.map(m => m.key);
                        multilevelDiveDepthElements.forEach((elem, i) => {
                            var key = elem.key.split('_')[0];
                            var interation = elem.key.split('_')[1];

                            if(!multilevelDiveDepthElementKeys.includes(key + '_' + interation + '_1_depthTime'))
                            {
                                multilevelDiveDepthElements.push({ key: key + '_' + interation + '_1_depthTime', name: 'Ankomst ny dybde', value: '' });
                            }

                            if(!multilevelDiveDepthElementKeys.includes(key + '_' + interation + '_2_newDepth'))
                            {
                                multilevelDiveDepthElements.push({ key: key + '_' + interation + '_2_newDepth', name: 'Ny dybde', value: '' });
                            }

                            if(!multilevelDiveDepthElementKeys.includes(key + '_' + interation + '_3_foriaterNewDepth'))
                            {
                                multilevelDiveDepthElements.push({ key: key + '_' + interation + '_3_foriaterNewDepth', name: 'Foriater ny dybde', value: '' });
                            }

                            if(!multilevelDiveDepthElementKeys.includes(key + '_' + interation + '_4_timeAtDepth'))
                            {
                                multilevelDiveDepthElements.push({ key: key + '_' + interation + '_4_timeAtDepth', value: '' });
                            }

                            if(!multilevelDiveDepthElementKeys.includes(key + '_' + interation + '_51_additionInTime'))
                            {
                                multilevelDiveDepthElements.push({ key: key + '_' + interation + '_51_additionInTime', name: 'Tillegg i tid (N2 fra forrige dybde)', value: '' });
                            }

                            if(!multilevelDiveDepthElementKeys.includes(key + '_' + interation + '_5_n2Group'))
                            {
                                multilevelDiveDepthElements.push({ key: key + '_' + interation + '_5_n2Group', name: 'N2 gruppe', value: '' });
                            }
                        });
                        multilevelDiveDepthElements = multilevelDiveDepthElements.filter((element, index, self) =>
                                index === self.findIndex((t) => (
                                    t.key === element.key
                                )));

                        if(multilevelDiveDepthElements && multilevelDiveDepthElements.length > 0){
                            var multilevelDiveDepthControls = template.filter(t => t.type == 'multilevelDiveDepth');
                            _.sortBy(multilevelDiveDepthElements, 'key').reverse().forEach(depth => {
                                var multilevelDiveDepthControl = multilevelDiveDepthControls.find(d => depth.key.includes(d.key + '_'));
                                var multilevelDiveDepthControlIndex =
                                    template.findIndex(t=> t.key == multilevelDiveDepthControl.key) - multilevelDiveDepthControls.findIndex(c => c.key == multilevelDiveDepthControl.key);
                                serviceForm.splice(multilevelDiveDepthControlIndex, 0, depth);
                            });
                        }

                        if(serviceForm.length){
                            serviceFormList.push({
                                name: serviceFormName,
                                serviceForm: serviceForm,
                                date: serviceFormDataWithTemplate.date,
                                relatedFiles: serviceFormDataWithTemplate.serviceFormFiles,
                                operationLog: serviceFormDataWithTemplate.operationLog,
                                componentName: getLogComponentName(serviceFormDataWithTemplate.operationLog),
                                componentNameForSort: getLogComponentNameString(serviceFormDataWithTemplate.operationLog),
                            });
                        }

                        if(callTime === resultServiceForms.length - 1){
                            var groupedLogs = _.groupBy(_.filter(serviceFormList, 'componentNameForSort'), 'componentNameForSort');
                            var simpleLogs = _.filter(serviceFormList, { componentNameForSort: '' });
                            var extendedLogs = _.merge(groupedLogs, simpleLogs);

                            serviceFormList = [];
                            _.forOwn(extendedLogs, (value, key) => {
                                if (Array.isArray(value)) {
                                    $.merge( serviceFormList, sortedOperationLogs(value) );
                                } else {
                                    serviceFormList.push(value);
                                }
                            });

                            var serviceFormPages = {
                                linesOnOnePage: 0,
                                maxLinesOnOnePage:  28,
                                serviceFormPageList: [],
                                serviceFormsOnOnePage: []
                            }
                            var imageHeightInRows = 9;
                            var maxCharNumberPerRow = 100;
                            serviceFormList = serviceFormList.filter(x => x.serviceForm.length);

                            for (var i = 0; i < serviceFormList.length; i++) {
                                if(i > 1 && JSON.stringify(serviceFormList[i - 1].componentName) !== JSON.stringify(serviceFormList[i].componentName)){
                                    serviceFormPages.serviceFormPageList.push(serviceFormPages.serviceFormsOnOnePage);
                                    serviceFormPages.serviceFormsOnOnePage = [];
                                    serviceFormPages.linesOnOnePage = 1; // starts with 1 because of the header
                                }
                                serviceFormPages.linesOnOnePage = serviceFormPages.linesOnOnePage + 1;
                                var serviceFormCopy = JSON.parse(JSON.stringify(serviceFormList[i].serviceForm));
                                serviceFormList[i].serviceForm = [];
                                serviceFormCopy.forEach(serviceForm => {
                                    var templateItem = template.find(t => t.key == serviceForm.key);
                                    if(serviceForm.value && Array.isArray(serviceForm.value) && serviceForm.value.some(v => v.mediaId)){
                                        for (var v = 0; v < serviceForm.value.length; ++v) {
                                            var serviceFormIterationCopy = JSON.parse(JSON.stringify(serviceForm));
                                            handleNewPagesOnServiceForm(serviceFormPages, imageHeightInRows, serviceFormList[i]);
                                            serviceFormIterationCopy.url = getMediaUrl(serviceFormIterationCopy.value[v].mediaId);
                                            serviceFormIterationCopy.type = "image";
                                            serviceFormIterationCopy.value = serviceFormIterationCopy.value[v];
                                            serviceFormList[i].serviceForm.push(serviceFormIterationCopy);
                                            serviceFormPages.linesOnOnePage = serviceFormPages.linesOnOnePage + imageHeightInRows;
                                        }
                                    } else if(serviceForm.value && serviceForm.value.mediaId) {
                                        handleNewPagesOnServiceForm(serviceFormPages, imageHeightInRows, serviceFormList[i])
                                        serviceForm.url = getMediaUrl(serviceForm.value.mediaId);
                                        serviceForm.type = "image";
                                        serviceFormList[i].serviceForm.push(serviceForm);
                                        serviceFormPages.linesOnOnePage = serviceFormPages.linesOnOnePage + imageHeightInRows;
                                    }
                                    else if(serviceForm.value && serviceForm.value.length > maxCharNumberPerRow){
                                        var rowsNumberWithTheCurrentValue = Math.round(serviceForm.value.length / maxCharNumberPerRow);
                                        handleNewPagesOnServiceForm(serviceFormPages, rowsNumberWithTheCurrentValue, serviceFormList[i])
                                        serviceFormPages.linesOnOnePage = serviceFormPages.linesOnOnePage + rowsNumberWithTheCurrentValue;
                                        serviceFormList[i].serviceForm.push(serviceForm);
                                    }
                                     else if(!templateItem || (templateItem && templateItem.type != 'photoUpload')) {
                                        handleNewPagesOnServiceForm(serviceFormPages, 1, serviceFormList[i])
                                        serviceFormPages.linesOnOnePage = serviceFormPages.linesOnOnePage + 1;
                                        serviceFormList[i].serviceForm.push(serviceForm);
                                    }
                                });

                                if(serviceFormList[i].serviceForm && serviceFormList[i].serviceForm.length){
                                    serviceFormPages.serviceFormsOnOnePage.push(serviceFormList[i]);
                                }
                            }

                            if(serviceFormPages.serviceFormsOnOnePage.length){
                                serviceFormPages.serviceFormPageList.push(serviceFormPages.serviceFormsOnOnePage);
                            }
                            $scope.finalReport.serviceForms = serviceFormPages.serviceFormPageList;
                            handleDiverData();
                        } else {
                            callTime = callTime + 1;
                        }
                    })
                });
            } else {
                $scope.finalReport.serviceForms = [];
            }
            dataPartLoadingCheck({name: 'serviceFormIsLoaded', value: true});
        }

        function handleNewPagesOnServiceForm(serviceFormPages, rowHeight, serviceFormList){
            if(serviceFormPages.linesOnOnePage + rowHeight > serviceFormPages.maxLinesOnOnePage){
                if(serviceFormList.serviceForm.length){
                    serviceFormPages.serviceFormsOnOnePage.push(JSON.parse(JSON.stringify(serviceFormList)));
                    serviceFormList.hideHeader = true;
                }
                serviceFormPages.serviceFormPageList.push(JSON.parse(JSON.stringify(serviceFormPages.serviceFormsOnOnePage)));
                serviceFormPages.serviceFormsOnOnePage = [];
                serviceFormList.serviceForm = [];
                serviceFormPages.linesOnOnePage = 1; // starts with 1 because of the header
            }
        }

        function handleDiverData(){
            var divers = JSON.parse(JSON.stringify($scope.diverData));
            if(!$scope.finalReport.captainMessagesCasesAndDivers.length){
                $scope.finalReport.captainMessagesCasesAndDivers.push(initializeCaptainMessagesCasesAndDiversObject());
            }

            if($scope.rowCount + 1 + divers.length > $scope.maxRowCount){
                var diverList = JSON.parse(JSON.stringify(divers))
                diverList = diverList.splice(0, $scope.maxRowCount - $scope.rowCount);
                var captainMessagesCasesAndDiver = $scope.finalReport.captainMessagesCasesAndDivers[$scope.finalReport.captainMessagesCasesAndDivers.length - 1];
                captainMessagesCasesAndDiver.divers = diverList;
                $scope.finalReport.captainMessagesCasesAndDivers[$scope.finalReport.captainMessagesCasesAndDivers.length - 1] = captainMessagesCasesAndDiver;
                captainMessagesCasesAndDiver = initializeCaptainMessagesCasesAndDiversObject();
                captainMessagesCasesAndDiver.divers = divers.splice($scope.maxRowCount - $scope.rowCount, divers.length);
                $scope.finalReport.captainMessagesCasesAndDivers.push(captainMessagesCasesAndDiver);
                captainMessagesCasesAndDivers = initializeCaptainMessagesCasesAndDiversObject();
                $scope.rowCount = 1 + captainMessagesCasesAndDiver.divers.length;
            } else {
                $scope.rowCount = $scope.rowCount + divers.length;
                var captainMessagesCasesAndDiver = $scope.finalReport.captainMessagesCasesAndDivers[$scope.finalReport.captainMessagesCasesAndDivers.length - 1];
                captainMessagesCasesAndDiver.divers = divers
                $scope.finalReport.captainMessagesCasesAndDivers[$scope.finalReport.captainMessagesCasesAndDivers.length - 1] = captainMessagesCasesAndDiver;
            }
        }

        function getLogComponentName(log) {
            var componentTreeHistory = null;
            var components = {};

            if (log.cages && log.cages && log.cages.length > 0) {
                componentTreeHistory = $scope.componentTreeHistory.find(x => x.cageId === log.cages[0].cageId);
                components.componentType = 0;
            }

            if (log.rings && log.rings && log.rings.length > 0) {
                componentTreeHistory = $scope.componentTreeHistory.find(x => x.ringId === log.rings[0].ringId);
                components.componentType = 1;
            }

            if (log.nets && log.nets && log.nets.length > 0) {
                componentTreeHistory = $scope.componentTreeHistory.find(x => x.netId === log.nets[0].id);
                components.componentType = 2;
            }

            if(componentTreeHistory){
                components.cage = `${componentTreeHistory.cageId && componentTreeHistory.cageNumber ? $translate.instant('FINAL_REPORT_CAGE') + ': ' + componentTreeHistory.cageNumber : ''}`;
                components.ring = `${componentTreeHistory.ringId && componentTreeHistory.ringNumber ? $translate.instant('FINAL_REPORT_RING') + ': ' + componentTreeHistory.ringNumber : ''}`;
                components.net = `${componentTreeHistory.netId && componentTreeHistory.netNumber ? $translate.instant('FINAL_REPORT_NET') + ': '+ componentTreeHistory.netNumber : ''}`;
            }
            else if (log.lines && log.lines && log.lines.length > 0) {
                components.line = `${$translate.instant('FINAL_REPORT_LINE')}: ${log.lines[0].description}`;
                components.componentType = 3;
            }
            else{
                components.componentType = -1;
            }

            return components;
        }

        function getLogComponentNameString(log) {
            var componentTreeHistory = null;

            if (log.cages && log.cages && log.cages.length > 0) {
                componentTreeHistory = $scope.componentTreeHistory.find(x => x.cageId === log.cages[0].cageId);
                if(componentTreeHistory){
                    return `${componentTreeHistory.cageId && componentTreeHistory.cageNumber ? $translate.instant('FINAL_REPORT_CAGE') + ': ' + componentTreeHistory.cageNumber + ' - ' : ''}` +
                        `${componentTreeHistory.ringId && componentTreeHistory.ringNumber ? $translate.instant('FINAL_REPORT_RING') + ': ' + componentTreeHistory.ringNumber + ' - ' : ''}` +
                        `${componentTreeHistory.netId && componentTreeHistory.netNumber ? $translate.instant('FINAL_REPORT_NET') + ': '+ componentTreeHistory.netNumber : ''}`;
                } else{
                    return ''
                }
            }

            if (log.nets && log.nets && log.nets.length > 0) {
                componentTreeHistory = $scope.componentTreeHistory.find(x => x.netId === log.nets[0].id);
                if(componentTreeHistory){
                    return `${componentTreeHistory.cageId && componentTreeHistory.cageNumber ? $translate.instant('FINAL_REPORT_CAGE') + ': ' + componentTreeHistory.cageNumber + ' - ' : ''}` +
                        `${componentTreeHistory.ringId && componentTreeHistory.ringNumber ? $translate.instant('FINAL_REPORT_RING') + ': ' + componentTreeHistory.ringNumber + ' - ' : ''}` +
                        `${componentTreeHistory.netId && componentTreeHistory.netNumber ? $translate.instant('FINAL_REPORT_NET') + ': '+ componentTreeHistory.netNumber : ''}`;
                } else{
                    return ''
                }
            }

            if (log.rings && log.rings && log.rings.length > 0) {
                componentTreeHistory = $scope.componentTreeHistory.find(x => x.ringId === log.rings[0].ringId);
                if(componentTreeHistory){
                    return `${componentTreeHistory.cageId && componentTreeHistory.cageNumber ? $translate.instant('FINAL_REPORT_CAGE') + ': ' + componentTreeHistory.cageNumber + ' - ' : ''}` +
                        `${componentTreeHistory.ringId && componentTreeHistory.ringNumber ? $translate.instant('FINAL_REPORT_RING') + ': ' + componentTreeHistory.ringNumber + ' - ' : ''}` +
                        `${componentTreeHistory.netId && componentTreeHistory.netNumber ? $translate.instant('FINAL_REPORT_NET') + ': '+ componentTreeHistory.netNumber : ''}`;
                } else{
                    return ''
                }
            }

            if (log.lines && log.lines && log.lines.length > 0) {
                return `${$translate.instant('FINAL_REPORT_LINE')} ${log.lines[0].description}`;
            }

            return '';
        }

        function sortedOperationLogs(logs) {
            return _.orderBy(
                logs,
                (o) => {
                    return moment(o.operationLog.doneOn);
                },
                ['asc']
            );
        }

        $scope.clickCaseRow = function(hasImage, id){
           if(hasImage){
            try{
                document.getElementById(id).scrollIntoView();
            } catch{}
           }
        }

        function getCaseImages(cases, deviationDocuments) {
            var allKundeavvikCases = useFilterOnCases(cases)
                .filter(c => (c.description.toLowerCase().includes('kundeavvik') || (c.logAction && c.logAction.isCustomerDeviation)))
                .map((deviation) => {
                    var componentNumber = getComponentIdFromCase(deviation);
                    return {
                        ...deviation,
                        componentNumber: componentNumber,
                    };
                });

            if (deviationDocuments && deviationDocuments.length) {
                var items = deviationDocuments;
                var caseImages = [];
                items.forEach((item) => {
                    if (item && item.files && item.files.length) {
                        var relatedCase = allKundeavvikCases.find((x) => {
                            return x.operationLogId === item.relatedEntityId;
                        });
                        if (relatedCase) {
                            var componentNumber = getComponentIdFromCase(relatedCase);
                            var caseStatus = relatedCase.workflowStatuses.length && _.find(deviationStatuses, { Id: relatedCase.workflowStatuses[0].workflowStatusId })
                                ? _.find(deviationStatuses, { Id: relatedCase.workflowStatuses[0].workflowStatusId }).Name
                                : '';
                            item.files.forEach((file) => {
                                if (file && file.mediaId) {
                                    var caseImageObject = {
                                        imageUrl: getMediaUrl(file.mediaId),
                                        caseId: relatedCase.caseId,
                                        componentNumber: componentNumber,
                                        caseType: relatedCase.description,
                                        caseDescription: relatedCase.comment,
                                        caseState: caseStatus,
                                        correctiveAction: relatedCase.correctiveAction,
                                        operationLogId: relatedCase.operationLogId,
                                    };
                                    caseImages.push(caseImageObject);
                                }
                            });
                        }
                    }
                });

                $scope.finalReport.caseImages = caseImages;
            }
            else {
                $scope.finalReport.caseImages = [];
            }
            dataPartLoadingCheck({name: 'caseImageIsLoaded', value: true});
        }

        function getComponentIdFromCase(deviation) {
            var componentNumber = '';
            if (deviation.rings && deviation.rings.length) {
                componentNumber = deviation.rings[0].serialNumber;
            } else if (deviation.nets && deviation.nets.length) {
                componentNumber = deviation.nets[0].netId;
            } else if (deviation.cages && deviation.cages.length) {
                componentNumber = deviation.cages[0].cageNumber;
            } else if (deviation.lines && deviation.lines.length) {
                componentNumber = deviation.lines[0].description;
            }
            return componentNumber;
        }

        function getCageNumberFromCase(deviation) {
            var cageNumber = '';
            if (deviation.rings && deviation.rings.length && deviation.rings[0].cage) {
                cageNumber = deviation.rings[0].cage.cageNumber;
            } else if (deviation.nets && deviation.nets.length && deviation.nets[0].cage) {
                cageNumber = deviation.nets[0].cage.cageNumber;
            }

            return cageNumber;
        }

        function initializeCaptainMessagesCasesAndDiversObject(){
            return {
                captainMessages: [],
                cases: [],
                divers: []
            }
        }

        function handleCaptainMessageAndCaseAndDiverPage(skipperMessage, deviations){
            $scope.finalReport.captainMessagesCasesAndDivers = [];
            var captainMessagesCasesAndDivers = initializeCaptainMessagesCasesAndDiversObject();
            $scope.maxRowCount = 32;
            $scope.rowCount = 0;

            if(skipperMessage){
                $scope.maxRowCount = 28;
                var captainMessages = skipperMessage;
                captainMessagesCasesAndDivers.captainMessages = [{comment: captainMessages}];
                $scope.rowCount = 4;
            }

            if(deviations && deviations.length){
                var maxTextLength = 60;
                var cases = useFilterOnCases(deviations)
                    .filter(c => (c.workflowStatuses.length && c.workflowStatuses[0].workflowStatusId !== '99999999-9999-9999-9999-999999999999') &&
                        (c.description.toLowerCase().includes('kundeavvik') || (c.logAction && c.logAction.isCustomerDeviation)))
                    .map((deviation) => {
                        var componentNumber = getComponentIdFromCase(deviation);
                        var cageNumber = getCageNumberFromCase(deviation);
                        var status = deviationStatuses.find(x => x.Id === deviation.workflowStatuses[0].workflowStatusId);
                        var statusText = status ? status.Name : '';
                        var commentRowLength = Math.ceil(deviation.comment.length / maxTextLength);
                        var correctiveActionRowLength = Math.ceil(deviation.correctiveAction.length / maxTextLength)
                        var rowHeight = commentRowLength < correctiveActionRowLength ? correctiveActionRowLength : commentRowLength;
                        var hasCaseImage = $scope.finalReport && $scope.finalReport.caseImages ? $scope.finalReport.caseImages.find(c => c.operationLogId == deviation.operationLogId) != null : false;

                        return {
                            ...deviation,
                            cageNumber: cageNumber,
                            statusText: statusText,
                            componentNumber: componentNumber,
                            rowHeight: rowHeight > 0 ? rowHeight: 1,
                            hasImage: hasCaseImage
                        };
                });

                cases = _.orderBy(cases, 'cageNumber', 'componentNumber', 'statusText', 'doneOn');

                while(cases.length){
                    var sumRowCount = 0;
                    cases.forEach((deviation) => {
                        sumRowCount += deviation.rowHeight;
                    })

                    if(sumRowCount + $scope.rowCount > $scope.maxRowCount){
                        var caseList = JSON.parse(JSON.stringify(cases))
                        var rowCountForPage = $scope.rowCount;
                        cases = [];
                        var casesOnThePage = [];
                        var isNewPageAdded = false;
                        caseList.forEach((deviation) => {
                            if(!isNewPageAdded && rowCountForPage + deviation.rowHeight < $scope.maxRowCount){
                                rowCountForPage = rowCountForPage + deviation.rowHeight;
                                casesOnThePage.push(deviation);
                            } else {
                                cases.push(deviation);
                                isNewPageAdded = true;
                            }
                        });

                        captainMessagesCasesAndDivers.cases = casesOnThePage;
                        $scope.finalReport.captainMessagesCasesAndDivers.push(captainMessagesCasesAndDivers);
                        captainMessagesCasesAndDivers = initializeCaptainMessagesCasesAndDiversObject();
                        $scope.rowCount = 0;
                    } else {
                        captainMessagesCasesAndDivers.cases = cases;
                        $scope.rowCount = $scope.rowCount + sumRowCount;
                        cases = [];
                    }
                }
            }

            if(captainMessagesCasesAndDivers.captainMessages.length || captainMessagesCasesAndDivers.cases.length
                || captainMessagesCasesAndDivers.divers.length ){
                $scope.finalReport.captainMessagesCasesAndDivers.push(captainMessagesCasesAndDivers);
            }

            dataPartLoadingCheck({name: 'captainMessageAndCaseAndDiverPageIsLoaded', value: true});
        }

        function getComponentImages(componentImages) {
            componentImages = componentImages.filter(
                x => !x.file.fileName.includes("Holdekraft") &&
                (
                    $scope.componentFilter.cages.find(y => x.componentNumber === y.cageNumber && y.isChecked)       ||
                    $scope.componentFilter.rings.find(y => x.componentNumber === y.serialNumber && y.isChecked)     ||
                    $scope.componentFilter.nets.find(y => x.componentNumber === y.netId.toString() && y.isChecked)  ||
                    $scope.componentFilter.lines.find(y => x.componentNumber === y.description && y.isChecked)
                )
            )
            componentImages.forEach((componentImage) => {
                componentImage.imageUrl = getMediaUrl(componentImage.file.mediaId);
                componentImage.description = componentImage.file.description;
            });
            $scope.finalReport.componentImages = componentImages;
            dataPartLoadingCheck({name: 'componentImageIsLoaded', value: true});
        }

        function getMediaUrl(mediaId) {
            return mediaService.createMediaUrlMediaBank(mediaId);
        }

        function setOrderDisplayName() {
            $scope.finalReport.order.orderDisplayText =
                ($scope.finalReport.order.customer && $scope.finalReport.order.customer.name
                    ? $scope.finalReport.order.customer.name
                    : '') +
                ($scope.finalReport.order.orderProcess && $scope.finalReport.order.orderProcess.name
                    ? ' - ' + $scope.finalReport.order.orderProcess.name
                    : '');
        }

        function getLineComponents(lineComponents) {
            var filteredLinesIds = $scope.componentFilter.lines.filter(c => c.isChecked == true).map(m => m.lineId);
            lineComponents  = lineComponents.filter(l => filteredLinesIds.includes(l.lineId));

            var groups = _.groupBy(lineComponents, (x) => x.lineId)
            var lineComponentGroups = [];
            _.forOwn(groups, (value, key) => {
                if (Array.isArray(value)) {
                    value = _.orderBy(value, 'lineSequenceNumber');
                    value.forEach(lineComponent => {
                        lineComponent.measurementType = lineComponent.measurementUnitType &&
                            lineComponent.measurementUnitType.measurementUnitDescription
                            ? lineComponent.measurementUnitType.measurementUnitDescription
                            : '',
                            lineComponent.insertedTimestamp = moment(new Date(lineComponent.insertedTimestamp)).format('DD.MM.YYYY HH:mm');
                    });
                    lineComponentGroups.push({lineComponentGroup: value, needHeader: true});
                }
            });

            var maxLinesOnOnePage = 22;

            for (var i = 0; i < lineComponentGroups.length; i++) {
                if(lineComponentGroups[i].lineComponentGroup.length > maxLinesOnOnePage){
                    lineComponentGroups.splice(i + 1, 0, { ...lineComponentGroups[i] });

                    lineComponentGroups[i].lineComponentGroup = lineComponentGroups[i].lineComponentGroup.slice(0, maxLinesOnOnePage);
                    lineComponentGroups[i + 1].lineComponentGroup = lineComponentGroups[i + 1].lineComponentGroup.slice(maxLinesOnOnePage);
                    lineComponentGroups[i + 1].needHeader = false;
                }
            }

            $scope.finalReport.lineComponentGroups = lineComponentGroups;
            dataPartLoadingCheck({name: 'lineComponentIsLoaded', value: true});
        }

        function setComponentTreeHistory(componentTreeHistory){
            $scope.componentTreeHistory = JSON.parse(componentTreeHistory);
            dataPartLoadingCheck({name: 'componentTreeIsLoaded', value: true});
        }

        $scope.$on('$stateChangeStart', function (event, next) {
            $('html').removeClass('auto-overflow');
        });

        function printReport() {
            $rootScope.showNavbar = false;
            $scope.printView = true;
            $('#main-view').addClass('print-view');
            $('#final-report-container').addClass('print-view-container');
            document.title =
                'Final-report-' + $scope.finalReport.order && $scope.finalReport.order.orderDisplayText
                    ? $scope.finalReport.order.orderDisplayText
                    : '' + kendo.toString(new Date(), 'HH:mm dd.MM.yyyy');

            // Give elements that should not be printed 'time to hide' before printing the html, workaround fixing bug where
            // report pdf first page is incorrectly aligned because navbar is still taking up space
            setTimeout(() => {
                window.print();
                
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'finalReportIndicator',
                    destination: '#main-view',
                    overlay: true,
                });

                $rootScope.showNavbar = true;
                $scope.printView = false;
                document.title = 'Naviaq Webinnsyn';
                $('#main-view').removeClass('print-view');
                $('#final-report-container').removeClass('print-view-container');
                $rootScope.$broadcast('hideBusyIndicator', 'finalReportIndicator');
            }, 30);
        }

        function openComponentFilter(){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Component filter',
                    resizable: true,
                    visible: false,
                    width: 400,
                    height: 535
                },
                templateUrl: 'app/report/finalReportComponents/final-report-component-filter-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'FinalReportComponentFilterModalController',
                resolve: {
                    componentFilter: $scope.componentFilter
                }
            });

            windowInstance.result.then(function (componentFilter) {
                if (componentFilter) {
                    $scope.componentFilter = componentFilter;
                    initController();
                }
            });
        }

        function useFilterOnCases(cases)
        {
            var filteredCases = [];
            if($scope.componentFilter){
                var filteredRingsIds = $scope.componentFilter.rings.filter(c => c.isChecked == true).map(m => m.ringId);
                var filteredNetsIds = $scope.componentFilter.nets.filter(c => c.isChecked == true).map(m => m.id);
                var filteredCageIds = $scope.componentFilter.cages.filter(c => c.isChecked == true).map(m => m.cageId);
                var filteredLinesIds = $scope.componentFilter.lines.filter(c => c.isChecked == true).map(m => m.lineId);

                _.forEach(cases, function (caseElement) {
                    var dirtyFlag = false;
                   if(caseElement.rings && caseElement.rings.length > 0){
                        dirtyFlag = true;
                        if(filteredRingsIds.includes(caseElement.rings[0].ringId)){
                            filteredCases.push(caseElement);
                        }
                   }

                   if(caseElement.nets && caseElement.nets.length > 0){
                        dirtyFlag = true;
                        if(filteredNetsIds.includes(caseElement.nets[0].id)){
                            filteredCases.push(caseElement);
                        }
                    }

                    if(caseElement.cages && caseElement.cages.length > 0){
                        dirtyFlag = true;
                        if(filteredCageIds.includes(caseElement.cages[0].cageId)){
                            filteredCases.push(caseElement);
                        }
                    }

                    if(caseElement.lines && caseElement.lines.length > 0){
                        dirtyFlag = true;
                        if(filteredLinesIds.includes(caseElement.lines[0].lineId)){
                            filteredCases.push(caseElement);
                        }
                    }

                    if(dirtyFlag == false){
                        filteredCases.push(caseElement);
                    }
                });

            }
            return filteredCases;
        }

        function useFilterOnServiceForms(serviceForms){
            var filteredServiceForm = [];
            var filteredRingsIds = $scope.componentFilter.rings.filter(c => c.isChecked == true).map(m => m.ringId);
            var filteredNetsIds = $scope.componentFilter.nets.filter(c => c.isChecked == true).map(m => m.id);
            var filteredCageIds = $scope.componentFilter.cages.filter(c => c.isChecked == true).map(m => m.cageId);
            var filteredLinesIds = $scope.componentFilter.lines.filter(c => c.isChecked == true).map(m => m.lineId);

            _.forEach(serviceForms, function (serviceForm) {
                var opLog = serviceForm.operationLog;
                var dirtyFlag = false;
                if(opLog.rings && opLog.rings.length > 0){
                    dirtyFlag = true;
                    if(filteredRingsIds.includes(opLog.rings[0].ringId)){
                        filteredServiceForm.push(serviceForm);
                    }
                }

               if(opLog.nets && opLog.nets.length > 0){
                    dirtyFlag = true;
                    if(filteredNetsIds.includes(opLog.nets[0].id)){
                        filteredServiceForm.push(serviceForm);
                    }
                }

                if(opLog.cages && opLog.cages.length > 0){
                    dirtyFlag = true;
                    if(filteredCageIds.includes(opLog.cages[0].cageId)){
                        filteredServiceForm.push(serviceForm);
                    }
                }

                if(opLog.lines && opLog.lines.length > 0){
                    dirtyFlag = true;
                    if(filteredLinesIds.includes(opLog.lines[0].lineId)){
                        filteredServiceForm.push(serviceForm);
                    }
                }

                if(dirtyFlag == false){
                    filteredServiceForm.push(serviceForm);
                }

            });
            return filteredServiceForm;
        }

        function collectComponents(order) {
            $scope.componentFilter = {
                rings: [],
                nets: [],
                lines: [],
                cages: []
            };

            if(order.rings && order.rings.length > 0){
                var addedRingIds = $scope.componentFilter.rings.map(r => r.ringId);
                var rings = order.rings.filter(r => !addedRingIds.includes(r.ringId)).map(m => ({
                    ...m,
                    isChecked: m.isChecked != null ? m.isChecked : true
                }));
                $scope.componentFilter.rings = $scope.componentFilter.rings.concat(rings);
                $scope.componentFilter.rings = $scope.componentFilter.rings.filter(x => x.serialNumber);
                $scope.componentFilter.rings.sort((a, b) => {
                    return a.serialNumber.toString().localeCompare(b.serialNumber.toString());
                });
            }

            if(order.nets && order.nets.length > 0){
                var addedNetIds = $scope.componentFilter.nets.map(r => r.netId);
                var nets = order.nets.filter(r => !addedNetIds.includes(r.netId)).map(m => ({
                    ...m,
                    isChecked: m.isChecked != null ? m.isChecked : true
                }));
                $scope.componentFilter.nets = $scope.componentFilter.nets.concat(nets);
                $scope.componentFilter.nets = $scope.componentFilter.nets.filter(x => x.netId);
                $scope.componentFilter.nets.sort((a, b) => {
                    return a.netId.toString().localeCompare(b.netId.toString());
                });
            }

            if(order.cages && order.cages.length > 0){
                var addedCageIds = $scope.componentFilter.cages.map(r => r.cageId);
                var cages = order.cages.filter(r => !addedCageIds.includes(r.cageId)).map(m => ({
                    ...m,
                    isChecked: m.isChecked != null ? m.isChecked : true
                }));
                $scope.componentFilter.cages = $scope.componentFilter.cages.concat(cages);
                $scope.componentFilter.cages = $scope.componentFilter.cages.filter(x => x.cageNumber);
                $scope.componentFilter.cages.sort((a, b) => {
                    return a.cageNumber.toString().localeCompare(b.cageNumber.toString());
                });
            }

            if(order.lines && order.lines.length > 0){
                var addedLineIds = $scope.componentFilter.lines.map(r => r.lineId);
                var lines = order.lines.filter(r => !addedLineIds.includes(r.lineId)).map(m => ({
                    ...m,
                    isChecked: m.isChecked != null ? m.isChecked : true
                }));
                $scope.componentFilter.lines = $scope.componentFilter.lines.concat(lines);
                $scope.componentFilter.lines = $scope.componentFilter.lines.filter(x => x.description);
                $scope.componentFilter.lines.sort((a, b) => {
                    return a.description.toString().localeCompare(b.description.toString());
                });
            }
            dataPartLoadingCheck({name: 'collectComponentIsLoaded', value: true});
        }

        function dataPartLoadingCheck(part){
            $scope.loadingDataParts[part.name] = part.value;
            var loadedAllParts = Object.values($scope.loadingDataParts).every(item => item === true);
            if(loadedAllParts){
                $rootScope.$broadcast('hideBusyIndicator', 'finalReportIndicator');
                setTimeout(function () {
                    $scope.$apply(function () {
                        $scope.disabledPrintBtn = false;
                    });
                }, 2500);
            }
        }

        $scope.openLightbox = function(url){
            $scope.imageUrl = url;
            $scope.showLightBox = true;
        }

        $scope.downloadFile = function (video) {
            if (!video.file.naviaqMediaId) {
                window.open(mediaService.createMediaUrlMediaBank(video.file.mediaId), '_blank')
            } else {
                if (!video.url.includes("blob.core.windows.net")) {
                    window.open(fileItem.url, '_blank')
                } else {
                    confirmDialogService.openVideoDownloadWarningModal(video.sizeMb).then(function (response) {
                        if (response) {
                            anonymousReportService.downloadOrderRelatedVideo(video).then(function (url) {
                                window.open(url, '_blank');
                            }).catch(function (error) {
                                if (error.status === 400) {
                                    if (error.data === "DELETED") {
                                        const title = $translate.instant('DOWNLOAD_TITLE');
                                        const content = $translate.instant('DOWNLOAD_ARCHIVE_FILE_PROCESS_DELETED');
                                        $rootScope.openAlertModal = true;
                                        confirmDialogService.openWarningDialog(content, title).finally(() => {
                                            $rootScope.openAlertModal = false;
                                        });
                                    }
                                    if (error.data === "PENDING") {
                                        const title = $translate.instant('DOWNLOAD_TITLE');
                                        const content = $translate.instant('DOWNLOAD_ARCHIVE_FILE_PROCESS_STARTED');
                                        $rootScope.openAlertModal = true;
                                        confirmDialogService.openWarningDialog(content, title, false).finally(() => {
                                            $rootScope.openAlertModal = false;
                                        });
                                    }

                                    if (error.data === "ARCHIVED") {
                                        const title = $translate.instant('DOWNLOAD_ARCHIVED_TITLE');
                                        const content = $translate.instant('DOWNLOAD_ARCHIVED_FILE_CONFIRMATION');
                                        confirmDialogService.open({
                                            modalWidth: 450,
                                            modalHeight: 200
                                        }, content, title).then(response => {
                                            if (response) {
                                                anonymousReportService.moveFileFromArchivedToHotAccessTier($scope.orderId, video).then(function (response) {
                                                    if (response) {
                                                        const title = $translate.instant('DOWNLOAD_ARCHIVED_TITLE');
                                                        const content = $translate.instant('DOWNLOAD_ARCHIVE_FILE_PROCESS_STARTED');
                                                        $rootScope.openAlertModal = true;
                                                        confirmDialogService.openWarningDialog(content, title).finally(() => {
                                                            $rootScope.openAlertModal = false;
                                                        });
                                                    }
                                                }).catch(function (error) {
                                                    if (error.status === 400) {
                                                        const title = $translate.instant('DOWNLOAD_ARCHIVED_TITLE');
                                                        const content = $translate.instant('DOWNLOAD_ARCHIVE_FILE_PROCESS_STARTED');
                                                        $rootScope.openAlertModal = true;
                                                        confirmDialogService.openWarningDialog(content, title).finally(() => {
                                                            $rootScope.openAlertModal = false;
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            }
        }


        $scope.openFileListModal = function(files){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('CASE_MODAL_TAB_FILES'),
                    resizable: true,
                    visible: false,
                    width: 550,
                    height: 400
                },
                templateUrl: 'app/report/finalReportComponents/final-report-file-list-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'finalReportFileListController',
                resolve: {
                    files: function () {
                        return files;
                    },
                }
            });

            windowInstance.result.then(function () {

            });
        }
    }
})();
