(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationOverviewController', LocationOverviewController);

    LocationOverviewController.$inject = ['$q', '$rootScope', '$scope', '$state', 'kendoUtility',
        'authService', 'locationService', 'mediaService', 'locationLogService'];

    function LocationOverviewController($q, $rootScope, $scope, $state, kendoUtility,
        authService, locationService, mediaService, locationLogService) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();

        $scope.locationName = '';
        $scope.pageSize = 24;
        //Height adjusting
        var locationOverviewHeight;

        $scope.componentLifetimeFilterOptions = {
            dataSource: new kendo.data.DataSource({
                data: [
                    { id: 'All', text: 'Vis alle' },
                    { id: 'Days', text: 'Dager' },
                    { id: 'Week', text: 'Uke' },
                    { id: 'Month', text: 'Måned' },
                    { id: 'Year', text: 'År' },
                ]
            }),
            dataTextField: 'text',
            dataValueField: 'id',
            change: componentLifetimeFilterChanged
        };

        $scope.componentFilterOptions = {
            dataSource: new kendo.data.DataSource({
                data: [{ id: 1, text: 'Vis alle' }]
            }),
            dataTextField: 'text',
            dataValueField: 'id',
            change: componentFilterChanged
        };

        $scope.statusFilterOptions = {
            dataSource: new kendo.data.DataSource({
                data: [{ id: 1, text: 'Vis alle' }]
            }),
            dataTextField: 'text',
            dataValueField: 'id',
            change: statusFilterChanged
        };

        $scope.locationOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                data: [],
                group: { field: 'ExtendedLineDescription' }
            }),
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            resizable: true,
            filterable: true,
            groupable: true,
            scrollable: true,
            dataBinding: function () {
                locationOverviewHeight = $('#location-overview').height();
            },
            dataBound: locationOverviewGridDataBound,
            excel: {
                fileName: 'Anleggsoversikt.xlsx',
                filterable: true
            },
            //template: '<span kendo-tooltip k-content="\'{{ dataItem.Test }}\'">{{dataItem.Test}}</span>',
            excelExport: locationOverviewGridExcelExport,
            columns: [
                {
                    field: 'LocationLogId',
                    hidden: true
                }, {
                    field: 'ExtendedLineDescription',
                    title: 'Enhet',
                    groupHeaderTemplate: '#=value#',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.ExtendedLineDescription }}\'">{{dataItem.ExtendedLineDescription}}</span>',
                    width: 100
                }, {
                    field: 'LineComponentNumber',
                    title: 'Komp. nr',
                    width: 95
                }, {
                    field: 'Resource',
                    title: 'Kategori',
                    width: 95
                },
                {
                    field: 'ResourceCertificate',
                    title: 'Batch/Sertifikat',
                    template: '#if(UnitBatchMediaId) { #<a href="#:UnitBatchMediaUrl#" target="_blank">#:ResourceCertificate#</a> #} else {# <span>#:ResourceCertificate#</span> # }#',
                    width: 140
                }, {
                    field: 'ResourceDimension',
                    title: 'Produkt',
                    width: 150
                }, {
                    field: 'Amount',
                    title: 'Mengde',
                    width: 90
                }, {
                    field: 'DepthInMeters',
                    title: 'Dybde',
                    width: 80
                }, {
                    field: 'Comment',
                    title: 'Kommentar',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.Comment }}\'">{{dataItem.Comment}}</span>',
                    width: 150
                }, {
                    field: 'Y',
                    title: 'N',
                    width: 120
                }, {
                    field: 'X',
                    title: 'Ø',
                    width: 120
                }, {
                    field: 'HoldingForceOriginal',
                    title: 'Oppgitt (tonn)',
                    width: 120
                }, {
                    field: 'HoldingForceTested',
                    title: 'Testet (tonn)',
                    width: 120
                }, {
                    field: 'HoldingForceTime',
                    title: 'Tidd (minutt)',
                    width: 120
                }, {
                    field: 'FromDate',
                    title: 'Satt i sjø',
                    type: 'datetime',
                    width: 120
                }, {
                    field: 'LastDayInSea',
                    title: 'Siste dag i sjø',
                    type: 'datetime',
                    width: 120
                }, {
                    field: 'Department',
                    title: 'Avdeling',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.Department }}\'">{{dataItem.Department}}</span>',
                    width: 140
                }, {
                    field: 'OrderText',
                    title: 'Oppdrag',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.OrderText }}\'">{{dataItem.OrderText}}</span>',
                    width: 140
                }, {
                    field: 'LogAction',
                    title: 'Aktivitiet',
                    width: 100
                }, {
                    field: 'Timestamp',
                    title: 'Opprettet',
                    width: 100
                }, {
                    field: 'LastEditedTimestamp',
                    title: 'Endret',
                    width: 130
                }, {
                    field: 'LastEditedByUser',
                    title: 'Endret av',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.LastEditedByUser }}\'">{{dataItem.LastEditedByUser}}</span>',
                    width: 100
                }, {
                    field: 'ObjectStatusText',
                    title: 'Status',
                    width: 100
                }, {
                    field: 'MediaUrl',
                    title: 'Media',
                    template: '#if(MediaUrl) { #<a href="#:MediaUrl#" target="_blank" class="media-url">Vis</a> #} else {# <span></span> # }#',
                    width: 80
                }]
        };

        $scope.exportToExcel = exportToExcel;

        initController();

        function initController() {
            var authData = authService.getAuthData();
            if (authData.contractorId && $state.params.LocationNumber) {
                locationService.getLocationBaseByLocationNumber($state.params.LocationNumber).then(function (location) {
                    $scope.locationName = location.locationName;

                    $scope.locationOverviewGrid.options.excel.fileName = 'Anleggsoversikt' + ' - ' + $scope.locationName + '.xlsx';
                });
            }

            $('#locationOverviewGrid').width($(window).width());

            $rootScope.$broadcast('showMainBusyIndicator', 'locationOverview');

            $q.all([
                locationLogService.getLocationLogsByFilter({ locationNumber: $state.params.LocationNumber })
            ]).then(function (data) {
                var locationLogs = data[0];

                //Extended log info
                $scope.locationLogs = [];
                _.each(locationLogs, function (locationLog) {
                    if (locationLog.lineDescription) {
                        $scope.locationLogs.push({
                            LocationLogId: locationLog.locationLogId,
                            ExtendedLineDescription: getExtendedLineDescription(locationLog),
                            MapObjectType: locationLog.mapObjectType,
                            LineComponentNumber: locationLog.lineComponent ? locationLog.lineComponent.lineComponentSequenceNumber : '',
                            Resource: locationLog.resourceTypeName ? locationLog.resourceTypeName : '',
                            ResourceCertificate: locationLog.resourceCertificate ? locationLog.resourceCertificate : '',
                            ResourceDimension: locationLog.resourceDimention,
                            Amount: locationLog.amount,
                            DepthInMeters: locationLog.depthInMeters,
                            Comment: locationLog.comment,
                            X: locationLog.x ? geolib.decimal2sexagesimal(locationLog.x) : '',
                            Y: locationLog.y ? geolib.decimal2sexagesimal(locationLog.y) : '',
                            HoldingForceOriginal: locationLog.holdingForce_Original ? locationLog.holdingForce_Original : '',
                            HoldingForceTested: locationLog.holdingForce_Tested ? locationLog.holdingForce_Tested : '',
                            HoldingForceTime: locationLog.holdingForce_Time ? locationLog.holdingForce_Time : '',
                            FromDate: locationLog.lineComponent && locationLog.lineComponent.fromDate ? moment(locationLog.lineComponent.fromDate).format('DD.MM.YYYY') : '',
                            LastDayInSea: locationLog.expirationDate ? moment(locationLog.expirationDate).format('DD.MM.YYYY') : '',
                            Department: locationLog.departmentName ? locationLog.departmentName  : '',
                            OrderText: locationLog.orderProcessName ? locationLog.orderProcessName  : '', //Build order text
                            LogAction: locationLog.logActionDescription ? locationLog.logActionDescription : '',
                            Timestamp: locationLog.timestamp ? moment(locationLog.timestamp).format('DD.MM.YYYY') : '',
                            LastEditedTimestamp: locationLog.lastEditedTimestamp ? moment(locationLog.lastEditedTimestamp).format('DD.MM.YYYY HH:mm') : '',
                            LastEditedByUser: locationLog.lastEditedByUser,
                            ObjectStatusText: getObjectStatusText(locationLog),

                            MediaUrl: locationLog.mediaUrl, //TODO?

                            UnitBatchMediaId: locationLog.unitBatch && locationLog.unitBatch.mediaId ? locationLog.unitBatch.mediaId : '',
                            UnitBatchMediaUrl: locationLog.unitBatch && locationLog.unitBatch.mediaId ? mediaService.createMediaUrlMediaBank(locationLog.unitBatch.mediaId) : ''
                        });
                    }
                });

                $scope.locationOverviewGrid.dataSource.data($scope.locationLogs);
                $scope.locationOverviewGrid.refresh();

                //Status dropdown
                var models = $scope.locationOverviewGrid.dataItems();
                var statuses = _.uniq(_.map(models, 'ObjectStatusText'));
                var statusTexts = _.concat(['Vis alle'], statuses);
                var id = 1;

                $scope.statusFilterOptions.dataSource.data(_.map(statusTexts, function (statusText) {
                    return { id: id++, text: statusText };
                }));

                //Component dropdown
                var components = _.uniq(_.map(models, function (model) {
                    return getObjectTypeName(model.MapObjectType);
                }));
                var componentTexts = _.concat(['Vis alle'], components);

                id = 1;
                $scope.componentFilterOptions.dataSource.data(_.map(componentTexts, function (componentText) {
                    return { id: id++, text: componentText };
                }));

                $rootScope.$broadcast('hideBusyIndicator', 'locationOverview');
            });
        }

        function componentLifetimeFilterChanged(e) {
            if (e.sender.value() !== 'All') {
                var selectedItem = _.find(e.sender.dataItems(), { id: e.sender.value() }),
                    lifetimeFilterValue = '';
                switch (selectedItem.id) {
                    case 'Days':
                        lifetimeFilterValue = moment().add(14, 'days');
                        break;
                    case 'Week':
                        lifetimeFilterValue = moment().add(1, 'weeks');
                        break;
                    case 'Month':
                        lifetimeFilterValue = moment().add(1, 'months');
                        break;
                    case 'Year':
                        lifetimeFilterValue = moment().add(1, 'years');
                        break;
                    default:
                        break;
                }
                var filteredLogs = _.filter($scope.locationLogs, function (l) { return moment(l.LastDayInSea,'DD.MM.YYYY') <= lifetimeFilterValue});
                $scope.locationOverviewGrid.dataSource.data(filteredLogs);
            }
            else {
                $scope.locationOverviewGrid.dataSource.data($scope.locationLogs);
            }
            $scope.locationOverviewGrid.refresh();
        }

        function componentFilterChanged(e) {
            var currentFilter = $scope.locationOverviewGridOptions.dataSource.filter(),
                statusTextFilter = '';

            if (e.sender.value() !== '1') {
                var selectedItem = _.find(e.sender.dataItems(), { id: parseInt(e.sender.value()) });
                statusTextFilter = selectedItem.text;
            }

            var newFilter = { field: 'ExtendedLineDescription', operator: 'startswith', value: statusTextFilter };
            if (!currentFilter) {
                $scope.locationOverviewGridOptions.dataSource.filter(newFilter);
            } else {
                var lineDescFilter = _.find(currentFilter.filters, { field: 'ExtendedLineDescription' });
                if (lineDescFilter) {
                    lineDescFilter.value = statusTextFilter;
                } else {
                    currentFilter.filters.push(newFilter);
                }

                $scope.locationOverviewGridOptions.dataSource.filter(currentFilter);
            }
        }

        function statusFilterChanged(e) {
            var currentFilter = $scope.locationOverviewGridOptions.dataSource.filter(),
                statusTextFilter = '';

            if (e.sender.value() !== '1') {
                var selectedItem = _.find(e.sender.dataItems(), { id: parseInt(e.sender.value()) });
                statusTextFilter = selectedItem.text;
            }

            var newFilter = { field: "ObjectStatusText", operator: "startswith", value: statusTextFilter };
            if (!currentFilter) {
                $scope.locationOverviewGridOptions.dataSource.filter(newFilter);
            } else {
                var lineDescFilter = _.find(currentFilter.filters, { field: 'ObjectStatusText' });
                if (lineDescFilter) {
                    lineDescFilter.value = statusTextFilter;
                } else {
                    currentFilter.filters.push(newFilter);
                }

                $scope.locationOverviewGridOptions.dataSource.filter(currentFilter);
            }
        }

        function locationOverviewGridDataBound(e) {
            if (this.content.height() > locationOverviewHeight - this.content.offset().top) {
                var newContentHeight = locationOverviewHeight - this.content.offset().top + $('#location-overview .left-menu').height();

                this.content.height(newContentHeight);
            }
            var dataItems = e.sender.dataSource.data();
            for (var i = 0; i < dataItems.length; ++i) {
                if (dataItems[i].lastDayInSea && moment(dataItems[i].lastDayInSea, 'DD.MM.YYYY') <= moment().add(14, 'days')) {
                    var itemRow = e.sender.element.find(':contains(' + dataItems[i].locationLogId + ')').closest('tr');
                    itemRow.addClass('marked-grid-row-red');
                }
            }
        }

        function locationOverviewGridExcelExport(e) {
            var sheet = e.workbook.sheets[0];
            var columnWidths = this.element.find('.k-grid-header colgroup col');

            for (var i = 0; i < sheet.columns.length; i++) {
                if ($(columnWidths[i]).attr('style')) {
                    sheet.columns[i].width = 120;
                } else {
                    sheet.columns[i].width = 12;
                }
            }
        }

        function exportToExcel() {
            $scope.locationOverviewGrid.saveAsExcel();
        }

        //Private functions
        function getObjectTypeName(mapObjectType) {
            switch (mapObjectType) {
                case 'Line':
                    return 'Line';
                case 'Net':
                    return 'Not';
                case 'Ring':
                    return 'Flytekrage';
                case 'Cage':
                    return 'Bur';
                case 'Barge':
                    return 'Flåte';
            }

            return '';
        }

        function getExtendedLineDescription(locationLog) {
            return locationLog ? 'Line: ' + locationLog.lineDescription : '';
        }

        function getObjectStatusText(locationLog) {
            switch (locationLog.status) {
                case 1: //New
                    return 'Aktive';
                case 2: //Updated
                    return 'Erstattet';
            }

            return '';
        }
    }
})();
