(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .factory('adminOrderRequestTypeService', adminOrderRequestTypeService);

        adminOrderRequestTypeService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function adminOrderRequestTypeService(
        $q,
        $http,
        serviceUrls,
    ) {
        var adominOrderRequestApiUrl = `${serviceUrls.webApiBaseUrl}/adminOrderRequestType`;
        return {
            getOrderRequestTypes: getOrderRequestTypes,
            addOrderRequestTypes: addOrderRequestTypes,
            updateOrderRequestTypes: updateOrderRequestTypes
        };

        function getOrderRequestTypes() {
            const deferred = $q.defer();
            var requestUrl = `${adominOrderRequestApiUrl}`;
            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addOrderRequestTypes(orderRequestType) {
            const deferred = $q.defer();
            var requestUrl = `${adominOrderRequestApiUrl}`;
            $http
                .post(requestUrl, orderRequestType)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateOrderRequestTypes(orderRequestType) {
            const deferred = $q.defer();
            var requestUrl = `${adominOrderRequestApiUrl}`;
            $http
                .put(requestUrl, orderRequestType)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
