(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('welcomeMailModalController', welcomeMailModalController);

    welcomeMailModalController.$inject = ['$rootScope', '$scope', '$windowInstance', '$translate', 'adminUserService', 'userName'];

    function welcomeMailModalController($rootScope, $scope, $windowInstance, $translate, adminUserService, userName) {
        $scope.userName = userName;
        $scope.ccEmail = '';
        $scope.submitDisabled = false;

        $scope.ok = ok;
        $scope.close = close;

        function ok() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'reSendWelcomeMail',
                destination: '.k-widget.k-window',
                overlay: true
            });

            adminUserService.sendWelcomeMail({ userName: $scope.userName, ccEmail: $scope.ccEmail })
                .then((response) => {
                    if (response) {
                        alert($translate.instant('ADMIN_USER_WELCOME_MAIL_SUCCESS').replace('[email]', $scope.userName));
                        close();
                    }
                })
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'reSendWelcomeMail'));
        }

        function close() {
            $windowInstance.close();
        }

        $scope.ccEmailChanged = () => {
            $scope.submitDisabled = !$scope.validator.validate();
        }
    }
})();
