(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationOrdersController', LocationOrdersController);

    LocationOrdersController.$inject = ['$state', '$stateParams', '$translate', '$scope', '$rootScope','orderService', 'kendoUtility'];

    function LocationOrdersController($state, $stateParams, $translate, $scope, $rootScope, orderService, kendoUtility) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.fromDate = null;
        $scope.toDate = null;

        $scope.ttipOptions.show = function (e) {
            if (e.sender.content[0].textContent.length > 0 && e.sender.content[0].textContent !== 'Vis oppdrag') {
                e.sender.popup.element.removeClass('k-tooltip-hidden');
            }
            e.sender.popup.element.addClass('k-tooltip-nowrap');
        };

        $scope.applyFilter = applyFilter;
        $scope.showOrderDetails = showOrderDetails;
        $scope.getOrderStatusText = getOrderStatusText;

        initController();
        function initController() {

            //Set default date filter
            var currentDate = new Date();

            $scope.fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
            $scope.toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

            const locationOrdersGridOptions = langId => ({
                dataSource: {
                    transport: {
                        read: readLocationOrders
                    }
                },
                sortable: true,
                resizable: true,
                filterable: {
                    name: 'FilterMenu',
                    extra: true
                },
                noRecords: true,
                messages: {
                    noRecords: 'Ingen treff'
                },
                columns: [{
                    field: 'ExternalOrderId',
                    title: '#',
                    width: '90px'
                }, {
                    field: 'DepartmentsDisplayText',
                    title: $translate.instant('MAIN_LOCATION_ORDERS_GRID_DEPARTMENT', null, null, langId),
                    width: '200px'
                }, {
                    field: 'OrderProcessName',
                    title: $translate.instant('MAIN_LOCATION_ORDERS_GRID_PROCESS', null, null, langId),
                    width: '120px'
                }, {
                    field: 'OrderDate',
                    title: $translate.instant('MAIN_LOCATION_ORDERS_GRID_STARTDATE', null, null, langId),
                    type: 'date',
                    format: '{0:dd.MM.yyyy}',
                    width: '100px'
                }, {
                    field: 'ExpireDate',
                    title: $translate.instant('MAIN_LOCATION_ORDERS_GRID_ENDDATE', null, null, langId),
                    type: 'date',
                    format: '{0:dd.MM.yyyy}',
                    width: '100px'
                }, {
                    field: 'ComponentNameList',
                    title: $translate.instant('MAIN_LOCATION_ORDERS_GRID_DEVICES', null, null, langId),
                    encoded: false,
                    attributes: {
                        'class': 'component-description'
                    }
                }, {
                    field: 'OrderStatus',
                        title: $translate.instant('MAIN_LOCATION_ORDERS_GRID_STATUS', null, null, langId),
                    template: '{{getOrderStatusText(dataItem.OrderStatus)}}'
                }, {
                    field: 'OrderId',
                    filterable: false,
                    sortable: false,
                    title: ' ',
                    width: '130px',
                        template: '<button class="btn btn-default btn-sm grid-button"  ng-touch=showOrderDetails($event,dataItem)  ng-mouseup=showOrderDetails($event,dataItem)>' + $translate.instant('MAIN_LOCATION_ORDERS_GRID_SHOWASSIGNMENTS', null, null, langId) +'</button>'
                }]
            });
            $scope.locationOrdersGridOptions = locationOrdersGridOptions($translate.use());

            $scope.$on('langChanged', (event, code) => {
                $scope.locationOrdersGridOptions = locationOrdersGridOptions(code);
                const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.locationOrdersGridOptions);
                kendoUtility.changeLangOfWidget(code, [() => mainGrid('#locationOrdersGrid')]);
            });
        }

        function applyFilter() {
            $scope.locationOrdersGrid.dataSource.read();
        }

        function showOrderDetails(event, dataItem) {
            if (event.button === 1) {
                var url = $state.href('main.orderReport', {
                    ContractorId: dataItem.ContractorId,
                    OrderId: dataItem.OrderId
                });
                window.open(url, '_blank');
            }
            else {
                $state.go('main.orderReport', {
                    ContractorId: dataItem.ContractorId,
                    OrderId: dataItem.OrderId
                });
            }
        }

        function getOrderStatusText(orderStatus) {
            return orderService.getOrderStatusText(orderStatus);
        }

        function readLocationOrders(e) {
            var locationRequestContractorId = $scope.authData.contractorId;

            //If user is in role LocationOwnerViewer then get all orders for location not just contractor specific ones
            if ($scope.authData && ($scope.authData.isLocationOwner || $scope.authData.isSuperuser)) {
                locationRequestContractorId = undefined;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationOrders',
                destination: '#right-pane',
                overlay: false
            });

            var filter = {
                contractorId: locationRequestContractorId,
                startDate: $scope.fromDate,
                endDate: $scope.toDate,
                locationNumber: $stateParams.LocationNumber
            };

            orderService.getOrdersByLocationNumber(filter).then((orders) => {
                e.success(orders);

                $rootScope.$broadcast('hideBusyIndicator', 'locationOrders');
            })
        }

        function wrapInSpan(text) {
            return '<span>' + text + '</span>';
        }
    }
})();
