(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('locationOwnerAdministrationController', locationOwnerAdministrationController);

    locationOwnerAdministrationController.$inject = ['$q', '$scope', '$rootScope', '$translate', 'kendoUtility', 'adminLocationOwnerService', 'formTypes', '$kWindow'];

    function locationOwnerAdministrationController($q, $scope, $rootScope, $translate, kendoUtility, adminLocationOwnerService, formTypes, $kWindow) {
        $scope.addLocationOwner = addLocationOwner;
        $scope.locationOwners = [];

        const locationOwnerGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.locationOwners),
                },
                schema: {
                    model: {
                        id: 'locationOwnerId',
                        fields: {
                            name: { editable: false, type: 'string' }
                        }
                    },
                }
            }),
            columns: [
                {
                    field: 'name',
                    title: $translate.instant('G_NAME', null, null, langId),
                },
                {
                    field: 'contractorNames',
                    title: $translate.instant('ADMIN_CONGLOMERATE_CONTRACTORS', null, null, langId),
                },
                {
                    command: [
                        { name: 'editLocationOwner', click: e => editLocationOwner(e), template: '<a class="k-grid-editLocationOwner"><i class="fa fa-edit grid-icon"></i></a>' },
                        { name: 'deleteLocationOwner', click: e => deleteLocationOwner(e), template: '<a ng-show="{{!dataItem.contractors.length}}" class="k-grid-deleteLocationOwner"><i class="fas fa-trash"></i></a>' }
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            editable: false,
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.locationOwnerGridOptions = locationOwnerGridOptions($translate.use());

        $scope.$on('langChanged', (_, langId) => {
            $scope.locationOwnerGridOptions = locationOwnerGridOptions(langId);
            const locationOwnerGrid = kendoUtility.createWidget('kendoGrid', $scope.locationOwnerGridOptions);
            kendoUtility.changeLangOfWidget(langId, [() => locationOwnerGrid('#location-owner-grid')]);
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationOwnersLoad',
                destination: '#location-owner-administration',
                overlay: true,
            });

            $q.all({
                getLocationOwners: adminLocationOwnerService.getLocationOwners(),
                getLocations: adminLocationOwnerService.getLocations()
            })
                .then(data => {
                    $scope.locationOwners = _.orderBy(data.getLocationOwners, 'name');
                    $scope.locations = data.getLocations;

                    $scope.locations.forEach(l => {
                        l.displayName = `${l.locationNumber} - ${l.name}`
                    });

                    $scope.locationOwners.forEach(lo => {
                        lo.contractorNames = lo.contractors.map(x => x.name).join(', ')
                    });

                    $scope.locationOwnerGridOptions.dataSource.read();
                })
                .catch(error => console.error(error))
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'locationOwnersLoad'));
        }

        function editLocationOwner(event) {
            const locationOwner = getLocationOwner(event);
            const updateModal = openLocationOwnerModal(formTypes.edit, locationOwner.locationOwnerId);
            locationOwner && updateModal.result.then(result => result && initController());
        }

        function getLocationOwner(event) {
            event.preventDefault();
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $("#location-owner-grid").data("kendoGrid").dataItem(row).toJSON();
        }

        function addLocationOwner() {
            const addModal = openLocationOwnerModal(formTypes.add);

            addModal.result.then(result => result && initController());
        }

        function openLocationOwnerModal(formType, locationOwnerId = null) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant(formType === formTypes.add ? 'ADMIN_LOCATION_OWNER_ADD' : 'G_EDIT'),
                    resizable: false,
                    visible: false,
                    width: 550
                },
                templateUrl: 'app/systemAdministration/locationOwner/location-owner-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'locationOwnerDetailsModalController',
                resolve: {
                    formType: () => formType,
                    locationOwnerId: () => locationOwnerId,
                    locations: () => $scope.locations,
                    locationOwnerIds: () => $scope.locationOwners.map(x => x.locationOwnerId)
                }
            });
        }

        function deleteLocationOwner(event) {
            const locationOwner = getLocationOwner(event);
            const deleteModal = openDeleteModal();

            locationOwner && deleteModal.result.then(response => {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'deleteLocationOwner',
                        destination: '#location-owner-administration',
                        overlay: true
                    });

                    adminLocationOwnerService.deleteLocationOwner(locationOwner.locationOwnerId)
                        .then(result => result && initController())
                        .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'deleteLocationOwner'));
                }
            });
        }

        function openDeleteModal() {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('G_DELETE'),
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
        }
    }
})();
