(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('downloadVideoWarningModalController', downloadVideoWarningModalController);

    downloadVideoWarningModalController.$inject = ['$scope', '$windowInstance', 'size'];

    function downloadVideoWarningModalController($scope, $windowInstance, size) {
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.size = size;

        initController();
        function initController() {
            $windowInstance.rendered.then(function () {
                $('.k-overlay').click(cancel);
            });
        }

        function ok() {
            $windowInstance.close(true);
        }

        function cancel() {
            $windowInstance.close(false);
        }
    }
}) ();
