angular.module('NaviaqWebApp').directive('nSplitter', ['$state', '$rootScope', function ($state, $rootScope) {
    'use strict';

    var half_opened_states = ['main.locationOrders', 'main.vesselDetails', 'main.vesselPersonel', 'main.vesselEquipment', 'main.vesselOrders', 'main.vesselmLink'];

    function getPaneSizes(stateName) {
        var paneSizes = {
            left: '100%',
            right: '0%'
        };

        if (half_opened_states.indexOf(stateName) >= 0) {
            paneSizes.left = '50%';
            paneSizes.right = '50%';
        } else if (stateName === 'main.orderReport') {
            paneSizes.left = '60%';
            paneSizes.right = '40%';
        }

        return paneSizes;
    }

    return {
        restrict: 'AE',
        replace: true,
        transclude: true,
        templateUrl: 'app/shared/directives/n-splitter.html',
        link: function (scope, element, attributes, controller, transclude) {
            var paneSizes = getPaneSizes($state.current.name);

            $('#splitter').kendoSplitter({
                orientation: 'horizontal',
                panes: [
                    { collapsible: false, size: paneSizes.left },
                    { collapsible: false, size: paneSizes.right }
                ],
                resize: function (resizeEvent) {
                    if ($state.current.name !== 'main') {
                        //Always needs to read toggle on resize
                        var splitbar = $('.k-splitbar'),
                            splitter = resizeEvent.sender;

                        splitbar.find('.k-resize-handle').remove();
                        splitbar.append('<div class="splitter-toggle"><i class="fa fa-chevron-left" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></div>');

                        var $splitterToggle = $('#splitter .splitter-toggle');
                        $splitterToggle.css('top', (resizeEvent.height / 2 - $splitterToggle.height() / 2) + 'px');

                        if (splitter.options.panes[1].size === '0%'
                            || splitter.options.panes[1].size === '0px') {
                            $splitterToggle.addClass('closed');
                            $splitterToggle.removeClass('opened');
                        } else {
                            $splitterToggle.addClass('opened');
                            $splitterToggle.removeClass('closed');
                        }

                        $splitterToggle.mousedown(function () {
                            var paneSizes = getPaneSizes($state.current.name);

                            if (splitter.options.panes[1].size === '0%'
                                || splitter.options.panes[1].size === '0px') {
                                splitter.options.panes[0].size = paneSizes.left;
                                splitter.options.panes[1].size = paneSizes.right;
                            } else {
                                splitter.options.panes[0].size = '100%';
                                splitter.options.panes[1].size = '0%';
                            }

                            splitter.resize(true);
                        });
                    } else{
                        var splitter = $('#splitter').data('kendoSplitter');
                        if (splitter && splitter.options && splitter.options.panes){
                            $rootScope.$broadcast('splitterResized', {
                                leftPaneSize: splitter.options.panes[0].size,
                                rightPaneSize: splitter.options.panes[1].size
                            });
                        }
                      
                    }
                }
            });

            scope.$on('setSplitterSize', function (event, paneSizes) {
                var splitter = $('#splitter').data('kendoSplitter');
                splitter.options.panes[0].size = paneSizes.left;
                splitter.options.panes[1].size = paneSizes.right;
                splitter.resize(true);
            });
        }
    }
}]);
