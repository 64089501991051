(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationChangeLogController', LocationChangeLogController);

    LocationChangeLogController.$inject = ['$q', '$scope', '$stateParams', '$translate', '$rootScope', 'authService', 'mainHistoryService','kendoUtility'];

    function LocationChangeLogController($q, $scope, $stateParams, $translate, $rootScope, authService, mainHistoryService, kendoUtility) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.contractorId = '';
        $scope.authData = null;
        $scope.logFromDate = null;
        $scope.logToDate = null;
        $scope.componentChangeLogsData = [];
        $scope.ringChangeLogsData = [];
        $scope.netChangeLogsData = [];
        $scope.applyFilter = applyFilter;

        const componentChangeLogsGridOptions = langId => ({
            dataSource: {
                data: $scope.componentChangeLogsData
            },
            height: '250px',
            sortable: true,
            resizable: true,
            filterable: true,
            noRecords: true,
            messages: {
            noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [
                {
                    field: 'timestamp',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_ASSIGNED', null, null, langId),
                    width: '150px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.timestamp }}\'" >{{ dataItem.timestamp }}</span>',
                },
                {
                    field: 'lineDescription',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_LINE', null, null, langId),
                    width: '80px'
                },
                {
                    field: 'resourceTypeName',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_CATEGORY', null, null, langId),
                    width: '120px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.resourceTypeName }}\'" >{{ dataItem.resourceTypeName }}</span>',

                },
                {
                    field: 'resourceDimention',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_DIMENSJON', null, null, langId),
                    width: '200px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.resourceDimention }}\'" >{{ dataItem.resourceDimention }}</span>',
                },
                {
                    field: 'resourceCertificate',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_CERTIFICATE', null, null, langId),
                    width: '120px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.resourceCertificate }}\'" >{{ dataItem.resourceCertificate }}</span>',
                },
                {
                    field: 'amount',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_QUANTITY', null, null, langId),
                    width: '65px'
                },
                {
                    field: 'billed',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_BILLED', null, null, langId),
                    width: '80px'
                },
                {
                    field: 'x',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_X', null, null, langId),
                    width: '65px',
                    format: "{0:0.00}"

                },
                {
                    field: 'y',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_Y', null, null, langId),
                    width: '65px',
                    format: "{0:0.00}"
                },
                {
                    field: 'z',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_Z', null, null, langId),
                    width: '65px',
                    format: "{0:0.00}"
                },
                {
                    field: 'comment',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_COMMENT', null, null, langId),
                    width: '200px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.comment }}\'" >{{ dataItem.comment }}</span>',
                },
                {
                    field: 'secundaryComment',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_ADDITIONALCOMMENT', null, null, langId),
                    width: '200px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.secundaryComment }}\'" >{{ dataItem.secundaryComment }}</span>',
                },
                {
                    field: 'createdByUser',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_CREATEDBY', null, null, langId),
                    width: '120px'
                },
                {
                    field: 'lastEditedByUser',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_CHANGEDBY', null, null, langId),
                    width: '120px'
                },
                {
                    field: 'lastEditedTimestamp',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_COMPONENTGRID_CHANGED', null, null, langId),
                    width: '150px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.lastEditedTimestamp }}\'" >{{ dataItem.lastEditedTimestamp }}</span>',
                }
            ]
        });
        $scope.componentChangeLogsGridOptions = componentChangeLogsGridOptions($translate.use());

        const netChangeLogsGridOptions = langId => ({
            dataSource: {
                data: $scope.netChangeLogsData
            },
            height: '250px',
            sortable: true,
            resizable: true,
            filterable: true,
            noRecords: true,
            messages: {
            noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [
                {
                    field: 'netNetId',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_NETGRID_NOTNR', null, null, langId),
                    width: '90px'
                },
                {
                    field: 'addedOn',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_NETGRID_PUTINTHESEA', null, null, langId),
                    width: '150px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.addedOn }}\'" >{{ dataItem.addedOn }}</span>',
                },
                {
                    field: 'addedByUserName',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_NETGRID_REGISTEREDBY', null, null, langId)
                },
                {
                    field: 'removedOn',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_NETGRID_REMOVEDDATE', null, null, langId),
                    width: '150px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.removedOn }}\'" >{{ dataItem.removedOn }}</span>',
                },
                {
                    field: 'removedByUserName',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_NETGRID_REMOVEDBY', null, null, langId)
                },
                {
                    field: 'locationText',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_NETGRID_LOCALITY', null, null, langId),
                    width: '200px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.locationText }}\'" >{{ dataItem.locationText }}</span>',
                },
                {
                    field: 'cageNumber',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_NETGRID_CAGE', null, null, langId)
                }
            ]
        });
        $scope.netChangeLogsGridOptions = netChangeLogsGridOptions($translate.use());

        const ringChangeLogsGridOptions = langId => ({
            dataSource: {
                data: $scope.ringChangeLogsData
            },
            height: '250px',
            sortable: true,
            resizable: true,
            filterable: true,
            noRecords: true,
            messages: {
            noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [
                {
                    field: 'serialNumber',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_RINGGRID_SERIALNUMBER', null, null, langId),
                    width: '90px'
                },
                {
                    field: 'addedOn',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_RINGGRID_PUTINTTHESEA', null, null, langId),
                    width: '150px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.addedOn }}\'" >{{ dataItem.addedOn }}</span>',
                },
                {
                    field: 'addedByUserName',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_RINGGRID_REGISTEREDBY', null, null, langId)
                },
                {
                    field: 'removedOn',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_RINGGRID_REMOVEDDATE', null, null, langId),
                    width: '150px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.removedOn }}\'" >{{ dataItem.removedOn }}</span>',
                },
                {
                    field: 'removedByUserName',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_RINGGRID_REMOVEDBY', null, null, langId)
                },
                {
                    field: 'locationText',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_RINGGRID_LOCALITY', null, null, langId),
                    width: '200px',
                    template: '<span kendo-tooltip k-content="\'{{ dataItem.locationText }}\'" >{{ dataItem.locationText }}</span>',
                },
                {
                    field: 'cageNumber',
                    title: $translate.instant('MAIN_LOCATION_CHANGELOG_RINGGRID_CAGE', null, null, langId)
                },
            ]
        });
        $scope.ringChangeLogsGridOptions = ringChangeLogsGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.componentChangeLogsGridOptions = componentChangeLogsGridOptions(code);
            const componentGrid = kendoUtility.createWidget('kendoGrid', $scope.componentChangeLogsGridOptions);

            $scope.netChangeLogsGridOptions = netChangeLogsGridOptions(code);
            const netGrid = kendoUtility.createWidget('kendoGrid', $scope.netChangeLogsGridOptions);

            $scope.ringChangeLogsGridOptions = ringChangeLogsGridOptions(code);
            const ringGrid = kendoUtility.createWidget('kendoGrid', $scope.ringChangeLogsGridOptions);

            kendoUtility.changeLangOfWidget(code, [
                () => componentGrid('#componentChangeLogsGrid'),
                () => netGrid('#netChangeLogsGrid'),
                () => ringGrid('#ringChangeLogsGrid')
            ]);
        });

        initController();
        function initController() {

            //Set default date filter
            var currentDate = new Date();
            $scope.logFromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
            $scope.logToDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            $scope.authData = authService.getAuthData();
            if ($scope.authData.isAuth) {
                $scope.contractorId = $scope.authData.contractorId;
            }

            readData();
        };

        function readData() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'rightPane',
                destination: '#right-pane'
            });

            $q.all([
                readComponentChangeLogRequest(),
                readNetChangeLogRequest(),
                readRingChangeLogRequest()
            ]).then(function () {
                $scope.componentChangeLogsGrid.dataSource.data($scope.componentChangeLogsData);
                $scope.componentChangeLogsGrid.refresh();

                $scope.netChangeLogsGrid.dataSource.data($scope.netChangeLogsData);
                $scope.netChangeLogsGrid.refresh();

                $scope.ringChangeLogsGrid.dataSource.data($scope.ringChangeLogsData);
                $scope.ringChangeLogsGrid.refresh();

                $rootScope.$broadcast('hideBusyIndicator', 'rightPane');
                });
        }

        function applyFilter() {
            readData();
        }

        function readComponentChangeLogRequest() {
            var deferred = $q.defer();
            mainHistoryService.getLocationLogsByFilter($stateParams.LocationNumber, $scope.logFromDate, $scope.logToDate)
                .then(function (locationLogs) {
                    $scope.componentChangeLogsData = [];
                    _.each(locationLogs, function (locationLog) {
                        locationLog.timestamp = moment(locationLog.timestamp).format('DD.MM.YYYY HH:mm:ss'),
                            locationLog.lastEditedTimestamp = moment(locationLog.lastEditedTimestamp).format('DD.MM.YYYY HH:mm:ss'),
                            $scope.componentChangeLogsData.push(locationLog);
                    });
                    deferred.resolve([]);
                });
            return deferred.promise;
        }

        function readNetChangeLogRequest() {
            var deferred = $q.defer();
            mainHistoryService.getNetHistoryByFilter($stateParams.LocationNumber, $scope.logFromDate, $scope.logToDate)
                .then(function (netHistories) {
                    $scope.netChangeLogsData = [];
                    _.each(netHistories, function (netHistory) {
                        let locationText = `${netHistory.locationNumber} - ${netHistory.locationName}`;
                        if (netHistory.location.locationTypeId === mainHistoryService.landBaseTypeId) {
                            locationText = netHistory.location.name;
                        }

                        var netItem = {
                            addedOn: moment(netHistory.addedOn).format('DD.MM.YYYY HH:mm:ss'),
                            addedByUserName: netHistory.addedByUserName,
                            removedOn: netHistory.removedOn ? moment(netHistory.removedOn).format('DD.MM.YYYY HH:mm:ss') : '-',
                            removedByUserName: netHistory.removedByUserName,
                            locationText: locationText,
                            cageNumber: netHistory.cage ? netHistory.cage.cageNumber : '-',
                            netNetId: netHistory.net ? netHistory.net.netId : '-'
                        };

                        $scope.netChangeLogsData.push(netItem);
                    });
                    deferred.resolve([]);
                });
            return deferred.promise;

        }
        function readRingChangeLogRequest() {
            var deferred = $q.defer();
            mainHistoryService.getRingHistoryByFilter($stateParams.LocationNumber, $scope.logFromDate, $scope.logToDate)
                .then(function (ringHistories) {
                    $scope.ringChangeLogsData = [];
                    _.each(ringHistories, function (ringHistory) {
                        let locationText = `${ringHistory.locationNumber} - ${ringHistory.locationName}`;
                        if (ringHistory.location.locationTypeId === mainHistoryService.landBaseTypeId) {
                            locationText = ringHistory.location.name;
                        }

                        var ringItem = {
                            addedOn: moment(ringHistory.addedOn).format('DD.MM.YYYY HH:mm:ss'),
                            addedByUserName: ringHistory.addedByUserName,
                            removedOn: ringHistory.removedOn ? moment(ringHistory.removedOn).format('DD.MM.YYYY HH:mm:ss') : '-',
                            removedByUserName: ringHistory.removedByUserName,
                            locationText: locationText,
                            cageNumber: ringHistory.cage ? ringHistory.cage.cageNumber : '-',
                            serialNumber: ringHistory.ring ? ringHistory.ring.serialNumber : '-'
                        };

                        $scope.ringChangeLogsData.push(ringItem);
                    });
                    deferred.resolve([]);
                });
            return deferred.promise;
        }
    }
})();
