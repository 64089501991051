//Solely required to enable anonymous mode as authService
//cannot be injected to authInterceptorService
(function() {
    angular.module('NaviaqWebApp').factory('localStorageAuthWrapper', localStorageAuthWrapper);

    localStorageAuthWrapper.$inject = ['localStorageService'];

    function localStorageAuthWrapper(localStorageService) {
        let authData = {
            isAnonymous: false,
            token: '',
            contractorId: ''
        };

        return {
            getAuthData,
            setAnonymous
        };

        function getAuthData() {
            if (authData.isAnonymous) {
                return authData;
            }

            return localStorageService.get('authenticationData');
        }

        function setAnonymous(token, contractorId) {
            authData = {
                isAnonymous: true,
                token: token,
                contractorId: contractorId
            };
        }
    }
})();
