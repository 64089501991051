(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('dayCounterService', dayCounterService);

    dayCounterService.$inject = ['$q', '$http', 'serviceUrls'];

    function dayCounterService($q, $http, serviceUrls) {
        const dayCounterApiUrl = `${serviceUrls.webApiBaseUrl}/daycounter`;

        return {
            getDayCounters,
            addDayCounter,
            updateDayCounter,
            updateDayCounterByPatch,
            setDayCounterActiveStatus,
        };
        function getDayCounters(contractorId) {
            const deferred = $q.defer(),
                requestUrl = dayCounterApiUrl;
            $http({
                    url: requestUrl,
                    method: "GET",
                    params: { contractorId: contractorId }
                }).then(
                    function (response) {
                        deferred.resolve(response.data);
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );

            return deferred.promise;
        }

        function addDayCounter(dayCounter) {
            const deferred = $q.defer(),
            requestUrl = dayCounterApiUrl;
            $http
                .post(requestUrl, dayCounter)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateDayCounter(dayCounter) {
            const deferred = $q.defer(),
            requestUrl = dayCounterApiUrl;
            $http
                .put(requestUrl, dayCounter)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateDayCounterByPatch(dayCounter) {
            const deferred = $q.defer(),
            requestUrl = dayCounterApiUrl;
            $http
                .patch(requestUrl, dayCounter)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function setDayCounterActiveStatus(dayCounterTypeId, status) {
            const deferred = $q.defer(),
            requestUrl = `${dayCounterApiUrl}/setActiveStatus?dayCounterTypeId=${dayCounterTypeId}&status=${status}`;
            $http
                .post(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
