(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('authService', authService);

    authService.$inject = [
        '$rootScope',
        '$q',
        '$http',
        '$state',
        'localStorageService',
        'localStorageAuthWrapper',
        'serviceUrls',
        'departmentService',
        'roleService',
        'userService',
        'objectPropertyNameHandlerService',
        'clientType'
    ];

    function authService(
        $rootScope,
        $q,
        $http,
        $state,
        localStorageService,
        localStorageAuthWrapper,
        serviceUrls,
        departmentService,
        roleService,
        userService,
        objectPropertyNameHandlerService,
        clientType
    ) {
        let authServiceVersion = '1.1.1';
        let webApiBaseUrl = serviceUrls.webApiBaseUrl;
        let userPath = '/user';

        var authenticationData = {
            isAnonymous: false,
            isAuth: false,
            token: '',
            username: '',
            userId: '',
            displayName: '',
            contractorId: '',
            activeDepartment: null,
            authUser: null,
            roles: [],
            modules: []
        };

        $rootScope.$on(
            'selectedDepartmentChanged',
            function (eventName, data) {
                authService.fillAuthData();
                authenticationData.contractorId = data.contractorId;
                authenticationData.contractor = data.contractor;
                authenticationData.activeDepartment = data.activeDepartment;

                localStorageService.set(
                    'authenticationData',
                    authenticationData
                );

                $rootScope.authData = authenticationData;

                $state.reload();
            }
        );

        function handleAuthentication(deferred, response, username){
            if (response && response.data) {
                if (response.data.isLockedOut || response.data.isDeactived) {
                    deferred.reject(response.data);
                }
                else if (response.data.token && response.data.token !== '') {
                    //Save authenticationData, so the authInterceptorService can inject it to the subsequent calls' header
                    localStorageService.set('authenticationData', { token: response.data.token, username: username });

                    $q.all([
                        $http.get(
                            webApiBaseUrl + userPath +
                            '/user'
                        ),
                        departmentService.getAllDepartmentsWebapi()
                    ]).then(function (resultList) {
                        var userResult = objectPropertyNameHandlerService.setCamelCaseToPascalCase(resultList[0]).Data;
                        var departments = resultList[1];

                        $q.all([
                            userService.getModulesByContractorId(userResult.ContractorId),
                            roleService.getRolesByUserId(userResult.UserId)
                        ]).then(function (results) {
                            var modules = results[0],
                                roles = results[1];

                            var authData = localStorageService.get('authenticationData');

                            authData.version = authServiceVersion;
                            authData.isAuth = true;
                            authData.isLocationOwner = userResult.IsLocationOwner;
                            authData.isSuperuser = userResult.IsSuperUser;
                            authData.displayName = userResult.DisplayName;
                            authData.username = userResult.UserName;
                            authData.userId = userResult.UserId;
                            authData.contractorId = userResult.ContractorId;
                            authData.activeDepartment = _.find(departments, { departmentId: userResult.ActiveDepartmentId });
                            authData.authUser = userResult;
                            authData.roles = roles;
                            authData.modules = modules;
                            authData.preferredLanguageId = userResult.PreferredLanguageId;

                            let language = userResult.PreferredLanguageId || "no"

                            localStorage['NG_TRANSLATE_LANG_KEY'] = language.toLowerCase();
                            localStorageService.set('authenticationData', authData);

                            authService.fillAuthData();
                            deferred.resolve(authData);
                        }, function () {
                            authService.logout();
                            deferred.reject();
                        });
                    }, function () {
                        authService.logout();
                        deferred.reject();
                    });

                } else {
                    authService.logout();
                    deferred.reject();
                }
            }
            else {
                authService.logout();
                deferred.reject();
            }
        }

        var authService = {
            fillAuthData: function () {
                var authData = localStorageService.get('authenticationData');
                if (authData) {
                    authenticationData.version = authData.version;
                    authenticationData.isAuth = authData.isAuth;
                    authenticationData.isLocationOwner = authData.isLocationOwner;
                    authenticationData.isSuperuser = authData.isSuperuser;
                    authenticationData.token = authData.token;
                    authenticationData.username = authData.username;
                    authenticationData.userId = authData.userId;
                    authenticationData.displayName = authData.displayName;
                    authenticationData.contractorId = authData.contractorId;
                    authenticationData.activeDepartment = authData.activeDepartment;
                    authenticationData.authUser = authData.authUser;
                    authenticationData.roles = authData.roles;
                    authenticationData.modules = authData.modules;
                }
            },

            login: function (username, password) {
                var deferred = $q.defer();

                //Step 1) Authenticate user by getting a token
                $http.post(webApiBaseUrl + '/auth', { 'username': username, 'password': password }).then(function (response) {
                    handleAuthentication(deferred, response);
                }, function (error) {
                    authService.logout();
                    deferred.reject(error);
                });

                return deferred.promise;
            },

            loginWithMicrosoft:function (username, displayName, tenantId, aDUserId) {
                var deferred = $q.defer();

                //Step 1) Authenticate user by getting a token
                var request = {
                    username: username,
                    displayName: displayName,
                    tenantId: tenantId,
                    adUserId: aDUserId,
                }

                $http.post(webApiBaseUrl + '/auth/withMicrosoft', request).then(function (response) {
                    handleAuthentication(deferred, response, username);
                }, function (error) {
                    authService.logout();
                    deferred.reject(error);
                });

                return deferred.promise;
            },

            logout: function () {
                localStorageService.remove('authenticationData');

                authenticationData.version = authServiceVersion;
                authenticationData.isAuth = false;
                authenticationData.token = '';
                authenticationData.username = '';
                authenticationData.displayName = '';
                authenticationData.userId = '';
                authenticationData.contractorId = '';
                authenticationData.activeDepartment = null;
                authenticationData.authUser = null;
                authenticationData.roles = [];
                authenticationData.modules = [];
            },

            hasRole: function (roleKey) {
                if (!authenticationData.isAuth) {
                    return false;
                }

                for (var i = 0; i < authenticationData.roles.length; ++i) {
                    if (authenticationData.roles[i].key === roleKey) {
                        return true;
                    }
                }

                return false;
            },

            hasPermission: function (permissionKey) {
                if (!authenticationData.isAuth) {
                    return false;
                }

                var permissionKeys = _.flatten(authenticationData.roles.map(r => r.permissions)).map(p => p.key);
                return _.find(permissionKeys, p => p === permissionKey)
            },

            isUpToDate: function () {
                return authenticationData.version === authServiceVersion;
            },

            getAuthData: function () {
                return authenticationData;
            },

            validateUser: function (userName, password) {
                var deferred = $q.defer();

                $http.post(webApiBaseUrl + userPath + '/userValidation', { userName: userName, password: password })
                    .then(function (result) {
                        deferred.resolve(result.data);
                    }, function () {
                        deferred.reject();
                    });

                return deferred.promise;
            },

            changePassword: function (username, oldPassword, newPassword, newPasswordConfirmation) {
                var deferred = $q.defer();

                var body = {
                    userName: username,
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                    newPasswordConfirmation: newPasswordConfirmation
                };

                $http.post(webApiBaseUrl + userPath + '/passwordChangeWithValidation', body)
                    .then(function (result) {
                        deferred.resolve(result.data);
                    }, function () {
                        deferred.reject();
                    });

                return deferred.promise;
            },

            userExists: function (username) {
                var deferred = $q.defer();

                $http.get(webApiBaseUrl + userPath + '/exists?username=' + username)
                    .then(function (response) {
                        deferred.resolve(response.data);
                    }, function () {
                        deferred.reject();
                    });

                return deferred.promise;
            },

            requestPasswordChange: function (username) {
                var deferred = $q.defer();

                $http.get(webApiBaseUrl + userPath + '/password-change?username=' + username + '&clientType=' + clientType)
                    .then(function (response) {
                        deferred.resolve(response.data);
                    }, function () {
                        deferred.reject();
                    });

                return deferred.promise;
            },

            resetPassword: function (passwordChangeRequestId, password, confirmPassword) {
                var deferred = $q.defer();

                $http.post(webApiBaseUrl + userPath + '/password-reset', {
                    passwordChangeRequestId: passwordChangeRequestId,
                    password: password,
                    confirmPassword: confirmPassword
                }).then(function (response) {
                    deferred.resolve(response.data);
                }, function () {
                    deferred.reject();
                });;

                return deferred.promise;
            },

            setAnonymous: function (token, contractorId, userId) {
                authenticationData.version = authServiceVersion;
                authenticationData.isAnonymous = true;
                authenticationData.isAuth = true;
                authenticationData.token = token;
                authenticationData.contractorId = contractorId;
                authenticationData.userId = userId;

                localStorageAuthWrapper.setAnonymous(token, contractorId);
            },

            hasModule(moduleName) {
                if (authenticationData && authenticationData.modules) {
                    return authenticationData.modules
                    .map((m) => (m.isBan ? '' : m.name))
                    .includes(moduleName);
                }

                return false;
            },

            hasBanOnModule(moduleName) {
                if (authenticationData && authenticationData.modules) {
                    return authenticationData.modules
                    .map((m) => (m.isBan ? m.name : ''))
                    .includes(moduleName);
                }

                return false;
            }
        };

        return authService;
    }
})();
