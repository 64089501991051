(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationReferenceCreateModalController', LocationReferenceCreateModalController);

    LocationReferenceCreateModalController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'locationReference',
        'currentFormType',
        'formTypes',
        'contractorService',
        'locationService',
        'locationReferenceService'
    ];

    function LocationReferenceCreateModalController(
        $rootScope,
        $scope,
        $windowInstance,
        locationReference,
        currentFormType,
        formTypes,
        contractorService,
        locationService,
        locationReferenceService
    ) {
        $scope.locationReference = {};
        $scope.close = close;
        $scope.validateForm = validateForm;
        $scope.contractors = [];
        $scope.contractorId;
        $scope.locations = [];
        $scope.selectedLocationNumber;

        $scope.locationReferenceContent;

        $scope.getLocationsForContractor = getLocationsForContractor;

        initController();

        function initController() {
            $scope.contractorId = $rootScope.authData.contractorId;
        }

        $scope.locationDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: getLocationsForContractor
                }
            }),
            change: function () {
                //console.log($scope.contractorId);
                //console.log($scope.selectedLocationNumber);
            },
            dataTextField: 'name',
            dataValueField: 'locationNumber'
        };

        function getLocationsForContractor(e)
        {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationReferenceDetatailsForm',
                destination: '#location-reference-detatails-form',
                overlay: true
            });

            $scope.locations = [];
            $scope.selectedLocationNumber;

            locationService.getLocationsByContractorCustomersOfContractor($scope.contractorId)
                .then(response => {
                    response = _.orderBy(response, 'name', 'asc');
                    $scope.locations = response;

                    $rootScope.$broadcast('hideBusyIndicator', 'locationReferenceDetatailsForm');

                    e.success($scope.locations);
                })
                .catch(error => {
                    console.log(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'locationReferenceDetatailsForm');
                });
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {

                $scope.locationReference = {
                  contractorId: $scope.contractorId,
                  locationNumber: $scope.selectedLocationNumber,
                  locationReferenceContent: $scope.locationReferenceContent  
                };

                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'locationReferenceDetatailsWindow',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        locationReferenceService.addLocationReference($scope.locationReference)
                            .then(lr => close(lr))
                            .catch(() => close());
                        break;
                    case formTypes.edit:
                        locationReferenceService.updateLocationReference($scope.locationReference)
                            .then(lr => close(lr))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'locationReferenceDetatailsWindow');
            $windowInstance.close(param);
        }
    }
})();
