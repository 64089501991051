(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CustomersController', CustomersController);

    CustomersController.$inject = ['$scope', '$state', 'authService'];

    function CustomersController($scope, $state, authService) {
        $scope.tabStripOptions = {
            animation: false,
            select: function (e) {
                switch (e.item.id) {
                    case 'location-references-tab':
                        $state.go('customers.locationReferences');
                        break;
                    default:
                        break;
                }
            }
        };

        $scope.tabsVisibility = {
            locationReferences: true,
        };
        $scope.visibleTabIndex = 0;
        $scope.setVisibleTab = setVisibleTab;

        initController();

        function initController() {
            $scope.authData = authService.getAuthData();

            $scope.$watch('customerstab', function () {
                switch ($state.current.name) {
                    case 'customers.locationReferences':
                        $scope.customerstab.select(0);
                        $scope.visibleTabIndex = 0;
                        break;
                    default:
                        $scope.customerstab.select(0);
                        $scope.visibleTabIndex = 0;
                        break;
                }
            });
        }

        function setVisibleTab(index) {
            $scope.visibleTabIndex = index;
        }
    }
})();
