(function () {
    'use strict';
    // setting scope to dirty in there is a change to a listbox (order change, add/remove row)
    angular.module('NaviaqWebApp').directive('detectListboxChanges', function() {
      return {
        restrict: 'A',
        link: function(scope, element) {
          setTimeout(function() {
            var observer = new MutationObserver(function(mutations) {
              mutations.forEach(function(mutation) {
                var target = mutation.target;
                if (target.nodeName === 'UL' && target.classList.contains('k-list')) {
                  if (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0) {
                    scope.isDirty = true;
                    scope.$apply();
                  }
                }
              });
            });
    
            observer.observe(element[0], { childList: true, subtree: true });
          }, 1500); // Delayed start to avoid initial changes
        }
      };
    });
})();
