(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('operationLogDetailsDirective', operationLogDetailsDirective);

    operationLogDetailsDirective.$inject = [
        '$q',
        '$rootScope',
        'authService',
        'departmentService',
        'logService',
        'userService',
        'formTypes',
        'itemTreeService',
        'logActionTypes',
        'listService',
        'operationLogStatus',
        'DepartmentStatus',
        'logActionService'
    ];

    function operationLogDetailsDirective(
        $q,
        $rootScope,
        authService,
        departmentService,
        logService,
        userService,
        formTypes,
        itemTreeService,
        logActionTypes,
        listService,
        operationLogStatus,
        DepartmentStatus,
        logActionService
    ) {
        var directive = {
            restrict: 'E',
            replace: true,
            transclude: false,
            templateUrl: 'app/log/operation-log-details-directive.html',
            link: link,
            scope: {
                formType: '=formType',
                operationLog: '=operationLog',
                logType: '=logType',
                dateRange: '=dateRange',
                logPairItem: '=logPairItem'
            },
        };

        return directive;

        function link(scope, element, attrs) {
            scope.authData = authService.getAuthData();
            scope.logActionTypes = logActionTypes;
            scope.formTypes = formTypes;
            scope.listElements = [];
            scope.departmentId = scope.operationLog ? scope.operationLog.departmentId : null;
            scope.relatedItemId = scope.operationLog && scope.operationLog.itemId ? scope.operationLog.itemId : null;
            scope.kendoOperationLogDate = new Date(scope.operationLog.doneOn);
            scope.itemElapsedInterval = null;
            scope.close = close;
            scope.save = save;
            scope.setDateToNow = setDateToNow;
            scope.hasUserAssignRole = hasUserAssignRole;
            scope.dateChanges = dateChanges;
            scope.isTimeBankType = scope.logType == 'timebank' ? true : false;
            scope.isLogOverlapped = false;

            scope.operationLogDepartmentsOptions = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: readDepartments,
                    },
                }),
                dataValueField: 'departmentId',
                dataTextField: 'name',
                noDataTemplate: 'Ingen treff',
                select: departmentSelected,
            };

            scope.operationLogEquipmentOptions = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: readEquipments,
                    },
                }),
                dataValueField: 'id',
                dataTextField: 'description',
                noDataTemplate: 'Ingen treff',
                select: equipmentSelected,
            };

            scope.operationLogLogActionsOptions = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: readLogActions,
                    },
                }),
                dataValueField: 'logActionId',
                dataTextField: 'description',
                noDataTemplate: 'Ingen treff',
                change: getLogActionListElements,
            };

            scope.operationLogPersonOptions = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: readUsers,
                    },
                }),
                sort: [{ field: "displayName", dir: "asc" }],
                dataValueField: 'userId',
                dataTextField: 'displayName',
                noDataTemplate: 'Ingen treff',
            };

            scope.listElementsOptions = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: e => e.success(scope.listElements),
                    },
                }),
                dataValueField: 'value',
                dataTextField: 'displayName',
            };

            init();

            function init() {
                if (scope.formType === scope.formTypes.edit || scope.formType === scope.formTypes.readOnly) {
                    scope.departmentId = scope.operationLog ? scope.operationLog.departmentId : null;
                    scope.operationLogDescription = scope.operationLog.description;
                    scope.operationLogComment = scope.operationLog.comment;
                    scope.kendoOperationLogDate = new Date(scope.operationLog.doneOn);
                    scope.operationLogValue = scope.operationLog.value;

                    var minYear = new Date(new Date().setFullYear(new Date().getFullYear() - 30));
                    var maxYear = new Date(new Date().setFullYear(new Date().getFullYear() + 30));
                    if (scope.logPairItem == 'startlog' && scope.dateRange) {
                        scope.customOptions = {
                            min: minYear,
                            max: scope.dateRange,
                        }
                    } else if (scope.logPairItem == 'endlog' && scope.dateRange) {
                        scope.customOptions = {
                            min: scope.dateRange,
                            max: maxYear,
                        }
                    }
                    if (scope.operationLog.logAction) {
                        scope.selectedLogActionId = scope.operationLog.logAction.logActionId;
                    } else {
                        scope.selectedLogActionId = scope.operationLog.logActionId;
                    }
                } else {
                    //formType add
                    scope.departmentId = getActiveDepartmentId(scope);
                }
            }

            function getLogActionListElements() {
                if (scope.selectedLogAction && scope.selectedLogAction.listId) {
                    listService.getListElementsByListId(scope.selectedLogAction.listId)
                    .then((result) => {
                        scope.listElements = result;

                        if (scope.formType === scope.formTypes.edit) {
                            scope.selectedListElement = scope.listElements.find((le => le.value === scope.operationLog.description));
                        }

                        scope.listElementsOptions.dataSource.read();
                    });
                }
            }

            function departmentSelected(e) {
                if (e && e.dataItem) {
                    scope.itemElapsedInterval = null;
                }
            }

            function equipmentSelected(e) {
                if (e && e.dataItem && e.dataItem.validityInterval) {
                    scope.itemElapsedInterval = e.dataItem.validityInterval.intervalElapsed;
                } else {
                    scope.itemElapsedInterval = null;
                }
            }

            function setDateToNow() {
                scope.kendoOperationLogDate = new Date();
            }


            function updateAssignedUser(operationLog) {
                var deferred = $q.defer();

                var upsertPromises = [];
                if (scope.selectedPerson && scope.selectedPerson != null) {
                    if (
                        operationLog.assignedUser[0] &&
                        scope.selectedPerson.userId !== operationLog.assignedUser[0].userId
                    ) {
                        deleteOperationLogAssignedUser(operationLog.assignedUser[0].operationLogAssinedUserId).then(
                            function (data) {
                                upsertPromises.push(assignUser(operationLog.operationLogId));
                            }
                        );
                    }
                }

                $q.all(upsertPromises).then(
                    function (data) {
                        deferred.resolve(data);
                    },
                    function () {
                        deferred.reject();
                    }
                );

                return deferred.promise;
            }

            function assignUser(operationLogId) {
                var deferred = $q.defer();

                var upsertPromises = [];
                if (operationLogId && scope.selectedPerson) {
                    var assignedUser = {
                        operationLogId: operationLogId,
                        userId: scope.selectedPerson.userId,
                        role: 'TimeBankUser',
                        createdBy: scope.authData.username,
                        createdOn: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                        status: 0,
                    };
                    scope.operationLog.assignedUser[0] = assignedUser;
                    upsertPromises.push(logService.addOperationLogAssignedUserWebApi(assignedUser));
                }

                $q.all(upsertPromises).then(
                    function (data) {
                        deferred.resolve(data);
                    },
                    function () {
                        deferred.reject();
                    }
                );

                return deferred.promise;
            }

            function deleteOperationLogAssignedUser(operationLogAssignedUserId) {
                var deferred = $q.defer();

                var upsertPromises = [];
                if (scope.selectedPerson) {
                    upsertPromises.push(logService.deleteOperationLogAssignedUser(operationLogAssignedUserId));
                }

                $q.all(upsertPromises).then(
                    function (data) {
                        deferred.resolve(data);
                    },
                    function () {
                        deferred.reject();
                    }
                );

                return deferred.promise;
            }

            function getActiveDepartmentId(scope){
                if (!scope || !scope.authData || !scope.authData.activeDepartment || !scope.authData.activeDepartment.departmentId){
                    return null
                }
                return scope.authData.activeDepartment.departmentId;
            }

            function readDepartments(e) {
                departmentService.getDepartmentsWebapi(scope.authData.contractorId, true).then(function (departments) {
                    var departmentToSelect = _.find(departments, { departmentId: scope.departmentId });

                    if (departmentToSelect) {
                        scope.selectedDepartment = departmentToSelect;
                    }

                    if (
                        scope.formType === scope.formTypes.readOnly &&
                        departmentToSelect === undefined &&
                        scope.operationLog.department
                    ) {
                        scope.selectedDepartmentId = scope.operationLog.department.departmentId;
                        departments.push({
                            departmentId: scope.operationLog.department.departmentId,
                            name: scope.operationLog.department.name,
                        });
                    }

                    e.success(departments.filter(d => d.status === DepartmentStatus.Active || (d.departmentId == scope.departmentId)));
                });
            }

            function readEquipments(e) {
                if (scope.departmentId) {
                    itemTreeService
                        .getItemsByRelatedEntityIdWebApi(scope.authData.contractorId, scope.departmentId)
                        .then(function (itemsOfDepartment) {
                            if (scope.relatedItemId) {
                                itemTreeService.getItemById(scope.relatedItemId).then(function (relatedItem) {
                                    scope.selectedEquipment = relatedItem;
                                });
                            }
                            e.success(_.sortBy(itemsOfDepartment, ['description']));
                        });
                } else {
                    e.success([]);
                }
            }

            function readLogActions(e) {
                var filter = {
                    contractorId: scope.authData.contractorId,
                    status: 0
                };

                logActionService.getActiveLogActionsAsDropdownByFilter(filter).then(
                    function (logActions) {
                        //If log action of an equipment, filter for vessel & operationAndVessel only
                        if (scope.relatedItemId) {
                            logActions = logActions.filter(function (logAction) {
                                return (
                                    (logAction.logActionType === logActionTypes.vesselLog ||
                                        logAction.logActionType === logActionTypes.operationAndVessel ||
                                        logAction.logActionType === scope.operationLog.logAction.logActionType)
                                );
                            });
                        } else {
                            logActions = logActions.filter(function (logAction) {
                                return (
                                    (logAction.logActionType === logActionTypes.vesselLog ||
                                        logAction.logActionType === logActionTypes.operationAndVessel ||
                                        logAction.logActionType === logActionTypes.timeBankLog ||
                                        logAction.logActionType === scope.operationLog.logAction.logActionType)
                                );
                            });
                        }

                        if (scope.formType === scope.formTypes.edit) {
                            var logActionToSelect = _.find(logActions, { logActionId: scope.operationLog.logActionId });
                            scope.selectedLogAction = logActionToSelect;
                            getLogActionListElements();

                            scope.operationLogLogActions.enable(false);
                        } else if (scope.formType === scope.formTypes.readOnly) {
                            var relatedLogAction = _.find(logActions, { logActionId: scope.operationLog.logActionId });

                            if (relatedLogAction === undefined && scope.operationLog.logAction) {
                                logActions.push(scope.operationLog.logAction);
                            }
                            scope.operationLogLogActions.enable(false);
                        }

                        e.success(logActions);
                    },
                    function () {
                        e.success([]);
                    }
                );
            }

            function readUsers(e) {
                userService.getUsersAsDropdownByFilter({contractorIds: [scope.authData.contractorId]}).then(
                    function (users) {
                        if (scope.formType === scope.formTypes.add) {
                            var personToSelect = _.find(users, {
                                userName: scope.authData.username,
                            });
                            scope.selectedPerson = personToSelect;
                        }
                        if (scope.formType === scope.formTypes.edit) {
                            if (scope.operationLog.assignedUser && scope.operationLog.assignedUser[0]) {
                                var personToSelect = _.find(users, {
                                    userId: scope.operationLog.assignedUser[0].userId,
                                });
                                scope.selectedPerson = personToSelect;
                            }
                        }
                        // filter users not relevant for current contractor
                        var filteredUsers = users.filter(u =>
                                u.userId == (scope.selectedPerson != null ? scope.selectedPerson.userId : null) ||
                                u.contractorId == scope.authData.contractorId
                        );

                        e.success(filteredUsers);
                    },
                    function () {
                        e.success([]);
                    }
                );
            }


            function hasUserAssignRole() {
                if ( scope.formType === scope.formTypes.edit){
                    if(scope.selectedLogAction &&
                    scope.selectedLogAction.logActionType == logActionTypes.timeBankLog &&
                    (scope.authData.isSuperuser || scope.authData.roles.find((x) => x.key === 'AssignUserToLog'))){
                        return true;
                    }
                }else if(scope.authData.isSuperuser || scope.authData.roles.find((x) => x.key === 'AssignUserToLog')){
                    return true;
                }
                return false;
            }

            function save(close) {
                var deferred = $q.defer();

                // if(!scope.kendoOperationLogDate){
                //     scope.operationLogDate = null;
                // }

                if (scope.validator.validate()) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'LogPairBusyIndicator',
                        destination: '.k-widget.k-window',
                        overlay: true,
                    });
                    var now = new Date();

                    scope.operationLog.contractorId = scope.authData.contractorId;
                    scope.operationLog.departmentId = scope.operationLogDepartments.value();
                    scope.operationLog.logActionId = scope.operationLogLogActions.value();
                    scope.operationLog.itemId = scope.selectedEquipment ? scope.selectedEquipment.id : null;
                    scope.operationLog.description = scope.selectedLogAction.listId && scope.selectedListElement && scope.selectedListElement.value
                    ? scope.selectedListElement.value : scope.operationLogDescription;
                    scope.operationLog.comment = scope.operationLogComment;
                    scope.operationLog.createdOn = moment(now).format('YYYY-MM-DDTHH:mm:ss');
                    scope.operationLog.createdBy = scope.authData.username;
                    scope.operationLog.editedOn = moment(now).format('YYYY-MM-DDTHH:mm:ss');
                    scope.operationLog.editedBy = scope.authData.username;
                    scope.operationLog.doneOn = moment(scope.kendoOperationLogDate).format('YYYY-MM-DDTHH:mm:ss');
                    scope.operationLog.value = scope.operationLogValue;

                    if (scope.formType === scope.formTypes.add) {
                        scope.operationLog.status = 0;

                        logService.addOperationLogWebApi(scope.operationLog).then(
                            function (operationLogId) {
                                if (close) {
                                    $rootScope.$broadcast('closeLogPairEditor', true);
                                }
                                $q.all([assignUser(operationLogId)]).then(
                                    function () {
                                        scope.operationLog.doneOn = scope.kendoOperationLogDate;
                                        $rootScope.$broadcast('hideBusyIndicator', 'LogPairBusyIndicator');
                                        $rootScope.$broadcast('dateChanged', scope.operationLogDate, scope.logPairItem);
                                        deferred.resolve(operationLogId);
                                    },
                                    function () {
                                        deferred.reject();
                                    }
                                );
                            },
                            function () {
                                deferred.reject();
                                if(error && error.status == 400){
                                    scope.isLogOverlapped = true;
                                }
                            }
                        ).finally(function () {
                            $rootScope.$broadcast('hideBusyIndicator', 'LogPairBusyIndicator');
                        });
                    } else if (scope.formType === scope.formTypes.edit) {
                        logService.updateOperationLogWebApi(scope.operationLog).then(
                            function () {
                                if(scope.operationLog.status == operationLogStatus.Rejected){
                                    if(close){
                                        $rootScope.$broadcast('setStatusToRegistered', true );
                                    } else {
                                        $rootScope.$broadcast('setStatusToRegistered', false);
                                    }
                                } else {
                                    if (close) {
                                        $rootScope.$broadcast('closeLogPairEditor', true);
                                    }
                                }

                                $q.all([
                                    updateAssignedUser(scope.operationLog),
                                ]).then(
                                    function () {
                                        scope.operationLog.doneOn = scope.kendoOperationLogDate;
                                        $rootScope.$broadcast('hideBusyIndicator', 'LogPairBusyIndicator');
                                        $rootScope.$broadcast('dateChanged', scope.operationLogDate, scope.logPairItem);
                                        deferred.resolve(scope.operationLog.operationLogId);
                                    },
                                    function () {
                                        deferred.reject();
                                    }
                                );
                            },
                            function (error) {
                                deferred.reject();
                                if(error && error.status == 400){
                                    scope.isLogOverlapped = true;
                                }
                            }
                        ).finally(function () {
                            $rootScope.$broadcast('hideBusyIndicator', 'LogPairBusyIndicator');
                        });
                    }
                } else {
                    deferred.reject();
                }

                return deferred.promise;
            }

            function dateChanges(){
                scope.isLogOverlapped = false;
            }

            function close(result) {
                $rootScope.$broadcast('closeLogPairEditor', result);
            }

            function dateChanged(){
                if(!scope.kendoOperationLogDate){
                    scope.operationLogDate = null;
                }
            }

        }
    }
})();
