(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('gridPageSizeUtility', gridPageSizeUtility);

    gridPageSizeUtility.$inject = [];

    function gridPageSizeUtility() {
        var service = {
            calculatePageSize: calculatePageSize
        };

        return service;

        function calculatePageSize(id, rowHeight, removeableFromHeight) {
            var height = $('#' + id).height();
            if (height) {
                height -= removeableFromHeight || 0;
                return Math.floor(height / rowHeight || 36);
            }
            return 1;
        }
    }
})();
