(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('workOrderListTaskTablesDirective', workOrderListTaskTablesDirective);

    workOrderListTaskTablesDirective.$inject = [
        '$q',
        '$rootScope',
        '$translate',
        'workflowStatuses',
        'authService',
        'logService',
        'formTypes',
        'dateUtility',
        'workOrderConnectCaseModalService',
        'sanitizeService'];

    function workOrderListTaskTablesDirective(
        $q,
        $rootScope,
        $translate,
        workflowStatuses,
        authService,
        logService,
        formTypes,
        dateUtility,
        workOrderConnectCaseModalService,
        sanitizeService) {
        var directive = {
            restrict: 'E',
            replace: true,
            transclude: false,
            templateUrl: 'app/workOrders/work-order-list-task-tables.html',
            link: link,
            scope: {
                workOrder: '=workOrder',
                modalType: "=modalType"
            },
        };
        return directive;

        function link (scope, element, attrs) {
                scope.setVisibleTab = setVisibleTab;
                scope.cancelChanges = cancelChanges;
                scope.saveChanges = saveChanges;
                scope.exportToPDF = exportToPDF;
                scope.openConnectCaseModal = openConnectCaseModal;
                scope.visibleTabIndex = 0;
                scope.checklists = [];
                scope.isDirty = false;

                function setVisibleTab(index) {
                    scope.visibleTabIndex = index;
                }

                // Checklist

                var logChecklistGridOptionsDataSource = new kendo.data.DataSource({
                    transport: {
                        read: e => e.success(scope.checklists),
                        update: e => logChecklistGridUpdate(e)
                    },
                    toolbar: ['save', 'cancel'],
                    schema: {
                        model: {
                            id: 'checkListItemId',
                            fields: {
                                'checkListItemId': { editable: false},
                                'description': { editable: false },
                                'numericValue': { editable: true, type: 'number'},
                                'stringValue': { editable: true },
                            }
                        },
                    },
                    batch: false,
                });

                var logChecklistGridOptions = langId =>({
                    dataSource: logChecklistGridOptionsDataSource,
                    columns: [
                        {
                            field: 'checkListItemId',
                            width: 180,
                            hidden: true
                        },
                        {
                            field: 'description',
                            title:  $translate.instant('WORKORDER_MODAL_CHECKLISTS_NAME'),
                            width: 180,
                            template: function (dataItem) {
                                if (dataItem.dirty) {
                                    scope.isDirty = true;
                                }

                                return dataItem.description || '';
                            },
                        },
                        {
                            field: 'numericValue',
                            title: $translate.instant('WORKORDER_MODAL_CHECKLISTS_VALUE'),
                            width: 180,
                            template: function (dataItem) {
                                if (dataItem.dirty) {
                                    scope.isDirty = true;
                                }

                                return dataItem.numericValue || '';
                            },
                        },
                        {
                            field: 'stringValue',
                            title: $translate.instant('WORKORDER_MODAL_CHECKLISTS_COMMENT'),
                            width: 180,
                            template: function (dataItem) {
                                if (dataItem.dirty) {
                                    scope.isDirty = true;
                                }

                                return dataItem.stringValue || '';
                            },
                        },
                        {
                            command: [
                                {
                                    name: 'setMinus',
                                    click: e => setMinus(e),
                                    template: '<a class="k-grid-command-icon k-grid-setMinus"><i class="fas fa-minus-circle"></i></a>'
                                },
                                {
                                    name: 'setCross',
                                    click: e => setCross(e),
                                    template: '<a class="k-grid-command-icon k-grid-setCross"><i class="fas fa-times-circle"></i></a>'
                                },
                                {
                                    name: 'setCheck',
                                    click: e => setCheck(e),
                                    template: '<a class="k-grid-command-icon k-grid-setCheck"><i class="fas fa-check-circle"></i></a>'
                                },
                            ],
                            title: '&nbsp;',
                            width: 150
                        }
                    ],
                    height: 250,
                    scrollable: false,
                    editable: true,
                    save: function (e) {
                        $scope.$applyAsync(function () {
                           sanitizeService.sanitizingGridContent(e);
                       });
                   },
                    dataBound: (e) => {

                        // Command buttons conditional visibility
                        var dataItems = e.sender.dataSource.data();
                        for (var i = 0; i < dataItems.length; ++i) {
                            var checklist = dataItems[i];
                            var tr = $('#log-checklist-grid').find('[data-uid="' + checklist.uid + '"]');

                            if(checklist.value.notApplicable){
                                disableCommandButton($(tr).find('.k-grid-setMinus'));
                            }

                            if(checklist.value.notOk){
                                disableCommandButton($(tr).find('.k-grid-setCross'));
                            }

                            if(checklist.value.boolValue){
                                disableCommandButton($(tr).find('.k-grid-setCheck'));
                            }
                        }
                    }
                });
                scope.logChecklistGridOptions = logChecklistGridOptions($translate.use());

                function disableCommandButton(button){
                    button.prop( "disabled", true );
                    button.addClass( "checked");
                }

                function saveChanges() {
                    scope.logChecklistGridOptions.dataSource.sync();
                }

                function cancelChanges() {
                    scope.isDirty = false;
                    scope.logChecklistGridOptions.dataSource.read();
                }

                // Task grid
                scope.subTaskGridOptions = {
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: function (e) {
                                if (scope.modalType === formTypes.add) {
                                    e.success([]);
                                } else {
                                    getSubTasksByOperationLogId(e, scope.workOrder.operationLogId);
                                }
                            },
                            create: function (e) {
                                var subtask = angular.copy(e.data);
                                subtask.userId = $rootScope.authData.userId;
                                subtask.createdBy = $rootScope.authData.username;
                                subtask.createdOn = dateUtility.toIsoString(new Date());
                                subtask.operationLogId = scope.workOrder.operationLogId;
                                subtask.status = 0;
                                logService.addSubTask(subtask)
                                    .then(function (response) {
                                        e.success();
                                    }, function (error) {
                                        e.error(error);
                                    });
                            },
                            update: function (e) {
                                var subtask = angular.copy(e.data);
                                logService.updateSubTask(subtask)
                                    .then(function (response) {
                                        e.success();
                                    }, function (error) {
                                        e.error(error);
                                    });
                            },
                            destroy: function (e) {
                                var subtask = angular.copy(e.data);
                                logService.archiveSubTask(subtask)
                                    .then(function (response) {
                                        e.success();
                                    }, function (error) {
                                        e.error(error);
                                    });
                            }
                        },
                        batch: false,
                        schema: {
                            model: {
                                id: "operationLogTaskId",
                                fields: {
                                    comment: {
                                        editable: true,
                                        validation: { required: { message: "Oppgave er påkrevd" } }
                                    },
                                    taskStatus: { editable: true }
                                }
                            }
                        }
                    }),
                    toolbar: [{ name: 'create', text: $translate.instant('WORKORDER_MODAL_ADD_SUBTASK')},
                              { template: `
                                            <a class="k-button pull-right export-to-pdf-button" ng-click='exportToPDF()'><span class="k-icon k-i-file-pdf"></span>{{ 'CASE_EXPORT_TO_PDF' | translate }}</a>
                                            ${authService.hasPermission('WorkOrderAdmin') ? '<a class="k-button pull-right connect-to-case-button" ng-click="openConnectCaseModal()"><span class="k-icon k-i-link-vertical"></span>{{ "WORKORDER_MODAL_CONNECT_CASE" | translate }}</a>' : ''}
                                         `
                            }],
                    columns: [
                        {
                            field: 'comment',
                            title: $translate.instant('WORKORDER_MODAL_SUBTASK_COMMENT'),
                            width: 180
                        },
                        {
                            field: 'taskStatus',
                            title: $translate.instant('WORKORDER_MODAL_SUBTASK_STATUS'),
                            width: 180,
                            editor: subTaskStatusDropDownEditor,
                            template: function (dataItem) {
                                if (dataItem.taskStatus) {
                                    var index = workflowStatuses.findIndex(function (task) {
                                        return task.value === dataItem.taskStatus;
                                    });
                                    return workflowStatuses[index].text;
                                }
                                return '';
                            }
                        },
                        {
                            command: [
                                { name: "edit", text: { edit: " ", update: " ", cancel: " " } },
                                { name: "destroy", text: " " }
                            ],
                            title: '&nbsp;',
                            width: 150
                        }
                    ],
                    height: 250,
                    scrollable: false,
                    editable: 'inline'
                };

                initDirective();
                function initDirective(){
                    var requests = {};
                    if (scope.workOrder && scope.workOrder.logactionId && scope.workOrder.operationLogId){
                        requests.getCheckListByOperationLog = logService.getCheckListByOperationLog(scope.workOrder);
                    }

                    $q.all(requests)
                    .then((data) => {
                        scope.checklists = data.getCheckListByOperationLog && data.getCheckListByOperationLog.map(
                            (checklistElement) => {
                                if (checklistElement.value) {
                                    checklistElement.value = {
                                        checkListValueId: checklistElement.value.checkListValueId,
                                        checkListItemId:
                                            checklistElement.item
                                                .checkListItemId,
                                        operationLogId:
                                            scope.workOrder.operationLogId,
                                        notApplicable: checklistElement.value.notApplicable,
                                        notOk: checklistElement.value.notOk,
                                        boolValue: checklistElement.value.boolValue,
                                        numericValue: checklistElement.value.numericValue,
                                        stringValue: checklistElement.value.stringValue,
                                        editedBy: checklistElement.value.editedBy,
                                        editedOn: checklistElement.value.editedOn,
                                    };
                                    checklistElement.checkListItemId = checklistElement.item.checkListItemId;
                                    checklistElement.description = checklistElement.item.description;
                                    checklistElement.numericValue = checklistElement.value.numericValue;
                                    checklistElement.stringValue = checklistElement.value.stringValue;
                                } else {
                                    checklistElement.value = {
                                        checkListValueId: null,
                                        checkListItemId:null,
                                        operationLogId: null,
                                        notApplicable: false,
                                        notOk: false,
                                        boolValue: false,
                                        numericValue: null,
                                        stringValue: null,
                                        editedBy: $rootScope.authData.username,
                                        editedOn: moment().toDate(),
                                    };
                                    checklistElement.checkListItemId = checklistElement.item.checkListItemId;
                                    checklistElement.description = checklistElement.item.description;
                                    checklistElement.numericValue = null;
                                    checklistElement.stringValue = null;
                                }
                                return checklistElement;
                            }
                        ) || [];
                        scope.logChecklistGridOptions.dataSource.read();
                    });
                }

                function exportToPDF(){
                    var printWorkorderUrl = `${window.location.origin}/workorder-report?workorderId=${scope.workOrder.woNumber}&operationLogId=${scope.workOrder.operationLogId}`;
                    window.open(printWorkorderUrl, '_blank');
                }

                function openConnectCaseModal() {
                    workOrderConnectCaseModalService.openWorkOrderConnectCaseModal(scope.workOrder);
                }

                function getSubTasksByOperationLogId(e, operationLogId) {
                    logService.getSubTasksByOperationLogId(operationLogId)
                        .then(function (data) {
                            e.success(data);
                        }, function (error) {
                            e.error(error);
                        });
                }

                function subTaskStatusDropDownEditor(container, options) {
                    $('<input required name="' + options.field + '"/>')
                        .appendTo(container)
                        .kendoDropDownList({
                            dataTextField: 'text',
                            dataValueField: 'value',
                            dataSource: new kendo.data.DataSource({
                                transport: {
                                    read: function (e) {
                                        e.success(workflowStatuses);
                                    }
                                }
                            })
                        });
                }

                function setMinus(event) {
                    var currentRow = getChecklistFromGrid(event);
                    var dataItem = $("#log-checklist-grid").data("kendoGrid").dataSource.data().find(c => c.item.checkListItemId == currentRow.item.checkListItemId);
                    dataItem.set("value.notApplicable", true);
                    dataItem.set("value.notOk", false);
                    dataItem.set("value.boolValue", false);

                }

                function setCross(event) {
                    var currentRow = getChecklistFromGrid(event);
                    var dataItem = $("#log-checklist-grid").data("kendoGrid").dataSource.data().find(c => c.item.checkListItemId == currentRow.item.checkListItemId);
                    dataItem.set("value.notApplicable", false);
                    dataItem.set("value.notOk", true);
                    dataItem.set("value.boolValue", false);
                }

                function setCheck(event) {
                    var currentRow = getChecklistFromGrid(event);
                    var dataItem = $("#log-checklist-grid").data("kendoGrid").dataSource.data().find(c => c.item.checkListItemId == currentRow.item.checkListItemId);
                    dataItem.set("value.notApplicable", false);
                    dataItem.set("value.notOk", false);
                    dataItem.set("value.boolValue", true);
                }

                function getChecklistFromGrid(event) {
                    const sender = event.currentTarget;
                    const row = angular.element(sender).closest('tr');
                    return $('#log-checklist-grid')
                        .data('kendoGrid')
                        .dataItem(row)
                        .toJSON();
                }

                function logChecklistGridUpdate(e) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'logChecklist',
                        destination: '#checklists'
                    });

                    var checklist = e.data;
                    var checklistValue = {
                        checkListValueId: checklist.value.checkListValueId || '00000000-0000-0000-0000-000000000000',
                        checkListItemId: checklist.checkListItemId,
                        operationLogId : scope.workOrder.operationLogId,
                        boolValue: checklist.value.boolValue,
                        notApplicable: checklist.value.notApplicable,
                        notOk: checklist.value.notOk,
                        numericValue: checklist.numericValue,
                        stringValue: checklist.stringValue,
                        editedBy: checklist.value.editedBy,
                        editedOn: checklist.value.editedOn
                    };

                    logService.upsertCheckListValue(checklistValue).then(function(){
                        e.success();
                        scope.isDirty = false;
                    }).catch(error => {
                        e.error();
                    }).finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'logChecklist');
                    });
                }
            };
    }
})();
