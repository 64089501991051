(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('listService', listService);

    listService.$inject = ['$q', '$http', 'serviceUrls'];

    function listService($q, $http, serviceUrls) {
        const listWebApiUrl = `${serviceUrls.webApiBaseUrl}/list`;
        const listElementWebApiUrl = `${serviceUrls.webApiBaseUrl}/listElement`;

        return {
            getListsByContractorId: getListsByContractorId,
            addList: addList,
            updateList: updateList,
            deleteList: deleteList,
            getListElementsByListId: getListElementsByListId,
            addListElement: addListElement,
            updateListElement: updateListElement
        };

        function getListsByContractorId(contractorId) {
            var deferred = $q.defer();
            var requestUrl = `${listWebApiUrl}?contractorId=${contractorId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addList(list) {
            const deferred = $q.defer();
            const requestUrl = `${listWebApiUrl}`;
            $http
                .post(requestUrl, list)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateList(list) {
            const deferred = $q.defer();
            const requestUrl = `${listWebApiUrl}`;
            $http
                .put(requestUrl, list)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteList(listId) {
            const deferred = $q.defer();
            const requestUrl = `${listWebApiUrl}?listId=${listId}`;
            $http
                .delete(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getListElementsByListId(listId) {
            var deferred = $q.defer();
            var requestUrl = `${listElementWebApiUrl}?listId=${listId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addListElement(listElement) {
            const deferred = $q.defer();
            const requestUrl = `${listElementWebApiUrl}`;
            $http
                .post(requestUrl, listElement)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateListElement(listElement) {
            const deferred = $q.defer();
            const requestUrl = `${listElementWebApiUrl}`;
            $http
                .put(requestUrl, listElement)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
