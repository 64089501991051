(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('finalReportLegalPageFroyPageTwoDirective', finalReportLegalPageFroyPageTwoDirective);

    finalReportLegalPageFroyPageTwoDirective.$inject = [];
    function finalReportLegalPageFroyPageTwoDirective() {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/report/finalReportComponents/final-report-legal-page-froy-page-two.html',
            scope: {
                data: '=data'
            },
            link: function (scope, element, attrs) {
            },
        };
    }
})();
