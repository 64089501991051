(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('caseGridController', caseGridController);

    caseGridController.$inject = ['$scope'];

    function caseGridController($scope) {
        $scope.title = 'caseGridController';

        activate();

        function activate() { }
    }
})();
