(function(){
    angular.module('NaviaqWebApp').factory('vesselDashboardService', vesselDashboardService);

    vesselDashboardService.$inject = ['dateUtility', '$kWindow', 'formTypes', 'operationLogStatus'];

    var dashboardStatusMap = {
        "Scheduled": 1,
        "Correction": 2,
        "Log": 0,
        "Other": -1
    };

    var statuses = {
        Registered: "Registrert",
        Approved: "Godkjent",
        Started: "Startet",
        Completed: "Fullført",
        Deleted: "Slettet"
    };

    function vesselDashboardService(dateUtility, $kWindow, formTypes, operationLogStatus) {
        return {
            getIcon: getIcon,
            getDueDate: getDueDate,
            calculateOperationLogTasks: calculateOperationLogTasks,
            getStatusNameFromJobType: getStatusNameFromJobType,
            getJobTypeFromStatusName: getJobTypeFromStatusName,
            mapOperationLogEnumToStatus: mapOperationLogEnumToStatus,
            editOperationLog: editOperationLog
        }

        function editOperationLog(log) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Endre logg',
                    resizable: true,
                    width: 1200,
                    visible: false,
                    appendTo: "body"
                },
                templateUrl: 'app/log/operation-log-extdetails-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OperationLogExtDetailsModalController',
                resolve: {
                    departmentId: function () {
                        return log.departmentId;
                    },
                    formType: function () {
                        return formTypes.edit;
                    },
                    relatedItemId: function () {
                        return log.itemId;
                    },
                    operationLog: function () {
                        return log;
                    }
                }
            });
        }

        function mapOperationLogEnumToStatus(statusId) {
            return statuses[(_.invert(operationLogStatus))[statusId]];
        }

        function getIcon(operationLog) {
            return getStatusNameFromJobType(operationLog.logAction.jobType);
        }

        function getStatusNameFromJobType(statusId) {
            var status = _.findKey(dashboardStatusMap, _.partial(_.isEqual, statusId));
            if (typeof (status) === "undefined") {
                return "Other";
            }

            return status;
        }

        function getJobTypeFromStatusName(name) {
            return dashboardStatusMap[name];
        }

        function getDueDate(operationLog) {
            var now = new Date();
            var dueDate = kendo.parseDate(operationLog.createdOn);
            if (now > dueDate) {
                var dayDiff = Math.round((now - dueDate) / 1000 / 60 / 60 / 24);
                var monthDiff = dateUtility.getMonthDiff(dueDate, now);

                if (monthDiff > 0) {
                    return (monthDiff + 1) + ' måneder siden';
                } else {
                    if (dayDiff === 0) {
                        return 'i dag';
                    } else if (dayDiff === 1) {
                        return 'en dag siden';
                    } else {
                        return dayDiff + ' dager siden';
                    }
                }
            } else {
                var secondDiffHours = Math.round((dueDate - now) / 1000 / 60 / 60);
                var secondDayDiff = Math.round((dueDate - now) / 1000 / 60 / 60 / 24);
                var secondMonthDiff = dateUtility.getMonthDiff(now, dueDate);

                if (secondDiffHours < 30) {
                    return 'om ' + secondDiffHours + ' timer';
                } else {
                    if (secondMonthDiff > 10 && secondMonthDiff < 14) {
                        return 'om ett år';
                    } else {
                        if (secondMonthDiff === 1) {
                            return 'om en måned';
                        } else if (secondMonthDiff === 0) {
                            if (secondDayDiff > 1) {
                                return 'om ' + secondDayDiff + ' dager';
                            }
                        }
                    }
                }
            }
        }

        function calculateOperationLogTasks(log, logActions) {
            log.allTaskCount = 0;
            log.closedTaskCount = 0;

            var logAction = _.find(logActions, { logActionId: log.logAction.logActionId });

            if (typeof (logAction) != "undefined" && typeof (logAction.checkListItems) != "undefined") {
                log.allTaskCount = logAction.checkListItems.length;

            }

            if (typeof (log.logAction.checkListItems) != "undefined" &&
                log.logAction.checkListItems.length > 0) {

                log.allTaskCount = log.logAction.checkListItems.length;

                _.forEach(log.logAction.checkListItems, function (checkListItem) {
                    if (checkListItem.checkListValues != null) {
                        if (checkListItem.checkListValues.boolValue != false ||
                            checkListItem.checkListValues.notOk != false ||
                            checkListItem.checkListValues.notApplicable != false
                        ) {
                            log.closedTaskCount += 1;
                        }
                    }
                });
            }

            return log;
        }
    }
}())
