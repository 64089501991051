angular.module('NaviaqWebApp').directive('formatNumberWithSpaces', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, modelCtrl) {
            // Format input into groups of 3 digits
            modelCtrl.$parsers.push(function(inputValue) {
                if (inputValue == undefined) return '';
                // Remove non-digits
                let cleanInputValue = inputValue.replace(/\D/g,''); 
                // Add spaces every 3 digits
                let formattedInputValue = cleanInputValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
                // Render new input
                if (formattedInputValue != inputValue) {
                    modelCtrl.$setViewValue(formattedInputValue);
                    modelCtrl.$render();
                }
                // Return clean value for use in controller
                return cleanInputValue;
            });
            // Display existing numbers in groups of 3
            modelCtrl.$formatters.push(function(modelValue) {
                if (!modelValue) return '';
                let formattedModelValue = modelValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
                return formattedModelValue;
            });
        }
    };
});