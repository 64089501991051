angular.module("NaviaqWebApp").factory("logService", [
    "$q",
    "$http",
    "$translate",
    "serviceUrls",
    "operationLogStatus",
    "objectPropertyNameHandlerService",
    function ($q, $http, $translate, serviceUrls, operationLogStatus, objectPropertyNameHandlerService) {
        "use strict";

        const checkListBaseApiUrl = `${serviceUrls.webApiBaseUrl}/checkList`;
        const logActionPairWebApiUrl = `${serviceUrls.webApiBaseUrl}/logActionPairs`;
        const operationLogWebApiUrl = `${serviceUrls.webApiBaseUrl}/operationLogs`;
        const logActionWebApiUrl = `${serviceUrls.webApiBaseUrl}/logAction`;
        const workOrderLogWebApiUrl = `${serviceUrls.webApiBaseUrl}/workOrderLog`;
        const deviationWebApiUrl = `${serviceUrls.webApiBaseUrl}/deviation`;

        const logService = {
            types: [
                { value: 0, name: "Oppdragslogg" },
                { value: 1, name: "Hendelser" },
                { value: 2, name: "Oppdragslogg/Hendelser" },
                { value: 3, name: "Sakssystem" },
                { value: 4, name: "Tidsbank" },
                { value: 5, name: "Videologg" },
                { value: 6, name: "TOHub" }
            ],
            jobTypes: [
                { value: 0, name: "Logg" },
                { value: 1, name: "Periodisk" },
                { value: 2, name: "Korrektiv" },
                { value: 3, name: "Service"}
            ],
            getType: function (value) {
                const e = _.find(logService.types, function (o) {
                    return o.value === value;
                });
                if (e) {
                    return e.name;
                }

                return value;
            },

            getTypes: function () {
                return logService.types;
            },

            getJobType: function (value) {
                const e = _.find(logService.jobTypes, function (o) {
                    return o.value === value;
                });
                if (e) {
                    return e.name;
                }

                return value;
            },

            getJobTypes: function () {
                return logService.jobTypes;
            },
            getOperationLogStatusText: function (status) {
                switch (status) {
                    case operationLogStatus.Registered:
                        return "Registrert";
                    case operationLogStatus.Approved:
                        return "Godkjent";
                    case operationLogStatus.Started:
                        return "I arbeid";
                    case operationLogStatus.Completed:
                        return "Utført";
                    case operationLogStatus.Deleted:
                        return "Slettet";
                    case operationLogStatus.Completed:
                        return "Fullført";
                    case -1:
                        return "Utløpt";
                    default:
                        return "";
                }
            },
            getTimeBankLogStatusText: function (status) {
                var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
                switch (status) {
                    case 0:
                        return $translate.instant('TIMEBANK_STATUS_REGISTERED', null, null, langId);
                    case 1:
                        return $translate.instant('TIMEBANK_STATUS_APPROVED', null, null, langId);
                    case 4:
                        return $translate.instant('TIMEBANK_STATUS_CLOSED', null, null, langId);
                    case 5:
                        return $translate.instant('TIMEBANK_STATUS_REJECTED', null, null, langId);
                    case 6:
                        return $translate.instant('TIMEBANK_LOG_TOOLTIP_CONFIRMED', null, null, langId);
                    default:
                        '';
                }
            },
            getOperationLogStatusValues: function () {
                const operationLogStatusValues = [];

                for (const status in operationLogStatus) {
                    if (operationLogStatus.hasOwnProperty(status)) {
                        operationLogStatusValues.push({
                            key: status,
                            value: operationLogStatus[status],
                            text: this.getOperationLogStatusText(
                                operationLogStatus[status]
                            )
                        });
                    }
                }

                return operationLogStatusValues;
            },
            getLogActions: function (contractorId, includeConglomerateLogActions = false) {
                const deferred = $q.defer();

                const url = `${logActionWebApiUrl}/byContractorId?contractorId=${contractorId}&includeConglomerateLogActions=${includeConglomerateLogActions}`;

                $http
                    .get(url)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            getLogActionsFilteredByIsActiveField: function(contractorId, relatedDepartmentId, includeConglomerateLogActions = false, selectedLogActionId = null) {
                const deferred = $q.defer();
                let url =`${logActionWebApiUrl}/active?contractorId=${contractorId}&includeConglomerateLogActions=${includeConglomerateLogActions}`;

                if (selectedLogActionId) {
                    url += `&selectedLogActionId=${selectedLogActionId}`;
                }

                if (relatedDepartmentId) {
                    url += `&relatedDepartmentId=${relatedDepartmentId}`;
                }

                $http
                    .get(url)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            addLogAction: function (logAction) {
                const deferred = $q.defer(),
                    requestUrl = logActionWebApiUrl;

                $http
                    .post(requestUrl, logAction)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            updateLogAction: function (logAction) {
                const deferred = $q.defer(),
                    requestUrl = logActionWebApiUrl;

                $http
                    .put(requestUrl, logAction)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            addCheckListItemWebApi: function (checkListItem) {
                const deferred = $q.defer(),
                    requestUrl = checkListBaseApiUrl;

                $http
                    .post(requestUrl, checkListItem)
                    .then(
                        function (response) {
                            deferred.resolve(response.data);
                        },
                        function () {
                            deferred.reject();
                        }
                    );

                return deferred.promise;
            },
            updateCheckListItemWebApi: function (checkListItem) {
                const deferred = $q.defer(),
                    requestUrl = checkListBaseApiUrl;

                $http
                    .put(requestUrl, checkListItem)
                    .then(
                        function (response) {
                            deferred.resolve(response.data);
                        },
                        function () {
                            deferred.reject();
                        }
                    );

                return deferred.promise;
            },
            getChecklistItemsByEquipmentId: function(equipmentId) {
                const deferred = $q.defer();
                const url = `${checkListBaseApiUrl}/byEquipmentId?equipmentId=${equipmentId}`;
                $http
                    .get(url)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            getOperationLogs: function (filter) {
                const deferred = $q.defer();

                $http
                    .post(`${operationLogWebApiUrl}/filter`, filter)
                    .then(
                        function (response) {
                            deferred.resolve(
                                response.data
                            );
                        },
                        function () {
                            deferred.reject();
                        }
                    );

                return deferred.promise;
            },
            getOperationLogsByItemFilterWebAPI: (filter) => {
                const deferred = $q.defer();
                $http
                    .post(operationLogWebApiUrl + "/byItemId/filter", filter)
                    .then((response) => deferred.resolve(response.data), () => deferred.reject());
                return deferred.promise;
            },
            getOperationLogsWithItemsByItemFilterWebAPI: (filter) => {
                const deferred = $q.defer();
                $http
                    .post(operationLogWebApiUrl + "/withItemsByItemId/filter", filter)
                    .then((response) => deferred.resolve(response.data), (error) => deferred.reject(error));
                return deferred.promise;
            },
            getOperationLogsByFilterWebAPI: (filter, includeConglomerateLogs = false) => {
                const deferred = $q.defer();
                var url = `${operationLogWebApiUrl}/filter?includeConglomerateLogs=${includeConglomerateLogs}`;
                $http
                    .post(url, filter)
                    .then((response) => deferred.resolve(response.data), () => deferred.reject());
                return deferred.promise;
            },
            getSkipperMessagesOperationLog: (orderId) => {
                const deferred = $q.defer();
                $http
                    .get(`${operationLogWebApiUrl}/skipperMessages?orderId=${orderId}`)
                    .then((response) => deferred.resolve(response.data), () => deferred.reject());
                return deferred.promise;
            },
            getOperationLogById: function (operationLogId){
                const deferred = $q.defer();
                $http
                    .get(operationLogWebApiUrl + "/" + operationLogId)
                    .then((response) => deferred.resolve(response.data), () => deferred.reject());
                return deferred.promise;
            },
            updateOperationLogs: function updateOperationLogs(
                operationLogIds,
                orderId,
                departmentId,
                logActionId
            ) {
                const deferred = $q.defer();
                var url = `${operationLogWebApiUrl}/batch?departmentId=${departmentId}`
                url = orderId ? `${url}&orderId=${orderId}` : url;
                url = logActionId ? `${url}&logActionId=${logActionId}` : url;

                $http
                    .put(url, operationLogIds)
                    .then(
                        function (response) {
                            deferred.resolve(response.data);
                        },
                        function () {
                            deferred.reject();
                        }
                    );

                return deferred.promise;
            },
            addOperationLogWebApi: function addOperationLogWebApi(
                operationlogToAdd
            ) {
                const deferred = $q.defer(),
                requestUrl = operationLogWebApiUrl;
                $http
                    .post(requestUrl , operationlogToAdd)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            updateOperationLogWebApi: function updateOperationLogWebApi(
                operationlogToModify
            ) {
                const deferred = $q.defer(),
                requestUrl = operationLogWebApiUrl;
                $http
                    .put(requestUrl, operationlogToModify)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            getCheckListItemsByOperationLogWebAPI: function (
                logActionId,
                operationLogId
            ) {
                const deferred = $q.defer();

                $http
                    .get(checkListBaseApiUrl + "/filter?logActionId=" + logActionId + "&operationLogId=" + operationLogId)
                    .then(
                        (response) => deferred.resolve(response.data),
                        () => deferred.reject());
                return deferred.promise;
            },
            upsertCheckListValue: function (checkListValue) {
                const deferred = $q.defer();
                const url = checkListBaseApiUrl + '/upsertvalue';

                $http.post(url, checkListValue)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            addOperationLogAssignedUserWebApi: function addOperationLogAssignedUserWebApi(operationLogAssignedUser) {
                const deferred = $q.defer(),
                requestUrl = `${operationLogWebApiUrl}/operationLogAssignedUser`;
                $http
                    .post(requestUrl, operationLogAssignedUser)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            deleteOperationLogAssignedUser: (assignedUserId) => {
                const deferred = $q.defer();

                $http
                    .delete(`${operationLogWebApiUrl}/deleteAssignedUser?assignedUserId=${assignedUserId}`)
                    .then(
                        function (response) {
                            deferred.resolve(response.data);
                        },
                        function () {
                            deferred.reject();
                        }
                    );

                return deferred.promise;
            },
            getBasisData: function getBasisData(contractorId) {
                const deferred = $q.defer();
                const url = logActionWebApiUrl + "/basisdata?contractorId=" + contractorId;
                $http
                    .get(url)
                    .then(response => deferred.resolve(response.data))
                    .catch(error => deferred.reject(error));

                return deferred.promise;
            },
            getScheduledOperationLogsByFilter: (
                startDate,
                endDate,
                departmentId
            ) => {
                const deferred = $q.defer(),
                    requestUrl = `${operationLogWebApiUrl}/scheduledOperationLogsByFilter?startDate=${startDate}&endDate=${endDate}&departmentId=${departmentId}`;

                $http.get(requestUrl).then(
                    function (response) {
                        deferred.resolve(
                            response.data
                        );
                    },
                    function () {
                        deferred.reject();
                    }
                );

                return deferred.promise;
            },
            getLogActionsByLogActionId(
                logActionId
            ) {
                const deferred = $q.defer(),
                    requestUrl =
                    logActionWebApiUrl +
                        "/byLogActionId?logActionId=" +
                        logActionId

                $http.get(requestUrl).then(
                    function(response) {
                        deferred.resolve(response.data);
                    },
                    function(error) {
                        deferred.reject(error);
                    }
                );

                return deferred.promise;
            },
            updateDepartmentsOnLogActions(
                updateObject
            ) {
                const deferred = $q.defer(),
                    requestUrl = `${logActionWebApiUrl}/updateDepartments`;

                $http.put(requestUrl, updateObject).then(
                    function(response) {
                        deferred.resolve(response.data);
                    },
                    function(error) {
                        deferred.reject(error);
                    }
                );

                return deferred.promise;
            },
            addSubTask: function addSubTask(subTask) {
                const deferred = $q.defer(),
                    requestUrl = operationLogWebApiUrl + "/subtask";

                $http.post(requestUrl, subTask).then(
                    function (response) {
                        deferred.resolve(response.data);
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );

                return deferred.promise;
            },
            getSubTasksByOperationLogId: function getSubTasksByOperationLogId(
                operationLogId
            ) {
                const deferred = $q.defer(),
                    requestUrl =
                    operationLogWebApiUrl +
                        "/subtask?operationLogId=" +
                        operationLogId;

                $http.get(requestUrl).then(
                    function (response) {
                        deferred.resolve(response.data);
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );

                return deferred.promise;
            },
            updateSubTask: function updateSubTask(subTask) {
                const deferred = $q.defer(),
                    requestUrl = operationLogWebApiUrl + "/subtask";

                $http.put(requestUrl, subTask).then(
                    function (response) {
                        deferred.resolve(response.data);
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );

                return deferred.promise;
            },
            archiveSubTask: archiveSubTask,
            addMedia: addMedia,
            getMediasByOperationLogId: getMediasByOperationLogId,
            getLogActionPairsByContractorId: getLogActionPairsByContractorId,
            removeOperationLog: removeOperationLog,
            getCheckListByOperationLog: getCheckListByOperationLog,
            getWorkOrdersByEquipmentId: getWorkOrdersByEquipmentId,
            getCasesByEquipmentId: getCasesByEquipmentId
        };

        function archiveSubTask(subtask) {
            const deferred = $q.defer(),
                requestUrl = operationLogWebApiUrl + "/subtask/archive";

            $http.put(requestUrl, subtask).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addMedia(media) {
            const deferred = $q.defer(),
                requestUrl = operationLogWebApiUrl + "/media";

            $http.post(requestUrl, media).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getMediasByOperationLogId(operationLogId) {
            const deferred = $q.defer(),
                requestUrl =
                operationLogWebApiUrl +
                    "/media?operationLogId=" +
                    operationLogId;

            $http.get(requestUrl).then(
                function (response) {
                    deferred.resolve(objectPropertyNameHandlerService.setCamelCaseToPascalCase(_.filter(
                        response.data,
                        { status: 0 }
                    )));
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function removeOperationLog(operationLogId) {
            const deferred = $q.defer();
            const url = operationLogWebApiUrl + `?operationLogId=${operationLogId}`

            $http.delete(url)
            .then(result => deferred.resolve(result.data))
            .catch(error => deferred.reject(error));
            return deferred.promise;
        }

        function getLogActionPairsByContractorId(contractorId) {
            const deferred = $q.defer();
            const url = logActionPairWebApiUrl + `?contractorId=${contractorId}`

            $http.get(url)
                .then(result => {
                    deferred.resolve(result.data);
                })
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getCheckListByOperationLog(operationLog) {
            const deferred = $q.defer(),
                requestUrl = operationLogWebApiUrl
                    + '/checklist?logActionId=' + operationLog.logActionId
                    + '&operationLogId=' + operationLog.operationLogId;

            $http.get(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getWorkOrdersByEquipmentId(equipmentId) {
            const deferred = $q.defer();
            const url = workOrderLogWebApiUrl + `/forEquipmentHistoryByItemId?itemId=${equipmentId}`

            $http.get(url)
                .then(result => {
                    deferred.resolve(result.data);
                })
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }


        function getCasesByEquipmentId(equipmentId) {
            const deferred = $q.defer();
            const url = deviationWebApiUrl + `/forEquipmentHistoryByItemId?itemId=${equipmentId}`

            $http.get(url)
                .then(result => {
                    deferred.resolve(result.data);
                })
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        return logService;

    }
]);
