(function () {
    'use strict';
    angular.module('NaviaqWebApp').factory('excelExportService', excelExportService);

    excelExportService.$inject = [];

    function excelExportService() {
        var inputHeader = [];
        var inputRows = [];
        var workbook = new kendo.ooxml.Workbook({
            sheets: [
                {
                    columns: [],
                    rows: []
                }
            ]
        });

        return {
            exportToExcel: exportToExcel,
            exportKendoGridToKendoSheet: exportKendoGridToKendoSheet

        };

        function exportToExcel(data){
            inputHeader = data.header;
            inputRows = data.rows;
            loadDataToGrid();
            exportDataToExcel(data.filename);
        }

        function loadDataToGrid() {
            var columns = [];
            var rows = [];
            for (let i = 0; i < inputHeader.length; i++) {
                columns.push({ width: 200 });
            }

            // Create header
            var headerCells = [];
            inputHeader.forEach(header => {
                headerCells.push({
                    value: header,
                    bold: true,
                });
            });
            rows.push({
                cells: headerCells
            });

            // Create data
            inputRows.forEach(row => {
                var rowCells = [];
                Object.keys(row).forEach(v => rowCells.push({ value: row[v]}));
                rows.push({
                    cells: rowCells
                });
            });

            workbook.options.sheets[0].columns = columns;
            workbook.options.sheets[0].rows = rows;
        }

        function exportDataToExcel(filename) {
            kendo.saveAs({
                dataURI: workbook.toDataURL(),
                fileName: `${filename}_${moment().format('DD.MM.YYYY')}.xlsx`
            });
        }

        function exportKendoGridToKendoSheet(kendoGrid, sheetName) {
            let grid = kendoGrid;
            let columns = grid.columns.filter(col => col.exportable != false);
    
            let headerRow = {
                cells: columns.map(col => {
                    return {
                        value: col.title,
                        bold: true,
                        background: "#2b2738",
                        color: "#ffffff"
                    };
                })
            }
    
            let dataRows = grid.dataSource.view().map(item => {
                return {
                    cells: columns.map(col => {
                        let cell = {
                            value: item[col.field]
                        };
                        
                        if (col.exportFormat) {
                            cell.format = col.exportFormat;
                        }
                        
                        return cell;
                    })
                };
            });
        
            return {
                name: sheetName,
                columns: columns.map(col => {
                    return {
                        width: 200,
                        field: col.field,
                        title: col.title
                    };
                }),
                rows: [headerRow].concat(dataRows),
                filter: { from: 0, to: columns.length - 1 },
                frozenRows: 1
            };
        }
    }

})();
