(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('WorkOrdersCalendarController', workOrdersCalendarController);

    workOrdersCalendarController.$inject = ['$scope', '$rootScope', '$timeout', 'dateUtility', 'kendoUtility', 'userService',
        'workOrderService', 'workOrderDetailsModalService', '$kWindow', 'noUser'];

    function workOrdersCalendarController($scope, $rootScope, $timeout, dateUtility, kendoUtility, userService,
        workOrderService, workOrderDetailsModalService, $kWindow, noUser) {

        $scope.isReadOnly = $scope.hasPermission('WorkOrderReadOnly') &&
            !$scope.hasPermission('WorkOrderAdmin') &&
            !$scope.hasPermission('WorkOrderEquipmentRead') &&
            !$scope.hasPermission('WorkOrderComponentRead');

        $scope.workOrdersCalendarOptions = {
            date: new Date(),
            startTime: new Date(),
            height: kendoUtility.calculateRemainingSpace(),
            eventTemplate: $("#event-template").html(),
            views: [
                {
                    type: 'month',
                    editable: {
                        destroy: false,
                        move: false,
                        resize: false
                    }
                },
                'agenda',
                'day',
                {
                    type: 'timelineWeek',
                    selected: true,
                    majorTick: 1440,
                    minorTickCount: 1,
                    columnWidth: 1,
                    eventHeight: 30,
                    startTime: new Date(1901, 1, 1, 0, 0, 0),
                    endTime: new Date(1901, 1, 1, 23, 59, 59),
                    workDayStart: new Date('2013/6/6 08:00 AM'),
                    workDayEnd: new Date('2013/6/6 08:00 PM'),
                    editable: {
                        destroy: false,
                        move: true,
                        resize: true,
                        create: true,
                        update: true
                    },
                    group: {
                        resources: ['AssignedUser'],
                        orientation: 'vertical'
                    }
                }
            ],
            timezone: 'Etc/GMT-1',
            dataSource: new kendo.data.SchedulerDataSource({
                transport: {
                    read: function (e) {
                        var calendarData = extWorkOrderLogs();
                        e.success(calendarData);
                    },
                    update: function (e) {
                        if (new Date(e.data.start) > new Date(e.data.dueDate)) {
                            var windowInstance = $kWindow.open({
                                options: {
                                    modal: true,
                                    movable: false,
                                    resizable: false,
                                    visible: false,
                                    width: 300
                                },
                                templateUrl: 'app/shared/popups/confirm-modal.html',
                                windowTemplateUrl: 'app/shared/modal-base.html',
                                controller: 'ConfirmModalController',
                                resolve: {
                                    content: function () {
                                        return 'Fristdato vil bli forlenget til '
                                            + moment(e.data.end).format('DD.MM')
                                            + '. Er du sikker på dette?';
                                    },
                                    primaryBtnText: function () {
                                        return null;
                                    },
                                    secondaryBtnText: function () {
                                        return null;
                                    }
                                }
                            });
                            windowInstance.result.then(function (response) {
                                if (response) {
                                    e.data.dueDate = new Date(e.data.start).setHours(23, 59, 59, 59, 0);
                                    updateWorkOrderLog(e);
                                } else {
                                    e.success();
                                    $rootScope.$broadcast('refreshWorkOrders');
                                }
                            });
                        } else {
                            updateWorkOrderLog(e);
                        }
                    }
                },
                schema: {
                    model: {
                        id: 'operationLogId',
                        fields: {
                            title: { from: 'title', defaultValue: 'No title', validation: { required: true } },
                            start: { type: 'date', from: 'start' },
                            end: { type: 'date', from: 'end' },
                            assignedUser: { type: 'array', from: 'assignedUser' },
                            dueDate: { type: 'date', from: 'dueDate'}
                        }
                    }
                }
            }),
            resources: [{
                field: 'assignedUser',
                name: 'AssignedUser',
                dataValueField: "userId",
                dataTextField: "displayName",
                dataSource: {
                    transport: {
                        read: function (e) {
                            userService.getUsersAsDropdownByFilter({contractorIds: [$rootScope.authData.contractorId]})
                                .then(function (data) {
                                    data = data.filter(u => !u.validTo || moment(u.validTo) >= moment(new Date()));
                                    data = data.sort(function (a, b) {
                                        if (a.displayName < b.displayName) { return -1; }
                                        if (a.displayName > b.displayName) { return 1; }
                                        return 0;
                                    });
                                    data.unshift(noUser);
                                    e.success(data);
                                }, function (error) {
                                    e.error(error);
                                });
                        }
                    }
                },
                multiple: true,
                title: 'Tildelt til'
            }],
            add: function (e) {
                e.preventDefault();
                if(!$scope.isReadOnly){
                    var workOrderLog = {
                        dueDateObject: new Date(e.event.start),
                        doneOnObject: new Date(e.event.end),
                        assignedUser: $scope.usersByContractorId.filter(function (user) {
                            return user.userId === e.event.assignedUser[0];
                        })
                    };
                    $scope.openWorkOrderDetailsModal(workOrderLog);
                }
            },
            edit: function (e) {
                e.preventDefault();
                var workOrderLog = workOrderDetailsModalService.createWorkOrderLog(e.event);
                workOrderLog.assignedUser = $scope.usersByContractorId.filter(function (user) {
                    return user.userId === e.event.assignedUser;
                });
                workOrderDetailsModalService.createWorkOrderDetailsResolve(workOrderLog)
                    .then(function (resolve) {
                        var modal = workOrderDetailsModalService.openWorkOrderDetailsModal(resolve, !$scope.isReadOnly);
                        modal.result.then(function (result) {
                            if (result) {
                                $rootScope.$broadcast('refreshWorkOrders');
                            }
                        });
                    });
            }
        };

        initController();

        function initController() {
            $rootScope.$on('workOrdersFetched', function () {
                $scope.workOrdersCalendarOptions.dataSource.read();
            });

            $rootScope.$on('refreshWorkOrderCalendar', function () {
                $timeout(100).then(function () {
                    $scope.workOrdersCalendar.refresh();
                });
            });

            $timeout(100).then(function () {
                $('.k-view-timelineweek > a').text('Tidslinje');
            });
        }

        function extWorkOrderLogs() {
            var workOrderLogsCopy = angular.copy($scope.workOrderLogs).filter(function (wo) {
                return !!wo.doneOn && !!wo.dueDate;
            }).map(function (wo) {
                wo.start = moment(wo.doneOn).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
                wo.end =
                wo.doneTo? moment(wo.doneTo).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate() :
                moment(wo.doneOn).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
                wo.assignedUser = wo.assignedUser && wo.assignedUser[0] ? wo.assignedUser[0].userId : noUser.userId;
                return wo;
            });
            return workOrderLogsCopy;
        }

        function updateWorkOrderLog(e) {
            e.data.doneOn = e.data.start;
            if (typeof e.data.assignedUser !== 'string') {
                //when moving assigned user is an array
                e.data.assignedUser = $scope.usersByContractorId.filter(function (user) {
                    return user.userId === e.data.assignedUser[0]
                        && user.userId !== '00000000-0000-0000-0000-000000000000';
                });
            } else {
                //when resizing assigneduser is a string
                e.data.assignedUser = $scope.usersByContractorId.filter(function (user) {
                    return user.userId === e.data.assignedUser
                        && user.userId !== $scope.noUser.userId;
                });
            }
            var workOrderLog = workOrderDetailsModalService.createWorkOrderLog(e.data);
            workOrderLog.dueDate = dateUtility.toIsoString(workOrderLog.dueDateObject);
            workOrderLog.doneOn = dateUtility.toIsoString(workOrderLog.doneOnObject);
            workOrderLog.doneTo = dateUtility.toIsoString(workOrderLog.doneToObject);
            workOrderLog.contractorId = $rootScope.authData.contractorId;
            workOrderService.updateWorkOrderLog(workOrderLog)
                .then(function () {
                    $rootScope.$broadcast('refreshWorkOrders');
                    e.success();
                }, function (error) {
                    e.error();
                });
        }
    }
})();
