(function() {
    'use strict';

    angular.module('NaviaqWebApp').controller('CategoryDetailsModalController', categoryDetailsModalController);

    categoryDetailsModalController.$inject = [
        '$scope',
        '$windowInstance',
        '$stateParams',
        'itemTreeService',
        'treeUtility',
        'authService',
        'formTypes',
        'formType',
        'equipmentRootCategory',
        'itemCategories',
        'selectedCategory',
        '$kWindow',
        '$translate'
    ];

    function categoryDetailsModalController(
        $scope,
        $windowInstance,
        $stateParams,
        itemTreeService,
        treeUtility,
        authService,
        formTypes,
        formType,
        equipmentRootCategory,
        itemCategories,
        selectedCategory,
        $kWindow,
        $translate
    ) {
        $scope.name = selectedCategory ? selectedCategory.name : '';
        $scope.selectedCategory = selectedCategory;
        $scope.selectedParentCategory = null;
        $scope.formType = formType;
        $scope.disableControls = formType === formTypes.edit && !selectedCategory;
        $scope.authData = authService.getAuthData();

        $scope.closeModal = closeModal;
        $scope.deleteCategory = deleteCategory;
        $scope.validateForm = validateForm;
        $scope.showDeleteButton = false;

        $scope.categoryOption = {
            dataSource: new kendo.data.DataSource({
                data: itemCategories
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            optionLabel: 'Velg kategori',
            select: categorySelected,
            dataBound: initEditedCategoryDropdown,
            change: () => {
                $scope.categoryParentsOption.dataSource.read();
            }
        };
        $scope.categoryParentsOption = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: getParents
                }
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            optionLabel: '',
            enable: !$scope.disableControls
        };

        function initEditedCategoryDropdown(e) {
            if ($scope.selectedCategory) {
                itemTreeService
                    .getItemForestByCategoryId(
                        $scope.selectedCategory.id,
                        $scope.authData.contractorId,
                        $stateParams.DepartmentId
                    )
                    .then(function(items) {
                        $scope.showDeleteButton =
                            formType === 'edit' && $scope.selectedCategory.children.length === 0 && items.length === 0;
                    });
                $scope.categoryParents.select(function(item) {
                    if (item.id === $scope.selectedCategory.parentId) {
                        $scope.selectedParentCategory = item;
                        return true;
                    }

                    return false;
                });
            }
        }

        function categorySelected(e) {
            if (e.dataItem.id) {
                $scope.disableControls = false;
                $scope.categoryParents.enable('true');

                //Load the values for selected categeory
                $scope.name = e.dataItem.name;
                $scope.selectedCategory = e.dataItem;

                if (e.dataItem.parentId) {
                    $scope.categoryParents.select(function(item) {
                        if (item.Id === e.dataItem.parentId) {
                            $scope.selectedParentCategory = item;
                            return true;
                        }

                        return false;
                    });
                } else {
                    $scope.categoryParents.select(null);
                }
                itemTreeService
                    .getItemForestByCategoryId(
                        $scope.selectedCategory.id,
                        $scope.authData.contractorId,
                        $stateParams.DepartmentId
                    )
                    .then(function(items) {
                        $scope.showDeleteButton =
                            formType === 'edit' && $scope.selectedCategory.children.length === 0 && items.length === 0;
                    });
            } else {
                $scope.name = '';
                $scope.categoryParents.select(0);

                $scope.disableControls = true;
                $scope.categoryParents.enable('false');
            }
        }

        function deleteCategory() {

            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: $translate.instant('G_CONFIRM'),
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function(){
                        return $translate.instant('G_AREYOUSURE');
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function(response) {
                if (response) {
                    itemTreeService.deleteCategory(selectedCategory.id).then(function() {
                        $windowInstance.close(true);
                    });
                }
            });
        }

        function closeModal() {
            $windowInstance.close();
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                var parentCategory = $scope.selectedParentCategory;

                //If no parent is selected, use equipment as parent
                if (!parentCategory) {
                    parentCategory = equipmentRootCategory;
                }

                if ($scope.formType === formTypes.add) {
                    itemTreeService
                        .addCategory({
                            name: $scope.name,
                            keyName: '',
                            contractorId: $scope.authData.contractorId,
                            relatedEntityId: $stateParams.DepartmentId,
                            parentId: parentCategory.id
                        })
                        .then(function() {
                            $windowInstance.close(true);
                        });
                } else {
                    itemTreeService
                        .updateCategory({
                            id: $scope.selectedCategory.id,
                            name: $scope.name,
                            keyName: '',
                            contractorId: $scope.authData.contractorId,
                            parentId: parentCategory.id
                        })
                        .then(function() {
                            $windowInstance.close(true);
                        });
                }
            }
        }

        function getParents(e) {
            if ($scope.selectedCategory) {
                let ids = [];
                let item = itemCategories.find(x => x.id == $scope.selectedCategory.id);
                treeUtility.collectIdsFromTree(ids, item);
                let filteredCategories = itemCategories.filter(x => !ids.includes(x.id))
                e.success(filteredCategories);
            } else {
                e.success(itemCategories);
            }
        }
    }
})();
