(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('radialMenu', radialMenu);

    radialMenu.$inject = ['$q', '$state', '$rootScope', 'mapService', 'authService'];

    function radialMenu($q, $state, $rootScope, mapService, authService) {
        var startWidth = 24,
            startHeight = 24,
            maxWidth = 265,
            maxHeight = 265,
            startLeft = 0,
            startTop = 0,
            animationDuration = 300,
            iconsFadeDuration = 200;

        var isOpen = false,
            lastScreenPoint = null,
            lastScreenPointType = null,
            item = null;

        return {
            restrict: 'AE',
            replace: true,
            scope: {
                'options': '='
            },
            link: function (scope, element, attrs) {
                scope.$on('showContextMenu', function (event, options) {
                    item = options.item;
                    lastScreenPointType = options.type;
                    scope.options.open(options.screenPoint, options.mapPosition, options.type, options.focusPoint);
                });

                scope.$on('hideContextMenu', function () {
                    item = null;
                    lastScreenPointType = null;
                    scope.options.close();
                });

                scope.zoom = function () {
                    if (lastScreenPoint) {
                        mapService.zoomTo(lastScreenPoint);

                        if (typeof (lastScreenPoint) === "undefined") {
                            return;
                        }

                        switch (lastScreenPointType) {
                            case "Location":

                                if (typeof (item) === "undefined") {
                                    return;
                                }

                                $rootScope.$broadcast('locationLoad', { locationId: item.locationNumber });
                                $state.go('main.locationOrders', {
                                    ContractorId: item.contractorId,
                                    LocationNumber: item.locationNumber
                                });

                                break;
                            case "Vessel":
                                $state.go('main.vesselDetails', {
                                    mmsi: item.mmsi
                                });
                                break;
                        }

                    }
                };

                scope.menuItemVisibility = {
                    info: true,
                    personnel: false,
                    orders: false,
                    equipments: false,
                    nets: false,
                    rings: false,
                    components: false,
                    newOrder: false,
                    documents: false,
                    mooring: false,
                    facilities:false
                };

                scope.setMenuItemVisibilities = function () {
                    // check if it is a vessel
                    if (item && item.mmsi && item.contractorId && authService) {
                        var authData = authService.getAuthData();

                        if (authData.contractorId === item.contractorId) {
                            scope.menuItemVisibility.personnel = true;
                            scope.menuItemVisibility.orders = true;
                            scope.menuItemVisibility.equipments = true;
                        } else {
                            scope.menuItemVisibility.personnel = false;
                            scope.menuItemVisibility.orders = false;
                            scope.menuItemVisibility.equipments = false;
                        }
                    }
                    // check if it is a location
                    if (item && item.LocationNumber) {
                        scope.menuItemVisibility.nets = item.LocationAccessInfo.HasDetailedAccess || authService.hasPermission('ComponentManager');
                        scope.menuItemVisibility.components = item.LocationAccessInfo.HasDetailedAccess || authService.hasPermission('ComponentManager');
                        scope.menuItemVisibility.rings = item.LocationAccessInfo.HasDetailedAccess || authService.hasPermission('ComponentManager');
                        scope.menuItemVisibility.newOrder = item.LocationAccessInfo.HasDetailedAccess;
                        scope.menuItemVisibility.documents = item.LocationAccessInfo.HasDetailedAccess;
                        scope.menuItemVisibility.mooring = item.LocationAccessInfo.HasDetailedAccess;
                        scope.menuItemVisibility.facilities = item.LocationAccessInfo.HasDetailedAccess || authService.hasPermission('MooringManager');
                    }
                };

                scope.toLocationOverview = function () {
                    if (lastScreenPoint) {
                        scope.setHalfSplitter();
                        $state.go('locationOverview', {
                            ContractorId: item.contractorId,
                            LocationNumber: item.locationNumber
                        });
                    }
                };

                scope.toVesselOverview = function () {
                    if (lastScreenPoint) {
                        scope.setHalfSplitter();
                        $state.go('main.vesselDetails', {
                            mmsi: item.mmsi
                        });
                        scope.options.close();
                    }
                };

                scope.toVesselPersonel = function () {
                    if (lastScreenPoint) {
                        scope.setHalfSplitter();
                        $state.go('main.vesselPersonel', {
                            mmsi: item.mmsi
                        });
                        scope.options.close();
                    }
                };

                scope.toVesselOrders = function () {
                    if (lastScreenPoint) {
                        scope.setHalfSplitter();
                        $state.go('main.vesselOrders', {
                            mmsi: item.mmsi
                        });
                    }
                    scope.options.close();
                };

                scope.toVesselEquipments = function () {
                    if (lastScreenPoint) {
                        scope.setHalfSplitter();
                        $state.go('main.vesselEquipment', {
                            mmsi: item.mmsi
                        });
                    }
                    scope.options.close();
                };

                scope.toVesselmLink = function () {
                    if (lastScreenPoint) {
                        scope.setHalfSplitter();
                        $state.go('main.vesselmLink', {
                            mmsi: item.mmsi
                        });
                    }
                    scope.options.close();
                };

                scope.setHalfSplitter = function () {
                    $rootScope.$broadcast('setSplitterSize', {
                        left: '50%',
                        right: '50%'
                    });
                };

                scope.options.setOpen = function (newValue, screenPoint, mapPosition, contextType, focusPoint) {
                    var deferred = $q.defer();

                    try {
                        if (isOpen !== newValue) {
                            isOpen = newValue;

                            if (newValue) {
                                var type = contextType || 'Map';

                                if (focusPoint) {
                                    lastScreenPoint = focusPoint;
                                } else {
                                    lastScreenPoint = screenPoint;
                                }

                                $(element).find('.menu-item-container').hide();

                                if (type === 'Map') {
                                    $(element).find('.map-menu').show();
                                } else if (type === 'Location') {
                                    $(element).find('.location-menu').show();
                                    scope.setMenuItemVisibilities();
                                } else if (type === 'Vessel') {
                                    $(element).find('.vessel-menu').show();
                                    scope.setMenuItemVisibilities();
                                }

                                element.width(startWidth);
                                element.height(startHeight);

                                startLeft = screenPoint.x - startWidth / 2;
                                startTop = screenPoint.y + mapPosition[1] - startWidth / 2;
                                element.css('top', startTop + 'px');
                                element.css('left', startLeft + 'px');

                                //Calculate end left and top
                                var endLeft = screenPoint.x - maxHeight / 2;
                                var endTop = screenPoint.y + mapPosition[1] - maxWidth / 2;

                                element.show();

                                element.animate({ width: maxWidth }, { queue: false, duration: animationDuration });
                                element.animate({ height: maxHeight }, { queue: false, duration: animationDuration });
                                element.animate({ left: endLeft }, { queue: false, duration: animationDuration });
                                element.animate({ top: endTop }, {
                                    queue: false, duration: animationDuration, complete: function () {
                                        $('.inner-circle .row').fadeIn(iconsFadeDuration);
                                        deferred.resolve();
                                    }
                                });
                            } else {
                                //There are multiple rows for icons, which need to fade out, and the
                                //function should only call deferred.resolve() when the last fadeOut
                                //is finished.
                                var rowCount = $('.inner-circle .row').length,
                                    fadedOutCount = 0;

                                $('.inner-circle .row').fadeOut(iconsFadeDuration, function () {
                                    var $radialMenu = $('.radial-menu');
                                    $radialMenu.animate({ width: startWidth }, { queue: false, duration: animationDuration });
                                    $radialMenu.animate({ height: startHeight }, { queue: false, duration: animationDuration });
                                    $radialMenu.animate({ left: startLeft }, { queue: false, duration: animationDuration });
                                    $radialMenu.animate({ top: startTop }, {
                                        queue: false, duration: animationDuration, complete: function () {
                                            $radialMenu.hide();

                                            fadedOutCount++;
                                            if (fadedOutCount === rowCount - 1) {
                                                deferred.resolve();
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    } catch (error) {
                        deferred.reject();
                    }

                    return deferred.promise;
                };

                scope.options.open = function (screenPoint, mapPosition, contextType, focusPoint) {
                    var deferred = $q.defer();

                    if (isOpen) {
                        scope.options.setOpen(false).then(function () {
                            scope.options.setOpen(true, screenPoint, mapPosition, contextType, focusPoint).then(deferred.resolve);
                        });
                    } else {
                        scope.options.setOpen(true, screenPoint, mapPosition, contextType, focusPoint).then(deferred.resolve);
                    }

                    return deferred.promise;
                };

                scope.options.close = function () {
                    scope.options.setOpen(false);
                };
            },
            templateUrl: 'app/shared/directives/radial-menu.html'
        }
    }
})();
