(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationDepartmentsController', LocationDepartmentsController);

    LocationDepartmentsController.$inject = ['$scope', '$rootScope', '$translate', '$stateParams', 'locationService', 'kendoUtility'];

    function LocationDepartmentsController($scope, $rootScope, $translate, $stateParams, locationService, kendoUtility) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.departmentsGridData = [];

        const departmentsGridOptions = langId => ({
            dataSource: {
                data: $scope.departmentsGridData
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [
                { field: 'externalId', title: $translate.instant('MAIN_LOCATION_DEPARTMENT_GRID_ID', null, null, langId), width: '70px' },
                { field: 'name', title: $translate.instant('MAIN_LOCATION_DEPARTMENT_GRID_NAME', null, null, langId) },
                { field: 'mmsi', title: $translate.instant('MAIN_LOCATION_DEPARTMENT_GRID_MMSI', null, null, langId), width: '100px' },
                { field: 'callSign', title: $translate.instant('MAIN_LOCATION_DEPARTMENT_GRID_CALLSIGN', null, null, langId) },
                { field: 'ownerName', title: $translate.instant('MAIN_LOCATION_DEPARTMENT_GRID_OWNERNAME', null, null, langId)},
                { field: 'ownerPhone', title: $translate.instant('MAIN_LOCATION_DEPARTMENT_GRID_OWNERPGONE', null, null, langId), width: '140px' }
            ]
        });
        $scope.departmentsGridOptions = departmentsGridOptions($translate.use());
        $scope.$on('langChanged', (event, code) => {
            $scope.departmentsGridOptions = departmentsGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.departmentsGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#departmentsGrid')]);
        });

        initController();
        function initController() {
            readDepartments();
        }

        function readDepartments() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationLog',
                destination: '#right-pane',
                overlay: false
            });

            locationService.getDepartmentsByLocationNumber($stateParams.LocationNumber).then(function (departments) {
                $scope.departmentsGridData = [];
                _.each(departments, function (item) {
                    extendItem(item);

                    $scope.departmentsGridData.push(item);
                });

                $scope.departmentsGrid.dataSource.data($scope.departmentsGridData);
                $scope.departmentsGrid.refresh();

                $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
            }, function () {
                $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
            });
        }

        function extendItem(item) {
            if (item.departmentInfo) {
                item.callSign = item.departmentInfo.callSign;
                item.ownerName = item.departmentInfo.ownerName;
                item.ownerPhone = item.departmentInfo.ownerPhone;
            }
        }
    }
})();
