(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('SystemAdministrationController', systemAdministrationController);

    systemAdministrationController.$inject = ['$scope', '$state', 'authService'];

    function systemAdministrationController($scope, $state, authService) {
        $scope.visibleTabIndex = 0;
        $scope.setVisibleTab = setVisibleTab;
        $scope.tabStripOptions = { animation: false, select: e => selectTab(e) };
        const isSuperUser = authService.getAuthData().isSuperuser;
        $scope.tabsVisibility = {
            suppliers: isSuperUser,
            resources: isSuperUser,
            contractors: isSuperUser,
            orderRequestType: isSuperUser,
            conglomerates: isSuperUser,
            emailTemplates: isSuperUser,
            users: isSuperUser,
            locationOwners: isSuperUser
        };

        initController();

        function initController() {
            $scope.$watch('sysadminTab', function () {
                switch ($state.current.name) {
                    case 'sysadmin.suppliers':
                        $scope.sysadminTab.select(0);
                        $scope.visibleTabIndex = 0;
                        break;
                    case 'sysadmin.resources':
                        $scope.sysadminTab.select(1);
                        $scope.visibleTabIndex = 1;
                        break;
                    case 'sysadmin.contractors':
                        $scope.sysadminTab.select(2);
                        $scope.visibleTabIndex = 2;
                        break;
                    case 'sysadmin.orderRequestType':
                        $scope.sysadminTab.select(3);
                        $scope.visibleTabIndex = 3;
                        break;
                    case 'sysadmin.conglomerates':
                        $scope.sysadminTab.select(4);
                        $scope.visibleTabIndex = 4;
                        break;
                    case 'sysadmin.emailTemplates':
                        $scope.sysadminTab.select(5);
                        $scope.visibleTabIndex = 5;
                        break;
                    case 'sysadmin.users':
                        $scope.sysadminTab.select(6);
                        $scope.visibleTabIndex = 6;
                        break;
                    case 'sysadmin.locationOwners':
                        $scope.sysadminTab.select(7);
                        $scope.visibleTabIndex = 7;
                        break;
                    default:
                        $scope.sysadminTab.select(0);
                        $scope.visibleTabIndex = 0;
                        break;
                }
            });
        }

        function setVisibleTab(index) {
            $scope.visibleTabIndex = index;
        }

        function selectTab(e) {
            switch (e.item.id) {
                case 'suppliers-administration-tab':
                    $state.go('sysadmin.suppliers');
                    break;
                case 'resources-administration-tab':
                    $state.go('sysadmin.resources');
                    break;
                case 'contractors-administration-tab':
                    $state.go('sysadmin.contractors');
                    break;
                case 'order-request-type-tab':
                    $state.go('sysadmin.orderRequestType');
                    break;
                case 'conglomerates-tab':
                    $state.go('sysadmin.conglomerates');
                    break;
                case 'email-templates-tab':
                    $state.go('sysadmin.emailTemplates');
                    break;
                case 'users-tab':
                    $state.go('sysadmin.users');
                    break;
                case 'location-owners-tab':
                    $state.go('sysadmin.locationOwners');
                    break;
                default:
                    break;
            }
        }
    }
})();
