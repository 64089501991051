(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('commonUtility', ['$q', '$translate', commonUtility]);

    commonUtility.$inject = [];

    function commonUtility($q, $translate) {
        var commonUtility = {
            base64Encode: base64Encode,
            base64Decode: base64Decode,
            getQueryParams: getQueryParams,
            isImage: isImage,
            setHeightToFit: setHeightToFit,
            calculateAdminTabMenuWidth: calculateAdminTabMenuWidth,
            setAdminTabMenuFullWidth: setAdminTabMenuFullWidth,
            uuidv4: uuidv4,
            sortAlphanumericByField: sortAlphanumericByField,
            isFileWithoutExtension,
            fileWithoutExtensionAlert
        };

        return commonUtility;

        function base64Encode(str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                return String.fromCharCode(parseInt(p1, 16));
            }));
        }

        function base64Decode(str) {
            return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }

        function isImage(src) {

            var deferred = $q.defer();

            var image = new Image();
            image.onerror = function () {
                deferred.resolve(false);
            };
            image.onload = function () {
                deferred.resolve(true);
            };
            image.src = src;

            return deferred.promise;
        }

        function setHeightToFit(objectOrId) {
            if (objectOrId) {
                var object = null;
                if (typeof (objectOrId) === 'string') {
                    object = $('#' + objectOrId);
                } else if (objectOrId instanceof jQuery) {
                    object = objectOrId;
                } else {
                    return;
                }

                var windowHeight = $(window).height(),
                    objectPositionTop = $(object).position().top;

                if (windowHeight && objectPositionTop
                    && objectPositionTop < windowHeight) {

                    $(object).height(windowHeight - objectPositionTop);
                }
            }
        }

        function getQueryParams(queryString) {
            let baseQuery = queryString.replace('?', ''),
                keyValues = baseQuery.split('&');

            let queryObject = {};
            for (let i = 0; i < keyValues.length; ++i) {
                let keyAndValue = keyValues[i].split('=');
                if (keyAndValue && keyAndValue.length === 2) {
                    queryObject[keyAndValue[0]] = keyAndValue[1];
                }
            }

            return queryObject;
        }

        function calculateAdminTabMenuWidth()
        {
            // set tab-menu width and next btn position
            var tab = $( ".k-tabstrip" );
            var tabwidth = tab.width();

            var btnGroup = $( ".grid-btn-group" );
            var btnGroupWidth = btnGroup.width();

            var tabMenuWidth = tabwidth - (btnGroupWidth);
            var tabMenu = $( "#tab-menu" );
            tabMenu.width(tabMenuWidth + 'px');
        }

        function setAdminTabMenuFullWidth()
        {
            var tab = $( ".k-tabstrip" );
            var tabwidth = tab.width();

            var tabMenu = $( "#tab-menu" );
            tabMenu.width(tabwidth + 'px');
        }

        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        function sortAlphanumericByField(fieldToSort) {
            return (a, b) => {
                var collator = new Intl.Collator(navigator.language, {
                    numeric: true,
                    sensitivity: 'base'
                  });
                
                  return collator.compare(a[fieldToSort], b[fieldToSort]); 
            }
        }

        function isFileWithoutExtension(file) {
            return file.name.indexOf('.') === -1;
        }

        function fileWithoutExtensionAlert() {
            alert($translate.instant('FILE_EXTENSION_NEEDED_WARNING'));
        }
    }
})();
