(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentJobGroupDetailsModalController', EquipmentJobGroupDetailsModalController);

    EquipmentJobGroupDetailsModalController.$inject = [
        '$scope',
        '$windowInstance',
        'equipmentJobDefinitionGroup',
        'formTypes',
        'formType',
        'selectedEquipmentId',
        'equipmentJobDefinitionService',
    ];

    function EquipmentJobGroupDetailsModalController(
        $scope,
        $windowInstance,
        equipmentJobDefinitionGroup,
        formTypes,
        formType,
        selectedEquipmentId,
        equipmentJobDefinitionService
    ) {
        $scope.save = save;
        $scope.equipmentJobDefinitionGroup = equipmentJobDefinitionGroup;

        initController();
        function initController() {
            if (formType == formTypes.edit) {
                initModal();
            }
        }

        function initModal() {
            $scope.groupName = $scope.equipmentJobDefinitionGroup.name;
        }

        function save(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                switch (formType) {
                    case formTypes.add:
                        var equipmentJobDefinitionGroup = {
                            name: $scope.groupName,
                            itemId: selectedEquipmentId,
                        };
                        equipmentJobDefinitionService
                            .addEquipmentJobDefinitionGroup(equipmentJobDefinitionGroup)
                            .then(function (result) {
                                equipmentJobDefinitionGroup.equipmentJobDefinitionGroupId = result;
                                closeModal(equipmentJobDefinitionGroup);
                            });
                        break;
                    case formTypes.edit:
                        $scope.equipmentJobDefinitionGroup.name = $scope.groupName;
                        equipmentJobDefinitionService
                            .updateEquipmentJobDefinitionGroup($scope.equipmentJobDefinitionGroup)
                            .then(function (result) {
                                closeModal($scope.equipmentJobDefinitionGroup);
                            });
                        break;
                }
            }
        }

        function closeModal(equipmentJobDefinitionGroup) {
            $windowInstance.close(equipmentJobDefinitionGroup);
        }
    }
})();
