(function() {
    'use strict';

    angular.module('NaviaqWebApp').factory('reportService', reportService);

    reportService.$inject = [
        '$q',
        '$http',
        'serviceUrls',
        'controlType',
        'mediaService'
    ];

    function reportService(
            $q,
            $http,
            serviceUrls,
            controlType,
            mediaService
        ) {
        const reportApiUrl = `${serviceUrls.webApiBaseUrl}/report`;

        return {
            getControlTypeValues: getControlTypeValues,
            extendLog: extendLog,
            extendMapObjectWithActivites: extendMapObjectWithActivites,
            getFinalReportHistoryByOrderId,
        };

        function getControlTypeValues() {
            var controlTypeValues = [];

            for (var type in controlType) {
                if (controlType.hasOwnProperty(type)) {
                    controlTypeValues.push({
                        key: type,
                        value: controlType[type]
                    });
                }
            }
            return controlTypeValues;
        }

        function extendMapObjectWithActivites(mapObjectWithActivitiesList, controlTypes, operationLogMedias) {
            for (var index = 0; index < mapObjectWithActivitiesList.length; index++) {
                var mapObject = mapObjectWithActivitiesList[index].mapObject;
                switch (mapObject.__type) {
                    case 'Net:http://www.softwareinnovasjon.no/OrderDispatcherClientService/OrderDispatcherClientService/V1':
                        mapObject.mapObjectType = 'net';
                        mapObject.dimension = mapObject.dimention ? mapObject.dimention : '0';
                        break;
                    case 'Ring:http://www.softwareinnovasjon.no/OrderDispatcherClientService/OrderDispatcherClientService/V1':
                        mapObject.mapObjectType = 'ring';
                        break;
                    case 'Cage:http://www.softwareinnovasjon.no/OrderDispatcherClientService/OrderDispatcherClientService/V1':
                        mapObject.mapObjectType = 'cage';
                        break;
                    case 'Line:http://www.softwareinnovasjon.no/OrderDispatcherClientService/OrderDispatcherClientService/V1':
                        mapObject.mapObjectType = 'line';
                        break;
                    case 'MapLayerObject:http://www.softwareinnovasjon.no/OrderDispatcherClientService/OrderDispatcherClientService/V1':
                        if (mapObject.objectType == 'Point') {
                            mapObject.mapObjectType = 'buoy';
                        } else {
                            mapObject.mapObjectType = 'mapLayerObject';
                        }
                        break;
                    default:
                        mapObject.mapObjectType = 'object';
                }

                var operationLogs = mapObjectWithActivitiesList[index].operationLogs;
                if (operationLogs && operationLogs.length > 0) {
                    mapObjectWithActivitiesList[index].hasOperationLogs = true;
                    for (var ao = 0; ao < operationLogs.length; ao++) {
                        extendLog(operationLogs[ao], operationLogMedias);
                    }
                }

                var deviationLogs = mapObjectWithActivitiesList[index].deviationLogs;
                if (deviationLogs && deviationLogs.length > 0) {
                    mapObjectWithActivitiesList[index].hasDeviationLogs = true;
                    for (var ad = 0; ad < deviationLogs.length; ad++) {
                        extendLog(deviationLogs[ad], operationLogMedias);
                    }
                }

                var activities = mapObjectWithActivitiesList[index].activities;
                for (var j = 0; j < activities.length; j++) {
                    var activity = activities[j];
                    for (var k = 0; k < activity.activityLines.length; k++) {
                        var activityLine = activity.activityLines[k];
                        if (activityLine) {
                            var row = _.find(activity.activity.activityXMLContent.body.schemaRows, {
                                SchemaRowId: activityLine.activityRowId
                            });

                            if (row) {
                                activityLine.controlType = controlTypes[row.ControlType];
                                switch (activityLine.controlType.value) {
                                    case controlType.CheckBox:
                                    case controlType.ToggleButton:
                                        activityLine.displayValue = activityLine.Value ? '☑' : '☐';
                                        break;
                                    case controlType.DateTime:
                                    case controlType.FullDateTime:
                                        activityLine.displayValue = activityLine.Value
                                            ? moment(activityLine.Value, ['MMDDYYYY']).format('DD.MM.YYYY')
                                            : '';
                                        break;
                                    case controlType.DateTimeIntervalPicker:
                                        formatToDateTimeInverval(activityLine);
                                        break;
                                    case controlType.Table:
                                        formatToTable(activityLine);
                                        break;
                                    case controlType.Image:
                                    case controlType.ImageEditor:
                                        if (activityLine.Value)
                                            activityLine.imageUrl = `${reportApiUrl}/activityImageFile?activityLogXmlId=${activityLine.ActivityLogXmlId}&activityRowId=${activityLine.ActivityRowId}`;
                                        break;

                                    default:
                                        break;
                                }
                            }
                        }
                    }
                }
            }
        }

        //Private functions
        function extendLog(log, operationLogMedias) {
            var logMedias = operationLogMedias.filter(x => x.relatedEntityId == log.operationLogId);
            getOperationLogMedias(log, logMedias);

            var doneOn = moment(log.doneOn);
            doneOn = doneOn.hours(doneOn.hours());
            log.doneOn = doneOn.toDate();

            var dueDate = log.dueDate ? moment(log.dueDate) : moment(log.doneOn);
            dueDate = dueDate.hours(dueDate.hours());
            log.dueDate = dueDate.toDate();

            log.hasChecklist = log.logAction && log.logAction.checkListItems && log.logAction.checkListItems.length > 0;
            log.hasMissingChecklistItem = false;

            if (log.hasChecklist && log.logAction.checkListItems) {
                for (var i = 0; i < log.logAction.checkListItems.length; ++i) {
                    log.hasMissingChecklistItem = log.status !== 3;

                    var currentItem = log.logAction.checkListItems[i];
                    if (currentItem) {
                        currentItem.hasValue =
                            currentItem.checkListValue &&
                            (currentItem.checkListValue.boolValue ||
                                currentItem.checkListValue.notApplicable ||
                                currentItem.checkListValue.notOk ||
                                currentItem.checkListValue.numericValue ||
                                currentItem.checkListValue.stringValue);
                    }
                }
            }

            log.assignedUserDisplayName = log.assignedUser ? log.assignedUser.displayName : '';

            log.vesselName = log.department ? log.department.name : '';
            log.logActionName = log.logAction ? log.logAction.description : '';
            log.itemDescription = log.item ? log.item.description : '';

            log.orderDisplayName = '';
            if (log.order) {
                log.orderDisplayName += log.order.externalOrderId;

                if (log.order.location) {
                    log.orderDisplayName += '-' + log.order.location.locationName;
                }
            }
        }

        function getOperationLogMedias(log, operationLogMedias) {
            if (operationLogMedias && operationLogMedias.length > 0) {
                log.HasFiles = true;
                var files = operationLogMedias.flatMap(x => x.files);
                for (var i = 0; i < files.length; ++i) {
                    files[i].mediaUrl = mediaService.createMediaUrl(files[i].mediaId);
                }
                log.medias = files;
            }
        }

        function formatToDateTimeInverval(activityLine) {
            var displayValue = '';

            if (activityLine && activityLine.Value) {
                var dateValues = activityLine.Value.split('|');
                var startVal, endVal;
                for (var index = 0; index < dateValues.length; index++) {
                    var val = moment(dateValues[index], ['DD.MM.YYYY HH:mm:ss']);
                    if (val) {
                        if (displayValue) {
                            displayValue += ' - ';
                            endVal = val;
                        } else {
                            startVal = val;
                        }
                        displayValue += val.format('DD.MM.YY HH:mm');
                    }
                }
                if (startVal && endVal) {
                    var ms = moment(endVal).diff(moment(startVal));
                    var d = moment.duration(ms);
                    displayValue += ' (Medgått tid: ' + d.hours() + 't ' + d.minutes() + 'm)';
                }
            } else {
                displayValue = '(ikke startet)';
            }

            activityLine.displayValue = displayValue;
        }

        function formatToTable(activityLine) {
            var tableData = {};
            if (activityLine && activityLine.Value) {
                var tableLines = activityLine.Value.trim().split('\n');
                tableData.firstIsGuid = tableLines.length > 0 && tableLines[0].toLowerCase().startsWith('guid');
                tableData.header = tableLines[0].split(',');
                tableData.lines = [];
                for (var index = 1; index < tableLines.length; index++) {
                    var line = tableLines[index];
                    tableData.lines.push(line.split(','));
                }
            }
            activityLine.tableData = tableData;
        }

        function getFinalReportHistoryByOrderId(orderId) {
            const deferred = $q.defer(),
                url = `${reportApiUrl}/finalReportHistoryByOrderId?orderId=${orderId}`;

            $http
                .get(url)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));
            return deferred.promise;
        }
    }
})();
