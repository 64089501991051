(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('caseDetailsModalService', caseDetailsModalService);

    caseDetailsModalService.$inject = [
        '$q',
        '$rootScope',
        '$translate',
        '$kWindow',
        'formTypes',
        '$http',
        'serviceUrls',
        '$state'
    ];

    function caseDetailsModalService(
        $q,
        $rootScope,
        $translate,
        $kWindow,
        formTypes,
        $http,
        serviceUrls,
        $state
    ) {
        var webApiBaseUrl = serviceUrls.webApiBaseUrl;
        var service = {
            createCase: createCase,
            createCaseDetailsResolve: createCaseDetailsResolve,
            openCaseDetailsModal: openCaseDetailsModal,
            openCaseHistoryModal: openCaseHistoryModal,
            setTextareasDisableAttribute: setTextareasDisableAttribute,
            getDeviationDetailsByOperationLogId
        };

        return service;

        function createCase(dataItem) {
            return getDeviationDetailsByOperationLogId(dataItem.operationLogId).then((caseItem) => {
                return {
                    operationLogId: caseItem.operationLogId,
                    title: caseItem.title,
                    description: caseItem.description,
                    editedOn: new Date(),
                    editedBy: $rootScope.authData.username,
                    assignedUser: caseItem.assignedUser,
                    comment: caseItem.comment,
                    createdOn: caseItem.createdOn,
                    createdBy: caseItem.createdBy,
                    doneOnObject: new Date(caseItem.doneOn),
                    dueDateObject: new Date(caseItem.dueDate),
                    logActionId: caseItem.logActionId,
                    departmentId: caseItem.departmentId,
                    caseId: dataItem.caseId,
                    immediateAction: caseItem.immediateAction,
                    correctiveAction: caseItem.correctiveAction,
                    cause: caseItem.cause,
                    workflowStatuses: caseItem.workflowStatuses,
                    severity: caseItem.severity,
                    isEmailNotification: caseItem.isEmailNotification,
                    estimatedDownTime: caseItem.estimatedDownTime,
                    estimatedCost: caseItem.estimatedCost,
                    isOwnDeviation: caseItem.isOwnDeviation,
                    itemId: caseItem.itemId,
                    logActionIsCustomerDeviation: caseItem.logAction.isCustomerDeviation,
                    nets: caseItem.nets,
                    rings: caseItem.rings,
                    lines: caseItem.lines,
                    cages: caseItem.cages
                };
            });
        }

        function setTextareasDisableAttribute(scope,  caseData) {
            var isDeviationAdmin = scope.hasPermission('DeviationLogAdmin');
            var canCurrentUserEdit = checkCurrentUserInAssignedUserList(scope, caseData.assignedUser);
            if (isDeviationAdmin || canCurrentUserEdit) {
                return false;
            }
            else {
                return true;
            }
        }

        function checkCurrentUserInAssignedUserList(scope, assignedUsers)
        {
            var currentUserData = scope.authData;
            if(assignedUsers && assignedUsers.length > 0)
            {
                var user = assignedUsers.find(f => f.userId == currentUserData.userId);
                if(user) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else{
                return false;
            }
        }

        function createCaseDetailsResolve(caseData) {
            var  deferred = $q.defer();

            var resolve = {
                caseData: function () {
                    return caseData;
                },
                modalType: function () {
                    return caseData ? formTypes.edit : formTypes.add;
                }
            };
            deferred.resolve(resolve);

            return deferred.promise;
        }

        function openCaseDetailsModal(resolve) {
            var isEdit = resolve && resolve.caseData() && resolve.caseData().caseId;

            var modal =  $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: isEdit ? $translate.instant('CASE') + ' - ' + resolve.caseData().caseId  : $translate.instant('CASE_NEW'),
                    resizable: true,
                    width: 1120,
                    height: '90%',
                    visible: false
                },
                templateUrl: 'app/case/case-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CaseDetailsModalController',
                resolve
            });

            if($state.current.name === "cases"){
                modal.result.finally(function (response) {
                    $state.go('cases', {'caseNo': ''}, {notify: false});
                });
            }
            return modal;
        }

        function openCaseHistoryModal(hisotryData) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('CASE_HISTORY_MODAL_TITLE'),
                    resizable: true,
                    width: 1120,
                    height: 650,
                    visible: false
                },
                templateUrl: 'app/case/history/case-history-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CaseHistoryModalController',
                resolve: {
                history: function () {
                        return hisotryData;
                    }
                }
            });
        }

        function getDeviationDetailsByOperationLogId(operationLogId) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/byOperationLogId?operationLogId=' + operationLogId;
                $http.get(url).then(response => {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
