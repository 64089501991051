(function () {
    'use strict';
    angular.module('NaviaqWebApp').directive('lightboxDirective', lightboxDirective);

    lightboxDirective.$inject = [];

    function lightboxDirective() {
        return {
            controller: ['$scope', lightboxDirective],
            replace: true,
            restrict: 'E',
            templateUrl: 'app/shared/directives/lightbox-directive.html',
            scope: {
                url: '=url',
            },
        };

        function lightboxDirective($scope) {

        }
    }
})();
