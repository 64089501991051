(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OrderProcessAdministrationController', OrderProcessAdministrationController);

    OrderProcessAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'dateUtility',
        'formTypes',
        'commonUtility',
        'htmlElementService',
        'orderService'
    ];

    function OrderProcessAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        dateUtility,
        formTypes,
        commonUtility,
        htmlElementService,
        orderService
    ) {
        $scope.addOrderProcess = addOrderProcess;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
        $scope.orderProcesses = [];
        $scope.editProcess = editProcess;

        const orderProcessGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.orderProcesses)
                }
            }),
            columns: [
                {
                    field: 'OrderProcessId',
                    hidden: true
                },
                {
                    field: 'name',
                    title: 'Name'
                },
                {
                    field: 'createdOn',
                    title: 'Created on',
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.createdOn);
                    },
                },
                {
                    field: 'createdBy',
                    title: 'Created By'
                },
                {
                    command: [
                        {
                            name: 'editProcess',
                            click: e => editProcess(e),
                            template: '<a class="k-grid-editProcess"><i class="fas fa-edit"></i></a>'
                        }
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.orderProcessGridOptions = orderProcessGridOptions($translate.use());



        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'orderProcessGrid',
                destination: '#order-process-grid'
            });

            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });

            orderService.getOrderProcessesByContractorId($scope.authData.contractorId).then(function(processes){
                $scope.orderProcesses = processes;
                $scope.orderProcessGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'orderProcessGrid');
            });
        }

        function editProcess(event) {
            const orderProcess = getOrderProcessFromGrid(event);
            openOrderProcessDetailsModal(formTypes.edit, orderProcess);
        }

        function getOrderProcessFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#order-process-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function addOrderProcess() {
            openOrderProcessDetailsModal(formTypes.add);
        }

        function updateOrderProcessGrid()
        {
            initController();
        }

        function openOrderProcessDetailsModal(formType, orderProcess) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ORDER_PROCESS_TITLE'),
                    resizable: true,
                    visible: false,
                    width: 510
                },
                templateUrl: 'app/administration/orderProcess/order-process-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OrderProcessDetailsModalController',
                resolve: {
                    formType: () => formType == 'edit' ? formType : formTypes.add,
                    orderProcess: () => orderProcess
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateOrderProcessGrid();
                }
            });
        }
    }
})();
