(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('SupplierModalController', supplierModalController);

    supplierModalController.$inject = ['$scope', '$windowInstance', 'supplier', 'formType'];

    function supplierModalController($scope, $windowInstance, supplier, formType) {
        $scope.supplier = formType === 'add' ? { name: "", orgNr: "", isActive: false } : supplier; 
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.isDisabled = isDisabled;

        function isDisabled() {
            return ($scope.supplier.name == null || $scope.supplier.name.trim() === '') ||
                   ($scope.supplier.orgNr == null || $scope.supplier.orgNr.trim() === '');
        }

        function ok() {
            $windowInstance.close({result: $scope.supplier});
        }

        function cancel() {
            $windowInstance.close({result: false});
        }
    }
})();
