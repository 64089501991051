angular.module('NaviaqWebApp').directive('activities', [function () {
    'use strict';

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/report/directives/activities.html',
        link: function (scope, element, attrs) {
        }
    };
}]);
