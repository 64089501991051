(function () {
    'use strict';
    angular.module('NaviaqWebApp').controller('departmentEquipmentJobStatusModal', departmentEquipmentJobStatusModal);
    departmentEquipmentJobStatusModal.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        '$windowInstance',
        'departmentId',
        'status',
        'DepartmentStatus',
        'departmentService',
        'sanitizeService'];

    function departmentEquipmentJobStatusModal(
        $q,
        $scope,
        $rootScope,
        $translate,
        $windowInstance,
        departmentId,
        status,
        DepartmentStatus,
        departmentService,
        sanitizeService) {

        $scope.equipmentJobs = [];

        const equipmentJobsGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                autoSync: true,
                transport: {
                    read: e => e.success($scope.equipmentJobs),
                    update: e => {
                        const index = $scope.equipmentJobs.findIndex(x => x.equipmentJobDefinitionId == e.data.equipmentJobDefinitionId);
                        $scope.equipmentJobs[index].validFrom = e.data.validFrom.toJSON();

                        e.success();
                    }
                },
                schema: {
                    model: {
                        id: 'equipmentJobDefinitionId',
                        fields: {
                            description: { editable: false },
                            itemDescription: { editable: false },
                            lastGeneratedLogCounterValue: { editable: false },
                            itemJobType: { editable: false },
                            validFrom: { editable: status === DepartmentStatus.Active, type: 'date' },
                            validTo: { editable: false },
                        }
                    },
                }
            }),
            columns: [
                { selectable: true, width: 45 },
                {
                    field: 'equipmentJobDefinitionId',
                    hidden: true
                },
                {
                    field: 'description',
                    title: $translate.instant('G_DESCRIPTION', null, null, langId)
                },
                {
                    field: 'itemDescription',
                    title: $translate.instant('EQUIPMENT_JOB_DESCRIPTION', null, null, langId)
                },
                {
                    field: 'lastGeneratedLogCounterValue',
                    title: $translate.instant('EQUIPMENT_JOB_COUNTER_VALUE', null, null, langId)
                },
                {
                    field: 'itemJobType',
                    title: $translate.instant('EQUIPMENT_JOB_JOB_TYPE', null, null, langId),
                    template: (dataItem) => getEquipmentJobDefinitionType(dataItem.itemJobType)
                },
                {
                    field: 'validFrom',
                    title: $translate.instant('EQUIPMENT_JOB_VALID_FROM', null, null, langId),
                    template: (dataItem) => dataItem.validFrom ? moment(dataItem.validFrom).format('DD.MM.YYYY.') : ''
                },
                {
                    field: 'validTo',
                    title: $translate.instant('EQUIPMENT_JOB_VALID_TO', null, null, langId),
                    template: (dataItem) => dataItem.validTo ? moment(dataItem.validTo).format('DD.MM.YYYY.') : ''
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            dataBound: () => {
                const checkboxes = $("#equipment-jobs-grid tbody input:checkbox");

                checkboxes.prop("checked", true);
                checkboxes.trigger("click");
            }
        });
        $scope.equipmentJobsGridOptions = equipmentJobsGridOptions($translate.use());

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'departmentEquipmentJobStatusModal',
                destination: '.equipment-jobs-modal',
                overlay: true,
            });

            $q.all({
                equipmentJobs: status === DepartmentStatus.Active ?
                    departmentService.getInactiveEquipmentJobsByDepartmentId(departmentId) :
                    departmentService.getActiveEquipmentJobsByDepartmentId(departmentId)
            }).then((result) => {
                $scope.equipmentJobs = result.equipmentJobs;
                $scope.equipmentJobs.forEach(equipmentJob => {
                    equipmentJob.itemDescription = equipmentJob.item.description;
                });

                $scope.equipmentJobsGridOptions.dataSource.read();
            })
                .finally(() => $rootScope.$broadcast(
                    'hideBusyIndicator',
                    'departmentEquipmentJobStatusModal'
                ));
        }

        $scope.save = () => {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'departmentEquipmentJobStatusModal',
                destination: '.equipment-jobs-modal',
                overlay: true,
            });

            const selectedEquipmentJobs = [];
            const selectedRows = $scope.equipmentJobsGrid.select();
            for (var i = 0; i < selectedRows.length; i++) {
                var dataItem = $scope.equipmentJobsGrid.dataItem(selectedRows[i]);
                selectedEquipmentJobs.push(dataItem);
            }

            if(selectedEquipmentJobs && selectedEquipmentJobs.length){
                departmentService.updateEquipmentJobs(selectedEquipmentJobs.map(x => x.equipmentJobDefinitionId), status).then(() => {
                    $rootScope.$broadcast(
                        'hideBusyIndicator',
                        'departmentEquipmentJobStatusModal'
                    );

                    $scope.close(true);
                });
            } else {
                $rootScope.$broadcast(
                    'hideBusyIndicator',
                    'departmentEquipmentJobStatusModal'
                );

                $scope.close(true);
            }
        }

        $scope.close = (result = false) => {
            $windowInstance.close({ result: result });
        }

        function getEquipmentJobDefinitionType(itemJobTypeValue) {
            switch (itemJobTypeValue) {
                case 2:
                    return 'Service';
                case 1:
                    return 'Teller';
                case 0:
                    return 'Periodisk';
                default:
                    return '';
            }
        }
    }
})();
