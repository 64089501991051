(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .controller('ChangeContractorDepartmentController', ChangeContractorDepartmentController);

    ChangeContractorDepartmentController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'contractorService',
        'departmentService',
        'userService',
        'localStorageService',
        'roleService',
        'authService'
    ];

    function ChangeContractorDepartmentController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        contractorService,
        departmentService,
        userService,
        localStorageService,
        roleService,
        authService
    ) {
        $scope.selectedContractor = null;
        $scope.selectedDepartment = null;

        $scope.contractors = [];
        $scope.departments = [];

        $scope.onContractorChange = onContractorChange;
        $scope.validateForm = validateForm;
        $scope.closeModal = closeModal;

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'initChangeContractorDepartmentController',
                destination: '.k-widget.k-window',
                overlay: true,
            });

            $q.all({
                contractors: contractorService.getContractorsWithinConglomerate($rootScope.authData.contractorId),
                departments: departmentService.getDepartmentsWebapi($rootScope.authData.contractorId),
            })
                .then((data) => {
                    $scope.contractors = _.orderBy(data.contractors, ['name']);

                    $scope.selectedContractor = _.find($scope.contractors, {
                        contractorId: $rootScope.authData.contractorId,
                    });

                    $scope.departments = _.orderBy(data.departments, ['name']);

                    if ($rootScope.authData.activeDepartment) {
                        $scope.selectedDepartment = _.find($scope.departments, {
                            departmentId: $rootScope.authData.activeDepartment.departmentId,
                        });
                    }
                })
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'initChangeContractorDepartmentController'));
        }

        function onContractorChange() {
            departmentService.getDepartmentsWebapi($scope.selectedContractor.contractorId).then((departments) => {
                $scope.departments = _.orderBy(departments, ['name']);
            });
        }

        function validateForm(event) {
            event.preventDefault();

            $rootScope.authData.contractorId = $scope.selectedContractor.contractorId;
            $rootScope.authData.contractor = $scope.selectedContractor;

            $rootScope.authData.activeDepartment = $scope.selectedDepartment;

            userService
                .setUserContractorAndDepartment(
                    $rootScope.authData.contractorId,
                    $scope.selectedDepartment ? $scope.selectedDepartment.departmentId : ''
                )
                .then(() => {
                    $rootScope.$broadcast('selectedDepartmentChanged', {
                        contractorId: $scope.selectedContractor.contractorId,
                        contractor: $scope.selectedContractor,
                        activeDepartment: $scope.selectedDepartment,
                    });
                    updateRolesAndModules($scope.selectedContractor.contractorId).then(function(){
                        closeModal();
                    });                    
                });
        }

        function updateRolesAndModules(contractorId){
            var deferred = $q.defer();
            $q.all([
                userService.getModulesByContractorId(contractorId)
            ]).then(function (results) {
                var modules = results[0];
                var authData = localStorageService.get('authenticationData');       
                authData.modules = modules;
                localStorageService.set('authenticationData', authData);
                authService.fillAuthData();
                deferred.resolve(authData);
            }).catch(error => deferred.reject(error));
            return deferred.promise;
        }

        function closeModal() {
            $windowInstance.close();
        }
    }
})();
