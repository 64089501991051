(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ExternalCodeDetailsController', externalCodeDetailsController);

    externalCodeDetailsController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$kWindow',
        '$windowInstance',
        '$translate',
        'externalCodeTypes',
        'formType',
        'formTypes',
        'externalCode',
        'logActionPairsService'
    ];

    function externalCodeDetailsController(
        $q,
        $rootScope,
        $scope,
        $kWindow,
        $windowInstance,
        $translate,
        externalCodeTypes,
        formType,
        formTypes,
        externalCode,
        logActionPairsService
    ) {
        $scope.closeModal = close;
        $scope.save = save;

        $scope.externalCodeTypeOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(externalCodeTypes);
                    }
                }
            }),
            dataValueField: 'id',
            dataTextField: 'value',
            noDataTemplate: 'Ingen treff'
        };

        initController();

        function initController() {
            if(formType == formTypes.edit && externalCode){
                initModal();
            }
        }

        function initModal(){
            $scope.selectedExternalCodeType = externalCodeTypes.find(e => e.id == externalCode.externalCodeType);
            $scope.externalCodeValue = externalCode.value;
        }

        function save(){
            if(!$scope.validator.validate()){
                return;
            }

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'externalcodeIndicator',
                destination: '#external-code-details-form',
                overlay: true
            });

            if(formType == formTypes.add){
                var externalCodesObj = {
                    externalCodeType : $scope.selectedExternalCodeType.id,
                    value : $scope.externalCodeValue,
                }

                logActionPairsService.addExternalCode(externalCodesObj).then(function(){
                    close(true);
                }).finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'externalcodeIndicator');
                });

           } else if(formType == formTypes.edit) {

                externalCode.externalCodeType = $scope.selectedExternalCodeType.id;
                externalCode.value = $scope.externalCodeValue;

                logActionPairsService.updateExternalCode(externalCode).then(function(){
                    close(true);
                }).finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'externalcodeIndicator');
                });
           }
        }

        function close(param) {
            $windowInstance.close(param);
        }
    }
})();
