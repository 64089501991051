(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('DocumentDetailsModalController', DocumentDetailsModalController);

    DocumentDetailsModalController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$stateParams',
        '$windowInstance',
        '$translate',
        'itemTreeService',
        'userService',
        'contractorService',
        'formTypes',
        'formType',
        'selectedCategory',
        'itemCategories',
        'document',
        'mediaService',
        'itemStatusObject',
        'equipmentTreeData',
        'equipmentId',
        'dateUtility'];

    function DocumentDetailsModalController(
        $rootScope,
        $scope,
        $q,
        $stateParams,
        $windowInstance,
        $translate,
        itemTreeService,
        userService,
        contractorService,
        formTypes,
        formType,
        selectedCategory,
        itemCategories,
        document,
        mediaService,
        itemStatusObject,
        equipmentTreeData,
        equipmentId,
        dateUtility) {

        $scope.document = null;
        $scope.name = '';
        $scope.formType = formType;
        $scope.selectedItemCategory = selectedCategory;

        $scope.equipmentSelectorId = 'equipment-selector-dropdown';
        $scope.equipmentSelectorPlaceholder = '';
        $scope.equipmentTreeData = equipmentTreeData || [];
        $scope.selectedEquipmentIds = [];

        $scope.uploadedFileId = null;
        $scope.uploadedFileName = '';
        $scope.isFileRemoved = false;
        $scope.isFileExist = false;
        $scope.fileArray = [];
        $scope.users = [];

        $scope.documentDocUploaderId = 'documentDocUploader';
        $scope.documents = [];

        $scope.manualValidationSuccess = true;
        $scope.itemValidityIntervalTypeValues = getItemValidityIntervalTypeValues;
        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;
        $scope.updateNotifiedUserWarningTime = updateNotifiedUserWarningTime;

        $scope.itemDocumentOptions = {
            multiple: false
        };

        $scope.statusDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success(itemStatusObject)
                }
            }),
            dataValueField: 'value',
            dataTextField: 'name',
        };

        $scope.itemCategoryOptions = {
            dataSource: new kendo.data.DataSource({
                data: itemCategories
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            optionLabel: $translate.instant('EQUIPMENT_MODAL_SELECT_CATEGORY')
        };

        $scope.itemWarningUserOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success(_.sortBy($scope.users, 'displayName'))
                }
            }),
            change: notifiedUserHasChanged,
            dataValueField: 'userId',
            dataTextField: 'displayName',
            optionLabel: $translate.instant('EQUIPMENT_MODAL_SELECT_USER')
        };

        $scope.$watch('selectedItemCategory', function () {
            if ($scope.selectedItemCategory) {
                $scope.departmentId = $scope.selectedItemCategory.id;
                if ($scope.itemParents) {
                    $scope.itemParents.dataSource.read();
                    $scope.itemParents.refresh();
                }
            }
        });

        initController();

        function initController() {
            $q.all({
                getDefaultWarningDays: contractorService.getDefaultWarningDays($scope.authData.contractorId),
                users: userService.getUsersByContractorIdWebApi($scope.authData.contractorId)
            })
                .then(data => {
                    if (data && data.getDefaultWarningDays) {
                        $scope.defaultWarningDays = data.getDefaultWarningDays;
                    }

                    $scope.users = data.users;
                    $scope.itemWarningUserOptions.dataSource.read();

                    if (formType === formTypes.edit && document) {
                        initModal(document);
                    } else {
                        if (equipmentId) {
                            $scope.selectedEquipmentIds = [equipmentId];
                        }
                        $scope.selectedStatus = itemStatusObject.find(x => x.value == 0);
                    }
                })
                .catch(error => console.error(error));
        }

        function initModal(document) {
            $scope.document = document;
            $scope.name = document.name;
            $scope.documentComment = document.comment;
            $scope.description = document.description;
            $scope.selectedStatus = itemStatusObject.find(x => x.value == document.status);

            //Category selector
            var selectedCategoryItem = itemCategories.find(x => x.id == document.categoryId);
            $scope.selectedItemCategory = selectedCategoryItem;

            if (document.designation) {
                $scope.model = document.designation.modelNumber;
                $scope.serialNumber = document.designation.serialNumber;
            }

            if (document.validityInterval) {
                $scope.updateElapsedByParent = document.validityInterval.updateElapsedByParent;
                $scope.intervalElapsed = document.validityInterval.intervalElapsed;
                if (document.validityInterval.warningUserId) {
                    $scope.selectedItemWarningUser = _.find($scope.users, { userId: document.validityInterval.warningUserId });
                }
                if (document.validityInterval.validTo) {
                    $scope.validTo = new Date(document.validityInterval.validTo);
                }

                if (document.validityInterval.warningTime) {
                    var warningDate = new Date(document.validityInterval.validTo);
                    warningDate.setDate(warningDate.getDate() - document.validityInterval.warningTime);
                    $scope.warningTime = warningDate;
                }

                for (var i in $scope.itemValidityIntervalTypeValues) {
                    if ($scope.itemValidityIntervalTypeValues[i].value === document.validityInterval.intervalType) {
                        $scope.selectedItemIntervalType = $scope.itemValidityIntervalTypeValues[i];
                    }
                }
            }

            //ItemDetails
            if (document.itemDetails) {
                var detailObject = JSON.parse(document.itemDetails);
                for (var prop in detailObject) {
                    if (detailObject.hasOwnProperty(prop)) {
                        $scope[prop] = detailObject[prop];
                    }
                }
            }

            $scope.documents = $scope.document.files.filter(d => !d.naviaqMediaId).map((f) => {
                return {
                    id: f.id,
                    fileName: f.fileName,
                    url: mediaService.createMediaUrlMediaBank(f.mediaId),
                    mediaId: f.mediaId,
                    base64Content:  null,
                    createdOn: f.createdOn,
                    description: f.description,
                    selectedComponentId: null,
                    displayText: null,
                    FileType: 0
                }
            });

            $scope.selectedEquipmentIds = document.equipments.map(x => x.id);
        }

        function closeModal() {
            $windowInstance.close();
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                if (!manualValidation()) {
                    return;
                }
                var categoryId = null;
                if ($scope.selectedItemCategory && $scope.selectedItemCategory !== null) {
                    categoryId = $scope.selectedItemCategory.id
                }
                if (formType === formTypes.add) {
                    $scope.document = {
                        Name: $scope.name,
                        Description: $scope.description,
                        Comment: $scope.documentComment,
                        CategoryId: categoryId,
                        ContractorId: $scope.authData.contractorId,
                        RelatedEntityId: $stateParams.DepartmentId,
                        ParentId: null,
                        ItemDetails: JSON.stringify({
                            Location: $scope.Location,
                            SWL: $scope.SWL,
                            Weight: $scope.Weight
                        }),
                        Designation: null,
                        status: $scope.selectedStatus.value,
                        ValidityInterval: {
                            UpdateElapsedByParent: $scope.updateElapsedByParent,
                            IntervalBase: $scope.intervalBase,
                            IntervalElapsed: $scope.intervalElapsed,
                            WarningTime: $scope.warningTime != null && $scope.selectedItemWarningUser != undefined ? calculateWarningTime() : null,
                            ValidTo: $scope.validTo ? dateUtility.formatDateForServer($scope.validTo) : undefined,
                            IntervalType: $scope.selectedItemIntervalType ? $scope.selectedItemIntervalType.value : undefined,
                            WarningUserId: $scope.selectedItemWarningUser && $scope.selectedItemWarningUser.userId !== "" ? $scope.selectedItemWarningUser.userId : null,
                            WarnUser: $scope.selectedItemWarningUser && $scope.selectedItemWarningUser.userId !== ""
                        },
                        equipments: $scope.selectedEquipmentIds.map(x => ({ id: x }))
                    };

                    itemTreeService.addItem($scope.document)
                    .then((itemId) => {
                        $rootScope.$broadcast(`${$scope.documentDocUploaderId}Broadcast`, itemId, $scope.authData.contractorId, itemId);
                    })
                    .finally(() => {
                        $windowInstance.close(true);
                    });
                } else {
                    $scope.document = {
                        Id: document.id,
                        Name: $scope.name,
                        Description: $scope.description,
                        Comment: $scope.documentComment,
                        CategoryId: categoryId,
                        RelatedEntityId: $stateParams.DepartmentId,
                        ParentId: null,
                        ItemDetails: JSON.stringify({
                            Location: $scope.Location,
                            SWL: $scope.SWL,
                            Weight: $scope.Weight
                        }),
                        Designation: null,
                        status: $scope.selectedStatus.value,
                        ValidityInterval: {
                            UpdateElapsedByParent: $scope.updateElapsedByParent,
                            IntervalElapsed: $scope.intervalElapsed,
                            WarningTime: $scope.warningTime != null && $scope.selectedItemWarningUser != undefined ? calculateWarningTime() : null,
                            ValidTo: $scope.validTo ? dateUtility.formatDateForServer($scope.validTo) : undefined,
                            IntervalType: $scope.selectedItemIntervalType ? $scope.selectedItemIntervalType.value : undefined,
                            WarningUserId: $scope.selectedItemWarningUser && $scope.selectedItemWarningUser.userId !== "" ? $scope.selectedItemWarningUser.userId : null,
                            WarnUser: $scope.selectedItemWarningUser && $scope.selectedItemWarningUser.userId !== ""
                        },
                        equipments: $scope.selectedEquipmentIds.map(x => ({ id: x }))
                    };

                    itemTreeService.updateItemWithChildren($scope.document)
                    .then((itemId) => {
                        $rootScope.$broadcast(`${$scope.documentDocUploaderId}Broadcast`, itemId, $scope.authData.contractorId, itemId);
                    })
                    .finally(() => {
                        $windowInstance.close(true);
                    });
                }
            }
        }

        function manualValidation() {
            if($scope.warningTime && $scope.selectedItemWarningUser){
                $scope.manualValidationSuccess = calculateWarningTime() >= 0;
                return $scope.manualValidationSuccess;
            } else {
                return true;
            }
        }

        //Private functions
        function getItemValidityIntervalTypeValues() {
            return itemTreeService.getItemValidityIntervalTypeValues();
        }

        function calculateWarningTime() {
            return Math.round((($scope.validTo ? $scope.validTo : 0) - ($scope.warningTime ? $scope.warningTime : 0)) / (1000 * 60 * 60 * 24));
        }

        function updateNotifiedUserWarningTime(newDate) {
            if($scope.selectedItemWarningUser && newDate){
                var calculatedWarningTime = new Date(newDate);
                calculatedWarningTime.setDate(calculatedWarningTime.getDate() - $scope.defaultWarningDays);
                $scope.$applyAsync(function () {
                    $scope.warningTime = calculatedWarningTime;
                });

            }
        }

        function notifiedUserHasChanged(){
            if(!$scope.warningTime){
                updateNotifiedUserWarningTime($scope.validTo);
            }
        }
    }
})();
