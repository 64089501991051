(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('unitGroupsDetailsModalAdministrationController', unitGroupsDetailsModalAdministrationController);

    unitGroupsDetailsModalAdministrationController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'unitGroup',
        'currentFormType',
        'formTypes',
        'unitService',
    ];

    function unitGroupsDetailsModalAdministrationController(
        $rootScope,
        $scope,
        $windowInstance,
        unitGroup,
        currentFormType,
        formTypes,
        unitService,
    ) {
        $scope.unitGroup = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        initController();

        function initController() {
            if (currentFormType === formTypes.edit && unitGroup) {
                $scope.unitGroup = unitGroup;
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'unitGroupDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        $scope.unitGroup.contractorId = $rootScope.authData.contractorId
                        unitService.addUnitGroup($scope.unitGroup)
                            .then(unitGroup => close($scope.unitGroup))
                            .catch(() => close());
                        break;
                    case formTypes.edit:
                        unitService.updateUnitGroup($scope.unitGroup)
                            .then(unitGroup => close($scope.unitGroup))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'unitGroupDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
