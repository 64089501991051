(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OrderRequestTypeAdministrationController', OrderRequestTypeAdministrationController);

    OrderRequestTypeAdministrationController.$inject = ['$q', '$scope', '$rootScope', '$translate', 'kendoUtility', 'authService', 'adminOrderRequestTypeService', 'formTypes', '$kWindow'];

    function OrderRequestTypeAdministrationController($q, $scope, $rootScope, $translate, kendoUtility, authService, adminOrderRequestTypeService, formTypes, $kWindow) {
        $scope.addOrderRequestType = addOrderRequestType;
        $scope.orderRequestTypes = [];
        $scope.isSuperUser = authService.getAuthData().isSuperuser;

        const orderRequestTypeAdministrationGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.orderRequestTypes),
                },
                schema: {
                    model: {
                        id: 'key',
                        fields: {
                            key: { editable: false, type: 'string' },
                            noTranslationText: { editable: false, type: 'string' },
                            enTranslationText: { editable: false, type: 'string' },
                        }
                    },
                }
            }),
            columns: [
                {
                    field: 'key',
                    title: $translate.instant('ORDER_REQUEST_TYPE_KEY', null, null, langId),
                },
                {
                    field: 'noTranslationText',
                    title: $translate.instant('ORDER_REQUEST_TYPE_NOTEXT', null, null, langId),
                },
                {
                    field: 'enTranslationText',
                    title: $translate.instant('ORDER_REQUEST_TYPE_ENTEXT', null, null, langId),
                },
                {
                    command: [
                        { name: 'editOrderRequestType', click: e => editOrderRequestType(e), template: '<a class="k-grid-editOrderRequestType"><i class="fa fa-edit grid-icon"></i></a>' },
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            editable: false,
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.orderRequestTypeAdministrationGridOptions = orderRequestTypeAdministrationGridOptions($translate.use());

        $scope.$on('langChanged', (_, langId) => {
            $scope.orderRequestTypeAdministrationGridOptions = orderRequestTypeAdministrationGridOptions(langId);
            const orderRequestTypeAdministrationGrid = kendoUtility.createWidget('kendoGrid', $scope.orderRequestTypeAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(langId, [() => orderRequestTypeAdministrationGrid('#order-request-type-administration-grid')]);
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'orderRequestTypesLoad',
                destination: '#order-request-type-administration',
                overlay: true,
            });

            var requests = {
                getOrderRequestTypes: adminOrderRequestTypeService.getOrderRequestTypes()
            }

            $q.all(requests)
                .then(data => {
                    $scope.orderRequestTypes = data.getOrderRequestTypes;
                    extendOrderRequestTypes();
                    $scope.orderRequestTypeAdministrationGridOptions.dataSource.read();
                })
                .catch(error => console.error(error))
                .finally(function(){
                    $rootScope.$broadcast('hideBusyIndicator', 'orderRequestTypesLoad');
                });
        }

        function extendOrderRequestTypes() {
            $scope.orderRequestTypes = $scope.orderRequestTypes.map(type => ({
                ...type,
                noTranslationText: $translate.instant(type.key, null, null, 'no', null),
                enTranslationText: $translate.instant(type.key, null, null, 'en', null)
            }));
        }

        function editOrderRequestType(event) {
            const orderRequestType = getOrderRequestTypeFromGrid(event);
            const updateModal = openOrderRequestTypeModal(formTypes.edit, orderRequestType);
            orderRequestType && updateModal.result.then(orderRequestTypeResponse => {
                if (orderRequestTypeResponse) {
                    var gridItem = $scope.orderRequestTypes.find(x => x.orderRequestTypeId == orderRequestTypeResponse.orderRequestTypeId);
                    gridItem.key = orderRequestTypeResponse.key;
                    gridItem.noTranslationText = $translate.instant(orderRequestTypeResponse.key, null, null, 'no', null);
                    gridItem.enTranslationText = $translate.instant(orderRequestTypeResponse.key, null, null, 'en', null);
                    $scope.orderRequestTypeAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function getOrderRequestTypeFromGrid(event) {
            event.preventDefault();
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $("#order-request-type-administration-grid").data("kendoGrid").dataItem(row).toJSON();
        }

        function addOrderRequestType() {
            const addModal =openOrderRequestTypeModal(formTypes.add, null);
            addModal.result.then(orderRequestTypeResponse => {
                if (orderRequestTypeResponse) {
                    orderRequestTypeResponse.noTranslationText = $translate.instant(orderRequestTypeResponse.key, null, null, 'no', null);
                    orderRequestTypeResponse.enTranslationText = $translate.instant(orderRequestTypeResponse.key, null, null, 'en', null);
                    $scope.orderRequestTypes.push(orderRequestTypeResponse);
                    $scope.orderRequestTypeAdministrationGridOptions.dataSource.read();                  
                }
            });
        }

        function openOrderRequestTypeModal(formType, orderRequestType = null) {
            const title = formType === formTypes.add ? 'ORDER_REQUEST_TYPE_ADD_TYPE' : 'ORDER_REQUEST_TYPE_EDIT_TYPE';

            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant(title),
                    resizable: false,
                    visible: false,
                    width: 550
                },
                templateUrl: 'app/systemAdministration/orderRequestType/order-request-type-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'OrderRequestTypeDetailsModalController',
                resolve: {
                    formType: () => formType,
                    orderRequestType: () => orderRequestType,
                }
            });
        }
    }
})();
