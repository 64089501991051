(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('caseService', caseService);

    caseService.$inject = ['$q', '$http', 'serviceUrls'];

    function caseService($q, $http, serviceUrls) {
        var webApiBaseUrl = serviceUrls.webApiBaseUrl;

        var service = {
            addCase,
            getCasesByContractorId,
            getCaseHistoryById,
            getCasesByFilter,
            getCaseTypesByContractorId,
            getTagsByContractorId,
            addCaseTag,
            addCaseTags,
            deleteCaseTag,
            getTagsByContractorIdLegacy,
            getTagsByOperationLogId,
            updateCase,
            updateTags,
            getNumberOfWorkOrdersByOperationLogId,
            updateWorkOrders,
            getCasesForExcelExportByFilter,
            getCaseReportContent
        };

        return service;

        function getCaseTypesByContractorId(contractorId, relatedDepartmentId) {
            var deferred = $q.defer(),
                url = `${webApiBaseUrl}/deviation/list?contractorId=${contractorId}`;
                url = relatedDepartmentId ? `${url}&relatedDepartmentId=${relatedDepartmentId}` : url;
            $http.get(url).then(response => {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, error => deferred.reject(error));

            return deferred.promise;
        }

        function getTagsByContractorId(contractorId) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/tagsbycontractor?contractorId=' + contractorId;

            $http.get(url).then(response => {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, error => deferred.reject(error));

            return deferred.promise;
        }

        function addCaseTag(caseTag) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/tag';

            $http.post(url, caseTag).then(response => {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, error => deferred.reject(error));

            return deferred.promise;
        }

        function addCaseTags(caseTags) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/tags';

            $http.post(url, caseTags).then(response => {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteCaseTag(caseTagId) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + `/deviation/tag?caseTagId=${caseTagId}`;

            $http.delete(url).then(response => {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, error => deferred.reject(error));

            return deferred.promise;
        }

        function getTagsByContractorIdLegacy(contractorId) { //TODO: create it in webapi
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/tagsbycontractor?contractorId=' + contractorId;

            $http.get(url)
                .then(function (response) {
                    deferred.resolve(response.data.GetTagsByContractorIdResult);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getTagsByOperationLogId(operationLogId) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/tags?operationLogId=' + operationLogId;

            $http.get(url).then(response => {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, error => deferred.reject(error));

            return deferred.promise;
        }

        function updateTags(operationLogId, tags) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/tags?operationLogId=' + operationLogId;

            $http.put(url, tags).then(response =>
                deferred.resolve(response),
                error => deferred.reject(error)
            );

            return deferred.promise;
        }

        function addCase(newCase) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation';

            $http.post(url, newCase)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getCasesByContractorId(contractorId) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation?contractorId=' + contractorId;

            $http.get(url)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getCaseHistoryById(operationLogId) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/history?deviationId=' + operationLogId;

            $http.get(url)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function updateCase(caseToBeUpdated, handleComponents = false) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + `/deviation?handleComponents=${handleComponents}`;

            $http.put(url, caseToBeUpdated)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getCasesByFilter(filter) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/filterForView';

            $http.post(url, filter)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getCasesForExcelExportByFilter(operationLogIds) {
            var deferred = $q.defer(),
                url = webApiBaseUrl + '/deviation/excelExportForView';

            $http.post(url, operationLogIds)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getNumberOfWorkOrdersByOperationLogId(operationLogId){
            var deferred = $q.defer(),
            requestUrl = `${webApiBaseUrl}/deviation/numberOfWorkOrdersByOperationLogId?operationLogId=${operationLogId}`;

            $http.get(requestUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function updateWorkOrders(workOrders, selectedOperationLogId, selectedDepartmentId) {
            var deferred = $q.defer(),
                url = `${webApiBaseUrl}/deviation/workOrders?selectedOperationLogId=${selectedOperationLogId}&selectedDepartmentId=${selectedDepartmentId}`;

            if(workOrders && selectedOperationLogId){
                $http.put(url, workOrders).then(response =>
                    deferred.resolve(response),
                    error => deferred.reject(error)
                );
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }

        function getCaseReportContent(operationLogId) {
            const deferred = $q.defer();

            $http
                .get(`${webApiBaseUrl}/deviation/reportContent?operationLogId=${operationLogId}`)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
