(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationPanelController', LocationPanelController);

    LocationPanelController.$inject = ['$q', '$scope', '$rootScope', '$state', '$stateParams', 'locationService', 'userService', '$kWindow', '$translate'];

    function LocationPanelController($q, $scope, $rootScope, $state, $stateParams, locationService, userService, $kWindow, $translate) {
        $scope.locationName = '';
        $scope.editInformatin = editInformatin;
        $scope.toLocationOverview = toLocationOverview;

        $scope.tabStripOptions = {
            animation: false,
            scrollable: false
        };

        $scope.tabsVisibility = {
            installation : false,
            files: false,
            orders: false,
            info: true,
            changelog: false,
            deviationlogs: false,
            departments: false
        };

        initController();
        function initController() {
            $rootScope.$broadcast('setSplitterSize', {
                left: '50%',
                right: '50%'
            });

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationLoading',
                destination: '#right-pane',
                overlay: true
            });

            $q.all([
                locationService.getLocationBaseByLocationNumber($stateParams.LocationNumber),
                userService.getLocationAccessInfo($stateParams.LocationNumber, $scope.authData.userId)
            ]).then(function (data) {
                var location = data[0],
                    locationAccessInfo = data[1];

                if (location) {
                    $scope.locationName = location.name;

                    $rootScope.$broadcast(
                        'zoomToLocation',
                        { x: location.x, y: location.y },
                        { id: location.locationNumber, type: 'Location' }
                    );
                }

                if (locationAccessInfo.hasDetailedAccess) {
                    $scope.tabsVisibility = {
                        installation: true,
                        files: true,
                        orders: true,
                        info: true,
                        changelog: true,
                        deviationlogs: true,
                        departments: true
                    };
                } else {
                    $scope.locationpaneltab.select(3);
                }

                $rootScope.$broadcast('hideBusyIndicator', 'locationLoading');
            }, function() {
                $rootScope.$broadcast('hideBusyIndicator', 'locationLoading');
            });

            $scope.$watch("locationpaneltab", function () {
                switch ($state.current.name) {
                    case "main.locationInstallations":
                        $scope.locationpaneltab.select(0);
                        break;
                    case "main.locationFiles":
                        $scope.locationpaneltab.select(1);
                        break;
                    case "main.locationOrders":
                        $scope.locationpaneltab.select(2);
                        break;
                    case "main.locationInfo":
                        $scope.locationpaneltab.select(3);
                        break;
                    case "main.locationChangelog":
                        $scope.locationpaneltab.select(4);
                        break;
                    case "main.locationDeviationlogs":
                        $scope.locationpaneltab.select(5);
                        break;
                    case "main.locationDepartments":
                        $scope.locationpaneltab.select(6);
                        break;
                }
            });
        }

        function toLocationOverview() {
            $state.go('locationOverview', {
                LocationNumber: $stateParams.LocationNumber
            });
        }

        function editInformatin() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('MAIN_LOCATION_INFO_EDIT_WINDOW_TITLE'),
                    resizable: true,
                    height: 420,
                    width: 430,
                    visible: false
                },
                templateUrl: 'app/location/locationPanel/views/location-info-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LocationInfoModalController'
            });
        }
    }
})();
