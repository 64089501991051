(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('documentsModalController', documentsModalController);

    documentsModalController.$inject = ['$q', '$rootScope', '$scope', '$translate', 'selectedCategory', '$kWindow', '$timeout', 'itemTreeService', 'kendoUtility', 'mediaService', 'formTypes', 'documentService', 'departmentId', 'equipmentId', 'itemtreeKeys', 'treeUtility'];

    function documentsModalController($q, $rootScope, $scope, $translate, selectedCategory, $kWindow, $timeout, itemTreeService, kendoUtility, mediaService, formTypes, documentService, departmentId, equipmentId, itemtreeKeys, treeUtility) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.archivedSwitchChanged = archivedSwitchChanged;
        $scope.documentsGridData = [];
        $scope.departmentId = departmentId;
        $scope.categoryList = [];

        $scope.filterDropDowns = {
            categoryName: [],
            description: [],
            comment: [],
        };

        var documentsGridDataSource = new kendo.data.DataSource({
            data: $scope.documentsGridData,
            sort: [
                { field: 'validFrom', dir: 'desc' }
            ],
            group: {
                field: 'categoryName'
            },
            schema: {
                model: {
                    fields: {
                        description: { type: "object" },
                        validFrom: { type: "date" },
                        validTo: { type: "date" },
                        categoryName: { type: "object" },
                        comment: { type: "object" },
                    }
                }
            }
        });

        const documentsGridOptions = langId => ({
            dataSource: documentsGridDataSource,
            sortable: true,
            filterable: true,
            filterMenuInit: kendoUtility.onFilterMenuInit,
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS', null, null, langId)
            },
            columns: [
                {
                    command: [
                        {
                            name: 'edit-document',
                            click: function (e) {
                                e.preventDefault();
                                const sender = e.currentTarget;
                                const row = angular.element(sender).closest('tr');
                                var rowObj = $('#documentsGrid')
                                    .data('kendoGrid')
                                    .dataItem(row)
                                    .toJSON();
                                return editDocument(rowObj);
                            },
                            template: function () {
                                return '<a class="k-grid-edit-document"><i class="fas fa-edit"></i></a>';
                            }
                        },
                    ],
                    showInSmallView: false,
                    width: 50
                },
                {
                    field: 'description', title: $translate.instant('LOCATION_DOCUMENT_GRID_CAMPS', null, null, langId), showInSmallView: true, width: "30%",
                    template: '<span ng-if="#:!hasDocumentUrl#">#: description#</span><a href="#:documentUrl#" target="_blank" ng-if="#:hasDocumentUrl#">#: description#</a>',
                    filterable: { ui: (element) => createDropDownFilter(element, 'description') }
                },
                { field: 'validFrom', format: '{0:dd.MM.yyyy}', title: $translate.instant('LOCATION_DOCUMENT_GRID_DATEFROM', null, null, langId), showInSmallView: true, filterable: { ui: element => { element.kendoDatePicker({ format: '{0: MM/dd/yyyy}' }) } } },
                { field: 'validTo', format: '{0:dd.MM.yyyy}', title: $translate.instant('LOCATION_DOCUMENT_GRID_DATETO', null, null, langId), showInSmallView: true, filterable: { ui: element => { element.kendoDatePicker({ format: '{0: MM/dd/yyyy}' }) } } },
                { field: 'categoryName', title: $translate.instant('LOCATION_DOCUMENT_GRID_TYPE', null, null, langId), showInSmallView: true, filterable: { ui: (element) => createDropDownFilter(element, 'categoryName') } },
                { field: 'comment', title: $translate.instant('LOCATION_DOCUMENT_GRID_COMMENT', null, null, langId), showInSmallView: true, width: "15%", filterable: { ui: (element) => createDropDownFilter(element, 'comment') } },
                { field: 'equipmentList', title: $translate.instant('EQUIPMENT_TAB_EQUIPMENTS', null, null, langId), showInSmallView: true, width: "15%" }
            ]
        });

        $scope.$on('langChanged', (event, code) => {
            $scope.documentsGridOptions = documentsGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.documentsGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#documentsGrid')]);
        });

        function formatDate(date) {
            if (date) {
                return moment(new Date(date)).format('DD.MM.YYYY');
            }
            return '';
        }
        $scope.formatDate = formatDate;

        initController();
        function initController() {
            $timeout(() => {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'documentTabInit',
                    destination: '.location-documents-tab',
                    overlay: true
                });

                $scope.documentsGridOptions = documentsGridOptions($translate.use());

                $q.all({
                    getAllByEquipmentId: documentService.getAllByEquipmentId(equipmentId),
                    getItemsTreeByRelatedEntityId: itemTreeService.getItemsTreeByRelatedEntityId($rootScope.authData.contractorId, $scope.departmentId),
                    getItemCategoryTreeByKey: itemTreeService.getItemCategoryTreeByKey(itemtreeKeys.Department, $rootScope.authData.contractorId)
                }).then((result) => {
                    $scope.documentsGridData = [];
                    _.each(result.getAllByEquipmentId, function (item) {
                        extendItem(item);
                        loadFilters();
                        $scope.documentsGridData.push(item);
                    });
                    $scope.documentSource = $scope.documentsGridData;
                    $scope.documentsGridData = $scope.documentsGridData.filter(d => d.status != 1 && d.description);

                    $scope.documentsGrid.dataSource.data($scope.documentsGridData);
                    $scope.documentsGrid.refresh();

                    const treeNodeList = treeUtility.convertTreeToList(result.getItemCategoryTreeByKey, 'children', 'id');
                    treeNodeList[0].parentId = null;
                    $scope.categoryList = treeNodeList;
                    $scope.categoryList = _.orderBy($scope.categoryList, ['name'], ['asc']);

                    $scope.equipmentTreeData = result.getItemsTreeByRelatedEntityId;
                })
                    .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'documentTabInit'));
            }, 50);
        }

        function loadFilters() {
            const description = _.uniq($scope.documentsGridData.map(d => d.description));
            $scope.filterDropDowns.description = [];
            description.forEach(description => description && $scope.filterDropDowns.description.push({ value: description, text: description }));

            const categoryNames = _.uniq($scope.documentsGridData.map(d => d.categoryName));
            $scope.filterDropDowns.categoryName = [];
            categoryNames.forEach(name => name && $scope.filterDropDowns.categoryName.push({ value: name, text: name }));

            const comment = _.uniq($scope.documentsGridData.map(d => d.comment));
            $scope.filterDropDowns.comment = [];
            comment.forEach(comment => comment && $scope.filterDropDowns.comment.push({ value: comment, text: comment }));
        }

        function archivedSwitchChanged() {
            if ($scope.showArchivedDocument) {
                $scope.documentsGridData = $scope.documentSource;
                $scope.documentsGrid.dataSource.data($scope.documentsGridData);
                $scope.documentsGrid.refresh();
            } else {
                $scope.documentsGridData = $scope.documentSource.filter(d => d.status != 1);
                $scope.documentsGrid.dataSource.data($scope.documentsGridData);
                $scope.documentsGrid.refresh();
            }
        }

        function extendItem(item) {
            if (item.validityInterval) {
                if (!item.validityInterval.validTo || item.validityInterval.validTo === "1900-01-01T00:00:00") {
                    item.validTo = '';
                }
                else {
                    item.validTo = new Date(item.validityInterval.validTo)
                }
                if (!item.validityInterval.validFrom || item.validityInterval.validFrom === "1900-01-01T00:00:00") {
                    item.validFrom = '';
                }
                else {
                    item.validFrom = new Date(item.validityInterval.validFrom);
                }
            }
            else {
                item.validFrom = '';
                item.validTo = '';
            }
            item.categoryName = item.category && item.category.name ? item.category.name : '';
            item.documentId = item.id;

            //Add document link url to items
            if (item.files && item.files.length) {
                var files = _.orderBy(item.files, [function (file) {
                    return new Date(parseInt(file.createdOn.substr(6)));
                }], ['asc']);

                var documentUrl = mediaService.createMediaUrlMediaBank(files[0].mediaId);

                item.hasDocumentUrl = true;
                item.documentUrl = documentUrl;
            } else if (item.description && (item.description.startsWith("http") || item.description.startsWith("www"))) {
                item.hasDocumentUrl = true;
                item.documentUrl = item.description;
            } else {
                item.documentUrl = '';
                item.hasDocumentUrl = false;
            }

            item.equipmentList = item.equipments ? item.equipments.map(x => x.description).join(', ') : '';
        }

        function createDropDownFilter(element, field) {
            element.removeAttr("data-bind");
            element.kendoMultiSelect({
                dataSource: new kendo.data.DataSource({ data: $scope.filterDropDowns[field] }),
                dataTextField: "text",
                dataValueField: "value",
                change: function (e) {
                    var values = this.value();
                    var activeFilters = [];
                    $.each(values, function (i, v) {
                        activeFilters.push({ field: field, operator: "equal", value: v });
                    });
                    kendoUtility.createFilterOnGrid(documentsGridDataSource, activeFilters, field);
                }
            });
            if (!document.getElementById(`${field}-filter-menu-selector`)) {
                $(element).context.id = `${field}-filter-menu-selector`;
            }
        }

        $scope.addNewDocument = function () {
            const window = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('LOCATION_DOCUMENT_ADD_NEW'),
                    resizable: true,
                    visible: false,
                    width: !$rootScope.isSmallDevice ? '600px' : kendoUtility.calculateRemainingWidth(60)
                },
                templateUrl: 'app/equipments/document-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DocumentDetailsModalController',
                resolve: {
                    formType: () => formTypes.add,
                    selectedCategory: () => $scope.selectedCategory,
                    itemCategories: () => $scope.categoryList,
                    document: () => null,
                    equipmentTreeData: () => $scope.equipmentTreeData,
                    equipmentId: () => equipmentId
                }
            });

            window.result.then(initController);
        }

        function editDocument(document) {
            var documentObj = $scope.documentsGridData.find(d => d.id == document.documentId);
            const window = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('LOCATION_DOCUMENT_ADD_NEW'),
                    resizable: true,
                    visible: false,
                    width: !$rootScope.isSmallDevice ? '600px' : kendoUtility.calculateRemainingWidth(60)
                },
                templateUrl: 'app/equipments/document-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DocumentDetailsModalController',
                resolve: {
                    formType: () => formTypes.edit,
                    selectedCategory: () => selectedCategory,
                    itemCategories: () => $scope.categoryList,
                    document: () => documentObj,
                    equipmentTreeData: () => $scope.equipmentTreeData,
                    equipmentId: () => equipmentId
                }
            });

            window.result.then(initController);
        }
    }
})();
