(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('moduleService', moduleService);

    moduleService.$inject = ['$q', '$http', 'serviceUrls'];

    function moduleService($q, $http, serviceUrls) {
        const moduleBaseApiUrl = `${serviceUrls.webApiBaseUrl}/module`;

        return {
            getModules
        };
       
        function getModules() {
            var deferred = $q.defer();

            $http
                .get(`${moduleBaseApiUrl}`)
                .then((response) => deferred.resolve(response.data))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
