(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('timeBankHistoryModal', timeBankHistoryModal);

    timeBankHistoryModal.$inject = [
        '$scope',
        '$windowInstance',
        '$translate',
        'ids'
    ];

    function timeBankHistoryModal(
        $scope,
        $windowInstance,
        $translate,
        ids
    ) {
        initController();

        function initController() {
            $scope.entityIds = ids;
            $scope.logName = $translate.instant('ENTITY_HISTORY_TIMEBANK_LOG');
        }

        $scope.cancel = () => {
            $windowInstance.close();
        }
    }
})();
