(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('SummaryController', SummaryController);

    SummaryController.$inject = ['$rootScope', '$scope', '$state', '$translate', 'timeBankService', 'dateUtility', 'kendoUtility', 'operationLogStatus'];

    function SummaryController($rootScope, $scope, $state, $translate, timeBankService, dateUtility, kendoUtility, operationLogStatus) {
        $scope.summaryLogsDaily = [];
        $scope.summaryLogsHourly = [];
        $scope.summaryLogsAbsence = [];
        $scope.summaryLogsRest = [];

        $scope.exportToExcel = exportToExcel;
        $scope.exportPaycheckToCsv = exportPaycheckToCsv;
        $scope.exportAbsenceToCsv = exportAbsenceToCsv;
        $scope.closedAll = closedAll;

        function exportToExcel(grid) {
            grid.saveAsExcel();
        }

        $scope.filter = null;

        $scope.$on('timeBankFilterChanged', function (event, filter, isUpdate, state) {
            $scope.filter = filter;
            if(isUpdate && (state == 'summary' || (!state && $state.current.name == 'timeBank.summary')))
            {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'timeBankSummaryBusyIndicator',
                    destination: '#timebank-summary',
                    overlay: true,
                });
                loadFilteredLogs(filter);
            }
        });

        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];

        $scope.$on('langChanged', (e, code) => {
            $scope.summaryLogsDailyGridOptions = summaryLogsDailyGridOptions(code);
            $scope.summaryLogsHourlyGridOptions = summaryLogsHourlyGridOptions(code);
            $scope.summaryLogsAbsenceGridOptions = summaryLogsAbsenceGridOptions(code);

            var grid = kendoUtility.createWidget('kendoGrid', $scope.summaryLogsDailyGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#summary-logs-daily-grid')]);

            grid = kendoUtility.createWidget('kendoGrid', $scope.summaryLogsHourlyGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#summary-logs-hourly-grid')]);

            grid = kendoUtility.createWidget('kendoGrid', $scope.summaryLogsAbsenceGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#summary-logs-absence-grid')]);

            langId = code;
        });

        var columns = (langId) => [
            {
                field: 'personName',
                title: $translate.instant('TIMEBANK_LOG_GRID_PERSON', null, null, langId),
            },
            {
                field: 'departmentName',
                title: $translate.instant('TIMEBANK_LOG_GRID_DEPARTMENT', null, null, langId),
            },
            {
                field: 'startDateTime',
                title: $translate.instant('TIMEBANK_LOG_GRID_START_DATE', null, null, langId),
                template: function (dataItem) {
                    return dateUtility.formatDateTime(dataItem.startDateTime);
                },
            },
            {
                field: 'startActionName',
                title: $translate.instant('TIMEBANK_LOG_GRID_START_ACTION', null, null, langId),
                template: (dataItem) => {
                   return `<span kendo-tooltip k-content="\'${ dataItem.startActionName}\'">${dataItem.startActionName}</span>`;
                }
            },
            {
                field: 'startActionComment',
                title: $translate.instant('TIMEBANK_LOG_GRID_START_COMMENT', null, null, langId),
            },
            {
                field: 'stopDateTime ',
                title: $translate.instant('TIMEBANK_LOG_GRID_STOP_DATE', null, null, langId),
                template: function (dataItem) {
                    return dateUtility.formatDateTime(dataItem.stopDateTime);
                },
            },
            {
                field: 'stopActionName ',
                title: $translate.instant('TIMEBANK_LOG_GRID_STOP_ACTION', null, null, langId),
                template: (dataItem) => {
                    return `<span kendo-tooltip k-content="\'${dataItem.stopActionName}\'">${dataItem.stopActionName}</span>`;
                 }
            },
            {
                field: 'stopActionComment',
                title: $translate.instant('TIMEBANK_LOG_GRID_STOP_COMMENT', null, null, langId),
                template: (dataItem) => {
                    if(dataItem.stopActionId){
                        return dataItem.stopActionComment ? dataItem.stopActionComment : "";
                    } else {
                        return $translate.instant('TIMEBANK_LOG_GRID_ACTIV_COMMENT', null, null, langId);
                    }
                }
            },
            {
                field: 'description',
                title: $translate.instant('TIMEBANK_LOG_GRID_DESCRTIPTION', null, null, langId),
            },
            {
                field: 'createdBy',
                title: $translate.instant('TIMEBANK_LOG_GRID_CREATED_BY', null, null, langId),
            },
            {
                field: 'sum',
                title: $translate.instant('TIMEBANK_LOG_GRID_SUM', null, null, langId),
                template: function (dataItem) {
                    return dataItem.sum.toFixed(2);
                }
            },
            {
                field: 'statusText',
                title: $translate.instant('TIMEBANK_LOG_GRID_STATUS', null, null, langId)
            }
        ];

        var dailyCommand = {
            width: 100,
            command: {
                name: 'closedPair',
                click: (e) => setLogActionStatus(e, 4, '#summary-logs-daily-grid'),
                template: () => {
                    const buttonText = $translate.instant('TIMEBANK_SUMMARY_CLOSED', null, null, langId);
                    return `<a class="k-grid-closedPair n-button k-button">${buttonText}</a>`;
                },
            }
        };

        var summaryLogsDailyGridOptions = (langId) => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.summaryLogsDaily),
                },
                group: {
                    field: 'startActionName',
                },
            }),
            columns: columns(langId).concat(dailyCommand),
            editable: false,
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true,
            dataBound: (e) => {
                var dataItems = e.sender.dataSource.data();
                for (var i = 0; i < dataItems.length; ++i) {
                    if (dataItems[i].status === 4) {
                        var tr = $('#summary-logs-daily-grid').find('[data-uid="' + dataItems[i].uid + '"]');
                        $(tr).find('.k-grid-closedPair').hide();
                    }
                }
            }
        });
        $scope.summaryLogsDailyGridOptions = summaryLogsDailyGridOptions($translate.use());

        var hourlyCommand =  {
            width: 100,
            command: {
                name: 'closedPair',
                click: (e) => setLogActionStatus(e, 4, '#summary-logs-hourly-grid'),
                template: () => {
                    const buttonText = $translate.instant('TIMEBANK_SUMMARY_CLOSED', null, null, langId);
                    return `<a class="k-grid-closedPair n-button k-button">${buttonText}</a>`;
                },
            }
        };

        var summaryLogsHourlyGridOptions = (langId) => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.summaryLogsHourly),
                },
                group: {
                    field: 'startActionName',
                },
            }),
            columns: columns(langId).concat(hourlyCommand),
            editable: false,
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true,
            dataBound: (e) => {
                var dataItems = e.sender.dataSource.data();
                for (var i = 0; i < dataItems.length; ++i) {
                    if (dataItems[i].status === 4) {
                        var tr = $('#summary-logs-hourly-grid').find('[data-uid="' + dataItems[i].uid + '"]');
                        $(tr).find('.k-grid-closedPair').hide();
                    }
                }
            }
        });
        $scope.summaryLogsHourlyGridOptions = summaryLogsHourlyGridOptions($translate.use());

        var absenceCommand =  {
            width: 100,
            command: {
                name: 'closedPair',
                click: (e) => setLogActionStatus(e, 4, '#summary-logs-absence-grid'),
                template: () => {
                    const buttonText = $translate.instant('TIMEBANK_SUMMARY_CLOSED', null, null, langId);
                    return `<a class="k-grid-closedPair n-button k-button">${buttonText}</a>`;
                },
            }
        };

        var summaryLogsAbsenceGridOptions = (langId) => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.summaryLogsAbsence),
                },
                group: {
                    field: 'startActionName',
                },
            }),
            columns: columns(langId).concat(absenceCommand),
            editable: false,
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true,
            dataBound: (e) => {
                var dataItems = e.sender.dataSource.data();
                for (var i = 0; i < dataItems.length; ++i) {
                    if (dataItems[i].status === 4) {
                        var tr = $('#summary-logs-absence-grid').find('[data-uid="' + dataItems[i].uid + '"]');
                        $(tr).find('.k-grid-closedPair').hide();
                    }
                }
            }
        });
        $scope.summaryLogsAbsenceGridOptions = summaryLogsAbsenceGridOptions($translate.use());

        var restCommand =  {
            width: 100,
            command: {
                name: 'closedPair',
                click: (e) => setLogActionStatus(e, 4, '#summary-logs-rest-grid'),
                template: () => {
                    const buttonText = $translate.instant('TIMEBANK_SUMMARY_CLOSED', null, null, langId);
                    return `<a class="k-grid-closedPair n-button k-button">${buttonText}</a>`;
                },
            }
        };

        var summaryLogsRestGridOptions = (langId) => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.summaryLogsRest),
                },
                group: {
                    field: 'startActionName',
                },
            }),
            columns: columns(langId).concat(restCommand),
            editable: false,
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true,
            dataBound: (e) => {
                var dataItems = e.sender.dataSource.data();
                for (var i = 0; i < dataItems.length; ++i) {
                    if (dataItems[i].status === 4) {
                        var tr = $('#summary-logs-rest-grid').find('[data-uid="' + dataItems[i].uid + '"]');
                        $(tr).find('.k-grid-closedPair').hide();
                    }
                }
            }
        });
        $scope.summaryLogsRestGridOptions = summaryLogsRestGridOptions($translate.use());

        function loadFilteredLogs(filter) {
            filter.operationLogStatuses = [operationLogStatus.Approved, operationLogStatus.Closed];
            filter.includeRestLogs = true;
            timeBankService
                .getTimeBankOperationLogPairsForUsers(filter, false)
                .then((result) => {
                    result = extendSummaryLogs(result)
                    $scope.summaryLogsDaily = result.filter(s => (s.balanceType == 'Daily' || s.balanceType == 'Daily - Full Day') && !s.isRest);
                    $scope.summaryLogsDailyGridOptions.dataSource.read();

                    $scope.summaryLogsHourly = _.filter(result, { balanceType: 'Hourly', isRest : false });
                    $scope.summaryLogsHourlyGridOptions.dataSource.read();

                    $scope.summaryLogsAbsence = _.filter(result, (pair) => {
                        return (pair.balanceType === 'Absence Hourly' || pair.balanceType === 'Absence Daily') && !pair.isRest;
                    });
                    $scope.summaryLogsAbsenceGridOptions.dataSource.read();

                    $scope.summaryLogsRest = _.filter(result, (pair) => {
                        return pair.isRest;
                    });
                    $scope.summaryLogsRestGridOptions.dataSource.read();
                })
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'timeBankSummaryBusyIndicator');
                });
        }

        function extendSummaryLogs(summaryLogs){
            _.forEach(summaryLogs, function (log) {
                switch (log.status) {
                    case 1:
                        log.statusText = $translate.instant('TIMEBANK_STATUS_APPROVED', null, null, langId);
                        break;
                    case 4:
                        log.statusText = $translate.instant('TIMEBANK_STATUS_CLOSED', null, null, langId);
                        break;
                    default:
                        '';
                }
            });
            return summaryLogs;
        }

        function exportPaycheckToCsv() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'exportPaycheckToCsvBusyIndicator',
                destination: '#main-view',
                overlay: true
            });

            timeBankService.getPaycheckExport($scope.filter)
                .then((response) => {
                    let now = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
                    let fileName = `Paycheck_export_${new Date($scope.filter.fromDate).toLocaleDateString()}-${new Date($scope.filter.toDate).toLocaleDateString()}__${now}.csv`;
                    downloadFile(response.data, fileName);
                })
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'exportPaycheckToCsvBusyIndicator');
                });
        }

        function exportAbsenceToCsv() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'exportAbsenceToCsvBusyIndicator',
                destination: '#main-view',
                overlay: true
            });

            timeBankService.getAbsenceExport($scope.filter)
                .then((response) => {
                    let now = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
                    let fileName = `Absence_export_${new Date($scope.filter.fromDate).toLocaleDateString()}-${new Date($scope.filter.toDate).toLocaleDateString()}__${now}.csv`;
                    downloadFile(response.data, fileName);
                })
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'exportAbsenceToCsvBusyIndicator');
                });
        }

        function downloadFile(data, fileName) {
            var downloadLink = document.createElement('a');
            var blob = new Blob([data], { type: 'text/csv' });
            var url = URL.createObjectURL(blob);
            downloadLink.href = url;

            downloadLink.download = fileName;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }

        function setLogActionStatus(e, status, gridId) {
            e.preventDefault();

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'timeBankSummaryBusyIndicator',
                destination: gridId,
                overlay: true,
            });

            var data = $(gridId)
            .data('kendoGrid')
            .dataItem(angular.element(e.currentTarget).closest('tr'))
            .toJSON();

            var toUpdate = {
                logIdPairs: [{ startId: data.startOperationLogId, stopId: data.stopOperationLogId }],
                status: status,
                rejectReason: null
            };
            timeBankService
                .updateLogPairStatus(toUpdate)
                .then(function(){
                    loadFilteredLogs($scope.filter);
                })
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'timeBankSummaryBusyIndicator');
            });

        }

        function closedAll(id) {
            var gridId = '';
            var logs = [];
            if(id == 1){
                gridId = '#summary-logs-daily-grid';
                logs =  $scope.summaryLogsDaily;
            } else  if(id == 2){
                gridId = '#summary-logs-hourly-grid';
                logs =  $scope.summaryLogsHourly;
            } else if(id == 3){
                gridId = '#summary-logs-absence-grid';
                logs =  $scope.summaryLogsAbsence;
            }
            else if(id == 4){
                gridId = '#summary-logs-rest-grid';
                logs =  $scope.summaryLogsRest;
            }

            if (confirm($translate.instant('TIMEBANK_SET_CLOSED_ALL_QUESTION', null, null, langId))) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'timeBankLogsBusyIndicator',
                    destination: gridId,
                    overlay: true,
                });

                var toUpdate = {
                    logIdPairs: _.map(logs, (p) => {
                        return { startId: p.startOperationLogId, stopId: p.stopOperationLogId };
                    }),
                    status: 4,
                };

                timeBankService
                    .updateLogPairStatus(toUpdate)
                    .then(() => {
                        loadFilteredLogs($scope.filter);
                    })
                    .finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'timeBankLogsBusyIndicator');
                    });
            }
        }
    }
})();
