(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('TagsAdministrationController', TagsAdministrationController);

    TagsAdministrationController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        'commonUtility',
        'htmlElementService',
        'caseService',
        'workOrderService',
        'kendoUtility',
        '$kWindow',
        'dateUtility'
    ];

    function TagsAdministrationController(
        $q,
        $scope,
        $rootScope,
        $translate,
        commonUtility,
        htmlElementService,
        caseService,
        workOrderService,
        kendoUtility,
        $kWindow,
        dateUtility
    ) {
        $scope.tags = [];
        $scope.addNewTag = addNewTag;

        const tagsAdministrationGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.tags)
                }
            }),
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'typeOfTagText',
                    title: $translate.instant('WORKORDER_GRID_TAGS', null, null, langId)
                },
                {
                    field: 'tag',
                    title: $translate.instant('ADMIN_TAG_GRID_TAG', null, null, langId),
                },
                {
                    field: 'createdOn',
                    title: $translate.instant('G_CREATED_ON', null, null, langId)
                },
                {
                    command: [
                        {
                            name: 'deleteTag',
                            click: e => deleteTag(e),
                            template: '<a class="k-grid-deleteTag"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.tagsAdministrationGridOptions = tagsAdministrationGridOptions($translate.use());

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'tagsAdministrationGrid',
                destination: '#tags-administration-grid'
            });

            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });

            var requests = {
                caseTagsByContractor: caseService.getTagsByContractorId($rootScope.authData.contractorId),
                workOrderTagsByContractor: workOrderService.getWorkOrderTagsByContractorId($rootScope.authData.contractorId)
            };

            $q.all(requests)
                .then(data => {
                    createTags(data);
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.tagsAdministrationGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'tagsAdministrationGrid');
                });
        }

        function createTags(requests){
            var caseTags = requests.caseTagsByContractor ? requests.caseTagsByContractor : [];
            var workOrderTags = requests.workOrderTagsByContractor ? requests.workOrderTagsByContractor : [];
            $scope.tags = [];
            _.forEach(caseTags, function (tag) {
                var currentTag = {
                    id: tag.caseTagId,
                    typeOfTag: 'Case',
                    typeOfTagText: $translate.instant('CASE'),
                    tag:  tag.tag,
                    createdOn: dateUtility.formatDateTime(tag.createdOn)
                }
                $scope.tags.push(currentTag);
            });

            _.forEach(workOrderTags, function (tag) {
                var currentTag = {
                    id: tag.workOrderTagId,
                    typeOfTag: 'Workorder',
                    typeOfTagText: $translate.instant('WORKORDER'),
                    tag:  tag.tag,
                    createdOn: dateUtility.formatDateTime(tag.createdOn)
                }
                $scope.tags.push(currentTag);
            });
        }

        function deleteTag(event) {
            const tag = getTagFromGrid(event);
            openDeleteTagModal(tag);
        }

        function getTagFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#tags-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function addNewTag(){
            openTagDetailsModal();
        }

        function openTagDetailsModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_TAG_MODAL_TITLE'),
                    resizable: true,
                    visible: false,
                    width: 390
                },
                templateUrl: 'app/administration/tags/tag-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'TagDetailsModalController',
                resolve: {}
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateTagsGrid();
                }
            });
        }

        function openDeleteTagModal(tag){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'deleteTagAdministrationGrid',
                        destination: '#tags-administration-grid'
                    });

                    if(tag.typeOfTag.toLowerCase().trim() == 'case'){
                        caseService.deleteCaseTag(tag.id).then(function(deletedCaseId){
                            updateTagsGrid();
                            $rootScope.$broadcast('hideBusyIndicator', 'deleteTagAdministrationGrid');
                        });
                    } else {
                        workOrderService.deleteWorkOrderTag(tag.id).then(function(deletedWorkOrderId){
                            updateTagsGrid();
                            $rootScope.$broadcast('hideBusyIndicator', 'deleteTagAdministrationGrid');
                        });
                    }
                }
            });
         }

         function updateTagsGrid(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'tagsAdministrationGrid',
                destination: '#tags-administration-grid'
            });

            var requests = {
                caseTagsByContractor: caseService.getTagsByContractorId($rootScope.authData.contractorId),
                workOrderTagsByContractor: workOrderService.getWorkOrderTagsByContractorId($rootScope.authData.contractorId)
            };

            $q.all(requests)
                .then(data => {
                    createTags(data);
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.tagsAdministrationGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'tagsAdministrationGrid');
                });
         }
    }
})();
