(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CaseDetailsModalController', caseDetailsModalController);

    caseDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$kWindow',
        '$windowInstance',
        '$translate',
        'caseData',
        'modalType',
        'formTypes',
        'dateUtility',
        'workflowStatuses',
        'severityList',
        'workOrderService',
        'departmentService',
        'workOrderDetailsModalService',
        'workOrderStatuses',
        'mediaService',
        'userService',
        'caseService',
        'noUser',
        'caseDetailsModalService',
        'fileHandlingService',
        'itemTreeService',
        'commonUtility',
        'confirmDialogService',
        'DepartmentStatus',
        'componentService',
        'contractorService'
];

    function caseDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $kWindow,
        $windowInstance,
        $translate,
        caseData,
        modalType,
        formTypes,
        dateUtility,
        workflowStatuses,
        severityList,
        workOrderService,
        departmentService,
        workOrderDetailsModalService,
        workOrderStatuses,
        mediaService,
        userService,
        caseService,
        noUser,
        caseDetailsModalService,
        fileHandlingService,
        itemTreeService,
        commonUtility,
        confirmDialogService,
        DepartmentStatus,
        componentService,
        contractorService
    ) {

        $scope.okClickedOnce = false;
        $scope.showAddWorkOrderBtn = $scope.hasPermission('WorkOrderEdit') || $scope.hasPermission('WorkOrderAdmin') ? true : false;
        $scope.case = {};
        $scope.deviationStatuses = workflowStatuses;
        $scope.subtypes = [];
        $scope.fileArray = [];
        $scope.availableTags = [];
        $scope.currentTags = [];
        $scope.departments = [];
        $scope.filteredUser = [];
        $scope.filteredAssociatedUser = [];
        $scope.caseTypes = [];
        $scope.isReadOnly = modalType == formTypes.edit ? isReadOnlyPermission() : false;
        $scope.relatedItem = [];
        $scope.numberOfWorkOrders = 0;
        $scope.hasDepartmentChanged = false;
        $scope.assignedUserSelectionChanged = false;
        $scope.isOpenCase = false;

        // component equipment selector
        $scope.equipmentTreeData = [];
        $scope.componentTreeData = [];
        $scope.equipCompSelectedIds = [];
        $scope.initEditedModal = true;

        const ids = { cage: [], line: [], net: [], ring: [] } ;

        $scope.selectedUserIds = [];

        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];

        $scope.$watchGroup([
            'case.comment',
            'selectedDepartment',
            'selectedType',
            'selectedSubtype',
            'selectedStatus',
            'selectedSeverity'
        ], revalidateAll);

        $scope.workorders = [];
        $scope.assignedUserDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.filteredUser)
                }
            }),
            change: function () {
                $scope.assignedUserSelectionChanged = true;

                if ($scope.selectedUserIds.length) {
                    $scope.filteredAssociatedUser = getFilteredAssociatedUsers();
                    $scope.associatedUserDropDownOptions.dataSource.read();
                }
            },
            dataTextField: 'displayName',
            dataValueField: 'userId'
        };

        $scope.associatedSelectedUsers = [];
        $scope.associatedUserDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.filteredAssociatedUser)
                }
            }),
            batch: true,
            dataTextField: 'displayName',
            dataValueField: 'userId',
        };

        $scope.selectedDepartment = null;
        $scope.departmentDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.departments)
                }
            }),
            dataTextField: 'name',
            dataValueField: 'departmentId',
            change: () => {
                if ($scope.selectedDepartment) {
                    $scope.hasDepartmentChanged = true;
                    const locationNumber = getSelectedDepartmentLocationNumber($scope.selectedDepartment);
                    loadEquipmentDropDownList($scope.selectedDepartment, locationNumber);
                    loadLogActionAndSubType();
                }
            }
        };

        $scope.selectedType = null;
        $scope.caseTypeDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.caseTypes)
                }
            }),
            dataTextField: 'description',
            dataValueField: 'logActionId',
            change: function () {
                $scope.caseTypes = $scope.caseTypes || [];
                $scope.selectedCaseType = $scope.caseTypes.find(type => type.logActionId === $scope.selectedType)
                if($scope.selectedCaseType)
                {
                    if($scope.selectedCaseType.list && $scope.selectedCaseType.list.listElements && $scope.selectedCaseType.list.listElements.length > 0)
                    {
                       $scope.subtypes = $scope.selectedCaseType.list.listElements.map(elem => elem.displayName);
                    }
                }

                if($scope.selectedCaseType && $scope.selectedCaseType.isCustomerDeviation){
                    if($scope.hasBanOnModule('KundeAvvikInWorkStatus')){
                        $scope.deviationStatuses =  $scope.deviationStatuses.filter(x => x.value != '22222222-2222-2222-2222-222222222222');
                    }
                } else {
                    $scope.deviationStatuses = workflowStatuses;
                }
                $scope.statusDropdown.dataSource.read();
                $scope.statusDropdown.refresh();
                $scope.caseSubtypeDropDownOptions.dataSource.read();
            }
        };

        $scope.selectedSubtype = null;
        $scope.caseSubtypeDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (e) {
                        return e.success($scope.subtypes);
                    }
                }
            })
        };

        $scope.selectedStatus = workflowStatuses[0].value;
        $scope.statusDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.deviationStatuses)
                }
            }),
            dataTextField: 'text',
            dataValueField: 'value'
        };

        $scope.selectedSeverity = severityList[0].value;
        $scope.severityDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                data: severityList
            }),
            dataTextField: 'text',
            dataValueField: 'value'
        };

        $scope.selectedTags = [];
        $scope.tagsMultiselectOptions = {
            filter: "startswith",
            dataSource: {
                batch: true,
                transport: {
                    read: e => e.success($scope.availableTags)
                }
            },
            dataTextField: 'tag',
            dataValueField: 'caseTagId',
            noDataTemplate: $("#case-tags-template").html()
        };

        $scope.relatedWorkOrdersGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => { e.success($scope.workorders) }
                },
                batch: false,
                schema: {
                    model: {
                        id: 'operationLogId',
                        fields: {}
                    }
                }
            }),
            columns: [
                {
                    title: $translate.instant('WORKORDER_GRID_WONUMBER'),
                    field: 'woNumber',
                    width: 50,
                    template: function (dataItem) {
                        if (dataItem.woNumber) {
                            return '<div class="text-center button-workorder-link" ng-click="openRelatedWorkOrderLog($event)">' + dataItem.woNumber + '</div>';
                        }
                        return '';
                    }
                },
                {
                    title: $translate.instant('G_DEPARTMENT'),
                    field: 'department.name',
                    width: 100,
                    filterable: false
                },
                {
                    title: $translate.instant('WORKORDER_GRID_LOGACTION'),
                    field: 'logAction.description',
                    width: 80
                },
                {
                    title: $translate.instant('WORKORDER_GRID_TITLE'),
                    field: 'title',
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        if (dataItem.title) {
                            return dataItem.title;
                        }
                        return '';
                    }
                },
                {
                    title: $translate.instant('WORKORDER_GRID_ASSIGNED_TO'),
                    field: 'assignedUser',
                    template: function (dataItem) {
                        if (dataItem.assignedUser && dataItem.assignedUser[0] && dataItem.assignedUser[0].user) {
                            return dataItem.assignedUser[0].user.displayName;
                        }
                        return 'Ikke tildelt';
                    },
                    width: 150,
                    filterable: false
                },
                {
                    title: $translate.instant('WORKORDER_GRID_DUEDATE'),
                    field: 'dueDate',
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.dueDate);
                    },
                    width: 100,
                    filterable: false
                },
                {
                    title: $translate.instant('WORKORDER_GRID_STATUS'),
                    field: 'status',
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return workOrderStatuses.find(s => s.value == dataItem.status).text;
                    },
                    width: 150,
                    filterable: false
                }
            ],
            editable: false
        };

        $scope.relatedMediaGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (e) {e.success($scope.relatedItem ? $scope.relatedItem.files : [])}
                }
            }),
            columns: [
                {
                    field: 'operationLogMediaId',
                    hidden: true
                },
                {
                    title: $translate.instant('CASE_FILES_GRID_FILENAME'),
                    field: 'fileName',
                    template: function (dataItem) {
                        return '<a href="' + mediaService.createMediaUrlMediaBank(dataItem.mediaId) + '" target="_blank">' + dataItem.fileName + '</a>';
                    }
                },
                {
                    title: $translate.instant('CASE_FILES_GRID_CREATEDBY'),
                    field: 'createdBy'
                },
                {
                    title: $translate.instant('CASE_FILES_GRID_CREATEDON'),
                    field: 'createdOn',
                    template: function (dataItem) {
                        return dateUtility.formatDate(dataItem.createdOn);
                    }
                },
                {
                    command: [
                        {
                            name: 'deleteRelatedMedia',
                            click: (e) => deleteRelatedMedia(e),
                            template: `<a class="k-grid-deleteRelatedMedia""><i class="fas fa-trash-alt grid-command-icon"></i></a>`,
                            hidden: modalType == formTypes.edit ? isReadOnlyPermission() : true,
                        },
                    ],
                    width: 80,
                }
            ],
            editable: false
        };

        $scope.tabOptions = {
            animation: false,
            value: $translate.instant('CASE_MODAL_TAB_DETAILS'),
            dataTextField: 'Name',
            dataContentUrlField: 'ContentUrl',
            dataSource: new kendo.data.DataSource({
                data: []
            })
        };

        $scope.ok = ok;
        $scope.close = close;
        $scope.cancel = cancel;
        $scope.addNewWorkOrder = addNewWorkOrder;
        $scope.uploadFile = uploadFile;
        $scope.handleFiles = handleFiles;
        $scope.exportToPDF = exportToPDF;
        $scope.openRelatedWorkOrderLog = openRelatedWorkOrderLog;
        $scope.contractorId = null;

        initController();

        function initController() {
            showBusyIndicator();
            var contractorId = $rootScope.authData.contractorId;
            $scope.contractorId = contractorId;

            var filter = {
                contractorIds: [contractorId],
                permissions: ['DeviationLogView']
            };

            let requests = {};
            requests.getDepartmentsWebapi = departmentService.getDepartmentsWebapi(contractorId, true);
            requests.getUsersWithPermissionsAsDropdownByFilter = userService.getUsersWithPermissionsAsDropdownByFilter(filter);
            requests.getDefaultLogActions = contractorService.getDefaultLogActions(contractorId)

            if (caseData) {
                ids.cage = _.map(caseData.cages, 'cageId');
                ids.line = _.map(caseData.lines, 'lineId');
                ids.net = _.map(caseData.nets, 'id');
                ids.ring = _.map(caseData.rings, 'ringId');
            }

            if (caseData && caseData.departmentId){
                requests.getWorkOrderLogsByParentId =  workOrderService.getWorkOrderLogsByParentId(caseData.operationLogId);
                requests.getDepartmentByIdWebApi = departmentService.getDepartmentByIdWebApi(caseData.departmentId);
            }

            if(caseData && caseData.operationLogId){
                requests.getItemsByRelatedEntityIdOrCreate = itemTreeService.getItemsByRelatedEntityIdOrCreate($scope.contractorId, caseData.operationLogId, 'Case document')
                requests.getNumberOfWorkOrdersByOperationLogId = caseService.getNumberOfWorkOrdersByOperationLogId(caseData.operationLogId);
            }

            $q.all(requests)
                .then(function (data) {
                    $scope.defaultLogActions = data.getDefaultLogActions;
                    if(data.getItemsByRelatedEntityIdOrCreate){
                        $scope.relatedItem = data.getItemsByRelatedEntityIdOrCreate.length ?
                            data.getItemsByRelatedEntityIdOrCreate.length > 1 ? data.getItemsByRelatedEntityIdOrCreate.find(x => x.name === 'Case document') : data.getItemsByRelatedEntityIdOrCreate[0]
                            : [];
                    }
                    $scope.departments = data.getDepartmentsWebapi;
                    $scope.caseDepartment = data.getDepartmentByIdWebApi;
                    $scope.users = modifiedData(data.getUsersWithPermissionsAsDropdownByFilter);
                    $scope.filteredUser = getFilteredUsers();

                    $scope.assignedUserDropDownOptions.dataSource.read();
                    $scope.caseTypeDropDownOptions.dataSource.read();

                    if(data.getNumberOfWorkOrdersByOperationLogId){
                        $scope.numberOfWorkOrders = data.getNumberOfWorkOrdersByOperationLogId;
                    }

                    switch (modalType) {
                        case formTypes.add:
                            $scope.departments = $scope.departments.filter(d => d.status === DepartmentStatus.Active);
                            $scope.departmentDropDownOptions.dataSource.read();
                            $scope.disabledTexArea = false;
                            $scope.case.createdOn = new Date();
                            $scope.case.createdBy = $rootScope.authData.username;
                            $scope.createdByDisplay = $rootScope.authData.displayName;
                            $scope.tabOptions.dataSource.add({ Name: $translate.instant('CASE_MODAL_TAB_DETAILS'), ContentUrl: 'app/case/case-details-tab.html' });

                            caseService.getTagsByContractorId($rootScope.authData.contractorId).then(tags => {
                                $scope.availableTags = _.orderBy(tags, 'tag', 'asc');
                                $scope.tagsMultiselect.dataSource.read();
                                $scope.tagsMultiselect.refresh();
                            });
                            $scope.case.isEmailNotification = true;
                            $scope.caseTabs.select(0);
                            $scope.filteredAssociatedUser = getFilteredAssociatedUsers();
                            $scope.associatedUserDropDownOptions.dataSource.read();
                            break;
                        case formTypes.edit:
                            if (caseData) {

                                $scope.isEdit = true;
                                $scope.tabOptions.dataSource.add({ Name: $translate.instant('CASE_MODAL_TAB_DETAILS'), ContentUrl: 'app/case/case-details-tab.html' });
                                $scope.tabOptions.dataSource.add({ Name: $translate.instant('CASE_MODAL_TAB_WORKORDERS'), ContentUrl: 'app/case/case-workorders-tab.html' });
                                $scope.tabOptions.dataSource.add({ Name: $translate.instant('CASE_MODAL_TAB_FILES'), ContentUrl: 'app/case/case-files-tab.html' });
                                $scope.case = caseData;
                                $scope.departments = $scope.departments.filter(d => d.status === DepartmentStatus.Active || d.departmentId == caseData.departmentId);

                                if (!$scope.departments.length) {
                                    $scope.departments.push($scope.caseDepartment);
                                }
                                $scope.departmentDropDownOptions.dataSource.read();
                                $scope.selectedDepartment = caseData.departmentId;

                                $scope.case.title = caseData.title;
                                $scope.workorders = data.getWorkOrderLogsByParentId;

                                var departmentDropDownList = $("#case-department").data("kendoDropDownList");
                                departmentDropDownList.value($scope.selectedDepartment);
                                departmentDropDownList.trigger("change");

                                $scope.disabledTexArea = $scope.isReadOnly ? true : caseDetailsModalService.setTextareasDisableAttribute($scope, caseData);

                                $scope.selectedSeverity = caseData.severity;

                                var thisAssigned = caseData.assignedUser.find(u => u.role === 'Assigned');
                                if ((thisAssigned) && (!$scope.filteredUser.find(u => u.userId === thisAssigned.userId && thisAssigned.role === 'Assigned'))){
                                    $scope.filteredUser.push(thisAssigned.user);
                                }

                                if ($scope.case.dueDateObject && dateUtility.isDefaultDate($scope.case.dueDateObject)) {
                                    $scope.case.dueDateObject = null;
                                }

                                loadLogActionAndSubType();

                                if (Array.isArray(caseData.workflowStatuses) && caseData.workflowStatuses.length > 0) {
                                    $scope.selectedStatus = caseData.workflowStatuses[0].workflowStatusId;
                                    $scope.isOpenCase = $scope.selectedStatus === '11111111-1111-1111-1111-111111111111';
                                }

                                if (Array.isArray(caseData.assignedUser)) {
                                    var assignedTo = caseData.assignedUser.filter(function (user) {
                                        return user.role === 'Assigned';
                                    });
                                    var associatedTo = caseData.assignedUser.filter(function (user) {
                                        return user.role === 'Associated';
                                    });
                                    if (assignedTo.length > 0) {
                                        $scope.selectedUserIds = assignedTo;
                                    }
                                }
                                var createdByUser = data.getUsersWithPermissionsAsDropdownByFilter.find(x => x.userName === caseData.createdBy)
                                $scope.createdByDisplay = createdByUser ? createdByUser.displayName : caseData.createdBy;

                                if($scope.selectedCaseType && $scope.selectedCaseType.isCustomerDeviation){
                                    if($scope.hasBanOnModule('KundeAvvikInWorkStatus')){
                                        $scope.deviationStatuses = $scope.deviationStatuses.filter(x => x.value != '22222222-2222-2222-2222-222222222222');
                                    }
                                } else {
                                    $scope.deviationStatuses = workflowStatuses;
                                }

                                $scope.statusDropdown.dataSource.read();
                                $scope.statusDropdown.refresh();

                                $scope.filteredAssociatedUser = getFilteredAssociatedUsers();
                                $scope.associatedUserDropDownOptions.dataSource.read();
                                $scope.associatedMultiselect.value(associatedTo.map(t => t.user));


                                $q.all([
                                    caseService.getTagsByContractorId($rootScope.authData.contractorId),
                                    caseService.getTagsByOperationLogId(caseData.operationLogId)
                                ]).then(data => {
                                    $scope.availableTags = _.orderBy(data[0], 'tag', 'asc');
                                    $scope.tagsMultiselect.dataSource.read();
                                    $scope.tagsMultiselect.refresh();

                                    $scope.currentTags = data[1];
                                    if ($scope.currentTags && $scope.currentTags.length > 0) {
                                        $scope.tagsMultiselect.value($scope.currentTags);
                                    }
                                });

                                if (caseData.workflowStatuses && caseData.workflowStatuses.length > 0) {
                                    if (caseData.workflowStatuses[0].workflowStatusId !== $scope.selectedStatus) {
                                        $scope.case.workflowStatuses = [{
                                            workflowStatusId: $scope.selectedStatus,
                                            createdBy: authData.username,
                                            createdOn: dateUtility.toIsoString(now),
                                            status: 0
                                        }];
                                    } else {
                                        $scope.case.workflowStatuses = [];
                                    }
                                } else {
                                    $scope.case.workflowStatuses = [];
                                }

                                $scope.caseTabs.select(0);
                            }
                            break;
                        default:
                            break;
                    }
                    hideBusyIndicator();
                });
        }

        function openRelatedWorkOrderLog(event) {
            var sender = event.currentTarget;
            var row = angular.element(sender).closest("tr");
            var dataItem = $("#related-work-orders-grid").data("kendoGrid").dataItem(row).toJSON();
            var workOrderLog = workOrderDetailsModalService.createWorkOrderLog(dataItem);
            workOrderDetailsModalService.createWorkOrderDetailsResolve(workOrderLog)
                .then(function (resolve) {
                    var modal = workOrderDetailsModalService.openWorkOrderDetailsModal(workOrderLog, null, isReadOnlyPermission());
                    modal.result.then(function () {
                        $scope.relatedWorkOrdersGridOptions.dataSource.read();
                    });
                });
        }

        function modifiedData(users) {
            var modifiedData = users.concat([noUser]).sort(function (a, b) {
                if (a.displayName < b.displayName) { return -1; }
                if (a.displayName > b.displayName) { return 1; }
                return 0;
            });
            return modifiedData;
        }

        function ok(isSaveAndClose) {
            $scope.okClickedOnce = true;
            if ($scope.hasDepartmentChanged && $scope.numberOfWorkOrders && $scope.case && $scope.case.departmentId) {
                let departmentName = $scope.departments.find(x => x.departmentId === $scope.selectedDepartment)
                const content = $translate.instant('CASE_UPDATE_WORK_ORDER_MESSAGE').replace('x',departmentName && departmentName.name),
                title = $translate.instant('CASE_UPDATE_WORK_ORDER'),
                obBtnTxt = $translate.instant('G_YES'),
                cancelBtnTxt = $translate.instant('G_NO'),
                relatedWorkOrders = $scope.workorders;

                confirmDialogService
                    .openCaseConfirmDialog(null, content, title, obBtnTxt, cancelBtnTxt, relatedWorkOrders, $scope.selectedDepartment)
                    .then(modal =>  { modal.result && callSaveDeviation(isSaveAndClose, modal.selectedWorkOrders)})
            } else {
                callSaveDeviation(isSaveAndClose);
            }
        }

        function callSaveDeviation (isSaveAndClose, selectedWorkOrders = []) {
            if ($scope.validator.validate()) {
                showBusyIndicator();
                caseService.updateWorkOrders(selectedWorkOrders, $scope.case.operationLogId, $scope.selectedDepartment)
                .then(() => {
                    var now = new Date();
                    $scope.case.editedOn = dateUtility.toIsoString(now);

                    var authData = $rootScope.authData;
                    $scope.case.contractorId = authData.contractorId;
                    $scope.case.editedBy = authData.username;
                    $scope.case.dueDate = $scope.case.dueDateObject ? dateUtility.toIsoString($scope.case.dueDateObject) : null;
                    $scope.case.doneOn = $scope.case.doneOnObject ? dateUtility.toIsoString($scope.case.doneOnObject) : null;

                    if ($scope.case.doneOn === null) {
                        $scope.case.doneOn = dateUtility.toIsoString(now);
                    }

                    if ($scope.case.dueDateObject == 'Invalid Date') {
                        $scope.case.dueDate = new Date(1970, 0, 1);
                    }

                    $scope.case.assignedUser = $scope.users.filter(user => $scope.selectedUserIds.map(x => x.userId).includes(user.userId) && user.userId !== '00000000-0000-0000-0000-000000000000')
                                                            .map((user) => {
                                                                user.role = 'Assigned';
                                                                return user;
                                                            });

                    var registeredUser = $scope.users.filter(user => user.userId === authData.userId).map(user => user);

                    var registeredUserClone = JSON.parse(JSON.stringify(registeredUser));

                    if (registeredUserClone.length > 0) {
                        registeredUserClone[0].role = 'Registered';
                    }

                    let associatedSelectedUsers = $scope.associatedMultiselect.value();
                    var associatedUser = $scope.users.filter((user) => {
                        return associatedSelectedUsers.find(x => x == user.userId) && !$scope.case.assignedUser.find(x => x.userId == user.userId);})
                                                      .map((user) => {
                                                        user.role = 'Associated';
                                                        return user;
                                                      });

                    $scope.case.assignedUser = $scope.case.assignedUser.concat(registeredUserClone).concat(associatedUser);
                    $scope.case.departmentId = $scope.selectedDepartment;
                    $scope.case.logactionId = $scope.selectedType;
                    $scope.case.description = $scope.selectedSubtype;
                    $scope.case.severity = $scope.selectedSeverity;

                    let selectedTags = $scope.tagsMultiselect.dataItems(),tagsToUpdate = [], newCaseTags = [];

                    if (selectedTags && selectedTags.length > 0) {
                        for (let i = 0; i < selectedTags.length; ++i) {
                            let currentTag = selectedTags[i];
                            var newGuid = commonUtility.uuidv4();

                            var relatedTag = $scope.currentTags.find(t => t.caseTagId === currentTag.caseTagId);
                            if (relatedTag) {
                                tagsToUpdate.push({
                                    operationLogCaseTagId: relatedTag.operationLogCaseTagId,
                                    operationLogId: relatedTag.operationLogId,
                                    caseTagId: relatedTag.caseTagId,
                                    createdBy: $scope.authData.username,
                                    createdOn: dateUtility.toIsoString(now),
                                    status: 0
                                });
                            } else {
                                tagsToUpdate.push({
                                    operationLogCaseTagId: '00000000-0000-0000-0000-000000000000',
                                    operationLogId: '00000000-0000-0000-0000-000000000000',
                                    caseTagId: currentTag.caseTagId ? currentTag.caseTagId : newGuid,
                                    createdBy: $scope.authData.username,
                                    createdOn: dateUtility.toIsoString(now),
                                    status: 0
                                });

                                if(!currentTag.caseTagId){
                                    var caseTag = {
                                        caseTagId: newGuid,
                                        contractorId: $rootScope.authData.contractorId,
                                        tag: currentTag.tag,
                                        status: 0,
                                        createdById: $scope.authData.userId,
                                        createdOn: dateUtility.toIsoString(now)
                                    }
                                    newCaseTags.push(caseTag);
                                }
                            }
                        }
                    }

                    // Componenet equipment handling
                    $scope.case.itemId = null;
                    if($scope.equipCompSelectedIds){
                        if(!Array.isArray($scope.equipCompSelectedIds)){
                            $scope.case.itemId = $scope.equipCompSelectedIds;
                        } else {
                            $scope.case.nets = $scope.nets ? $scope.nets.filter(n => $scope.equipCompSelectedIds.includes(n.value)).map((m) => ({id: m.value})) : [];
                            $scope.case.rings = $scope.rings ? $scope.rings.filter(n => $scope.equipCompSelectedIds.includes(n.value)).map(m => ({ringId: m.value})) : [];
                            $scope.case.cages = $scope.cages ? $scope.cages.filter(n => $scope.equipCompSelectedIds.includes(n.value)).map(m => ({cageId: m.value})) : [];
                            $scope.case.lines = $scope.lines ? $scope.lines.filter(n => $scope.equipCompSelectedIds.includes(n.lineId)).map(m => ({lineId: m.lineId})) : [];
                        }
                    } else {
                        $scope.case.itemId = null;
                        $scope.case.nets = [];
                        $scope.case.rings = [];
                        $scope.case.cages = [];
                        $scope.case.lines = [];
                    }

                    switch (modalType) {
                        case formTypes.add:
                            $scope.case.workflowStatuses = [{
                                workflowStatusId: $scope.selectedStatus,
                                createdBy: authData.username,
                                createdOn: dateUtility.toIsoString(now),
                                status: 0
                            }];

                            caseService.addCase($scope.case)
                                .then((operationLogId) => {
                                    if (operationLogId) {
                                        if (tagsToUpdate && tagsToUpdate.length > 0) {
                                            tagsToUpdate.forEach(s => s.operationLogId = operationLogId);

                                            caseService.addCaseTags(newCaseTags).then(() => {
                                                caseService.updateTags(operationLogId, tagsToUpdate).then(() => { isSaveAndClose && close(true) }, close);
                                            });
                                        }

                                        $scope.case.operationLogId = operationLogId;

                                        itemTreeService.getItemsByRelatedEntityIdOrCreate($scope.contractorId, operationLogId, 'Case document').then((data) => {
                                            $scope.relatedItem = data.length ? data.length > 1
                                                ? data.find(x => x.name === 'Case document') : data[0]
                                                : [];
                                        });

                                        //
                                        $scope.isEdit = true;
                                        modalType = formTypes.edit;
                                        $scope.tabOptions.dataSource.add({ Name: $translate.instant('CASE_MODAL_TAB_WORKORDERS'), ContentUrl: 'app/case/case-workorders-tab.html' });
                                        $scope.tabOptions.dataSource.add({ Name: $translate.instant('CASE_MODAL_TAB_FILES'), ContentUrl: 'app/case/case-files-tab.html' });
                                        hideBusyIndicator();
                                    } else {
                                        //TODO: display error toast
                                    }

                                    if (isSaveAndClose) {
                                        close(true);
                                    }
                                }, (error) => {
                                    //TODO: display error toast
                                    close(false);
                                });
                            break;
                        case formTypes.edit:
                            var isDirty = $scope.caseDetails.$dirty;
                            if (isDirty) {
                                var selectedStatus = _.find($scope.deviationStatuses, { value: $scope.selectedStatus });
                                var isOpenCaseAfterEdit = selectedStatus.meaning === 'Open';
                                var params = {};
                                params.modalWidth = 400;
                                params.modalHeight = 150;

                                if($scope.assignedUserSelectionChanged && !$scope.isOpenCase && !isOpenCaseAfterEdit){
                                    confirmDialogService.open(params, $translate.instant('CASE_SET_CASE_TO_OPEN'), '').then(x => {
                                        if(x){
                                            var openStatus = $scope.deviationStatuses.find(x => x.meaning === 'Open');
                                            $scope.case.workflowStatuses = [];
                                            $scope.case.workflowStatuses.push({
                                                createdBy: authData.username,
                                                createdOn: dateUtility.toIsoString(now),
                                                workflowStatusId: openStatus ? openStatus.value : 0,
                                                status: 0
                                            });
                                        }else{
                                            $scope.case.workflowStatuses = [{
                                                workflowStatusId: $scope.selectedStatus,
                                                createdBy: authData.username,
                                                createdOn: dateUtility.toIsoString(now),
                                                status: 0
                                            }];
                                        }
                                        editCase($scope.case, tagsToUpdate, newCaseTags, isSaveAndClose);
                                    })
                                } else {
                                    $scope.case.workflowStatuses = [{
                                        workflowStatusId: $scope.selectedStatus,
                                        createdBy: authData.username,
                                        createdOn: dateUtility.toIsoString(now),
                                        status: 0
                                    }];
                                    editCase($scope.case, tagsToUpdate, newCaseTags, isSaveAndClose)
                                }
                            }
                            else {
                                hideBusyIndicator();
                                if (isSaveAndClose) {
                                    close(false);
                                }
                            }
                            break;
                        default:
                            break;

                    }
                    $scope.relatedWorkOrdersGridOptions.dataSource.read();
                })
                .finally(() => $rootScope.$broadcast("hideAllBusyIndicator"));
            }
        }

        function editCase(deviation, tagsToUpdate, newCaseTags, isSaveAndClose){
            var handleComponents = Array.isArray($scope.equipCompSelectedIds);
            caseService.updateCase(deviation, handleComponents).then(response => {
                if (tagsToUpdate) {
                    tagsToUpdate.forEach(s => s.operationLogId = deviation.operationLogId);
                    caseService.addCaseTags(newCaseTags).then(function(tags) {
                        caseService.updateTags(deviation.operationLogId, tagsToUpdate).then(() => {
                            hideBusyIndicator();
                            if (isSaveAndClose) {
                                close(true);
                            }
                        }, close);
                    });
                } else {
                    hideBusyIndicator();

                    if (isSaveAndClose) {
                        close(true);
                    }
                }
            }, close);
        }

        function close(isSaveAndClose) {
            if (isSaveAndClose){
                $rootScope.$broadcast("saveAndClose");
            }
            $windowInstance.close();
        }

        function cancel() {
            $windowInstance.close(false);
        }

        $scope.setDueDate = function() {
            if (modalType === formTypes.add) {
                var doneOnDate = new Date($scope.case.doneOnObject);
                if ($scope.case.doneOnObject) {
                    var defaultDueDate = new Date(doneOnDate.setDate(doneOnDate.getDate() + 28));
                    $scope.case.dueDateObject = defaultDueDate;
                }
            }
        }

        function addNewWorkOrder() {
            const locationNumber = getSelectedDepartmentLocationNumber($scope.selectedDepartment);
            const isLocation = locationNumber !== null;
            let workOrderLog = {
                parentId: $scope.case.operationLogId,
                locationNumber : locationNumber,
                selectedDepartment: $scope.selectedDepartment,
            };

            if (isLocation){
                if (Array.isArray($scope.equipCompSelectedIds)){
                    workOrderLog.nets = $scope.nets ? $scope.nets.filter(n => $scope.equipCompSelectedIds.includes(n.value)).map((m) => ({id: m.value})) : [];
                    workOrderLog.rings = $scope.rings ? $scope.rings.filter(n => $scope.equipCompSelectedIds.includes(n.value)).map(m => ({ringId: m.value})) : [];
                    workOrderLog.lines = $scope.lines ? $scope.lines.filter(n => $scope.equipCompSelectedIds.includes(n.lineId)).map(m => ({lineId: m.lineId})) : [];
                }
            }
            else
            {
                if ($scope.equipCompSelectedIds){
                    workOrderLog.equipments = [{"equipmentId": $scope.equipCompSelectedIds}];
                }
            }
            caseData.currentTags = $scope.currentTags;
            workOrderDetailsModalService.createWorkOrderDetailsResolve(workOrderLog)
                .then(function (resolve) {
                    var modal = workOrderDetailsModalService.openWorkOrderDetailsModal(resolve, caseData);
                    modal.result.then(function () {
                        $scope.relatedWorkOrdersGridOptions.dataSource.read();
                    });
                });
        }

        function uploadFile() {
            $('#case-file-input').click();
        }

        function deleteRelatedMedia(e) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 550
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: () => {
                        return $translate.instant('CASE_MODAL_DELETE_FILE_MODAL_MSG', null, null, langId);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then((response => {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'caseDeleteFileBusyIndicator',
                        destination: '#related-media-grid',
                        overlay: true,
                    });

                    var dataItem = $("#related-media-grid").data("kendoGrid").dataItem(angular.element(e.currentTarget).closest("tr"));
                    itemTreeService.deleteItemFileWebApi(dataItem.id).then( () => {
                            $scope.relatedMediaGridOptions.dataSource.remove(dataItem);
                            $rootScope.$broadcast('hideBusyIndicator', 'caseDeleteFileBusyIndicator');
                    });
                }
            }));

        }

        function isReadOnlyPermission(){
            if($scope.hasPermission('DeviationLogAdmin') ){
                return false;
            }

            if($scope.hasPermission('DeviationLogEdit') || $scope.hasPermission('DeviationLogEditOnlyOwn')){
                if(($scope.hasPermission('DeviationLogEditOnlyOwn') && caseData && caseData.isOwnDeviation)
                || ($scope.hasPermission('DeviationLogEdit') && !$scope.hasPermission('DeviationLogEditOnlyOwn'))){
                   return false;
                } else {
                    return true;
                }
            } else {
                return true
            }
        }

        function handleFiles() {
            if ($scope.fileArray[0]) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'relatedMediaIndicator',
                    destination: '#related-media-grid'
                });
                let file = $scope.fileArray[0];

                if (
                    file &&
                    file.type == 'image/jpeg'
                ) {
                    mediaService.asyncEncodeImageFile(file)
                        .then((base64FileContent) =>
                            mediaService.uploadFileWebApi(
                                file.name,
                                base64FileContent
                            )
                        )
                        .then(newMediaId => {
                            fileHandlingService.addItemFile(file.name, newMediaId, $scope.relatedItem.id, $rootScope.authData.userId)
                            .then(function (response) {
                                itemTreeService.getItemsByRelatedEntityIdOrCreate($scope.contractorId, caseData ? caseData.operationLogId : $scope.case.operationLogId, 'Case document').then(x => {
                                    $scope.relatedItem = x.length ?
                                        x.length > 1 ? x.find(x => x.name === 'Case document') : x[0]
                                        : [];
                                    $scope.relatedMediaGridOptions.dataSource.read();
                                    $rootScope.$broadcast('hideBusyIndicator', 'relatedMediaIndicator');
                                }), function (error) {
                                    $rootScope.$broadcast('hideBusyIndicator', 'relatedMediaIndicator');
                                }
                            }, function (error) {
                                $rootScope.$broadcast('hideBusyIndicator', 'relatedMediaIndicator');
                            });
                            file = null;
                        })
                        .catch((error) => console.error(error))
                        .finally(() => {
                            $rootScope.$broadcast('hideBusyIndicator', 'relatedMediaIndicator');
                        });
                } else {
                    mediaService.asyncReadGenericFile(file)
                        .then((base64FileContent) =>
                            mediaService.uploadFileWebApi(
                                file.name,
                                base64FileContent
                            )
                        )
                        .then(newMediaId => {
                            fileHandlingService.addItemFile(file.name, newMediaId, $scope.relatedItem.id, $rootScope.authData.userId)
                            .then(function (response) {
                                itemTreeService.getItemsByRelatedEntityIdOrCreate($scope.contractorId, caseData ? caseData.operationLogId : $scope.case.operationLogId, 'Case document').then(x => {
                                    $scope.relatedItem = x.length ?
                                        x.length > 1 ? x.find(x => x.name === 'Case document') : x[0]
                                        : [];
                                    $scope.relatedMediaGridOptions.dataSource.read();
                                    $rootScope.$broadcast('hideBusyIndicator', 'relatedMediaIndicator');
                                }), function (error) {
                                    $rootScope.$broadcast('hideBusyIndicator', 'relatedMediaIndicator');
                                }
                            }, function (error) {
                                $rootScope.$broadcast('hideBusyIndicator', 'relatedMediaIndicator');
                            });
                            file = null;
                        })
                        .catch((error) => console.error(error))
                        .finally(() => {
                            $rootScope.$broadcast('hideBusyIndicator', 'relatedMediaIndicator');
                        });
                }
            }
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'caseDetailsControllerModal',
                destination: '.k-window',
                overlay: true
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'caseDetailsControllerModal');
        }

        function revalidateAll() {
            if ($scope.okClickedOnce) {
                $scope.$applyAsync(function () {
                    if ($scope.validator) {
                        $scope.validator.validate();
                    }
                });
            }
        }

        function getFilteredUsers() {
            var notAssignableUsers = $scope.users.filter(x => x.permissions != null && x.permissions.includes('DeviationLogNotAssignable'));

            var filteredUsers = $scope.users.filter(function (item) {
                return notAssignableUsers.find(function (item2) {
                    return item.userName == item2.userName;
                }) == undefined;
            });
            return $scope.users.filter(u => !u.userName).concat(filteredUsers);
        }

        function getFilteredAssociatedUsers() {
            var roleFilter = [];
            if ($scope.selectedUserIds.length) {
                roleFilter = $scope.users.filter(u => u.userName && !$scope.selectedUserIds.map(x => x.userId).includes(u.userId));
            }
            else {
                roleFilter = $scope.users.filter(u => u.userName);
            }
            return roleFilter;
        }

        function exportToPDF() {
            var printCaseUrl = `${window.location.origin}/case-report?operationLogId=${$scope.case.operationLogId}`;
            window.open(printCaseUrl, '_blank');
        }

        const loadEquipmentDropDownList = (loadByDepartmentId, locationNumber) => {
            let requests = {};
            requests.getItemsTreeByRelatedEntityId = itemTreeService.getItemsTreeByRelatedEntityId($scope.authData.contractorId, loadByDepartmentId);
            if (locationNumber){
                requests.nets = componentService.getNetsByLocationNumberAsDropDown(locationNumber, ids.net);
                requests.rings = componentService.getRingsByLocationNumberAsDropDown(locationNumber, ids.ring);
                requests.lines = componentService.getLinesByLocationNumberAsDropDown(locationNumber, ids.line);
                requests.cages = componentService.getCagesByLocationNumberAsDropDown(locationNumber, ids.cage);
            }

            return $q.all(requests).then((data) => {
                $scope.equipmentTreeData = data.getItemsTreeByRelatedEntityId;
                if(locationNumber){
                    $scope.componentTreeData = createComponentTree(data.nets, data.rings, data.lines, data.cages);
                }

                if(modalType == formTypes.edit && $scope.initEditedModal){
                    var selectedIds = [];
                    if ($scope.case && ($scope.case.rings || $scope.case.nets || $scope.case.lines || $scope.case.cages)) {
                        selectedIds = [...$scope.case.nets.map(x => x.id), ...$scope.case.rings.map(x => x.ringId), ...$scope.case.lines.map(x => x.lineId), ...$scope.case.cages.map(x => x.cageId)];
                    }
                    $scope.equipCompSelectedIds = caseData.itemId || selectedIds || [];
                    $scope.initEditedModal = false;
                }
            })
        }

        function createComponentTree(nets, rings, lines, cages){
            // Main folder
            $scope.nets = [];
            $scope.rings = [];
            $scope.cages = [];
            $scope.lines = [];
            var netFolder = {text: 'Nets', value: null, disabled: 'disabled', items: []};
            var ringFolder = {text: 'Rings', value: null, disabled: 'disabled', items: []};
            var cageFolder = {text: 'Cages', value: null, disabled: 'disabled', items: []};
            var lineFolder = {text: 'Lines', value: null, disabled: 'disabled', items: []};
            var tree = [netFolder, ringFolder, cageFolder, lineFolder];
            nets.forEach(comp => {
                netFolder.items.push({
                    text: comp.text,
                    value: comp.value,
                    items: []
                });
                $scope.nets.push(comp);
            });

            rings.forEach(comp => {
                ringFolder.items.push({
                    text: comp.text,
                    value: comp.value,
                    items: []
                });
                $scope.rings.push(comp);
            });

            lines.forEach(comp => {
                lineFolder.items.push({
                    text: comp.description,
                    value: comp.lineId,
                    items: []
                });
                $scope.lines.push(comp);
            });

            cages.forEach(comp => {
                cageFolder.items.push({
                    text: comp.text,
                    value: comp.value,
                    items: []
                });
                $scope.cages.push(comp);
            });
            return tree;
        };

        function loadLogActionAndSubType(){
            caseService.getCaseTypesByContractorId($rootScope.authData.contractorId, $scope.selectedDepartment).then(function(caseTypes){
                $scope.caseTypes = caseTypes.filter(l => l.status === 0);
                $scope.selectedCaseType = $scope.caseTypes.find(type => type.logActionId === $scope.selectedType)
                if ($scope.selectedCaseType) {
                    if ($scope.selectedCaseType.list && $scope.selectedCaseType.list.listElements && $scope.selectedCaseType.list.listElements.length > 0) {
                        $scope.subtypes = $scope.selectedCaseType.list.listElements.map(elem => elem.displayName);
                    }
                }
                $scope.caseTypeDropDownOptions.dataSource.read();

                if(caseData){
                    $scope.selectedType = caseData.logActionId;
                    $scope.selectedCaseType = $scope.caseTypes.find(type => type.logActionId === $scope.selectedType);
                    if($scope.selectedCaseType)
                    {
                        if($scope.selectedCaseType.list && $scope.selectedCaseType.list.listElements && $scope.selectedCaseType.list.listElements.length > 0)
                        {
                            $scope.subtypes =  $scope.selectedCaseType.list.listElements.map(elem => elem.displayName);
                            $scope.caseSubtypeDropdownList.dataSource.read();
                            $scope.caseSubtypeDropdownList.refresh();
                            $scope.selectedSubtype = $scope.subtypes.filter(function (type) {
                                return type === caseData.description;
                            })[0];
                        }
                    }
                } else {
                    $scope.selectedType = $scope.defaultLogActions.defaultDeviationLogActionId;
                    $scope.selectedCaseType = $scope.caseTypes.find(type => type.logActionId === $scope.selectedType);
                    if($scope.selectedCaseType)
                    {
                        if($scope.selectedCaseType.list && $scope.selectedCaseType.list.listElements && $scope.selectedCaseType.list.listElements.length > 0)
                        {
                            $scope.subtypes =  $scope.selectedCaseType.list.listElements.map(elem => elem.displayName);
                            $scope.caseSubtypeDropdownList.dataSource.read();
                            $scope.caseSubtypeDropdownList.refresh();
                            $scope.selectedSubtype = $scope.subtypes[0];
                        }
                    }
                }
            });
        }

        const getSelectedDepartmentLocationNumber = () => {
            const department = $scope.departments.find(x => x.departmentId === $scope.selectedDepartment);
            return department ? department.locationNumber || null : null;
        }
    }
})();
