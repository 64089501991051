(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('entityHistory', entityHistory);

    function entityHistory() {
        return {
            controller: ['$rootScope', '$scope', '$translate', 'entityHistoryService', 'entityHistoryPropertyDictionaryKeys', 'entityHistoryValueDictionaryKeys', entityHistory],
            replace: true,
            restrict: 'E',
            templateUrl: 'app/shared/directives/entity-history.html',
            scope: {
                entityIds: '=entityIds',
                logName: '=logName'
            },
        };

        function entityHistory($rootScope, $scope, $translate, entityHistoryService, entityHistoryPropertyDictionaryKeys, entityHistoryValueDictionaryKeys) {
            initDirective();
            function initDirective() {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'entityHistoryBusyIndicator',
                    destination: '#entity-history-container',
                    overlay: true
                });

                entityHistoryService.get($scope.entityIds)
                    .then((result) => {
                        $scope.entityHistories = _.orderBy(result, ['timestamp'], ['desc']);;
                    })
                    .finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'entityHistoryBusyIndicator');
                    });
            }

            $scope.getTextFromChangeType = (type) => type === 0 ? $translate.instant('ENTITY_HISTORY_CHANGE_TYPE_ADD') : $translate.instant('ENTITY_HISTORY_CHANGE_TYPE_MODIFIED');

            $scope.getPropertyLabel = (property) => entityHistoryPropertyDictionaryKeys.hasOwnProperty(property) ? entityHistoryPropertyDictionaryKeys[property] : property;
            $scope.getValueLabel = (value, type) => {
                if (type == 'new' && (value == '' || value == null || value == undefined)){
                    return $translate.instant('ENTITY_HISTORY_NO_VALUE')
                } else {
                    return entityHistoryValueDictionaryKeys.hasOwnProperty(value) ? entityHistoryValueDictionaryKeys[value] : value;
                }
            }

            $scope.setSelectedEntity = (entity) => {
                let changesObj = entity.entityChanges;

                // format properties
                Object.keys(changesObj).forEach((key) => {
                    // date format
                    if (dateIsValid(changesObj[key].old) || dateIsValid(changesObj[key].new)) {
                        changesObj[key] = {
                            old: formatDate(changesObj[key].old),
                            new: formatDate(changesObj[key].new)
                        };
                    }

                    // removing leftover foreign key properties
                    if (isGuidValue(changesObj[key])) {
                        delete changesObj[key];
                    }
                });

                entity.entityChanges = changesObj;

                $scope.selectedEntity = entity;
            };

            function formatDate(date) {
                return date ? moment(date).format('DD.MM.YYYY HH:mm') : null;
            }

            function dateIsValid(date) {
                const formats = [
                    "YYYY-MM-DDTHH:mm:ss",
                    "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ"
                ];

                return date && moment(date, formats, true).isValid();
            }

            function isGuidValue(field) {
                const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

                if (guidRegex.test(field.old) || guidRegex.test(field.new)) {
                    return true;
                }

                return false;
            }
        }
    }
})();
