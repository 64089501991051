(function() {
    'use strict';

    angular.module('NaviaqWebApp').factory('restService', restService);

    restService.$inject = ['$q', '$http', 'serviceUrls'];

    function restService($q, $http, serviceUrls) {
        const restReportBaseApiUrl = `${serviceUrls.webApiBaseUrl}/restReport`;

        return {
            getRestReportOverview: getRestReportOverview,
            approveRestLogs: approveRestLogs,
        };

        function getRestReportOverview(filter) {
            var deferred = $q.defer();

            $http.post(`${restReportBaseApiUrl}/restReportOverview`, filter).then(function(response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function approveRestLogs(filter) {
            var deferred = $q.defer();

            $http.post(`${restReportBaseApiUrl}/approveRestLogs`, filter).then(function(response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
