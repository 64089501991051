(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('customersUnitAgreementModalController', customersUnitAgreementModalController);

    customersUnitAgreementModalController.$inject = [
        '$translate',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'customerUnitAgreement',
        'currentFormType',
        'formTypes',
        'customerService',
        'htmlElementService',
        'customerId',
        'units',
        'dateUtility'
    ];

    function customersUnitAgreementModalController(
        $translate,
        $rootScope,
        $scope,
        $windowInstance,
        customerUnitAgreement,
        currentFormType,
        formTypes,
        customerService,
        htmlElementService,
        customerId,
        units,
        dateUtility
    ) {
        $scope.customerUnitAgreement = {};
        $scope.close = close;
        $scope.validateForm = validateForm;
        $scope.selectedUnit = null;
        $scope.unitOptions = {
            dataSource: new kendo.data.DataSource({
                data: units,
                sort: { field: 'description', dir: 'asc' }
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            optionLabel: $translate.instant('ADMIN_CUSTOMERS_SELECT_UNIT')
        };

        initController();

        function initController() {
            htmlElementService.checkIfElementExists("vessel-form")
                .then(function (result) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'customersUnitAgreementModal',
                        destination: '#vessel-form',
                        overlay: true
                    });
                    if (currentFormType === formTypes.edit && customerUnitAgreement) {
                        $scope.customerUnitAgreement = customerUnitAgreement;
                    }

                    $scope.selectedUnit = units.find(function (unit) {
                        return unit.id == $scope.customerUnitAgreement.unitId;
                    });
                });
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'customersUnitAgreementModal',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        $scope.customerUnitAgreement.customerId = customerId
                        $scope.customerUnitAgreement.unitId = $scope.selectedUnit.id
                        $scope.customerUnitAgreement.unit = null;
                        $scope.customerUnitAgreement.fromDate = dateUtility.toIsoString($scope.customerUnitAgreement.fromDate);
                        $scope.customerUnitAgreement.toDate = dateUtility.toIsoString($scope.customerUnitAgreement.toDate);
                        $scope.customerUnitAgreement.comment = $scope.customerUnitAgreement.comment;
                        $scope.customerUnitAgreement.lastCommentedById = $rootScope.authData.userId;
                        $scope.customerUnitAgreement.lastCommentedOn = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
                        customerService.addCustomerUnitAgreement($scope.customerUnitAgreement)
                        .then(customerUnitAgreement => close($scope.customerUnitAgreement))
                        .catch(() => close());
                        break;
                        case formTypes.edit:
                            $scope.customerUnitAgreement.unitId = $scope.selectedUnit.id
                            $scope.customerUnitAgreement.unit = null;
                            $scope.customerUnitAgreement.fromDate = dateUtility.toIsoString($scope.customerUnitAgreement.fromDate);
                            $scope.customerUnitAgreement.toDate = dateUtility.toIsoString($scope.customerUnitAgreement.toDate);
                            $scope.customerUnitAgreement.comment = $scope.customerUnitAgreement.comment;
                            $scope.customerUnitAgreement.lastCommentedById = $rootScope.authData.userId;
                            $scope.customerUnitAgreement.lastCommentedOn = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
                        customerService.updateCustomerUnitAgreement($scope.customerUnitAgreement)
                            .then(customerUnitAgreement => close($scope.customerUnitAgreement))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'customersUnitAgreementModal');
            $windowInstance.close(param);
        }
    }
})();
