(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('OrderReportController', OrderReportController);

    OrderReportController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$stateParams',
        '$rootScope',
        'externalReportType',
        'itemtreeKeys',
        'locationService',
        'mediaService',
        'queryStringService',
        'confirmDialogService',
        'dateUtility',
        '$kWindow',
        '$translate',
        'anonymousReportService',
        'mapUtility',
        'kendoUtility'
    ];

    function OrderReportController(
        $q,
        $scope,
        $state,
        $stateParams,
        $rootScope,
        externalReportType,
        itemtreeKeys,
        locationService,
        mediaService,
        queryStringService,
        confirmDialogService,
        dateUtility,
        $kWindow,
        $translate,
        anonymousReportService,
        mapUtility,
        kendoUtility
    ) {
        $scope.contractorId = $stateParams.ContractorId;
        $scope.orderId = $stateParams.OrderId;
        $scope.newDocumentMedia = null;
        $scope.showContractorReports = false;
        $scope.showFinalReport = false;
        $scope.showMooringReport = false;
        $scope.showOrderConfirmation = false;
        $scope.showOrderWorkReport = false;
        $scope.showOperationLogsReport = false;
        $scope.finalReportName = '';
        $scope.finalReportUrl = '';
        $scope.reportFolder = '';
        $scope.locationNumber = null;
        $scope.externalReportItems = [];
        $scope.orderLoadRequest = orderLoadRequest;
        $scope.externalReportRequest = externalReportRequest;
        $scope.userId = '';
        $scope.showDelicingKombiReport = false;
        $scope.showDelicingH202Report = false;

        $scope.toLocationOrders = toLocationOrders;
        $scope.initController = initController;
        $scope.showTracking = showTracking;
        $scope.hasServiceReport = hasServiceReport;
        $scope.videoWarningHasToBeDisplayed = false;
        $scope.videos = [];
        $scope.imageFiles= [];

        $scope.relatedEntityId = null;
        $scope.orderFiles = [];
        $scope.orderVideos = [];
        $scope.videoUploadQueue = [];
        $scope.videoUploadIsUnderProcess = false;
        $scope.video = null;
        $scope.relatedDocumentsAndVideos = [];
        $scope.filterDropDowns = {
            componentNumber: null,
            cageNumber: null,
            serviceFormName: null,
            fileName: null,
        };

        $scope.isAuth = $rootScope.authData.isAuth;

        let order = null;

        $scope.openInformationModal = openInformationModal;

        $scope.fileUploadOptions = {
            multiple: false,
            localization: {
                select: 'Legg til dokument...',
            },
        };

        $scope.videoUploadOptions = {
            multiple: false,
        };

        var mediaGridDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.relatedDocumentsAndVideos)
            },
            batch: false,
            schema: {
                model: {
                    id: 'fileName',
                    fields: {
                        createdOn: { editable: false, type: "date" },
                        componentNumber: { editable: false, type: "object" },
                        cageNumber: { editable: false, type: "object" },
                        serviceFormName: { editable: false, type: "object" },
                        fileName: { editable: false, type: "object" },
                    }
                }
            },
        });

        const mediaGridOptions = langId => ({
            dataSource: mediaGridDataSource,
            selectable: false,
            filterable: true,
            filterMenuInit: kendoUtility.onFilterMenuInit,
            columns: [
                {
                    title: $translate.instant('REPORT_MEDIA_GRID_DATE', null, null, langId),
                    field: 'createdOn',
                    template: function(dataItem){
                        return dateUtility.formatDateTimeUntilMinute(dataItem.createdOn);
                    }
                },
                {
                    title: $translate.instant('REPORT_MEDIA_GRID_COMPONENT', null, null, langId),
                    field: 'componentNumber',
                    filterable:{ui: (element) => createDropDownFilter(element, 'componentNumber')},
                },
                {
                    title: $translate.instant('REPORT_MEDIA_GRID_CAGE', null, null, langId),
                    field: 'cageNumber',
                    filterable:{ui: (element) => createDropDownFilter(element, 'cageNumber')},
                },
                {
                    title: $translate.instant('REPORT_MEDIA_GRID_REPORT', null, null, langId),
                    field: 'serviceFormName',
                    filterable:{ui: (element) => createDropDownFilter(element, 'serviceFormName')},
                },
                {
                    title: $translate.instant('REPORT_MEDIA_GRID_LINK_TO_MEDIA', null, null, langId),
                    field: 'fileName',
                    template: function(dataItem){
                        if(dataItem.fileType === 0){
                            return `<a href="${dataItem.file.url}" target="_blank">${dataItem.file.fileName}</a>`;
                        } else {
                            return `<a ng-click="handleVideo(dataItem)"  href="" target="_blank">${dataItem.file.fileName}</a>`;
                        }
                    },
                    filterable:{ui: (element) => createDropDownFilter(element, 'fileName')},
                },
                {
                    title: $translate.instant('REPORT_MEDIA_GRID_MEDIA_TYPE', null, null, langId),
                    field: 'fileType',
                    template: function(dataItem){
                        if(dataItem.fileType === 0){
                            return `<i class="fas fa-file-alt"></i> ${$translate.instant('COMPONENT_ATTACHMENT')}`;
                        } else if(dataItem.fileType === 1) {
                            return `<i class="fas fa-file-video"></i> ${$translate.instant('COMPONENT_VIDEO')}`;
                        } else {
                            return `<i class="fas fa-link"></i> ${$translate.instant('COMPONENT_LINK')}`;
                        }
                    },
                    filterable: false,
                }
            ],
            editable: false
        });
        $scope.mediaGridOptions = mediaGridOptions($translate.use());

        function createDropDownFilter(element, field){
            element.removeAttr("data-bind");
            element.kendoMultiSelect({
                dataSource: new kendo.data.DataSource({ data: $scope.filterDropDowns[field]}),
                dataTextField: "text",
                dataValueField: "value",
                change: function(e) {
                    var values = this.value();
                    var activeFilters = [];
                    $.each(values, function(i, v) {
                        activeFilters.push({field: field, operator: "equal", value: v });
                    });
                    kendoUtility.createFilterOnGrid(mediaGridDataSource, activeFilters, field);
                }
            });
        }

        function getMediaFilters(){
            const componentNumbers = _.uniq($scope.relatedDocumentsAndVideos.map(w => w.componentNumber));
            $scope.filterDropDowns.componentNumber = [];
            componentNumbers.forEach(number => number && $scope.filterDropDowns.componentNumber.push({value: number, text: number}));

            const cageNumbers = _.uniq($scope.relatedDocumentsAndVideos.map(w => w.cageNumber));
            $scope.filterDropDowns.cageNumber = [];
            cageNumbers.forEach(number => number && $scope.filterDropDowns.cageNumber.push({value: number, text: number}));

            const serviceFormNames = _.uniq($scope.relatedDocumentsAndVideos.map(w => w.serviceFormName));
            $scope.filterDropDowns.serviceFormName = [];
            serviceFormNames.forEach(name => name && $scope.filterDropDowns.serviceFormName.push({value: name, text: name}));

            const fileNames = _.uniq($scope.relatedDocumentsAndVideos.map(w => w.fileName));
            $scope.filterDropDowns.fileName = [];
            fileNames.forEach(name => name && $scope.filterDropDowns.fileName.push({value: name, text: name}));
        }

        $scope.initController();

        function initController() {
            window.onbeforeunload = function(e) {
                if($scope.videoUploadQueue.length){
                  return "";
                }
             };

            if ($scope.contractorId && $scope.orderId) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'rightPane',
                    destination: '#right-pane',
                });

                $scope.userId = queryStringService.getParameterByName('UserId');

                let requests = {
                    orderReportContent: anonymousReportService.getOrderReportContent($scope.orderId)
                };

                $q.all(requests).then((result) => {
                    let content  = result.orderReportContent;
                    order = content.order;
                    $scope.contractorId = order.contractorId ? order.contractorId : $scope.contractorId;
                    $scope.videoWarningHasToBeDisplayed = (order.orderStatus === 2 || order.orderStatus === 3) && !order.hasVideo ? true : false;

                    $scope.reportDisplayInformation = content.displayInformation;
                    orderLoadRequest();
                    externalReportRequest(content.orderItems);
                    getFiles(content.orderRelatedFiles);

                    $scope.arrivalReports = _.orderBy(content.arrivalReports, 'sentTime', 'asc');

                    $scope.relatedEntityId = content.orderItems && content.orderItems.length
                        && content.orderItems[0] ? content.orderItems[0].id : null;

                    if(content.delicingReport.hasKombiReport){
                        $scope.showDelicingKombiReport = true;
                    }
                    if(content.delicingReport.hasH202Report){
                        $scope.showDelicingH202Report = true;
                    }

                    if($rootScope.isSmallDevice){
                        $rootScope.$broadcast('setSplitterSize', {
                            left: '0%',
                            right: '100%'
                        });
                     } else {
                        $rootScope.$broadcast('setSplitterSize', {
                            left: '60%',
                            right: '40%',
                        });
                     }

                    $rootScope.$broadcast('hideBusyIndicator', 'rightPane');
                });
            }
        }

        $scope.delicingKombiReportClicked = function(){
            $rootScope.$broadcast('delicingKombiReportRequested');
        }

        $scope.delicingH202ReportClicked = function(){
            $rootScope.$broadcast('delicingH202ReportRequested');
        }

        function externalReportRequest(items) {
            var reportItems = _.filter(items, function (item) {
                return !item.isDeleted && item.category && item.category.keyName === itemtreeKeys.Report;
            });

            for (var i = 0; i < reportItems.length; ++i) {
                reportItems[i].reportTypeName = getReportNameByType(reportItems[i].itemType);

                if (reportItems[i].files && reportItems[i].files.length > 0) {
                    var fileName = reportItems[i].files[0].fileName;
                    reportItems[i].name = fileName.substr(0, fileName.length - 4);
                    reportItems[i].downloadUrl = mediaService.createMediaUrl(reportItems[i].files[0].mediaId);
                }
            }
            $scope.externalReportItems = reportItems;
        }

        function orderLoadRequest() {
            $scope.orderContractor = order.contractor;

            $scope.showOrderWorkReport = $scope.reportDisplayInformation.showOrderWorkReport;
            $scope.showOperationLogsReport = $scope.reportDisplayInformation.showOperationLogsReport;

            if ($scope.reportDisplayInformation.showFinalReport && order.orderProcess) {
                $scope.showFinalReport = true;
                $scope.finalReportName = order.orderProcess.name;
            }
            if (order.orderProcess && order.orderProcess.showMooringReport) {
                $scope.showMooringReport = true;
                $scope.mooringReportName = `${order.orderProcess.name} - Mooring`;
            }

            if($scope.reportDisplayInformation.showOrderConfirmation){
                $scope.showOrderConfirmation = true;
                $scope.orderConfirmationName = $translate.instant('ORDER_CONFIRMATION_TITLE');
                $scope.orderConfirmationUrl = `orderConfirmation?OrderId=${$scope.orderId}`
            }

            $scope.orderNumber = order.externalOrderId;
            $scope.customerName = order.customer ? order.customer.name : '';

            if (order.orderProcess) {
                $scope.processName = order.orderProcess.name;
            } else if(order.orderProcess) {
                $scope.processName = order.orderProcess.name;
                $scope.finalReportName = order.orderProcess.name;
            }

            var reportFolder = `/${$scope.reportDisplayInformation.reportFolder}`;
            $scope.finalReportUrl = `final-report${reportFolder}?OrderId=${$scope.orderId}&UserId=${$scope.userId}`
            $scope.mooringReportUrl = `mooring-report${reportFolder}?OrderId=${$scope.orderId}&UserId=${$scope.userId}`

            $scope.departmentNames = order.departmentNames;
            $scope.contactPersonNames =  order.contactPersonNames;

            $scope.projectLeaderName = order.projectManagerName;
            $scope.description = order.orderDescription;

            if (order.locationNumber) {
                $scope.location = order.locationName;
                if(!locationService.isDefaultLocation(order.locationNumber)){
                    var coordinates = mapUtility.convertToUTM33NFromWGS84(order.location.y, order.location.x)
                    $rootScope.$broadcast(
                        'zoomToLocation',
                        {
                            x: coordinates.latitude,
                            y: coordinates.longitude,
                        },
                        {
                            id: order.location.locationNumber,
                            type: 'Location',
                        }
                    );
                }
            } else {
                $scope.location = '';
            }

            $scope.locationNumber = order.locationNumber;
            $scope.startDate = dateUtility.formatDate(order.orderDate);

            if (order.expireDate) {
                $scope.endDate = dateUtility.formatDate(order.expireDate);
            }
        }

        function toLocationOrders() {
            if ($scope.locationNumber) {
                $state.go('main.locationOrders', {
                    LocationNumber: $scope.locationNumber,
                });
            }
        }

        function getParameterByName(name, url) {
            if (!url) {
                url = window.location.href;
            }
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        function getReportNameByType(reportType) {
            switch (reportType) {
                case externalReportType.AquaScan:
                    return 'Aquascan Tellerapport';
                case externalReportType.MmcOzone:
                    return 'Ozoneringsrapport';
                case externalReportType.MmcPipe:
                    return 'Rørvaskrapport';
                case externalReportType.MmcWell:
                    return 'Vaskerapport';
                case externalReportType.WaterQuality:
                    return 'Vannkvalitetsrapport';
                case externalReportType.HidrogenPeroxide:
                    return 'Hydrogenperoxid logg';
                default:
                    return 'Rapport';
            }
        }

        function showTracking() {
            $state.go('main.showTracking', {
                OrderId: $scope.orderId,
                ContractorId: $scope.contractorId,
            });
        }

        function hasServiceReport() {
            return $scope.orderContractor && $scope.orderContractor.isServiceStation;
        }

        function getFiles(files){
            $scope.relatedDocumentsAndVideos = [];
            $scope.orderFiles = [];

            // related order - images
            files.relatedItems.forEach(function (item) {
                item.files.forEach(function (itemFile) {
                    if (itemFile.naviaqMediaId === null) {
                        itemFile.displayText = "".concat(itemFile.fileName);
                        itemFile.url = mediaService.createMediaUrlMediaBank(itemFile.mediaId);
                        $scope.orderFiles.push(itemFile);
                    }
                });
            });
            files.orderDocuments.forEach(function (file) {
                file.displayText = "".concat(file.filename);
                file.url = mediaService.createMediaUrlMediaBank(file.mediaId);
                $scope.orderFiles.push(file);
            });

            // related order's components - images
            $scope.relatedDocumentsAndVideos = files.relatedComponentItems;

            // related order's service forms - images / videos
            $scope.relatedDocumentsAndVideos = $scope.relatedDocumentsAndVideos.concat(files.relatedServiceFormItems);
            $scope.relatedDocumentsAndVideos = $scope.relatedDocumentsAndVideos.concat(files.relatedServiceFormVideos);

            // releated order - videos
            files.orderVideos.forEach(video => {
                video.displayText = video.url.includes("blob.core.windows.net") ? video.file.fileName : video.url
            });
            $scope.orderVideos = files.orderVideos;

            // related order's components - videos
            $scope.relatedDocumentsAndVideos = $scope.relatedDocumentsAndVideos.concat(files.relatedComponentVideos);
            extendRelatedDocumentsAndVideos($scope.relatedDocumentsAndVideos);
            $scope.relatedDocumentsAndVideos = _.orderBy($scope.relatedDocumentsAndVideos, 'componentNumber', 'asc');
            $scope.mediaGridOptions.dataSource.read();
            getMediaFilters();
        }

        function extendRelatedDocumentsAndVideos(items){
            items.forEach(item => {
                item.createdOn = item.file.createdOn;
                item.fileName = item.file.fileName;
                if(item.fileType === 0){
                    item.file.url = mediaService.createMediaUrlMediaBank(item.file.mediaId);
                }
            });
        }

        $scope.handleVideo = function(video){
            if(!video.url.includes("blob.core.windows.net")){
                window.open(video.url, '_blank')
            }else{
                confirmDialogService.openVideoDownloadWarningModal(video.sizeMb).then(function (response) {
                    if (response) {
                        anonymousReportService.downloadOrderRelatedVideo(video).then(function (url) {
                            window.open(url, '_blank');
                        }).catch(function (error) {
                            if (error.status === 400) {
                                if (error.data === "DELETED") {
                                    const title = $translate.instant('DOWNLOAD_TITLE');
                                    const content = $translate.instant('DOWNLOAD_ARCHIVE_FILE_PROCESS_DELETED');
                                    $rootScope.openAlertModal = true;
                                    confirmDialogService.openWarningDialog(content, title).finally(() => {
                                        $rootScope.openAlertModal = false;
                                    });
                                }
                                if (error.data === "PENDING") {
                                    const title = $translate.instant('DOWNLOAD_TITLE');
                                    const content = $translate.instant('DOWNLOAD_ARCHIVE_FILE_PROCESS_STARTED');
                                    $rootScope.openAlertModal = true;
                                    confirmDialogService.openWarningDialog(content, title, false).finally(() => {
                                        $rootScope.openAlertModal = false;
                                    });
                                }

                                if (error.data === "ARCHIVED") {
                                    const title = $translate.instant('DOWNLOAD_ARCHIVED_TITLE');
                                    const content = $translate.instant('DOWNLOAD_ARCHIVED_FILE_CONFIRMATION');
                                    confirmDialogService.open({
                                        modalWidth: 450,
                                        modalHeight: 200
                                    }, content, title).then(response => {
                                        if (response) {
                                            anonymousReportService.moveFileFromArchivedToHotAccessTier($scope.orderId, video).then(function (response) {
                                                if (response) {
                                                    const title = $translate.instant('DOWNLOAD_ARCHIVED_TITLE');
                                                    const content = $translate.instant('DOWNLOAD_ARCHIVE_FILE_PROCESS_STARTED');
                                                    $rootScope.openAlertModal = true;
                                                    confirmDialogService.openWarningDialog(content, title).finally(() => {
                                                        $rootScope.openAlertModal = false;
                                                    });
                                                }
                                            }).catch(function (error) {
                                                if (error.status === 400) {
                                                    const title = $translate.instant('DOWNLOAD_ARCHIVED_TITLE');
                                                    const content = $translate.instant('DOWNLOAD_ARCHIVE_FILE_PROCESS_STARTED');
                                                    $rootScope.openAlertModal = true;
                                                    confirmDialogService.openWarningDialog(content, title).finally(() => {
                                                        $rootScope.openAlertModal = false;
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            }
        }

        function openInformationModal(report) {
            $kWindow.open({
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/report/information/report-info-modal.html',
                controller: 'reportInformationController',
                resolve: {
                    arrivalReport: report ? report : null
                },
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: $translate.instant('REPORT_INFO_MODAL_TITLE'),
                    width: 350,
                }
            });
        }
    }
})();
