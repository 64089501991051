(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('UnitGroupsAdministrationController', UnitGroupsAdministrationController);

    UnitGroupsAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'unitService',
        'commonUtility',
        'htmlElementService',
        'sanitizeService',
    ];

    function UnitGroupsAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        unitService,
        commonUtility,
        htmlElementService,
        sanitizeService
    ) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#unit-groups-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#unit-groups-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });


        var unitGroupsAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.unitGroups),
                update: e => onUnitGroupsGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        description: { editable: true },
                    }
                },
            }
        });

        const unitGroupsAdministrationGridOptions = langId => ({
            dataSource: unitGroupsAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_UNIT_GROUPS_DECRIPTION', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return sanitizeService.sanitizingStringContent(dataItem.description);
                    },
                },
                {
                    command: [
                        {
                            name: 'editUnitGroups',
                            click: e => editUnitGroups(e),
                            template: '<a class="k-grid-editUnitGroups"><i class="fas fa-edit"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: false
        });
        $scope.unitGroupsAdministrationGridOptions = unitGroupsAdministrationGridOptions($translate.use());
        $scope.unitGroups = [];
        $scope.addNewUnitGroups = addNewUnitGroups;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editUnitGroups = editUnitGroups;

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.unitGroupsAdministrationGridOptions = unitGroupsAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.unitGroupsAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#unit-groups-administration-grid')]);
            langId = code;
        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitGroupsAdministrationGrid',
                destination: '#unit-groups-administration-grid'
            });
            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });
            toggleChangesButtons(true)
            let requests = {};
            requests.getUnitGroupsByContractor = unitService.getUnitGroupsByContractor($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    $scope.unitGroups = data.getUnitGroupsByContractor;
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.unitGroupsAdministrationGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'unitGroupsAdministrationGrid');
                });
            $rootScope.$broadcast('hideBusyIndicator', 'unitGroupsAdministrationGrid');
        }

        function openUnitGroupWindow(currentFormType, unitGroup) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_CUSTOMERS_ADD_UNIT'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/administration/unitGroups/unit-groups-details-modal-administration-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'unitGroupsDetailsModalAdministrationController',
                resolve: {
                    currentFormType: () => currentFormType,
                    unitGroup: () => unitGroup,
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    if (currentFormType == formTypes.add) {
                        $scope.unitGroups = $scope.unitGroups.concat([result]);
                    } else {
                        $scope.unitGroups = $scope.unitGroups.map(u => (u.id === result.id ? result : u));
                    }
                    $scope.unitGroupsAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function addNewUnitGroups() {
            openUnitGroupWindow(formTypes.add, null)
        }

        function editUnitGroups(event) {
            var unitGroup = getUnitGroupFromGrid(event)
            openUnitGroupWindow(formTypes.edit, unitGroup)
        }

        function getUnitGroupFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#unit-groups-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function saveChanges() {
            $scope.unitGroupsAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.unitGroupsAdministrationGridOptions.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-unit-groups');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-unit-groups');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onUnitGroupsGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitGroupsAdministrationGrid',
                destination: '#unit-groups-administration-grid'
            });
            unitService.updateUnitGroup(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'unitGroupsAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'unitGroupsAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'unitGroupsAdministrationGrid');
                });

        }
    }
})();
