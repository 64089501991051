(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('adminSupplierService', adminSupplierService);

    adminSupplierService.$inject = ['$q', '$http', 'serviceUrls'];

    function adminSupplierService($q, $http, serviceUrls) {
        const suppliersWebApiUrl = `${serviceUrls.webApiBaseUrl}/adminSupplier`;

        return {
            getAllSuppliers: getAllSuppliers,
            addSupplier: addSupplier,
            updateSupplier: updateSupplier,
            deleteSupplier: deleteSupplier
        };

        function getAllSuppliers() {
            var deferred = $q.defer();
            var requestUrl = `${suppliersWebApiUrl}/all`;
            $http({ url: requestUrl, method: "GET"})
            .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function addSupplier(supplier) {
            const deferred = $q.defer();
            const requestUrl = `${suppliersWebApiUrl}`;
            $http
                .post(requestUrl, supplier)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateSupplier(supplier) {
            const deferred = $q.defer();
            const requestUrl = `${suppliersWebApiUrl}`;
            $http
                .put(requestUrl, supplier)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteSupplier(id) {
            const deferred = $q.defer();
            const requestUrl = `${suppliersWebApiUrl}?id=${id}`;
            $http
                .delete(requestUrl)
                .then(response => response && response.data && deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
