(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('locationInfoCoordinatesController', locationInfoCoordinatesController);

    locationInfoCoordinatesController.$inject = ['$q', '$scope', '$state', '$rootScope', '$stateParams', 'locationService', '$translate', '$kWindow', "mapObjectType", "mapObjectService", "kendoUtility", "mapUtility"];

    function locationInfoCoordinatesController($q, $scope, $state, $rootScope, $stateParams, locationService, $translate, $kWindow, mapObjectType, mapObjectService, kendoUtility, mapUtility) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#coordinatesInformationGrid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#coordinatesInformationGrid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        var coordinatesInformationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.cornerBuoys),
            },
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'mapLayerObjectPointId',
                    fields: {
                        description: { editable: false },
                        x: { editable: false },
                        y: { editable: false },
                    }
                },
            }
        });

        const coordinatesInformationGridOptions = langId => ({
            dataSource: coordinatesInformationDataSource,
            columns: [
                {
                    field: 'mapLayerObjectPointId',
                    hidden: true
                },
                {
                    field: 'description',
                    title: $translate.instant('G_DESCRIPTION', null, null, langId),
                },
                {
                    field: 'x',
                    title: $translate.instant('G_LAT', null, null, langId),
                },
                {
                    field: 'y',
                    title: $translate.instant('G_LONG', null, null, langId),
                },
                {
                    command: [
                        {
                            name: 'setToNotCornerCoordinate',
                            click: e => setToNotCornerCoordinate(e),
                            template: '<a class="k-grid-setToNotCornerCoordinate"><i class="fas fa-ban"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            editable: false,
            filterable: false,
            resizable: false,
            sortable: true,
            groupable: false
        });
        $scope.coordinatesInformationGridOptions = coordinatesInformationGridOptions($translate.use());
        $scope.buoys = [];
        $scope.cornerBuoys = [];
        $scope.mapObjects = [];
        $scope.addToCornerCoordinates = addToCornerCoordinates;
        $scope.setToNotCornerCoordinate = setToNotCornerCoordinate;
        $scope.markButtonDisabled = false;
        $scope.$on('langChanged', (event, code) => {
            $scope.coordinatesInformationGridOptions = coordinatesInformationGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.coordinatesInformationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#coordinatesInformationGrid')]);
            langId = code;
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'coordinatesInformationGrid',
                destination: '#right-pane',
                overlay: false
            });
            let requests = {};
            requests.getLocationByLocationNumber = locationService.getLocationByLocationNumber($stateParams.LocationNumber)

            $q.all(requests)
                .then(data => {
                    var buoys = []
                    var mapObjectIds = [];
                    _.forEach(data.getLocationByLocationNumber.MapLayers, function (mapLayer) {
                        _.forEach(mapLayer.MapLayerObjects, function (mapLayerObject) {
                            if (mapLayerObject.MapLayerObjectPoints[0] &&
                                mapLayerObject.MapLayerObjectPoints[0].Description !== null &&
                                mapLayerObject.MapLayerObjectPoints[0].Symbol &&
                                mapLayerObject.MapLayerObjectPoints[0].Symbol.Description &&
                                (mapLayerObject.MapLayerObjectPoints[0].Symbol.Description === "Gulramme")) {
                                buoys.push(mapLayerObject.MapLayerObjectPoints[0])
                                if (!mapObjectIds.includes(mapLayerObject.MapLayerObjectId)) {
                                    mapObjectIds.push(mapLayerObject.MapLayerObjectId);
                                }
                            }
                        })
                    });
                    $scope.buoys = buoys
                    _.forEach($scope.buoys, function (buoy) {
                        var mapObject = {
                            group: $translate.instant('MAIN_LOCATION_INFO_BUOYS'),
                            id: buoy.MapLayerObjectPointId,
                            text: buoy.Description,
                            objectType: mapObjectType.MapLayerObject,
                            x: buoy.x,
                            y: buoy.y
                        };

                        $scope.mapObjects.push(mapObject);

                        $scope.coordinatesFilterOptions = {
                            dataSource: new kendo.data.DataSource({
                                transport: {
                                    read: e => e.success($scope.mapObjects)
                                },
                                group: { field: 'group' }
                            }),
                            change: function (e) {
                                var selectedBuoy = $scope.cornerBuoys.filter(obj => {
                                    return obj.mapLayerObjectPointId === $scope.buoys[e.sender.selectedIndex].MapLayerObjectPointId
                                })[0];

                                if (selectedBuoy) {
                                    $scope.$apply(function () {
                                        $scope.markButtonDisabled = true;
                                    });
                                } else {
                                    $scope.$apply(function () {
                                        $scope.markButtonDisabled = false;
                                    });
                                }
                            },
                            dataTextField: 'text',
                            dataValueField: 'id',
                        };

                        $scope.coordinatesFilterOptions.dataSource.read();
                    });

                    var innerRequests = {}
                    innerRequests.getMapPoints = mapObjectService.getMapPoints(mapObjectIds)
                    $q.all(innerRequests)
                        .then(innerData => {
                            _.forEach(innerData.getMapPoints, function (buoy) {
                                extendBuoys(buoy)
                            })
                            $scope.cornerBuoys = innerData.getMapPoints
                            $scope.coordinatesInformationGrid.dataSource.read();
                        });
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'coordinatesInformationGrid');
                });

            $scope.$on('mapObjectSelected', function (event, selectedGraphic) {
                if (selectedGraphic && selectedGraphic.attributes) {
                    var selected = null;
                    if (selectedGraphic.attributes.mapLayerObjectPointId) {
                        selected = $scope.mapObjects.filter(obj => {
                            return obj.id === selectedGraphic.attributes.mapLayerObjectPointId
                        });
                    }
                    var selectedId = null;
                    if (selected !== null && selected.length) {
                        selectedId = selected[0].id;
                    }

                    if (selectedId) {
                        $scope.coordinatesFilter.value(selectedId);
                        $scope.selectedDataItem = $scope.coordinatesFilter.dataItem();
                    }
                    var selectedBuoy = $scope.cornerBuoys.filter(obj => {
                        return obj.mapLayerObjectPointId === selectedId
                    })[0];

                    if (selectedBuoy) {
                        $scope.$apply(function () {
                            $scope.markButtonDisabled = true;
                        });
                    } else {
                        $scope.$apply(function () {
                            $scope.markButtonDisabled = false;
                        });
                    }
                }
            });
        }

        function extendBuoys(buoy) {
            var latLong = mapUtility.convertToWGS84(buoy.x, buoy.y);;
            if (latLong) {
                buoy.lat = latLong.lat;
                buoy.long = latLong.lng;
            }
        }

        function setToNotCornerCoordinate(e) {
            const windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function(){
                        return $translate.instant('G_CONFIRM_DELETE', null, null, langId);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                    removeCornerCoordinate();
                }
            });

            function removeCornerCoordinate() {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'coordinatesInformationGrid',
                    destination: '#right-pane',
                    overlay: false
                });
                var buoy = getBuoyFromGrid(e)
                let requests = {};
                requests.setPointAsCornerPoint = mapObjectService.setPointAsCornerPoint(buoy.mapLayerObjectPointId, false)
                $q.all(requests)
                    .then(data => {
                        $scope.cornerBuoys = $scope.cornerBuoys.filter(obj => {
                            return obj.mapLayerObjectPointId !== data.setPointAsCornerPoint
                        });
                        $scope.coordinatesInformationGrid.dataSource.read();
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        $rootScope.$broadcast('hideBusyIndicator', 'coordinatesInformationGrid');
                    });
            }
        }

        function getBuoyFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#coordinatesInformationGrid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function addToCornerCoordinates() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'coordinatesInformationGrid',
                destination: '#right-pane',
                overlay: false
            });
            let requests = {};
            requests.setPointAsCornerPoint = mapObjectService.setPointAsCornerPoint($scope.coordinatesFilter.value(), true)
            $q.all(requests)
                .then(data => {
                    var buoy = $scope.buoys.filter(obj => {
                        return obj.MapLayerObjectPointId === data.setPointAsCornerPoint
                    })[0];
                    var buoyObject = {
                        mapLayerObjectPointId: buoy.MapLayerObjectPointId,
                        x: buoy.X,
                        y: buoy.Y,
                        description: buoy.Description
                    }
                    extendBuoys(buoyObject)
                    buoyObject.x = buoyObject.lat;
                    buoyObject.y = buoyObject.long;
                    $scope.cornerBuoys.push(buoyObject)
                    $scope.markButtonDisabled = true;
                    $scope.coordinatesInformationGrid.dataSource.read();
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'coordinatesInformationGrid');
                });
        }
    }
})();
