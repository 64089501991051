(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('DocumentTypeConfigModalController', documentTypeConfigModalController);

    documentTypeConfigModalController.$inject = ['$scope', '$windowInstance', 'configData'];

    function documentTypeConfigModalController($scope, $windowInstance, configData) {
        $scope.configData = configData ? JSON.parse(configData) : null;
        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.fieldList = [
            {
                text: 'description',
                value: 'description'
            },
            {
                text: 'expireDate',
                value: 'expireDate'
            },
            {
                text: 'subCategory',
                value: 'subCategory'
            },
            {
                text: 'warningTime',
                value: 'warningTime'
            },
            {
                text: 'notificationUser',
                value: 'notificationUser'
            },
            {
                text: 'comment',
                value: 'comment'
            },
            {
                text: 'fileUpload',
                value: 'fileUpload'
            },
        ];

        $scope.requiredFieldsConfigOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.fieldList);
                    }
                }
            }),
            dataValueField: 'text',
            dataTextField: 'value'
        };

        initModal();
        function initModal(){
            if($scope.configData && $scope.configData.required){
                $scope.SelectedFields = $scope.fieldList.filter(f => $scope.configData.required.includes(f.value));
            }
        }

        function ok() {
            if ($scope.validator.validate()) {
                var selectedValueList = $scope.SelectedFields.map(k => k.value);
                var jsonConfig =JSON.stringify({
                    required: selectedValueList
                });

                $windowInstance.close(jsonConfig);
            }

        }

        function cancel() {
            $windowInstance.close(false);
        }
    }
})();
