(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('documentModal', documentModal);

    documentModal.$inject = ['$q', '$scope', '$rootScope', '$windowInstance', 'itemTreeService', 'userService', 'formTypes', 'formType', 'document', 'externalId',
    'htmlElementService', 'fileHandlingService', 'mediaService', '$translate', 'listService', 'itemStatusObject', 'serviceUrls', 'relatedEntityId'
    ];

    function documentModal($q, $scope, $rootScope, $windowInstance, itemTreeService, userService, formTypes, formType, document, externalId,
        htmlElementService, fileHandlingService, mediaService, $translate, listService, itemStatusObject, serviceUrls, relatedEntityId
    ) {
        $scope.close = close;
        $scope.save = save;
        $scope.handleFilesDocument = handleFilesDocument;
        $scope.uploadFile = uploadFile;
        $scope.users = [];
        $scope.documentTypes = [];
        $scope.subCategoryList = [];
        $scope.isEdit = formType === formTypes.edit ? true : false;
        $scope.relatedFile = null;
        $scope.uploadedNewFile = false;
        $scope.isWrongFileFormat = false;
        $scope.workContractUrl = null;

        $scope.categoryDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.documentTypes)
                }
            }),
            change: function () {
                if($scope.selectedCategory && $scope.selectedCategory.subCategoryId){
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'documentBusyIndicator',
                        destination: '.document-details'
                    });
                    listService.getListElementsByListId($scope.selectedCategory.subCategoryId).then(function(subCategory){
                        $scope.subCategoryList = subCategory;
                        $scope.subCategoryDropdownOptions.dataSource.read();
                        $rootScope.$broadcast('hideBusyIndicator', 'documentBusyIndicator');
                    });
                } else {
                    $scope.subCategoryList = [];
                    $scope.subCategoryDropdownOptions.dataSource.read();
                }
            },
            dataValueField: 'id',
            dataTextField: 'name',
        };

        $scope.subCategoryDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.subCategoryList)
                }
            }),
            dataValueField: 'id',
            dataTextField: 'displayName',
        };

        $scope.statusDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success(itemStatusObject)
                }
            }),
            dataValueField: 'value',
            dataTextField: 'name',
        };

        $scope.noticePersonDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.users)
                }
            }),
            dataValueField: 'userId',
            dataTextField: 'displayName',
        };

        initController();
        function initController()
        {
            htmlElementService.checkIfElementExists('document-details').then(function(result){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'documentBusyIndicator',
                    destination: '.document-details'
                });
            });

            $q.all([itemTreeService.getDocumentTypesByContractorId($scope.authData.contractorId),
                userService.getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId]})])
            .then(function (results) {
                $scope.documentTypes = results[0];
                $scope.users = results[1];
                if($scope.isEdit){
                    initModal();
                } else{
                    if($scope.documentTypes && $scope.documentTypes.length > 0 && $scope.documentTypes[0].subCategoryId){
                        var subCategoryId = $scope.documentTypes[0].subCategoryId;
                        listService.getListElementsByListId(subCategoryId).then(function(subCategory){
                            $scope.subCategoryList = subCategory;
                        });
                    }
                }
                $scope.noticePersonDropdownOptions.dataSource.read();
                $scope.subCategoryDropdownOptions.dataSource.read();
                $scope.categoryDropdownOptions.dataSource.read();
            })
            .finally(function(){
                $rootScope.$broadcast('hideBusyIndicator', 'documentBusyIndicator');
            });
        }

        function initModal(){
            $scope.description = document.description;
            $scope.comment = document.comment;
            $scope.selectedCategory = $scope.documentTypes.find(x => x.id == document.categoryId);
            $scope.validFrom = moment(document.validityInterval.validFrom).format('YYYY-MM-DDTHH:mm:ss');
            $scope.validTo = document.validityInterval.validTo ? moment(document.validityInterval.validTo).format('YYYY-MM-DDTHH:mm:ss') : null;
            $scope.sendEmail = document.validityInterval.warnUser
            $scope.noticeTime = document.validityInterval.warningTime ? document.validityInterval.warningTime : 0
            $scope.selectedPerson = $scope.users.find(x => x.userId == document.validityInterval.warningUserId);
            $scope.relatedFile = document.files && document.files.length > 0 ? document.files[0] : null;
            if($scope.relatedFile && $scope.relatedFile.mediaId){
                $scope.relatedFile.url = mediaService.createMediaUrlMediaBank($scope.relatedFile.mediaId);
            }
            $scope.selectedStatus = itemStatusObject.find(x => x.value == document.status);
            if($scope.selectedCategory.subCategoryId){
                listService.getListElementsByListId($scope.selectedCategory.subCategoryId).then(function(subCategory){
                    $scope.subCategoryList = subCategory;
                    $scope.subCategoryDropdownOptions.dataSource.read();
                    $scope.selectedSubCategory = $scope.subCategoryList.find(x => x.id == document.subCategoryListElementId);
                });
            }
            if(document.workContractId){
                $scope.workContractUrl = `${serviceUrls.webApiBaseUrl}work-contract?workContractId=${document.workContractId}`
            }
        }

        function save(){

            if (!$scope.validator.validate()) {
                return;
            }

            htmlElementService.checkIfElementExists('document-details').then(function(result){
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'documentBusyIndicator',
                    destination: '.document-details'
                });
            });

            if (formType === formTypes.add || formType === formTypes.edit) {
                switch (formType) {
                    case formTypes.add:
                        var doc = createDocumentObject();
                        itemTreeService.addItem(doc).then(function(result){
                            if($scope.uploadedNewFile){
                                $scope.relatedFile.relatedItemId = result;
                                fileHandlingService.addItemFile($scope.relatedFile.fileName, $scope.relatedFile.mediaId, $scope.relatedFile.relatedItemId, $rootScope.authData.userId).then(function(docId){
                                    closeModal(docId);
                                });
                            } else {
                                closeModal(doc.id);
                            }
                        }) .finally(function(){
                            $rootScope.$broadcast('hideBusyIndicator', 'documentBusyIndicator');
                        });
                        break;
                    case formTypes.edit:
                        var doc = createDocumentObject();
                        itemTreeService.updateItem(doc).then(function(result){
                            if($scope.uploadedNewFile){
                                $scope.relatedFile.relatedItemId = doc.id;
                                fileHandlingService.addItemFile($scope.relatedFile.fileName, $scope.relatedFile.mediaId, $scope.relatedFile.relatedItemId, $rootScope.authData.userId).then(function(docId){
                                    closeModal(docId);
                                });
                            } else {
                                closeModal(doc.id);
                            }
                        }).finally(function(){
                            $rootScope.$broadcast('hideBusyIndicator', 'documentBusyIndicator');
                        });
                        break;
                }
            }
        }

        function createDocumentObject(){
            var editedDocument = {};
            if($scope.isEdit){
                editedDocument.id =  document.id;
            }

            editedDocument.contractorId =  $scope.isEdit ? document.contractorId : $scope.authData.contractorId;
            editedDocument.description = $scope.description;
            editedDocument.comment = $scope.comment;
            editedDocument.status = $scope.selectedStatus.value;
            editedDocument.createdById = $scope.isEdit ? document.createdById : $rootScope.authData.userId;
            editedDocument.lastChangedById = $rootScope.authData.userId;
            editedDocument.lastChangedOn = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
            editedDocument.categoryId = $scope.selectedCategory.id;
            editedDocument.relatedEntityId = $scope.isEdit ? document.relatedEntityId : relatedEntityId;
            editedDocument.externalId = externalId;
            editedDocument.SubCategoryListElementId = $scope.selectedSubCategory ? $scope.selectedSubCategory.id : null;
            // validityInterval
            editedDocument.validityInterval = {};
            editedDocument.validityInterval.validFrom =  moment($scope.validFrom).format('YYYY-MM-DDTHH:mm:ss');
            editedDocument.validityInterval.validTo = $scope.validTo ?  moment($scope.validTo).format('YYYY-MM-DDTHH:mm:ss') : null;
            editedDocument.validityInterval.warnUser = $scope.sendEmail;
            editedDocument.validityInterval.warningTime = $scope.noticeTime
            editedDocument.validityInterval.warningUserId = $scope.selectedPerson ? $scope.selectedPerson.userId : null;
            editedDocument.validityInterval.intervalType = 1;

            return editedDocument;
        }

        function uploadFile()
        {
            $('#uploadDocumentInputDocument').click();
        }

        function handleFilesDocument(e) {
            if ($scope.fileArray[0]) {
                var acceptedExtension = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'txt', 'rtf'];
                let file = $scope.fileArray[0];
                var extension = file.name.split('.').pop();

                if(!acceptedExtension.includes(extension.toLowerCase().trim())){
                    $scope.fileArray = [];
                    $scope.isWrongFileFormat = true;
                    angular.element("#uploadDocumentInputDocument").val(null);
                   return;
                } else {
                   $scope.isWrongFileFormat = false;
                }
                if($scope.relatedFile && $scope.relatedFile.relatedItemId  && confirm($translate.instant('USER_DOCUMENT_DELETE_CONFIRM'))){
                    itemTreeService.deleteItemFileWebApi($scope.relatedFile.id)
                    .then(() => {
                        $scope.relatedFile = null;
                        $scope.uploadedNewFile = false;
                        $scope.fileArray = [];
                        fileHandlingService.asyncReadGenericFile(file)
                                .then(base64FileContent => uploadBase64ToDoc(file.name, base64FileContent))

                    });
                } else if(!$scope.relatedFile || ($scope.relatedFile && !$scope.relatedFile.relatedItemId)) {
                    fileHandlingService.asyncReadGenericFile(file)
                        .then(base64FileContent => uploadBase64ToDoc(file.name, base64FileContent))
                } else {
                    $scope.fileArray = [];
                    angular.element("#uploadDocumentInputDocument").val(null);
                }
            }
        }

        function uploadBase64ToDoc(fileName, base64FileContent) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'uploadFileToDoc',
                destination: '#document-details'
            });

            var tempItemFile = {
                fileName: fileName,
                createdOn: new Date(),
                mediaId: null,
                relatedItemId: null
            };

            mediaService.uploadFileWebApi(fileName, base64FileContent)
                .then(newMediaId => {
                    $scope.uploadedNewFile = true;
                    tempItemFile.mediaId = newMediaId;
                    $scope.relatedFile = tempItemFile;
                })
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'uploadFileToDoc');
                });
        }

        $scope.deleteItemFile = function (itemFileId) {
            if($scope.relatedFile.id){
                if(confirm($translate.instant('USER_DOCUMENT_DELETE_CONFIRM'))){
                    itemTreeService.deleteItemFileWebApi(itemFileId)
                    .then(() => {
                        $scope.relatedFile = null;
                        $scope.uploadedNewFile = false;
                    });
                }
            } else {
                $scope.relatedFile = null;
                $scope.uploadedNewFile = false;
            }
        }

        function close(){
            closeModal(false);
        }

        function closeModal(param) {
            $windowInstance.close(param);
        }
    }
})();
