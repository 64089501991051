angular.module('NaviaqWebApp').factory('treeUtility', [function() {
    'use strict';


    /**
     * Checking whether a tree contains a node object.
     * @param {object} root The root object of the tree.
     * @param {object} node Object that checked whether
     * @param {string} childrenProp The name of the node's children array property.
     * @param {string} idProp The name of the node's Id property
     */
    function isNodeInTree(root, node, childrenProp, idProp) {
        var stack = [];
        stack.push(root);

        while (stack.length !== 0) {
            var currentNode = stack.pop();

            if (currentNode[idProp] === node[idProp]) {
                return true;
            }

            for (var i = 0; i < currentNode[childrenProp].length; ++i) {
                stack.push(currentNode[childrenProp][i]);
            }
        }

        return false;
    }

    /**
     *  Pre-order tree traversal visits each node using stack.
     *  Checks if leaf node based on children === null otherwise
     *  pushes all children into stack and continues traversal.
     *  hashMap object literal used for deduping.
     *  @param root - deserialized JSON root to begin traversal
     *  @returns array  - final array of nodes in order with no dups
     */
    function convertTreeToList(root, childrenProp, idProp) {
        var stack = [], array = [], hashMap = {};
        stack.push(root);

        while (stack.length !== 0) {
            var node = stack.pop();

            if (node[childrenProp] && node[childrenProp].length !== 0) {
                for (var i = node[childrenProp].length - 1; i >= 0; i--) {
                    stack.push(node[childrenProp][i]);
                }
            }

            visitNode(node, hashMap, array, idProp);
        }

        return array;
    }

    /**
     *  For each node visit if node not a hashMap key, insert
     *  into array.  Then append node into end of the array.
     *  @params node - object to check
     *  @param hashMap - object literal used for deduping
     *  @param array - final array that nodes are inserted
     */
    function visitNode(node, hashMap, array, idProp) {
        if (!hashMap[node[idProp]]) {
            hashMap[node[idProp]] = true;
            array.push(node);
        }
    }

    /**
     * Recursively collects the IDs of an item and all its descendants from a tree structure.
     * @param {Array} ids - An array to store the collected IDs.
     * @param {Object} item - The current item whose ID and descendants' IDs are to be collected.
     */
    function collectIdsFromTree(ids, item) {
        ids.push(item.id);
        if(item.children && item.children.length > 0){
            item.children.forEach(child => {
                collectIdsFromTree(ids, child)
            });
        }
    }

    var treeUtility = {
        convertTreeToList: convertTreeToList,
        isNodeInTree: isNodeInTree,
        collectIdsFromTree: collectIdsFromTree
    };

    return treeUtility;
}]);
