(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('JobTitleAdministrationController', JobTitleAdministrationController);

    JobTitleAdministrationController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        'commonUtility',
        'htmlElementService',
        'jobTitleService',
        'kendoUtility',
        '$translate',
        'formTypes',
        '$kWindow'
    ];

    function JobTitleAdministrationController(
        $q,
        $scope,
        $rootScope,
        commonUtility,
        htmlElementService,
        jobTitleService,
        kendoUtility,
        $translate,
        formTypes,
        $kWindow
    ) {

        $scope.jobTitles = [];
        $scope.addNewJobTitle = addNewJobTitle;

        var jobTitleAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success(_.sortBy($scope.jobTitles, 'sequenceNumber'))
            }
        });

        const jobTitleAdministrationGridOptions = langId => ({
            dataSource: jobTitleAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'sequanceNumber',
                    hidden: true
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_JOB_TITLE_GRID_NAME')
                },
                {
                    field: 'sequenceNumber',
                    title: $translate.instant('ADMIN_JOB_TITLE_GRID_SEQUENCE_NUMBER')
                },
                {
                    field: 'styrkTitleName',
                    title: $translate.instant('ADMIN_JOB_TITLE_STYRKTITLE')
                },
                {
                    command: [
                        {
                            name: 'editJobTitle',
                            click: e => editJobTitle(e),
                            template: '<a class="k-grid-editJobTitle"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteJobTitle',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                deleteJobTitle(data.jobTitleId);
                            },
                            template: '<a class="k-grid-deleteJobTitle"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 100,
                }
            ],
            editable: false,
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: false
        });
        $scope.jobTitleAdministrationGridOptions = jobTitleAdministrationGridOptions($translate.use());

        $scope.$on('langChanged', (event, code) => {
            $scope.jobTitleAdministrationGridOptions = jobTitleAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.jobTitleAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#job-title-administration-grid')]);
            langId = code;
        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'jobTitleAdministrationGrid',
                destination: '#job-title-administration'
            });

            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });

            $q.all([jobTitleService.getJobTitlesbyContractorId($rootScope.authData.contractorId)])
            .then(function(results){
                $scope.jobTitles = results[0].sort((a, b) => {
                    return a.sequenceNumber.toString().localeCompare(b.sequenceNumber.toString());
                });
                extendJobTitles();
            })
            .catch(error => console.error(error))
            .finally(() => {
                $scope.jobTitleAdministrationGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'jobTitleAdministrationGrid');
            });
        }

        function extendJobTitles(){
            $scope.jobTitles.forEach(jobTitle => {
                jobTitle.styrkTitleName = jobTitle.styrkTitle ? jobTitle.styrkTitle.title : '';
            });
        }

        function editJobTitle(event){
           var jobTitle = getJobTitleFromGrid(event);
           const window = openJobTitleDetailsModal(formTypes.edit, jobTitle);
           window.result.then(result => updateJobTitleGrid());
        }

        function deleteJobTitle(jobTitleId){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'jobTitleAdministrationGrid',
                        destination: '#job-title-administration'
                    });

                    jobTitleService.deleteJobTitle(jobTitleId).then(function(){
                        updateJobTitleGrid();
                        $rootScope.$broadcast('hideBusyIndicator', 'jobTitleAdministrationGrid');
                    });
                }
            });
         }

        function addNewJobTitle(){
            const window = openJobTitleDetailsModal(formTypes.add, null);
            window.result.then(result => {
                if (result) {
                    updateJobTitleGrid();
                }
            });
        }

        function getJobTitleFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#job-title-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function openJobTitleDetailsModal(formType, jobTitle) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: jobTitle ? $translate.instant('ADMIN_JOB_TITLE_EDIT_JOB_TITLE') : $translate.instant('ADMIN_JOB_TITLE_ADD_NEW_JOB_TITLE'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/administration/jobTitle/job-title-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'JobTitleDetailsModalController',
                resolve: {
                    formType: () => formType,
                    jobTitleId: () => jobTitle ? jobTitle.jobTitleId : ""
                }
            });
        }

        function updateJobTitleGrid()
        {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'jobTitleAdministrationGrid',
                destination: '#job-title-administration'
            });

            $q.all([jobTitleService.getJobTitlesbyContractorId($rootScope.authData.contractorId)])
            .then(function(results){
                $scope.jobTitles = results[0].sort((a, b) => {
                    return a.sequenceNumber.toString().localeCompare(b.sequenceNumber.toString());
                });
                extendJobTitles();
            })
            .catch(error => console.error(error))
            .finally(() => {
                $scope.jobTitleAdministrationGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'jobTitleAdministrationGrid');
            });
        }
    }
})();
