(function () {
    'use strict';

    angular
        .module('NaviaqWebApp')
        .controller('passwordResetController', passwordResetController);

    passwordResetController.$inject = [
        '$state',
        '$stateParams',
        '$scope',
        '$translate',
        '$q',
        'userService',
        'authService'
    ];

    function passwordResetController(
        $state,
        $stateParams,
        $scope,
        $translate,
        $q,
        userService,
        authService
    ) {
        $scope.isResetInProgress = false;
        $scope.resetPassword = resetPassword;

        $scope.customValidation = customValidation;

        $scope.passwordLength = 8;
        $scope.numberLength = 1;
        $scope.capitalLetterLength = 1;

        $scope.pattern = '(?=.*[0-9])(?=.*[A-Z])(.*)';
        $scope.numberPattern = '[0-9]+';
        $scope.capitalPattern = '[A-Z]+';

        $scope.minRequiredPasswordIsValid = true;
        $scope.minNumberInPasswordIsValid = true;
        $scope.minCapitalInPasswordIsValid = true;
        $scope.confirmPasswordIsValid = true;
        $scope.regexMatchIsValid = true;
        $scope.failed = false;

        initController();

        function initController() {
            if (!$stateParams.passwordRequestChangeId) {
                $state.go('login');
            }
        }


        function customValidation() {

            var deferred = $q.defer();
            $scope.customValidationSuccess = false;
            var pattern = new RegExp($scope.pattern);
            if ($scope.password) {
                $scope.minRequiredPasswordIsValid = $scope.password.length >= $scope.passwordLength;
                $scope.minNumberInPasswordIsValid = ($scope.password.match(new RegExp($scope.numberPattern, 'g')) || []).length >= $scope.numberLength;
                $scope.minCapitalInPasswordIsValid = ($scope.password.match(new RegExp($scope.capitalPattern, 'g')) || []).length >= $scope.capitalLetterLength;
            }
            $scope.confirmPasswordIsValid = $scope.password === $scope.confirmPassword;
            $scope.regexMatchIsValid = pattern.test($scope.password);

            $scope.customValidationSuccess = $scope.confirmPasswordIsValid
                && $scope.minNumberInPasswordIsValid
                && $scope.minRequiredPasswordIsValid
                && $scope.minCapitalInPasswordIsValid
                && $scope.regexMatchIsValid;

            deferred.resolve($scope.customValidationSuccess);
            return deferred.promise;
        };

        function resetPassword(password, confirmPassword) {
            if ($scope.validator.validate()) {
                customValidation().then(function (validationSuccess) {
                    if (validationSuccess) {
                        if ($scope.isResetInProgress) {
                            return;
                        }

                        if (!password || !confirmPassword) {
                            alert($translate.instant('PASSWORD_RESET_MISSING'));
                            return;
                        }

                        if (password !== confirmPassword) {
                            alert($translate.instant('PASSWORD_RESET_NOT_MATCHING'));
                            return;
                        }

                        $scope.isResetInProgress = true;

                        authService.resetPassword($stateParams.passwordRequestChangeId, password, confirmPassword)
                            .then((response) => {
                                if (response.isExpiredRequest){
                                    alert($translate.instant('PASSWORD_RESET_EXPIRED'));
                                }
                                else if (response.isSuccess){
                                    alert($translate.instant('PASSWORD_RESET_SUCCESSFUL'));
                                }
                                else {
                                    alert($translate.instant('PASSWORD_RESET_FAILED'));
                                }

                                $state.go('login');
                            })
                            .catch(() => alert($translate.instant('PASSWORD_RESET_FAILED')))
                            .finally(() => $scope.isResetInProgress = false);
                    }

                })
            }
        }
    }
})();
