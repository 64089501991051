(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('FinalReportComponentFilterModalController', FinalReportComponentFilterModalController);

    FinalReportComponentFilterModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'componentFilter'
    ];

    function FinalReportComponentFilterModalController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        componentFilter
    ) {
        $scope.isDirty = false;

        $scope.close = close;
        $scope.saveFilter = saveFilter;

        $scope.componentFilterRingsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(componentFilter.rings);
                    }
                }
            }),
            dataTextField: 'serialNumber',
            dataValueField: 'rindId',
            template: dataItem => {
                return `<label for="${dataItem.serialNumber}_checkbox" class="ring-serial-number"><input class="ring-checkbox" type="checkbox" id="${dataItem.serialNumber}_checkbox" ${dataItem.isChecked ? 'checked' : ''} ng-model="${dataItem.isChecked}" k-ng-model="${dataItem.isChecked}" value="${dataItem.isChecked}" />${dataItem.serialNumber}</label>`;
            }
        };

        $scope.componentFilterNetsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(componentFilter.nets);
                    }
                }
            }),
            dataTextField: 'netId',
            dataValueField: 'id',
            toolbar: {},
            template: dataItem => {
                return `<label for="${dataItem.netId}_checkbox" class="net-id"><input id="${dataItem.netId}_checkbox" class="net-checkbox" type="checkbox" ${dataItem.isChecked ? 'checked' : ''} ng-model="${dataItem.isChecked}" k-ng-model="${dataItem.isChecked}" value="${dataItem.isChecked}" />${dataItem.netId}</label>`;
            }
        };

        $scope.componentFilterCagesOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(componentFilter.cages);
                    }
                }
            }),
            dataTextField: 'cageNumber',
            dataValueField: 'cageId',
            toolbar: {},
            template: dataItem => {
                return `<label for="${dataItem.cageNumber}_checkbox" class="cage-number"><input id="${dataItem.cageNumber}_checkbox" class="cage-checkbox" type="checkbox" ${dataItem.isChecked ? 'checked' : ''} ng-model="${dataItem.isChecked}" k-ng-model="${dataItem.isChecked}" value="${dataItem.isChecked}" />${dataItem.cageNumber}</label>`;
            }
        };

        $scope.componentFilterLinesOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(componentFilter.lines);
                    }
                }
            }),
            dataTextField: 'description',
            dataValueField: 'lineId',
            toolbar: {},
            template: dataItem => {
                return `<label for="${dataItem.description}_checkbox" class="line-number"><input id="${dataItem.description}_checkbox" class="line-checkbox" type="checkbox" ${dataItem.isChecked ? 'checked' : ''} ng-model="${dataItem.isChecked}" k-ng-model="${dataItem.isChecked}" value="${dataItem.isChecked}" />${dataItem.description}</label>`;
            }
        };


        initController();
        function initController() {
            setTimeout(() => {
                $(".ring-checkbox").change( event => {
                    ringCheckboxValueChange(event);
                })
                $(".net-checkbox").change( event => {
                    netCheckboxValueChange(event);
                })
                $(".cage-checkbox").change( event => {
                    cageCheckboxValueChange(event);
                })
                $(".line-checkbox").change( event => {
                    lineCheckboxValueChange(event);
                })
            }, 1000);

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'orderProcessSaveIndicator',
                destination: '#order-process-form'
            });

            extendComponents();
            $scope.componentFilterRingsOptions.dataSource.read();
            $scope.componentFilterNetsOptions.dataSource.read();
            $scope.componentFilterCagesOptions.dataSource.read();
            $scope.componentFilterLinesOptions.dataSource.read();

            $rootScope.$broadcast('hideBusyIndicator', 'landbaseAdministrationGrid');
        }

        function extendComponents(){
            if(componentFilter.rings && componentFilter.rings.length > 0){
                componentFilter.rings = componentFilter.rings.map(m => ({
                    ...m,
                    isChecked: m.isChecked != null ? m.isChecked : true
                }));
            }

            if(componentFilter.nets && componentFilter.nets.length > 0){
                componentFilter.nets = componentFilter.nets.map(m => ({
                    ...m,
                    isChecked: m.isChecked != null ? m.isChecked : true
                }));
            }

            if(componentFilter.cages && componentFilter.cages.length > 0){
                componentFilter.cages = componentFilter.cages.map(m => ({
                    ...m,
                    isChecked: m.isChecked != null ? m.isChecked : true
                }));
            }

            if(componentFilter.lines && componentFilter.lines.length > 0){
                componentFilter.lines = componentFilter.lines.map(m => ({
                    ...m,
                    isChecked: m.isChecked != null ? m.isChecked : true
                }));
            }
        }

        function ringCheckboxValueChange(event){
            $scope.isDirty = true;
            var text = $(event.target).parent().text();
            var index = componentFilter.rings.findIndex(x => x.serialNumber == text);
            if(index !== -1){
                componentFilter.rings[index].isChecked = componentFilter.rings[index].isChecked ? false : true;
            }
        }

        function netCheckboxValueChange(event){
            $scope.isDirty = true;
            var text = $(event.target).parent().text()
            var index = componentFilter.nets.findIndex(x => x.netId == text);
            if(index !== -1){
                componentFilter.nets[index].isChecked = componentFilter.nets[index].isChecked ? false : true;
            }
        }

        function cageCheckboxValueChange(event){
            $scope.isDirty = true;
            var text = $(event.target).parent().text()
            var index = componentFilter.cages.findIndex(x => x.cageNumber == text);
            if(index !== -1){
                componentFilter.cages[index].isChecked = componentFilter.cages[index].isChecked ? false : true;
            }
        }

        function lineCheckboxValueChange(event){
            $scope.isDirty = true;
            var text = $(event.target).parent().text()
            var index = componentFilter.lines.findIndex(x => x.description == text);
            if(index !== -1){
                componentFilter.lines[index].isChecked = componentFilter.lines[index].isChecked ? false : true;
            }
        }

        function saveFilter(){
            if($scope.isDirty){
                close(componentFilter);
            } else {
                close();
            }

        }

        function close(param) {
            $windowInstance.close(param);
        }
    }
})();
