(function() {
    'use strict';

    angular.module('NaviaqWebApp').factory('sanitizeService', sanitizeService);

    sanitizeService.$inject = [
        'sanitize'
    ];

    function sanitizeService(
        sanitize){

        return {
            sanitizingStringContent: sanitizingStringContent,
            sanitizingGridContent: sanitizingGridContent,
            hasXssContentInString: hasXssContentInString,
        };

        function sanitizingStringContent(stringValue) {
            return sanitize(stringValue);
        }

        function sanitizingGridContent(e) {
            for (var field in e.model.dirtyFields) {
                if (e.model.dirtyFields.hasOwnProperty(field) && typeof e.values[field] === 'string') {
                    e.model[field] = e.values[field] != null && hasXssContentInString(e.values[field]) ? '' : e.values[field];
                }
            }
        }

        function hasXssContentInString(content)
        {
            var sanitizedContent = sanitize(content);
            return content != null && sanitizedContent !== content;
        }
    }
})();
