(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationInfoHSController', LocationInfoHSController);

    LocationInfoHSController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$stateParams',
        'locationService',
        '$translate',
        'kendoUtility',
        '$kWindow',
        'sanitizeService'];

    function LocationInfoHSController(
        $q,
        $scope,
        $state,
        $rootScope,
        $stateParams,
        locationService,
        $translate,
        kendoUtility,
        $kWindow,
        sanitizeService) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#locationInformationHSGrid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#locationInformationHSGrid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        var locationInformationHSDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.locationInformation),
                update: e => onLocationInformationGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        hsWindWaves10Y: { editable: true, type: 'number' },
                        hsWindWaves50Y: { editable: true, type: 'number' },
                        hsOceanWaves10Y: { editable: true, type: 'number' },
                        hsOceanWaves50Y: { editable: true, type: 'number' },
                    }
                },
            }
        });

        const locationInformationHSGridOptions = langId => ({
            dataSource: locationInformationHSDataSource,
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'hsWindWaves10Y',
                    title: $translate.instant('MAIN_LOCATION_INFO_HS_OCEAN_WAVES_IN_10_YEARS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.hsWindWaves10Y;
                    },
                },
                {
                    field: 'hsWindWaves50Y',
                    title: $translate.instant('MAIN_LOCATION_INFO_HS_WIND_WAVES_IN_50_YEARS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.hsWindWaves50Y;
                    },
                },
                {
                    field: 'hsOceanWaves10Y',
                    title: $translate.instant('MAIN_LOCATION_INFO_HS_WIND_WAVES_IN_10_YEARS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.hsOceanWaves10Y;
                    },
                },
                {
                    field: 'hsOceanWaves50Y',
                    title: $translate.instant('MAIN_LOCATION_INFO_HS_OCEAN_WAVES_IN_50_YEARS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.hsOceanWaves50Y;
                    },
                },
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: false,
            resizable: true,
            sortable: true,
            groupable: false
        });
        $scope.locationInformationHSGridOptions = locationInformationHSGridOptions($translate.use());

        $scope.locationInformation = [];
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.toggleChangesButtons = toggleChangesButtons;

        $scope.$on('langChanged', (event, code) => {
            $scope.locationInformationHSGridOptions = locationInformationHSGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.locationInformationHSGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#locationInformationHSGrid')]);
            langId = code;
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationInformation',
                destination: '#right-pane',
                overlay: false
            });
            toggleChangesButtons(true)
            let requests = {};
            requests.getLocationInformationWebApi = locationService.getLocationInformationWebApi($stateParams.LocationNumber)
            $q.all(requests)
                .then(data => {
                    if (!data.getLocationInformationWebApi || data.getLocationInformationWebApi === null) {
                        $scope.locationInformation.push({
                            hsWindWaves10Y: 0,
                            hsWindWaves50Y: 0,
                            hsOceanWaves10Y: 0,
                            hsOceanWaves50Y: 0,
                            id: '00000000-0000-0000-0000-000000000000',
                            locationNumber: $stateParams.LocationNumber
                        });
                    } else {
                        $scope.locationInformation.push(data.getLocationInformationWebApi);
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.locationInformationHSGrid.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'locationInformation');
                });
        }

        function saveChanges() {
            $scope.locationInformationHSGrid.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.locationInformationHSGrid.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-locationInformation-hs');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-locationInformation-hs');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onLocationInformationGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationInformationHSGrid',
                destination: '#locationInformationHSGrid'
            });

            let requests = {};
            if (e.data.id === '00000000-0000-0000-0000-000000000000') {
                e.data.vsMaxValueInMeterPerSecond = 0;
                e.data.vs10Y = 0;
                e.data.vs50Y = 0;
                e.data.vsDirectionGrd = 0;
                requests.locationInformation = locationService.addLocationInformationWebApi(e.data)
            } else {
                requests.locationInformation = locationService.updateLocationInformationWebApi(e.data)
            }
            $q.all(requests)
                .then(data => {
                    if (data.locationInformation) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'locationInformationHSGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'locationInformationHSGrid');
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'locationInformationHSGrid');
                });
        }
    }
})();
