(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('listElementDetailsModalController', listElementDetailsModalController);

    listElementDetailsModalController.$inject = [
        '$rootScope',
        '$scope',
        '$windowInstance',
        'listElement',
        'listId',
        'currentFormType',
        'formTypes',
        'listService',
    ];

    function listElementDetailsModalController(
        $rootScope,
        $scope,
        $windowInstance,
        listElement,
        listId,
        currentFormType,
        formTypes,
        listService,
    ) {
        $scope.listElement = {};
        $scope.close = close;
        $scope.validateForm = validateForm;

        initController();

        function initController() {
            if (currentFormType === formTypes.edit && listElement) {
                $scope.listElement = listElement;
            }
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'listElementDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        $scope.listElement.listId = listId
                        listService.addListElement($scope.listElement)
                            .then(listElement => {
                                $scope.listElement.id = listElement
                                close($scope.listElement)
                            })
                            .catch(() => close());
                        break;
                    case formTypes.edit:
                        listService.updateListElement($scope.listElement)
                            .then(listElement => close($scope.listElement))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'listElementDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
