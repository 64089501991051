angular.module('NaviaqWebApp').directive('mapLayersPanel', [function () {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/main/directives/map-layers-panel.html',
        link: function (scope, element, attrs) {
            element.find('.dropdown-item').click(function(event) {
                $(event.currentTarget).toggleClass('active');
            });
        }
    };
}]);
