(function () {
    angular.module('NaviaqWebApp').factory('locationReferenceService', locationReferenceService);

    locationReferenceService.$inject = [
        '$q',
        '$rootScope',
        '$http',
        'serviceUrls'
    ];

    function locationReferenceService(
        $q,
        $rootScope,
        $http,
        serviceUrls
        )
    {
        var locationReferenceApiUrl = `${serviceUrls.webApiBaseUrl}/locationReference`;

        return {
            getLocationReferences: getLocationReferences,
            addLocationReference: addLocationReference,
            updateLocationReference: updateLocationReference,
            getLocationReferenceByCustomerId: getLocationReferenceByCustomerId,
        };

        function getLocationReferences() {
            var contractorId = $rootScope.authData.contractorId;
            const deferred = $q.defer(),
                requestUrl = `${locationReferenceApiUrl}/byContractorId?contractorId=${contractorId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function addLocationReference(locationReference) {
            const deferred = $q.defer();
            const requestUrl = `${locationReferenceApiUrl}/add`;
            $http
                .post(requestUrl, locationReference)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateLocationReference(locationReference) {
            const deferred = $q.defer();
            const url = `${locationReferenceApiUrl}/update`;

            $http
                .post(url, locationReference)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }


        function getLocationReferenceByCustomerId(customerId, contractorId = null){
            const deferred = $q.defer();
            const url = `${locationReferenceApiUrl}/byCustomerId?customerId=${customerId}&contractorId=${contractorId}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
