(function() {
    angular.module('NaviaqWebApp').factory('timeFilterService', timeFilterService);

    function timeFilterService() {
        return {
            getDateOfToday: getDateOfToday,
            getStartDateOfThisWeek: getStartDateOfThisWeek,
            getEndDateOfThisWeek: getEndDateOfThisWeek,
            getStartDateOfLastWeek: getStartDateOfLastWeek,
            getEndDateOfLastWeek: getEndDateOfLastWeek
        };

        function getDateOfToday() {
            return new Date();
        }

        function getStartDateOfThisWeek() {
            return offsetDate(1);
        }

        function getEndDateOfThisWeek() {
            return offsetDate(7);
        }

        function getStartDateOfLastWeek() {
            return offsetDate(-6);
        }

        function getEndDateOfLastWeek() {
            return offsetDate(0);
        }

        function offsetDate(offset) {
            var currentDate = new Date();
            var date = currentDate.getDate() - currentDate.getDay() + offset;
            return new Date(currentDate.setDate(date));
        }
    }
}());
