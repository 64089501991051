(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationDeviationsController', LocationDeviationsController);

    LocationDeviationsController.$inject = ['$scope', '$rootScope','$translate', '$stateParams', 'locationService', 'kendoUtility'];

    function LocationDeviationsController($scope, $rootScope, $translate, $stateParams, locationService, kendoUtility) {
        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.deviationsGridData = [];
        $scope.tasks = [];

        initController();
        function initController() {
            readDeviations();

            const deviationsGridOptions = langId => ({
                dataSource: {
                    data: $scope.deviationsGridData
                },
                databound: hideIcon,
                sortable: true,
                filterable: true,
                noRecords: true,
                messages: {
                    noRecords: $translate.instant('G_NORESULTS', null, null, langId)
                },

                detailInit: detailInit,
                detailTemplate: kendo.template($("#location-deviations-units-template").html()),
                columns: [
                    { field: 'mapObjectDescription', title: $translate.instant('MAIN_LOCATION_DEVIATION_GRID_COMPONENT', null, null, langId) },
                    { field: 'vesselName', title: $translate.instant('MAIN_LOCATION_DEVIATION_GRID_DEPARTME', null, null, langId) },
                    { field: 'logActionDescription', title: $translate.instant('MAIN_LOCATION_DEVIATION_GRID_AREA', null, null, langId) },
                    { field: 'deviationLogDescription', title: $translate.instant('MAIN_LOCATION_DEVIATION_GRID_TYPEOFCASE', null, null, langId) },
                    { field: 'registeredToUserDisplayName', title: $translate.instant('MAIN_LOCATION_DEVIATION_GRID_REGISTEREDBY', null, null, langId) },
                    { field: 'doneOn', title: $translate.instant('MAIN_LOCATION_DEVIATION_GRID_DATETIME', null, null, langId) },
                    { field: 'deviationLogStatus', title: $translate.instant('G_STATUS', null, null, langId) },
                    { field: 'tag', title: $translate.instant('MAIN_LOCATION_DEVIATION_GRID_TAGS', null, null, langId) }
                ]
            });
            $scope.deviationsGridOptions = deviationsGridOptions($translate.use());

            const unitsGridOption = langId => ({
                noRecords: true,
                messages: {
                    noRecords: $translate.instant('G_NORESULTS', null, null, langId)
                },
                columns: [
                    {
                        field: 'comment',
                        title: $translate.instant('MAIN_LOCATION_DEVIATION_UNITSGRID_TASK', null, null, langId)
                    }, {
                        field: 'assignedToUser',
                        title: $translate.instant('MAIN_LOCATION_DEVIATION_UNITSGRID_ASSIGNEDTO', null, null, langId)
                    }, {
                        field: 'targetDate',
                        title: $translate.instant('MAIN_LOCATION_DEVIATION_UNITSGRID_DEADLINE', null, null, langId)
                    }, {
                        field: 'taskStatus',
                        title: $translate.instant('G_STATUS', null, null, langId)
                    }
                ]
            });
            $scope.unitsGridOption = unitsGridOption($translate.use());

            $scope.$on('langChanged', (event, code) => {
                $scope.deviationsGridOptions = deviationsGridOptions(code);
                const deviationsGrid = kendoUtility.createWidget('kendoGrid', $scope.deviationsGridOptions);

                $scope.unitsGridOption = unitsGridOption(code);
                const unitsGrid = kendoUtility.createWidget('kendoGrid', $scope.unitsGridOption);

                kendoUtility.changeLangOfWidget(code, [
                    () => deviationsGrid('#deviationsGrid'),
                    () => unitsGrid('#unitsGrid')
                ]);
            });

        }

        function hideIcon(e) {
            e.sender.element.find('.k-hierarchy-cell a').hide();
        }

        function readDeviations() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationLog',
                destination: '#right-pane',
                overlay: false
            });

            locationService.getDeviationLogsByLocationNumber($stateParams.LocationNumber).then(function (deviations) {
                $scope.deviationsGridData = [];
                var operationLogIds = [];

                _.each(deviations, function (item) {
                    operationLogIds.push(item.operationLogId);
                });
                locationService.getOperationLogTasksByOperationLogIds(operationLogIds).then(function (tasks) {
                    $scope.tasks = tasks;
                });
                _.each(deviations, function (item) {
                    extendItem(item);
                    $scope.deviationsGridData.push(item);
                });

                $scope.deviationsGrid.dataSource.data($scope.deviationsGridData);
                $scope.deviationsGrid.refresh();

                $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
            }, function () {
                $rootScope.$broadcast('hideBusyIndicator', 'locationLog');
            });
        }

        function extendItem(item) {
            item.doneOn = moment(item.doneOn).format('DD.MM.YYYY');
            item.deviationLogStatus = getStatus(item.deviationLogStatus);
        }

        function detailInit(e) {
            var taskList = _.filter($scope.tasks, function (item) { return item.operationLogId == e.data.operationLogId });
            var subItems = [];

            _.each(taskList, function (item) {
                extendSubItem(item);
                subItems.push(item);
            });

            var detailRow = e.detailRow,
                items = subItems,
                grid = detailRow.find(".units-grid"),
                data = grid.data();
            data.kendoGrid.dataSource.data(items);
        }

        function extendSubItem(item) {
            item.targetDate = moment(item.targetDate).format('DD.MM.YYYY');
            item.taskStatus = getStatus(item.taskStatus);
            item.assignedToUser = item.assignedToUser.displayName;
        }

        function getStatus(statusId) {
            var retVal = '';
            switch (statusId) {
                case '11111111-1111-1111-1111-111111111111':
                    retVal = $translate.instant('MAIN_LOCATION_DEVIATION_UNITSGRID_OPEN');
                    break;
                case '22222222-2222-2222-2222-222222222222':
                    retVal = $translate.instant('MAIN_LOCATION_DEVIATION_UNITSGRID_DURINGTREATMENT');
;
                    break;
                case '99999999-9999-9999-9999-999999999999':
                    retVal = $translate.instant('MAIN_LOCATION_DEVIATION_UNITSGRID_CLOSE');
;
                    break;
            }
            return retVal;
        }
    }
})();
