angular.module('NaviaqWebApp').config([
    '$stateProvider',
    '$urlRouterProvider',
    'appVersion',
    function($stateProvider, $urlRouterProvider, appVersion) {
        'use strict';

        var appVersionString = appVersion.replace(/\./g, '');

        var navbarView = {
            templateUrl: 'app/shared/navbar/navbar.html' + '?v=' + appVersionString,
            controller: 'NavbarController',
        };

        let handlePageRequest = {
            'timeBank': function ($q, $rootScope, $state, $timeout) {
                var hasTimebankHubModule = $rootScope.hasModule('TimebankHub');
                if(hasTimebankHubModule){
                    return $q.when();
                }  else {
                    $timeout(function () {
                        $state.go('main');
                      }, 100);
                      return $q.reject();
                }
            },
          };

        var translateReadyResolve = {
            translateReady: ['$translate', function($translate) {
                return $translate.onReady();
              }]
        }

        $urlRouterProvider.otherwise(function($injector, $location){
            var state = $injector.get('$state');
            if($location.$$path.includes('final-report') && (!$location.$$path.includes('FRØY') || !$location.$$path.includes('AQS'))){
                state.go('finalReport', $location.$$search);
                $location.$$path = `final-report?OrderId=${$location.$$search.OrderId}&UserId=${$location.$$search.UserId}`
            } else if($location.$$path.includes('mooring-report') && (!$location.$$path.includes('FRØY') || !$location.$$path.includes('AQS'))){
                state.go('mooringReport', $location.$$search);
                $location.$$path = `mooring-report?OrderId=${$location.$$search.OrderId}&UserId=${$location.$$search.UserId}`
            } else {
                state.go('login')
                $location.$$path = `login`
            }

            return $location.path();
         });

        $stateProvider
            .state('login', {
                url: '/login?returnState&returnParams',
                views: {
                    main: {
                        templateUrl: 'app/login/login-view.html' + '?v=' + appVersionString,
                        controller: 'LoginController'
                    }
                },
                resolve: translateReadyResolve
            })
            .state('forgotPassword',
                {
                    url: '/forgot-password',
                    views: {
                        main: {
                            templateUrl: 'app/password/forgot-password-view.html' + '?v=' + appVersionString,
                            controller: 'forgotPasswordController'
                        }
                    },
                    resolve: translateReadyResolve
                })
            .state('passwordReset',
              {
                url: '/password-reset?passwordRequestChangeId',
                params: {
                    passwordRequestChangeId: null,
                },
                views: {
                    main: {
                        templateUrl: 'app/password/password-reset-view.html' + '?v=' + appVersionString,
                        controller: 'passwordResetController'
                    }
                },
                resolve: translateReadyResolve
              })
            .state('loginFromOtherSite', {
                url: '/login?site&redirectUrl',
                views: {
                    main: {
                        templateUrl: 'app/login/login-view.html' + '?v=' + appVersionString,
                        controller: 'LoginController'
                    }
                },
                resolve: translateReadyResolve
            })
            .state('main', {
                url: '/main',
                views: {
                    main: {
                        templateUrl: 'app/main/main-view.html' + '?v=' + appVersionString,
                        controller: 'MainController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('main.orderReport', {
                url: '/order-report?ContractorId&OrderId',
                templateUrl: 'app/report/order-report.html' + '?v=' + appVersionString,
                controller: 'OrderReportController',
                resolve: translateReadyResolve
            })
            .state('main.locationOrders', {
                url: '/location/location-orders?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.locationInstallations', {
                url: '/location/location-installations?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.locationInfo', {
                url: '/location/location-info?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.locationInfo.details', {
                url: '/location/location-info?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.locationInfo.marinogram', {
                url: '/location/location-info?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.locationInfo.coordinates', {
                url: '/location/location-info?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.locationDocuments', {
                url: '/location/location-documents?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.locationDepartments', {
                url: '/location/location-departments?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.locationDeviations', {
                url: '/location/location-deviations?LocationNumber',
                templateUrl: 'app/location/locationPanel/views/location-panel-view.html' + '?v=' + appVersionString,
                controller: 'LocationPanelController',
                resolve: translateReadyResolve
            })
            .state('main.vesselDetails', {
                url: '/vessel/details?:mmsi',
                templateUrl: 'app/vessels/vessel-overview.html' + '?v=' + appVersionString,
                controller: 'VesselOverviewController',
                resolve: translateReadyResolve
            })
            .state('main.vesselPersonel', {
                url: '/vessel/personnel?:mmsi',
                templateUrl: 'app/vessels/vessel-overview.html' + '?v=' + appVersionString,
                controller: 'VesselOverviewController',
                resolve: translateReadyResolve
            })
            .state('main.vesselOrders', {
                url: '/vessel/orders?:mmsi',
                templateUrl: 'app/vessels/vessel-overview.html' + '?v=' + appVersionString,
                controller: 'VesselOverviewController',
                resolve: translateReadyResolve
            })
            .state('main.vesselEquipment', {
                url: '/vessel/equipment?:mmsi',
                templateUrl: 'app/vessels/vessel-overview.html' + '?v=' + appVersionString,
                controller: 'VesselOverviewController',
                resolve: translateReadyResolve
            })
            .state('main.vesselmLink', {
                url: '/vessel/dashboard?:mmsi',
                templateUrl: 'app/vessels/vessel-overview.html' + '?v=' + appVersionString,
                controller: 'VesselOverviewController',
                resolve: translateReadyResolve
            })
            .state('locationOverview', {
                url: '/location-overview?LocationNumber&ContractorId',
                views: {
                    main: {
                        templateUrl: 'app/location/location-overview.html' + '?v=' + appVersionString,
                        controller: 'LocationOverviewController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('tourReport', {
                url: '/tour-report?ContractorId&OrderId',
                views: {
                    main: {
                        templateUrl: 'app/report/tour-report.html' + '?v=' + appVersionString,
                        controller: 'TourReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('operationLogsOverview', {
                url: '/operation-logs-overview?ContractorId&OrderId',
                views: {
                    main: {
                        templateUrl: 'app/log/operation-log-view.html' + '?v=' + appVersionString,
                        controller: 'OperationLogController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })

            .state('operationLogsReport', {
                url: '/operation-logs-report?ContractorId&OrderId&ReportFolder',
                views: {
                    main: {
                        templateUrl: 'app/report/operation-logs-report.html' + '?v=' + appVersionString,
                        controller: 'OperationLogsReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('orderWorkReport', {
                url: '/order-work-report?ContractorId&OrderId&ReportFolder',
                views: {
                    main: {
                        templateUrl: 'app/report/order-work-report.html' + '?v=' + appVersionString,
                        controller: 'OrderWorkReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('WorkContractReport', {
                url: '/work-contract?workContractId',
                views: {
                    main: {
                        templateUrl: 'app/report/contracts/work-contract-report.html' + '?v=' + appVersionString,
                        controller: 'WorkContractReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('arrivalReport', {
                url: '/arrival-report?OrderId&ReportId',
                views: {
                    main: {
                        templateUrl: 'app/report/arrival-report.html' + '?v=' + appVersionString,
                        controller: 'ArrivalReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('finalReport', {
                url: '/final-report?OrderId',
                views: {
                    main: {
                        templateUrl: 'app/report/final-report.html' + '?v=' + appVersionString,
                        controller: 'FinalReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('finalReportFroy', {
                url: '/final-report/FRØY?OrderId',
                views: {
                    main: {
                        templateUrl: 'app/report/clients/Froy/final-report.html' + '?v=' + appVersionString,
                        controller: 'FinalReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('finalReportAQS', {
                url: '/final-report/AQS?OrderId',
                views: {
                    main: {
                        templateUrl: 'app/report/clients/AQS/final-report.html' + '?v=' + appVersionString,
                        controller: 'FinalReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('mooringReport', {
                url: '/mooring-report/?OrderId',
                views: {
                    main: {
                        templateUrl: 'app/report/mooring-report/mooring-report.html' + '?v=' + appVersionString,
                        controller: 'mooringReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('mooringReportFroy', {
                url: '/mooring-report/FRØY?OrderId',
                views: {
                    main: {
                        templateUrl: 'app/report/clients/Froy/mooring-report.html' + '?v=' + appVersionString,
                        controller: 'mooringReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('mooringReportAQS', {
                url: '/mooring-report/AQS?OrderId',
                views: {
                    main: {
                        templateUrl: 'app/report/clients/AQS/mooring-report.html' + '?v=' + appVersionString,
                        controller: 'mooringReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('restReport', {
                url: '/rest-report?userId&fromDate&toDate',
                views: {
                    main: {
                        templateUrl: 'app/report/restReport/rest-report.html' + '?v=' + appVersionString,
                        controller: 'restReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('orderConfirmationReport', {
                url: '/orderConfirmation?OrderId',
                views: {
                    main: {
                        templateUrl: 'app/report/order-confirmation/order-confirmation-report.html' + '?v=' + appVersionString,
                        controller: 'orderConfirmationReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('caseReport', {
                url: '/case-report?operationLogId',
                views: {
                    main: {
                        templateUrl: 'app/report/case-report.html' + '?v=' + appVersionString,
                        controller: 'CaseReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('caseListReport', {
                url: '/case-list-report',
                views: {
                    main: {
                        templateUrl: 'app/report/case-list-report.html' + '?v=' + appVersionString,
                        controller: 'CaseListReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('caseListReportPrint', {
                url: '/case-list-report-print?PrintView',
                views: {
                    main: {
                        templateUrl: 'app/report/case-list-report.html' + '?v=' + appVersionString,
                        controller: 'CaseListReportController'
                    },
                },
                resolve: translateReadyResolve
            })
            .state('serviceCard', {
                url: '/service-card?ContractorId&OrderId&ReportFolder',
                views: {
                    main: {
                        templateUrl: 'app/report/clients/Hepso/service-card-report.html' + '?v=' + appVersionString,
                        controller: 'ServiceCardReportController'
                    },
                    navbar: navbarView,
                },
                resolve: translateReadyResolve
            })
            .state('serviceCardPrint', {
                url: '/service-card-print?ContractorId&OrderId&ReportFolder&PrintView',
                views: {
                    main: {
                        templateUrl: 'app/report/clients/Hepso/service-card-report.html' + '?v=' + appVersionString,
                        controller: 'ServiceCardReportController'
                    }
                },
                resolve: translateReadyResolve
            })
            .state('equipments', {
                url: '/equipments?DepartmentId&CategoryId&ItemDescription&CategoryTab',
                views: {
                    main: {
                        templateUrl: 'app/equipments/equipments-view.html' + '?v=' + appVersionString,
                        controller: 'EquipmentsController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('vesselDashboard', {
                url: '/vessel-dashboard?DepartmentId&CategoryId',
                views: {
                    main: {
                        templateUrl: 'app/vessels/vessel-dashboard-view.html' + '?v=' + appVersionString,
                        controller: 'VesselDashboardController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('main.showTracking', {
                url: '/order-track?ContractorId&OrderId',
                templateUrl: 'app/order/order-track.html' + '?v=' + appVersionString,
                controller: 'OrderTrackController',
                resolve: translateReadyResolve
            })
            .state('privacyAgreement', {
                url: '/privacy',
                views: {
                    main: {
                        templateUrl: 'app/privacy/privacy-agreement.html' + '?v=' + appVersionString,
                        controller: 'PrivacyAgreementController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration', {
                url: '/administration',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.logActions', {
                url: '/logActions',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.vessels', {
                url: '/vessels',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.operationTypes', {
                url: '/operationTypes',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.role', {
                url: '/role',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.dayCounter', {
                url: '/dayCounter',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.customers', {
                url: '/customers',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.units', {
                url: '/units',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                }
            })
            .state('administration.unitGroups', {
                url: '/unitGroups',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.persons', {
                url: '/persons',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.landbases', {
                url: '/landbases',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.documentTypes', {
                url: '/documentTypes',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.lists', {
                url: '/lists',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.orderProcess', {
                url: '/orderProcess',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.logActionPairs', {
                url: '/logActionPairs',
                views: {
                    main: {
                        templateUrl: 'app/administration/logActionPairs/logAction-pairs-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.jobTitle', {
                url: '/jobTitle',
                views: {
                    main: {
                        templateUrl: 'app/administration/jobTitle/job-title-administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.contractor', {
                url: '/contractor',
                views: {
                    main: {
                        templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.tags', {
                url: '/tags',
                views: {
                    main: {
                        templateUrl: 'app/administration/tags/tags-administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('administration.locationSharing', {
                url: '/locationSharing',
                views: {
                    main: {
                        templateUrl: 'app/administration/locationSharing/location-sharing-administration-view.html' + '?v=' + appVersionString,
                        controller: 'AdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('serviceforms', {
                url: '/serviceforms',
                views: {
                    main: {
                        templateUrl: 'app/serviceForms/service-forms-editor-view.html' + '?v=' + appVersionString,
                        controller: 'ServiceFormsEditorController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('workorders', {
                url: '/workorders?woNo',
                views: {
                    main: {
                        templateUrl: 'app/workorders/work-orders-view.html' + '?v=' + appVersionString,
                        controller: 'WorkOrdersController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('workorderReport', {
                url: '/workorder-report?workorderId&operationLogId',
                views: {
                    main: {
                        templateUrl: 'app/report/workorder-report.html' + '?v=' + appVersionString,
                        controller: 'WorkorderReportController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('timeBank', {
                url: '/timebank',
                views: {
                    main: {
                        templateUrl: 'app/timebank/timebank-view.html' + '?v=' + appVersionString,
                        controller: 'TimeBankController'
                    },
                    navbar: navbarView
                },
                resolve: {
                    translateReady: ['$translate', function($translate) {
                        return $translate.onReady();
                      }],
                    authenticate:handlePageRequest['timeBank']
                }
            })
            .state('timeBank.logs', {
                url: '/logs',
                views: {
                    main: {
                        templateUrl: 'app/timebank/logs/logs-view.html' + '?v=' + appVersionString,
                        controller: 'TimeBankController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('timeBank.summary', {
                url: '/summary',
                views: {
                    main: {
                        templateUrl: 'app/timebank/summary/summary-view.html' + '?v=' + appVersionString,
                        controller: 'TimeBankController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('timeBank.userBalance', {
                url: '/userbalance',
                views: {
                    main: {
                        templateUrl: 'app/timebank/summary/summary-view.html' + '?v=' + appVersionString,
                        controller: 'TimeBankController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('timeBank.balance', {
                url: '/balance',
                views: {
                    main: {
                        templateUrl: 'app/timebank/balance/balance-view.html' + '?v=' + appVersionString,
                        controller: 'TimeBankController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('timeBank.sailingTime', {
                url: '/sailingTime',
                views: {
                    main: {
                        templateUrl: 'app/timebank/sailingTime/sailing-time-view.html' + '?v=' + appVersionString,
                        controller: 'TimeBankController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('cases', {
                url: '/cases?caseNo',
                views: {
                    main: {
                        templateUrl: 'app/case/case-view.html' + '?v=' + appVersionString,
                        controller: 'CaseController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('customers', {
                url: '/customers',
                views: {
                    main: {
                        templateUrl: 'app/customers/customers-view.html' + '?v=' + appVersionString,
                        controller: 'CustomersController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('customers.locationReferences', {
                url: '/locationReferences',
                views: {
                    main: {
                        templateUrl: 'app/customers/customers-view.html' + '?v=' + appVersionString,
                        controller: 'CustomersController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin', {
                url: '/sysadmin',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/system-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin.suppliers', {
                url: '/suppliers',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/suppliers/suppliers-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin.resources', {
                url: '/resources',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/resources/resources-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin.contractors', {
                url: '/contractors',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/contractors/contractors-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin.orderRequestType', {
                url: '/orderRequestType',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/orderRequestType/order-request-type-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin.emailTemplates', {
                url: '/emailTemplates',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/emailTemplates/email-templates-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin.users', {
                url: '/users',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/users/user-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin.locationOwners', {
                url: '/locationOwners',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/locationOwner/location-owner-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            })
            .state('sysadmin.conglomerates', {
                url: '/conglomerates',
                views: {
                    main: {
                        templateUrl: 'app/systemAdministration/contractors/contractors-administration-view.html' + '?v=' + appVersionString,
                        controller: 'SystemAdministrationController'
                    },
                    navbar: navbarView
                },
                resolve: translateReadyResolve
            });
    }
]);
