(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('PersonAdministrationController', PersonAdministrationController);

    PersonAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'commonUtility',
        'personService',
        'locationService',
        'htmlElementService',
        'sanitizeService'
    ];

    function PersonAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        commonUtility,
        personService,
        locationService,
        htmlElementService,
        sanitizeService
    ) {
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
        $scope.isSuperUser = $rootScope.authData.isSuperuser;

        window.onbeforeunload = function () {
            if ($('#persons-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#persons-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });


        var personsAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.persons),
                update: e => onPersonsGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'personId',
                    fields: {
                        firstName: { editable: true },
                        lastName: { editable: true },
                        email: { editable: true },
                        phoneNumber: { editable: true },
                        mobilePhoneNumber: { editable: true },
                        externalId: { editable: true },
                        locationPersons: { editable: true },
                    }
                },
            }
        });

        const personsAdministrationGridOptions = langId => ({
            dataSource: personsAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'personId',
                    hidden: true
                },
                {
                    field: 'firstName',
                    title: $translate.instant('ADMIN_PERSONS_FIRST_NAME', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.firstName ? dataItem.firstName : '';
                    },
                },
                {
                    field: 'lastName',
                    title: $translate.instant('ADMIN_PERSONS_LAST_NAME', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.lastName ? dataItem.lastName : '';
                    },
                },
                {
                    field: 'email',
                    title: $translate.instant('ADMIN_PERSONS_EMAIL', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.email ? dataItem.email : '';
                    },
                },
                {
                    field: 'phoneNumber',
                    title: $translate.instant('ADMIN_PERSONS_PHONE_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.phoneNumber ? dataItem.phoneNumber : '';
                    },
                },
                {
                    field: 'mobilePhoneNumber',
                    title: $translate.instant('ADMIN_PERSONS_MOBIL_NUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.mobilePhoneNumber ? dataItem.mobilePhoneNumber : '';
                    },
                },
                {
                    field: 'externalId',
                    title: $translate.instant('ADMIN_PERSONS_EXTERNAL_ID', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.externalId ? dataItem.externalId : '';
                    },
                },
                {
                    field: 'locations',
                    title: $translate.instant('ADMIN_PERSONS_LOCATIONS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            var locationsText = ''
                            _.forEach(dataItem.locations, function (location) {
                                locationsText = locationsText + location.name + ", "
                            });
                            dataItem.locationsText = locationsText.substr(0, locationsText.length - 2)
                            toggleChangesButtons(false);
                        }
                        return dataItem.locationsText ? dataItem.locationsText : '';
                    },
                    width: 300,
                    editor: locationPersonsDropDownEditor,
                },
                {
                    command: [
                        {
                            name: 'editPerson',
                            click: e => editPerson(e),
                            template: '<a class="k-grid-editPerson"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deletePerson',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                deletePerson(data.personId);
                            },
                            template: '<a ng-show="isSuperUser" class="k-grid-deletePerson"><i class="fas fa-trash"></i></a>'
                        }
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true
        });
        $scope.personsAdministrationGridOptions = personsAdministrationGridOptions($translate.use());
        $scope.persons = [];
        $scope.locations = [];
        $scope.addNewPerson = addNewPerson;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editPerson = editPerson;

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.personsAdministrationGridOptions = personsAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.personsAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#persons-administration-grid')]);
            langId = code;
        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'personsAdministrationGrid',
                destination: '#persons-administration-grid'
            });
            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });
            toggleChangesButtons(true)
            let requests = {};
            requests.getPersons = personService.getContactPersonsByContractorId($rootScope.authData.contractorId);
            requests.getLocationsWebApi = locationService.getLocationsWebApi($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    $scope.locations = data.getLocationsWebApi;
                    _.forEach(data.getPersons, function (item) {
                        extendPerson(item);
                    });

                    $scope.persons = data.getPersons;
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.personsAdministrationGridOptions.dataSource.read();
                });
            $rootScope.$broadcast('hideBusyIndicator', 'personsAdministrationGrid');
        }

        function extendPerson(item) {
            if (item.locationPersons && item.locationPersons.length) {
                var locations = [];
                var locationsText = '';
                _.forEach(item.locationPersons, function (locationPerson) {
                    var location = $scope.locations.find(obj => {
                        return obj.locationNumber == locationPerson.locationNumber
                    });
                    if (location) {
                        locations.push(location);
                        locationsText = locationsText + location.name + ", "
                    }
                });
                item.locationsText = locationsText.substr(0, locationsText.length - 2)
                item.locations = locations
            } else {
                item.locations = [];
                item.locationsText = '';
            }
        }

        function locationPersonsDropDownEditor(container, options) {
            $('<select name="' + options.field + '"></select>').appendTo(container).kendoMultiSelect({
                dataTextField: "name",
                dataValueField: "locationNumber",
                dataSource: {
                    data: _.orderBy($scope.locations,'name')
                },
            });
        }

        function openPersonWindow(currentFormType, person) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_PERSONS_ADD_PERSON'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/administration/persons/person-details-modal-administration-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'personDetailsModalAdministrationController',
                resolve: {
                    currentFormType: () => currentFormType,
                    person: () => person,
                    locations: () => $scope.locations,
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    extendPerson(result)
                    if (currentFormType == formTypes.add) {
                        $scope.persons = $scope.persons.concat([result]);
                    } else {
                        $scope.persons = $scope.persons.map(u => (u.personId === result.personId ? result : u));
                    }
                    $scope.personsAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function addNewPerson() {
            openPersonWindow(formTypes.add, null)
        }

        function editPerson(event) {
            var person = getPersonFromGrid(event)
            openPersonWindow(formTypes.edit, person)
        }

        function deletePerson(personId){
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'personsAdministrationGrid',
                        destination: '#persons-administration-grid'
                    });

                    personService.deletePerson(personId).then(function(){
                        $scope.persons = $scope.persons.filter(x => x.personId != personId);
                        $scope.personsAdministrationGridOptions.dataSource.read();
                    }).finally(function(){
                        $rootScope.$broadcast('hideBusyIndicator', 'personsAdministrationGrid');
                    });
                }
            });
        }

        function getPersonFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#persons-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function saveChanges() {
            $scope.personsAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.personsAdministrationGridOptions.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-persons');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-persons');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function mapLocationPersonObjects(item) {
            if (item.locations && item.locations.length) {
                var locationPersons = [];
                _.forEach(item.locations, function (location) {
                    var locationPerson = {};
                    locationPerson.locationNumber = location.locationNumber;
                    locationPerson.personId = item.personId;

                    locationPersons.push(locationPerson);
                });
                item.locationPersons = locationPersons
            }
        }

        function onPersonsGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'personsAdministrationGrid',
                destination: '#persons-administration-grid'
            });
            mapLocationPersonObjects(e.data)
            personService.updatePerson(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'personsAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'personsAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'personsAdministrationGrid');
                });

        }
    }
})();
