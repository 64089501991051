(function() {
    'use strict';

    angular.module('NaviaqWebApp').factory('mediaService', mediaService);

    mediaService.$inject = ['$q', '$http', 'commonUtility', 'serviceUrls'];

    function mediaService($q, $http, commonUtility, serviceUrls) {
        const mediaWebApiUrl = `${serviceUrls.webApiBaseUrl}/media`;

        var service = {
            createMediaUrl: createMediaUrl,
            createMediaUrlMediaBank: createMediaUrlMediaBank,
            uploadFileWebApi: uploadFileWebApi,
            getMediaByMediaId,
            asyncEncodeImageFile,
            asyncReadGenericFile,
            addItemFile
        };

        return service;

        function uploadFileWebApi(fileName, fileContent) {
            var deferred = $q.defer();
            var encodedFileName = commonUtility.base64Encode(fileName);

            $http.post(`${mediaWebApiUrl}/upload`, {
                    Base64FileName:encodedFileName,
                    Base64FileContent:fileContent
                })
                .then(
                    function(response) {
                        deferred.resolve(response.data);
                    },
                    function() {
                        deferred.reject();
                    }
                )
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function createMediaUrl(mediaId) {
            return createMediaUrlMediaBank(mediaId);
        }

        function createMediaUrlMediaBank(mediaId) {
            return mediaId ?
                `${mediaWebApiUrl}/mediaBankDownload/${mediaId}` :
                '';
        }

        function getMediaByMediaId(mediaId) {
            const deferred = $q.defer();

            $http
                .get(`${mediaWebApiUrl}/getMediaContentById/${mediaId}`)
                .then(response =>
                    deferred.resolve(response.data)
                )
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function asyncEncodeImageFile(file) {
            if (commonUtility.isFileWithoutExtension(file)) {
                commonUtility.fileWithoutExtensionAlert();
                return;
            }

            return $q((resolve, reject) => {
                let reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        }

        function asyncReadGenericFile(file) {
            if (commonUtility.isFileWithoutExtension(file)) {
                commonUtility.fileWithoutExtensionAlert();
                return;
            }

            return $q((resolve, reject) => {
                let reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        }

        function addItemFile(fileName, mediaId) {
            var itemFile = {
                Id: commonUtility.uuidv4(),
                CreatedById: $rootScope.authData.userId,
                CreatedOn: new Date(),
                MediaId: mediaId,
                RelatedItemId: $scope.arrivalReportItem.id,
                FileName: fileName,
            };

            return itemTreeService.addItemFile(itemFile);
        }
    }
})();
