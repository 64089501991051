(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('DocumentTypeDetailsModalController', documentTypeDetailsModalController);

    documentTypeDetailsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$kWindow',
        '$windowInstance',
        '$translate',
        'documentType',
        'currentFormType',
        'formTypes',
        'documentTypeService',
        'documentTypeAccess',
        'jobTitleService',
        'listService',
        'htmlElementService'
    ];

    function documentTypeDetailsModalController(
        $q,
        $rootScope,
        $scope,
        $kWindow,
        $windowInstance,
        $translate,
        documentType,
        currentFormType,
        formTypes,
        documentTypeService,
        documentTypeAccess,
        jobTitleService,
        listService,
        htmlElementService
    ) {

        $scope.documentType = {};
        $scope.lists = [];
        $scope.JobTitles = [];
        $scope.close = close;
        $scope.validateForm = validateForm;
        $scope.openDocumentTypeConfigModal = openDocumentTypeConfigModal;
        $scope.isSkillCategory = false;
        $scope.documentConfig = null;
        $scope.hasConfigChange = false;

        $scope.documentTypeSubCategoryOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.lists);
                    }
                }
            }),
            dataValueField: 'id',
            dataTextField: 'description'
        };

        $scope.documentTypeAccessOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success(documentTypeAccess);
                    }
                }
            }),
            dataValueField: 'id',
            dataTextField: 'value',
            change: function () {
                if($scope.selectedAccess == 2){
                    documentTypeForm.documentTypeJobTitle.$valid = false;
                    documentTypeForm.documentTypeJobTitle.required = true;
                } else {
                    documentTypeForm.documentTypeJobTitle.$valid = true;
                    documentTypeForm.documentTypeJobTitle.required = false;
                }
            }
        };

        $scope.documentTypeJobTitleOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        e.success($scope.JobTitles);
                    }
                }
            }),
            dataValueField: 'jobTitleId',
            dataTextField: 'name'
        };

        initController();

        function initController() {
            htmlElementService.checkIfElementExists("document-type-details-form")
            .then(function (result) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'documentTypeDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });
            });

            $q.all([jobTitleService.getJobTitlesbyContractorId($rootScope.authData.contractorId, true),
                listService.getListsByContractorId($rootScope.authData.contractorId)]).then(function(results){
                $scope.JobTitles = results[0];
                $scope.lists = results[1].filter(x => x.placeHolderId.startsWith('doc_'));

                if (currentFormType === formTypes.edit && documentType) {
                    $scope.documentType = documentType;
                    $scope.selectedAccess = documentType.access;
                    if($scope.selectedAccess == 2){
                        documentTypeForm.documentTypeJobTitle.$valid = false;
                        documentTypeForm.documentTypeJobTitle.required = true;
                    }
                }
                $scope.documentTypeSubCategoryOptions.dataSource.read();
                $scope.documentTypeAccessOptions.dataSource.read();
                $scope.documentTypeJobTitleOptions.dataSource.read();

                if(currentFormType === formTypes.edit){
                    if(documentType && documentType.jobTitles){
                        var selectedJobTitleIds = _.map(documentType.jobTitles, 'jobTitleId');
                        $scope.selectedJobTitles = selectedJobTitleIds;
                    }

                    if(documentType && documentType.subCategoryId){
                        $scope.selectedSubCategory = documentType.subCategoryId;
                    }

                    $scope.isSkillCategory = documentType.isSkillCategory;
                }
                $rootScope.$broadcast('hideBusyIndicator', 'documentTypeDetailsModalIndicator');
            });
        }

        function validateForm(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'documentTypeDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                    $scope.documentType.contractorId = $rootScope.authData.contractorId;
                    $scope.documentType.isSystemCategory = false;
                    $scope.documentType.isDeleted = false;
                    $scope.documentType.order = 0;
                    $scope.documentType.relatedEntityId = $rootScope.authData.userId;
                    $scope.documentType.subCategoryId = $scope.selectedSubCategory;
                    $scope.documentType.documentConfig = $scope.documentConfig;
                    $scope.documentType.access = $scope.selectedAccess
                    $scope.documentType.jobTitles = $scope.selectedJobTitles ? getSelectedJobTitles() : null;
                    $scope.documentType.isSkillCategory = $scope.isSkillCategory

                    documentTypeService
                        .addDocumentType($scope.documentType)
                        .then(function(documentType){
                            $scope.documentType.id = documentType;
                            close($scope.documentType);
                        })
                        .catch(() => close());
                    break;
                    case formTypes.edit:
                        $scope.documentType.access = $scope.selectedAccess;
                        $scope.documentType.subCategoryId = $scope.selectedSubCategory;
                        $scope.documentType.jobTitles = $scope.selectedJobTitles ? getSelectedJobTitles() : null;
                        $scope.documentType.isSkillCategory = $scope.isSkillCategory;
                        if($scope.hasConfigChange){
                            $scope.documentType.documentConfig = $scope.documentConfig;
                        }

                        documentTypeService
                            .updateDocumentType($scope.documentType)
                            .then(documentType => close($scope.documentType))
                            .catch(() => close());
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        function openDocumentTypeConfigModal() {
            $scope.documentConfig = currentFormType === formTypes.edit ? $scope.documentType.documentConfig : null;
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: $translate.instant('ADMIN_DOCUMENT_TYPES_CONFIG_MODAL_TITLE'),
                    width: 500
                },
                templateUrl: 'app/shared/popups/document-type-config-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DocumentTypeConfigModalController',
                resolve: {
                    configData: function () {
                        return $scope.documentConfig;
                    }
                }
            });

            windowInstance.result.then(function (response) {
                if (response) {
                    $scope.hasConfigChange = true;
                    $scope.documentConfig = response;
                    if(currentFormType === formTypes.edit){
                        $scope.documentType.documentConfig = $scope.documentConfig;
                    }
                }
            });
        }

        function getSelectedJobTitles(){
            return $scope.JobTitles.filter(j => $scope.selectedJobTitles.includes(j.jobTitleId));
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'documentTypeDetailsModalIndicator');
            $windowInstance.close(param);
        }
    }
})();
