(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LogActionDetailsModalController', logActionDetailsModalController);

    logActionDetailsModalController.$inject = [
        '$scope',
        '$q',
        '$rootScope',
        '$windowInstance',
        '$translate',
        'authService',
        'logService',
        'formTypes',
        'formType',
        'logAction',
        'lists',
        'users',
        'htmlElementService',
        'departmentService'
    ];

    function logActionDetailsModalController(
        $scope,
        $q,
        $rootScope,
        $windowInstance,
        $translate,
        authService,
        logService,
        formTypes,
        formType,
        logAction,
        lists,
        users,
        htmlElementService,
        departmentService
    ) {
        $scope.authData = authService.getAuthData();
        $scope.logAction = null;
        $scope.formType = formType;
        $scope.departments = [];

        $scope.logActionTypeOptions = {
            dataSource: new kendo.data.DataSource({
                data: logService.getTypes()
            }),
            dataValueField: 'value',
            dataTextField: 'name',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE')
        };

        $scope.logActionListOptions = {
            dataSource: new kendo.data.DataSource({
                data: lists
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTLIST')
        };
        $scope.logActionUserOptions = {
            dataSource: new kendo.data.DataSource({
                data: users
            }),
            dataValueField: 'userId',
            dataTextField: 'displayName',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTUSER')
        };
        $scope.jobTypeOptions = {
            dataSource: new kendo.data.DataSource({
                data: logService.getJobTypes()
            }),
            dataValueField: 'value',
            dataTextField: 'name',
            optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTJOBTYPE')
        };
        $scope.departmentsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.departments)
                }
            }),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            noDataTemplate: 'Ingen treff'
        };

        $scope.closeModal = closeModal;
        $scope.validateForm = validateForm;

        initController();
        function initController(){
            var requests = {
                getDepartmentsWebapi: departmentService.getDepartmentsWebapi($scope.authData.contractorId)
            };

            $q.all(requests).then(function(result){
                $scope.departments = result.getDepartmentsWebapi;
                $scope.departmentsOptions.dataSource.read();

                if (formType === formTypes.edit && logAction) {
                    initModal(logAction);
                    setModule();
                }
                else {
                    $scope.activeLogAction = true;
                    setModule();
                }
            });
        }

        function setModule(){
            if(authService.hasModule('RequiredDescriptionInLog')){
                $scope.showRequiredDescription = true;
            }
        }

        function initModal(logAction) {
            htmlElementService.checkIfElementExists("log-action-details-form")
                .then(function (result) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'logActionDetailsModalBusyIndicator',
                        destination: '#log-action-details-form',
                        overlay: true
                    });
                    $scope.logAction = logAction;
                    $scope.description = logAction.description;
                    $scope.logActionType.select(function (type) {
                        if (type.value === logAction.logActionType) {
                            $scope.selectedLogActionType = type;
                        }
                        return type.value === logAction.logActionType;
                    });
                    $scope.logActionList.select(function (list) {
                        if (list.id === logAction.listId) {
                            $scope.selectedLogActionList = list;
                        }

                        return list.Id === logAction.listId;
                    });
                    $scope.logActionUser.select(function (user) {
                        if (user.userId === logAction.defaultUserId) {
                            $scope.selectedLogActionUser = user;
                        }
                        return user.userId === logAction.defaultUserId;
                    });
                    $scope.userListNeeded = logAction.userListNeeded;
                    $scope.isPrivate = logAction.isPrivate;

                    $scope.jobType.select(function (jobType) {
                        if (jobType.value === logAction.jobType) {
                            $scope.selectedJobType = jobType;
                        }
                        return jobType.value === logAction.jobType;
                    });
                    $scope.hasValue = logAction.hasValue;
                    $scope.valueName = logAction.valueName;
                    $scope.exportLogAction = logAction.exportLogAction;
                    $scope.activeLogAction = logAction.status;
                    $scope.isCustomerDeviation = logAction.isCustomerDeviation;
                    $scope.isComponentLog = logAction.isComponentLog;
                    $scope.hasFileToLogAction = logAction.hasFile;
                    $scope.selectedDepartments = logAction.departments;

                    $scope.isDescriptionRequired = logAction.isDescriptionRequired;

                    $rootScope.$broadcast('hideBusyIndicator', 'logActionDetailsModalBusyIndicator');
                });
        }

        function validateForm(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                $scope.logAction = {
                    description: $scope.description,
                    logActionType: $scope.selectedLogActionType.value,
                    listId:
                        $scope.selectedLogActionList && $scope.selectedLogActionList.id !== ''
                            ? $scope.selectedLogActionList.id
                            : null,
                    defaultUserId:
                        $scope.selectedLogActionUser && $scope.selectedLogActionUser.userId !== ''
                            ? $scope.selectedLogActionUser.userId
                            : null,
                    userListNeeded: $scope.userListNeeded,
                    isPrivate: $scope.isPrivate,
                    isDescriptionRequired: $scope.isDescriptionRequired,
                    jobType:
                        $scope.selectedJobType && $scope.selectedJobType.value !== ''
                            ? $scope.selectedJobType.value
                            : null,
                    hasValue: $scope.hasValue,
                    hasFile: $scope.hasFileToLogAction,
                    valueName: $scope.valueName,
                    isCustomerDeviation: $scope.isCustomerDeviation,
                    isComponentLog: $scope.isComponentLog,
                    exportLogAction: $scope.exportLogAction,
                    status: ($scope.activeLogAction === true ? 0 : 1),
                    contractorId: $scope.authData.contractorId,
                    departments: $scope.selectedDepartments ? $scope.selectedDepartments : null
                };

                if (formType === formTypes.add) {
                    logService.addLogAction($scope.logAction).then(function (response) {
                        //TODO: check if successful
                        $windowInstance.close(true);
                    });
                } else {
                    $scope.logAction.logActionId = logAction.logActionId;
                    logService.updateLogAction($scope.logAction).then(function (response) {
                        $windowInstance.close(true);
                    });
                }
            }
        }

        function closeModal() {
            $windowInstance.close();
        }
    }
})();
