(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('confirmDialogService', confirmDialogService);

    confirmDialogService.$inject = ['$kWindow', '$translate'];

    function confirmDialogService($kWindow, $translate) {
        var service = {
            open: open,
            openVideoDownloadWarningModal: openVideoDownloadWarningModal,
            openWarningDialog: openWarningDialog,
            openCaseConfirmDialog: openCaseConfirmDialog
        };

        return service;

        function open(params, content, title, okBtnText, cancelBtnText) {
            if (!params) { params = {}; }

            var modalWidth = params.modalWidth || 175;
            var modalHeight = params.modalHeight || 100;

            var defaults = {
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: title ? title : $translate.instant('G_CONFIRM'),
                    width: modalWidth,
                    height: modalHeight,
                    overlay: true
                },
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/shared/modals/confirm-dialog.html',
                controller: 'ConfirmDialogController',
                resolve: {
                    content: () => content,
                    okBtnText: () => okBtnText,
                    cancelBtnText: () => cancelBtnText
                }
            };
            params = params ? _.merge(defaults, params) : defaults;

            var modal = $kWindow.open(params);

            return modal.result;
        }

        function openVideoDownloadWarningModal(size, params) {
            if (!params) { params = {}; }

            var modalWidth = params.modalWidth || 400;
            var modalHeight = params.modalHeight || 180;

            var defaults = {
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: $translate.instant('G_CONFIRM'),
                    width: modalWidth,
                    height: modalHeight,
                    overlay: true
                },
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/shared/modals/download-video-warning-modal.html',
                controller: 'downloadVideoWarningModalController',
                resolve: {
                    size: () =>  size
                },
            };
            params = params ? _.merge(defaults, params) : defaults;

            var modal = $kWindow.open(params);

            return modal.result;
        }

        function openWarningDialog(content, title) {
            var defaults = {
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: title ? title : $translate.instant('G_CONFIRM'),
                    width: 500,
                    height: 150,
                    overlay: true
                },
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/shared/modals/warning-dialog.html',
                controller: 'WarningDialogController',
                resolve: {
                    content: function () {
                        return content;
                    },
                }
            };
            var params = params ? _.merge(defaults, {}) : defaults;
            var modal = $kWindow.open(params);
            return modal.result;
        }

        function openCaseConfirmDialog(params, message, title, okBtnText, closeBtnText, workOrders, selectedDepartmentId) {
            !params ? params = {} : params;

            var defaults = {
                options: {
                    modal: true,
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    title: title ? title : $translate.instant('G_WARNING'),
                    width: 450,
                    height: '60%',
                    overlay: false
                },
                windowTemplateUrl: 'app/shared/modal-base.html',
                templateUrl: 'app/shared/modals/case-confirm-dialog.html',
                controller: 'caseConfirmDialog',
                resolve: {
                    message: () =>  message,
                    okBtnText: () => okBtnText,
                    closeBtnText: () => closeBtnText,
                    workOrders: () => workOrders,
                    selectedDepartmentId: () => selectedDepartmentId
                },
            };
            params = params ? _.merge(defaults, params) : defaults;

            var modal = $kWindow.open(params);

            return modal.result;
        }
    }
})();
