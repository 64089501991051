(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('conglomerateAdministrationController', conglomerateAdministrationController);

    conglomerateAdministrationController.$inject = ['$q', '$scope', '$rootScope', '$translate', 'kendoUtility', 'authService', 'adminConglomerateService', 'formTypes', '$kWindow'];

    function conglomerateAdministrationController($q, $scope, $rootScope, $translate, kendoUtility, authService, adminConglomerateService, formTypes, $kWindow) {
        $scope.addConglomerate = addConglomerate;
        $scope.conglomerates = [];
        $scope.isSuperUser = authService.getAuthData().isSuperuser;

        const conglomerateGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.conglomerates),
                },
                schema: {
                    model: {
                        id: 'conglomerateId',
                        fields: {
                            name: { editable: false, type: 'string' }
                        }
                    },
                }
            }),
            columns: [
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_CONGLOMERATE_NAME', null, null, langId),
                },
                {
                    field: 'contractorList',
                    title: $translate.instant('ADMIN_CONGLOMERATE_CONTRACTORS', null, null, langId),
                },
                {
                    command: [
                        { name: 'editConglomerate', click: e => editConglomerate(e), template: '<a class="k-grid-editConglomerate"><i class="fa fa-edit grid-icon"></i></a>' },
                        { name: 'deleteConglomerate', click: e => deleteConglomerate(e), template: '<a ng-show="{{!dataItem.contractors.length}}" class="k-grid-deleteConglomerate"><i class="fas fa-trash"></i></a>' }
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            editable: false,
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.conglomerateGridOptions = conglomerateGridOptions($translate.use());

        $scope.$on('langChanged', (_, langId) => {
            $scope.conglomerateGridOptions = conglomerateGridOptions(langId);
            const conglomerateGrid = kendoUtility.createWidget('kendoGrid', $scope.conglomerateGridOptions);
            kendoUtility.changeLangOfWidget(langId, [() => conglomerateGrid('#conglomerate-grid')]);
        });

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'conglomeratesLoad',
                destination: '#conglomerate-administration',
                overlay: true,
            });

            $q.all({
                getConglomerates: adminConglomerateService.getConglomerates()
            })
                .then(data => {
                    $scope.conglomerates = data.getConglomerates;
                    extendConglomerates();
                    $scope.conglomerateGridOptions.dataSource.read();
                })
                .catch(error => console.error(error))
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'conglomeratesLoad'));
        }

        function editConglomerate(event) {
            const conglomerate = getConglomerate(event);
            const updateModal = openConglomerateModal(formTypes.edit, conglomerate);
            conglomerate && updateModal.result.then(response => {
                if (response) {
                    const gridItem = $scope.conglomerates.find(x => x.conglomerateId == response.conglomerateId);
                    gridItem.name = response.name;
                    gridItem.contractors = response.contractors;
                    extendConglomerates();
                    $scope.conglomerateGridOptions.dataSource.read();
                }
            });
        }

        function getConglomerate(event) {
            event.preventDefault();
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $("#conglomerate-grid").data("kendoGrid").dataItem(row).toJSON();
        }

        function addConglomerate() {
            const addModal = openConglomerateModal(formTypes.add);

            addModal.result.then(response => {
                if (response) {
                    $scope.conglomerates.push(response);
                    extendConglomerates();
                    $scope.conglomerateGridOptions.dataSource.read();
                }
            });
        }

        function extendConglomerates() {
            $scope.conglomerates.forEach(c => {
                if (c.contractors) {
                    c.contractorList = c.contractors.map(x => x.name).join(", ");
                }
            });

            $scope.conglomerates = _.orderBy($scope.conglomerates, 'name')
        }

        function openConglomerateModal(formType, conglomerate = null) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant(formType === formTypes.add ? 'ADMIN_ADD_CONGLOMERATE' : 'ADMIN_EDIT_CONGLOMERATE'),
                    resizable: false,
                    visible: false,
                    width: 550
                },
                templateUrl: 'app/systemAdministration/conglomerate/conglomerate-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'conglomerateDetailsModalController',
                resolve: {
                    formType: () => formType,
                    conglomerate: () => conglomerate,
                }
            });
        }

        function deleteConglomerate(event) {
            const conglomerate = getConglomerate(event);
            const deleteModal = openDeleteModal();

            conglomerate && deleteModal.result.then(response => {
                if (response) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'deleteConglomerate',
                        destination: '#conglomerate-administration',
                        overlay: true
                    });

                    adminConglomerateService.deleteConglomerate(conglomerate.conglomerateId)
                        .then(result => result && initController())
                        .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'deleteConglomerate'));
                }
            });
        }

        function openDeleteModal() {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('G_DELETE'),
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('G_CONFIRM_DELETE', null, null);
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
        }
    }
})();
