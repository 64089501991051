(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ConsentController', ConsentController);

    function ConsentController($scope, $state, $windowInstance, authService, userService) {

        var authData = authService.getAuthData();

        $scope.userHasConsent = getConsent;
        $scope.acceptConsent = acceptConsent;
        $scope.rejectConsent = rejectConsent;

        init();
        function init() {
            getConsent();
        }

        function getConsent() {
            if (authData.userId) {
                userService.getConsentByUserId(authData.userId).then(function (result) {
                    $scope.consent = result;
                });
            }
            else {
                userService.getLatestConsent().then(function (result) {
                    $scope.consent = result;
                });
            }

        }
        function acceptConsent() {
            userService.addConsent(authData.userId, $scope.consent).then(function () {
                $windowInstance.close();
            });
        }

        function rejectConsent() {
            var kendoWindow = $('<div />').kendoWindow({
                title: 'Bekrefte',
                resizable: false,
                modal: true,
                width: 350
            });

            kendoWindow.data('kendoWindow')
                .content($('#confirmation').html())
                .center()
                .open();

            kendoWindow
                .find('.confirm-button,.cancel-button')
                .click(function () {
                    if ($(this).hasClass('confirm-button')) {
                        $windowInstance.close();
                        kendoWindow.data("kendoWindow").close();
                        authService.logout();
                        $state.go('login');
                    } else {
                        kendoWindow.data('kendoWindow').close();
                    }
                })
                .end();
        }
    }
})();
