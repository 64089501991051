angular.module('NaviaqWebApp').directive('stretchTest', [function () {
    'use strict';

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/report/directives/stretch-test.html',
        link: function (scope, element, attrs) {
        }
    };
}]);
