(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LogActionsController', logActionsController);

    logActionsController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$kWindow',
        '$translate',
        'authService',
        'logService',
        'baseDataService',
        'userService',
        'formTypes',
        'kendoUtility',
        'commonUtility',
        'htmlElementService',
        'logActionService',
        'sanitizeService'
    ];

    function logActionsController(
        $q,
        $rootScope,
        $scope,
        $kWindow,
        $translate,
        authService,
        logService,
        baseDataService,
        userService,
        formTypes,
        kendoUtility,
        commonUtility,
        htmlElementService,
        logActionService,
        sanitizeService
    ) {

        var logActionsGridHeight;
        $scope.logActionData = [];
        $scope.basisData = [];
        $scope.userData = [];
        $scope.userDataRequest = userDataRequest;
        $scope.addLogAction = addLogAction;
        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;

        $scope.toggleActiveState = false;

        $scope.toggleActiveRows = function() {
            var filter = $scope.toggleActiveState ? {} : { field: "status", operator: "equals", value: true };
            $scope.logActionsGridOptions.dataSource.filter(filter);
        };

        var logActionsDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.logActionData),
                update: e => logActionGridUpdate(e)
            },
            schema: {
                model: {
                    id: 'logActionId',
                    fields: {
                        description: { editable: true, validation: { required: true } },
                        LogActionType: { editable: true, validation: { required: true } },
                        departmentNames: { editable: false},
                        listId: { editable: true, validation: { required: false } },
                        defaultUserId: { editable: true },
                        userListNeeded: { editable: true, type: "boolean", editable: false },
                        isPrivate: { editable: true, type: "boolean", editable: false },
                        jobType: { editable: true },
                        hasValue: { editable: true, type: "boolean", editable: false },
                        valueName: { editable: true },
                        exportLogAction: { editable: true, type: "boolean", editable: false },
                        checkListItemsCount: { editable: false },
                        status: { type: "boolean", editable: false },
                        isCustomerDeviation: { editable: true, type: "boolean", editable: false },
                        isDescriptionRequired: { editable: true, type: "boolean", editable: false },
                    }
                }
            },
            filter: { field: "status", operator: "equals", value: true }
        });

        const logActionsGridOptions = langId => ({
            dataSource: logActionsDataSource,
            dataBound: function (e) {
                if (this.content.height() > logActionsGridHeight - this.content.offset().top) {
                    var newContentHeight = $('#logActions-view').height() - 5;
                    this.content.height(newContentHeight);
                }
            },
            animation: false,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            groupable: true,
            resizable: true,
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: {
                name: 'FilterMenu',
                extra: true
            },
            detailInit: detailInit,
            detailTemplate: kendo.template($('#operationLog-checklist-template').html()),
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'id',
                    hidden: true,
                    template: '<span data-event-id="#:logActionId#">#:#<span>'
                },
                {
                    headerTemplate: "<input type='checkbox' id='header-chb' class='header-checkbox'>",
                    template: function (dataItem) {
                        return `<input type='checkbox' id='${dataItem.logActionId}' class='row-checkbox'>`;
                    },
                    width: 45,
                    attributes: {class: "k-text-center"},
                    headerAttributes: {class: "k-text-center"},
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_DISPLAYNAME', null, null, langId),
                    width: 150,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return '<span kendo-tooltip k-content="\'{{ dataItem.description }}\'">{{dataItem.description}}</span>';
                    }
                },
                {
                    field: 'logActionType',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_TYPE', null, null, langId),
                    width: 150,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                            var logActionTypeObject = $scope.logActionTypes.filter(obj => {
                                return obj.value === dataItem.logActionType
                            })[0];
                            dataItem.logActionTypeText = logActionTypeObject.name;
                            dataItem.logActionType = logActionTypeObject.value;
                            return dataItem.logActionTypeText;
                        }
                        return dataItem.logActionTypeText
                    },
                    editor: logActionTypeDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_LOGACTION_GRID_TYPE', null, null, langId) + ": #=items[0] && items[0].logActionTypeText ? items[0].logActionTypeText : '' #"
                },
                {
                    field: 'departmentNames',
                    title: $translate.instant('G_DEPARTMENTS', null, null, langId),
                    width: 140,
                    groupHeaderTemplate: $translate.instant('G_DEPARTMENTS', null, null, langId) + ": #=items[0] && items[0].departmentNames ? items[0].departmentNames : '' #"
                },
                {
                    field: 'listId',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_LIST', null, null, langId),
                    width: 140,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                            var listBasisDataObject = $scope.basisData.filter(obj => {
                                return obj.id === dataItem.listId
                            })[0];
                            dataItem.listText = listBasisDataObject.description;
                            dataItem.listId = listBasisDataObject.id;
                            return dataItem.listText;
                        }
                        return dataItem.listId ? dataItem.listText : '';
                    },
                    editor: listBasisDataDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_LOGACTION_GRID_LIST', null, null, langId) + ": #=items[0] && items[0].listText ? items[0].listText : '' #"
                },
                {
                    field: 'defaultUserId',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_USERNAME', null, null, langId),
                    width: 140,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                            var assigneUserObject = $scope.userData.filter(obj => {
                                return obj.userId === dataItem.defaultUserId
                            })[0];
                            dataItem.UserName = assigneUserObject.displayName;
                            dataItem.defaultUserId = assigneUserObject.userId;
                            return assigneUserObject.displayName;
                        }
                        return dataItem.UserName ? dataItem.UserName : '';
                    },
                    editor: assigneUserDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_LOGACTION_GRID_USERNAME', null, null, langId) + ": #=items[0] && items[0].UserName ? items[0].UserName : '' #"
                },
                {
                    field: 'userListNeeded',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_ISUSERLISTNEEDED', null, null, langId),
                    width: '70px',
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.userListNeeded ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-user-list-needed-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    field: 'isPrivate',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_ISPRIVATE', null, null, langId),
                    width: 70,
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.isPrivate ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-isprivate-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    field: 'jobType',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_JOBTYPE', null, null, langId),
                    width: 100,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                            var jobtypeObject = $scope.jobTypes.filter(obj => {
                                return obj.value === dataItem.jobType
                            })[0];
                            dataItem.jobTypeText = jobtypeObject.name;
                            dataItem.jobType = jobtypeObject.value;
                            return jobtypeObject.name;
                        }
                        return dataItem.jobType ? dataItem.jobTypeText : '';
                    },
                    editor: jobTypeDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_LOGACTION_GRID_JOBTYPE', null, null, langId) + ": #=items[0] && items[0].jobTypeText ? items[0].jobTypeText : '' #"
                },
                {
                    field: 'hasValue',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_HASVALUE', null, null, langId),
                    width: 90,
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.hasValue ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-hasvalue-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    field: 'valueName',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_VALUENAME', null, null, langId),
                    width: 120
                },
                {
                    field: 'exportLogAction',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_EXPORT', null, null, langId),
                    width: 70,
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.exportLogAction ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-export-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    field: 'checkListItemsCount',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST', null, null, langId),
                    width: 70,
                    template: function (dateItem) {
                        return dateItem.checkListItems.length
                    }
                },
                {
                    field: 'status',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_ISACTIVE', null, null, langId),
                    width: 70,
                    filterable: false,
                    hidden: !authService.hasPermission('OperationLogAdmin'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.status === true ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-status-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    field: 'isCustomerDeviation',
                    title: $translate.instant('ADMIN_LOGACTION_IS_CUSTOMER_DEVIATION', null, null, langId),
                    width: 110,
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.isCustomerDeviation ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-isCustomerDeviation-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    field: 'isDescriptionRequired',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_ISDESCRIPTIONREQUIRED', null, null, langId),
                    width: 110,
                    hidden: !authService.hasModule('RequiredDescriptionInLog'),
                    template: dataItem => {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        const checked = dataItem.isDescriptionRequired ? 'checked' : '';
                        return `<div class="center-content"><input class="logaction-isdescriptionrequired-checkbox" type="checkbox" ${checked} /></div>`;
                    }
                },
                {
                    command: [
                        {
                            name: 'editLogAction',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                editLogAction(data.logActionId);
                            },
                            template: '<a class="k-grid-editLogAction"><i class="fas fa-edit"></i></a>'
                        }
                    ],
                    width: 60
                }
            ]
        });
        $scope.logActionsGridOptions = logActionsGridOptions($translate.use());

        //bind click event to the checkbox
        $("#logActionsGrid").on("click", ".row-checkbox", selectRow);

        $("#logActionsGrid").on("click", ".header-checkbox", selectAllRow);
        function selectAllRow(ev){
            var checked = ev.target.checked;
            $('.row-checkbox').each(function (idx, item) {
                if (checked) {
                    if (!($(item).closest('tr').is('.k-selected'))) {
                        $(item).click();
                    }
                } else {
                    if ($(item).closest('tr').is('.k-selected')) {
                        $(item).click();
                    }
                }
            });
        }

        $scope.selectedLogActionIds = [];
        //on click of the checkbox:
        function selectRow() {
            var checked = this.checked,
                row = $(this).closest("tr"),
                grid = $("#logActionsGrid").data("kendoGrid"),
                dataItem = grid.dataItem(row);

            if (checked) {
                $scope.$applyAsync(function () {
                    $scope.selectedLogActionIds.push(dataItem.logActionId);
                });

                //-select the row
                row.addClass("k-selected");

                var checkHeader = true;

                $.each(grid.items(), function (index, item) {
                    if (!($(item).hasClass("k-selected"))) {
                        checkHeader = false;
                    }
                });

                $("#header-chb")[0].checked = checkHeader;
            } else {
                //-remove selection
                $scope.$applyAsync(function () {
                    $scope.selectedLogActionIds = $scope.selectedLogActionIds.filter(x => x != dataItem.logActionId);
                });

                row.removeClass("k-selected");
                $("#header-chb")[0].checked = false;
            }
        }

        const checklistGridOptions = data => ({
            dataSource: {
                transport: {
                    read: e => e.success(data)
                },
                sort: {
                    field: 'description',
                    dir: 'asc'
                }
            },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS')
                },
            columns: [
                  {
                    field: 'checkListItemId',
                    hidden: true,
                    template: '<span data-event-id="#:checkListItemId#">#:#<span>'
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_DESCRIPTION'),
                    template: function (dataItem) {
                        return dataItem.description;
                    }
                },
                {
                    field: 'additionalInformation',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_INFO'),
                    template: function (dataItem) {
                        return dataItem.additionalInformation;
                    }
                },
                {
                    field: 'measurementUnitType',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_MEASUREMENTTYPE'),
                    template: '#: measurementUnitType != null? measurementUnitType.measurementUnitDescription : ""#'
                },
                {
                    width: 50,
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_ACTIVE'),
                    template:
                        '<span class="center-content"><input type="checkbox" #= isActive != null && isActive  ? \'checked="checked"\' : ""# disabled="disabled" /></span>'
                },
                {
                    command: [
                        {
                            name: 'editCheckListItem',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest("tr");
                                var parent = $(e.target).closest(".checklist-grid");
                                var data = parent.data("kendoGrid").dataItem(row).toJSON();
                                editCheckListItem(data.checkListItemId, parent);
                            },
                            template: '<a class="k-grid-editCheckListItem"><i class="fa fa-pencil-alt"></i></a>'
                        }
                    ],
                    width: 80
                }
            ]
        });

        $scope.$on('langChanged', (event, code) => {
            $scope.logActionsGridOptions = logActionsGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.logActionsGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#logActionsGrid')]);
        });

        initController();

        function initController() {
            $scope.authData = authService.getAuthData();
            var userDataRequestTask = $scope.userDataRequest();
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionsIndicator',
                destination: '#logActionsGrid',
                overlay: true
            });
            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });
            toggleChangesButtons(true);
            kendoUtility.calculateAndSetMaxWidth($('#administration-content-container'));
            addCheckboxChangeEventListener();
            $q.all([
                !authService.hasPermission('OperationLogAdmin') ? logService.getLogActionsFilteredByIsActiveField($scope.authData.contractorId) : logService.getLogActions($scope.authData.contractorId),
                logService.getBasisData($scope.authData.contractorId),
                userDataRequestTask,
                baseDataService.getMeasurementUnitTypesWebapi(),
                logService.getTypes(),
                logService.getJobTypes(),
            ]).then(function (result) {
                $scope.logActionData = result[0];
                $scope.basisData = result[1];
                $scope.userData = result[2];
                $scope.measurementUnitTypes = result[3];
                $scope.logActionTypes = result[4];
                $scope.jobTypes = result[5];
                _.forEach($scope.logActionData, function (item) {
                    extendItem(item);
                });
                $scope.logActionData = _.orderBy(
                    $scope.logActionData,
                    [
                        function (logAction) {
                            logAction.status = logAction.status === 0 ? true : false;
                            return logAction;
                        }
                    ],
                    ['asc']
                );
            }).finally(() => {
                $scope.logActionsGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'logActionsIndicator');
            });
        }

        function addCheckboxChangeEventListener() {
            $("#administration-content-container").on("change", ".logaction-status-checkbox", function (e) {
                var grid = $("#logActionsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.status = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });

            $("#administration-content-container").on("change", ".logaction-export-checkbox", function (e) {
                var grid = $("#logActionsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.exportLogAction = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });

            $("#administration-content-container").on("change", ".logaction-hasvalue-checkbox", function (e) {
                var grid = $("#logActionsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.hasValue = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });

            $("#administration-content-container").on("change", ".logaction-isprivate-checkbox", function (e) {
                var grid = $("#logActionsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.isPrivate = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });

            $("#administration-content-container").on("change", ".logaction-user-list-needed-checkbox", function (e) {
                var grid = $("#logActionsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.userListNeeded = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });

            $("#administration-content-container").on("change", ".logaction-isdescriptionrequired-checkbox", function (e) {
                var grid = $("#logActionsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.isDescriptionRequired = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });

            $("#administration-content-container").on("change", ".logaction-isCustomerDeviation-checkbox", function (e) {
                var grid = $("#logActionsGrid").data("kendoGrid")
                var dataItem = grid.dataItem($(e.target).closest("tr"));
                $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
                dataItem.isCustomerDeviation = this.checked;
                dataItem.dirty = true;
                toggleChangesButtons(false)
            });
        }

        function logActionTypeDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTTYPE'),
                    dataTextField: 'name',
                    dataValueField: 'value',
                    dataSource: {
                        data: $scope.logActionTypes
                    }
                });
        }

        function listBasisDataDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTLIST'),
                    dataTextField: 'description',
                    dataValueField: 'id',
                    dataSource: {
                        data: $scope.basisData
                    }
                });
        }

        function assigneUserDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTUSER'),
                    dataTextField: 'displayName',
                    dataValueField: 'userId',
                    dataSource: {
                        data: $scope.userData
                    }
                });
        }

        function jobTypeDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_LOGACTION_MODAL_SELECTJOBTYPE'),
                    dataTextField: 'name',
                    dataValueField: 'value',
                    dataSource: {
                        data: $scope.jobTypes
                    }
                });
        }

        function detailInit(e) {
            var checkListItemes = e.data.checkListItems;

            _.forEach(checkListItemes, function (item) {
                item.measurementUnitType = $scope.measurementUnitTypes.find(r => r.measurementUnitTypeId === item.measurementUnitTypeId);
            });

            $scope.checklistGridOptions = checklistGridOptions(checkListItemes);
            const element = e.detailRow.find('#check-list-grid');
            element.kendoGrid($scope.checklistGridOptions);
            var table = $(element);

            $(`#${e.data.logActionId}_add-checklist-item-btn`)
                .text($translate.instant('G_NEWITEM'))
                .click(() => addCheckListItem(e.data.logActionId, table));
        }

        function saveChanges() {
            $scope.logActionsGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true);
            $scope.logActionsGridOptions.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('#save-logaction-button');
            var cancelChangesButton = $('#cancel-logaction-button');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function userDataRequest() {
            var deferred = $q.defer();

            userService.getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId]}).then(function (users) {
                if (users && users.length > 0) {
                    deferred.resolve(users);
                } else {
                    deferred.resolve([]);
                }
            });
            return deferred.promise;
        }

        function extendItem(item) {

            item.logActionTypeText = logService.getType(item.logActionType);

            if (item.defaultUserId != null) {
                item.UserName = item.defaultUserId.asUserName();
            } else {
                item.defaultUserId = '';
            }
            if (item.listId != null) {
                item.listText = item.listId.asListText();
            } else {
                item.listId = '';
            }
            if (item.jobType != null) {
                item.jobTypeText = item.jobType.asJobTypeText();
            } else {
                item.jobType = '';
            }

            item.HasChecklist = item.checkListItems && item.checkListItems.length > 0;

            if (item.HasChecklist && item.checkListItems) {
                for (var i = 0; i < item.checkListItems.length; ++i) {
                    var checkListValue = item.checkListItems[i].checkListValue;

                    item.HasMissingChecklistItem = item.objectStatus !== 3;
                }

                item.checkListItems = _.orderBy(
                    item.checkListItems,
                    [
                        function (checkListItem) {
                            return checkListItem.description.toLowerCase();
                        }
                    ],
                    ['asc']
                );
                item.checkListItemsCount = item.checkListItems.length;
            }

            item.departmentNames = item.departments && item.departments.length > 0 ? item.departments.map(x => x.name).join(', ') : '';
        }

        function updateLogActions() {
            var deferred = $q.defer();
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionsIndicator',
                destination: '#logActionsGrid',
                overlay: true
            });

            logService.getLogActions($scope.authData.contractorId).then(
                function (items) {
                    $scope.logActionData = items;
                    _.forEach($scope.logActionData, function (item) {
                        extendItem(item);
                    });
                    $scope.logActionData = _.orderBy(
                        $scope.logActionData,
                        [
                            function (logAction) {
                                logAction.status = logAction.status === 0 ? true : false;
                                return logAction;
                            }
                        ],
                        ['asc']
                    );

                    $scope.logActionsGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'logActionsIndicator');
                    deferred.resolve();
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function addLogAction() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_LOGACTION_ADD'),
                    resizable: true,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/administration/logActions/logAction-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LogActionDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.add;
                    },
                    logAction: function () {
                        return null;
                    },
                    lists: function () {
                        return $scope.basisData;
                    },
                    users: function () {
                        return $scope.userData;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateLogActions();
                }
            });
        }

        function editLogAction(logActionId) {
            var logAction = _.find($scope.logActionData, function (l) {
                return l.logActionId == logActionId;
            });

            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_LOGACTION_EDIT'),
                    resizable: true,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/administration/logActions/logAction-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LogActionDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.edit;
                    },
                    logAction: function () {
                        return logAction;
                    },
                    lists: function () {
                        return $scope.basisData;
                    },
                    users: function () {
                        return $scope.userData;
                    }
                }
            });
            windowInstance.result.then(function (result) {
                if (result) {
                    updateLogActions();
                }
            });
        }

        function addCheckListItem(logActionId, table) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_LOGACTION_ADDCHECKLIST'),
                    resizable: true,
                    width: 445,
                    visible: false
                },
                templateUrl: 'app/administration/logActions/check-list-item-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CheckListItemDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.add;
                    },
                    checkListItem: function () {
                        return null;
                    },
                    measurementUnitTypes: function () {
                        return $scope.measurementUnitTypes;
                    },
                    logActionId: function () {
                        return logActionId;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateChecklistItem(logActionId, table);
                }
            });
        }

        function editCheckListItem(checkListItemId, table) {
            var checkListItem = null;
            for (var i = 0; i < $scope.logActionData.length; i++) {
                var la = $scope.logActionData[i];
                checkListItem = _.find(la.checkListItems, function (cli) {
                    return cli.checkListItemId == checkListItemId;
                });
                if (checkListItem != null) {
                    break;
                }
            }

            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_LOGACTION_EDITCHECKLIST'),
                    resizable: true,
                    height: 290,
                    width: 445,
                    visible: false
                },
                templateUrl: 'app/administration/logActions/check-list-item-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CheckListItemDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.edit;
                    },
                    checkListItem: function () {
                        return checkListItem;
                    },
                    measurementUnitTypes: function () {
                        return $scope.measurementUnitTypes;
                    },
                    logActionId: function () {
                        return checkListItem.logActionId;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                  updateChecklistItem(checkListItem.logActionId, table);
                }
            });
        }

        $scope.openDepartmentChangeModal = function(){
            let distinctDepartmentIds = [];
            if($scope.selectedLogActionIds){
                const selectedLogActions = $scope.logActionData.filter(x => $scope.selectedLogActionIds.includes(x.logActionId));
                distinctDepartmentIds = findDistinctElementsInLists(selectedLogActions.map(x => x.departments.map(d => d.departmentId)));
            }

            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_LOGACTION_CHANGE_DEPARTMENTS'),
                    resizable: true,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/administration/logActions/logAction-department-change-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'logActionDepartmentChangeModalController',
                resolve: {
                    selectedLogActionIds: function () {
                        return $scope.selectedLogActionIds;
                    },
                    selectedDepartmentIds: function () {
                        return distinctDepartmentIds;
                    },
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    $scope.selectedLogActionIds = [];
                    updateLogActions();
                }
            });
        }

        function findDistinctElementsInLists(arr){
            if (!Array.isArray(arr) || arr.length === 0) {
                return [];
            };

            return arr.reduce((distinctIds, current) => {
              if (!distinctIds.includes(current))
                {
                    distinctIds.push(current);
                }
              return distinctIds;
            }, []).flat();
          }

        function updateChecklistItem(logActionId, table){
            logActionService.getCheckListsByLogActionId(logActionId).then(function(data){
                var checklists = data
                const element = table;
                element.kendoGrid('destroy').empty();
                $scope.checklistGridOptions = checklistGridOptions(checklists);
                element.kendoGrid($scope.checklistGridOptions);

                // update checklist of logaction
                var currentLogAction = $scope.logActionData.find(l => l.logActionId == logActionId);
                if(currentLogAction){
                    currentLogAction.checkListItems = checklists;
                }
            });
        }

        String.prototype.asUserName = function () {
            var searchedId = this,
                user = _.find($scope.userData, function (o) {
                    return o.userId === searchedId;
                });

            if (user) {
                return user.displayName;
            }

            return this;
        };

        String.prototype.asListText = function () {
            var searchedId = this,
                list = _.find($scope.basisData, function (o) {
                    return o.id === searchedId;
                });

            if (list) {
                return list.description;
            }

            return this;
        };

        Number.prototype.asJobTypeText = function () {
            var searchedId = this,
                jobType = _.find($scope.jobTypes, function (o) {
                    return o.value === searchedId;
                });

            if (jobType) {
                return jobType.name;
            }

            return this;
        };

        function logActionGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionsGridAdministrationGrid',
                destination: '#logActionsGrid'
            });
            var dataItem = e.data;
            var logAction = {
                description: dataItem.description,
                logActionId: dataItem.logActionId,
                logActionType: dataItem.logActionType,
                listId: dataItem.listId ? dataItem.listId : null,
                defaultUserId: dataItem.defaultUserId ? dataItem.defaultUserId : null,
                userListNeeded: dataItem.userListNeeded,
                isPrivate: dataItem.isPrivate,
                jobType: dataItem.jobType ? dataItem.jobType : null,
                hasValue: dataItem.hasValue,
                valueName: dataItem.valueName,
                isCustomerDeviation: dataItem.isCustomerDeviation,
                exportLogAction: dataItem.exportLogAction,
                status: (dataItem.status === true ? 0 : 1),
                isDescriptionRequired: authService.hasModule('RequiredDescriptionInLog') ? dataItem.isDescriptionRequired : null,
                contractorId: $scope.authData.contractorId
            };

            logService.updateLogAction(logAction)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                    } else {
                        e.error();
                    }
                }, function (error) {
                    e.error(error);
                }).finally(function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'logActionsGridAdministrationGrid');
                });
        }
    }
})();
