(function () {
    'use strict';

    angular.module('NaviaqWebApp').directive('finalReportFooterAqsDirective', finalReportFooterAqsDirective);

    finalReportFooterAqsDirective.$inject = [];
    function finalReportFooterAqsDirective() {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/report/finalReportComponents/final-report-footer-aqs.html',
            scope: {
                data: '=data'
            },
            link: function (scope, element, attrs) {
            },
        };
    }
})();
