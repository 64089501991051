(function() {
    'use strict';

    angular.module('NaviaqWebApp').factory('logActionService', logActionService);

    logActionService.$inject = ['$q', '$http', 'serviceUrls'];

    function logActionService($q, $http, serviceUrls) {
        const logActionApiUrl = `${serviceUrls.webApiBaseUrl}/logaction`;
        const checklistApiUrl = `${serviceUrls.webApiBaseUrl}/checklist`;

        return {
            getActiveLogActions,
            getActiveLogActionsAsDropdownByFilter,
            getCheckListsByLogActionId,
            getByContractorIdAndLogActionType
        };

        function getActiveLogActions(contractorId){
            const deferred = $q.defer(),
                url = `${logActionApiUrl}/active?contractorId=${contractorId}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getActiveLogActionsAsDropdownByFilter(filter){
            const deferred = $q.defer(),
                url = `${logActionApiUrl}/byFilterAsDropdown`;

            $http
                .post(url, filter)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getCheckListsByLogActionId(logActionId) {
            const deferred = $q.defer(),
                requestUrl = `${checklistApiUrl}/byLogActionId?logActionId=${logActionId}`;

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getByContractorIdAndLogActionType(contractorId, logActionType) {
            const deferred = $q.defer(),
                requestUrl = `${logActionApiUrl}?contractorId=${contractorId}&logActionType=${logActionType}`;

            $http
                .get(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
