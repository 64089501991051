(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LandbaseAdministrationController', LandbaseAdministrationController);

    LandbaseAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'landBaseService',
        'mapUtility',
        'commonUtility',
        'htmlElementService',
        'sanitizeService',
        'confirmDialogService',
    ];

    function LandbaseAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        landBaseService,
        mapUtility,
        commonUtility,
        htmlElementService,
        sanitizeService,
        confirmDialogService,
    ) {
        const defaultGroupFilter = [];
        $scope.landBases = {};
        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#landbase-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#landbase-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });


        var landbaseAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success($scope.landBases),
                update: e => onLandbaseGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'locationNumber',
                    fields: {
                        name: { editable: true},
                        x: { editable: true},
                        y: { editable: true}
                    }
                },
            }
        });

        const landbaseAdministrationGridOptions = langId => ({
            dataSource: landbaseAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_LANDBASES_GRID_NAME'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.name;
                    },
                },
                {
                    field: 'y',
                    title: $translate.instant('ADMIN_LANDBASES_GRID_LATITUDE'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }

                        return dataItem.yDegree + '° ' +  dataItem.yMinutes + 'N';
                    },
                    editor: landBaseLatitudeEditor
                },
                {
                    field: 'x',
                    title: $translate.instant('ADMIN_LANDBASES_GRID_LONGITUDE'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.xDegree + '° ' +  dataItem.xMinutes;
                    },
                    editor: landBaseLongitudeEditor
                },
                {
                    command: [
                        {
                            name: 'editLandbase',
                            click: e => editLandbase(e),
                            template: '<a class="k-grid-editLandbase"><i class="fas fa-edit"></i></a>'
                        },
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true
        });
        $scope.landbaseAdministrationGridOptions = landbaseAdministrationGridOptions($translate.use());
        $scope.landbases = [];
        $scope.addNewLandbase = addNewLandbase;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editLandbase = editLandbase;

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.landbaseAdministrationGridOptions = landbaseAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.landbaseAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#landbase-administration-grid')]);
            langId = code;
        });

        initController();
        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'landbaseAdministrationGrid',
                destination: '#landbase-administration-grid'
            });
            htmlElementService.checkIfElementExists("filter-group")
            .then(function (result) {
                commonUtility.calculateAdminTabMenuWidth();
            });
            toggleChangesButtons(true)
            let requests = {};
            requests.landBases = landBaseService.getLandBasesByContractorId($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    $scope.landBases = data.landBases;
                    extendLandBases($scope.landBases);
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.landbaseAdministrationGridOptions.dataSource.read();
                });
            $rootScope.$broadcast('hideBusyIndicator', 'landbaseAdministrationGrid');
        }

        function extendLandBases(landBases)
        {
            _.forEach(landBases, function (landBaseObj) {
               var coord = mapUtility.convertDecDegreesToDegreesMins(landBaseObj.x);
               landBaseObj.xDegree = coord.deg;
               landBaseObj.xMinutes = coord.min.toFixed(5).replace('.', ',');

               var coord = mapUtility.convertDecDegreesToDegreesMins(landBaseObj.y);
               landBaseObj.yDegree = coord.deg;
               landBaseObj.yMinutes = coord.min.toFixed(5).replace('.', ',');
            });
        }

        function addNewLandbase() {
            const window = openLandBaseDetailsModal(formTypes.add);
            window.result.then(result => {
                if (result) {
                    $scope.landBases = $scope.landBases.concat([result]);
                    $scope.landbaseAdministrationGridOptions.dataSource.read();
                }
            });
        }

        function editLandbase(event){
            const window = openLandBaseDetailsModal(formTypes.edit, getLandBaseFromGrid(event));
            window.result.then(result => handleUpdate(result));
        }

        function handleUpdate(landBase) {
            if (landBase) {
                $scope.landBases = $scope.landBases.map(l => (l.locationNumber === landBase.locationNumber ? landBase : l));
                $scope.landbaseAdministrationGridOptions.dataSource.read();
            }
        }

        function getLandBaseFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#landbase-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function openLandBaseDetailsModal(formType, landBase) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: landBase ? landBase.name : $translate.instant('ADMIN_LANDBASES_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/administration/landbase/landbase-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LandBaseDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    landBase: () => landBase,
                }
            });
        }

        function saveChanges() {
            $scope.landbaseAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.landbaseAdministrationGridOptions.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-landbase');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-landbase');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function landBaseLatitudeEditor(container, options) {
            var decimalDegree = $('<input name="yDegree"/>');
            var degreeMarker = $('<span class="degreeMarker" name="yDegreeMarker">° </span>');
            var minutes = $('<input name="yMinutes"/>');
            var minutesMarker = $('<span class="minutesMarker" name="yMinutesMarker">N </span>');

            decimalDegree.appendTo(container);
            degreeMarker.appendTo(container);
            minutes.appendTo(container);
            minutesMarker.appendTo(container);
        }


        function landBaseLongitudeEditor(container, options) {
            var decimalDegree = $('<input name="xDegree"/>');
            var degreeMarker = $('<span class="degreeMarker" name="xDegreeMarker">° </span>');
            var minutes = $('<input name="xMinutes"/>');

            decimalDegree.appendTo(container);
            degreeMarker.appendTo(container);
            minutes.appendTo(container);
        }

        function ConvertCoordBack(degree, minutes)
        {
            if (minutes != 0){
                return degrees + minutes / 60;
            }
            else{
                return degrees;
            }
        }

        function onLandbaseGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'landbaseAdministrationGrid',
                destination: '#landbase-administration-grid'
            });
            var landBaseObj = e.data;
            landBaseObj.x = mapUtility.convertDegreesMinsToDecDegrees(landBaseObj.xDegree, landBaseObj.xMinutes.toString().replace(',', '.'));
            landBaseObj.y = mapUtility.convertDegreesMinsToDecDegrees(landBaseObj.yDegree, landBaseObj.yMinutes.toString().replace(',', '.'));

            landBaseService.updateLandBase(landBaseObj)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);

                        if(response == -1){
                            confirmDialogService.openWarningDialog($translate.instant('ADMIN_LANDBASES_WARNING_MSG_CONENT'), $translate.instant('ADMIN_LANDBASES_WARNING_MSG_TITLE'));
                        }

                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'landbaseAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'landbaseAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'landbaseAdministrationGrid');
                });

        }
    }
})();
