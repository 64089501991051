(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('DocumentsController', DocumentsController);

    function DocumentsController($q, $rootScope, $scope, authService, treeUtility, itemtreeKeys, itemTreeService, itemStatus, serviceUrls, mediaService) {
        var documentTreeHeight;
        $scope.showArchived = false;

        $scope.statusFilterOptions = {
            dataSource: new kendo.data.DataSource({
                data: [{ id: 1, text: 'Vis alle' }, { id: 2, text: 'Aktiv' }, { id: 3, text: 'Arkivert' }]
            }),
            dataTextField: 'text',
            dataValueField: 'id',
            change: statusFilterChanged
        };

        $scope.itemTreeListOptions = {
            scrollable: true,
            dataBinding: function () {
                documentTreeHeight = $('#my-documents').height();
            },
            dataSource:  {
                    transport: {
                        read: readDocuments
                    },
                schema: {
                    model: {
                        id: 'id',
                        fields: {
                            id: { type: 'string', editable: false, nullable: false },
                            parentId: { field: "ParentId", nullable: true }
                        }
                    }
                }
            },
            sortable: true,
            filterable: true,
            messages: {
                noRows: 'Ingen treff'
            },
            columns: [
                { field: 'description', title: 'Beskrivelse', width: 400, template: '<span ng-if="#:!hasDocumentUrl#">#: description#</span><a href="#:documentUrl#" ng-if="#:hasDocumentUrl#">#: description#</a>' },

                { field: 'validFrom', title: 'Dato', width: 100 },
                { field: 'validTo', title: 'Utgår', width: 100 },
                { field: 'categoryName', title: 'Type', width: 100 },
                { field: 'warningUserName', title: 'Varsel bruker', width: 150 },
                { field: 'warningTime', title: 'Varsel tid', width: 100  },
                { field: 'warnUser', filterable: { extra: false } , title: 'Varse', template: '<span class="center-content"><input type="checkbox" #= warnUser != null && warnUser  ? \'checked="checked"\' : ""# disabled="disabled" /></span>', width: 70  },
                { field: 'comment', title: 'Kommentar' },
                { field: 'status', title: 'Status', width: 100 }
            ]
        };

        $scope.toggleArchived = toggleArchived;

        kendo.culture('no-NO');

        function toggleArchived() {
            $scope.showArchived = !$scope.showArchived;

            $scope.itemTree.dataSource.read();
            $scope.itemtree.refresh();
        }

        //Private functions
        function readDocuments(e) {
            $rootScope.$broadcast('showMainBusyIndicator', 'userDocumentsIndicator');

            itemTreeService.getDocumentsByUserId($scope.authData.contractorId, $scope.authData.userId).then(function (items) {
                items = _.filter(items, function(item) {
                    if (item.status === 1 && !$scope.showArchived) {
                        return false;
                    }

                    return true;
                });

                _.forEach(items, function (item) {
                    extendItem(item);
                });

                e.success(items);

                $rootScope.$broadcast('hideBusyIndicator', 'userDocumentsIndicator');
            }, function () {
                e.success([]);

                $rootScope.$broadcast('hideBusyIndicator', 'userDocumentsIndicator');
            });
        }

        function extendItem(item) {
            if (item.validityInterval !== null) {
                if (item.validityInterval.validTo === '/Date(-2208992400000+0100)/') {
                    item.validTo = '';
                }
                else {
                    item.validTo = moment(item.validityInterval.validTo).format('DD.MM.YYYY');
                }
                if (item.validityInterval.validFrom === '/Date(-2208992400000+0100)/') {
                    item.validFrom = '';
                }
                else {
                    item.validFrom = moment(item.validityInterval.validFrom).format('DD.MM.YYYY');
                }

                item.warningUserName = item.validityInterval.warningUser ? item.validityInterval.warningUser.displayName : '';
                if (item.validityInterval.warningTime !== null) {
                    item.warningTime = item.validityInterval.warningTime;

                    if (item.warningTime > 1) {
                        item.warningTime += " dager";
                    } else {
                        item.warningTime += " dag";
                    }
                }

                item.warnUser = item.validityInterval.warnUser ? item.validityInterval.warnUser : false;
                item.categoryName = getItemCategoryText(item.category.name);

                //Add document link url to items
                if (item.files && item.files.length > 0) {
                    var files = _.orderBy(item.files, [function (file) {
                        return new Date(parseInt(file.createdOn.substr(6)));
                    }], ['asc']);

                    var documentUrl = mediaService.createMediaUrl(files[0].mediaId);

                    item.hasDocumentUrl = true;
                    item.documentUrl = documentUrl;
                } else {
                    item.documentUrl = '';
                    item.hasDocumentUrl = false;
                }
            }
            else {
                item.validFrom = '';
                item.validTo = '';
                item.warningUserName = '';
                item.warningTime = '';
                item.warnUser = false;
                item.documentUrl = '';
                item.hasDocumentUrl = false;
            }
            item.status = getItemStatusText(item.status);
        }

        function getItemStatusText(status) {
            switch (status) {
                case itemStatus.Active:
                    return 'Aktiv';
                case itemStatus.Archived:
                    return 'Arkivert';
                default:
                    return '';
            }
        }

        function getItemCategoryText(categoryName) {
            switch (categoryName) {
                case 'Document':
                    return 'Dokument';
                case 'Course':
                    return 'Kurs';
                case 'Certificate':
                    return 'Sertifikater';
                default:
                    return categoryName;
            }
        }

        function statusFilterChanged(e) {
            var currentFilter = $scope.itemTreeListOptions.dataSource.filter(),
                statusTextFilter = '';

            if (e.sender.value() !== '1') {
                var selectedItem = _.find(e.sender.dataItems(), { id: parseInt(e.sender.value()) });
                statusTextFilter = selectedItem.text;
            }

            var newFilter = { field: "Status", operator: "startswith", value: statusTextFilter };
            if (!currentFilter) {
                $scope.itemTreeListOptions.dataSource.filter(newFilter);
            } else {
                var lineDescFilter = _.find(currentFilter.filters, { field: 'Status' });
                if (lineDescFilter) {
                    lineDescFilter.value = statusTextFilter;
                } else {
                    currentFilter.filters.push(newFilter);
                }

                $scope.itemTreeListOptions.dataSource.filter(currentFilter);
            }
        }
    }
})();
