(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('jobTitleService', jobTitleService);

    jobTitleService.$inject = ['$q', '$http', 'serviceUrls'];

    function jobTitleService($q, $http, serviceUrls) {
        const jobTitleBaseApiUrl = `${serviceUrls.webApiBaseUrl}/jobTitle`;

        return {
            getJobTitlesbyContractorId,
            getJobTitleById,
            addJobTitle,
            updateJobTitle,
            getNextSequenceNumberByContractorId,
            deleteJobTitle,
        };

        function getJobTitlesbyContractorId(contractorId, withoutRoles = true) {
            const deferred = $q.defer();
            const url = `${jobTitleBaseApiUrl}?contractorId=${contractorId}&withoutRoles=${withoutRoles}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getJobTitleById(jobTitleId) {
            const deferred = $q.defer();
            const url = `${jobTitleBaseApiUrl}/byJobTitleId?jobTitleId=${jobTitleId}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }




        function addJobTitle(jobTitle) {
            const deferred = $q.defer();
            const url = `${jobTitleBaseApiUrl}`;

            $http
                .post(url, jobTitle)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateJobTitle(jobTitle) {
            const deferred = $q.defer();
            const url = `${jobTitleBaseApiUrl}`;

            $http
                .put(url, jobTitle)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getNextSequenceNumberByContractorId(contractorId){
            const deferred = $q.defer();
            const url = `${jobTitleBaseApiUrl}/nextSequenceNumber?contractorId=${contractorId}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteJobTitle(jobTitleId) {
            const deferred = $q.defer();
            const url = `${jobTitleBaseApiUrl}?jobTitleId=${jobTitleId}`;

            $http
                .delete(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
