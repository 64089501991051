(function() {
    'use strict';

    angular.module('NaviaqWebApp').controller('EquipmentMoveModalController', equipmentMoveModalController);

    equipmentMoveModalController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$windowInstance',
        '$stateParams',
        '$translate',
        'equipment',
        'itemtreeKeys',
        'departmentService',
        'itemTreeService',
        'userService',
        'treeUtility',
        'equipmentJobDefinitionService',
        '$kWindow'
    ];

    function equipmentMoveModalController(
        $rootScope,
        $scope,
        $q,
        $windowInstance,
        $stateParams,
        $translate,
        equipment,
        itemtreeKeys,
        departmentService,
        itemTreeService,
        userService,
        treeUtility,
        equipmentJobDefinitionService,
        $kWindow
    ) {
        $scope.equipment = equipment;
        $scope.selectedVessel = null;
        $scope.selectedCategory = null;

        $scope.vesselDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => {
                        departmentService
                            .getDepartmentsWebapi($scope.authData.contractorId)
                            .then(data => {
                                data = data.filter(d => d.departmentId !== $stateParams.DepartmentId);
                                data = _.orderBy(data,'name');
                                e.success(data);
                            })
                            .catch(() => e.success([]));
                    }
                }
            }),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            optionLabel: $translate.instant('EQUIPMENT_MOVE_MODAL_TOVESSEL_SELECT'),
            change: () => {
                $scope.categoryDropdownOptions.dataSource.read();
                $scope.parentDropdownOptions.dataSource.read();
            }
        };
        $scope.categoryDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: getCategories
                }
            }),
            dataValueField: 'id',
            dataTextField: 'name',
            optionLabel: $translate.instant('EQUIPMENT_MOVE_MODAL_TOCATEGORY_SELECT'),
            noDataTemplate: $translate.instant('G_NODATA'),
            enable: false,
            change: () => $scope.parentDropdownOptions.dataSource.read()
        };

        $scope.parentDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: getParentItems
                }
            }),
            dataValueField: 'id',
            dataTextField: 'description',
            optionLabel: $translate.instant('EQUIPMENT_MOVE_MODAL_TOITEM_SELECT'),
            noDataTemplate: $translate.instant('G_NODATA'),
            enable: false
        };

        $scope.warningUserDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: getUsers
                }
            }),
            optionLabel: $translate.instant('EQUIPMENT_MOVE_MODAL_WARN_USER_SELECT'),
            dataValueField: 'userId',
            dataTextField: 'displayName',
            noDataTemplate: $translate.instant('G_NODATA')
        };

        $scope.close = close;
        $scope.validateForm = validateForm;

        initController();

        function initController() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'equipmentMoveBusyIndicator',
                destination: '#equipment-move-form'
            });

            var requests = {
                getEquipmentJobDefinitionsByItemId: equipmentJobDefinitionService.getEquipmentJobDefinitionsByItemId(equipment.id)
            };

            $q.all(requests)
            .then(result => {
                $scope.equipmentJobDefinitions = result.getEquipmentJobDefinitionsByItemId.filter(j =>!j.validTo || (moment(j.validTo) > moment()));
            })
            .catch(error => console.error(error))
            .finally(() => {
                $rootScope.$broadcast('hideBusyIndicator', 'equipmentMoveBusyIndicator');
            });
        }

        function close() {
            $windowInstance.close();
        }

        function validateForm() {
            if ($scope.validator.validate()) {
                var moveOptions = {
                    ItemId: $scope.equipment.id,
                    DepartmentId: $stateParams.DepartmentId,
                    RelatedEntityId: $scope.selectedVessel,
                    ToCategoryId: $scope.selectedCategory,
                    ToParentItemId: $scope.selectedParent || null,
                    WarnUserId: $scope.selectedUser || null
                };

                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'equipmentMoveIndicator',
                    destination: '.k-window-content',
                    overlay: true
                });

                if($scope.equipmentJobDefinitions && $scope.equipmentJobDefinitions.length > 0){
                    var windowInstance = $kWindow.open({
                        options: {
                            modal: true,
                            movable: false,
                            resizable: false,
                            visible: false,
                            width: 550,
                            title: $translate.instant('EQUIPMENT_MOVE_MODAL_CONFIRM_TITLE'),
                        },
                        templateUrl: 'app/shared/popups/confirm-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ConfirmModalController',
                        resolve: {
                            content: function () {
                                return $translate.instant('EQUIPMENT_MOVE_MODAL_CONFIRM_CONTENT');
                            },
                            primaryBtnText: function () {
                                return $translate.instant('G_YES');
                            },
                            secondaryBtnText: function () {
                                return $translate.instant('G_NO');
                            }
                        }
                    });

                    windowInstance.result.then(function (response) {
                        var stayActive = response;
                        itemTreeService.moveItem(moveOptions, stayActive)
                        .then(data => $windowInstance.close(data))
                        .catch(error => console.error(error))
                        .finally(() => {
                            $rootScope.$broadcast('hideBusyIndicator', 'equipmentMoveIndicator');
                        });
                    });
                } else {
                    itemTreeService.moveItem(moveOptions, false)
                        .then(data => $windowInstance.close(data))
                        .catch(error => console.error(error))
                        .finally(() => {
                            $rootScope.$broadcast('hideBusyIndicator', 'equipmentMoveIndicator');
                        });
                }

            }
        }

        function getCategories(e) {
            if ($scope.selectedVessel) {
                itemTreeService
                    .getItemCategoryTreeByKey(
                        itemtreeKeys.Equipment,
                        $scope.authData.contractorId,
                        $scope.selectedVessel
                    )
                    .then(data => {
                        let categories = treeUtility.convertTreeToList(data, 'children', 'id');
                        categories = _.filter(categories, c => c.keyName !== 'EQUIP');

                        $scope.topCategories = categories;
                        e.success(_.sortBy(categories, 'Name'));
                    })
                    .catch(() => e.success([]))
                    .finally(() => {
                        $('#item-category')
                            .data('kendoDropDownList')
                            .enable(true);
                    });
            } else {
                e.success([]);
            }
        }

        function getParentItems(e) {
            if ($scope.selectedVessel && $scope.selectedCategory) {
                itemTreeService.getItemForestByCategoryId(
                    $scope.selectedCategory,
                    $scope.authData.contractorId,
                    $scope.selectedVessel
                ).then(data => {
                    let items = _.orderBy(data, 'description');

                    e.success(items);
                }).finally(() => {
                    $('#item-parent')
                        .data('kendoDropDownList')
                        .enable(true);
                });
            } else {
                e.success([]);
            }
        }

        function getUsers(e) {
            userService
                .getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId]})
                .then(users => e.success(users))
                .catch(() => e.success([]));
        }
    }
})();
