angular.module('NaviaqWebApp').directive('busyIndicator', ['$translate', function($translate) {
    'use strict';

    var indicatorMap = new Map();

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/shared/directives/busy-indicator.html',
        link: function (scope, element) {
            scope.$on('showBusyIndicator', showBusyIndicator);

            scope.$on('showMainBusyIndicator', function (event, id) {
                showBusyIndicator(event, {
                    id: id,
                    message: $translate.instant('G_LOADING_DATA'),
                    destination: '#main-view',
                    overlay: true,
                    positionClass: 'centered'
                });
            });

            scope.$on('hideBusyIndicator', function (event, id) {
                indicatorMap.forEach(function (value, key, map) {
                    var indicators = value;
                    for (var i = 0; i < indicators.length; ++i) {
                        var currentIndicator = indicators[i];
                        if (currentIndicator && currentIndicator.id === id) {
                            var modelId = currentIndicator.model.id + 'Indicator',
                                hasIndicator = $(currentIndicator.model.destination).find(modelId).length > 0;

                            if (currentIndicator.busyIndicator) {
                                currentIndicator.busyIndicator.remove();
                            }

                            indicators.splice(i, 1);
                            if (indicators.length > 0 && hasIndicator) {
                                $(currentIndicator.model.destination).append(indicators[indicators.length - 1].busyIndicator);
                            }

                            break;
                        }
                    }

                    indicatorMap.set(key, indicators);
                });
            });

            scope.$on('hideAllBusyIndicator', function (event) {
                $('.busy-indicator.overlay').remove();
            });

            function showBusyIndicator(event, model) {
                var key = getKey(model),
                    indicators = [];

                if (indicatorMap.has(key)) {
                    indicators = indicatorMap.get(key);
                }

                var busyIndicator = createBusyIndicator(model);
                indicators.push({
                    id: model.id,
                    busyIndicator: busyIndicator,
                    model: model
                });

                indicatorMap.set(key, indicators);

                if (indicators.length === 1) {
                    $(model.destination).append(busyIndicator);
                }
            }

            function getKey(model) {
                var keyObject = {
                    dest: model.destination,
                    posClass: model.positionClass,
                    posStyle: model.positionStyle
                };

                return JSON.stringify(keyObject);
            }

            function createBusyIndicator(model) {
                var modelId = model.id + 'Indicator',
                    busyIndicator = $('#' + modelId);

                if (busyIndicator.length === 0) {
                    busyIndicator = element.clone();
                }

                busyIndicator.id = model.id;
                busyIndicator.attr('id', modelId);
                busyIndicator.find('.busy-indicator-text').text(model.message || $translate.instant('G_LOADING_DATA'));

                busyIndicator.removeClass('hidden');
                if (model.overlay === false) {
                    busyIndicator.removeClass('overlay');
                } else {
                    busyIndicator.addClass('overlay');
                }
                if (model.positionStyle) {
                    busyIndicator.find('.busy-indicator-box').css(model.positionStyle);
                } else if (model.positionClass) {
                    busyIndicator.find('.busy-indicator-box').addClass(model.positionClass);
                } else {
                    busyIndicator.find('.busy-indicator-box').addClass('centered');
                }

                return busyIndicator;
            }
        }
    };
}]);
