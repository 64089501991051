(function () {
    'use strict';
    angular.module('NaviaqWebApp').controller('departmentStatusModalController', departmentStatusModalController);
    departmentStatusModalController.$inject = ['$scope', '$rootScope', '$translate', '$kWindow', '$windowInstance', 'department', 'DepartmentStatus', 'departmentService'];

    function departmentStatusModalController($scope, $rootScope, $translate, $kWindow, $windowInstance, department, DepartmentStatus, departmentService) {
        $scope.department = null;
        $scope.reason = '';

        $scope.departmentStatusDropdownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success(getDepartmentStatuses())
                }
            }),
            dataValueField: 'value',
            dataTextField: 'text'
        };

        initController();
        function initController() {
            $scope.department = department;
            $scope.selectedDepartmentStatusId = department.status;
        }

        $scope.save = () => {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'departmentStatusChangeIndicator',
                destination: '.department-status-edit-modal',
                overlay: true,
            });

            const statusDto = {
                departmentId: $scope.department.departmentId,
                reason: $scope.reason,
                status: $scope.selectedDepartmentStatusId
            };

            if (statusDto.status !== department.status) {
                departmentService.updateStatus(statusDto).then(() => {
                    $rootScope.$broadcast(
                        'hideBusyIndicator',
                        'departmentStatusChangeIndicator'
                    );

                    const window = $kWindow.open({
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        templateUrl: 'app/department/department-equipment-job-status-modal.html',
                        controller: 'departmentEquipmentJobStatusModal',
                        resolve: {
                            departmentId: () => $scope.department.departmentId,
                            status: () => statusDto.status,
                        },
                        options: {
                            actions: [],
                            modal: true,
                            draggable: false,
                            movable: false,
                            resizable: false,
                            visible: false,
                            width: 800,
                            title: $scope.selectedDepartmentStatusId == DepartmentStatus.Active ?
                            $translate.instant('EQUIPMENT_JOB_TITLE_ACTIVE_JOBS') :
                            $translate.instant('EQUIPMENT_JOB_TITLE_PAUSE_JOBS'),
                        }
                    });

                    window.result.then(result => {
                        if (result) {
                            $scope.close(true);
                        }
                    });
                });
            } else {
                $scope.close(true);
            }
        }

        $scope.close = (result = false) => {
            $windowInstance.close({ result: result });
        }

        function getDepartmentStatuses() {
            return Object.entries({
                Inactive: 0,
                Active: 1,
                Paused: 2,
            }).map(e => {
                return {
                    value: e[1],
                    text: getStatusNameByValue(e[1])
                };
            });
        }

        function getStatusNameByValue(value)
        {
            if(value === 0){
                return $translate.instant('G_INACTIVE');
            } else if(value === 1){
                return $translate.instant('G_ACTIVE');
            } else if(value === 2){
                return $translate.instant('ADMIN_VESSEL_GRID_STATUS_PAUSED');
            }
        }
    }
})();
