(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('VesselsAdminController', VesselsAdminController);

    VesselsAdminController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$state',
        '$kWindow',
        '$translate',
        'authService',
        'kendoUtility',
        'departmentService',
        'personService',
        'formTypes',
        'locationService',
        'baseDataService',
        'mediaService',
        'commonUtility',
        'htmlElementService',
        'userService',
        'sanitizeService'
    ];

    function VesselsAdminController(
        $q,
        $rootScope,
        $scope,
        $state,
        $kWindow,
        $translate,
        authService,
        kendoUtility,
        departmentService,
        personService,
        formTypes,
        locationService,
        baseDataService,
        mediaService,
        commonUtility,
        htmlElementService,
        userService,
        sanitizeService
    ) {
        var gridHeight;
        const defaultGroupFilter = [];
        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        $scope.ttipOptions = kendoUtility.getTooltipOptions();
        $scope.openLocationHistoryModal = openLocationHistoryModal;
        $scope.editDepartment = editDepartment;
        $scope.addDepartment = addDepartment;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.openDepartmentSharing = openDepartmentSharing;
        $scope.viewImage = viewImage;
        $scope.departments = [];
        $scope.locations = [];

        window.onbeforeunload = function () {
            if ($('#vesselsGrid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#vesselsGrid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        var vesselAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: e => e.success(_.sortBy($scope.departments, 'externalId')),
                update: e => onVesselGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'departmentId',
                    fields: {
                        externalId: { editable: false },
                        name: { editable: true, validation: { required: true } },
                        departmentTypeId: { editable: true },
                        departmentInfo: { editable: false },
                        locationNumber: { editable: true },
                        mmsi: { editable: true },
                        callSign: { editable: false },
                        ownerName: { editable: false },
                        ownerAddress: { editable: false },
                        ownerPhone: { editable: false },
                        bargeName: { editable: false },
                        normalWorkHoursPerDay: { editable: false },
                        canHaveOrders: { editable: false },
                        status: { editable: false }
                    }
                },
            }
        });

        const getDistinctAndSortedLocations = (locations) => {
            let distinctLocations = [...new Map(locations.map((item) => [item['locationNumber'], item])).values()];
            return _.sortBy(distinctLocations, 'name');
        }

        const vesselsGridOptions = langId => ({
            dataSource: vesselAdministrationDataSource,
            columns: [
                {
                    field: 'externalId',
                    title: $translate.instant('ADMIN_VESSEL_GRID_ID', null, null, langId),
                    width: 60,
                    template: function (department) {
                        if (department.hasImage) {
                            return '<a href="' + department.departmentInfo.mediaUrl + '" target="_blank">' + department.externalId + '</a>';
                        }
                        else if (department.externalId) {
                            return '<span>' + department.externalId + '</span>';
                        }
                        else return '';
                    }
                },
                {
                    field: 'name',
                    title: $translate.instant('ADMIN_VESSEL_GRID_NAME', null, null, langId),
                    width: 160,
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.name ? dataItem.name : '';
                    },
                },
                {
                    field: 'departmentTypeId',
                    title: $translate.instant('ADMIN_VESSEL_GRID_TYPEOFDEPARTMENT', null, null, langId),
                    width: 120,
                    template: function (dataItem) {
                        if (dataItem.departmentTypeId !== null) {
                            var departmentObject = null
                            if (dataItem.dirty) {
                                toggleChangesButtons(false);
                            }
                            if (dataItem.dirty && dataItem.departmentTypeId && dataItem.departmentTypeId.id) {
                                departmentObject = dataItem.departmentTypeId
                                dataItem.departmentTypeId = dataItem.departmentTypeId.id
                            } else {
                                departmentObject = $scope.departmentTypes.filter(obj => {
                                    return obj.id === dataItem.departmentTypeId
                                })[0];
                            }
                            if (departmentObject && departmentObject !== null && departmentObject.name_NO !== null) {
                                return departmentObject.name_NO;
                            }
                            return ''
                        }
                        return ''
                    },
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                eq: $translate.instant("KENDO_FILTER_OPERATOR_EQ"),
                                neq: $translate.instant("KENDO_FILTER_OPERATOR_NEQ"),
                                isnull: $translate.instant("KENDO_FILTER_OPERATOR_ISNULL"),
                                isnotnull: $translate.instant("KENDO_FILTER_OPERATOR_ISNOTNULL"),
                            }
                        },
                        ui: function (element) {
                            element.kendoDropDownList({
                                optionLabel: $translate.instant('ADMIN_VESSEL_GRID_TYPEOFDEPARTMENT', null, null, langId),
                                dataSource: new kendo.data.DataSource({ data: $scope.departmentTypes }),
                                dataTextField: "name_NO",
                                dataValueField: "id",
                                defaultValue: ""
                            });
                        }
                    },
                    editor: departmentTypeDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_VESSEL_GRID_TYPEOFDEPARTMENT', null, null, langId) + ": #=items[0] && items[0].departmentType && items[0].departmentType.name_NO ? items[0].departmentType.name_NO : '' #"
                },
                {
                    field: 'departmentInfo',
                    title: $translate.instant('ADMIN_VESSEL_GRID_AISTYPEOFDEPARTMENT', null, null, langId),
                    template: function (dataItem) {
                        return dataItem.departmentInfo.diType ? dataItem.departmentInfo.diType : '';
                    },
                    groupHeaderTemplate: $translate.instant('ADMIN_VESSEL_GRID_AISTYPEOFDEPARTMENT', null, null, langId) + ": #=items[0] && items[0].departmentInfo && items[0].departmentInfo.diType ? items[0].departmentInfo.diType : '' #"
                },
                {
                    field: 'locationNumber',
                    title: $translate.instant('G_LOCATION', null, null, langId),
                    width: 200,
                    template: function (dataItem) {
                        var departmentObject = null
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        if (dataItem.dirty && dataItem.locationNumber !== null && dataItem.locationNumber.locationNumber && dataItem.locationNumber.locationNumber !== null) {
                            departmentObject = dataItem.locationNumber
                            dataItem.locationNumber = dataItem.locationNumber.locationNumber
                        } else {
                            departmentObject = $scope.locations.filter(obj => {
                                return obj.locationNumber == dataItem.locationNumber
                            })[0];
                        }
                        if (departmentObject && departmentObject !== null && departmentObject.name) {
                            dataItem.locationName = departmentObject.name
                            return departmentObject.name;
                        }
                        return ''
                    },
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                eq: $translate.instant("KENDO_FILTER_OPERATOR_EQ"),
                                neq: $translate.instant("KENDO_FILTER_OPERATOR_NEQ"),
                                isnull: $translate.instant("KENDO_FILTER_OPERATOR_ISNULL"),
                                isnotnull: $translate.instant("KENDO_FILTER_OPERATOR_ISNOTNULL"),
                            }
                        },
                        ui: function (element) {
                            element.kendoDropDownList({
                                optionLabel: $translate.instant('G_LOCATION', null, null, langId),
                                dataSource: new kendo.data.DataSource({ data: getDistinctAndSortedLocations($scope.locations) }),
                                dataTextField: "name",
                                dataValueField: "locationNumber",
                                defaultValue: ""
                            });
                        }
                    },
                    editor: locationDropDownEditor,
                    groupHeaderTemplate: $translate.instant('G_LOCATION', null, null, langId) + ": #=items[0] && items[0].locationName ? items[0].locationName : '' #"
                },
                {
                    field: 'mmsi',
                    title: $translate.instant('ADMIN_VESSEL_GRID_MMSI', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.mmsi ? dataItem.mmsi : '';
                    },
                },
                {
                    field: 'parentDepartment',
                    title: $translate.instant('ADMIN_VESSEL_GRID_PARENTDEPARTMENT', null, null, langId),
                    template: function (dataItem) {
                        var parentDepartmentObject = null;
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }

                        if (dataItem.dirty && dataItem.parentDepartment) {
                            dataItem.parentDepartmentId = dataItem.parentDepartment.departmentId;
                            parentDepartmentObject = $scope.departments.filter(obj => {
                                return obj.departmentId === dataItem.parentDepartment.departmentId;
                            })[0];
                        }
                        else if (dataItem.parentDepartment && dataItem.parentDepartment != null) {
                            parentDepartmentObject = $scope.departments.filter(obj => {
                                return obj.departmentId === dataItem.parentDepartment.departmentId;
                            })[0];
                        }

                        if (parentDepartmentObject && parentDepartmentObject != null && parentDepartmentObject.name != null) {
                            return parentDepartmentObject.name;
                        }

                        return '';
                    },
                    editor: parentDepartmentDropDownEditor,
                },
                {
                    field: 'callSign',
                    title: $translate.instant('ADMIN_VESSEL_GRID_CALLSIGN', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.departmentInfo.callSign ? dataItem.departmentInfo.callSign : '';
                    },
                },
                {
                    field: 'ownerName',
                    title: $translate.instant('ADMIN_VESSEL_GRID_OWNERNAME', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.departmentInfo.ownerName ? dataItem.departmentInfo.ownerName : '';
                    },
                },
                {
                    field: 'ownerAddress',
                    title: $translate.instant('ADMIN_VESSEL_GRID_OWNERADDRESS', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.departmentInfo.ownerAddress ? dataItem.departmentInfo.ownerAddress : '';
                    },
                },
                {
                    field: 'ownerPhone',
                    title: $translate.instant('ADMIN_VESSEL_GRID_OWNERPHONE', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.departmentInfo.ownerPhone ? dataItem.departmentInfo.ownerPhone : '';
                    },
                },
                {
                    field: 'bargeName',
                    title: $translate.instant('ADMIN_VESSEL_BARGE', null, null, langId),
                },
                {
                    field: 'normalWorkHoursPerDay',
                    title: $translate.instant('ADMIN_VESSEL_GRID_WORK_HOURS_PER_DAY', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.normalWorkHoursPerDay ? dataItem.normalWorkHoursPerDay : '';
                    },
                },
                {
                    field: 'canHaveOrders',
                    title: $translate.instant('ADMIN_VESSEL_GRID_ASSIGNTOORDER', null, null, langId),
                    template: function (dataItem) {
                        const checked = (dataItem.canHaveOrders ? 'checked' : '');
                        return `<div class="center-content"><input type="checkbox" class="status-checkbox" disabled ${checked} /></div>`;
                    }
                },
                {
                    field: 'status',
                    title: $translate.instant('G_STATUS', null, null, langId),
                    template: (dataItem) => {
                        switch (dataItem.status){
                            case 0: {
                                return $translate.instant('G_INACTIVE', null, null, langId);
                            }
                            case 1: {
                                return $translate.instant('G_ACTIVE', null, null, langId);
                            }
                            case 2: {
                                return $translate.instant('ADMIN_VESSEL_GRID_STATUS_PAUSED', null, null, langId);
                            }
                            default:
                                return '';
                        }
                    }
                },
                {
                    width: 100,
                    command: [
                        {
                            name: 'editDepartmentAction',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                editDepartment(data);
                            },
                            template: '<a class="k-grid-editDepartmentAction"><i class="fa fa-edit grid-icon"></i></a>'
                        },
                        {
                            name: 'locationHistory',
                            click: function (e) {
                                e.preventDefault();
                                const tr = $(e.target).closest('tr');
                                const data = this.dataItem(tr).toJSON();
                                openLocationHistoryModal(data);
                            },
                            template: '<a class="k-grid-locationHistory"><i class="fa fa-clipboard-list grid-icon"></i></a>'
                        }
                    ],
                }
            ],
            dataBinding: function (e) {
                gridHeight = $('#vessels-admin-view').height();
            },
            dataBound: function (e) {
                if (this.content.height() > gridHeight - this.content.offset().top) {
                    var newContentHeight = $('#vessels-admin-view').height() - 5;

                    this.content.height(newContentHeight);
                }
            },
            detailInit: detailInit,
            detailTemplate: kendo.template($('#operationLog-department-checklist-template').html()),
            animation: false,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            groupable: true,
            height: kendoUtility.calculateRemainingSpace(-40),
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            resizable: true,
            filterable: {
                name: 'FilterMenu',
                extra: true
            }
        });
        $scope.vesselsGridOptions = vesselsGridOptions($translate.use());

        const checklistGridOptions = data => ({
            dataSource: {
                data,
                group: { field: "logActionDescription" },
            },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS')
            },
            columns: [
                {
                    field: 'logActionDescription',
                    hidden: true,
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_LOGACTION_DESCRIPTION'),
                    groupHeaderTemplate: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_GROUP_TITLE') + " #=value#",
                    template: function (dataItem) {
                        return dataItem.logActionDescription;
                    }
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_DESCRIPTION')
                },
                {
                    field: 'additionalInformation',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_INFO')
                },
                {
                    field: 'measurementUnitType',
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_MEASUREMENTTYPE'),
                    template: '#: measurementUnitType != null? measurementUnitType.measurementUnitDescription : ""#'
                },
                {
                    width: 50,
                    title: $translate.instant('ADMIN_LOGACTION_GRID_CHECKLIST_ACTIVE'),
                    template:
                        '<span class="center-content"><input type="checkbox" #= isActive != null && isActive  ? \'checked="checked"\' : ""# disabled="disabled" /></span>'
                },
                {
                    command: [
                        {
                            name: 'editCheckListItem',
                            click: function (e) {
                                e.preventDefault();
                                var sender = e.currentTarget;
                                var row = angular.element(sender).closest("tr");
                                var data = $("#check-list-grid").data("kendoGrid").dataItem(row).toJSON();
                                editCheckListItem(data.checkListItemId);
                            },
                            template: '<a class="k-grid-editCheckListItem"><i class="fa fa-pencil-alt"></i></a>'
                        }
                    ],
                    width: 80
                }
            ]
        });
        $scope.checklistGridOptions = checklistGridOptions([]);

        $scope.$on('langChanged', (event, code) => {
            $scope.vesselsGridOptions = vesselsGridOptions(code);
            const mainGrid = kendoUtility.createWidget('kendoGrid', $scope.vesselsGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => mainGrid('#vesselsGrid')]);
            langId = code;
        });

        initController();
        function initController() {
            $scope.authData = authService.getAuthData();
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'vesselsAdminBusyIndicator',
                destination: '#vesselsGrid'
            });
            htmlElementService.checkIfElementExists("filter-group")
                .then(function (result) {
                    commonUtility.calculateAdminTabMenuWidth();
                });

            toggleChangesButtons(true);

            let requests = {};
            requests.persons = personService.getPersonsByContractorId($scope.authData.contractorId);
            requests.departments = departmentService.getDepartmentsWebapi($scope.authData.contractorId, true, false, false, true);
            requests.departmentTypes = departmentService.getDepartmentTypesWebApi();
            requests.locations = locationService.getContractorOwnLocations($scope.authData.contractorId);
            requests.measurementUnitTypes = baseDataService.getMeasurementUnitTypesWebapi();
            requests.users = userService.getUsersAsDropdownByFilter({contractorIds: [$scope.authData.contractorId]});
            $q.all(requests)
                .then(data => {
                    $scope.persons = data.persons;
                    $scope.users = data.users;
                    $scope.departments = data.departments;
                    $scope.departmentTypes = data.departmentTypes
                    $scope.departmentInfos = data.departments.map(d => d.departmentInfo);
                    $scope.measurementUnitTypes = data.measurementUnitTypes;
                    $scope.locations = data.locations

                    _.forEach($scope.locations, function (location) {
                        location.locationNumber = location.locationNumberString
                    });

                    _.forEach($scope.departments, function (department) {
                        extendDepartment(department);
                    });
                })
                .catch(error => console.error(error))
                .finally(() => {
                    loadDepartmentData();
                });
            $rootScope.$broadcast('hideBusyIndicator', 'vesselsAdminBusyIndicator');
        }

        function loadDepartmentData() {
            $scope.vesselsGrid.dataSource.read();
            $scope.vesselsGrid.refresh();
        }

        function departmentTypeDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_VESSEL_DEPARTMENT_TYPE_SELECT'),
                    dataTextField: 'name_NO',
                    dataValueField: 'id',
                    dataSource: {
                        data: $scope.departmentTypes
                    }
                });
        }

        function locationDropDownEditor(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('G_SELECT_LOCATION'),
                    dataTextField: 'name',
                    dataValueField: 'locationNumber',
                    dataSource: {
                        data: getDistinctAndSortedLocations($scope.locations)
                    }
                });
        }

        function parentDepartmentDropDownEditor(container, options) {

            var row = angular.element(container).closest("tr");
            var data = $("#vesselsGrid").data("kendoGrid").dataItem(row).toJSON();

            var childDepartmentIds = [];
            getChildDepartments(data, childDepartmentIds);

            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_VESSEL_DEPARTMENTSHARING_DEPARTMENT_SELECT'),
                    dataTextField: 'name',
                    dataValueField: 'departmentId',
                    dataSource: {
                        data: $scope.departments.filter(d => d.departmentId != data.departmentId && !childDepartmentIds.includes(d.departmentId))
                    }
                });
        }

        function extendDepartment(department) {
            department.hasImage = false;

            if (department.departmentInfo) {
                department.departmentInfo.deliveryDate = department.departmentInfo.deliveryDate ? moment(department.departmentInfo.deliveryDate).format(
                    'DD.MM.YYYY'
                ) : null;
                department.departmentInfo.warrantyExpireDate = department.departmentInfo.warrantyExpireDate ? moment(
                    department.departmentInfo.warrantyExpireDate
                ).format('DD.MM.YYYY') : null;

                var person = _.find($scope.persons, { personId: department.departmentInfo.technicalManagerId });

                if (person) {
                    department.departmentInfo.technicalManagerName = person.firstName + ' ' + person.lastName;
                }

                if (department.departmentInfo.defaultMediaId) {
                    department.departmentInfo.mediaUrl = mediaService.createMediaUrl(department.departmentInfo.defaultMediaId);
                    department.hasImage = true;
                }

                department.callSign = department.departmentInfo.callSign;
                department.ownerName = department.departmentInfo.ownerName;
                department.ownerAddress = department.departmentInfo.ownerAddress;
                department.ownerPhone = department.departmentInfo.ownerPhone;

            } else {
                department.departmentInfo = {};
            }

            if (department.parentDepartmentId != null) {
                department.parentDepartment = $scope.departments.find(d => d.departmentId === department.parentDepartmentId)
            }

            if (!department.departmentType) {
                department.departmentType = {};
            }

            department.bargeName = department.barge ? department.barge.name : '';
        }

        function detailInit(e) {
            var departmentId = e.data.departmentId;
            departmentService.getCheckListItemsWebApi(departmentId).then(function (data) {
                _.forEach(data, function (item) {
                    item.measurementUnitType = $scope.measurementUnitTypes.find(r => r.measurementUnitTypeId === item.measurementUnitTypeId);
                    item.logActionDescription = item.logAction ? item.logAction.description : '';
                });
                $scope.checkListItems = data;
                const element = e.detailRow.find('.checklist-grid');
                $scope.checklistGridOptions = checklistGridOptions(data);
                element.kendoGrid($scope.checklistGridOptions);
                $(`#${departmentId}`)
                    .text($translate.instant('G_NEWITEM'))
                    .click(() => addCheckListItem(departmentId));
            });

        }

        function addCheckListItem(logActionId) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_LOGACTION_ADDCHECKLIST'),
                    resizable: true,
                    height: 290,
                    width: 430,
                    visible: false
                },
                templateUrl: 'app/administration/logActions/check-list-item-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CheckListItemDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.add;
                    },
                    checkListItem: function () {
                        return null;
                    },
                    measurementUnitTypes: function () {
                        return $scope.measurementUnitTypes;
                    },
                    logActionId: function () {
                        return logActionId;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                }
            });
        }

        function openLocationHistoryModal(data) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_VESSEL_DEPARTMENT_HISTORY', null, null, $translate.use()),
                    resizable: true,
                    height: gridHeight < 860 ? gridHeight : 860,
                    width: 1380,
                    visible: false
                },
                templateUrl: 'app/administration/vessels/vessel-history-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'VesselHistoryController',
                resolve: {
                    department: () => data,
                }
            });
        }

        function updateDepartments() {
            var deferred = $q.defer();
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'vesselsIndicator',
                destination: '#vesselsGrid',
                overlay: true
            });

            departmentService.getDepartmentsWebapi($scope.authData.contractorId, true, false, true).then(
                function (departments) {
                    $scope.departments = departments;
                    _.forEach($scope.departments, function (department) {
                        extendDepartment(department);
                    });

                    $scope.vesselsGrid.dataSource.data(_.sortBy($scope.departments, 'externalId'));
                    $scope.vesselsGrid.refresh();
                    $rootScope.$broadcast('hideBusyIndicator', 'vesselsIndicator');

                    deferred.resolve();
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }
        function addDepartment() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_VESSEL_NEW_DEPARTMENT', null, null, $translate.use()),
                    maxHeight: 700,
                    resizable: true,
                    width: 550,
                    visible: false
                },
                templateUrl: 'app/administration/vessels/vessel-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'VesselDetailsModalController',
                resolve: {
                    formType: () => formTypes.add,
                    department: () => null,
                    departments: () => $scope.departments,
                    departmentTypes: () => $scope.departmentTypes,
                    technicalManagers: () => $scope.persons,
                    users: () => $scope.users,
                    locations: () => $scope.locations,

                }
            });
            windowInstance.result.then(function (result) {
                if (result) {
                    updateDepartments();
                }
            });
        }

        function editDepartment(department) {
            var department = _.find($scope.departments, function (d) {
                return d.departmentId == department.departmentId;
            });

            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_VESSEL_EDIT_DEPARTMENT', null, null, $translate.use()),
                    resizable: true,
                    height: 630,
                    width: 550,
                    visible: false
                },
                templateUrl: 'app/administration/vessels/vessel-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'VesselDetailsModalController',
                resolve: {
                    formType: () => formTypes.edit,
                    department: () => department,
                    departments: () => $scope.departments,
                    departmentTypes: () => $scope.departmentTypes,
                    technicalManagers: () => $scope.persons,
                    users: () => $scope.users,
                    locations: () => $scope.locations,
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateDepartments();
                }
            });
        }

        function editCheckListItem(checkListItemId) {
            var checkListItem = null;

            checkListItem = _.find($scope.checkListItems, function (cli) {
                return cli.checkListItemId == checkListItemId;
            });

            if (checkListItem == null) {
                return;
            }

            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_LOGACTION_EDITCHECKLIST'),
                    resizable: true,
                    height: 290,
                    width: 430,
                    visible: false
                },
                templateUrl: 'app/administration/logActions/check-list-item-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'CheckListItemDetailsModalController',
                resolve: {
                    formType: function () {
                        return formTypes.edit;
                    },
                    checkListItem: function () {
                        return checkListItem;
                    },
                    measurementUnitTypes: function () {
                        return $scope.measurementUnitTypes;
                    },
                    logActionId: function () {
                        return checkListItem.logActionId;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateDepartments();
                }
            });
        }

        function openDepartmentSharing() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_VESSEL_DEPARTMENTSHARING'),
                    resizable: true,
                    height: gridHeight,
                    width: 1380,
                    visible: false
                },
                templateUrl: 'app/administration/vessels/vessel-sharing-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'VesselSharingController',
                resolve: {}
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateDepartments();
                }
            });
        }

        function viewImage(mediaUrl) {
            var url = $state.href('main.orderReport', {
                ContractorId: dataItem.ContractorId,
                OrderId: dataItem.OrderId
            });
            window.open(url, '_blank');
        }

        function saveChanges() {
            $scope.vesselsGrid.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.vesselsGrid.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-vessel');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-vessel');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function CreateDepartmentHistoryElementIfLocationIsDirty(e) {
            var isLocationDirty = false
            $scope.vesselsGridOptions.dataSource.data().filter(obj => {
                if (obj.dirty) {
                    for (var prop in obj.dirtyFields) {
                        if (prop == "locationNumber" && Object.prototype.hasOwnProperty.call(obj.dirtyFields, prop)) {
                            isLocationDirty = true
                        }
                    }
                }
                return true
            })
            if (isLocationDirty && e.data.locationNumber && e.data.locationNumber !== '') {
                departmentService.addDepartmentHistoryElementWebApi({
                    moveDate: new Date(),
                    departmentID: e.data.departmentId,
                    locationNumber: e.data.locationNumber,
                    userId: $scope.authData.userId
                })
            }
        }

        function onVesselGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'vesselsAdminBusyIndicator',
                destination: '#vesselsGrid',
            });
            CreateDepartmentHistoryElementIfLocationIsDirty(e);
            departmentService.updateDepartmentWebApi(transformDates(e.data))
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'vesselsAdminBusyIndicator');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'vesselsAdminBusyIndicator');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'vesselsAdminBusyIndicator');
                });

        }

        function getChildDepartments(dep, childDepartmentIds)
        {
            var mainChildDepartments = $scope.departments.filter(x => x.parentDepartmentId == dep.departmentId);
            for (var i = 0; i < mainChildDepartments.length; i++) {
                var childDepartment = mainChildDepartments[i];
                childDepartmentIds.push(childDepartment.departmentId);
                getChildDepartments(childDepartment, childDepartmentIds);
            }
        }

        function transformDates(department) {
            var dInfo = department.departmentInfo;
            department.departmentInfo.deliveryDate = (dInfo.deliveryDate ? moment(dInfo.deliveryDate, 'DD.MM.YYYY').format() : null);
            department.departmentInfo.warrantyExpireDate = (dInfo.warrantyExpireDate ? moment(dInfo.warrantyExpireDate, 'DD.MM.YYYY').format() : null);
            return department;
        }
    }
})();
