(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('adminEmailTemplateService', adminEmailTemplateService);

    adminEmailTemplateService.$inject = ['$q', '$http', 'serviceUrls'];

    function adminEmailTemplateService($q, $http, serviceUrls) {
        const webApiUrl = `${serviceUrls.webApiBaseUrl}/adminEmailTemplate`;

        return {
            getAllEmailTemplates,
            getEmailTemplateByType,
            updateTemplates,
            getLanguages
        };

        function getAllEmailTemplates() {
            const deferred = $q.defer();

            $http.get(webApiUrl)
                .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function getEmailTemplateByType(type) {
            const deferred = $q.defer();

            $http.get(`${webApiUrl}/byType?type=${type}`)
                .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function updateTemplates(templates) {
            const deferred = $q.defer();

            $http.put(`${webApiUrl}`, templates)
                .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }

        function getLanguages() {
            const deferred = $q.defer();

            $http.get(`${webApiUrl}/languages`)
                .then(response => deferred.resolve(response.data), error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
