(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ServiceFormsOptionsController', ServiceFormsOptionsController);

    ServiceFormsOptionsController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$translate',
        'kendoUtility',
        'serviceFormService',
        'formTypes',
        '$kWindow'
    ];

    function ServiceFormsOptionsController(
        $q,
        $scope,
        $rootScope,
        $translate,
        kendoUtility,
        serviceFormService,
        formTypes,
        $kWindow
    ) {
        $scope.serviceFormsOptions = [];
        $scope.addNewServiceFormOptions = addNewServiceFormOptions;

        const serviceFormsOptionsGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.serviceFormsOptions)
                }
            }),
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    field: 'name',
                    title: $translate.instant('SERVICEFORM_EDITOR_OPTIONS_NAME', null, null, langId)
                },
                {
                    command: [
                        {
                            name: 'editServiceFormOptions',
                            click: e => editServiceFormOptions(e),
                            template: '<a class="k-grid-editServiceFormOptions"><i class="fas fa-edit"></i></a>'
                        },
                        {
                            name: 'deleteServiceFormOptions',
                            click: e => deleteServiceFormOptions(e),
                            template: '<a class="k-grid-deleteServiceFormOptions"><i class="fas fa-trash-alt"></i></a>'
                        }
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            height: 400,
        });
        $scope.serviceFormsOptionsGridOptions = serviceFormsOptionsGridOptions($translate.use());

        initController();
        function initController() {

            $q.all([serviceFormService.getServiceFormOptions()]).then(function(results){
                $scope.serviceFormsOptions = results[0];
                $scope.serviceFormsOptionsGridOptions.dataSource.read();
                $rootScope.$broadcast('hideBusyIndicator', 'serviceFormBusyIndicator');
            });
        }

        function editServiceFormOptions(event) {
            const serviceFormOptions = getServiceFromOptionsGrid(event);
            openServiceFormOptionsDetailsModal(formTypes.edit, serviceFormOptions);
        }

        function deleteServiceFormOptions(event) {
            const serviceFormOptions = getServiceFromOptionsGrid(event);
            confirmDeleteDialog(serviceFormOptions.id);
        }

        function confirmDeleteDialog(id) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 300
                },
                templateUrl: 'app/shared/popups/confirm-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConfirmModalController',
                resolve: {
                    content: function () {
                        return $translate.instant('SERVICEFORM_EDITOR_CONFIRM_MSG');
                    },
                    primaryBtnText: function () {
                        return null;
                    },
                    secondaryBtnText: function () {
                        return null;
                    }
                }
            });
            windowInstance.result.then(function (response) {
                if (response) {
                    serviceFormService.deleteServiceFormOptions(id).then(function(){
                        initController();
                    });
                }
            });
        }

        function addNewServiceFormOptions(){
            openServiceFormOptionsDetailsModal(formTypes.add);
        }

        function getServiceFromOptionsGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#service-forms-options-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function openServiceFormOptionsDetailsModal(formType, serviceFormOption) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('SERVICEFORM_EDITOR_OPTIONS_TITLE'),
                    resizable: true,
                    visible: false,
                    width: 900
                },
                templateUrl: 'app/serviceForms/service-forms-option-details-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ServiceFormsOptionDetailsController',
                resolve: {
                    formType: () => formType == 'edit' ? formType : formTypes.add,
                    serviceFormOption: () => serviceFormOption
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    updateServiceFormOptionsGrid();
                }
            });
        }

        function updateServiceFormOptionsGrid(){
            initController();
        }
    }
})();
