(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('dateUtility', dateUtility);

    dateUtility.$inject = [];

    function dateUtility() {
        var service = {
            getMonthDiff,
            getYesterday,
            isDefaultDate,
            formatDate,
            formatDateForServer,
            toJson,
            toIsoString,
            formatDateTime,
            formatDateTimeForServer,
            formatDateWithKendo,
            getYear,
            getWeekNumber,
            toLocalDateTimeString,
            createUTCDate,
            formatDateTimeUntilMinute
        };

        return service;

        function toLocalDateTimeString(dateTimeString){
            return moment(dateTimeString).add(moment().utcOffset(), 'minutes').format()
        }

        function getMonthDiff(date1, date2) {
            var diffYears = date2.getFullYear() - date1.getFullYear();
            var diffMonths = date2.getMonth() - date1.getMonth();
            var diffDays = date2.getDate() - date1.getDate();

            var months = diffYears * 12 + diffMonths;
            if (diffDays > 0) {
                months += '.' + diffDays;
            } else if (diffDays < 0) {
                months--;
                months += '.' + (new Date(date2.getFullYear(), date2.getMonth(), 0).getDate() + diffDays);
            }

            return Math.floor(months);
        }

        function isDefaultDate(date) {
            let dateToTest = date;
            if (typeof (dateToTest) === 'string') {
                dateToTest = new Date(dateToTest);
            }

            if (dateToTest &&
                dateToTest != 'Invalid Date' &&
                dateToTest.getFullYear() === 1970 &&
                dateToTest.getMonth() === 0 &&
                dateToTest.getDate() === 1) {

                return true;
            }

            return false;
        }

        function formatDateTime(date) {
            var currentDate = kendo.parseDate(date);

            if (currentDate == null || currentDate < new Date('1900-01-01'))
                return '-';
            else
                return kendo.toString(kendo.parseDate(date), 'dd.MM.yyyy HH:mm:ss');
        }

        function formatDateTimeForServer(date) {
            var currentDate = kendo.parseDate(date);

            if (currentDate == null || currentDate < new Date('1900-01-01'))
                return '-';
            else
                return kendo.toString(kendo.parseDate(date), 'yyyy-MM-ddTHH:mm:ss');
        }

        function formatDate(date) {
            if (date) {
                return moment(new Date(date)).format('DD.MM.YYYY');
            }
            return '';
        }

        function formatDateForServer(date) {
            if (date) {
                return kendo.toString(kendo.parseDate(date), 'yyyy-MM-ddT00:00:00');
            }
            return '';
        }

        function formatDateWithKendo(date) {
            if (date) {
                return kendo.toString(kendo.parseDate(date), 'dd.MM.yyyy');
            }
            return '';
        }

        function toJson(date) {
            var mmDate = moment(date);
            var timeZoneOffset = mmDate.format('ZZ');
            return '/Date(' + mmDate.valueOf() + timeZoneOffset + ')/';
        }

        function toIsoString(date) {
            return moment(date).format().substring(0, 19);
        }

        function getYesterday() {
            return (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
        }

        function getYear() {
            return new Date().getFullYear();
        }

        function getWeekNumber() {
            var date = new Date();
            var onejan = new Date(date.getFullYear(), 0, 1);
            return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
        }

        function createUTCDate(date) {
            return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        }

        function formatDateTimeUntilMinute(date) {
            var currentDate = kendo.parseDate(date);

            if (currentDate == null || currentDate < new Date('1900-01-01'))
                return '';
            else
                return kendo.toString(kendo.parseDate(date), 'dd.MM.yyyy HH:mm');
        }
    }
})();
