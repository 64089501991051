(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('itemTreeService', itemTreeService);

    itemTreeService.$inject = ['$q', '$http', 'serviceUrls', 'itemValidityIntervalType', 'itemStatus', 'dateUtility'];

    function itemTreeService($q, $http, serviceUrls, itemValidityIntervalType, itemStatus, dateUtility) {
        const itemWebApiUrl = `${serviceUrls.webApiBaseUrl}/item`;
        const documentApiBaseUrl = `${serviceUrls.webApiBaseUrl}/document`;

        const itemTreeService = {
            getItemById: getItemById,
            getItemForestByCategoryId: getItemForestByCategoryId,
            getItemsByRelatedEntityIdWebApi: getItemsByRelatedEntityIdWebApi,
            getAllItemsByRelatedEntityIdsWebApi: getAllItemsByRelatedEntityIdsWebApi,
            updateItem: updateItem,
            moveItem: moveItem,
            addCategory: addCategory,
            getSkillCategories: getSkillCategories,
            getItemCategoryWithItemsTree: getItemCategoryWithItemsTree,
            cloneItemCategoryTree: cloneItemCategoryTree,
            cloneSelectedItemsWithCategory: cloneSelectedItemsWithCategory,
            updateCategory: updateCategory,
            deleteCategory: deleteCategory,
            addEquipmentDocument: addEquipmentDocument,
            addItemFileWebApi: addItemFileWebApi,
            updateItemWithChildren: updateItemWithChildren,
            deleteItemWithChildren: deleteItemWithChildren,
            updateItemFileWebApi: updateItemFileWebApi,
            deleteItemFileWebApi: deleteItemFileWebApi,
            getItemValidityIntervalTypeValues: getItemValidityIntervalTypeValues,
            getItemValidityIntervalTypeText: getItemValidityIntervalTypeText,
            getItemStatusValues: getItemStatusValues,
            getItemStatusText: getItemStatusText,
            getDocumentsByUserId: getDocumentsByUserId,
            getAllItemsByExternalId: getAllItemsByExternalId,
            getItemsByRelatedEntityIdOrCreate: getItemsByRelatedEntityIdOrCreate,
            getEquipmentsByDepartmentId: getEquipmentsByDepartmentId,
            getDocumentTypesByContractorId: getDocumentTypesByContractorId,
            addItem: addItem,
            updateItem: updateItem,
            getItemsTreeByRelatedEntityId: getItemsTreeByRelatedEntityId,
            uploadFiles: uploadFiles,
            loadCriticalDocuments: loadCriticalDocuments,
            getItemByCategoryId: getItemByCategoryId,
            getItemCategoryTreeByKey: getItemCategoryTreeByKey,
            getEquipmentsByRelatedEntityId: getEquipmentsByRelatedEntityId,
            getItemCategoryByKey: getItemCategoryByKey,
            addCategoryTreeFromSfiConstants: addCategoryTreeFromSfiConstants
        };

        return itemTreeService;

        function getItemByCategoryId(categoryId, contractorId, departmentId) {
            var deferred = $q.defer();

            var url = itemWebApiUrl + `/byCategoryIdWithoutChildren?categoryId=${categoryId}&contractorId=${contractorId}&departmentId=${departmentId}`;

            $http.get(url).then(function (response) {
                formatItemList(response.data);
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function loadCriticalDocuments(departmentId) {
            var deferred = $q.defer();

            getItemsByDepartmentId(departmentId).then(
                function (items) {
                    //Order items by name
                    items = _.sortBy(items, ['description']);
                    deferred.resolve(items);
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function getItemsByDepartmentId(departmentId) {
            var deferred = $q.defer();
            var url = itemWebApiUrl + `/criticalDocByDepartment?departmentId=${departmentId}`

            $http.get(url).then(function (response) {
                var item = response.data;
                formatItemList(response.data);
                deferred.resolve(item);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getItemForestByCategoryId(categoryId, contractorId, departmentId) {
            var deferred = $q.defer();

            var url = itemWebApiUrl + `/byCategoryId?categoryId=${categoryId}&contractorId=${contractorId}&departmentId=${departmentId}`;

            $http.get(url).then(function (response) {
                formatItemList(response.data);
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getItemById(itemId) {
            var deferred = $q.defer();
            var url = itemWebApiUrl + `/byItemId?itemId=${itemId}`

            $http.get(url).then(function (response) {
                var item = response.data;
                formatItem(response.data);
                deferred.resolve(item);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getItemCategoryTreeByKey(categoryKey, contractorId, relatedEntityId) {
            var deferred = $q.defer();

            var url = itemWebApiUrl + `/itemCategoryTreeByKey?categoryKey=${categoryKey}&contractorId=${contractorId}`;
            url = relatedEntityId ? `${url}&relatedEntityId=${relatedEntityId}` : url

            $http.get(url).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getEquipmentsByRelatedEntityId(contractorId, relatedEntityId, keyName) {
            var deferred = $q.defer();

            var url = itemWebApiUrl + `/equipmentsByReletedIds?keyName=${keyName}`;

            var filter = {
                ContractorId: contractorId,
                RelatedEntityIds: [relatedEntityId]
            }

            $http.post(url, filter).then(function (response) {
                var items = response.data;

                formatItemList(items);

                deferred.resolve(items);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getItemCategoryByKey(categoryKey, contractorId) {
            var deferred = $q.defer();

            var url = itemWebApiUrl + `/itemCategoryByKey?categoryKey=${categoryKey}&contractorId=${contractorId}`;

            $http.get(url).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function addItemFileWebApi(itemFile) {
            const deferred = $q.defer();
            const url = itemWebApiUrl + '/file'
            $http.post(url, itemFile)
                .then(result => {
                    deferred.resolve(result);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getItemsByRelatedEntityIdWebApi(contractorId, relatedEntityId, includeConglomerateItems = false) {
            const url = itemWebApiUrl + `/pwa?contractorId=${contractorId}&relatedEntityId=${relatedEntityId}&includeConglomerateItems=${includeConglomerateItems}`
            const deferred = $q.defer();
            $http.get(url)
                .then(result => {
                    let items = result.data;
                    formatItemList(items);
                    deferred.resolve(items);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getAllItemsByRelatedEntityIdsWebApi(contractorId, relatedEntityIds) {
            const deferred = $q.defer();
            const url = itemWebApiUrl + '/byRelatedEntityIds'
            $http.post(url, {
                contractorId,
                relatedEntityIds
            })
                .then(response => {
                    let items = response.data;

                    formatItemList(items);

                    deferred.resolve(items);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateItemWithChildren(item) {
            var deferred = $q.defer();
            var url = itemWebApiUrl + '/withChildren'

            $http.put(url, item).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function deleteItemWithChildren(itemId) {
            const deferred = $q.defer();

            $http.delete(itemWebApiUrl + "?itemId=" + itemId).then(function (result) {
                deferred.resolve(result);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function moveItem(moveItem, jobDefinitionStayActive) {
            let deferred = $q.defer();


            $http.post(`${itemWebApiUrl}/MoveItem?jobDefinitionStayActive=${jobDefinitionStayActive}`, moveItem).then(function(response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function cloneItemCategoryTree(contractorId, sourceDepartmentId, targetDepartmentId, needFileCopy) {
            const deferred = $q.defer();
            const url = `${itemWebApiUrl}/copyEquipmentCategoryTree?contractorId=${contractorId}&sourceDepartmentId=${sourceDepartmentId}&targetDepartmentId=${targetDepartmentId}&needFileCopy=${needFileCopy}`;
            $http.get(url).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function cloneSelectedItemsWithCategory(contractorId, sourceDepartmentId, targetDepartmentId, needFileCopy, selectedItemIds) {
            const deferred = $q.defer();
            const url = `${itemWebApiUrl}/copySelectedEquipmentsWithCategory?contractorId=${contractorId}&sourceDepartmentId=${sourceDepartmentId}&targetDepartmentId=${targetDepartmentId}&needFileCopy=${needFileCopy}`;
            $http.post(url, selectedItemIds).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function deleteCategory(categoryId) {
            const deferred = $q.defer();

            $http.put(`${itemWebApiUrl}/deleteItemCategoryById?categoryId=${categoryId}`).then(function (result) {
                deferred.resolve(result);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function addEquipmentDocument(itemWithDocument) {
            const deferred = $q.defer();
            const url = itemWebApiUrl + '/equipment/document'
            $http.post(url, itemWithDocument)
                .then(result => {
                    deferred.resolve(result ? result.data : null);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getItemCategoryWithItemsTree(contractorId, relatedEntityId) {
            const deferred = $q.defer();
            const url = `${itemWebApiUrl}/itemCategoryWithItemsTree?relatedEntityId=${relatedEntityId}&contractorId=${contractorId}`;
            $http.get(url,)
                .then(result => {
                    deferred.resolve(result.data);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateItemFileWebApi(itemFile) {
            const deferred = $q.defer();

            $http.put(itemWebApiUrl + '/file', itemFile)
                .then(result => {
                    deferred.resolve(result);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteItemFileWebApi(itemFileId) {
            const deferred = $q.defer();

            $http.delete(`${itemWebApiUrl}/file?itemFileId=${itemFileId}`)
                .then(result => {
                    deferred.resolve(result);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getItemValidityIntervalTypeValues() {
            const itemValidityIntervalTypeValues = [];

            for (const intervalType in itemValidityIntervalType) {
                if (itemValidityIntervalType.hasOwnProperty(intervalType)) {
                    itemValidityIntervalTypeValues.push({
                        key: intervalType,
                        value: itemValidityIntervalType[intervalType],
                        text: this.getItemValidityIntervalTypeText(itemValidityIntervalType[intervalType])
                    });
                }
            }
            return itemValidityIntervalTypeValues;
        }

        function getItemValidityIntervalTypeText(intervalType) {
            switch (intervalType) {
                case itemValidityIntervalType.Hours:
                    return 'timer';
                case itemValidityIntervalType.Days:
                    return 'dager';
                case itemValidityIntervalType.Months:
                    return 'måneder';
                default:
                    return '';
            }
        }

        function getItemStatusText(status) {
            switch (status) {
                case itemStatus.Active:
                    return 'Aktiv';
                case itemStatus.Archived:
                    return 'Arkivert';
                default:
                    return '';
            }
        }

        function getItemStatusValues() {
            const itemStatusValues = [];

            for (const status in itemStatus) {
                if (itemStatus.hasOwnProperty(status)) {
                    itemStatusValues.push({
                        key: status,
                        value: itemStatus[status],
                        text: this.getItemStatusText(itemStatus[status])
                    });
                }
            }
            return itemStatusValues;
        }

        function getDocumentsByUserId(contractorId, userId) {
            const deferred = $q.defer();

            $http.get(`${documentApiBaseUrl}/getDocumentsByUserId?contractorId=${contractorId}&userId=${userId}`)
                .then(function (response) {
                    if (response && response.data) {
                        deferred.resolve(response.data);
                    } else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getAllItemsByExternalId(externalId) {
            const deferred = $q.defer();
            $http.get(`${itemWebApiUrl}/getAllItemsByExternalId?externalId=${externalId}`)
                .then(result => {
                    deferred.resolve(result ? result.data : []);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        //Helper functions
        function formatItemList(itemList) {
            for (let i = 0; i < itemList.length; ++i) {
                formatItem(itemList[i]);
            }
        }

        function formatItem(item) {
            //Map ItemDetails JSON to properties
            if (item.itemDetails) {
                const details = JSON.parse(item.itemDetails);
                for (const prop in details) {
                    if (details.hasOwnProperty(prop)) {
                        item[prop] = details[prop];
                    }
                }
            }

            //Map dimensions as properties

            //Format date(s)
            if (item.lastChangedOn) {
                item.lastChangedOn = moment(item.lastChangedOn).format('DD.MM.YYYY HH:mm:ss')
            }
            if (item.validityInterval) {
                if(item.validityInterval && item.validityInterval.validFrom){
                    const validFrom = new Date(item.validityInterval.validFrom);
                    item.validityInterval.validFrom = dateUtility.formatDate(validFrom);
                }

                if (item.validityInterval && item.validityInterval.validTo) {
                    const validTo = new Date(item.validityInterval.validTo);
                    item.validityInterval.validTo = dateUtility.formatDate(validTo);
                    if (item.validityInterval.warningTime) {
                        item.validityInterval.warningTime = kendo.toString(
                            new Date(validTo.setDate(validTo.getDate() - item.validityInterval.warningTime)), 'dd.MM.yyyy');
                    }
                }
                item.validityInterval.intervalTypeText = getItemValidityIntervalTypeText(item.validityInterval.intervalType);
            }
        }

        function getItemsByRelatedEntityIdOrCreate(contractorId, relatedEntityId, itemName = null) {
            const deferred = $q.defer();
            const url = `${itemWebApiUrl}/byRelatedEntityIdOrCreate?contractorId=${contractorId}&relatedEntityId=${relatedEntityId}&itemName=${itemName}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getEquipmentsByDepartmentId(departmentId) {
            const deferred = $q.defer();
            const url = `${itemWebApiUrl}/equipmentsByDepartmentId?departmentId=${departmentId}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDocumentTypesByContractorId(contractorId) {
            const deferred = $q.defer();

            $http
                .get(`${documentApiBaseUrl}/allDocumentTypesByContractorId?contractorId=${contractorId}`)
                .then((response) => {
                    if (response.data) {
                        deferred.resolve(response.data);
                    }

                    deferred.reject();
                })
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function addItem(document) {
            const deferred = $q.defer();
            $http
                .post(itemWebApiUrl, document)
                .then((response) => {
                    if (response.data) {
                        deferred.resolve(response.data);
                    }
                    deferred.reject();
                })
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function updateItem(document) {
            const deferred = $q.defer();
            $http
                .put(itemWebApiUrl, document)
                .then((response) => {
                    if (response.data) {
                        deferred.resolve(response.data);
                    }
                    deferred.reject();
                })
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function addCategory(category) {
            const deferred = $q.defer();

            $http.post(`${itemWebApiUrl}/addItemCategory`, category).then(function (result) {
                deferred.resolve(result);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getSkillCategories(contractorId) {
            const deferred = $q.defer();
            const url = `${itemWebApiUrl}/skillCategoriesByContractorId?contractorId=${contractorId}`;

            $http.get(url).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateCategory(category) {
            const deferred = $q.defer();

            $http.put(`${itemWebApiUrl}/updateItemCategory`, category).then(function (result) {
                deferred.resolve(result);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getItemsTreeByRelatedEntityId(contractorId, departmentId) {
            const deferred = $q.defer();

            $http.get(itemWebApiUrl + `/PWA/itemsTreeByRelatedEntityId?contractorId=${contractorId}&relatedEntityId=${departmentId}`, {
                relatedEntityId: departmentId
            }).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function uploadFiles(fileItems, relatedEntityId, contractorId, relatedItemId = null) {
            const deferred = $q.defer();
            let url = `${itemWebApiUrl}/uploadFiles?contractorId=${contractorId}`;
            url = relatedItemId ? url + `&relatedItemId=${relatedItemId}` : url;
            url = relatedEntityId ? url + `&relatedEntityId=${relatedEntityId}` : url;
            $http.post(url, fileItems)
                .then(result => {
                    deferred.resolve(result.data);
                }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addCategoryTreeFromSfiConstants(contractorId, targetDepartmentId) {
            const deferred = $q.defer();
            const url = `${itemWebApiUrl}/addCategoryTreeFromSfiConstants?contractorId=${contractorId}&departmentId=${targetDepartmentId}`;
            $http.post(url).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
