(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('MainController', MainController);

    MainController.$inject = ['$scope', '$rootScope', 'mapService', 'baseLayerFactory', 'flyFotoLayerFactory',
    'locationLayerFactory', 'vesselLayerFactory', 'orderTrackLayerFactory', 'mapObjectsLayerService'];

    function MainController($scope, $rootScope, mapService, baseLayerFactory, flyFotoLayerFactory,
        locationLayerFactory, vesselLayerFactory, orderTrackLayerFactory, mapObjectsLayerService) {

        $scope.currentLocationId = null;
        $scope.mapLayers = [];
        $scope.menuOptions = {};

        $scope.toggleLayer = toggleLayer;
        $scope.toggleObjectsLayer = toggleObjectsLayer;

        $scope.latitude = null;
        $scope.longitude = null;

        $scope.$on('zoomToLocation', zoomToLocation);

        $scope.$on('goTo', goTo);

        $scope.$on('mapObjectsLayerCreated', mapObjectsLayerCreated);

        $scope.$on('updateCoordinates', (broadCastEvt, latlng) => {
            $scope.$applyAsync(() => {
                $scope.latitude = latlng.lat;
                $scope.longitude = latlng.lng;
            })
        })

        $scope.$on('splitterResized', function (event, paneSizes) {
            var size = Number(paneSizes.leftPaneSize.replace('px', ''));

            if (size < 800) {
                $('.coordinate-box').css('margin-right', '320px');
            } else {
                $('.coordinate-box').css('margin-right', '0px');
            }

        });

        $scope.copyCoordinates = function (){
            navigator.clipboard.writeText($scope.latitude + "\r\n" + $scope.longitude);
        }

        mapService.ready().then(initMapService);

        function zoomToLocation(event, location, searchItem, redrawLayers, zoomLevel) {
            $rootScope.$broadcast('clearTracking');

            var initialZoomLevel = zoomLevel || 13;

            mapService.ready()
                .then(function () {
                    mapService.setInitialPosition(location, initialZoomLevel);
                })
                .then(mapService.mapCreated)
                .then(function () {
                    mapService.zoomToLocation(location, initialZoomLevel);

                    if (searchItem && searchItem.type === 'Location') {
                        $rootScope.$broadcast('locationLoad', { locationId: searchItem.id });
                    }

                    if (searchItem && searchItem.type === 'OrderTrack') {
                        orderTrackLayerFactory.createLayer(searchItem.id, searchItem.positions);
                    }
                });
        }

        function goTo(event, target, options) {
            mapService.ready()
                .then(mapService.mapCreated)
                .then(function () {
                    mapService.goTo(target, options);
                });
        }

        function mapObjectsLayerCreated(event, location) {
            if ($scope.currentLocationId !== location.LocationNumber) {

                $scope.currentLocationId = location.LocationNumber;
                if (location && location.MapLayers) {
                    $scope.mapLayers = _.map(location.MapLayers, function (layer) {
                        const lastChanged = new Date(parseInt(layer.LastChangedTs.substr(6)));

                        return {
                            id: layer.MapLayerId,
                            selected: layer.Show,
                            defaultSelected: layer.Show,
                            changedAt: lastChanged,
                            name: layer.Name,
                            description: kendo.toString(lastChanged, 'dd.MM.yyyy HH:mm') + ' (' + layer.LastChangedBy + ')'
                        };
                    });

                    $scope.mapLayers = _.orderBy($scope.mapLayers, ['changedAt'], ['desc']);
                }
            }
        }

        function toggleLayer(event, layerName, layerType) {
            event.stopPropagation();

            mapService.toggleLayerVisibility(layerName, layerType);
        }

        function toggleObjectsLayer(event, layer) {
            event.stopPropagation();

            layer.selected = !layer.selected;
            showSelected();
        }

        function showSelected() {
            if ($scope.currentLocationId) {
                var layersToDisplay = _.filter($scope.mapLayers, 'selected'),
                    layersVisibility = _.map(mapObjectsLayerService.layers, function (layer) {
                        return {
                            name: layer.name,
                            visible: layer.visible
                        };
                    });

                $rootScope.$broadcast('locationLoad', {
                    locationId: $scope.currentLocationId,
                    redrawLayers: true,
                    layersToDisplay: _.map(layersToDisplay, 'id'),
                    layersVisibility: layersVisibility
                });
            }
        }

        function initMapService() {
            mapService.createMap('map');

            if($scope.authData.isAuth){
                mapService.on('zoom', function () {
                    if ($scope.menuOptions.close) {
                        $scope.menuOptions.close();
                    }
                });
                mapService.on('center', function () {
                    if ($scope.menuOptions.close) {
                        $scope.menuOptions.close();
                    }
                });
                mapService.on('click', function () {
                    if ($scope.menuOptions.close) {
                        $scope.menuOptions.close();
                    }
                });

                if (!mapService.isInitialized()) {
                    mapService.addLayerFactory([baseLayerFactory, flyFotoLayerFactory, locationLayerFactory, vesselLayerFactory, orderTrackLayerFactory]);
                    mapService.addLayerService(mapObjectsLayerService);
                } else if ($rootScope.initRequired) {
                    mapService.reInitialize();
                    $rootScope.initRequired = false;
                }
            } else {
                mapService.addLayerFactory([baseLayerFactory, null, null, null, null]);
            }
        }
    }
})();
