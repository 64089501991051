(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('CaseHistoryModalController', caseHistoryModalController);

    caseHistoryModalController.$inject = ['$scope', '$translate',
        'dateUtility', 'history', 'workflowStatuses'];

    function caseHistoryModalController($scope, $translate,
        dateUtility, history, workflowStatuses) {

        $scope.HistoryGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success(history)
                },
                batch: false
            }),
            columns: [
                {
                    title: $translate.instant('CASE_HISTORY_MODAL_COMMENT'),
                    field: 'comment',
                    width: 100,
                    template: function (dataItem) {
                        var dirtyElement = dataItem.changes.find(c => c.field == 'comment');
                        if (dirtyElement) {
                            return `<span class="text-red" kendo-tooltip k-content="\' ${dataItem.comment}\'">{{dataItem.comment}}</span>`;
                        }
                        else {
                            if (dataItem.comment) {
                                return `<span kendo-tooltip k-content="\' ${dataItem.comment}\'">{{dataItem.comment}}</span>`;
                            }
                            else {
                                return '';
                            }
                        }
                    }
                },
                {
                    title: $translate.instant('CASE_HISTORY_MODAL_CORRECTIVE_ACTION'),
                    field: 'correctiveAction',
                    width: 100,
                    template: function (dataItem) {
                        var dirtyElement = dataItem.changes.find(c => c.field == 'correctiveAction');
                        if (dirtyElement) {
                            return `<span class="text-red" kendo-tooltip k-content="\'${dataItem.correctiveAction}\'">{{dataItem.correctiveAction}}</span>`;
                        }
                        else {
                            if (dataItem.correctiveAction) {
                                return `<span kendo-tooltip k-content="\'${dataItem.correctiveAction}\'">{{dataItem.correctiveAction}}</span>`;
                            }
                            else {
                                return '';
                            }
                        }
                    }
                },
                {
                    title: $translate.instant('CASE_HISTORY_MODAL_IMMEDIATE_ACTION'),
                    field: 'immediateAction',
                    width: 100,
                    template: function (dataItem) {
                        var dirtyElement = dataItem.changes.find(c => c.field == 'immediateAction');
                        if (dirtyElement) {
                            return `<span class="text-red" kendo-tooltip k-content="\'${dataItem.immediateAction}\'">{{dataItem.immediateAction}}</span>`;
                        }
                        else {
                            if (dataItem.immediateAction) {
                                return `<span kendo-tooltip k-content="\'${dataItem.immediateAction}\'">{{dataItem.immediateAction}}</span>`;
                            }
                            else {
                                return '';
                            }
                        }
                    }
                },
                {
                    title: $translate.instant('CASE_HISTORY_MODAL_CAUSE'),
                    field: 'cause',
                    width: 100,
                    template: function (dataItem) {
                        var dirtyElement = dataItem.changes.find(c => c.field == 'cause');
                        if (dirtyElement) {
                            return `<span class="text-red" kendo-tooltip k-content="\'${dataItem.cause}\'">{{dataItem.cause}}</span>`;
                        }
                        else {
                            if(dataItem.cause)
                            {
                                return `<span kendo-tooltip k-content="\'${dataItem.cause}\'">{{dataItem.cause}}</span>`;
                            }
                            else{
                                return '';
                            }
                        }
                    }
                },
                {
                    title: $translate.instant('CASE_HISTORY_MODAL_STATUS'),
                    field: 'workflowStatusId',
                    width: 70,
                    template: function (dataItem) {
                        var dirtyElement = dataItem.changes.find(c => c.field == 'status');
                        var status = workflowStatuses.filter(function (status) {
                            return status.value === dataItem.workflowStatusId;
                        })[0];
                        if (dirtyElement) {
                            var preStatus = workflowStatuses.filter(function (status) {
                                return status.value === dirtyElement.preValue;
                            })[0];
                            return `<span class="text-red" kendo-tooltip k-content="\'${preStatus.text} <br> &#8595; <br> ${status.text}\'">${status.text}</span>`;
                        }
                        else {
                            if(status && status.text)
                            {
                                return `<span kendo-tooltip k-content="\'${status.text}\'">${status.text}</span>`;
                            }
                            else{
                                return '';
                            }
                        }
                    }
                },
                {
                    title: $translate.instant('CASE_HISTORY_MODAL_ASSIGNEDTO'),
                    field: 'assignedUserName',
                    width: 100,
                    template: function (dataItem) {
                        var dirtyElement = dataItem.changes.find(c => c.field == 'assignedTo');
                        if (dirtyElement) {
                            return `<span class="text-red" kendo-tooltip k-content="\'${dirtyElement.preValue} <br> &#8595; <br> ${dataItem.assignedUserName}\'">{{dataItem.assignedUserName}}</span>`;
                        }
                        else{
                            if(dataItem.assignedUserName)
                            {
                                return `<span kendo-tooltip k-content="\'${dataItem.assignedUserName}\'">${dataItem.assignedUserName}</span>`;
                            }
                            else{
                                return '';
                            }
                        }
                    }
                },
                {
                    title: $translate.instant('CASE_HISTORY_MODAL_DUEDATE'),
                    field: 'dueDate',
                    width: 100,
                    template: function (dataItem) {
                        var dirtyElement = dataItem.changes.find(c => c.field == 'dueDate');
                        if (dirtyElement) {

                            return `<span class="text-red" kendo-tooltip k-content="\'${dateUtility.formatDateTime(dateUtility.toLocalDateTimeString(dirtyElement.preValue))} <br> &#8595; <br> ${dateUtility.formatDateTime(dateUtility.toLocalDateTimeString(dataItem.dueDate))}\'">${dateUtility.formatDateTime(dateUtility.toLocalDateTimeString(dataItem.dueDate))}</span>`;
                        }
                        else{
                            if(dataItem.dueDate)
                            {
                                return `<span kendo-tooltip k-content="\'${dateUtility.formatDateTime(dateUtility.toLocalDateTimeString(dataItem.dueDate))}\'">${dateUtility.formatDateTime(dateUtility.toLocalDateTimeString(dataItem.dueDate))}</span>`;                            }
                            else{
                                return '';
                            }
                        }
                    }
                },
                {
                    title: $translate.instant('G_EDITED_BY'),
                    field: 'createdBy',
                    width: 100,
                    template: function (dataItem) {
                        if(dataItem.createdBy)
                        {
                            return `<span kendo-tooltip k-content="\'${dataItem.createdBy}\'">${dataItem.createdBy}</span>`;
                        }
                        else{
                            return '';
                        }
                    }
                },
                {
                    title: $translate.instant('G_EDITED_ON'),
                    field: 'createdOn',
                    width: 70,
                    template: function (dataItem) {
                        if(dataItem.createdOn)
                        {
                            return `<span kendo-tooltip k-content="\'${dateUtility.formatDateTime(dateUtility.toLocalDateTimeString(dataItem.createdOn))}\'">${dateUtility.formatDateTime(dateUtility.toLocalDateTimeString(dataItem.createdOn))}</span>`;
                        }
                        else{
                            return '';
                        }
                    }
                }
            ],
            editable: false,
            resizable: true
        };

        initController();
        function initController() {
            extendHistory();
            $scope.HistoryGridOptions.dataSource.read();
        }

        function extendHistory() {
            history = _.uniqBy(history,  h => [h.comment, h.correctiveAction, h.immediateAction, h.cause, h.workflowStatusId, h.assignedUserId, h.dueDate].join());
            for (var i = 0; i < history.length; i++) {
                history[i].assignedUserName = (history[i].assignedToUser != null ? history[i].assignedToUser.displayName : '');
                history[i].assignedUserId = (history[i].assignedToUser != null ? history[i].assignedToUser.userId : '');
            }

            for (var i = 0; i < history.length; i++) {
                var changes = [];
                var parentHistoryElement = (i + 1 < history.length ? history[i + 1] : null);
                if (parentHistoryElement) {
                    if (history[i].comment != parentHistoryElement.comment) {
                        changes.push({ field: 'comment', preValue: parentHistoryElement.comment });
                    }
                    if (history[i].correctiveAction != parentHistoryElement.correctiveAction) {
                        changes.push({ field: 'correctiveAction', preValue: parentHistoryElement.correctiveAction });
                    }
                    if (history[i].immediateAction != parentHistoryElement.immediateAction) {
                        changes.push({ field: 'immediateAction', preValue: parentHistoryElement.immediateAction });
                    }
                    if (history[i].cause != parentHistoryElement.cause) {
                        changes.push({ field: 'cause', preValue: parentHistoryElement.cause });
                    }
                    if (history[i].workflowStatusId != parentHistoryElement.workflowStatusId) {
                        changes.push({ field: 'status', preValue: parentHistoryElement.workflowStatusId });
                    }
                    if (history[i].assignedUserId != parentHistoryElement.assignedUserId) {
                        changes.push({ field: 'assignedTo', preValue: parentHistoryElement.assignedUserName });
                    }
                    if (moment(history[i].dueDate).format('DD.MM.YYYY HH:mm:ss')  != moment(parentHistoryElement.dueDate).format('DD.MM.YYYY HH:mm:ss')) {
                        changes.push({ field: 'dueDate', preValue: parentHistoryElement.dueDate });
                    }
                }
                history[i].changes = changes;
            }
        }
    }
})();
