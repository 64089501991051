(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('emailTemplatesAdministrationController', emailTemplatesAdministrationController);

    emailTemplatesAdministrationController.$inject = ['$scope', '$rootScope', '$translate', '$kWindow', 'kendoUtility', 'adminEmailTemplateService', 'authService'];

    function emailTemplatesAdministrationController($scope, $rootScope, $translate, $kWindow, kendoUtility, adminEmailTemplateService, authService) {
        $scope.isSuperUser = authService.getAuthData().isSuperuser;
        $scope.emailTemplates = [];

        const emailTemplatesAdministrationGridOptions = langId => ({
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.emailTemplates)
                },
                schema: {
                    model: {
                        id: 'id',
                        fields: {
                            name: { editable: false }
                        }
                    },
                }
            }),
            columns: [
                { field: 'id', hidden: true },
                { field: 'name', title: $translate.instant('G_NAME', null, null, langId) },
                {
                    command: [
                        { name: 'editEmailTemplate', click: e => editEmailTemplate(e), template: '<a class="k-grid-editEmailTemplate"><i class="fa fa-edit grid-icon"></i></a>' }
                    ],
                    width: 100
                }
            ],
            sortable: true,
            resizable: true,
            filterable: true,
            height: kendoUtility.calculateRemainingSpace(-40),
        });
        $scope.emailTemplatesAdministrationGridOptions = emailTemplatesAdministrationGridOptions($translate.use());

        $scope.$on('langChanged', (_, langId) => {
            $scope.emailTemplatesAdministrationGridOptions = emailTemplatesAdministrationGridOptions(langId);
            const emailTemplatesAdministrationGrid = kendoUtility.createWidget('kendoGrid', $scope.emailTemplatesAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(langId, [() => emailTemplatesAdministrationGrid('#email-templates-administration-grid')]);
        });

        initController();

        function initController() {
            if ($scope.isSuperUser) {
                showBusyIndicator('emailTemplatesAdministrationGrid');

                adminEmailTemplateService.getAllEmailTemplates()
                    .then(result => $scope.emailTemplates = result)
                    .catch(error => console.error(error))
                    .finally(() => {
                        $scope.emailTemplatesAdministrationGridOptions.dataSource.read();
                        hideBusyIndicator('emailTemplatesAdministrationGrid');
                    });
            }
        }

        function editEmailTemplate(e) {
            const type = getTypeFromGrid(e);

            const modal = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('G_EDIT'),
                    resizable: false,
                    visible: false,
                    width: 900
                },
                templateUrl: 'app/systemAdministration/emailTemplates/email-template-details.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'emailTemplateDetailsModalController',
                resolve: {
                    type: () => type.id
                }
            });

            modal.result.then(response => {
                if (response.result) {
                    initController();
                }
            });
        }

        function getTypeFromGrid(event) {
            event.preventDefault();

            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');

            return $("#email-templates-administration-grid").data("kendoGrid").dataItem(row).toJSON();
        }

        function showBusyIndicator(id) {
            $rootScope.$broadcast('showBusyIndicator', { id: id, destination: '#email-templates-administration-grid' });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', id);
        }
    }
})();
