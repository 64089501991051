(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('logActionDepartmentChangeModalController', logActionDepartmentChangeModalController);

    logActionDepartmentChangeModalController.$inject = [
        '$scope',
        '$rootScope',
        '$q',
        '$windowInstance',
        'departmentService',
        'selectedLogActionIds',
        'selectedDepartmentIds',
        'logService'
    ];

    function logActionDepartmentChangeModalController(
        $scope,
        $rootScope,
        $q,
        $windowInstance,
        departmentService,
        selectedLogActionIds,
        selectedDepartmentIds,
        logService
    ) {

        $scope.departments = [];
        $scope.departmentsOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: e => e.success($scope.departments)
                }
            }),
            dataValueField: 'departmentId',
            dataTextField: 'name',
            noDataTemplate: 'Ingen treff'
        };

        initController();
        function initController(){
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logActionDepartmentsUpdateLoadBusyIndicator',
                destination: '#log-action-department-change-form',
                overlay: true
            });

            var requests = {
                getDepartmentsWebapi: departmentService.getDepartmentsWebapi($scope.authData.contractorId)
            };

            $q.all(requests).then(function(result){
                $scope.departments = result.getDepartmentsWebapi;
                $scope.departmentsOptions.dataSource.read();
                $scope.selectedDepartments = $scope.departments.filter(x => selectedDepartmentIds.includes(x.departmentId));
            }).finally(function(){
                $rootScope.$broadcast('hideBusyIndicator', 'logActionDepartmentsUpdateLoadBusyIndicator');
            });
        }

        $scope.validateForm = function(event) {
            event.preventDefault();
            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'logActionDepartmentsUpdateBusyIndicator',
                    destination: '#log-action-department-change-form',
                    overlay: true
                });
                var updateObject = {
                    logActionIds: selectedLogActionIds,
                    departmentIds: $scope.selectedDepartments && $scope.selectedDepartments.length > 0 ? $scope.selectedDepartments.map(x => x.departmentId) : []
                };

                logService.updateDepartmentsOnLogActions(updateObject).then(function(result){
                    $windowInstance.close(result);
                }).finally(function(){
                    $rootScope.$broadcast('hideBusyIndicator', 'logActionDepartmentsUpdateBusyIndicator');
                });
            }
        }

        $scope.closeModal = function(){
            $windowInstance.close();
        }
    }
})();
